import React, { useEffect, useState } from "react";
import TopTitleBanner from "../component/TopTitleBanner";
import "../css/dashboard.css";
import "../css/setting.css";
import { useAuth } from "../contexts/AuthContext";
import "react-toastify/dist/ReactToastify.css";
import "react-tippy/dist/tippy.css";
import { roles } from "../firebase.collection";
import {
  documentId,
  onSnapshot,
  query,
  where,
  collection,
} from "firebase/firestore";
import { db } from "../firebase";
import TitleMedium5 from "../component/Titles/TitleMedium5";
import FormLineMedium5 from "../component/FormLineMedium5";

export default function RolesContent() {
  const [allRoles, setAllRoles] = useState([]);
  const [userRole, setUserRole] = useState("");
  const { currentUser } = useAuth();

  const userIdState = currentUser ? currentUser.uid : "";

  useEffect(() => {
    const roleCollection = collection(db, "roles");
    const unsubscribe = onSnapshot(roleCollection, (querySnapshot) => {
      setAllRoles(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (currentUser) {
      queryRole();
    } else {
      console.log("No user id");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  function queryRole() {
    onSnapshot(
      query(roles, where(documentId(), "==", userIdState)),
      (querySnapshot) => {
        const doc = querySnapshot.docs[0];
        if (doc && doc.exists) {
          const role = doc.data().role;

          setUserRole(role);
        } else {
          console.log("No role for this user");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }
    );
  }

  const isSuperAdmin =
    userRole === "Super Admin" || userRole === "Clinical Director";

  return (
    <div className="right-container-settings">
      {isSuperAdmin ? (
        <div className="admin-panel">
          <TopTitleBanner title={"Role management"} />

          <div className="profile-title-setting">
            <div className="grey-subtitle-2 ">
              As a Super Admin, you can modify the role of each user
            </div>
          </div>

          <TitleMedium5 />

          {allRoles.map((role) => (
            <FormLineMedium5
              key={role.id}
              userId={role.id}
              userName={role.displayName}
              userRole={role.role}
              userPhone={role.phoneNumber}
              userEmail={role.userEmail}
            />
          ))}
        </div>
      ) : (
        <div>
          <TopTitleBanner title={"Role management"} />
          <p style={{ marginLeft: "60px", color: "red", fontWeight: "bold " }}>
            --You don't have Super-admin credentials to access this page--
          </p>
        </div>
      )}
    </div>
  );
}
