import { Box, Modal } from "@mui/material";
import React, { useState } from "react";
import connector from "../../images/Connector.png";
import DeleteButton from "../Buttons/DeleteButton";
import EditButton from "../Buttons/EditButton";
import DateAndTimeChip from "../Chips/DateAndTimeChip";
import SmallClientChip from "../SmallClientChip";
import EditQuickNote from "./EditQuickNote";
import DeleteNote from "./DeleteNote";
import ViewNote from "./ViewNote";
import TypeNoteChip from "../Chips/TypeNoteChip";
import { useNavigate } from "react-router-dom";

function NotesContainer(props) {
  //VARIABLES
  const styleLive = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: 600,
    borderRadius: 5,
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const styleEdit = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    height: "80%",
    borderRadius: 5,
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const styleDeleteModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    height: 260,
    borderRadius: 2,
    bgcolor: "background.paper",
    /*border: "1px solid #000",*/
    boxShadow: 24,
    // p: 4,
  };

  //STATES
  const navigate = useNavigate();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);

  const [shouldNavigate, setShouldNavigate] = React.useState(false);

  //FUNCTIONS
  const openEditModal = () => {
    setShowEditModal(true);
  };
  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };
  const openViewModal = () => {
    setShowViewModal(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const closeViewModal = () => {
    setShowViewModal(false);
  };

  const handleNavigate = () => {
    setShouldNavigate(true);
  };

  React.useEffect(() => {
    if (shouldNavigate) {
      navigate(`/client/${props.clientId}`, {
        state: props, // Passez l'objet client complet
      });
    }
  }, [shouldNavigate]);

  return (
    <div className="notes-wrapper">
      <Modal
        open={showEditModal}
        onClose={closeEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleEdit}>
          <EditQuickNote
            note={props.noteContent}
            noteId={props.noteId}
            typeOfNote={props.noteType}
            effectiveDate={props.effectiveDate}
            closemodal={closeEditModal}
          />
        </Box>
      </Modal>

      <Modal
        open={showDeleteModal}
        onClose={closeDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleDeleteModal}>
          <DeleteNote
            note={props.noteContent}
            noteId={props.noteId}
            typeOfNote={props.noteType}
            effectiveDate={props.effectiveDate}
            closemodal={closeDeleteModal}
          />
        </Box>
      </Modal>

      <Modal
        open={showViewModal}
        onClose={closeViewModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleLive}>
          <ViewNote
            note={props.noteContent}
            noteId={props.noteId}
            typeOfNote={props.noteType}
            effectiveDate={props.effectiveDate}
            closemodal={closeViewModal}
          />
        </Box>
      </Modal>
      <div className="note-container">
        <div
          className="note-container-block-1"
          style={{ width: "99px", height: "auto" }}
        >
          <DateAndTimeChip date={props.noteDate} />
          <DateAndTimeChip date={props.noteTime} />
          <TypeNoteChip noteType={props.noteType} />
        </div>
        <div className="note-container-block-2" style={{ width: "43px" }}>
          <img src={connector} height="260" />
        </div>
        <div className="note-container-block-3">
          <div className="note-container-line-1">
            <div
              className="note-container-line1-block1 centre-block"
              onClick={handleNavigate}
              style={{ cursor: "pointer", paddingLeft: "10px" }}
            >
              <SmallClientChip
                firstName={props.clientFirstName}
                lastName={props.clientLastName}
                avatar="1"
                clientPic={props.clientPic}
              />
            </div>
            <div className="note-container-line1-block1bis left-block">
              <div className="title-cell-container">Done by:</div>
              <div className="content-cell-container">{props.doneBy}</div>
            </div>
            <div className="note-container-line1-block2 left-block">
              <div className="title-cell-container">Record Timestamp:</div>
              <div className="content-cell-container">
                {props.timeStamp}
              </div>{" "}
              <span
                style={{ fontSize: "10px", textAlign: "left", width: "100%" }}
              >
                {props.lastModification &&
                  `(Last modification: ${props.lastModification})`}
              </span>
            </div>
            <div className="note-container-line1-block3-bis centre-block2">
              <div className="sub-block-2">
                {/* <ViewButton handleOpen={openViewModal} /> */}
                {/* <PrintButton /> */}
                <EditButton handleOpen={openEditModal} />
                <DeleteButton handleOpen={openDeleteModal} />
              </div>
            </div>
          </div>
          <div
            className="note-container-line-2"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {props.noteContent}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotesContainer;
