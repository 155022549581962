import React, { useContext, useEffect, useState } from "react";
import "../css/dashboard.css";
import "../css/client.css";
import { Box, Button, Modal } from "@mui/material";
import AddClient from "../component/forms/AddClient";
import {
  collection,
  documentId,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import { roles } from "../firebase.collection";
import AddQuickNoteWithClients from "../component/Notes/AddQuickNoteWithClients";
import { db } from "../firebase";
import { format } from "date-fns";
import TopTitleBannerV2 from "../component/TopTitleBannerV2";
import MetricsWidgetV2 from "../component/MetricsWidgetV2";
import MetricsWidgetV3 from "../component/MetricsWidgetV3";
import { useNavigate } from "react-router-dom";
import NotesContainerCompact from "../component/Notes/NotesContainerCompact";
import TitleNoteCompact from "../component/Notes/TitleNoteCompact";
import { LanguageContext } from "../context/LanguageContext";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Joyride, { ACTIONS, STATUS } from "react-joyride";

export default function HomepageContent() {
  const { translate } = useContext(LanguageContext);
  const [notes, setNotes] = useState([]);
  const [, setReadCount] = useState(0);
  const { currentUser } = useAuth();
  const userIdState = currentUser ? currentUser.uid : "";
  const [userRole, setUserRole] = useState("");
  const [noteTypes, setNoteTypes] = useState([]);
  const [selectedClientName, setSelectedClientName] = useState("");
  const [clientNames, setClientNames] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedNoteType, setSelectedNoteType] = useState([]);
  const [clients, setClients] = useState([]);
  const [clientsIncoming, setClientsIncoming] = useState([]);
  const [nextDischargeClient, setNextDischargeClient] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingIncoming, setIsLoadingIncoming] = useState(true);
  const [firestoreReadCount, setFirestoreReadCount] = useState(0);
  const [incomingClients, setIncomingClients] = useState(0);
  const [potentialClients, setPotentialClients] = useState(0);
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [isLoadingNotes, setIsLoadingNotes] = useState(true);
  const [notesLimit, setNotesLimit] = useState(30); // Commence par requêter 10 notes
  const [visibleNotesCount, setVisibleNotesCount] = useState(7); // Initialement afficher 7 notes

  const [run, setRun] = useState(false); // Pour démarrer ou arrêter Joyride
  const [showHelpButton, setShowHelpButton] = useState(true);

  const handleHelpClick = () => {
    setRun(true);
    setShowHelpButton(false); // Cache le bouton "Help" après le clic
  };

  const handleJoyrideCallback = (data) => {
    const { status, action } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      setShowHelpButton(true);
    } else if ([ACTIONS.CLOSE].includes(action)) {
      setRun(false);
      setShowHelpButton(true);
    }
  };

  const customStyles = {
    options: {
      zIndex: 10000,
    },
    tooltip: {
      backgroundColor: "#fff",
      color: "#000",
      animation: "fadeIn 0.3s ease-in-out",
    },
    tooltipContent: {
      textAlign: "justify",
      marginTop: "10px",
    },
    beacon: {
      offsetY: -50,
    },
    buttonNext: {
      backgroundColor: "#1565c0",
      color: "#fff",
    },
    buttonBack: {
      color: "blue",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 1000,
    },
    beaconInner: {
      backgroundColor: "#1565c0",
      borderColor: "#1565c0",
    },
    beaconOuter: {
      backgroundColor: "rgba(21, 101, 192, 0.5)",
      borderColor: "rgba(21, 101, 192, 0.5)",
    },
  };

  const steps = [
    {
      target: "#metrics-inhouse2", // Assurez-vous d'ajouter cette classe au widget In-House
      content:
        "This widget displays the total number of clients currently residing in the facility. Click on it to view the detailed list of all in-house clients.",
    },
    {
      target: "#metrics-inhouse", // Assurez-vous d'ajouter cette classe au widget In-House
      content: translate("InHouseClientsInfo"),
    },
    {
      target: "#metrics-next-departure", // Assurez-vous d'ajouter cette classe au widget Next Departure
      content: translate("NextClientDepartureInfo"),
    },
    {
      target: "#metrics-next-incoming", // Assurez-vous d'ajouter cette classe au widget Next Incoming
      content: translate("NextIncomingClientInfo"),
    },
    {
      target: "#metrics-incoming", // Assurez-vous d'ajouter cette classe au widget Incoming
      content: translate("UpcomingClientsInfo"),
    },
  ];

  const incrementFirestoreReadCount = (incrementBy = 1) => {
    setFirestoreReadCount((prevCount) => prevCount + incrementBy);
  };

  const handleClientClick = (clientId) => {
    window.open(`/client/${clientId}`, "_blank");
  };

  const navigate = useNavigate();

  const handleWidgetClick = (selectedTab) => {
    navigate("/clients", { state: { selectedTab } });
  };

  // console.log("isLoadingNotes: ", isLoadingNotes);

  const handleSort = (order) => {
    let sortedNotes = [...notes];
    switch (order) {
      case "dateAsc":
        sortedNotes.sort(
          (a, b) => a.effectiveDateTime.toDate() - b.effectiveDateTime.toDate()
        );
        break;
      case "dateDesc":
        sortedNotes.sort(
          (a, b) => b.effectiveDateTime.toDate() - a.effectiveDateTime.toDate()
        );
        break;
      case "clientAsc":
        sortedNotes.sort((a, b) =>
          (a.clientFirstName + " " + a.clientLastName).localeCompare(
            b.clientFirstName + " " + b.clientLastName
          )
        );
        break;
      case "clientDesc":
        sortedNotes.sort((a, b) =>
          (b.clientFirstName + " " + b.clientLastName).localeCompare(
            a.clientFirstName + " " + a.clientLastName
          )
        );
        break;
      case "typeAsc":
        sortedNotes.sort((a, b) => a.noteType.localeCompare(b.noteType));
        break;
      case "typeDesc":
        sortedNotes.sort((a, b) => b.noteType.localeCompare(a.noteType));
        break;
      default:
        break;
    }
    setNotes(sortedNotes);
  };

  useEffect(() => {
    setIsLoading({
      ...isLoading,
      inHouse: true,
      incoming: true,
      potential: true,
    });

    const queryRef = query(
      collection(db, "clients"),
      where("clientStatus", "==", "In House")
    );

    const unsubscribe = onSnapshot(
      queryRef,
      async (snapshot) => {
        await sleep(500);
        const clientsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setClients(clientsData);

        // Fetch incoming clients count
        const incomingQueryRef = query(
          collection(db, "clients"),
          where("clientStatus", "==", "Incoming")
        );

        const potentialQueryRef = query(
          collection(db, "clients"),
          where("clientStatus", "==", "Potential")
        );

        const [incomingSnapshot, potentialSnapshot] = await Promise.all([
          getDocs(incomingQueryRef),
          getDocs(potentialQueryRef),
        ]);

        setIncomingClients(incomingSnapshot.size);
        setPotentialClients(potentialSnapshot.size);

        setIsLoading({
          inHouse: false,
          incoming: false,
          potential: false,
        });
        incrementFirestoreReadCount(snapshot.docs.length);
      },
      (error) => {
        console.error("Failed to fetch clients:", error);
        setIsLoading({
          inHouse: false,
          incoming: false,
          potential: false,
        });
      }
    );

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  useEffect(() => {
    setIsLoadingIncoming(true);

    const clientQuery = query(
      collection(db, "clients"),
      where("clientStatus", "==", "Incoming")
    );

    const unsubscribe = onSnapshot(
      clientQuery,
      async (snapshot) => {
        const clientsIncomingData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setClientsIncoming(clientsIncomingData);
        setIsLoadingIncoming(false);
        incrementFirestoreReadCount(snapshot.docs.length);
      },
      (error) => {
        console.error("Failed to fetch Incoming clients:", error);
        setIsLoadingIncoming(false);
      }
    );

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  useEffect(() => {
    if (clients.length > 0) {
      const now = new Date();
      const closestClient = clients.reduce((closest, client) => {
        const dischargeDate = new Date(
          client.projectedResidentialDischargeDate?.seconds * 1000
        );

        if (isNaN(dischargeDate.getTime()) || dischargeDate <= now) {
          return closest; // Ignore invalid or past dates
        }
        if (
          closest === null ||
          dischargeDate <
            new Date(closest.projectedResidentialDischargeDate?.seconds * 1000)
        ) {
          return client;
        }
        return closest;
      }, null);

      setNextDischargeClient(closestClient);
    }
  }, [clients]);

  function formatDate2(date) {
    if (!date || isNaN(date.getTime())) {
      console.log("Invalid date provided to formatDate");
      return "Invalid date"; // Retourner une chaîne indiquant que la date est invalide
    }
    try {
      return format(date, "dd MMMM yyyy"); // Format correct avec date-fns
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Formatting error";
    }
  }

  useEffect(() => {
    const notesRef = collection(db, "notes");

    const getSortingDate = (note) => {
      const sortingDate =
        note.noteType === "endOfShift" ? note.shiftEnd : note.effectiveDateTime;

      if (!sortingDate || typeof sortingDate.toDate !== "function") {
        console.error(`Invalid date for note ID: ${note.id}`);
        return new Date(0); // Return a default date or handle appropriately
      }

      return sortingDate.toDate();
    };

    const q = query(notesRef, limit(notesLimit));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let notesData = [];
      const types = new Set();
      // console.log("Snapshot received:", querySnapshot.size, "documents");

      querySnapshot.forEach((doc) => {
        const noteData = { id: doc.id, ...doc.data() };
        // console.log("Note data:", noteData);

        notesData.push(noteData);
        types.add(noteData.noteType);
      });

      const aftercareNotes = notesData.filter(
        (note) => note.noteType === "aftercareNote"
      );

      notesData = notesData.sort((a, b) => {
        const dateA = getSortingDate(a);
        const dateB = getSortingDate(b);
        return dateB - dateA; // Tri décroissant
      });

      console.log("All notes sorted by effectiveDateTime:");
      notesData.forEach((note) => {});

      setNotes(notesData);
      setNoteTypes(Array.from(types));
      setReadCount((prevCount) => prevCount + querySnapshot.size);
      incrementFirestoreReadCount(querySnapshot.size);
      setIsLoadingNotes(false);
    });

    return () => {
      unsubscribe();
    };
  }, [notesLimit]);

  // const handleShowMoreNotes = () => {
  //   setNotesLimit((prevLimit) => prevLimit + 10); // Requêter 10 notes supplémentaires
  // };
  const handleShowMoreNotes = () => {
    setVisibleNotesCount((prevCount) => prevCount + 10); // Ajouter 10 notes supplémentaires
  };

  useEffect(() => {
    console.log(`Total Firestore reads: ${firestoreReadCount}`);
  }, [firestoreReadCount]);

  const handleStartDateChange = (newValue) => {
    if (endDate && newValue > endDate) {
      setEndDate(newValue);
    }
    setStartDate(newValue);
  };

  const handleEndDateChange = (newValue) => {
    if (startDate && newValue < startDate) {
      setStartDate(newValue);
    }
    setEndDate(newValue);
  };

  useEffect(() => {
    if (selectedNoteType.length > 0) {
      const filteredNotesByType = notes.filter((note) =>
        selectedNoteType.includes(note.noteType)
      );
      const uniqueClientNames = Array.from(
        new Set(
          filteredNotesByType.map(
            (note) => note.clientFirstName + " " + note.clientLastName
          )
        )
      );
      setClientNames(uniqueClientNames);
    } else {
      // Si aucun type de note n'est sélectionné, afficher tous les clients
      const allClientNames = Array.from(
        new Set(
          notes.map((note) => note.clientFirstName + " " + note.clientLastName)
        )
      );
      setClientNames(allClientNames);
      // console.log("clientNames:", clientNames);
    }
  }, [selectedNoteType, notes]);

  const filteredNotes = notes.filter((note) => {
    const matchesType = selectedNoteType.length
      ? selectedNoteType.includes(note.noteType)
      : true;
    const matchesClient = selectedClientName
      ? note.clientFirstName + " " + note.clientLastName === selectedClientName
      : true;
    const matchesDate =
      startDate && endDate
        ? note.effectiveDateTime &&
          note.effectiveDateTime.toDate() >= startDate &&
          note.effectiveDateTime.toDate() <= endDate
        : true;
    return matchesType && matchesClient && matchesDate;
  });

  const styleModalGeneral = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    height: "80%",
    borderRadius: 5,
    // overflowY: "scroll",
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const styleModalQuickNote = {
    ...styleModalGeneral, // Vous pouvez réutiliser le style de la modal générale
  };

  const [openAddClient, setOpenAddClient] = useState(false);
  const [openAddQuickNote, setOpenAddQuickNote] = useState(false);

  // const handleOpenAddClient = () => {
  //   setOpenAddClient(true);
  //   recordMetrics("Clients", "addClient", currentUser.email, "write", "bodhi");
  // };

  const handleCloseAddClient = () => setOpenAddClient(false);

  const handleCloseAddQuickNote = () => setOpenAddQuickNote(false);

  function queryRole() {
    onSnapshot(
      query(roles, where(documentId(), "==", userIdState)),
      (querySnapshot) => {
        const doc = querySnapshot.docs[0];
        if (doc && doc.exists) {
          const role = doc.data().role;
          setUserRole(role);
        } else {
          console.log("No role for this user");
        }
      }
    );
  }

  useEffect(() => {
    if (currentUser) {
      queryRole();
      // recordMetrics("Clients", "pageLoad", currentUser.email, "read", "bodhi");
    } else {
      console.log("No user id");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const formatDate = (date, options) => {
    return date
      ? new Intl.DateTimeFormat("en-EN", options).format(date.toDate())
      : "";
  };

  const handleShowLessNotes = () => {
    setVisibleNotesCount((prevCount) => Math.max(prevCount - 10, 7)); // Réduire de 10 notes, mais ne pas descendre en dessous de 7
  };

  // console.log("notes:", notes);

  const renderMetricsWidgets = () => {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          id="metrics-inhouse"
          style={{
            marginRight: "40px",
            display: "flex",
            flexDirection: "row",
            width: "auto",
            cursor: "pointer",
          }}
          onClick={() => handleWidgetClick("In house")}
        >
          <MetricsWidgetV2
            title="# INHOUSE "
            value={clients.length.toString()}
            title2={
              clients.length > 1 ? translate("Clients") : translate("Client")
            }
            textColor="black"
            textColor2="#7a8199"
            iconName="People"
            isLoading={isLoading.inHouse}
          />
        </div>

        <div
          style={{ borderRight: "1px solid #cbcbcd", marginRight: "40px" }}
        ></div>

        <div
          id="metrics-next-departure"
          style={{
            marginRight: "40px",
            width: "auto",
          }}
        >
          <MetricsWidgetV3
            title={translate("NextDeparture")}
            value={
              nextDischargeClient ? (
                <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    fontSize: "22px",
                  }}
                  onClick={() => handleClientClick(nextDischargeClient.id)}
                >
                  {`${nextDischargeClient.firstName} ${nextDischargeClient.lastName}`}
                </span>
              ) : (
                <div style={{ fontSize: "22px" }}>
                  {translate("NoDischarges")}
                </div>
              )
            }
            date={
              nextDischargeClient
                ? formatDate2(
                    new Date(
                      nextDischargeClient.projectedResidentialDischargeDate
                        ?.seconds * 1000
                    )
                  )
                : ""
            }
            textColor="black"
            textColor2="#7a8199"
            iconName="Flight"
            isLoading={isLoading.inHouse}
          />
        </div>
        <div
          style={{ borderRight: "1px solid #cbcbcd", marginRight: "40px" }}
        ></div>
        <div
          id="metrics-next-incoming"
          style={{ marginRight: "40px", width: "230px" }}
        >
          <MetricsWidgetV3
            title={translate("NextIncoming")}
            value={
              clientsIncoming.length > 0 ? (
                <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    fontSize: "22px",
                  }}
                  onClick={() => handleClientClick(clientsIncoming[0].id)}
                >
                  {`${clientsIncoming[0].firstName} ${clientsIncoming[0].lastName}`}
                </span>
              ) : (
                translate("NoUpcomingClient")
              )
            }
            date={
              clientsIncoming.length > 0
                ? formatDate2(new Date(clientsIncoming[0].eta?.seconds * 1000))
                : ""
            }
            textColor="black"
            textColor2="#7a8199"
            iconName="Event"
            isLoading={isLoading.incoming}
          />
        </div>
        <div
          style={{ borderRight: "1px solid #cbcbcd", marginRight: "40px" }}
        ></div>

        <div
          id="metrics-incoming"
          style={{
            marginRight: "40px",
            display: "flex",
            flexDirection: "row",
            cursor: "pointer",
            width: "auto",
          }}
          onClick={() => handleWidgetClick("Incoming")}
        >
          <MetricsWidgetV2
            title={translate("Incoming")}
            value={incomingClients.toString()}
            title2={
              incomingClients > 1 ? translate("Clients") : translate("Client")
            }
            textColor="black"
            textColor2="#7a8199"
            iconName="People"
            isLoading={isLoading.incoming}
          />
        </div>

        <div>
          <HelpOutlineIcon
            style={{
              cursor: "pointer",
              marginRight: "10px",
              color: "#a9a9a9",
            }}
            onClick={handleHelpClick}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="right-container">
      <Joyride
        steps={steps}
        continuous
        scrollToFirstStep
        showProgress={false} // Empêche d'afficher le numéro d'aide en cours
        showSkipButton
        run={run}
        callback={handleJoyrideCallback}
        disableScrolling={true}
        disableBeacon={true}
        styles={customStyles}
      />

      <Modal
        open={openAddClient}
        onClose={handleCloseAddClient}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalGeneral}>
          <AddClient closemodal={handleCloseAddClient} />
        </Box>
      </Modal>
      <Modal
        open={openAddQuickNote}
        onClose={handleCloseAddQuickNote}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalQuickNote}>
          <AddQuickNoteWithClients closemodal={handleCloseAddQuickNote} />
        </Box>
      </Modal>
      <TopTitleBannerV2 title={"Dashboard"} />

      <div
        className="top-component-list-clients"
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          {renderMetricsWidgets()}
        </div>

        <div
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          {translate("LastClientNotes")}
        </div>
      </div>
      <div
        style={{
          // backgroundColor: "yellow",
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          paddingLeft: "60px",
          overflow: "hidden",
        }}
      >
        <div style={{ width: "90%" }}>
          <TitleNoteCompact handleSort={handleSort} />
        </div>

        <div
          style={{
            width: "90%",
            maxHeight: "570px",
            overflow: isLoadingNotes ? "hidden" : "auto",
            // backgroundColor: "blue"
          }}
        >
          {isLoadingNotes
            ? Array.from({ length: 5 }).map((_, index) => (
                <NotesContainerCompact key={index} isSkeleton={true} />
              ))
            : // : filteredNotes.map((note, index) => {
              filteredNotes.slice(0, visibleNotesCount).map((note, index) => {
                const dateOptions = {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                };

                const dateOptions2 = {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                };

                const timeOptions = {
                  hour: "2-digit",
                  minute: "2-digit",
                };

                const formattedTimestamp = note.createdAt
                  ? new Intl.DateTimeFormat("en-EN", dateOptions2).format(
                      note.createdAt.toDate()
                    )
                  : "";

                const formattedLastModification = note.updateAt
                  ? new Intl.DateTimeFormat("en-EN", dateOptions2).format(
                      note.updateAt.toDate()
                    )
                  : "";

                const formattedDate = note.effectiveDateTime
                  ? new Intl.DateTimeFormat("en-EN", dateOptions).format(
                      note.effectiveDateTime.toDate()
                    )
                  : "";

                const formattedTime = note.effectiveDateTime
                  ? new Intl.DateTimeFormat("en-EN", timeOptions).format(
                      note.effectiveDateTime.toDate()
                    )
                  : "";

                const noteDate = formatDate(note.shiftStart, dateOptions);
                const noteTime = formatDate(note.shiftStart, timeOptions); // Assurez-vous que c'est bien le champ que vous voulez utiliser
                const noteDateEnd = formatDate(note.shiftEnd, dateOptions);
                const noteDateEndTime = formatDate(note.shiftEnd, timeOptions);

                switch (note.noteType) {
                  case "quickNote":
                  case "nonTherapyNote":
                  case "therapyNote":
                  case "supportStaffNote":
                  case "aftercareNote":
                    return (
                      <NotesContainerCompact
                        key={note.id}
                        noteContent={note.noteContent}
                        effectiveDate={note.effectiveDateTime}
                        noteDate={formattedDate}
                        noteTime={formattedTime}
                        doneBy={note.doneBy}
                        timeStamp={formattedTimestamp}
                        lastModification={formattedLastModification}
                        clientPic={note.clientProfilePicture}
                        clientFirstName={note.clientFirstName}
                        clientLastName={note.clientLastName}
                        noteId={note.id}
                        {...note}
                      />
                    );

                  case "endOfShift":
                    return (
                      <NotesContainerCompact
                        key={note.id}
                        noteContent={note.noteContent}
                        shiftStartRaw={note.shiftStart}
                        shiftEndRaw={note.shiftEnd}
                        shiftStart={noteDate}
                        shiftEnd={noteDateEnd}
                        staff={note.staff}
                        doneBy={note.doneBy}
                        noteId={note.id}
                        noteType={note.noteType}
                        noteDate={noteDate} // Date formatée
                        noteTime={noteTime} // Heure formatée
                        noteDateEndTime={noteDateEndTime}
                      />
                    );

                  case "familyNote":
                    return (
                      <NotesContainerCompact
                        key={note.id}
                        noteContent={note.noteContent}
                        effectiveDate={note.effectiveDateTime}
                        noteDate={formattedDate}
                        noteTime={formattedTime}
                        doneBy={note.doneBy}
                        timeStamp={formattedTimestamp}
                        lastModification={formattedLastModification}
                        clientPic={note.clientProfilePicture}
                        clientFirstName={note.clientFirstName}
                        clientLastName={note.clientLastName}
                        noteId={note.id}
                        relationship={note.relationShipFamilyNote}
                        fileUrls={note.fileUrls}
                        {...note}
                      />
                    );

                  case "medicalNote":
                    return (
                      <NotesContainerCompact
                        key={note.id}
                        noteContent={note.noteContent}
                        effectiveDate={note.effectiveDateTime}
                        noteDate={formattedDate}
                        noteTime={formattedTime}
                        doneBy={note.doneBy}
                        timeStamp={formattedTimestamp}
                        lastModification={formattedLastModification}
                        clientPic={note.clientProfilePicture}
                        clientFirstName={note.clientFirstName}
                        clientLastName={note.clientLastName}
                        noteId={note.id}
                        fileUrls={note.fileUrls}
                        {...note}
                      />
                    );
                  case "handoverNote":
                    return (
                      <NotesContainerCompact
                        key={note.id}
                        // noteContent={note.noteContent}
                        effectiveDateTime={note.effectiveDateTime}
                        noteDate={formattedDate}
                        noteTime={formattedTime}
                        doneBy={note.doneBy}
                        timeStamp={formattedTimestamp}
                        lastModification={formattedLastModification}
                        noteId={note.id}
                        immmigrationAppointment={note.immmigrationAppointment}
                        dischargesSwot={note.dischargesSwot}
                        newClients={note.newClients}
                        medicalInfos={note.medicalInfos}
                        hospitalAppointment={note.hospitalAppointment}
                        residentialClientMovements={
                          note.residentialClientMovements
                        }
                        zoomSessionAssistance={note.zoomSessionAssistance}
                        updateAt={note.updateAt}
                        createdAt={note.createdAt}
                        staffToday={note.staffToday}
                        generalAnnouncement={note.generalAnnouncement}
                        noteContent={note.generalAnnouncement}
                        {...note}
                      />
                    );

                  case "groupNote":
                    return (
                      <NotesContainerCompact
                        key={note.id}
                        noteContent={note.noteContent}
                        effectiveDateTime={note.effectiveDateTime}
                        noteDate={formattedDate}
                        noteTime={formattedTime}
                        doneBy={note.doneBy}
                        timeStamp={formattedTimestamp}
                        lastModification={formattedLastModification}
                        clientPic={note.clientProfilePicture}
                        clientFirstName={note.clientFirstName}
                        clientLastName={note.clientLastName}
                        noteId={note.id}
                        groupTopic={note.groupTopic}
                        groupType={note.typeGroupNote}
                        notInAttendance={note.notInAttendance}
                        updateAt={note.updateAt}
                        createdAt={note.createdAt}
                        groupStartDateTime={note.groupStartDateTime}
                        {...note}
                      />
                    );

                  default:
                    return null;
                }
              })}
        </div>

        <div
          style={{
            width: "20%",
            // backgroundColor: "red",
            display: "flex",
            justifyContent: "flex-start",
            alignContent: "center",
            paddingTop: "30px",
            alignItems: "center",
            flexDirection: "column",
          }}
        ></div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {!isLoadingNotes && notes.length > visibleNotesCount && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "10px",
              paddingLeft: "60px",
            }}
          >
            <Button
              onClick={handleShowMoreNotes}
              variant="outlined"
              style={{
                padding: "5px 10px",
                fontSize: "12px",
                cursor: "pointer",
              }}
            >
              {translate("ShowMoreNotes")}
            </Button>
          </div>
        )}

        {!isLoadingNotes && visibleNotesCount > 7 && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "10px",
              paddingLeft: "60px",
            }}
          >
            <Button
              onClick={handleShowLessNotes}
              variant="outlined"
              style={{
                padding: "5px 10px",
                fontSize: "12px",
                cursor: "pointer",
              }}
            >
              {translate("ShowLessNotes")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
