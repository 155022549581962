import React, { useContext } from "react";
import "../css/dashboard.css";
import TopTitleBannerV2 from "../component/TopTitleBannerV2";
import { LanguageContext } from "../context/LanguageContext";
import Tickets from "../component/Misc/Tickets";

export default function SupportContent() {
  const { translate } = useContext(LanguageContext);
  return (
    <div className="right-container">
      <TopTitleBannerV2 title={translate("Support")} />
      <Tickets />
    </div>
  );
}
