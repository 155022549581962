import React, { useState } from "react";
import { Button, Checkbox } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import SignaturePadComponent from "../Signature/SignaturePadComponent";
import TopTitleFormComponent from "./TopTitleFormComponent";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { updateDoc, doc as firestoreDoc } from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadString,
} from "firebase/storage";
import { showCustomToast } from "../Misc/CustomToast";
import SignaturePadComponentV2 from "../Signature/SignaturePadComponentV2";

function AddPsychiatristServicesForm(props) {
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [signatureDataURL, setSignatureDataURL] = useState(null);
  const { clientId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isSignatureValidated, setIsSignatureValidated] = useState(false);
  const text1 =
    "The cost of any detox medication is an additional cost not included in the original program package cost. You will be billed for, and expected to pay this cost, whilst in treatment.";

  const text2 =
    "All clients will receive an initial consultation with our international psychiatrist as part of our treatment program package. Any subsequent consultation session with our international psychiatrist (if agreed upon by the client to pursue) is an additional cost to the cost of the original program package. Clients will be responsible for the cost of any prescribed medications. The cost for a consultation is USD 300 for a 45-minute consultation session. The USD 300 also applies to any consultation sessions less than 45 minutes. You will be billed for, and expected to pay this cost, whilst in treatment. How many sessions you will need with our psychiatrist depends on the complexity of your detox and/or any co-occurring disorders you have either been previously diagnosed with or you present with whilst in treatment.";

  const text3 =
    "I understand the above and agree to promptly pay for any of the above mentioned costs prior to discharging from our rehab.";

  const [, setIsSignatureStarted] = useState(false);

  const handleCheckboxChange1 = (event) => {
    setChecked1(event.target.checked);
  };

  const handleCheckboxChange2 = (event) => {
    setChecked2(event.target.checked);
  };

  const handleCheckboxChange4 = (event) => {
    setChecked4(event.target.checked);
  };

  const handleSignatureSave = (dataURL) => {
    setSignatureDataURL(dataURL);
  };

  const uploadSignature = async () => {
    const storage = getStorage();
    const fileName = `${clientId}.png`;
    const signatureRef = ref(
      storage,
      `MandatoryForms/PsychiatristServices/${fileName}`
    ); // Modifiez cette ligne

    await uploadString(signatureRef, signatureDataURL, "data_url");
    const downloadURL = await getDownloadURL(signatureRef);

    return downloadURL;
  };

  const handleFormSubmit = async () => {
    if (
      !checked1 ||
      !checked2 ||
      !checked4 ||
      !signatureDataURL ||
      !isSignatureValidated
    ) {
      showCustomToast(
        "Please agree to all the terms before submitting.",
        "error"
      );
    }

    try {
      setIsLoading(true);
      const signatureURL = await uploadSignature();
      const docRef = doc(db, "PsychiatristServices", props.clientId);
      await setDoc(docRef, {
        checked1,
        checked2,
        // checked3,
        checked4,
        text1,
        text2,
        text3,
        signatureURL,
        signedOn: serverTimestamp(),
      });

      const clientDocRef = firestoreDoc(db, "clients", props.clientId);
      await updateDoc(clientDocRef, {
        PsychiatristServicesOk: true,
        PsychiatristServicesDateSigned: serverTimestamp(),
      });
      showCustomToast(
        "Psychiatrist Services form correctly submitted!",
        "success"
      );

      setIsLoading(false);
      props.closemodal();
    } catch (e) {
      showCustomToast("Error adding document", "error");
      console.error("Error adding document: ", e);
    }
  };

  const allChecked = checked1 && checked2 && checked4;

  return (
    <>
      <div
        // className="inputAndLabelModalContainerTitle2"
        style={{ width: "auto", height: "100%" }}
      >
        <div className="line1-modal-percent">
          <TopTitleFormComponent
            title="PSYCHIATRIC SERVICES COSTS FORM"
            closemodal={props.closemodal}
          />
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "3px",
            height: "80%",
            overflowY: "scroll",
            // overflowX: "hidden",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <div className="SubTitleBold">
            By signing this document you acknowledge that you understand and
            agree to the following points:
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked1} onChange={handleCheckboxChange1} />
            <div className="block-text-check">{text1}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked2} onChange={handleCheckboxChange2} />
            <div className="block-text-check">{text2}</div>
          </div>
          {/* <div className="checkbox-container-margin">
          <Checkbox checked={checked3} onChange={handleCheckboxChange3} />
          <div className="block-text-check">
            I understand that the Psychiatrist (online) bills at US$300 (three
            hundred dollars) per session and that this is my responsibility for
            payment due to the extra level of medical supervision required by me
            for my detoxification process.
          </div>
        </div> */}

          <div className="SubTitleBold">Acknowledgement</div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked4} onChange={handleCheckboxChange4} />
            <div className="block-text-check">{text3}</div>
          </div>

          <div style={{ width: "100%", marginBottom: "25px" }}>
            <SignaturePadComponentV2
              onSignatureSave={handleSignatureSave}
              signatureDataURL={signatureDataURL}
              onSignatureStart={() => setIsSignatureStarted(true)}
              onSignatureValidate={setIsSignatureValidated}
            />
          </div>
        </div>
        <div className="line4-modal-percent-bis">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
              onClick={props.closemodal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleFormSubmit}
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : !signatureDataURL || !isSignatureValidated || !allChecked
                  ? {
                      backgroundColor: "grey",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                    }
              }
              disabled={
                isLoading ||
                !signatureDataURL ||
                !allChecked ||
                !isSignatureValidated
              }
            >
              {isLoading ? "SAVING..." : "SAVE"}
            </Button>
          </>
        </div>
      </div>
    </>
  );
}

export default AddPsychiatristServicesForm;
