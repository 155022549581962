import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import DropDownComponent from "../DropDownComponent";
import InputComponent from "../InputComponent";
import TextFieldComponentLarge from "../TextFieldComponentLarge";
import RatingComponent from "../RatingComponent";
import TopTitleFormComponent from "./TopTitleFormComponent";
import SignaturePadComponent from "../Signature/SignaturePadComponent";
import DatePickerComponent from "../DatePickerComponent";
import { useAuth } from "../../contexts/AuthContext";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadString,
} from "firebase/storage";
import { Button } from "@mui/material";
import { db } from "../../firebase";
import {
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { updateDoc, doc as firestoreDoc } from "firebase/firestore";
import { showCustomToast } from "../Misc/CustomToast";
import DropDownAllStaffs from "../Dropdowns/DropDownAllStaffs";
import { useEffect } from "react";
import SignaturePadComponentNoMargin from "../Signature/SignaturePadComponentNoMargin";
import SignaturePadComponentV2 from "../Signature/SignaturePadComponentV2";

function AddBioPsycho3(props) {
  //STATES

  const { currentUser } = useAuth();
  const [clientIdState, setClientIdState] = useState(props.clientId);
  const [isLoading, setIsLoading] = useState(false);
  const [dischargeDate, setDischargeDate] = useState(null);
  const [submittedDate, setSubmittedDate] = useState(null);
  const [dischargeType, setDischargeType] = useState("");
  const [dischargeTo, setDischargeTo] = useState("");
  const [medication, setMedication] = useState("");
  const [staffMemberName, setStaffMemberName] = useState(
    currentUser.displayName
  );
  const [signatureData, setSignatureData] = useState(null);
  const [rating, setRating] = useState(0);
  const [signatureDataURL, setSignatureDataURL] = useState(null);
  const [isSignatureStarted, setIsSignatureStarted] = useState(false);
  const [isSignatureValidated, setIsSignatureValidated] = useState(false);
  const [selectedTherapist, setSelectedTherapist] = useState("");
  const [dateLastModified, setDateLastModified] = useState(null);

  //FUNCTIONS

  const handleSelectedTherapistChange = (value) => {
    setSelectedTherapist(value);
  };

  const handleSubmittedDateChange = (event) => {
    setSubmittedDate(event);
  };

  const handleMedicationChange = (event) => {
    setMedication(event);
  };

  const handleDateLastModified = (value) => {
    setDateLastModified(value);
  };

  const handleSignatureSave = (dataURL) => {
    setSignatureDataURL(dataURL);
  };

  const uploadSignature = async () => {
    const storage = getStorage();
    const fileName = `${clientIdState}.png`;
    const signatureRef = ref(storage, `DischargesReport/${fileName}`);

    await uploadString(signatureRef, signatureDataURL, "data_url");
    const downloadURL = await getDownloadURL(signatureRef);

    return downloadURL;
  };

  useEffect(() => {
    const loadData = async () => {
      const dischargeFormsRef = collection(db, "bioPsychoForms");
      const dischargeFormDocRef = doc(dischargeFormsRef, clientIdState);
      const docData = await getDoc(dischargeFormDocRef);

      if (docData.exists()) {
        const data = docData.data();
        setClientIdState(data.clientIdState || props.clientId);
        setDischargeDate(data.dischargeDate || null);
        setSubmittedDate(data.submittedDate || null);
        setMedication(data.medication || "");
        setStaffMemberName(data.staffMemberName || currentUser.displayName);
        setSignatureData(data.signatureData || null);
        setSignatureDataURL(data.signatureDataURL || null);
        setIsSignatureStarted(data.isSignatureStarted || false);
        setIsSignatureValidated(data.isSignatureValidated || false);
        setSelectedTherapist(data.selectedTherapist || "");
        setDateLastModified(data.dateLastModified || null);
      }
    };

    loadData();
  }, [clientIdState]); // Dependency array includes clientIdState since it's referenced inside the effect

  const [isSavingModifications, setIsSavingModifications] = useState(false);

  const handleSaveModifications = async () => {
    setIsSavingModifications(true);

    try {
      const signatureURL = signatureDataURL ? await uploadSignature() : null;

      const dischargeData = {
        submittedDate,
        medication,
        staffMemberName,
        selectedTherapist,
        signatureData: signatureURL,
        dateLastModified,
      };

      const dischargeFormsRef = collection(db, "bioPsychoForms");
      const dischargeFormDocRef = doc(dischargeFormsRef, clientIdState);

      await setDoc(dischargeFormDocRef, dischargeData);

      showCustomToast("Modifications saved successfully!", "success");

      setIsLoading(false);
    } catch (error) {
      showCustomToast("Error saving modifications", "error");
      console.error("Error saving modifications:", error);
      setIsLoading(false);
    }
    setIsSavingModifications(false);
    props.closemodal();
  };

  const handleFormSubmit = async () => {
    if (submittedDate) {
      setIsLoading(true);

      try {
        const signatureURL = await uploadSignature();

        const dischargeData = {
          submittedDate,
          dischargeType,
          dischargeTo,
          medication,
          staffMemberName,
          selectedTherapist,
          signatureData: signatureURL,
          dateLastModified,
          signedOn: serverTimestamp(),
        };

        const dischargeFormsRef = collection(db, "bioPsychoForms");
        const dischargeFormDocRef = doc(dischargeFormsRef, clientIdState);

        await setDoc(dischargeFormDocRef, dischargeData);

        const clientDocRef = firestoreDoc(db, "clients", clientIdState);
        await updateDoc(clientDocRef, {
          bioPsychoFormOk: true,
          BioPsychoFormDateSigned: serverTimestamp(),
        });

        showCustomToast("Discharge form correctly submitted!", "success");

        setIsLoading(false);
        props.closemodal();
      } catch (error) {
        showCustomToast("Error submitting form", "error");

        console.error("Error submitting form:", error);
        setIsLoading(false);
      }
    } else {
      showCustomToast(
        "Some mandatory fields are missing, please check",
        "error"
      );

      console.error("All required fields must be filled out.");
    }
  };

  const isAddButtonDisabled =
    !submittedDate || !signatureDataURL || !isSignatureValidated;

  return (
    <>
      <div
        // className="inputAndLabelModalContainerTitle2"
        style={{ width: "auto", height: "100%" }}
      >
        <div className="line1-modal-percent">
          <TopTitleFormComponent
            title="BIOPSYCHOSOCIAL ASSESSMENT"
            closemodal={props.closemodal}
          />
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "3px",
            height: "80%",
            overflowY: "scroll",
            // paddingLeft: 30,
            // overflowX: "hidden",
          }}
        >
          <div className="tab-form-container-biopsycho">
            <DatePickerComponent
              title="Date submitted"
              width="300"
              isRequired={true}
              value={submittedDate}
              onChange={handleSubmittedDateChange}
            />

            <DatePickerComponent
              title="Last modified"
              width="150px"
              value={dateLastModified}
              onChange={handleDateLastModified}
            />
          </div>

          <div className="tab-form-container-biopsycho">
            <DropDownAllStaffs
              title="Assigned Therapist"
              value={selectedTherapist}
              width="auto"
              onChange={handleSelectedTherapistChange}
            />
          </div>

          <div className="tab-form-container-biopsycho">
            <TextFieldComponentLarge
              title="Description"
              value={medication}
              onChange={handleMedicationChange}
            />
          </div>
          <div
            style={{ width: "100%", marginBottom: "25px", marginTop: "20px" }}
          >
            <SignaturePadComponentV2
              onSignatureSave={handleSignatureSave}
              signatureDataURL={signatureDataURL}
              onSignatureStart={() => setIsSignatureStarted(true)}
              onSignatureValidate={setIsSignatureValidated}
            />
          </div>
        </div>
        <div className="line4-modal-percent-bis">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              onClick={props.closemodal}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              onClick={handleSaveModifications}
              style={{
                backgroundColor: isSavingModifications
                  ? "lightgrey"
                  : "#FFC107",
                color: "black",
                marginLeft: "15px",
              }}
              disabled={isSavingModifications}
            >
              {isSavingModifications
                ? "Saving Modifications..."
                : "Save Modifications"}
            </Button>

            <Button
              variant="contained"
              onClick={handleFormSubmit}
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : isAddButtonDisabled
                  ? {
                      backgroundColor: "lightgrey",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                    }
              }
              disabled={isLoading || isAddButtonDisabled}
            >
              {isLoading ? "SUBMITTING..." : "SUBMIT"}
            </Button>
          </>
        </div>
      </div>
    </>
  );
}

export default AddBioPsycho3;
