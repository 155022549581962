import { Box, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { documentId, onSnapshot, query, where } from "firebase/firestore";
import { roles } from "../firebase.collection";
import { useAuth } from "../contexts/AuthContext";
import IncomingClientAvailability from "./clientsList/IncomingClientAvailability";
import PotentialClientAvailability from "./clientsList/PotentialClientAvailability";
import ClinicalAssessmentClientAvailability from "./clientsList/ClinicalAssessmentClientAvailability";

function ClientsTopTab() {
  const { currentUser } = useAuth();
  const userIdState = currentUser ? currentUser.uid : "";
  const [value, setValue] = useState("1");
  const [, setUserRole] = useState("");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function queryRole() {
    onSnapshot(
      query(roles, where(documentId(), "==", userIdState)),
      (querySnapshot) => {
        const doc = querySnapshot.docs[0];
        if (doc && doc.exists) {
          const role = doc.data().role;
          setUserRole(role);
        } else {
          console.log("No role for this user");
        }
      }
    );
  }

  useEffect(() => {
    if (currentUser) {
      queryRole();
    } else {
      console.log("No user id");
    }
  }, [currentUser]);

  return (
    <div className="tab-client-container">
      <Box>
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              width: "1010px",
              paddingLeft: "45px",
              display: "flex",
              flexDirection: "row",
              marginRight: "0px",
              justifyContent: "space-between",
              paddingRight: "0px",
            }}
          >
            <TabList
              aria-label="Tabs example"
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              {/* <Tab label="In House" value="1" /> */}
              <Tab label="Incoming" value="1" />
              <Tab label="Potential" value="2" />
              <Tab label="Clinical Assessment" value="3" />
              {/* <Tab label="Chart" value="5" /> */}
            </TabList>
          </Box>
          {/* <TabPanel value="1">
            <InhouseClientAvailability />
          </TabPanel> */}
          <TabPanel value="1">
            <IncomingClientAvailability />
          </TabPanel>
          <TabPanel value="2">
            <PotentialClientAvailability />
          </TabPanel>
          <TabPanel value="3">
            <ClinicalAssessmentClientAvailability />
          </TabPanel>
          {/* <TabPanel value="5">
            <AvailabilityChart />
          </TabPanel> */}
        </TabContext>
      </Box>
    </div>
  );
}

export default ClientsTopTab;
