import React from "react";
import TopTitleBanner from "../component/TopTitleBanner";
import "../css/dashboard.css";
import UnderConstruction from "../component/Misc/UnderConstruction";

export default function MetricsContent() {
  return (
    <div className="right-container">
      <TopTitleBanner title={"Metrics"} />
      <UnderConstruction />
    </div>
  );
}
