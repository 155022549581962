import React, { useContext, useEffect, useState } from "react";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import dayjs from "dayjs";
import MonthPicker from "../Dropdowns/MonthPicker";
import { Button, CircularProgress, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Joyride, { ACTIONS, STATUS } from "react-joyride";
import { LanguageContext } from "../../context/LanguageContext";
import MonthPickerMobileV2 from "../Dropdowns/MonthPickerMobileV2";

function CheatsheetChartMobileV2(props) {
  const { translate } = useContext(LanguageContext);
  const [run, setRun] = useState(false);
  const [showHelpButton, setShowHelpButton] = useState(true);
  const [currentWeekStart, setCurrentWeekStart] = useState(
    dayjs().startOf("week")
  ); // Sunday
  const [currentWeekEnd, setCurrentWeekEnd] = useState(dayjs().endOf("week")); // Saturday

  const handleHelpClick = () => {
    setRun(true);
    setShowHelpButton(false);
  };

  const customStyles = {
    options: {
      zIndex: 10000,
    },
    tooltip: {
      backgroundColor: "#fff",
      color: "#000",
      animation: "fadeIn 0.3s ease-in-out",
    },
    tooltipContent: {
      textAlign: "justify",
      marginTop: "10px",
    },
    beacon: {
      offsetY: -50,
    },
    buttonNext: {
      backgroundColor: "#1565c0",
      color: "#fff",
    },
    buttonBack: {
      color: "blue",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 1000,
    },
    spotlight: {
      position: "absolute",
      borderRadius: 0,
    },

    beaconInner: {
      backgroundColor: "#1565c0",
      borderColor: "#1565c0",
    },
    beaconOuter: {
      backgroundColor: "rgba(21, 101, 192, 0.5)",
      borderColor: "rgba(21, 101, 192, 0.5)",
    },
  };

  const steps = [
    {
      target: "#nights-month",
      content:
        "This section shows the total number of nights booked for the current month.",
    },
    {
      target: "#los-to-date",
      content:
        "Here you can see the length of stay (LOS) to date for each client.",
    },
    {
      target: "#los-intended",
      content:
        "This section displays the intended length of stay (LOS) for each client.",
    },
    {
      target: "#payment-problem",
      content:
        "This area highlights any clients with payment problems. It corresponds to the -payment details- field in the pre-admission tab of the corresponding client profile.",
    },
    {
      target: "#total-nights-booked",
      content:
        "This shows the total number of nights booked across all clients.",
    },
    {
      target: "#total-incoming-booked",
      content:
        "Here you can find the total number of nights booked for incoming clients.",
    },
    {
      target: "#total-incoming-night",
      content:
        "This section displays the total number of nights for incoming clients.",
    },
    {
      target: "#total-potential-night",
      content:
        "This area shows the total potential nights that could be booked.",
    },
    {
      target: "#total-projected-night",
      content: "Here you can see the total projected number of nights booked.",
    },
  ];

  const handleJoyrideCallback = (data) => {
    const { status, action } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      setShowHelpButton(true);
    } else if ([ACTIONS.CLOSE].includes(action)) {
      setRun(false);
      setShowHelpButton(true);
    }
  };
  const navigate = useNavigate();
  const handleClientClick = (id) => {
    navigate(`/client/${id}`);
  };

  const [clientsInHouse, setClientsInHouse] = useState([]);
  const [clientsOther, setClientsOther] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [yearlyPresence, setYearlyPresence] = useState({});
  const [yearlyPresenceOther, setYearlyPresenceOther] = useState({});
  const [totalPresencePerDay, setTotalPresencePerDay] = useState({});
  const [totalPresencePerDayOther, setTotalPresencePerDayOther] = useState([]);
  const [
    totalPresencePerDayInHouseAndOther,
    setTotalPresencePerDayInHouseAndOther,
  ] = useState([]);
  const [currentMonthStart, setCurrentMonthStart] = useState(
    dayjs().startOf("month")
  );
  const [currentMonthEnd, setCurrentMonthEnd] = useState(
    dayjs().endOf("month")
  );
  const [clientColors, setClientColors] = useState({});
  const [lastDaysInHouse, setLastDaysInHouse] = useState(new Set());
  const [lastDaysOther, setLastDaysOther] = useState(new Set());
  const [clientsPast, setClientsPast] = useState([]);
  const [yearlyPresencePast, setYearlyPresencePast] = useState({});
  const [totalPresencePerDayPast, setTotalPresencePerDayPast] = useState([]);
  const [selectedYear, setSelectedYear] = useState(dayjs().year());
  const [selectedMonth, setSelectedMonth] = useState(dayjs().month());
  const [clientsIncoming, setClientsIncoming] = useState([]);
  const [clientsPotential, setClientsPotential] = useState([]);
  const [yearlyPresenceIncoming, setYearlyPresenceIncoming] = useState({});
  const [yearlyPresencePotential, setYearlyPresencePotential] = useState({});
  const [totalPresencePerDayIncoming, setTotalPresencePerDayIncoming] =
    useState([]);
  const [totalPresencePerDayPotential, setTotalPresencePerDayPotential] =
    useState([]);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      const clientsSnapshot = await getDocs(collection(db, "clients"));
      const clientsData = clientsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const inHouseClients = clientsData.filter(
        (client) => client.clientStatus === "In House"
      );
      const incomingClients = clientsData.filter(
        (client) => client.clientStatus === "Incoming"
      );
      const potentialClients = clientsData.filter(
        (client) => client.clientStatus === "Potential"
      );
      const pastClients = clientsData.filter(
        (client) =>
          client.clientStatus === "Aftercare" ||
          client.clientStatus === "Continued Therapy" ||
          client.clientStatus === "Miracles@home" ||
          (client.clientStatus === "Inactive" && client.dateOfAdmission)
      );

      setClientsInHouse(inHouseClients);
      setClientsIncoming(incomingClients);
      setClientsPotential(potentialClients);
      setClientsPast(pastClients);
      assignColors(clientsData);

      const year = selectedYear;
      calculateYearlyPresence(inHouseClients, year);
      calculateYearlyPresenceIncoming(incomingClients, year);
      calculateYearlyPresencePotential(potentialClients, year);
      calculateYearlyPresencePast(pastClients, year);

      setIsLoading(false);
    };

    loadData();
  }, [selectedYear, currentMonthStart]); // Ajoutez selectedYear comme dépendance

  // console.log("pastClient: ", clientsPast);

  const [lastDaysPast, setLastDaysPast] = useState(new Set());

  useEffect(() => {
    const lastDaysInHouse = getLastDaysOfMonth(
      clientsInHouse,
      currentMonthStart,
      currentMonthEnd
    );
    const lastDaysOther = getLastDaysOfMonth(
      clientsOther,
      currentMonthStart,
      currentMonthEnd
    );
    const lastDaysPast = getLastDaysOfMonth(
      clientsPast,
      currentMonthStart,
      currentMonthEnd
    );
    setLastDaysInHouse(lastDaysInHouse);
    setLastDaysOther(lastDaysOther);
    setLastDaysPast(lastDaysPast);
  }, [
    clientsInHouse,
    clientsOther,
    clientsPast,
    currentMonthStart,
    currentMonthEnd,
  ]);

  const assignColors = (clients) => {
    const colors = clients.reduce((acc, client, index) => {
      const color = index % 2 === 0 ? "#003366" : "#6699CC"; // Alternance entre bleu foncé et bleu clair
      return {
        ...acc,
        [client.id]: color,
      };
    }, {});
    setClientColors(colors);
  };

  function isColorTooLight(hex) {
    const rgb = hexToRgb(hex);
    return 0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b > 186; // Brightness threshold
  }

  function hexToRgb(hex) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return { r, g, b };
  }

  const hasPresenceInMonth = (presenceArray, startIndex, endIndex) => {
    if (!presenceArray) return false;
    console.log(`Checking presence from ${startIndex} to ${endIndex}`);
    console.log(
      `Presence array slice:`,
      presenceArray.slice(startIndex, endIndex)
    );
    return presenceArray
      .slice(startIndex, endIndex)
      .some((isPresent) => isPresent);
  };

  const calculateYearlyPresence = (clients, year) => {
    const startOfYear = dayjs().year(year).startOf("year");
    const endOfYear = dayjs().year(year).endOf("year");
    const daysInYear = endOfYear.diff(startOfYear, "day") + 1;
    let presenceData = {};
    let totalDailyPresence = Array(daysInYear).fill(0);

    clients.forEach((client) => {
      const eta = client.dateOfAdmission
        ? dayjs(client.dateOfAdmission.toDate())
        : dayjs(client.eta.toDate());
      const dischargeDate = client.actualDischargeDate
        ? dayjs(client.actualDischargeDate.toDate())
        : client.projectedResidentialDischargeDate
        ? dayjs(client.projectedResidentialDischargeDate.toDate())
        : eta.add(client.durationOfStay, "days");
      const presence = Array.from({ length: daysInYear }, (_, i) => {
        const day = startOfYear.add(i, "day");
        const isPresent =
          (eta.isSame(day, "day") || eta.isBefore(day, "day")) &&
          (dischargeDate.isSame(day, "day") ||
            dischargeDate.isAfter(day, "day"));
        if (isPresent) totalDailyPresence[i]++;
        return isPresent;
      });
      presenceData[client.id] = presence;
    });

    setYearlyPresence(presenceData);
    setTotalPresencePerDay(totalDailyPresence);
  };

  const hasMonthlyPresence = (presenceArray, startIndex, endIndex) => {
    return presenceArray
      .slice(startIndex, endIndex)
      .some((isPresent) => isPresent);
  };

  const calculateYearlyPresenceIncoming = (clients, year) => {
    const startOfYear = dayjs().year(year).startOf("year");
    const endOfYear = dayjs().year(year).endOf("year");
    const daysInYear = endOfYear.diff(startOfYear, "day") + 1;
    let presenceData = {};
    let totalDailyPresence = Array(daysInYear).fill(0);

    clients.forEach((client) => {
      const eta = client.dateOfAdmission
        ? dayjs(client.dateOfAdmission.toDate())
        : dayjs(client.eta.toDate());
      const dischargeDate = client.actualDischargeDate
        ? dayjs(client.actualDischargeDate.toDate())
        : client.projectedResidentialDischargeDate
        ? dayjs(client.projectedResidentialDischargeDate.toDate())
        : eta.add(client.durationOfStay, "days");
      const presence = Array.from({ length: daysInYear }, (_, i) => {
        const day = startOfYear.add(i, "day");
        const isPresent =
          (eta.isSame(day, "day") || eta.isBefore(day, "day")) &&
          (dischargeDate.isSame(day, "day") ||
            dischargeDate.isAfter(day, "day"));
        if (isPresent) totalDailyPresence[i]++;
        return isPresent;
      });
      presenceData[client.id] = presence;
    });

    setYearlyPresenceIncoming(presenceData);
    setTotalPresencePerDayIncoming(totalDailyPresence);
  };

  const calculateYearlyPresencePotential = (clients, year) => {
    const startOfYear = dayjs().year(year).startOf("year");
    const endOfYear = dayjs().year(year).endOf("year");
    const daysInYear = endOfYear.diff(startOfYear, "day") + 1;
    let presenceData = {};
    let totalDailyPresence = Array(daysInYear).fill(0);

    clients.forEach((client) => {
      const eta = client.dateOfAdmission
        ? dayjs(client.dateOfAdmission.toDate())
        : dayjs(client.eta.toDate());
      const dischargeDate = client.actualDischargeDate
        ? dayjs(client.actualDischargeDate.toDate())
        : client.projectedResidentialDischargeDate
        ? dayjs(client.projectedResidentialDischargeDate.toDate())
        : eta.add(client.durationOfStay, "days");
      const presence = Array.from({ length: daysInYear }, (_, i) => {
        const day = startOfYear.add(i, "day");
        const isPresent =
          (eta.isSame(day, "day") || eta.isBefore(day, "day")) &&
          (dischargeDate.isSame(day, "day") ||
            dischargeDate.isAfter(day, "day"));
        if (isPresent) totalDailyPresence[i]++;
        return isPresent;
      });
      presenceData[client.id] = presence;
    });

    setYearlyPresencePotential(presenceData);
    setTotalPresencePerDayPotential(totalDailyPresence);
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
    const monthDate = currentMonthStart.month();
    const newMonthStart = dayjs().year(year).month(monthDate).startOf("month");
    const newMonthEnd = dayjs().year(year).month(monthDate).endOf("month");
    setCurrentMonthStart(newMonthStart);
    setCurrentMonthEnd(newMonthEnd);
  };

  const calculateYearlyPresenceOthers = (clients, year) => {
    const startOfYear = dayjs().year(year).startOf("year");
    const endOfYear = dayjs().year(year).endOf("year");
    const daysInYear = endOfYear.diff(startOfYear, "day") + 1;
    let presenceData = {};
    let totalDailyPresence = Array(daysInYear).fill(0);

    clients.forEach((client) => {
      const eta = client.dateOfAdmission
        ? dayjs(client.dateOfAdmission.toDate())
        : dayjs(client.eta.toDate());
      const dischargeDate = client.actualDischargeDate
        ? dayjs(client.actualDischargeDate.toDate())
        : client.projectedResidentialDischargeDate
        ? dayjs(client.projectedResidentialDischargeDate.toDate())
        : eta.add(client.durationOfStay, "days");
      const presence = Array.from({ length: daysInYear }, (_, i) => {
        const day = startOfYear.add(i, "day");
        const isPresent =
          (eta.isSame(day, "day") || eta.isBefore(day, "day")) &&
          (dischargeDate.isSame(day, "day") ||
            dischargeDate.isAfter(day, "day"));
        if (isPresent) totalDailyPresence[i]++;
        return isPresent;
      });
      presenceData[client.id] = presence;
    });

    setYearlyPresenceOther(presenceData);
    setTotalPresencePerDayOther(totalDailyPresence);
  };

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
    const newMonthStart = dayjs()
      .year(selectedYear)
      .month(month)
      .startOf("month");
    const newMonthEnd = dayjs().year(selectedYear).month(month).endOf("month");
    setCurrentMonthStart(newMonthStart);
    setCurrentMonthEnd(newMonthEnd);
  };

  // const totalPresencePerDaySum = (presenceArray) => {
  //   return presenceArray.reduce((acc, count) => acc + count, 0);
  // };

  const totalPresencePerDaySum = (presenceArray) => {
    return presenceArray
      .slice(monthDayStartIndex, monthDayEndIndex)
      .reduce((acc, count) => acc + count, 0);
  };

  useEffect(() => {
    if (totalPresencePerDay.length && totalPresencePerDayOther.length) {
      const summedTotal = totalPresencePerDay.map(
        (count, index) => count + (totalPresencePerDayOther[index] || 0)
      );
      setTotalPresencePerDayInHouseAndOther(summedTotal);
    }
  }, [totalPresencePerDay, totalPresencePerDayOther]);

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        setIsLoading(true);
        console.log("Fetching clients...");
        const clientsSnapshot = await getDocs(collection(db, "clients"));
        console.log("Clients fetched:", clientsSnapshot.docs.length);

        const clientsData = clientsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log("Clients data:", clientsData);

        const paymentDetailsPromises = clientsData.map(async (client) => {
          const preAdmissionDoc = await getDoc(
            doc(db, "preAdmissionAssessments", client.id)
          );
          console.log(
            `Payment details for client ${client.id}:`,
            preAdmissionDoc.exists()
              ? preAdmissionDoc.data().paymentDetails
              : null
          );
          return {
            ...client,
            paymentDetails: preAdmissionDoc.exists()
              ? preAdmissionDoc.data().paymentDetails
              : null,
          };
        });

        const clientsWithPaymentDetails = await Promise.all(
          paymentDetailsPromises
        );
        console.log("Clients with Payment Details:", clientsWithPaymentDetails);

        setClientsInHouse(
          clientsWithPaymentDetails.filter(
            (client) => client.clientStatus === "In House"
          )
        );
        setClientsIncoming(
          clientsWithPaymentDetails.filter(
            (client) => client.clientStatus === "Incoming"
          )
        );
        setClientsPotential(
          clientsWithPaymentDetails.filter(
            (client) => client.clientStatus === "Potential"
          )
        );
        setClientsPast(
          clientsWithPaymentDetails.filter(
            (client) =>
              client.clientStatus === "Aftercare" ||
              client.clientStatus === "Continued Therapy" ||
              client.clientStatus === "Miracles@home" ||
              client.clientStatus === "Inactive"
          )
        );
      } catch (error) {
        console.error("Error fetching payment details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPaymentDetails();
  }, [selectedYear, selectedMonth]);

  const calculateYearlyPresencePast = (clients, year) => {
    const startOfYear = dayjs().year(year).startOf("year");
    const endOfYear = dayjs().year(year).endOf("year");
    const daysInYear = endOfYear.diff(startOfYear, "day") + 1;
    let presenceData = {};
    let totalDailyPresence = Array(daysInYear).fill(0);

    clients.forEach((client) => {
      const eta = client.dateOfAdmission
        ? dayjs(client.dateOfAdmission.toDate())
        : client.eta
        ? dayjs(client.eta.toDate())
        : null;

      const dischargeDate = client.actualDischargeDate
        ? dayjs(client.actualDischargeDate.toDate())
        : client.projectedResidentialDischargeDate
        ? dayjs(client.projectedResidentialDischargeDate.toDate())
        : eta
        ? eta.add(client.durationOfStay, "days")
        : null;

      if (!eta || !dischargeDate) {
        console.error(`Client ID: ${client.id} has invalid date(s)`);
        return;
      }

      const presence = Array.from({ length: daysInYear }, (_, i) => {
        const day = startOfYear.add(i, "day");
        const isPresent =
          (eta.isSame(day, "day") || eta.isBefore(day, "day")) &&
          (dischargeDate.isSame(day, "day") ||
            dischargeDate.isAfter(day, "day"));
        if (isPresent) totalDailyPresence[i]++;
        return isPresent;
      });
      presenceData[client.id] = presence;
    });

    setYearlyPresencePast(presenceData);
    setTotalPresencePerDayPast(totalDailyPresence);
  };

  const calculateLengthOfStayToDate = (client) => {
    const today = dayjs().startOf("day"); // Début de la journée actuelle
    const eta = client.dateOfAdmission
      ? dayjs(client.dateOfAdmission.toDate()).startOf("day")
      : dayjs(client.eta.toDate()).startOf("day");

    const dischargeDate = client.actualDischargeDate
      ? dayjs(client.actualDischargeDate.toDate()).startOf("day")
      : client.projectedResidentialDischargeDate
      ? dayjs(client.projectedResidentialDischargeDate.toDate()).startOf("day")
      : today;

    // Calculer la durée de séjour en tenant compte de la date de sortie
    const endDate = dischargeDate.isBefore(today) ? dischargeDate : today;
    const lengthOfStay = endDate.diff(eta, "day") + 1;

    return lengthOfStay <= 0 ? "--" : lengthOfStay;
  };

  const getLastDaysOfMonth = (clients, monthStart, monthEnd) => {
    const lastDays = new Set();
    clients.forEach((client) => {
      const dischargeDate = client.actualDischargeDate
        ? dayjs(client.actualDischargeDate.toDate())
        : client.projectedResidentialDischargeDate
        ? dayjs(client.projectedResidentialDischargeDate.toDate())
        : null;

      if (
        dischargeDate &&
        dischargeDate.isBetween(monthStart, monthEnd, "day", "[]")
      ) {
        lastDays.add(dischargeDate.date());
      }
    });
    return lastDays;
  };

  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const daysInMonth = currentMonthEnd.diff(currentMonthStart, "day") + 1;

  // const monthDayStartIndex = currentMonthStart.diff(
  //   dayjs().year(selectedYear).startOf("year"),
  //   "day"
  // );
  // const monthDayEndIndex = monthDayStartIndex + daysInMonth;

  const monthDayStartIndex = currentWeekStart.diff(
    dayjs().year(selectedYear).startOf("year"),
    "day"
  );
  const monthDayEndIndex = monthDayStartIndex + 7; // Only 7 days for the week

  const hasPresenceInWeek = (presenceArray, startIndex, endIndex) => {
    if (!presenceArray) return false;
    return presenceArray
      .slice(startIndex, endIndex)
      .some((isPresent) => isPresent);
  };

  return (
    <div style={{ backgroundColor: "white", paddingLeft: "0px" }}>
      <Joyride
        steps={steps}
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
        run={run}
        callback={handleJoyrideCallback}
        disableScrolling={true}
        disableBeacon={true}
        styles={customStyles}
      />
      <div
        style={{
          paddingLeft: "0px",
          // width: "987px",
          width: "auto",
          display: "flex",
          flexDirection: "column",
          height: "20%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "auto",
          }}
        >
          {/* <MonthPicker onChange={handleMonthChange} /> */}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <MonthPickerMobileV2
              selectedYear={selectedYear}
              selectedMonth={selectedMonth}
              onYearChange={handleYearChange}
              onMonthChange={handleMonthChange}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              paddingRight: "10px",
              marginTop: "20px",
              justifyContent: "space-between",
              paddingLeft: "0px",
            }}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setCurrentWeekStart(currentWeekStart.subtract(1, "week"));
                setCurrentWeekEnd(currentWeekEnd.subtract(1, "week"));
              }}
            >
              {translate("PreviousWeek")}
            </Button>

            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setCurrentWeekStart(currentWeekStart.add(1, "week"));
                setCurrentWeekEnd(currentWeekEnd.add(1, "week"));
              }}
            >
              {translate("NextWeek")}
            </Button>
          </div>

          <table
            style={{
              marginLeft: "0px",
              marginTop: "10px",
              paddingRight: "10px",
            }}
          >
            <thead>
              <tr>
                <th>{/* Firstname */}</th>
                <th></th>
                {Array.from({ length: 7 }, (_, i) => {
                  const day = currentMonthStart.clone().add(i, "day");
                  const isLastDayInHouse = lastDaysInHouse.has(day.date());
                  const isLastDayOther = lastDaysOther.has(day.date());
                  const isLastDay = isLastDayInHouse || isLastDayOther;
                  return (
                    <th
                      key={`day-initial-${i}`}
                      style={{
                        border: "1px solid #ccc",
                        backgroundColor: "#3d5681",
                        color: "white",
                        fontSize: "12px",
                        borderRadius: "5px",
                      }}
                    >
                      {day.format("dd")[0].toUpperCase() + day.format("dd")[1]}
                    </th>
                  );
                })}
                <th></th>
                <th></th>
                <th></th>
              </tr>
              <tr style={{ height: "30px" }}>
                <th
                  style={{
                    border: "1px solid #ccc",
                    backgroundColor: "#3d5681",
                    color: "white",
                    fontSize: "12px",
                    borderRadius: "5px",
                  }}
                  colspan={2}
                >
                  {translate("ClientName")}
                </th>

                {/* {Array.from({ length: daysInMonth }, (_, i) => {
                  const day = currentMonthStart.clone().add(i, "day");
                  const isToday = day.isSame(dayjs(), "day");
                  const isLastDayInHouse = lastDaysInHouse.has(day.date());
                  const isLastDayOther = lastDaysOther.has(day.date());
                  const isLastDayPast = lastDaysPast.has(day.date());
                  const isLastDay =
                    isLastDayInHouse || isLastDayOther || isLastDayPast;
                  return (
                    <th
                      key={i}
                      style={{
                        border: "1px solid #ccc",
                        backgroundColor: isToday
                          ? "green"
                          : isLastDayInHouse || isLastDayOther || isLastDayPast
                          ? "red"
                          : "#3d5681",
                        color: "white",
                      }}
                    >
                      {day.format("DD")}
                      {isLastDayInHouse || isLastDayOther || isLastDayPast
                        ? console.log(
                            `${day.format("DD MMM")}: ${
                              clientsInHouse
                                .concat(clientsOther, clientsPast)
                                .find((client) => {
                                  const dischargeDate =
                                    client.actualDischargeDate
                                      ? dayjs(
                                          client.actualDischargeDate.toDate()
                                        )
                                      : client.projectedResidentialDischargeDate
                                      ? dayjs(
                                          client.projectedResidentialDischargeDate.toDate()
                                        )
                                      : null;
                                  return (
                                    dischargeDate &&
                                    dischargeDate.isSame(day, "day")
                                  );
                                })?.lastName
                            }`
                          )
                        : null}
                    </th>
                  );
                })} */}

                {Array.from({ length: 7 }, (_, i) => {
                  const day = currentWeekStart.clone().add(i, "day");
                  const isToday = day.isSame(dayjs(), "day");
                  const isLastDayInHouse = lastDaysInHouse.has(day.date());
                  const isLastDayOther = lastDaysOther.has(day.date());
                  const isLastDayPast = lastDaysPast.has(day.date());
                  const isLastDay =
                    isLastDayInHouse || isLastDayOther || isLastDayPast;
                  return (
                    <th
                      key={`day-initial-${i}`}
                      style={{
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        backgroundColor: isToday
                          ? "green"
                          : isLastDayInHouse || isLastDayOther || isLastDayPast
                          ? "red"
                          : "#3d5681",
                        color: "white",
                      }}
                    >
                      {day.format("DD")}
                      {isLastDayInHouse || isLastDayOther || isLastDayPast
                        ? console.log(
                            `${day.format("DD MMM")}: ${
                              clientsInHouse
                                .concat(clientsOther, clientsPast)
                                .find((client) => {
                                  const dischargeDate =
                                    client.actualDischargeDate
                                      ? dayjs(
                                          client.actualDischargeDate.toDate()
                                        )
                                      : client.projectedResidentialDischargeDate
                                      ? dayjs(
                                          client.projectedResidentialDischargeDate.toDate()
                                        )
                                      : null;
                                  return (
                                    dischargeDate &&
                                    dischargeDate.isSame(day, "day")
                                  );
                                })?.lastName
                            }`
                          )
                        : null}
                    </th>
                  );
                })}

                {/* <th
                  id="nights-month"
                  style={{
                    width: "100px",
                    minWidth: "100px",
                    border: "1px solid #ccc",
                    backgroundColor: "#3d5681",
                    color: "white",
                  }}
                >
                  {translate("NightsMo")}
                </th>
                <th
                  id="los-to-date"
                  style={{
                    width: "100px",
                    minWidth: "100px",
                    border: "1px solid #ccc",
                    backgroundColor: "#3d5681",
                    color: "white",
                    fontSize: "12px",
                  }}
                >
                  {translate("LOSToDate")}
                </th>
                <th
                  id="los-intended"
                  style={{
                    border: "1px solid #ccc",
                    backgroundColor: "#3d5681",
                    color: "white",
                    fontSize: "12px",
                  }}
                >
                  {translate("LOSIntended")}
                </th> */}
                {/* <th>
                  {showHelpButton && (
                    <div
                      style={{
                        height: "30px",
                        paddingTop: "0px",
                        paddingLeft: "40px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleHelpClick}
                        sx={{ fontSize: "10px" }}
                      >
                        HELP
                      </Button>
                    </div>
                  )}
                </th> */}
              </tr>
            </thead>

            <tbody>
              {clientsInHouse.filter((client) =>
                hasPresenceInMonth(
                  yearlyPresence[client.id],
                  monthDayStartIndex,
                  monthDayEndIndex
                )
              ).length > 0 && (
                <>
                  {/* <td
                    colSpan="2"
                    style={{
                      background: "#003366",
                      color: "white",
                      textAlign: "center",
                      fontWeight: "600",
                    }}
                  >
                    Inhouse Clients
                  </td> */}
                  {[...clientsInHouse]
                    .filter((client) =>
                      hasPresenceInMonth(
                        yearlyPresence[client.id],
                        monthDayStartIndex,
                        monthDayEndIndex
                      )
                    )
                    .map((client, clientIndex) => (
                      <tr key={client.id}>
                        <td
                          colSpan="2"
                          onClick={() => handleClientClick(client.id)}
                          style={{
                            cursor: "pointer",
                            // textDecoration: "underline",
                          }}
                        >
                          <div
                            style={{
                              width: "auto", // Adjusted width to accommodate both names
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              border: "1px solid #ccc",
                              textAlign: "center",
                              fontWeight: "600",
                              backgroundColor: "#f5f5f5",
                              borderRadius: "5px",
                            }}
                          >
                            <span
                              style={{
                                textDecoration: "none",
                                fontSize: "12px",
                              }}
                            >
                              {`${client.firstName} ${client.lastName}`}
                            </span>
                          </div>
                        </td>

                        {yearlyPresence[client.id]
                          .slice(monthDayStartIndex, monthDayEndIndex)
                          .map((isPresent, index) => {
                            const day = currentMonthStart
                              .clone()
                              .add(index, "day");
                            const isToday = day.isSame(dayjs(), "day");
                            const dischargeDate = client.actualDischargeDate
                              ? dayjs(client.actualDischargeDate.toDate())
                              : client.projectedResidentialDischargeDate
                              ? dayjs(
                                  client.projectedResidentialDischargeDate.toDate()
                                )
                              : null;
                            const isLastDayForClient = dischargeDate
                              ? dischargeDate.isSame(day, "day")
                              : false;
                            const bgColor = isLastDayForClient
                              ? "red"
                              : clientIndex % 2 === 0
                              ? "#003366"
                              : "#6699CC";
                            return (
                              <td
                                key={index}
                                style={{
                                  width: "25px",
                                  minWidth: "25px",
                                  borderRadius: "5px",
                                  backgroundColor: isPresent
                                    ? bgColor
                                    : "#e6e6e4",
                                  border: isToday
                                    ? "2px solid green"
                                    : "1px solid #ccc",
                                }}
                              />
                            );
                          })}

                        {/* <td
                          style={{
                            border: "1px solid #ccc",
                            textAlign: "center",
                          }}
                        >
                          {
                            yearlyPresence[client.id]
                              .slice(monthDayStartIndex, monthDayEndIndex)
                              .filter(Boolean).length
                          }
                        </td> */}
                        {/* <td
                          style={{
                            textAlign: "center",
                            border: "1px solid #ccc",
                          }}
                        >
                          {calculateLengthOfStayToDate(client)}
                        </td>
                        <td>
                          <div
                            style={{
                              width: "100px",
                              textAlign: "center",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              border: "1px solid #ccc",
                            }}
                          >
                            {client.durationOfStay}
                          </div>
                        </td> */}
                        <td>
                          {console.log(
                            `Client ID: ${client.id}, Payment Details: ${client?.paymentDetails}`
                          )}

                          {client.paymentDetails !== undefined && (
                            <Tooltip title={client.paymentDetails} arrow>
                              <div
                                id="payment-problem"
                                className={`diode ${
                                  !client.paymentDetails ||
                                  [
                                    "1 - Invoice Requested",
                                    "2 - Invoice Sent",
                                    "--Choose--",
                                    "",
                                  ].includes(client.paymentDetails)
                                    ? "red-diode"
                                    : "green-diode"
                                }`}
                                style={{ cursor: "pointer" }}
                                title={
                                  !client.paymentDetails ||
                                  [
                                    "1 - Invoice Requested",
                                    "2 - Invoice Sent",
                                    "--Choose--",
                                    "",
                                  ].includes(client.paymentDetails)
                                    ? "Payment issue"
                                    : ""
                                }
                              />
                            </Tooltip>
                          )}
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </tbody>

            {/* <br /> */}

            <tbody>
              {clientsPast.filter((client) =>
                hasPresenceInMonth(
                  yearlyPresencePast[client.id],
                  monthDayStartIndex,
                  monthDayEndIndex
                )
              ).length > 0 && (
                <>
                  {[...clientsPast]
                    .filter((client) =>
                      hasPresenceInMonth(
                        yearlyPresencePast[client.id],
                        monthDayStartIndex,
                        monthDayEndIndex
                      )
                    )
                    .map((client, clientIndex) => (
                      <tr key={client.id}>
                        <td
                          colSpan="2"
                          onClick={() => handleClientClick(client.id)}
                          style={{
                            cursor: "pointer",
                            // textDecoration: "underline",
                          }}
                        >
                          <div
                            style={{
                              width: "auto", // Adjusted width to accommodate both names
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              border: "1px solid #ccc",
                              textAlign: "center",
                              fontWeight: "600",
                            }}
                          >
                            <span
                              style={{
                                textDecoration: "none",
                                fontSize: "12px",
                              }}
                            >
                              {`${client.firstName} ${client.lastName}`}
                            </span>
                          </div>
                        </td>
                        {yearlyPresencePast[client.id] &&
                          yearlyPresencePast[client.id]
                            .slice(monthDayStartIndex, monthDayEndIndex)
                            .map((isPresent, index) => {
                              const day = currentMonthStart
                                .clone()
                                .add(index, "day");
                              const isToday = day.isSame(dayjs(), "day");
                              const dischargeDate = client.actualDischargeDate
                                ? dayjs(client.actualDischargeDate.toDate())
                                : client.projectedResidentialDischargeDate
                                ? dayjs(
                                    client.projectedResidentialDischargeDate.toDate()
                                  )
                                : null;
                              const isLastDayForClient = dischargeDate
                                ? dischargeDate.isSame(day, "day")
                                : false;
                              const bgColor = isLastDayForClient
                                ? "red"
                                : clientIndex % 2 === 0
                                ? "#003366"
                                : "#6699CC";
                              if (isLastDayForClient) {
                                console.log(
                                  `${day.format("DD MMM")}: ${client.lastName}`
                                );
                              }
                              return (
                                <td
                                  key={index}
                                  style={{
                                    width: "25px",
                                    minWidth: "25px",
                                    backgroundColor: isPresent
                                      ? bgColor
                                      : "#e6e6e4",
                                    border: isToday
                                      ? "2px solid green"
                                      : "1px solid #ccc",
                                  }}
                                />
                              );
                            })}
                        <td
                          style={{
                            border: "1px solid #ccc",
                            textAlign: "center",
                          }}
                        >
                          {yearlyPresencePast[client.id] &&
                            yearlyPresencePast[client.id]
                              .slice(monthDayStartIndex, monthDayEndIndex)
                              .filter(Boolean).length}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid #ccc",
                          }}
                        >
                          {calculateLengthOfStayToDate(client)}
                        </td>
                        <td>
                          <div
                            style={{
                              width: "100px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              textAlign: "center",
                              border: "1px solid #ccc",
                            }}
                          >
                            {client.durationOfStay}
                          </div>
                        </td>
                        <td>
                          {console.log(
                            `Client ID: ${client.id}, Payment Details: ${client?.paymentDetails}`
                          )}

                          {client.paymentDetails !== undefined && (
                            <Tooltip title={client.paymentDetails} arrow>
                              <div
                                className={`diode ${
                                  !client.paymentDetails ||
                                  [
                                    "1 - Invoice Requested",
                                    "2 - Invoice Sent",
                                    "--Choose--",
                                    "",
                                  ].includes(client.paymentDetails)
                                    ? "red-diode"
                                    : "green-diode"
                                }`}
                                style={{ cursor: "pointer" }}
                                title={
                                  !client.paymentDetails ||
                                  [
                                    "1 - Invoice Requested",
                                    "2 - Invoice Sent",
                                    "--Choose--",
                                    "",
                                  ].includes(client.paymentDetails)
                                    ? "Payment issue"
                                    : ""
                                }
                              />
                            </Tooltip>
                          )}
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </tbody>

            <tbody>
              <tr id="total-nights-booked">
                <td
                  colspan="2"
                  style={{
                    background: "#567ab3",
                    color: "white",
                    textAlign: "center",
                    fontWeight: "600",
                    fontSize: "12px",
                    // backgroundColor: "#f5f5f5",
                    borderRadius: "5px",
                  }}
                >
                  {translate("TotalNightsBooked")}
                </td>
                {Array.from({ length: 7 }, (_, i) => {
                  const totalDay0 =
                    totalPresencePerDay[monthDayStartIndex + i] || 0;
                  const totalDay1 =
                    totalPresencePerDayPast[monthDayStartIndex + i] || 0;
                  const total = totalDay0 + totalDay1;
                  return (
                    <td
                      key={i}
                      style={{
                        border: "1px solid #ccc",
                        textAlign: "center",
                        fontSize: "12px",
                        fontWeight: "600",
                        backgroundColor: "#f5f5f5",
                        borderRadius: "5px",
                      }}
                    >
                      {total}
                    </td>
                  );
                })}
                {/* <td
                  style={{
                    border: "1px solid #ccc",
                    textAlign: "center",
                    backgroundColor: "#3d5681",
                    color: "white",
                    fontWeight: "800",
                    fontSize: "12px",
                  }}
                >
                  {totalPresencePerDaySum(
                    totalPresencePerDay.slice(
                      monthDayStartIndex,
                      monthDayEndIndex
                    )
                  ) +
                    totalPresencePerDaySum(
                      totalPresencePerDayPast.slice(
                        monthDayStartIndex,
                        monthDayEndIndex
                      )
                    )}
                </td> */}
              </tr>
            </tbody>

            <br />

            <tbody>
              {clientsIncoming.filter((client) =>
                hasPresenceInMonth(
                  yearlyPresenceIncoming[client.id],
                  monthDayStartIndex,
                  monthDayEndIndex
                )
              ).length > 0 && (
                <>
                  <td
                    colSpan="2"
                    style={{
                      background: "#003366",
                      color: "white",
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: "12px",
                    }}
                  >
                    {translate("Incoming2")}
                  </td>
                  {[...clientsIncoming]
                    .filter((client) =>
                      hasPresenceInMonth(
                        yearlyPresenceIncoming[client.id],
                        monthDayStartIndex,
                        monthDayEndIndex
                      )
                    )
                    .map((client, clientIndex) => (
                      <tr key={client.id}>
                        <td
                          colSpan="2"
                          onClick={() => handleClientClick(client.id)}
                          style={{
                            cursor: "pointer",
                            // textDecoration: "underline",
                          }}
                        >
                          <div
                            style={{
                              width: "150px", // Adjusted width to accommodate both names
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              border: "1px solid #ccc",
                              textAlign: "center",
                              fontWeight: "600",
                            }}
                          >
                            <span
                              style={{
                                textDecoration: "none",
                                fontSize: "12px",
                              }}
                            >
                              {`${client.firstName} ${client.lastName}`}
                            </span>
                          </div>
                        </td>

                        {yearlyPresenceIncoming[client.id]
                          .slice(monthDayStartIndex, monthDayEndIndex)
                          .map((isPresent, index) => {
                            const day = currentMonthStart
                              .clone()
                              .add(index, "day");
                            const isToday = day.isSame(dayjs(), "day");
                            const dischargeDate = client.actualDischargeDate
                              ? dayjs(client.actualDischargeDate.toDate())
                              : client.projectedResidentialDischargeDate
                              ? dayjs(
                                  client.projectedResidentialDischargeDate.toDate()
                                )
                              : null;
                            const isLastDayForClient = dischargeDate
                              ? dischargeDate.isSame(day, "day")
                              : false;
                            const bgColor = isLastDayForClient
                              ? "red"
                              : clientIndex % 2 === 0
                              ? "#003366"
                              : "#6699CC";
                            if (isLastDayForClient) {
                              console.log(
                                `${day.format("DD MMM")}: ${client.lastName}`
                              );
                            }
                            return (
                              <td
                                key={index}
                                style={{
                                  width: "25px",
                                  minWidth: "25px",
                                  backgroundColor: isPresent
                                    ? bgColor
                                    : "#e6e6e4",
                                  border: isToday
                                    ? "2px solid green"
                                    : "1px solid #ccc",
                                }}
                              />
                            );
                          })}

                        <td
                          style={{
                            border: "1px solid #ccc",
                            textAlign: "center",
                          }}
                        >
                          {
                            yearlyPresenceIncoming[client.id]
                              .slice(monthDayStartIndex, monthDayEndIndex)
                              .filter(Boolean).length
                          }
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid #ccc",
                          }}
                        >
                          {calculateLengthOfStayToDate(client)}
                        </td>
                        <td>
                          <div
                            style={{
                              width: "100px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              textAlign: "center",
                              border: "1px solid #ccc",
                            }}
                          >
                            {client.durationOfStay}
                          </div>
                        </td>
                        <td>
                          {console.log(
                            `Client ID: ${client.id}, Payment Details: ${client?.paymentDetails}`
                          )}

                          {client.paymentDetails !== undefined && (
                            <Tooltip title={client.paymentDetails} arrow>
                              <div
                                className={`diode ${
                                  !client.paymentDetails ||
                                  [
                                    "1 - Invoice Requested",
                                    "2 - Invoice Sent",
                                    "--Choose--",
                                    "",
                                  ].includes(client.paymentDetails)
                                    ? "red-diode"
                                    : "green-diode"
                                }`}
                                style={{ cursor: "pointer" }}
                                title={
                                  !client.paymentDetails ||
                                  [
                                    "1 - Invoice Requested",
                                    "2 - Invoice Sent",
                                    "--Choose--",
                                    "",
                                  ].includes(client.paymentDetails)
                                    ? "Payment issue"
                                    : ""
                                }
                              />
                            </Tooltip>
                          )}
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </tbody>
            <tbody>
              {hasMonthlyPresence(
                totalPresencePerDayIncoming,
                monthDayStartIndex,
                monthDayEndIndex
              ) && (
                <tr id="total-incoming-booked">
                  <td
                    colSpan="2"
                    style={{
                      background: "#567ab3",
                      color: "white",
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: "12px",
                    }}
                  >
                    {translate("TotalIncomingNights")}
                  </td>
                  {totalPresencePerDayIncoming
                    .slice(monthDayStartIndex, monthDayEndIndex)
                    .map((count, index) => (
                      <td
                        key={index}
                        style={{
                          border: "1px solid #ccc",
                          textAlign: "center",
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        {count}
                      </td>
                    ))}
                  <td
                    style={{
                      border: "1px solid #ccc",
                      textAlign: "center",
                      backgroundColor: "#3d5681",
                      color: "white",
                      fontWeight: "800",
                      fontSize: "12px",
                    }}
                  >
                    {totalPresencePerDaySum(
                      totalPresencePerDayIncoming.slice(
                        monthDayStartIndex,
                        monthDayEndIndex
                      )
                    )}
                  </td>
                </tr>
              )}
              <br />
            </tbody>

            <tbody>
              {clientsPotential.length > 0 && (
                <>
                  {console.log("clientsPotential:", clientsPotential)}
                  {clientsPotential.forEach((client) => {
                    console.log(
                      `yearlyPresencePotential for client ${client.id}:`,
                      yearlyPresencePotential[client.id]
                    );
                  })}
                  {console.log(
                    `monthDayStartIndex: ${monthDayStartIndex}, monthDayEndIndex: ${monthDayEndIndex}`
                  )}
                  {clientsPotential.filter((client) => {
                    const hasPresence = hasPresenceInMonth(
                      yearlyPresencePotential[client.id],
                      monthDayStartIndex,
                      monthDayEndIndex
                    );
                    console.log(
                      `hasPresenceInMonth for client ${client.id}:`,
                      hasPresence
                    );
                    return hasPresence;
                  }).length > 0 && (
                    <>
                      <td
                        colSpan="2"
                        style={{
                          background: "#003366",
                          color: "white",
                          textAlign: "center",
                          fontWeight: "600",
                        }}
                      >
                        Potential
                      </td>
                      {[...clientsPotential]
                        .filter((client) =>
                          hasPresenceInMonth(
                            yearlyPresencePotential[client.id],
                            monthDayStartIndex,
                            monthDayEndIndex
                          )
                        )
                        .map((client, clientIndex) => (
                          <tr key={client.id}>
                            <td
                              colSpan="2"
                              onClick={() => handleClientClick(client.id)}
                              style={{
                                cursor: "pointer",
                                // textDecoration: "underline",
                              }}
                            >
                              <div
                                style={{
                                  width: "150px", // Adjusted width to accommodate both names
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  border: "1px solid #ccc",
                                  textAlign: "center",
                                  fontWeight: "600",
                                }}
                              >
                                <span
                                  style={{
                                    textDecoration: "none",
                                    fontSize: "12px",
                                  }}
                                >
                                  {`${client.firstName} ${client.lastName}`}
                                </span>
                              </div>
                            </td>

                            {yearlyPresencePotential[client.id]
                              .slice(monthDayStartIndex, monthDayEndIndex)
                              .map((isPresent, index) => {
                                const day = currentMonthStart
                                  .clone()
                                  .add(index, "day");
                                const isToday = day.isSame(dayjs(), "day");
                                const dischargeDate = client.actualDischargeDate
                                  ? dayjs(client.actualDischargeDate.toDate())
                                  : client.projectedResidentialDischargeDate
                                  ? dayjs(
                                      client.projectedResidentialDischargeDate.toDate()
                                    )
                                  : null;
                                const isLastDayForClient = dischargeDate
                                  ? dischargeDate.isSame(day, "day")
                                  : false;
                                const bgColor = isLastDayForClient
                                  ? "red"
                                  : clientIndex % 2 === 0
                                  ? "#003366"
                                  : "#6699CC";
                                if (isLastDayForClient) {
                                  console.log(
                                    `${day.format("DD MMM")}: ${
                                      client.lastName
                                    }`
                                  );
                                }
                                return (
                                  <td
                                    key={index}
                                    style={{
                                      width: "25px",
                                      minWidth: "25px",
                                      backgroundColor: isPresent
                                        ? bgColor
                                        : "#e6e6e4",
                                      border: isToday
                                        ? "2px solid green"
                                        : "1px solid #ccc",
                                    }}
                                  />
                                );
                              })}

                            <td
                              style={{
                                border: "1px solid #ccc",
                                textAlign: "center",
                              }}
                            >
                              {
                                yearlyPresencePotential[client.id]
                                  .slice(monthDayStartIndex, monthDayEndIndex)
                                  .filter(Boolean).length
                              }
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                border: "1px solid #ccc",
                              }}
                            >
                              {calculateLengthOfStayToDate(client)}
                            </td>
                            <td>
                              <div
                                style={{
                                  width: "100px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  textAlign: "center",
                                  border: "1px solid #ccc",
                                }}
                              >
                                {client.durationOfStay}
                              </div>
                            </td>
                            <td>
                              {console.log(
                                `Client potential ID: ${client.id}, Payment Details: ${client?.paymentDetails}`
                              )}

                              {client.paymentDetails !== undefined && (
                                <Tooltip title={client.paymentDetails} arrow>
                                  <div
                                    className={`diode ${
                                      !client.paymentDetails ||
                                      [
                                        "1 - Invoice Requested",
                                        "2 - Invoice Sent",
                                        "--Choose--",
                                        "",
                                      ].includes(client.paymentDetails)
                                        ? "red-diode"
                                        : "green-diode"
                                    }`}
                                    style={{ cursor: "pointer" }}
                                    title={
                                      !client.paymentDetails ||
                                      [
                                        "1 - Invoice Requested",
                                        "2 - Invoice Sent",
                                        "--Choose--",
                                        "",
                                      ].includes(client.paymentDetails)
                                        ? "Payment issue"
                                        : ""
                                    }
                                  />
                                </Tooltip>
                              )}
                            </td>
                          </tr>
                        ))}
                    </>
                  )}
                </>
              )}
            </tbody>

            <tbody>
              {hasMonthlyPresence(
                totalPresencePerDayPotential,
                monthDayStartIndex,
                monthDayEndIndex
              ) && (
                <tr id="total-potential-night">
                  <td
                    colSpan="2"
                    style={{
                      background: "#567ab3",
                      color: "white",
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: "12px",
                    }}
                  >
                    {translate("TotalPotentialNights")}
                  </td>
                  {totalPresencePerDayPotential
                    .slice(monthDayStartIndex, monthDayEndIndex)
                    .map((count, index) => (
                      <td
                        key={index}
                        style={{
                          border: "1px solid #ccc",
                          textAlign: "center",
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        {count}
                      </td>
                    ))}
                  {/* <td
                    style={{
                      border: "1px solid #ccc",
                      textAlign: "center",
                      backgroundColor: "#3d5681",
                      color: "white",
                      fontWeight: "800",
                      fontSize: "12px",
                    }}
                  >
                    {totalPresencePerDaySum(
                      totalPresencePerDayPotential.slice(
                        monthDayStartIndex,
                        monthDayEndIndex
                      )
                    )}
                  </td> */}
                </tr>
              )}
            </tbody>

            <br />
            <tr id="total-projected-night">
              <td
                colSpan="2"
                style={{
                  background: "#567ab3",
                  color: "white",
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "12px",

                  borderRadius: "5px",
                }}
              >
                {translate("TotalProjectedNights")}
              </td>
              {Array.from({ length: 7 }, (_, i) => {
                const totalDay0 =
                  totalPresencePerDay[monthDayStartIndex + i] || 0;
                const totalDay1 =
                  totalPresencePerDayPast[monthDayStartIndex + i] || 0;
                const totalDay2 =
                  totalPresencePerDayIncoming[monthDayStartIndex + i] || 0;
                const totalDay3 =
                  totalPresencePerDayPotential[monthDayStartIndex + i] || 0;
                const total = totalDay0 + totalDay1 + totalDay2 + totalDay3;
                return (
                  <td
                    key={i}
                    style={{
                      border: "1px solid #ccc",
                      textAlign: "center",
                      fontSize: "12px",
                      fontWeight: "600",
                      backgroundColor: "#f5f5f5",
                      borderRadius: "5px",
                    }}
                  >
                    {total}
                  </td>
                );
              })}
              {/* <td
                style={{
                  border: "1px solid #ccc",
                  textAlign: "center",
                  backgroundColor: "#3d5681",
                  color: "white",
                  fontWeight: "800",
                  fontSize: "12px",
                }}
              >
                {totalPresencePerDaySum(
                  totalPresencePerDay.slice(
                    monthDayStartIndex,
                    monthDayEndIndex
                  )
                ) +
                  totalPresencePerDaySum(
                    totalPresencePerDayPast.slice(
                      monthDayStartIndex,
                      monthDayEndIndex
                    )
                  ) +
                  totalPresencePerDaySum(
                    totalPresencePerDayIncoming.slice(
                      monthDayStartIndex,
                      monthDayEndIndex
                    )
                  ) +
                  totalPresencePerDaySum(
                    totalPresencePerDayPotential.slice(
                      monthDayStartIndex,
                      monthDayEndIndex
                    )
                  )}
              </td> */}
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CheatsheetChartMobileV2;
