import React from "react";

function TitleMedium6Mobile() {
  return (
    <div className="title-line-medium-6">
      {/* <div className="title-medium5-block1bis">Google email</div> */}
      <div className="title-medium5-block1bis-v2">User Name</div>
      {/* <div className="title-medium5-block2bis">User Role</div> */}
      <div className="title-medium5-block1bis-v2">Phone Number</div>
      {/* <div className="title-medium5-block3"></div> */}
    </div>
  );
}

export default TitleMedium6Mobile;
