import React from "react";

function FormLineMedium6(props) {
  const styleModalGeneral = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: 300,
    borderRadius: 5,
    backgroundColor: "white",
    boxShadow: 24,
  };

  return (
    <div
      className={
        props.color === "1"
          ? "formLineMedium6 light-grey-form"
          : "formLineMedium6 grey-form"
      }
    >
      <div className="role-line-block1">{props.userEmail}</div>
      <div className="role-line-block1">{props.userName}</div>
      <div className="role-line-block1bis">{props.userRole}</div>
      <div className="role-line-block1bis">{props.userPhone}</div>
    </div>
  );
}

export default FormLineMedium6;
