import React, { useRef, useState } from "react";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../../contexts/AuthContext";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

function ViewNote(props) {
  const location = useLocation();
  const state = location.state;

  const { currentUser } = useAuth();

  //Tous les states des données récupérées par client

  const [quickNoteText, setQuickNoteText] = useState(props.note);
  const [effectiveDateTime, setEffectiveDateTime] = useState(
    props.effectiveDate
  );
  const [isLoading, setIsLoading] = useState(false);

  const printRef = useRef(); // Ajouter cette ligne
  const cancelButtonRef = React.useRef(null);

  const handleClose2 = () => setOpen2(false);
  const [open2, setOpen2] = React.useState(false);

  const handleQuickNoteChange = (event) => {
    setQuickNoteText(event);
  };

  const handleDateTimeChange = (event) => {
    setEffectiveDateTime(event);
  };

  function formatDate(timestamp) {
    const date = timestamp.toDate();
    const day = date.getDate().toString().padStart(2, "0");
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day} ${month} ${year} ${hours}:${minutes}`;
  }

  const handlePrint = () => {
    html2canvas(printRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("note.pdf");
    });
  };

  return (
    <div id="printableArea" ref={printRef}>
      <div className="inputAndLabelModalContainerTitle2">
        <div className="line1-modal">
          <div className="modal-title">
            View Note{" "}
            <span style={{ fontSize: "10px" }}>(note Id: {props.noteId})</span>
          </div>

          <CloseIcon
            style={{
              marginRight: "16px",
              color: "rgb(214, 213, 213)",
              cursor: "pointer",
            }}
            onClick={props.closemodal}
          />
        </div>

        <div className="line2-column-modal">
          <div className="label-textfield-underline">Note content</div>

          <div
            className="note-content-for-print"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {quickNoteText}
          </div>

          {/* <TextField
            name="quickNote"
            value={quickNoteText}
            onChange={handleChange}
            style={{
              width: "95%",
              borderRadius: "5px",
              backgroundColor: "#f2eeef",
            }}
            placeholder="Type quick session note here"
            multiline
            rows={14}
          /> */}
        </div>
        <div className="line2-column-modal">
          <div className="label-textfield-underline">Effective date</div>
          <div className="note-content-for-print">
            {effectiveDateTime && formatDate(effectiveDateTime)}
          </div>

          {/* <DatePickerWithTimeComponent
            title="Effective date and time"
            value={effectiveDateTime}
            // onChange={handleDateTimeChange}
          /> */}
        </div>
      </div>

      <div className="line4-modal">
        <>
          <Button
            variant="contained"
            style={{ backgroundColor: "white", color: "black" }}
            onClick={props.closemodal}
            ref={cancelButtonRef}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            style={
              isLoading
                ? {
                    backgroundColor: "red",
                    color: "white",
                    marginLeft: "15px",
                  }
                : {
                    backgroundColor: "#1565C0",
                    color: "white",
                    marginLeft: "15px",
                  }
            }
            disabled={isLoading}
            onClick={handlePrint}
          >
            {isLoading ? "DOWNLOADING NOTES..." : "DOWNLOAD NOTE"}
          </Button>
        </>
      </div>
    </div>
  );
}

export default ViewNote;
