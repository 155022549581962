import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Slide,
  Skeleton,
  TextField,
  Button,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import GroupIcon from "@mui/icons-material/Group";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloseIcon from "@mui/icons-material/Close";
import SmallClientChipV2 from "../SmallClientChipV2";
import EditQuickNote from "./EditQuickNote";
import DeleteNote from "./DeleteNote";
import ViewNote from "./ViewNote";
import DropDownSpecificStaff from "../Dropdowns/DropDownSpecificStaff";
import InputComponent from "../InputComponent";
import { useAuth } from "../../contexts/AuthContext";
import { showCustomToast } from "../Misc/CustomToast";
import { Timestamp, doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import CircularProgress from "@mui/material/CircularProgress";
import DatePickerWithTimeComponentNoTitle from "../DatePickerWithTimeComponentNoTitle";
import SmallClientChipV2Large from "../SmallClientChipV2Large";
import DropDownComponentNoTitleNoMargin from "../DropDownComponentNoTitleNoMargin";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import UploaderDocForNoteV2 from "../Updloader/UploaderDocForNoteV2";
import UploadedFilesComponentV2 from "../Updloader/UploadedFilesComponentV2";
import InputComponentNoTitleNomargin from "../InputComponentNoTitleNomargin";
import DatePickerWithTimeComponentNoTitleNoMargin from "../DatePickerWithTimeComponentNoTitleNoMargin";
import DropDownComponentSortedNoTitle from "../DropDownComponentSortedNoTitle";
import { LanguageContext } from "../../context/LanguageContext";

function NotesContainerCompact(props) {
  const { translate, language, setLanguage } = useContext(LanguageContext);
  const { isSkeleton } = props;
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [currentNoteType, setCurrentNoteType] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const [noteContent, setNoteContent] = useState(props.noteContent || "");
  const [shiftStartRaw, setShiftStartRaw] = useState(
    props.shiftStartRaw || null
  );
  const [shiftEndRaw, setShiftEndRaw] = useState(props.shiftEndRaw || null);
  const [staff, setStaff] = useState(props.staff || "");
  const [effectiveDateTime, setEffectiveDateTime] = useState(
    props.effectiveDateTime || null
  );
  const [notInAttendance, setNotInAttendance] = useState(
    props.notInAttendance || ""
  );
  const [hasChanged, setHasChanged] = useState(false);

  const [groupTopic, setGroupTopic] = useState(props.groupTopic || "");
  const [groupType, setGroupType] = useState(props.groupType || "");
  const [otherTypeGroupNote, setOtherTypeGroupNote] = useState(
    props.otherTypeGroupNote || ""
  );
  const [relationship, setRelationship] = useState(props.relationship || "");
  const [files, setFiles] = useState(props.files || []);
  const [fileUrls, setFileUrls] = useState(props.fileUrls || []);
  const [noteId, setNoteId] = useState(props.noteId || "");
  const [immigrationAppointment, setImmigrationAppointment] = useState(
    props.immigrationAppointment || ""
  );
  const [dischargeSwot, setDischargeSwot] = useState(props.dischargeSwot || "");
  const [newClients, setNewClients] = useState(props.newClients || "");
  const [medicalInfos, setMedicalInfos] = useState(props.medicalInfos || "");
  const [hospitalAppointment, setHospitalAppointment] = useState(
    props.hospitalAppointment || ""
  );
  const [residentialClientMovements, setResidentialClientMovements] = useState(
    props.residentialClientMovements || ""
  );
  const [zoomSessionAssistance, setZoomSessionAssistance] = useState(
    props.zoomSessionAssistance || ""
  );
  const [staffToday, setStaffToday] = useState(props.staffToday || "");
  const [overnight, setOvernight] = useState(props.overnight || "");
  const [numberOfClient, setNumberOfClient] = useState(
    props.numberOfClient || ""
  );
  const [swot, setSwot] = useState(props.swot || "");
  const [generalAnnouncement, setGeneralAnnouncement] = useState(
    props.generalAnnouncement || ""
  );

  const [groupStartDateTime, setGroupStartDateTime] = useState(
    props.groupStartDateTime || null
  );
  const [effectiveDate, setEffectiveDate] = useState(
    props.effectiveDate || null
  );

  const handleNoteContentChange = (event) => setNoteContent(event.target.value);
  const handleShiftStartRawChange = (date) => setShiftStartRaw(date);
  const handleShiftEndRawChange = (date) => setShiftEndRaw(date);
  const handleStaffChange = (staff) => setStaff(staff);
  const handleEffectiveDateTimeChange = (date) => setEffectiveDateTime(date);
  const handleNotInAttendanceChange = (event) =>
    setNotInAttendance(event.target.value);
  const handleGroupTopicChange = (event) => setGroupTopic(event.target.value);
  const handleGroupTypeChange = (type) => setGroupType(type);
  const handleOtherTypeGroupNoteChange = (event) =>
    setOtherTypeGroupNote(event.target.value);
  const handleRelationshipChange = (relationship) =>
    setRelationship(relationship);

  const [errors, setErrors] = useState({});

  // const handleFilesChange = (event) => {
  //   const selectedFiles = event.target.files;
  //   if (selectedFiles && selectedFiles.length <= 10) {
  //     setFiles(Array.from(selectedFiles)); // Convertir en tableau
  //     setHasChanged(true);
  //   } else {
  //     showCustomToast("You can only upload up to 10 files.", "error");
  //   }
  // };
  const handleFilesChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    if (selectedFiles.every((file) => file.size <= 50 * 1024 * 1024)) {
      // Vérifiez la taille du fichier (50 Mo)
      setFiles(selectedFiles);
    } else {
      alert("One or more files exceed the size limit of 50MB.");
    }
  };

  const handleFileRemove = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const handleFileUrlsChange = (urls) => setFileUrls(urls);
  const handleImmigrationAppointmentChange = (event) =>
    setImmigrationAppointment(event.target.value);
  const handleDischargeSwotChange = (event) =>
    setDischargeSwot(event.target.value);
  const handleNewClientsChange = (event) => setNewClients(event.target.value);
  const handleMedicalInfosChange = (event) =>
    setMedicalInfos(event.target.value);
  const handleHospitalAppointmentChange = (event) =>
    setHospitalAppointment(event.target.value);
  const handleResidentialClientMovementsChange = (event) =>
    setResidentialClientMovements(event.target.value);
  const handleZoomSessionAssistanceChange = (event) =>
    setZoomSessionAssistance(event.target.value);
  const handleStaffTodayChange = (event) => setStaffToday(event.target.value);
  const handleOvernightChange = (event) => setOvernight(event.target.value);
  const handleNumberOfClientChange = (event) =>
    setNumberOfClient(event.target.value);
  const handleSwotChange = (event) => setSwot(event.target.value);
  const handleGeneralAnnouncementChange = (event) =>
    setGeneralAnnouncement(event.target.value);

  const handleGroupStartDateTimeChange = (date) => setGroupStartDateTime(date);
  const handleEffectiveDateChange = (date) => setEffectiveDate(date);

  // ...

  useEffect(() => {
    setNoteContent(props.noteContent || "");
    setShiftStartRaw(props.shiftStartRaw || null);
    setShiftEndRaw(props.shiftEndRaw || null);
    setStaff(props.staff || "");
    setEffectiveDateTime(props.effectiveDateTime || null);
    setNotInAttendance(props.notInAttendance || "");
    setGroupTopic(props.groupTopic || "");
    setGroupType(props.groupType || "");
    setOtherTypeGroupNote(props.otherTypeGroupNote || "");
    setRelationship(props.relationship || "");
    setFiles(props.files || []);
    setFileUrls(props.fileUrls || []);
    setNoteId(props.noteId || "");
    setImmigrationAppointment(props.immigrationAppointment || "");
    setDischargeSwot(props.dischargeSwot || "");
    setNewClients(props.newClients || "");
    setMedicalInfos(props.medicalInfos || "");
    setHospitalAppointment(props.hospitalAppointment || "");
    setResidentialClientMovements(props.residentialClientMovements || "");
    setZoomSessionAssistance(props.zoomSessionAssistance || "");
    setStaffToday(props.staffToday || "");
    setOvernight(props.overnight || "");
    setNumberOfClient(props.numberOfClient || "");
    setSwot(props.swot || "");
    setGeneralAnnouncement(props.generalAnnouncement || "");
    setGroupStartDateTime(props.groupStartDateTime || null);
    setEffectiveDate(props.effectiveDate || null);
  }, [props]);

  const validateFields = () => {
    const newErrors = {};
    switch (modalContent) {
      case "aftercareNote":
      case "medicalNote":
      case "quickNote":
      case "familyNote":
      case "supportStaffNote":
      case "therapyNote":
        if (!noteContent) newErrors.noteContent = "Content is required";
        if (!effectiveDateTime)
          newErrors.effectiveDateTime = "Effective Date is required";
        if (!props.clientFirstName) newErrors.clientName = "Client is required";
        break;
      case "handoverNote":
        if (!generalAnnouncement)
          newErrors.generalAnnouncement = "General Announcement is required";
        if (!effectiveDateTime)
          newErrors.effectiveDateTime = "Effective Date is required";
        break;
      case "groupNote":
        if (!groupTopic) newErrors.groupTopic = "Group Topic is required";
        if (!groupStartDateTime)
          newErrors.groupStartDateTime = "Start Time is required";
        if (!groupType) newErrors.groupType = "Group Type is required";
        if (!noteContent) newErrors.noteContent = "Content is required";
        if (!notInAttendance)
          newErrors.notInAttendance = "Clients Not Present is required";
        if (!effectiveDateTime)
          newErrors.effectiveDateTime = "Effective Date is required";
        break;
      case "endOfShift":
        if (!noteContent) newErrors.noteContent = "Content is required";
        if (!shiftStartRaw)
          newErrors.shiftStartRaw = "Start of Shift is required";
        if (!shiftEndRaw) newErrors.shiftEndRaw = "End of Shift is required";
        if (!staff) newErrors.staff = "Staff is required";
        if (!effectiveDateTime)
          newErrors.effectiveDateTime = "Effective Date is required";
        break;
      default:
        break;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const getTitleForNoteType = (noteType) => {
    switch (noteType) {
      case "endOfShift":
        return translate("EditEndOfShiftNote");
      case "familyNote":
        return translate("EditFamilyNote");
      case "groupNote":
        return translate("EditGroupNote");
      case "handoverNote":
        return translate("EditHandoverNote");
      case "medicalNote":
        return translate("EditMedicalNote");
      case "quickNote":
        return translate("EditQuickNote");
      case "therapyNote":
        return translate("EditTherapyNote");
      case "supportStaffNote":
        return translate("EditSupportStaffNote");
      case "aftercareNote":
        return translate("EditAftercareNote");
      default:
        return "Edit Note";
    }
  };

  const handleOpenNoteModal = (noteType) => () => {
    setCurrentNoteType(noteType);
    setModalContent(noteType);
    setOpenNoteModal(true);
  };

  const uploadFilesAndGetUrls = async (noteId, files) => {
    if (!Array.isArray(files)) {
      return [];
    }

    const storage = getStorage();
    const uploadedFileUrls = [];

    for (const file of files) {
      const filePath = `Documents/familyNotes/${noteId}/${file.name}`;
      const storageRef = ref(storage, filePath);

      await uploadBytes(storageRef, file);
      const fileUrl = await getDownloadURL(storageRef);
      uploadedFileUrls.push({ url: fileUrl, name: file.name });
    }

    return uploadedFileUrls;
  };

  const deleteFile = async (fileUrl, fileName, noteId) => {
    const storage = getStorage();
    const filePath = `Documents/familyNotes/${noteId}/${fileName}`;
    const storageRef = ref(storage, filePath);

    try {
      await deleteObject(storageRef);
      const newFileLinks = fileUrls.filter((file) => file.url !== fileUrl);
      setFileUrls(newFileLinks);
      const noteRef = doc(db, "notes", noteId);

      await updateDoc(noteRef, {
        fileUrls: newFileLinks.map((file) => ({
          url: file.url,
          name: file.name,
        })),
      });

      showCustomToast("File successfully deleted!", "success");
    } catch (error) {
      console.log("Error deleting file:", error);
      showCustomToast("Error deleting file", "error");
    }
  };

  // const deleteFile = async (fileUrl, fileName, noteId) => {
  //   try {
  //     showCustomToast("Demo version - modifications not allowed :)", "info");
  //   } catch (error) {
  //     console.log("Error deleting file:", error);
  //     showCustomToast("Error deleting file", "error");
  //   }
  // };

  const renderEditModalContent = () => {
    switch (modalContent) {
      case "supportStaffNote":
        return (
          <div>
            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
              <div
                className="label-textfield-handover"
                style={{
                  color: "black",
                  fontWeight: "600",
                  marginTop: "10px",
                }}
              >
                {translate("ClientName")}
              </div>
              <div
                style={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  marginTop: "10px",
                  width: "100%",
                  border: "1px solid lightgrey",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <SmallClientChipV2Large
                  firstName={props.clientFirstName}
                  lastName={props.clientLastName}
                  avatar="1"
                  clientPic={props.clientPic}
                />
              </div>
            </div>
            <div style={{ width: "100%", marginTop: "0px", height: "100%" }}>
              <div className="line2-column-modal">
                <div
                  className="label-textfield-handover"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    paddingLeft: "15px",
                    paddingBottom: "5px",
                  }}
                >
                  {translate("NoteContent")}
                </div>
                <TextField
                  name="quickNote"
                  value={noteContent}
                  onChange={handleNoteContentChange}
                  style={{
                    width: "95%",
                    borderRadius: "5px",
                  }}
                  placeholder="Type quick session note here"
                  multiline
                  rows={14}
                />
              </div>
              <div
                // className="line3-modal"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  className="label-textfield-handover"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    marginTop: "20px",
                    paddingLeft: "20px",
                    paddingBottom: "5px",
                  }}
                >
                  {translate("EffectiveDateTime")}
                </div>
                <div style={{ paddingLeft: "15px" }}>
                  <DatePickerWithTimeComponentNoTitle
                    title={translate("EffectiveDateTime")}
                    value={effectiveDateTime}
                    onChange={handleEffectiveDateTimeChange}
                  />
                </div>
              </div>
            </div>
          </div>
        );

      case "endOfShift":
        return (
          <div>
            <div className="line2-column-modal-slider">
              <div
                className="label-textfield"
                style={{
                  color: "black",
                  fontWeight: "600",
                  marginTop: "10px",
                  paddingLeft: "0px",
                  paddingBottom: "0px",
                }}
              >
                {translate("NoteContent")}
              </div>
              <TextField
                value={noteContent}
                onChange={handleNoteContentChange}
                style={{
                  width: "95%",
                  borderRadius: "5px",
                  // backgroundColor: "#f2eeef",
                }}
                placeholder={translate("TypeNoteHere")}
                multiline
                rows={12}
              />
            </div>
            <div className="line3-modal-mobile-bis">
              {console.log("shiftStartRaw:", shiftStartRaw)}

              <div style={{ marginLeft: "0px" }}>
                <div
                  className="label-textfield-handover"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    marginTop: "20px",
                    paddingLeft: "10px",
                    paddingBottom: "0px",
                  }}
                >
                  {translate("StartOfShift")}
                </div>
                <DatePickerWithTimeComponentNoTitleNoMargin
                  value={shiftStartRaw}
                  onChange={handleShiftStartRawChange}
                  // title="Start of Shift"
                />
              </div>

              <div style={{}}>
                <div
                  className="label-textfield-handover"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    marginTop: "20px",
                    paddingLeft: "10px",
                    paddingBottom: "0px",
                  }}
                >
                  {translate("StartOfShift")}
                </div>
                <DatePickerWithTimeComponentNoTitleNoMargin
                  value={shiftEndRaw}
                  onChange={handleShiftEndRawChange}
                  // title="End of Shift"
                />
              </div>
            </div>
            <div
              // className="line3-modal-mobile-bis"
              style={{ width: "50%", paddingLeft: "20px" }}
            >
              <div
                className="label-textfield-handover"
                style={{
                  color: "black",
                  fontWeight: "600",
                  marginTop: "20px",
                  paddingLeft: "10px",
                  paddingBottom: "0px",
                }}
              >
                {translate("SelectedStaff")}
              </div>
              <DropDownSpecificStaff
                value={staff}
                onChange={handleStaffChange}
                // title="Select Staff"
              />
            </div>
            <div
              // className="line3-modal"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                paddingLeft: "5px",
              }}
            >
              <div
                className="label-textfield-handover"
                style={{
                  color: "black",
                  fontWeight: "600",
                  marginTop: "20px",
                  paddingLeft: "20px",
                  paddingBottom: "5px",
                }}
              >
                {translate("EffectiveDateTime")}
              </div>
              <div style={{ paddingLeft: "15px" }}>
                <DatePickerWithTimeComponentNoTitle
                  title={translate("EffectiveDateTime")}
                  value={effectiveDateTime}
                  onChange={handleEffectiveDateTimeChange}
                />
              </div>
            </div>
          </div>
        );

      case "aftercareNote":
        return (
          <div>
            <div className="inputAndLabelModalContainerTitle2">
              <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <div
                  className="label-textfield-handover"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    marginTop: "10px",
                  }}
                >
                  {translate("ClientName")}
                </div>
                <div
                  style={{
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    marginTop: "10px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    border: errors.clientName
                      ? "1px solid red"
                      : "1px solid lightgrey",
                  }}
                >
                  <SmallClientChipV2Large
                    firstName={props.clientFirstName}
                    lastName={props.clientLastName}
                    avatar="1"
                    clientPic={props.clientPic}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: "0px",
                  height: "75%",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <div className="line2-column-modal">
                  <div
                    className="label-textfield-handover"
                    style={{
                      color: "black",
                      fontWeight: "600",
                      paddingLeft: "15px",
                      paddingBottom: "5px",
                    }}
                  >
                    {translate("NoteContent")}
                  </div>
                  <TextField
                    name="noteContent"
                    value={noteContent}
                    onChange={handleNoteContentChange}
                    style={{
                      width: "95%",
                      borderRadius: "5px",
                      borderColor: errors.noteContent ? "red" : "default",
                    }}
                    placeholder="Type aftercare session note here"
                    multiline
                    rows={14}
                    error={!!errors.noteContent}
                    helperText={errors.noteContent}
                  />
                </div>
                <div
                  // className="line3-modal"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    className="label-textfield-handover"
                    style={{
                      color: "black",
                      fontWeight: "600",
                      marginTop: "20px",
                      paddingLeft: "20px",
                      paddingBottom: "5px",
                    }}
                  >
                    {translate("EffectiveDateTime")}
                  </div>
                  <div
                    style={{
                      paddingLeft: "15px",
                      borderColor: errors.effectiveDateTime ? "red" : "default",
                    }}
                  >
                    <DatePickerWithTimeComponentNoTitle
                      title={translate("EffectiveDateTime")}
                      value={effectiveDateTime}
                      onChange={handleEffectiveDateTimeChange}
                      error={!!errors.effectiveDateTime}
                      helperText={errors.effectiveDateTime}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      case "familyNote":
        return (
          <div>
            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
              <div
                className="label-textfield-handover"
                style={{
                  color: "black",
                  fontWeight: "600",
                  marginTop: "10px",
                }}
              >
                {translate("ClientName")}
              </div>
              <div
                style={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  marginTop: "10px",
                  width: "100%",
                  border: "1px solid lightgrey",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <SmallClientChipV2Large
                  firstName={props.clientFirstName}
                  lastName={props.clientLastName}
                  avatar="1"
                  clientPic={props.clientPic}
                />
              </div>
            </div>

            <div className="line2-column-modal">
              <div
                className="label-textfield-handover"
                style={{
                  color: "black",
                  fontWeight: "600",
                  paddingLeft: "15px",
                  paddingBottom: "5px",
                }}
              >
                {translate("NoteContent")}
              </div>
              <TextField
                name="quickNote"
                value={noteContent}
                onChange={handleNoteContentChange}
                style={{
                  width: "95%",
                  borderRadius: "5px",
                }}
                placeholder="Type quick session note here"
                multiline
                rows={12}
              />
            </div>
            <div className="line3-modal-mobile"></div>
            <div
              // className="line3-modal"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                paddingBottom: "0px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "50%",
                  flexDirection: "column",
                }}
              >
                <div
                  className="label-textfield-handover"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    marginTop: "20px",
                    paddingLeft: "20px",
                    paddingBottom: "5px",
                  }}
                >
                  {translate("EffectiveDateTime")}
                </div>
                <div style={{ paddingLeft: "15px" }}>
                  <DatePickerWithTimeComponentNoTitle
                    title={translate("EffectiveDateTime")}
                    value={effectiveDateTime}
                    onChange={handleEffectiveDateTimeChange}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "50%",
                  flexDirection: "column",
                  paddingRight: "25px",
                }}
              >
                <div
                  className="label-textfield-handover"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    marginTop: "20px",
                    paddingLeft: "20px",
                    paddingBottom: "5px",
                  }}
                >
                  {translate("FamilyNoteRelationship")}
                </div>
                <div style={{}}>
                  <DropDownComponentNoTitleNoMargin
                    title={translate("FamilyNoteRelationship")}
                    api="livingWith"
                    value={relationship}
                    onChange={handleRelationshipChange}
                  />
                </div>
              </div>
            </div>
            <div className="main-upload-container">
              <UploaderDocForNoteV2
                handleFileChange={handleFilesChange}
                files={files}
                handleFileRemove={handleFileRemove}
              />

              {fileUrls.length > 0 && (
                <UploadedFilesComponentV2
                  fileLinks={fileUrls}
                  // deleteFile={(fileUrl, fileName, noteId) =>
                  //   props.deleteFile(fileUrl, fileName, noteId)
                  // }
                  deleteFile={(fileUrl, fileName, noteId) =>
                    deleteFile(fileUrl, fileName, noteId)
                  }
                  noteId={noteId}
                />
              )}
            </div>
          </div>
        );

      case "groupNote":
        return (
          <div>
            <div className="inputAndLabelModalContainerTitle2">
              <div style={{ paddingLeft: "10px", paddingRight: "20px" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="label-textfield-handover"
                    style={{
                      color: "black",
                      fontWeight: "600",
                      paddingLeft: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    {translate("GroupTopic")}
                  </div>

                  <InputComponentNoTitleNomargin
                    isRequired={true}
                    title="Group topic"
                    width="300px"
                    value={groupTopic}
                    onChange={handleGroupTopicChange}
                  />
                </div>
              </div>

              <div
                // className="line3-modal"
                style={{
                  paddingLeft: "15px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignContent: "start",
                  width: "50%",
                  paddingTop: "10px",
                }}
              >
                <div
                  className="label-textfield-handover"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    marginTop: "10px",
                    width: "300px",
                  }}
                >
                  {translate("GroupDateStartTime")}
                </div>
                <div style={{ paddingTop: "5px", paddingLeft: "5px" }}>
                  <DatePickerWithTimeComponentNoTitleNoMargin
                    isRequired={true}
                    value={effectiveDateTime}
                    title="Group Date and start time"
                    width="400px"
                    onChange={handleEffectiveDateTimeChange}
                    leftMargin="none"
                  />
                </div>
              </div>
              <div
                style={{
                  paddingLeft: "10px",
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: "10px",
                }}
              >
                <div
                  className="label-textfield-handover"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    marginTop: "10px",
                    paddingLeft: "5px",
                  }}
                >
                  {translate("TypeOfGroup")}
                </div>
                <DropDownComponentSortedNoTitle
                  isRequired={true}
                  api="groupType"
                  title="Type of group"
                  value={groupType}
                  onChange={handleGroupTypeChange}
                />

                {groupType === "Other" && (
                  <InputComponent
                    title="if other, describe"
                    width="auto"
                    value={otherTypeGroupNote}
                    onChange={handleOtherTypeGroupNoteChange}
                  />
                )}
              </div>

              <div
                // className="line2-column-modal"
                style={{ paddingLeft: "20px", paddingTop: "20px" }}
              >
                <div
                  // className="label-textfield"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    paddingLeft: "0px",
                    paddingBottom: "5px",
                    // backgroundColor: "lightyellow",
                  }}
                >
                  {translate("GroupNote")}
                  <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
                </div>
                <TextField
                  style={{
                    width: "95%",
                    borderRadius: "5px",
                  }}
                  placeholder={translate("TypeGroupNoteHere")}
                  multiline
                  rows={10}
                  value={noteContent}
                  onChange={handleNoteContentChange}
                />
              </div>
              <div className="line2-column-modal">
                <div
                  className="label-textfield"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    paddingLeft: "0px",
                    paddingBottom: "5px",
                  }}
                >
                  {translate("ClientsNotInAttendance")}
                  <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
                </div>
                <TextField
                  style={{
                    width: "95%",
                    borderRadius: "5px",
                    // backgroundColor: "#f2eeef",
                  }}
                  placeholder="Type names here (first name / or preferred name and first initial of last name) and reason why client is not in group, if known..."
                  multiline
                  rows={4}
                  value={notInAttendance}
                  onChange={handleNotInAttendanceChange}
                />
              </div>
              <div
                // className="line3-modal"
                style={{
                  // paddingLeft: "10px",
                  // marginBottom: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  marginBottom: "30px",
                }}
              ></div>
            </div>
          </div>
        );

      case "handoverNote":
        return (
          <div>
            <div
              style={{
                width: "100%",
                marginTop: "0px",
                height: "75%", // overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              <div className="line2-column-modal-handover">
                <div
                  className="tab-form-container-handover"
                  style={{ marginTop: "0px" }}
                >
                  <div className="textfield-and-label-container100">
                    <div
                      className="label-textfield-handover"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      {translate("ImmigrationAppointment")}
                    </div>
                    <TextField
                      value={immigrationAppointment}
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      placeholder="..."
                      multiline
                      rows={5}
                      onChange={(e) =>
                        setImmigrationAppointment(e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="tab-form-container-handover">
                  <div className="textfield-and-label-container100">
                    <div
                      className="label-textfield-handover"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      {translate("DischargesFlights")}
                    </div>
                    <TextField
                      value={dischargeSwot}
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      placeholder="..."
                      multiline
                      rows={5}
                      onChange={(e) => setDischargeSwot(e.target.value)}
                    />
                  </div>
                </div>
                <div className="tab-form-container-handover">
                  <div className="textfield-and-label-container100">
                    <div
                      className="label-textfield-handover"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      {translate("NewClients")}
                    </div>
                    <TextField
                      value={newClients}
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      placeholder="..."
                      multiline
                      rows={5}
                      onChange={(e) => setNewClients(e.target.value)}
                    />
                  </div>
                </div>
                <div className="tab-form-container-handover">
                  <div className="textfield-and-label-container100">
                    <div
                      className="label-textfield-handover"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      {translate("PsychiatristAppointments")}
                    </div>
                    <TextField
                      value={medicalInfos}
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      placeholder="..."
                      multiline
                      rows={5}
                      onChange={(e) => setMedicalInfos(e.target.value)}
                    />
                  </div>
                </div>
                <div className="tab-form-container-handover">
                  <div className="textfield-and-label-container100">
                    <div
                      className="label-textfield-handover"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      {translate("HospitalAppointments")}
                    </div>
                    <TextField
                      value={hospitalAppointment}
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      placeholder="..."
                      multiline
                      rows={5}
                      onChange={(e) => setHospitalAppointment(e.target.value)}
                    />
                  </div>
                </div>
                <div className="tab-form-container-handover">
                  <div className="textfield-and-label-container100">
                    <div
                      className="label-textfield-handover"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      {translate("Outings")}
                    </div>
                    <TextField
                      value={residentialClientMovements}
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      placeholder="..."
                      multiline
                      rows={5}
                      onChange={(e) =>
                        setResidentialClientMovements(e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="tab-form-container-handover">
                  <div className="textfield-and-label-container100">
                    <div
                      className="label-textfield-handover"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      {translate("ZoomSessionAssistance")}
                    </div>
                    <TextField
                      value={zoomSessionAssistance}
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      placeholder="..."
                      multiline
                      rows={5}
                      onChange={(e) => setZoomSessionAssistance(e.target.value)}
                    />
                  </div>
                </div>
                <div className="tab-form-container-handover">
                  <div className="textfield-and-label-container100">
                    <div
                      className="label-textfield-handover"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      {translate("StaffToday")}
                    </div>
                    <TextField
                      value={staffToday}
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      placeholder="..."
                      multiline
                      rows={5}
                      onChange={(e) => setStaffToday(e.target.value)}
                    />
                  </div>
                </div>
                <div className="tab-form-container-handover">
                  <div className="textfield-and-label-container100">
                    <div
                      className="label-textfield-handover"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      {translate("Overnight")}
                    </div>
                    <TextField
                      value={overnight}
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      placeholder="..."
                      multiline
                      rows={5}
                      onChange={(e) => setOvernight(e.target.value)}
                    />
                  </div>
                </div>
                <div className="tab-form-container-handover">
                  <div className="textfield-and-label-container100">
                    <div
                      className="label-textfield-handover"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      {translate("NumberOfClients")}
                    </div>
                    <TextField
                      value={numberOfClient}
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      placeholder="..."
                      multiline
                      rows={5}
                      onChange={(e) => setNumberOfClient(e.target.value)}
                    />
                  </div>
                </div>
                <div className="tab-form-container-handover">
                  <div
                    className="textfield-and-label-container100"
                    style={{ marginBottom: "10px" }}
                  >
                    <div
                      className="label-textfield-handover"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      {translate("Swot")}
                    </div>
                    <TextField
                      value={swot}
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      placeholder="..."
                      multiline
                      rows={5}
                      onChange={(e) => setSwot(e.target.value)}
                    />
                  </div>
                  <div className="textfield-and-label-container100">
                    <div
                      className="label-textfield-handover"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      {translate("GeneralAnnouncement")}
                    </div>
                    <TextField
                      value={generalAnnouncement}
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      placeholder={translate("TypeMedicalNoteHere")}
                      multiline
                      rows={5}
                      onChange={(e) => setGeneralAnnouncement(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div
                // className="line3-modal"
                style={{
                  paddingLeft: "10px",
                  marginTop: "15px",
                  marginBottom: "40px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  className="label-textfield-handover"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    paddingLeft: "10px",
                  }}
                >
                  {translate("EffectiveDateTime")}
                </div>
                <div style={{ paddingLeft: "5px" }}>
                  <DatePickerWithTimeComponentNoTitle
                    value={effectiveDateTime}
                    onChange={handleEffectiveDateTimeChange}
                    title={translate("EffectiveDateTime")}
                  />
                </div>
              </div>
            </div>
          </div>
        );

      case "medicalNote":
        return (
          <div>
            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
              <div
                className="label-textfield-handover"
                style={{
                  color: "black",
                  fontWeight: "600",
                  marginTop: "10px",
                }}
              >
                {translate("ClientName")}
              </div>
              <div
                style={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  marginTop: "10px",
                  width: "100%",
                  border: "1px solid lightgrey",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <SmallClientChipV2Large
                  firstName={props.clientFirstName}
                  lastName={props.clientLastName}
                  avatar="1"
                  clientPic={props.clientPic}
                />
              </div>
            </div>
            <div className="line2-column-modal">
              <div
                className="label-textfield-handover"
                style={{
                  color: "black",
                  fontWeight: "600",
                  paddingLeft: "15px",
                  paddingBottom: "5px",
                }}
              >
                {translate("NoteContent")}
              </div>
              <TextField
                name="quickNote"
                value={noteContent}
                onChange={handleNoteContentChange}
                style={{
                  width: "95%",
                  borderRadius: "5px",
                }}
                placeholder="Type quick session note here"
                multiline
                rows={14}
              />
            </div>
            <div
              style={{
                display: "flex",
                width: "50%",
                flexDirection: "column",
              }}
            >
              <div
                className="label-textfield-handover"
                style={{
                  color: "black",
                  fontWeight: "600",
                  marginTop: "20px",
                  paddingLeft: "20px",
                  paddingBottom: "5px",
                }}
              >
                {translate("EffectiveDateTime")}
              </div>
              <div style={{ paddingLeft: "15px" }}>
                <DatePickerWithTimeComponentNoTitle
                  title={translate("EffectiveDateTime")}
                  value={effectiveDateTime}
                  onChange={handleEffectiveDateTimeChange}
                />
              </div>
            </div>
            <div className="main-upload-container">
              <UploaderDocForNoteV2
                handleFileChange={handleFilesChange}
                files={files}
                handleFileRemove={handleFileRemove}
              />
              {/* <UploadedFilesComponent
                fileLinks={fileUrls}
                deleteFile={(fileUrl, fileName, noteId) =>
                  props.deleteFile(fileUrl, fileName, noteId)
                }
                noteId={noteId}
              /> */}
              {fileUrls.length > 0 && (
                <UploadedFilesComponentV2
                  fileLinks={fileUrls}
                  // deleteFile={(fileUrl, fileName, noteId) =>
                  //   props.deleteFile(fileUrl, fileName, noteId)
                  // }
                  deleteFile={(fileUrl, fileName, noteId) =>
                    deleteFile(fileUrl, fileName, noteId)
                  }
                  noteId={noteId}
                />
              )}
            </div>
          </div>
        );

      case "quickNote":
        return (
          <div>
            <div className="inputAndLabelModalContainerTitle2">
              <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <div
                  className="label-textfield-handover"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    marginTop: "10px",
                  }}
                >
                  {translate("ClientName")}
                </div>
                <div
                  style={{
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    marginTop: "10px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <SmallClientChipV2Large
                    firstName={props.clientFirstName}
                    lastName={props.clientLastName}
                    avatar="1"
                    clientPic={props.clientPic}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: "0px",
                  height: "75%",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <div className="line2-column-modal">
                  <div
                    className="label-textfield-handover"
                    style={{
                      color: "black",
                      fontWeight: "600",
                      paddingLeft: "15px",
                      paddingBottom: "5px",
                    }}
                  >
                    {translate("NoteContent")}
                  </div>
                  <TextField
                    name="quickNote"
                    value={noteContent}
                    onChange={handleNoteContentChange}
                    style={{
                      width: "95%",
                      borderRadius: "5px",
                      // backgroundColor: "#f2eeef",
                    }}
                    placeholder="Type quick session note here"
                    multiline
                    rows={14}
                  />
                </div>
                <div
                  // className="line3-modal"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    className="label-textfield-handover"
                    style={{
                      color: "black",
                      fontWeight: "600",
                      marginTop: "20px",
                      paddingLeft: "20px",
                      paddingBottom: "5px",
                    }}
                  >
                    {translate("EffectiveDateTime")}
                  </div>
                  <div style={{ paddingLeft: "15px" }}>
                    <DatePickerWithTimeComponentNoTitle
                      title={translate("EffectiveDateTime")}
                      value={effectiveDateTime}
                      onChange={handleEffectiveDateTimeChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      case "therapyNote":
        return (
          <div>
            <div className="inputAndLabelModalContainerTitle2">
              <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <div
                  className="label-textfield-handover"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    marginTop: "10px",
                  }}
                >
                  {translate("ClientName")}
                </div>
                <div
                  style={{
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    marginTop: "10px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <SmallClientChipV2Large
                    firstName={props.clientFirstName}
                    lastName={props.clientLastName}
                    avatar="1"
                    clientPic={props.clientPic}
                  />
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  marginTop: "0px",
                  height: "75%",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <div className="line2-column-modal">
                  <div
                    className="label-textfield-handover"
                    style={{
                      color: "black",
                      fontWeight: "600",
                      paddingLeft: "15px",
                      paddingBottom: "5px",
                    }}
                  >
                    {translate("NoteContent")}
                  </div>
                  {/* <div className="label-textfield">Note content</div> */}
                  <TextField
                    value={noteContent}
                    onChange={handleNoteContentChange}
                    style={{
                      width: "95%",
                      borderRadius: "5px",
                      // backgroundColor: "#f2eeef",
                    }}
                    placeholder="Type 1 to 1 therapy session note here"
                    multiline
                    rows={14}
                  />
                </div>

                <div
                  // className="line3-modal"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    className="label-textfield-handover"
                    style={{
                      color: "black",
                      fontWeight: "600",
                      marginTop: "20px",
                      paddingLeft: "20px",
                      paddingBottom: "5px",
                    }}
                  >
                    {translate("EffectiveDateTime")}
                  </div>
                  <div style={{ paddingLeft: "15px" }}>
                    <DatePickerWithTimeComponentNoTitle
                      title={translate("EffectiveDateTime")}
                      value={effectiveDateTime}
                      onChange={handleEffectiveDateTimeChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const handleSaveNote = async () => {
    setIsLoading(true);
    try {
      let noteData = {};
      const noteId = props.noteId;

      const getValidTimestamp = (date) => {
        return date instanceof Date && !isNaN(date)
          ? Timestamp.fromDate(new Date(date))
          : null;
      };

      switch (modalContent) {
        case "endOfShift":
          noteData = {
            noteContent,
            shiftStart: getValidTimestamp(shiftStartRaw),
            shiftEnd: getValidTimestamp(shiftEndRaw),
            staff,
            updatedBy: currentUser.displayName,
            updatedAt: Timestamp.now(),
          };
          break;
        case "familyNote":
          const newFileUrls = await uploadFilesAndGetUrls(noteId, files);
          const updatedFileLinks = [...fileUrls, ...newFileUrls];
          noteData = {
            noteContent,
            effectiveDateTime: effectiveDateTime,
            relationship,
            // files,
            // fileUrls,
            fileUrls: updatedFileLinks,
            updatedBy: currentUser.displayName,
            updatedAt: Timestamp.now(),
          };
          break;
        case "groupNote":
          noteData = {
            noteContent,
            groupTopic,
            groupStartDateTime: effectiveDateTime,
            groupType,
            otherTypeGroupNote,
            notInAttendance,
            effectiveDateTime: effectiveDateTime,
            updatedBy: currentUser.displayName,
            updatedAt: Timestamp.now(),
          };
          break;
        case "handoverNote":
          noteData = {
            noteContent,
            immigrationAppointment,
            dischargeSwot,
            newClients,
            medicalInfos,
            hospitalAppointment,
            residentialClientMovements,
            zoomSessionAssistance,
            staffToday,
            overnight,
            numberOfClient,
            swot,
            generalAnnouncement,
            effectiveDateTime: effectiveDateTime,
            updatedBy: currentUser.displayName,
            updatedAt: Timestamp.now(),
          };
          break;
        case "medicalNote":
          const newFileUrlsMedical = await uploadFilesAndGetUrls(noteId, files);
          const updatedFileLinksMedical = [...fileUrls, ...newFileUrlsMedical];
          noteData = {
            noteContent,
            effectiveDateTime: effectiveDateTime,
            // files,
            fileUrls: updatedFileLinksMedical,
            updatedBy: currentUser.displayName,
            updatedAt: Timestamp.now(),
          };
          break;
        case "quickNote":
          noteData = {
            noteContent,
            effectiveDateTime: effectiveDateTime,
            updatedBy: currentUser.displayName,
            updatedAt: Timestamp.now(),
          };
          break;
        case "therapyNote":
          noteData = {
            noteContent,
            effectiveDateTime: effectiveDateTime,
            updatedBy: currentUser.displayName,
            updatedAt: Timestamp.now(),
          };
          break;
        case "supportStaffNote":
          noteData = {
            noteContent,
            effectiveDateTime: effectiveDateTime,
            updatedBy: currentUser.displayName,
            updatedAt: Timestamp.now(),
          };
          break;
        case "aftercareNote":
          noteData = {
            noteContent,
            effectiveDateTime: effectiveDateTime,
            updatedBy: currentUser.displayName,
            updatedAt: Timestamp.now(),
          };
          break;
        default:
          throw new Error("Unknown note type");
      }

      const docRef = doc(db, "notes", noteId);
      await updateDoc(docRef, noteData);
      showCustomToast(
        `${modalContent
          .replace(/([A-Z])/g, " $1")
          .trim()} Note successfully updated!`,
        "success"
      );
      setOpenNoteModal(false);
      resetFiles();
      handleMenuClose();
    } catch (error) {
      console.error("Error updating note:", error);
      showCustomToast("Problem when updating note!", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseNoteModal = () => {
    setOpenNoteModal(false);
    setModalContent(null);
    setFiles([]); // Réinitialiser l'état des fichiers
    handleMenuClose();
  };

  const resetFiles = () => {
    setFiles([]);
  };

  const styleLive = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: 600,
    borderRadius: 5,
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const styleEdit = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    height: "80%",
    borderRadius: 5,
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const styleDeleteModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    height: 260,
    borderRadius: 2,
    bgcolor: "background.paper",
    /*border: "1px solid #000",*/
    boxShadow: 24,
    // p: 4,
  };

  const getIconForNoteType = (noteType) => {
    switch (noteType) {
      case "endOfShift":
        return (
          <AccessTimeIcon
            style={{
              fontSize: "40px",
              color: getStylesForNoteType(noteType).color,
            }}
          />
        );
      case "groupNote":
        return (
          <GroupIcon
            style={{
              fontSize: "40px",
              color: getStylesForNoteType(noteType).color,
            }}
          />
        );
      case "handoverNote":
        return (
          <SwapHorizIcon
            style={{
              fontSize: "40px",
              color: getStylesForNoteType(noteType).color,
            }}
          />
        );
      default:
        return null;
    }
  };

  const getStylesForNoteType = (noteType) => {
    switch (noteType) {
      case "quickNote":
        return { backgroundColor: "#FFFACD", color: "orange" };
      case "handoverNote":
        return { backgroundColor: "#B0E0E6", color: "#4682B4" };
      case "therapyNote":
        return { backgroundColor: "#98FB98", color: "#2E8B57" };
      case "supportStaffNote":
        return { backgroundColor: "#FFC0CB", color: "#CD5C5C" };
      case "medicalNote":
        return { backgroundColor: "#FFDAB9", color: "#DB7093" };
      case "groupNote":
        return { backgroundColor: "#FFDAB9", color: "#FF4500" };
      case "familyNote":
        return { backgroundColor: "#FAFAD2", color: "#B8860B" };
      case "aftercareNote":
        return { backgroundColor: "#ADD8E6", color: "#4682B4" };
      case "endOfShift":
        return { backgroundColor: "#D3D3D3", color: "#696969" };
      default:
        return { backgroundColor: "white", color: "black" };
    }
  };

  const formatNoteType = (noteType) => {
    switch (noteType) {
      case "quickNote":
        return "QUICK NOTE";
      case "handoverNote":
        return "HANDOVER NOTE";
      case "therapyNote":
        return "THERAPY NOTE";
      case "supportStaffNote":
        return "SUPPORT STAFF NOTE";
      case "medicalNote":
        return "MEDICAL NOTE";
      case "groupNote":
        return "GROUP NOTE";
      case "familyNote":
        return "FAMILY NOTE";
      case "aftercareNote":
        return "AFTERCARE NOTE";
      case "endOfShift":
        return "END OF SHIFT";
      default:
        return noteType.toUpperCase();
    }
  };

  const navigate = useNavigate();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);

  const [shouldNavigate, setShouldNavigate] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const openEditModal = () => {
    setShowEditModal(true);
  };
  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };
  const openViewModal = () => {
    setShowViewModal(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const closeViewModal = () => {
    setShowViewModal(false);
  };

  const handleNavigate = () => {
    setShouldNavigate(true);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (shouldNavigate) {
      navigate(`/client/${props.clientId}`, {
        state: props,
      });
    }
  }, [shouldNavigate]);

  if (isSkeleton) {
    return (
      <div
        style={{
          width: "100%",
          height: "80px",
          display: "flex",
          flexDirection: "row",
          borderBottom: "1px solid lightgrey",
          padding: "10px",
        }}
      >
        <Skeleton
          variant="rectangular"
          width={80}
          height={80}
          style={{ marginRight: "15px" }}
        />
        <div style={{ flex: 1 }}>
          <Skeleton variant="text" width="60%" height={30} />
          <Skeleton
            variant="text"
            width="80%"
            height={20}
            style={{ marginTop: 10 }}
          />
        </div>
      </div>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <Modal
        open={openNoteModal}
        onClose={() => setOpenNoteModal(false)}
        aria-labelledby="note-modal-title"
        aria-describedby="note-modal-description"
        closeAfterTransition
      >
        <Slide direction="left" in={openNoteModal}>
          <Box
            sx={{
              position: "fixed",
              top: "5%",
              right: 0,
              width: "30%",
              height: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              marginRight: "15px",
              pl: 0,
              pr: 0,
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1,
                borderBottom: "1px solid lightgray",
              }}
            >
              <div
                id="note-modal-title"
                style={{
                  margin: 0,
                  paddingLeft: "35px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  fontSize: "22px",
                  fontWeight: "700",
                }}
              >
                {getTitleForNoteType(modalContent)}
              </div>
              <div style={{ paddingRight: "15px" }}>
                <IconButton
                  // onClick={() => setOpenNoteModal(false)}
                  onClick={handleCloseNoteModal}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </Box>
            <Box
              id="note-modal-description"
              sx={{
                flexGrow: 1,
                overflowY: "auto",
                mt: 0,
                pl: 2,
                pr: 2,
              }}
            >
              {/* Ajoutez ici le contenu dynamique basé sur le type de note */}
              {/* {modalContent} */}
              {renderEditModalContent()}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                borderTop: "1px solid lightgray",
                padding: "10px 20px",
                mt: "auto",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: "10px" }}
                // onClick={() => setOpenNoteModal(false)}
                onClick={handleCloseNoteModal}
              >
                {translate("Cancel")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveNote}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : translate("Save")}
              </Button>
            </Box>
          </Box>
        </Slide>
      </Modal>

      <Modal
        open={showEditModal}
        onClose={closeEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleEdit}>
          <EditQuickNote
            note={props.noteContent}
            noteId={props.noteId}
            typeOfNote={props.noteType}
            effectiveDate={props.effectiveDate}
            closemodal={closeEditModal}
          />
        </Box>
      </Modal>

      <Modal
        open={showDeleteModal}
        onClose={closeDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleDeleteModal}>
          <DeleteNote
            note={props.noteContent}
            noteId={props.noteId}
            typeOfNote={props.noteType}
            effectiveDate={props.effectiveDate}
            closemodal={closeDeleteModal}
          />
        </Box>
      </Modal>

      <Modal
        open={showViewModal}
        onClose={closeViewModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleLive}>
          <ViewNote
            note={props.noteContent}
            noteId={props.noteId}
            typeOfNote={props.noteType}
            effectiveDate={props.effectiveDate}
            closemodal={closeViewModal}
          />
        </Box>
      </Modal>
      <div
        style={{
          width: "100%",
          height: "80px",
          display: "flex",
          flexDirection: "row",
          borderBottom: "1px solid lightgrey",
          cursor: "pointer",
          transition: "background-color 0.3s ease",
        }}
        onMouseEnter={(e) =>
          (e.currentTarget.style.backgroundColor = "#f9f9f9")
        }
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "white")}
      >
        <div
          style={{ display: "flex", flexDirection: "row", width: "100%" }}
          onClick={handleOpenNoteModal(props.noteType)}
        >
          <div
            style={{
              width: "10%",
              height: "80px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignContent: "center",
              alignItems: "center",
              paddingLeft: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "15px",
              }}
            >
              <div
                style={{
                  color: "#a3a3a4",
                  fontSize: "14px",
                  textAlign: "center",
                  fontWeight: "500",
                }}
              >
                {props.noteDate
                  ? new Date(props.noteDate)
                      .toLocaleString("en-US", { month: "short" })
                      .toUpperCase()
                  : ""}
              </div>

              <div
                style={{
                  color: "black",
                  fontSize: "20px",
                  lineHeight: "18px",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                {props.noteDate ? new Date(props.noteDate).getDate() : ""}
              </div>

              <div
                style={{
                  color: "#a3a3a4",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                {new Date(props.noteDate).getFullYear()}
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  color: "black",
                  fontSize: "16px",
                  textAlign: "center",
                  fontWeight: "500",
                }}
              >
                {props.noteTime.split(":")[0]}
              </div>

              <div
                style={{
                  color: "black",
                  fontSize: "20px",
                  lineHeight: "16px",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                {props.noteTime && props.noteTime.split(":")[1]
                  ? props.noteTime.split(":")[1].slice(0, 2)
                  : ""}
              </div>

              <div
                style={{
                  color: "#a3a3a4",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                {props.noteTime ? props.noteTime.split(" ")[1] : ""}
              </div>
            </div>
          </div>

          <div
            style={{
              width: "20%",
              height: "80px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start", // Simplifié pour aligner à gauche quel que soit le type
              alignItems: "center",
              paddingLeft: ["endOfShift", "groupNote", "handoverNote"].includes(
                props.noteType
              )
                ? "30px"
                : "0px",
            }}
          >
            {["endOfShift", "groupNote", "handoverNote"].includes(
              props.noteType
            ) ? (
              getIconForNoteType(props.noteType)
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignContent: "flex-start",
                }}
              >
                <SmallClientChipV2
                  firstName={props.clientFirstName}
                  lastName={props.clientLastName}
                  avatar="1"
                  clientPic={props.clientPic}
                />
              </div>
            )}
          </div>

          <div
            style={{
              // backgroundColor: "lightgreen",
              width: "50%",
              height: "80px",
              padding: "10px",
              fontStyle: "italic",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                height: "100%",
                overflow: "hidden",
                position: "relative",
                zIndex: 1,
              }}
            >
              {/* {props.noteContent} */}
              {props.noteType === "handoverNote"
                ? props.generalAnnouncement
                : props.noteContent}
            </div>
            <div
              style={{
                position: "absolute",
                bottom: "10px",
                left: 0,
                width: "100%",
                height: "20px",
                backgroundImage:
                  "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))",
                zIndex: 2,
              }}
            ></div>
          </div>
          <div
            style={{
              width: "15%",

              height: "80px",
              display: "flex",
              justifyContent: "flex-start",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: "12px",
                backgroundColor: getStylesForNoteType(props.noteType)
                  .backgroundColor,
                paddingLeft: "7px",
                paddingTop: "px",
                paddingRight: "7px",
                borderRadius: "10px",
                color: getStylesForNoteType(props.noteType).color,
                fontWeight: "500",
                height: "20px",
              }}
            >
              {formatNoteType(props.noteType)}
            </div>
          </div>
        </div>

        <div
          style={{
            // backgroundColor: "lightcoral",
            width: "5%",

            height: "75px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton onClick={handleMenuClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleMenuClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {/* <MenuItem onClick={() => handleOpenNoteModal(props.noteType)}>
              View
            </MenuItem> */}
            <MenuItem onClick={handleOpenNoteModal(props.noteType)}>
              {translate("Edit")}
            </MenuItem>

            <MenuItem onClick={openDeleteModal} sx={{ color: "red" }}>
              {translate("Delete")}
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
}

export default NotesContainerCompact;
