// import React from "react";
// import Button from "@mui/material/Button";
// import DeleteIcon from "@mui/icons-material/Delete"; // Importez l'icône de suppression
// import { IconButton } from "@mui/material";

// // Composant de bouton de suppression
// function ButtonDeleteRed({ onClick }) {
//   return (
//     <IconButton
//       onClick={onClick}
//       aria-label="delete" // Bonne pratique d'accessibilité
//       color="error" // Applique le thème rouge pour le bouton
//       sx={
//         {
//           // Styles supplémentaires pour ajuster l'apparence si nécessaire
//         }
//       }

//     >
//       <DeleteIcon />
//     </IconButton>
//   );
// }

// export default ButtonDeleteRed;

import React, { useContext } from "react";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip"; // Importez Tooltip pour le texte de survol
import DeleteIcon from "@mui/icons-material/Delete"; // Importez l'icône de suppression
import { LanguageContext } from "../../context/LanguageContext";

// Composant de bouton de suppression
function ButtonDeleteRed({ onClick }) {
  const { translate } = useContext(LanguageContext);
  return (
    <Tooltip title={translate("ResetFilters")} placement="top">
      <Button
        onClick={onClick}
        aria-label="delete"
        color="error" // Applique le thème rouge pour le bouton
        variant="contained" // Ajoute un arrière-plan pour le bouton, le rendant visible
        sx={{
          borderRadius: "20%",
          padding: 0, // Réduire le padding pour permettre un meilleur centrage
          minWidth: "30px", // Ajuster selon la taille souhaitée
          height: "30px", // Ajuster selon la taille souhaitée
          "& .MuiButton-startIcon": {
            margin: 0, // Élimine les marges autour de l'icône
          },
          "& .MuiSvgIcon-root": {
            fontSize: "1rem", // Ajustez ici pour changer la taille de l'icône
          },
        }}
        startIcon={<DeleteIcon />}
      >
        {/* Conditionnellement afficher le texte sur le bouton basé sur le survol n'est pas directement supporté ici */}
      </Button>
    </Tooltip>
  );
}

export default ButtonDeleteRed;
