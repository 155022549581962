import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { format } from "date-fns";
import { Button } from "@mui/material";
import Papa from "papaparse";
import TitleSearchComponentNoFilter from "../Misc/TitleSearchComponentNoFilter";
import ClientRowAvailability from "../Misc/ClientRowAvailability";

function ClinicalAssessmentClientAvailability(props) {
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [clientDisplayLimit, setClientDisplayLimit] = useState(10);
  const [readCount, setReadCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filteredClients, setFilteredClients] = useState([]);

  const [filters, setFilters] = useState({
    fullName: "",
    primaryAddiction: "",
    estimatedArrivalDate: "",
    admissionDate: "",
    durationOfStay: "",
    flights: "",
    payment: "",
    passport: "",
    vipTransfer: "",
    // ... (autres filtres existants)
  });

  function convertToCSV(data) {
    const csv = Papa.unparse(data, {
      header: true,
    });
    return csv;
  }

  useEffect(() => {
    // const clientCollection = collection(db, "clients");
    const clientQuery = query(
      collection(db, "clients"),
      where("clientStatus", "==", "Clinical Assessment")
    );

    getDocs(clientQuery).then(async (snapshot) => {
      let clientDataWithAssessment = [];

      for (let docList of snapshot.docs) {
        const clientData = docList.data();
        clientData.id = docList.id; // Correction ici: utilisez docList.id

        // Récupération des données de la collection preAdmissionAssessments
        const assessmentDocRef = doc(db, "preAdmissionAssessments", docList.id); // Correction ici aussi
        const assessmentSnapshot = await getDoc(assessmentDocRef);

        if (assessmentSnapshot.exists()) {
          const assessmentData = assessmentSnapshot.data();
          // Fusion des données client avec les données d'évaluation
          clientDataWithAssessment.push({ ...clientData, ...assessmentData });
        } else {
          // Si pas de données d'évaluation, ajoutez simplement les données client
          clientDataWithAssessment.push(clientData);
        }
      }

      setClients(clientDataWithAssessment);
      setTotalRecords(clientDataWithAssessment.length);
      setIsLoading(false);
    });
  }, []);

  function formatDate(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    return format(date, "d MMMM yyyy");
  }

  function formatDate(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    return format(date, "d MMM yyyy");
  }

  return (
    <div>
      {/* <div className="container-counter">Total records: {totalRecords}</div> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          paddingLeft: "50px",
        }}
      >
        {/* Vos TitleSearchComponents ici */}
      </div>

      <div style={{ paddingLeft: "25px", width: "987px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingBottom: "20px",
            overflowX: "auto",
            justifyContent: "flex-start",
            alignContent: "flex-end",
            alignItems: "flex-end",
            borderBottom: "3px solid lightgray",
          }}
        >
          <div
            style={{
              width: "auto",
              // borderLeft: "1px solid black"
            }}
          >
            <TitleSearchComponentNoFilter
              width="180px"
              title="Full Name"
              value={filters.fullName}
              onChange={(e) =>
                setFilters({ ...filters, fullName: e.target.value })
              }
            />
          </div>
          <TitleSearchComponentNoFilter
            width="110px"
            title="Prim. Addiction"
            value={filters.primaryAddiction}
            onChange={(e) =>
              setFilters({ ...filters, primaryAddiction: e.target.value })
            }
          />
          <TitleSearchComponentNoFilter
            width="95px"
            title="ETA"
            value={filters.estimatedArrivalDate}
            onChange={(e) =>
              setFilters({ ...filters, estimatedArrivalDate: e.target.value })
            }
          />
          <TitleSearchComponentNoFilter
            width="95px"
            title="Adm. Date"
            value={filters.admissionDate}
            onChange={(e) =>
              setFilters({ ...filters, admissionDate: e.target.value })
            }
          />
          <TitleSearchComponentNoFilter
            width="95px"
            title="Lenght of Stay"
            value={filters.durationOfStay}
            onChange={(e) =>
              setFilters({ ...filters, durationOfStay: e.target.value })
            }
          />
          <TitleSearchComponentNoFilter
            width="95px"
            title="Flight Details"
            value={filters.flights}
            onChange={(e) =>
              setFilters({ ...filters, flights: e.target.value })
            }
          />
          <TitleSearchComponentNoFilter
            width="95px"
            title="Payment"
            value={filters.payment}
            onChange={(e) =>
              setFilters({ ...filters, payment: e.target.value })
            }
          />
          <TitleSearchComponentNoFilter
            width="95px"
            title="Passport"
            value={filters.passport}
            onChange={(e) =>
              setFilters({ ...filters, passport: e.target.value })
            }
          />
          <TitleSearchComponentNoFilter
            width="95px"
            title="VIP Transfer"
            value={filters.vipTransfer}
            onChange={(e) =>
              setFilters({ ...filters, vipTransfer: e.target.value })
            }
          />
        </div>
      </div>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "500px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div
          className="client-widgets-global-container"
          style={{
            paddingLeft: "20px",
            paddingTop: "5px",
            marginBottom: "50px",
            paddingLeft: "10px",
          }}
        >
          {clients.slice(0, clientDisplayLimit).map((client) => (
            <ClientRowAvailability
              key={client.id}
              clientStatus={client.clientStatus}
              firstName={client.firstName}
              lastName={client.lastName}
              gender={client.gender}
              email={client.email}
              eta={formatDate(client.eta)}
              admissionDate={formatDate(client.dateOfAdmission)}
              nationality={client.nationality}
              country={client.country}
              primaryAddiction={client.primaryAddiction}
              secondaryAddiction={client.secondaryAddiction}
              inActiveRecovery={client.recoveryRadio ? "Yes" : "No"}
              googleReviewDone={client.googleReview ? "Yes" : "No"}
              personalEmail={client.personalEmail}
              durationOfStay={client.durationOfStay}
              flight={client.flightDetails}
              vipTransfer={client.vipTransfer}
              passportStatus={client.passportStatus}
              paymentDetail={client.paymentDetails}
              profilPicture={client.profilePicture}
              idClient={client.id}
              clientData={client}
            />
          ))}
        </div>
      )}

      {!isLoading && filteredClients.length > clientDisplayLimit && (
        <div className="seemore-container">
          <Button
            onClick={() => setClientDisplayLimit(clientDisplayLimit + 10)}
          >
            See more
          </Button>
        </div>
      )}
    </div>
  );
}

export default ClinicalAssessmentClientAvailability;
