import React, { useContext, useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Column from "./Column";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  onSnapshot,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";

import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Slide,
  TextField,
} from "@mui/material";
import countries from "../../api/countries";
import clientSource from "../../api/clientSource";
import { showCustomToast } from "./CustomToast";
import CircularProgress from "@mui/material/CircularProgress";
import { LanguageContext } from "../../context/LanguageContext";
import { useAuth } from "../../contexts/AuthContext";
import durationOfStay from "../../api/durationOfStay";
import addictions from "../../api/addictions";
import ColumnMobileV2 from "./ColumnMobileV2";

export default function KabanMobileV2() {
  const { translate } = useContext(LanguageContext);
  const theme = useTheme();
  const [openAddClientModal, setOpenAddClientModal] = useState(false);
  const steps = ["Personal Info", "Addiction Info"];
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedCard, setSelectedCard] = useState(null);
  const [openAddNoteSlider, setOpenAddNoteSlider] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [notes, setNotes] = useState([]);
  const { currentUser } = useAuth();
  const [run, setRun] = useState(false);
  const [showHelpButton, setShowHelpButton] = useState(true);
  const handleHelpClick = () => {
    setRun(true);
    setShowHelpButton(false); // Cache le bouton "HELP" après le clic
  };

  const handleOpenAddNoteSlider = () => {
    setOpenAddNoteSlider(true);
  };

  const handleCloseAddNoteSlider = () => {
    setOpenAddNoteSlider(false);
    setNoteText("");
  };

  const handleAddNote = () => {
    if (noteText.trim()) {
      const newNote = {
        text: noteText,
        date: new Date().toLocaleString(),
        addedBy: currentUser?.displayName, // Replace with actual user's name if available
      };
      setNotes([newNote, ...notes]); // Add the new note at the top
      setNoteText("");
      handleCloseAddNoteSlider(); // Close the add note slider after adding
    }
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(
        collection(db, "clients"),
        where("clientStatus", "in", [
          "NewLead",
          "Contacted",
          "Qualified",
          "ProposalSent",
          "Incoming",
        ])
      ),
      (querySnapshot) => {
        const clientsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Séparation des clients par statut
        const newLeads = clientsData.filter(
          (client) => client.clientStatus === "NewLead"
        );
        const contacted = clientsData.filter(
          (client) => client.clientStatus === "Contacted"
        );
        const qualified = clientsData.filter(
          (client) => client.clientStatus === "Qualified"
        );
        const proposalSent = clientsData.filter(
          (client) => client.clientStatus === "ProposalSent"
        );
        const incoming = clientsData.filter(
          (client) => client.clientStatus === "Incoming"
        );

        setNewLeads(newLeads);
        setContacted(contacted);
        setQualified(qualified);
        setProposalSent(proposalSent);
        setIncoming(incoming);
        setLoadingData(false);
      },
      (error) => {
        console.error("Error fetching clients: ", error);
        setLoadingData(false);
      }
    );

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const onSelectCard = (card) => {
    setSelectedCard(card);
    setOpenAddClientModal(true); // Ouvre le modal pour édition
  };

  useEffect(() => {
    if (selectedCard && openAddClientModal) {
      setFormValues({
        personalInfo: {
          firstName: selectedCard.firstName || "",
          lastName: selectedCard.lastName || "",
          gender: selectedCard.gender || "",
          personalEmail: selectedCard.personalEmail || "",
          phoneNumber: selectedCard.phoneNumber || "",
          country: selectedCard.country || "",
        },
        addictionInfo: {
          primaryAddiction: selectedCard.addiction || "",
          durationOfStay: selectedCard.durationOfStay || "",
          eta: selectedCard.eta || null,
          clientSource: selectedCard.clientSource || "",
        },
        additionalInfo: {
          notes: selectedCard.notes || "",
        },
      });
      setNotes(selectedCard.notes || []); // Charger les notes existantes
    }
  }, [selectedCard, openAddClientModal]); // Ajoutez openAddClientModal ici pour recharger les données lors de la réouverture

  const validateForm = () => {
    const newFormErrors = { ...formErrors };
    let isValid = true;

    switch (activeStep) {
      case 0:
        newFormErrors.personalInfo.firstName =
          formValues.personalInfo.firstName === "";
        newFormErrors.personalInfo.lastName =
          formValues.personalInfo.lastName === "";
        newFormErrors.personalInfo.gender =
          formValues.personalInfo.gender === "";
        newFormErrors.personalInfo.personalEmail =
          formValues.personalInfo.personalEmail === "";
        newFormErrors.personalInfo.phoneNumber =
          formValues.personalInfo.phoneNumber === "";
        newFormErrors.personalInfo.country =
          formValues.personalInfo.country === "";
        newFormErrors.personalInfo.dob =
          formValues.personalInfo.dob === null ||
          formValues.personalInfo.dob === "";
        isValid = Object.values(newFormErrors.personalInfo).every(
          (field) => field === false
        );
        break;
      case 1:
        newFormErrors.addictionInfo.primaryAddiction =
          formValues.addictionInfo.primaryAddiction === "";
        newFormErrors.addictionInfo.durationOfStay =
          formValues.addictionInfo.durationOfStay === "";
        newFormErrors.addictionInfo.eta = formValues.addictionInfo.eta === null;
        newFormErrors.addictionInfo.clientSource =
          formValues.addictionInfo.clientSource === "";
        isValid = Object.values(newFormErrors.addictionInfo).every(
          (field) => field === false
        );
        break;
      default:
        break;
    }

    setFormErrors(newFormErrors);
    return isValid;
  };

  const resetForm = () => {
    setFormValues({
      personalInfo: {
        firstName: "",
        lastName: "",
        gender: "",
        personalEmail: "",
        phoneNumber: "",
        country: "",
      },
      addictionInfo: {
        primaryAddiction: "",
        durationOfStay: "",
        eta: null,
        nickname: "",
        clientSource: "",
      },
      additionalInfo: {
        projectedResidentialDischargeDate: null,
        projectedProgramCompletionDate: null,
        notes: "",
      },
    });
    setFormErrors({
      personalInfo: {
        firstName: false,
        lastName: false,
        gender: false,
        personalEmail: false,
        phoneNumber: false,
        country: false,
      },
      addictionInfo: {
        primaryAddiction: false,
        durationOfStay: false,
        eta: false,
        clientSource: false,
      },
      additionalInfo: {
        projectedResidentialDischargeDate: false,
        projectedProgramCompletionDate: false,
      },
    });
    setActiveStep(0);
    setNotes([]); // Réinitialiser les notes
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const addictions = [
  //   { id: 1, textDropDownEn: "Alcohol" },
  //   { id: 2, textDropDownEn: "Drugs" },
  //   { id: 3, textDropDownEn: "Gambling" },
  //   // Ajoutez plus d'options si nécessaire
  // ];

  const isStepCompleted = (step) => {
    switch (step) {
      case 0:
        return Object.values(formValues.personalInfo).every(
          (field) => field !== ""
        );
      case 1:
        return Object.values(formValues.addictionInfo).every(
          (field) => field !== "" && field !== null
        );
      default:
        return false;
    }
  };

  const [formErrors, setFormErrors] = useState({
    personalInfo: {
      firstName: false,
      lastName: false,
      gender: false,
      personalEmail: false,
      phoneNumber: false,
      country: false,
      dob: false, // Ajoutez dob ici
    },
    addictionInfo: {
      primaryAddiction: false,
      durationOfStay: false,
      eta: false,
      clientSource: false,
    },
  });

  const [formValues, setFormValues] = useState({
    personalInfo: {
      firstName: "",
      lastName: "",
      gender: "",
      personalEmail: "",
      phoneNumber: "",
      country: "",
      dob: null, // Ajout du champ dob ici
    },
    addictionInfo: {
      primaryAddiction: "",
      durationOfStay: "",
      eta: null,
      nickname: "",
      clientSource: "",
    },
  });

  const [newLeads, setNewLeads] = useState([]);
  const [contacted, setContacted] = useState([]);
  const [qualified, setQualified] = useState([]);
  const [proposalSent, setProposalSent] = useState([]);
  const [incoming, setIncoming] = useState([]);

  // const handleOpenAddClientModal = () => {
  //   setOpenAddClientModal(true);
  // };

  const handleOpenAddClientModal = () => {
    setSelectedCard(null); // Réinitialise selectedCard
    resetForm(); // Réinitialise le formulaire pour s'assurer qu'il est vide
    setOpenAddClientModal(true); // Ouvre le modal
  };

  // const handleCloseAddClientModal = () => {
  //   resetForm();
  //   setOpenAddClientModal(false);
  // };

  const handleCloseAddClientModal = () => {
    if (!selectedCard) {
      resetForm(); // Réinitialise le formulaire uniquement si ce n'est pas en mode édition
    }
    setOpenAddClientModal(false);
  };

  const saveLeadToFirestore = async (newLead) => {
    try {
      const docRef = await addDoc(collection(db, "clients"), {
        ...newLead,
        clientStatus: "NewLead", // Enregistrer le statut
      });
      console.log("Document written with ID: ", docRef.id);

      // Mise à jour de l'état local pour afficher le nouveau lead dans le Kaban
      setNewLeads([{ id: docRef.id, ...newLead }, ...newLeads]);
      handleCloseAddClientModal();
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setLoading(true); // Démarrer le chargement

      // Définir l'objet newLead
      const newLead = {
        firstName: formValues.personalInfo.firstName,
        lastName: formValues.personalInfo.lastName,
        gender: formValues.personalInfo.gender,
        personalEmail: formValues.personalInfo.personalEmail,
        phoneNumber: formValues.personalInfo.phoneNumber,
        country: formValues.personalInfo.country,
        dob: formValues.personalInfo.dob
          ? Timestamp.fromDate(new Date(formValues.personalInfo.dob))
          : null,
        addiction: formValues.addictionInfo.primaryAddiction,
        durationOfStay: formValues.addictionInfo.durationOfStay,
        eta: formValues.addictionInfo.eta,
        clientSource: formValues.addictionInfo.clientSource,
        notes: notes, // Inclure les notes ici
        dateOfAdmission: null,
      };

      try {
        if (selectedCard) {
          // Mettre à jour le lead existant
          const clientRef = doc(db, "clients", selectedCard.id);
          await updateDoc(clientRef, newLead);
          showCustomToast(translate("LeadUpdatedSuccessfully"), "success");
        } else {
          // Créer un nouveau lead
          const docRef = await addDoc(collection(db, "clients"), {
            ...newLead,
            clientStatus: "NewLead", // Définir le statut
          });
          setNewLeads([{ id: docRef.id, ...newLead }, ...newLeads]);
          showCustomToast(translate("LeadCreatedSuccessfully"), "success");
        }
        resetForm(); // Réinitialiser le formulaire après ajout ou mise à jour
        setOpenAddClientModal(false); // Fermer le modal
      } catch (e) {
        showCustomToast(translate("FailedToCreateOrUpdateLead"), "error");
        console.error("Error updating document: ", e);
      } finally {
        setLoading(false); // Arrêter le chargement
      }
    } else {
      showCustomToast(translate("PleaseFillRequiredFields"), "error");
    }
  };

  const customStyles = {
    formControl: {
      marginTop: "16px",
      marginBottom: "8px",
      "& .MuiInputLabel-root": {
        transform: "translate(14px, 12px) scale(1)",
        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      },
      "& .MuiInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.75)",
      },
      "& .MuiInputBase-root": {
        "&.Mui-focused .MuiInputLabel-root": {
          transform: "translate(14px, -6px) scale(0.75)",
        },
      },
    },
  };

  const handleDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    if (!destination || source.droppableId === destination.droppableId) return;

    const task = findItemById(draggableId, [
      ...newLeads,
      ...contacted,
      ...qualified,
      ...proposalSent,
      ...incoming,
    ]);

    if (!task) {
      console.error("Task not found");
      return;
    }

    deletePreviousState(source.droppableId, draggableId);
    setNewState(destination.droppableId, task);

    // Mettre à jour le statut du client dans Firestore
    try {
      const clientRef = doc(db, "clients", task.id); // Utilisez l'id réel du document Firestore ici
      await updateDoc(clientRef, {
        clientStatus: getStatusFromDroppableId(destination.droppableId),
      });
      console.log("Client status updated successfully");
    } catch (e) {
      console.error("Error updating client status: ", e);
    }
  };

  function getStatusFromDroppableId(droppableId) {
    switch (droppableId) {
      case "1":
        return "NewLead";
      case "2":
        return "Contacted";
      case "3":
        return "Qualified";
      case "4":
        return "ProposalSent";
      case "5":
        return "Incoming";
      default:
        return "";
    }
  }

  function deletePreviousState(sourceDroppableId, taskId) {
    switch (sourceDroppableId) {
      case "1":
        setNewLeads(removeItemById(taskId, newLeads));
        break;
      case "2":
        setContacted(removeItemById(taskId, contacted));
        break;
      case "3":
        setQualified(removeItemById(taskId, qualified));
        break;
      case "4":
        setProposalSent(removeItemById(taskId, proposalSent));
        break;
      case "5":
        setIncoming(removeItemById(taskId, incoming));
        break;
      default:
        break;
    }
  }

  function setNewState(destinationDroppableId, task) {
    let updatedTask;
    switch (destinationDroppableId) {
      case "1": // New Leads
        updatedTask = { ...task };
        setNewLeads([updatedTask, ...newLeads]);
        break;
      case "2": // Contacted
        updatedTask = { ...task };
        setContacted([updatedTask, ...contacted]);
        break;
      case "3": // Qualified
        updatedTask = { ...task };
        setQualified([updatedTask, ...qualified]);
        break;
      case "4": // Proposal Sent
        updatedTask = { ...task };
        setProposalSent([updatedTask, ...proposalSent]);
        break;
      case "5": // Incoming
        updatedTask = { ...task };
        setIncoming([updatedTask, ...incoming]);
        break;
      default:
        break;
    }
  }

  function findItemById(id, array) {
    return array.find((item) => item.id === id);
  }

  function removeItemById(id, array) {
    return array.filter((item) => item.id !== id);
  }

  const handleAddNewLead = () => {
    const newLead = {
      id: `${newLeads.length + 1}`, // Id unique pour chaque lead
      firstName: "New",
      lastName: "Lead",
      sex: "Unknown",
      country: "Unknown",
      addiction: "Unknown",
    };
    setNewLeads([newLead, ...newLeads]); // Ajouter le nouveau lead au début de la liste
  };

  const handleChange = (step, field) => (event) => {
    const value = event && event.target ? event.target.value : event; // handle both event.target.value and direct value from PhoneInput
    setFormValues({
      ...formValues,
      [step]: { ...formValues[step], [field]: value },
    });
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      {/* <h2 style={{ textAlign: "center" }}>CRM Board</h2> */}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: "15px",
          paddingBottom: "20px",
          marginTop: "20px",
          paddingRight: "15px",
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={handleOpenAddClientModal}
          startIcon={<Add />}
          sx={{ fontSize: "12px" }}
        >
          {translate("AddNewLead")}
        </Button>
        {showHelpButton && (
          <Button
            variant="outlined"
            color="primary"
            onClick={handleHelpClick}
            sx={{ fontSize: "10px" }}
          >
            {translate("Help")}
          </Button>
        )}
      </div>

      <Modal
        open={openAddNoteSlider}
        onClose={handleCloseAddNoteSlider}
        aria-labelledby="add-note-modal-title"
        aria-describedby="add-note-modal-description"
        closeAfterTransition
      >
        <Slide direction="left" in={openAddNoteSlider}>
          <Box
            sx={{
              position: "fixed",
              top: "5%",
              right: "4%",
              width: "90%",
              height: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              pl: 2,
              pr: 2,
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <div style={{ fontSize: "18px", fontWeight: "700" }}>
                {translate("AddNote")}
              </div>
              <IconButton onClick={handleCloseAddNoteSlider}>
                <CloseIcon />
              </IconButton>
            </Box>
            <TextField
              label={translate("Note")}
              multiline
              rows={4}
              variant="outlined"
              value={noteText}
              onChange={(e) => setNoteText(e.target.value)}
              fullWidth
            />
            <Button
              sx={{ mt: 2 }}
              variant="contained"
              color="primary"
              onClick={handleAddNote}
            >
              Ajouter
            </Button>
          </Box>
        </Slide>
      </Modal>

      <Modal
        open={openAddClientModal}
        onClose={handleCloseAddClientModal}
        aria-labelledby="add-client-modal-title"
        aria-describedby="add-client-modal-description"
        closeAfterTransition
      >
        <Slide direction="left" in={openAddClientModal}>
          <Box
            sx={{
              position: "fixed",
              top: "5%",
              right: 0,
              width: "90%",
              height: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              marginRight: "15px",
              pl: "2",
              pr: "2",
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 0,
                borderBottom: "1px solid lightgray",
              }}
            >
              <div
                id="add-client-modal-title"
                style={{
                  // margin: 0,
                  paddingLeft: "30px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  fontSize: "18px",
                  fontWeight: "700",
                }}
              >
                {selectedCard
                  ? translate("UpdateLead")
                  : translate("AddNewLead")}
              </div>
              <div style={{ paddingRight: "15px" }}>
                <IconButton onClick={handleCloseAddClientModal}>
                  <CloseIcon />
                </IconButton>
              </div>
            </Box>

            <Box
              id="add-client-modal-description"
              sx={{
                // flexGrow: 1,
                overflowY: "auto",
                // mt: 0,
                pl: "30px",
                pr: "30px",
                height: "67%",
                marginTop: "0px",
              }}
            >
              <div
                style={{
                  height: "60%",
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                {/* Nom et Prénom */}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "45%" }}>
                    <TextField
                      fullWidth
                      label={translate("FirstName")}
                      variant="outlined"
                      margin="normal"
                      size="small"
                      value={formValues.personalInfo.firstName}
                      onChange={handleChange("personalInfo", "firstName")}
                      error={formErrors.personalInfo.firstName}
                      helperText={
                        formErrors.personalInfo.firstName
                          ? translate("FirstNameRequired")
                          : ""
                      }
                      // InputProps={{
                      //   sx: {
                      //     height: "30px", // Adjust this value to increase the height
                      //     padding: "0 0px", // Adjust the padding to prevent text overflow
                      //     "&::placeholder": {
                      //       color: "gray", // Customize the placeholder color
                      //       padding: "0", // Remove extra padding from the placeholder
                      //     },
                      //   },
                      //   inputProps: {
                      //     style: {
                      //       padding: "5px 5px", // Adjust padding inside the input field
                      //     },
                      //   },
                      // }}
                    />
                  </div>

                  <div style={{ width: "45%" }}>
                    <TextField
                      size="small"
                      fullWidth
                      label={translate("LastName")}
                      variant="outlined"
                      margin="normal"
                      value={formValues.personalInfo.lastName}
                      onChange={handleChange("personalInfo", "lastName")}
                      error={formErrors.personalInfo.lastName}
                      helperText={
                        formErrors.personalInfo.lastName
                          ? translate("LastNameRequired")
                          : ""
                      }
                    />
                  </div>
                </div>

                {/* Sexe et Email */}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "45%" }}>
                    <TextField
                      fullWidth
                      size="small"
                      label={translate("PhoneNumber")}
                      variant="outlined"
                      margin="normal"
                      value={formValues.personalInfo.phoneNumber}
                      onChange={handleChange("personalInfo", "phoneNumber")}
                      error={formErrors.personalInfo.phoneNumber}
                      helperText={
                        formErrors.personalInfo.phoneNumber
                          ? translate("PhoneNumberRequired")
                          : ""
                      }
                    />
                  </div>

                  <div style={{ width: "45%" }}>
                    <TextField
                      fullWidth
                      size="small"
                      label={translate("Email")}
                      variant="outlined"
                      margin="normal"
                      sx={{ fontSize: "10px" }}
                      value={formValues.personalInfo.personalEmail}
                      onChange={handleChange("personalInfo", "personalEmail")}
                      error={formErrors.personalInfo.personalEmail}
                      helperText={
                        formErrors.personalInfo.personalEmail
                          ? translate("EmailRequired")
                          : ""
                      }
                    />
                  </div>
                </div>

                {/* Numéro de téléphone et Pays */}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "45%" }}>
                    <FormControl fullWidth size="small" margin="normal">
                      <InputLabel>{translate("Gender")}</InputLabel>
                      <Select
                        value={formValues.personalInfo.gender}
                        onChange={handleChange("personalInfo", "gender")}
                        error={formErrors.personalInfo.gender}
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div style={{ width: "45%" }}>
                    <FormControl
                      fullWidth
                      size="small"
                      margin="normal"
                      style={customStyles.formControl}
                    >
                      <InputLabel>{translate("Country")}</InputLabel>
                      <Select
                        value={formValues.personalInfo.country}
                        onChange={handleChange("personalInfo", "country")}
                        error={formErrors.personalInfo.country}
                      >
                        {countries.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.textDropDownEn}
                          >
                            {option.textDropDownEn}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                {/* Addiction Principale et Durée de séjour */}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "45%" }}>
                    <FormControl fullWidth margin="normal" size="small">
                      <InputLabel>{translate("PrimaryAddiction")}</InputLabel>
                      <Select
                        value={formValues.addictionInfo.primaryAddiction}
                        onChange={handleChange(
                          "addictionInfo",
                          "primaryAddiction"
                        )}
                        error={formErrors.addictionInfo.primaryAddiction}
                      >
                        {addictions.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.textDropDownEn}
                          >
                            {option.textDropDownEn}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div
                    style={{
                      width: "45%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <TextField
                      size="small"
                      fullWidth
                      label={translate("DateOfBirth")}
                      type="date"
                      variant="outlined"
                      margin="normal"
                      sx={{ fontSize: "10px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={formValues.personalInfo.dob}
                      onChange={handleChange("personalInfo", "dob")}

                      // error={formErrors.personalInfo.dob}
                      // helperText={
                      //   formErrors.personalInfo.dob
                      //     ? "Date of Birth is required"
                      //     : ""
                      // }
                    />
                    {formErrors.personalInfo.dob && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {translate("DateOfBirthRequired")}
                      </div>
                    )}
                  </div>
                </div>

                {/* ETA (Estimated Time of Arrival) et Source du client */}

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "45%" }}>
                    <FormControl fullWidth margin="normal" size="small">
                      <InputLabel>{translate("DurationOfStay")} </InputLabel>
                      <Select
                        value={formValues.addictionInfo.durationOfStay}
                        onChange={handleChange(
                          "addictionInfo",
                          "durationOfStay"
                        )}
                        error={formErrors.addictionInfo.durationOfStay}
                      >
                        {durationOfStay.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.textDropDownEn}
                          >
                            {option.textDropDownEn}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{ width: "45%" }}>
                    <FormControl fullWidth margin="normal" size="small">
                      <InputLabel>{translate("ClientSource")}</InputLabel>
                      <Select
                        value={formValues.addictionInfo.clientSource}
                        onChange={handleChange("addictionInfo", "clientSource")}
                        error={formErrors.addictionInfo.clientSource}
                      >
                        {clientSource.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.textDropDownEn}
                          >
                            {option.textDropDownEn}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                {/* <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  
                </div> */}
              </div>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 2,
                height: "30%", // Ajuste la hauteur à 50%
                overflowY: "auto",
                pl: "30px",
                pr: "30px",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={handleOpenAddNoteSlider}
                startIcon={<Add />}
                style={{ fontSize: "12px" }}
              >
                {translate("AddNote")}
              </Button>

              {notes.map((note, index) => (
                <Box
                  key={index}
                  sx={{ mt: 2, p: 2, bgcolor: "#f9f9f9", borderRadius: 1 }}
                >
                  <Box sx={{ fontSize: "0.875rem", color: "#555" }}>
                    {note.date} - {note.addedBy}
                  </Box>
                  <Box sx={{ mt: 1, fontSize: "1rem" }}>{note.text}</Box>
                </Box>
              ))}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 2,
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingBottom: "20px",
                paddingTop: "20px",
                borderTop: "1px solid lightgray",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: "10px", fontsize: "10px" }}
                onClick={handleCloseAddClientModal}
              >
                {translate("Cancel")}
              </Button>

              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                style={{ fontSize: "12px" }}
                disabled={loading} // Désactiver le bouton pendant le chargement
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : selectedCard ? (
                  translate("Update")
                ) : (
                  translate("Create")
                )}
              </Button>
            </Box>
          </Box>
        </Slide>
      </Modal>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          paddingLeft: "20px",
          paddingRight: "20px",
          margin: "0 auto",
        }}
      >
        <ColumnMobileV2
          title={"New Leads"}
          tasks={loadingData ? Array(4).fill(null) : newLeads}
          id={"1"}
          borderColor={"#fdce1b"}
          renderSkeleton={loadingData}
          onSelectCard={onSelectCard}
        />

        <ColumnMobileV2
          title={"Contacted"}
          tasks={loadingData ? Array(4).fill(null) : contacted}
          id={"2"}
          borderColor={"#007bff"}
          renderSkeleton={loadingData}
          onSelectCard={onSelectCard}
        />

        <ColumnMobileV2
          title={"Qualified"}
          tasks={loadingData ? Array(4).fill(null) : qualified}
          id={"3"}
          borderColor={"#6f42c1"}
          renderSkeleton={loadingData}
          onSelectCard={onSelectCard}
        />

        <ColumnMobileV2
          title={"Proposal Sent"}
          tasks={loadingData ? Array(4).fill(null) : proposalSent}
          id={"4"}
          borderColor={"#28a745"}
          renderSkeleton={loadingData}
          onSelectCard={onSelectCard}
        />

        <ColumnMobileV2
          title={"Incoming"}
          tasks={loadingData ? Array(4).fill(null) : incoming}
          id={"5"}
          borderColor={"#dc3545"}
          renderSkeleton={loadingData}
          onSelectCard={onSelectCard}
        />
      </div>
    </DragDropContext>
  );
}
