import React, { useState } from "react";
import { Button } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import DropDownComponent from "../DropDownComponent";
import InputComponent from "../InputComponent";
import PhoneNumberInput from "../PhoneNumberInput";
import TextFieldComponentLarge from "../TextFieldComponentLarge";
import DatePickerComponent from "../DatePickerComponent";
import TextFieldComponent from "../TextFieldComponent";
import TopTitleFormComponent from "./TopTitleFormComponent";
import { doc, getDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebase";
import DeleteButtonFile from "../Buttons/DeleteButtonFile";
import { showCustomToast } from "../Misc/CustomToast";
import DropDownComponentNotSorted from "../DropDownComponentNotSorted";
import DropDownComponentSorted from "../DropDownComponentSorted";
import DropDownAllStaffs from "../Dropdowns/DropDownAllStaffs";

function AddIntakeForm(props) {
  //Tous les states des données récupérées par client

  const { currentUser } = useAuth();
  const maxSizePicFile = 2 * 1024 * 1024;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTherapist, setSelectedTherapist] = useState(
    props.selectedTherapist
  );
  const [genderOther, setGenderOther] = useState(props.genderOther || "");
  const [sexualOrientationOther, setSexualOrientationOther] = useState(
    props.sexualOrientationOther || ""
  );
  const [livingWithOther, setLivingWithOther] = useState(props.livingWithOther);
  const [emergencyRelationShipOther, setEmergencyRelationShipOther] = useState(
    props.emergencyRelationShipOther || ""
  );
  const [firstName, setFirstName] = useState(props.firstName);
  const [lastName, setLastName] = useState(props.lastName);
  const [nickname, setNickname] = useState(props.nickname);
  const [clientStatus, setClientStatus] = useState(props.clientStatus);
  const [gender, setGender] = useState(props.gender);
  const [sexualOrientation, setSexualOrientation] = useState(
    props.sexualOrientation
  );
  const [personalEmail, setPersonalEmail] = useState(props.personalEmail);
  const [phoneNumber, setPhoneNumber] = useState(props.phoneNumber);
  const [dob, setDob] = useState(props.dob || "");
  const [currentAddress, setCurrentAddress] = useState(props.currentAddress);
  const [city, setCity] = useState(props.city);
  const [nationality, setNationality] = useState(props.nationality);
  const [country, setCountry] = useState(props.country);
  const age = props.age;
  const [passportNumber, setPassportNumber] = useState(
    props.passportNumber || ""
  );
  const [visaExpirationDate, setVisaExpirationDate] = useState(
    props.visaExpirationDate || ""
  );

  const [durationOfStay, setDurationOfStay] = useState(props.durationOfStay);
  const [dateOfAdmission, setDateOfAdmission] = useState(
    props.dateOfAdmission || ""
  );
  const [
    projectedResidentialDischargeDate,
    setProjectedResidentialDischargeDate,
  ] = useState(props.projectedResidentialDischargeDate || "");
  const [projectedProgramCompletionDate, setProjectedProgramCompletionDate] =
    useState(props.projectedProgramCompletionDate || "");
  const [roomNumber, setRoomNumber] = useState(props.roomNumber || "");
  const [emergencyContactFirstName, setEmergencyContactFirstName] = useState(
    props.emergencyContactFirstName || ""
  );
  const [emergencyContactLastName, setEmergencyContactLastName] = useState(
    props.emergencyContactLastName || ""
  );
  const [emergencyContactRelationship, setEmergencyContactRelationship] =
    useState(props.emergencyContactRelationship || "");
  const [emergencyContactPhoneNumber, setEmergencyContactPhoneNumber] =
    useState(props.emergencyContactPhoneNumber || "");
  const [emergencyContactEmail, setEmergencyContactEmail] = useState(
    props.emergencyContactEmail || ""
  );
  const [emergencyContactAddress, setEmergencyContactAddress] = useState(
    props.emergencyContactAddress || ""
  );
  const [emergencyContactCity, setEmergencyContactCity] = useState(
    props.emergencyContactCity || ""
  );
  const [emergencyContactNationality, setEmergencyContactNationality] =
    useState(props.emergencyContactNationality || "");
  const [emergencyContactCountry, setEmergencyContactCountry] = useState(
    props.emergencyContactCountry || ""
  );
  const [payerName, setPayerName] = useState(props.payerName || "");
  const [payerPhoneNumber, setPayerPhoneNumber] = useState(
    props.payerPhoneNumber || ""
  );

  const [maritalStatus, setMaritalStatus] = useState(props.maritalStatus || "");
  const [livingWith, setLivingWith] = useState(props.livingWith || "");

  const [files, setFiles] = useState([]);
  const [fileLinks, setFileLinks] = useState(props.fileUrls || []);

  const clientId = props.clientId;

  const handleGenderOtherChange = (event) => {
    setGenderOther(event);
  };

  const handleSexualOrientationOtherChange = (event) => {
    setSexualOrientationOther(event);
  };

  const handleLivingWithOtherChange = (event) => {
    setLivingWithOther(event);
  };

  const handleEmergencyRelationShipOtherChange = (event) => {
    setEmergencyRelationShipOther(event);
  };

  const handleSelectedTherapistChange = (event) => {
    setSelectedTherapist(event);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event);
  };

  const handleLastNameChange = (event) => {
    setLastName(event);
  };

  const handleNicknameChange = (event) => {
    setNickname(event);
  };

  const handleClientStatusChange = (event) => {
    setClientStatus(event);
  };

  const handleDuratioOfStayChange = (event) => {
    setDurationOfStay(event);
  };

  const handleGenderChange = (event) => {
    setGender(event);
  };

  const handleSexualOrientationChange = (event) => {
    setSexualOrientation(event);
  };

  const handlePersonalEmailChange = (event) => {
    setPersonalEmail(event);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event);
  };

  const handleDOBChange = (event) => {
    setDob(event);
  };

  const handleCurrentAddressChange = (event) => {
    setCurrentAddress(event);
  };

  const handleCityChange = (event) => {
    setCity(event);
  };

  const handleNationalityChange = (event) => {
    setNationality(event);
  };

  const handleCountryChange = (event) => {
    setCountry(event);
  };

  const handleDateOfAdmissionChange = (event) => {
    setDateOfAdmission(event);
  };

  const handleProjectedResidentialDischargeDateChange = (event) => {
    setProjectedResidentialDischargeDate(event);
  };

  const handleProjectedProgramCompletionDateChange = (event) => {
    setProjectedProgramCompletionDate(event);
  };

  const handleRoomNumberChange = (event) => {
    setRoomNumber(event);
  };

  const handleEmergencyContactFirstNameChange = (event) => {
    setEmergencyContactFirstName(event);
  };

  const handleEmergencyContactLastNameChange = (event) => {
    setEmergencyContactLastName(event);
  };

  const handleEmergencyContactRelationshipChange = (event) => {
    setEmergencyContactRelationship(event);
  };

  const handleEmergencyContactPhoneNumberChange = (event) => {
    setEmergencyContactPhoneNumber(event);
  };

  const handleEmergencyContactEmailChange = (event) => {
    setEmergencyContactEmail(event);
  };

  const handleEmergencyContactAddressChange = (event) => {
    setEmergencyContactAddress(event);
  };

  const handleEmergencyContactCityChange = (event) => {
    setEmergencyContactCity(event);
  };

  const handleEmergencyContactNationalityChange = (event) => {
    setEmergencyContactNationality(event);
  };

  const handleEmergencyContactCountryChange = (event) => {
    setEmergencyContactCountry(event);
  };

  const handlePayerNameChange = (event) => {
    setPayerName(event);
  };

  const handlePayerPhoneNumberChange = (event) => {
    setPayerPhoneNumber(event);
  };

  const handleVisaExpirationDateChange = (date) => {
    setVisaExpirationDate(date);
  };

  const handlePassportNumberChange = (event) => {
    setPassportNumber(event);
  };

  const handleMaritalStatusChange = (event) => {
    setMaritalStatus(event);
  };

  const handleLivingWithChange = (event) => {
    setLivingWith(event);
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length <= 3) {
      setFiles(event.target.files);
    } else {
      showCustomToast("You can only upload up to 3 files.", "error");
    }
  };

  const uploadFilesAndGetUrls = async (docId) => {
    const storage = getStorage();
    const fileUrls = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > maxSizePicFile) {
        showCustomToast(`File size exceeds the limit: ${file.name}`, "error");

        console.error(`File size exceeds the limit: ${file.name}`);
        continue; // Passez au fichier suivant dans la boucle
      }
      const filePath = `Documents/generalInfos/${docId}/${file.name}`;
      const storageRef = ref(storage, filePath);

      await uploadBytes(storageRef, file);
      const fileUrl = await getDownloadURL(storageRef);
      fileUrls.push({ url: fileUrl, name: file.name });
    }

    return fileUrls;
  };

  const deleteFile = async (fileUrl, fileName, noteId) => {
    const storage = getStorage();
    const filePath = `Documents/generalInfos/${noteId}/${fileName}`;
    const storageRef = ref(storage, filePath);

    try {
      await deleteObject(storageRef);
      const newFileLinks = fileLinks.filter((file) => file.url !== fileUrl);
      setFileLinks(newFileLinks);
      const noteRef = doc(db, "clients", noteId);

      // Modifier cette ligne pour inclure les noms des fichiers
      await updateDoc(noteRef, {
        fileUrls: newFileLinks.map((file) => ({
          url: file.url,
          name: file.name,
        })),
      });
      showCustomToast("File successfully deleted!", "success");
    } catch (error) {
      console.log("Error deleting file:", error);
      showCustomToast("Error deleting file", "error");
    }
  };

  const handleSave = async (clientId) => {
    if (
      firstName &&
      lastName &&
      clientStatus &&
      gender &&
      personalEmail &&
      phoneNumber &&
      dob &&
      selectedTherapist &&
      durationOfStay &&
      dateOfAdmission &&
      projectedResidentialDischargeDate &&
      projectedProgramCompletionDate
    ) {
      setIsLoading(true);
      try {
        const clientRef = doc(db, "clients", clientId);
        const fileUrls = await uploadFilesAndGetUrls(clientRef.id);
        const clientDoc = await getDoc(clientRef);
        const existingFileUrls = clientDoc.data().fileUrls || [];
        const mergedFileUrls = [...existingFileUrls, ...fileUrls];

        // const storageRef = ref(storage, `profilePics/${clientId}`);
        // await uploadBytes(storageRef, profilePicture);
        // const profilePictureURL = await getDownloadURL(storageRef);

        await updateDoc(clientRef, {
          selectedTherapist,
          firstName,
          lastName,
          nickname,
          gender,
          sexualOrientation,
          personalEmail,
          phoneNumber,
          dob,
          currentAddress,
          city,
          nationality,
          country,
          durationOfStay,
          dateOfAdmission,
          projectedResidentialDischargeDate,
          projectedProgramCompletionDate,
          passportNumber,
          roomNumber,
          visaExpirationDate,
          emergencyContactFirstName,
          emergencyContactLastName,
          emergencyContactRelationship,
          emergencyContactPhoneNumber,
          emergencyContactEmail,
          emergencyContactAddress,
          emergencyContactCity,
          emergencyContactNationality,
          emergencyContactCountry,
          payerName,
          payerPhoneNumber,
          inTakestaffName: currentUser.displayName,
          intakeStaffId: currentUser.uid,
          intakeStaffEmail: currentUser.email,
          clientStatus: clientStatus,
          age: age,
          lastModification: serverTimestamp(),
          // profilePicture: profilePictureURL,
          fileUrls: mergedFileUrls,
          maritalStatus,
          livingWith,
          genderOther,
          sexualOrientationOther,
          livingWithOther,
          emergencyRelationShipOther,
        });

        showCustomToast(`Client successfully updated!`, "success");

        setIsLoading(false);
        props.closemodal();
      } catch (error) {
        console.error("Error updating client: ", error);
        setIsLoading(false);
      }
    } else {
      showCustomToast(
        `Some mandatory fields are empty. Please check.`,
        "error"
      );
    }
  };

  return (
    <>
      <div
        // className="inputAndLabelModalContainerTitle2"
        style={{ width: "auto", height: "100%" }}
      >
        <TopTitleFormComponent
          title={`INTAKE FORM : ${props.firstName} ${props.lastName} `}
          closemodal={props.closemodal}
        />

        <div
          style={{
            width: "100%",
            marginTop: "0px",
            height: "75%",
            overflowY: "scroll",
            // overflowX: "hidden",
          }}
        >
          <div className="alert-message2">Fields with * are mandatory</div>
          <div className="tab-form-container">
            {/* <InputComponent
            isRequired={true}
            title="Selected Therapist"
            value={selectedTherapist}
            width="200"
            onChange={handleSelectedTherapistChange}
          /> */}

            <DropDownAllStaffs
              isRequired={true}
              title="Selected Therapist"
              value={selectedTherapist}
              width="200"
              onChange={handleSelectedTherapistChange}
            />
            <DropDownComponent
              api="clientStatus"
              title="Client status"
              value={clientStatus}
              onChange={handleClientStatusChange}
              isRequired={true}
            />
          </div>
          <div className="tab-form-container">
            <InputComponent
              title="Client firstname"
              width="300"
              isRequired={true}
              value={firstName}
              onChange={handleFirstNameChange}
            />
            <InputComponent
              title="Client lastname"
              width="300"
              isRequired={true}
              value={lastName}
              onChange={handleLastNameChange}
            />
            <InputComponent
              title="Client nickname"
              width="300"
              value={nickname}
              onChange={handleNicknameChange}
            />
          </div>

          <div className="tab-form-container">
            <DropDownComponentNotSorted
              api="gender"
              title="Client gender"
              isRequired={true}
              value={gender}
              onChange={handleGenderChange}
            />

            {gender === "Other" ? (
              <InputComponent
                title="If Other, describe"
                width="300"
                value={genderOther}
                onChange={handleGenderOtherChange}
              />
            ) : (
              ""
            )}

            <DropDownComponent
              api="sexualOrientation"
              title="Sexual orientation"
              value={sexualOrientation}
              onChange={handleSexualOrientationChange}
            />

            {sexualOrientation === "other" ? (
              <InputComponent
                title="If Other, describe"
                width="300"
                value={sexualOrientationOther}
                onChange={handleSexualOrientationOtherChange}
              />
            ) : (
              ""
            )}
          </div>

          <div className="tab-form-container">
            <InputComponent
              title="Client personal email"
              width="300"
              isRequired={true}
              value={personalEmail}
              onChange={handlePersonalEmailChange}
            />

            <PhoneNumberInput
              title="Client phone number"
              isRequired={true}
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
            />

            <DatePickerComponent
              title="Date of birth"
              width="150px"
              isRequired={true}
              value={dob}
              onChange={handleDOBChange}
            />

            <DropDownComponent
              api="maritalStatus"
              title="Marital status"
              value={maritalStatus}
              onChange={handleMaritalStatusChange}
            />
            <div className="column-div">
              <DropDownComponentNotSorted
                api="relationship"
                title="Living with"
                value={livingWith}
                onChange={handleLivingWithChange}
              />
              {livingWith === "Other" ? (
                <InputComponent
                  title="If Other, describe"
                  width="300"
                  value={livingWithOther}
                  onChange={handleLivingWithOtherChange}
                />
              ) : (
                ""
              )}
            </div>

            <TextFieldComponentLarge
              title="Client current address"
              value={currentAddress}
              onChange={handleCurrentAddressChange}
            />

            <InputComponent
              title="City"
              width="300px"
              value={city}
              onChange={handleCityChange}
            />

            <DropDownComponent
              api="nationalities"
              title="Nationality"
              value={nationality}
              onChange={handleNationalityChange}
            />

            <DropDownComponentSorted
              api="countries"
              title="Country"
              value={country}
              onChange={handleCountryChange}
            />
            <InputComponent
              title="Passport number"
              width="150px"
              value={passportNumber}
              onChange={handlePassportNumberChange}
            />

            <DatePickerComponent
              title="Visa expiration date"
              width="150px"
              value={visaExpirationDate}
              onChange={handleVisaExpirationDateChange}
            />

            <DropDownComponent
              isRequired={true}
              api="durationOfStay"
              title="Intended duration of stay"
              value={durationOfStay}
              onChange={handleDuratioOfStayChange}
            />
            <DatePickerComponent
              title="Date of admission"
              width="150px"
              isRequired={true}
              value={dateOfAdmission}
              onChange={handleDateOfAdmissionChange}
            />

            <DatePickerComponent
              title="Projected date of residential discharge"
              width="150px"
              isRequired={true}
              value={projectedResidentialDischargeDate}
              onChange={handleProjectedResidentialDischargeDateChange}
            />

            <DatePickerComponent
              title="Projected date of program completion"
              width="150px"
              isRequired={true}
              value={projectedProgramCompletionDate}
              onChange={handleProjectedProgramCompletionDateChange}
            />

            <InputComponent
              title="Room number"
              width="300"
              value={roomNumber}
              onChange={handleRoomNumberChange}
            />
          </div>

          <div className="uploader-component-general-infos">
            <div className="form2">
              <div className="line5-modal">
                <label
                  htmlFor="uploadFiles"
                  style={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "8px",
                    marginTop: "20px",
                  }}
                >
                  Upload Passport Pictures (max 3)
                </label>
                <input
                  type="file"
                  id="uploadFiles"
                  multiple
                  onChange={handleFileChange}
                  accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv,.zip,.rar,.7z"
                  style={{
                    width: "0.1px",
                    height: "0.1px",
                    opacity: 0,
                    overflow: "hidden",
                    position: "absolute",
                    zIndex: -1,
                  }}
                />
                <label htmlFor="uploadFiles" className="custom-file-upload">
                  Select files
                </label>

                {files.length > 0 && (
                  <div className="select-title">
                    Selected files:
                    <ul className="ul-container">
                      {Array.from(files).map((file) => (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a className="a-container" key={file.name}>
                          <span
                            className="ellipsis"
                            style={{
                              maxWidth: "calc(30ch)",
                              color: "black",
                              fontWeight: "500",
                            }}
                          >
                            {file.name}
                          </span>
                        </a>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div className="form2">
              <label
                htmlFor="uploadFiles"
                style={{
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "8px",
                  marginTop: "20px",
                }}
              >
                Pictures already uploaded :
              </label>

              <div className="list-files-container">
                {fileLinks && fileLinks !== null && fileLinks !== undefined ? (
                  fileLinks.map((file, index) => (
                    <div key={index} className="file-item">
                      File :
                      {file.name.match(/\.(jpg|jpeg|png|gif)$/i) && (
                        <a href={file.url} target="_blank" rel="noreferrer">
                          <img
                            src={file.url}
                            alt={file.name}
                            style={{
                              maxWidth: "50px",
                              marginLeft: "10px",
                              marginRight: "10px",
                            }}
                          />
                        </a>
                      )}
                      <a
                        className="ellipsis"
                        style={{
                          marginLeft: "10px",
                          marginRight: "10px",
                          maxWidth: "calc(20ch)",
                        }}
                        href={file.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {file.name}
                      </a>
                      <DeleteButtonFile
                        onClick={() =>
                          deleteFile(file.url, file.name, props.clientId)
                        }
                      />
                    </div>
                  ))
                ) : (
                  <div>No files found</div>
                )}
              </div>
            </div>
          </div>

          <div className="tab-form-container-red">
            <div className="tab-container-title">Emergency contact</div>
            <div className="tab-form-container-fied">
              <InputComponent
                title="Emergency contact firstname"
                width="300px"
                value={emergencyContactFirstName}
                onChange={handleEmergencyContactFirstNameChange}
              />
              <InputComponent
                title="Emergency contact lastname"
                width="300px"
                value={emergencyContactLastName}
                onChange={handleEmergencyContactLastNameChange}
              />
            </div>

            <div className="tab-form-container-fied">
              <DropDownComponent
                api="relationship"
                title="Relationship"
                value={emergencyContactRelationship}
                onChange={handleEmergencyContactRelationshipChange}
              />

              {emergencyContactRelationship === "Other" ? (
                <InputComponent
                  title="Emergency contact lastname"
                  width="100px"
                  value={emergencyRelationShipOther}
                  onChange={handleEmergencyRelationShipOtherChange}
                />
              ) : (
                ""
              )}
            </div>

            <div className="tab-form-container-fied">
              <InputComponent
                title="Contact Email"
                width="300px"
                value={emergencyContactEmail}
                onChange={handleEmergencyContactEmailChange}
              />
              <PhoneNumberInput
                title="Contact phone number"
                value={emergencyContactPhoneNumber}
                onChange={handleEmergencyContactPhoneNumberChange}
              />
            </div>

            <div className="tab-form-container-fied">
              <TextFieldComponent
                title="Physical adress of residence"
                value={emergencyContactAddress}
                onChange={handleEmergencyContactAddressChange}
              />

              <div className="block-input">
                <div className="block-input-line1">
                  <InputComponent
                    title="City"
                    width="300px"
                    value={emergencyContactCity}
                    onChange={handleEmergencyContactCityChange}
                  />
                </div>
                <div className="block-input-line2">
                  <DropDownComponent
                    api="nationalities"
                    title="Nationality"
                    value={emergencyContactNationality}
                    onChange={handleEmergencyContactNationalityChange}
                  />

                  <DropDownComponent
                    api="countries"
                    title="Country"
                    value={emergencyContactCountry}
                    onChange={handleEmergencyContactCountryChange}
                  />
                </div>
              </div>
            </div>

            <div className="tab-form-container-fied">
              <InputComponent
                title="Name of the person paying for the treatment"
                width="300px"
                value={payerName}
                onChange={handlePayerNameChange}
              />

              <PhoneNumberInput
                title="Phone number of person paying"
                value={payerPhoneNumber}
                onChange={handlePayerPhoneNumberChange}
              />
            </div>
          </div>
        </div>
        <div className="line4-modal-percent">
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
              onClick={props.closemodal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => handleSave(clientId)}
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                    }
              }
              disabled={isLoading}
              //   onClick={handlePostRecord}
            >
              {isLoading ? "ADDING INTAKE..." : "ADD INTAKE"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddIntakeForm;
