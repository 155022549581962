import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

export default function PhoneNumberInputNoTitle(props) {
  const handleChange = (event) => {
    props.onChange(event);
  };
  const [value, setValue] = useState();
  return (
    <div className="input-label-container-newdesign">
      {/* <div className="input-label-2">
        {props.title}{" "}
        {props.isRequired && <span style={{ color: "red" }}>*</span>}
      </div> */}

      <PhoneInput
        className="phone-input"
        value={props.value}
        onChange={handleChange}
        placeholder={props.placeholder}
      />
    </div>
  );
}
