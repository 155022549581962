import React from "react";
import GradingIcon from "@mui/icons-material/Grading";
import IconButton from "@mui/material/IconButton";

function RevokeButton(props) {
  return (
    <IconButton
      aria-label="view"
      title="View"
      onClick={props.handleOpen2}
      style={{ backgroundColor: "#ff6f50", color: "white" }} // Define circle background and icon color
    >
      <GradingIcon style={{ color: "white" }} />
    </IconButton>
  );
}

export default RevokeButton;
