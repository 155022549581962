import React, { useState } from "react";
import { Box, Modal, Slide } from "@mui/material";
import ViewAcaReport from "../forms/ViewAcaReport";
import CheckWithDateMobileV2 from "../CheckWithDateMobileV2";
import ViewButtonMobileV2 from "../Buttons/ViewButtonMobileV2";
import AddButtonRoundMobileV4 from "../Buttons/AddButtonRoundMobileV4";
import ViewAcaReportMobileV2 from "../forms/ViewAcaReportMobileV2";

function FormLineAcaMobileV2(props) {
  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: "80%",
    borderRadius: 5,
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const [showViewModal, setShowViewModal] = useState(false);

  const openViewModal = () => {
    setShowViewModal(true);
  };

  const closeViewModal = () => {
    setShowViewModal(false);
  };

  return (
    <div
      style={{
        marginLeft: "0px",
        display: "flex",
        flexDirection: "row",
        height: "30px",
        borderRadius: "30px",
        border: "1px solid lightgrey",
        width: "100%",
        marginTop: "5px",
        marginBottom: "5px",
        paddingLeft: "15px",
        paddingRight: "5px",
      }}
    >
      {/* <Modal
        open={showViewModal}
        onClose={closeViewModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <ViewAcaReport {...props} closemodal={closeViewModal} />
        </Box>
      </Modal> */}

      <Modal
        open={showViewModal}
        onClose={closeViewModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Slide direction="left" in={showViewModal}>
          <Box
            sx={{
              position: "fixed",
              top: "5%",
              right: 0,
              width: "90%",
              height: "90%",
              minHeight: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              marginRight: "15px",
              pl: 0,
              pr: 0,
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
              zIndex: "1",
            }}
          >
            <ViewAcaReportMobileV2 {...props} closemodal={closeViewModal} />
          </Box>
        </Slide>
      </Modal>

      <div
        style={{
          width: "60%",
          fontSize: "12px",
          fontWeight: "600",
          display: "flex",
          justifyContent: "flex-start",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {props.title}
      </div>
      <div
        style={{
          width: "30%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.signed === false || props.signed === undefined ? (
          <CheckWithDateMobileV2 checked={false} content="Not signed" />
        ) : (
          <CheckWithDateMobileV2 checked={true} content={props.date} />
        )}
      </div>
      <div
        style={{
          width: "10%",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {props.signed ? (
          <ViewButtonMobileV2 handleOpen2={openViewModal} {...props} />
        ) : (
          <AddButtonRoundMobileV4 handleOpen={props.handleOpen} />
        )}

        {/* <CheckButton />
        <PrintButton />
        <MailButton /> */}
      </div>
    </div>
  );
}

export default FormLineAcaMobileV2;
