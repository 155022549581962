import React from "react";

export default function ChipStatusMobile(props) {
  return (
    <>
      {props.status === "In House" ? (
        <div
          className="chip-status-mobile chip-green"
          style={{ fontSize: "8px" }}
        >
          Inhouse
        </div>
      ) : props.status === "Aftercare" ? (
        <div
          className="chip-status-mobile chip-aftercare"
          style={{ fontSize: "8px" }}
        >
          Aftercare
        </div>
      ) : props.status === "Incoming" ? (
        <div
          className="chip-status-mobile chip-incoming"
          style={{ fontSize: "8px" }}
        >
          Incoming
        </div>
      ) : props.status === "Miracles@home" ? (
        <div
          className="chip-status-mobile chip-mathome"
          style={{ fontSize: "8px" }}
        >
          M@home
        </div>
      ) : props.status === "Hot Lead" ? (
        <div
          className="chip-status-mobile chip-lead"
          style={{ fontSize: "8px" }}
        >
          Hot Lead
        </div>
      ) : props.status === "Clinical Assessment" ? (
        <div
          className="chip-status-mobile chip-clinical-assessment"
          style={{ fontSize: "8px" }}
        >
          Clin. Assess.
        </div>
      ) : props.status === "Continued Therapy" ? (
        <div
          className="chip-status-mobile chip-continued-therapy"
          style={{ fontSize: "8px" }}
        >
          Cont. Ther.
        </div>
      ) : props.status === "Denied" ? (
        <div
          className="chip-status-mobile chip-denied-admission"
          style={{ fontSize: "8px" }}
        >
          Den. Adm.
        </div>
      ) : props.status === "Potential" ? (
        <div
          className="chip-status-mobile chip-potential"
          style={{ fontSize: "8px" }}
        >
          Potential
        </div>
      ) : (
        <div
          className="chip-status-mobiles chip-inactive"
          style={{ fontSize: "8px" }}
        >
          Inactive
        </div>
      )}
    </>
  );
}
