export default [
  {
    id: "1",
    en_short_name: "Afghanistan",
    textDropDownEn: "--Choose--",
  },
  {
    id: "4",
    alpha_2_code: "AF",
    alpha_3_code: "AFG",
    en_short_name: "Afghanistan",
    textDropDownEn: "Afghan",
  },
  {
    id: "248",
    alpha_2_code: "AX",
    alpha_3_code: "ALA",
    en_short_name: "\u00c5land Islands",
    textDropDownEn: "\u00c5land Island",
  },
  {
    id: "8",
    alpha_2_code: "AL",
    alpha_3_code: "ALB",
    en_short_name: "Albania",
    textDropDownEn: "Albanian",
  },
  {
    id: "12",
    alpha_2_code: "DZ",
    alpha_3_code: "DZA",
    en_short_name: "Algeria",
    textDropDownEn: "Algerian",
  },
  {
    id: "16",
    alpha_2_code: "AS",
    alpha_3_code: "ASM",
    en_short_name: "American Samoa",
    textDropDownEn: "American Samoan",
  },
  {
    id: "20",
    alpha_2_code: "AD",
    alpha_3_code: "AND",
    en_short_name: "Andorra",
    textDropDownEn: "Andorran",
  },
  {
    id: "24",
    alpha_2_code: "AO",
    alpha_3_code: "AGO",
    en_short_name: "Angola",
    textDropDownEn: "Angolan",
  },
  {
    id: "660",
    alpha_2_code: "AI",
    alpha_3_code: "AIA",
    en_short_name: "Anguilla",
    textDropDownEn: "Anguillan",
  },
  {
    id: "10",
    alpha_2_code: "AQ",
    alpha_3_code: "ATA",
    en_short_name: "Antarctica",
    textDropDownEn: "Antarctic",
  },
  {
    id: "28",
    alpha_2_code: "AG",
    alpha_3_code: "ATG",
    en_short_name: "Antigua and Barbuda",
    textDropDownEn: "Antiguan or Barbudan",
  },
  {
    id: "32",
    alpha_2_code: "AR",
    alpha_3_code: "ARG",
    en_short_name: "Argentina",
    textDropDownEn: "Argentine",
  },
  {
    id: "51",
    alpha_2_code: "AM",
    alpha_3_code: "ARM",
    en_short_name: "Armenia",
    textDropDownEn: "Armenian",
  },
  {
    id: "533",
    alpha_2_code: "AW",
    alpha_3_code: "ABW",
    en_short_name: "Aruba",
    textDropDownEn: "Aruban",
  },
  {
    id: "36",
    alpha_2_code: "AU",
    alpha_3_code: "AUS",
    en_short_name: "Australia",
    textDropDownEn: "Australian",
  },
  {
    id: "40",
    alpha_2_code: "AT",
    alpha_3_code: "AUT",
    en_short_name: "Austria",
    textDropDownEn: "Austrian",
  },
  {
    id: "31",
    alpha_2_code: "AZ",
    alpha_3_code: "AZE",
    en_short_name: "Azerbaijan",
    textDropDownEn: "Azerbaijani, Azeri",
  },
  {
    id: "44",
    alpha_2_code: "BS",
    alpha_3_code: "BHS",
    en_short_name: "Bahamas",
    textDropDownEn: "Bahamian",
  },
  {
    id: "48",
    alpha_2_code: "BH",
    alpha_3_code: "BHR",
    en_short_name: "Bahrain",
    textDropDownEn: "Bahraini",
  },
  {
    id: "50",
    alpha_2_code: "BD",
    alpha_3_code: "BGD",
    en_short_name: "Bangladesh",
    textDropDownEn: "Bangladeshi",
  },
  {
    id: "52",
    alpha_2_code: "BB",
    alpha_3_code: "BRB",
    en_short_name: "Barbados",
    textDropDownEn: "Barbadian",
  },
  {
    id: "112",
    alpha_2_code: "BY",
    alpha_3_code: "BLR",
    en_short_name: "Belarus",
    textDropDownEn: "Belarusian",
  },
  {
    id: "56",
    alpha_2_code: "BE",
    alpha_3_code: "BEL",
    en_short_name: "Belgium",
    textDropDownEn: "Belgian",
  },
  {
    id: "84",
    alpha_2_code: "BZ",
    alpha_3_code: "BLZ",
    en_short_name: "Belize",
    textDropDownEn: "Belizean",
  },
  {
    id: "204",
    alpha_2_code: "BJ",
    alpha_3_code: "BEN",
    en_short_name: "Benin",
    textDropDownEn: "Beninese, Beninois",
  },
  {
    id: "60",
    alpha_2_code: "BM",
    alpha_3_code: "BMU",
    en_short_name: "Bermuda",
    textDropDownEn: "Bermudian, Bermudan",
  },
  {
    id: "64",
    alpha_2_code: "BT",
    alpha_3_code: "BTN",
    en_short_name: "Bhutan",
    textDropDownEn: "Bhutanese",
  },
  {
    id: "68",
    alpha_2_code: "BO",
    alpha_3_code: "BOL",
    en_short_name: "Bolivia (Plurinational State of)",
    textDropDownEn: "Bolivian",
  },
  {
    id: "535",
    alpha_2_code: "BQ",
    alpha_3_code: "BES",
    en_short_name: "Bonaire, Sint Eustatius and Saba",
    textDropDownEn: "Bonaire",
  },
  {
    id: "70",
    alpha_2_code: "BA",
    alpha_3_code: "BIH",
    en_short_name: "Bosnia and Herzegovina",
    textDropDownEn: "Bosnian or Herzegovinian",
  },
  {
    id: "72",
    alpha_2_code: "BW",
    alpha_3_code: "BWA",
    en_short_name: "Botswana",
    textDropDownEn: "Motswana, Botswanan",
  },
  {
    id: "74",
    alpha_2_code: "BV",
    alpha_3_code: "BVT",
    en_short_name: "Bouvet Island",
    textDropDownEn: "Bouvet Island",
  },
  {
    id: "76",
    alpha_2_code: "BR",
    alpha_3_code: "BRA",
    en_short_name: "Brazil",
    textDropDownEn: "Brazilian",
  },
  {
    id: "86",
    alpha_2_code: "IO",
    alpha_3_code: "IOT",
    en_short_name: "British Indian Ocean Territory",
    textDropDownEn: "BIOT",
  },
  {
    id: "96",
    alpha_2_code: "BN",
    alpha_3_code: "BRN",
    en_short_name: "Brunei Darussalam",
    textDropDownEn: "Bruneian",
  },
  {
    id: "100",
    alpha_2_code: "BG",
    alpha_3_code: "BGR",
    en_short_name: "Bulgaria",
    textDropDownEn: "Bulgarian",
  },
  {
    id: "854",
    alpha_2_code: "BF",
    alpha_3_code: "BFA",
    en_short_name: "Burkina Faso",
    textDropDownEn: "Burkinab\u00e9",
  },
  {
    id: "108",
    alpha_2_code: "BI",
    alpha_3_code: "BDI",
    en_short_name: "Burundi",
    textDropDownEn: "Burundian",
  },
  {
    id: "132",
    alpha_2_code: "CV",
    alpha_3_code: "CPV",
    en_short_name: "Cabo Verde",
    textDropDownEn: "Cabo Verdean",
  },
  {
    id: "116",
    alpha_2_code: "KH",
    alpha_3_code: "KHM",
    en_short_name: "Cambodia",
    textDropDownEn: "Cambodian",
  },
  {
    id: "120",
    alpha_2_code: "CM",
    alpha_3_code: "CMR",
    en_short_name: "Cameroon",
    textDropDownEn: "Cameroonian",
  },
  {
    id: "124",
    alpha_2_code: "CA",
    alpha_3_code: "CAN",
    en_short_name: "Canada",
    textDropDownEn: "Canadian",
  },
  {
    id: "136",
    alpha_2_code: "KY",
    alpha_3_code: "CYM",
    en_short_name: "Cayman Islands",
    textDropDownEn: "Caymanian",
  },
  {
    id: "140",
    alpha_2_code: "CF",
    alpha_3_code: "CAF",
    en_short_name: "Central African Republic",
    textDropDownEn: "Central African",
  },
  {
    id: "148",
    alpha_2_code: "TD",
    alpha_3_code: "TCD",
    en_short_name: "Chad",
    textDropDownEn: "Chadian",
  },
  {
    id: "152",
    alpha_2_code: "CL",
    alpha_3_code: "CHL",
    en_short_name: "Chile",
    textDropDownEn: "Chilean",
  },
  {
    id: "156",
    alpha_2_code: "CN",
    alpha_3_code: "CHN",
    en_short_name: "China",
    textDropDownEn: "Chinese",
  },
  {
    id: "162",
    alpha_2_code: "CX",
    alpha_3_code: "CXR",
    en_short_name: "Christmas Island",
    textDropDownEn: "Christmas Island",
  },
  {
    id: "166",
    alpha_2_code: "CC",
    alpha_3_code: "CCK",
    en_short_name: "Cocos (Keeling) Islands",
    textDropDownEn: "Cocos Island",
  },
  {
    id: "170",
    alpha_2_code: "CO",
    alpha_3_code: "COL",
    en_short_name: "Colombia",
    textDropDownEn: "Colombian",
  },
  {
    id: "174",
    alpha_2_code: "KM",
    alpha_3_code: "COM",
    en_short_name: "Comoros",
    textDropDownEn: "Comoran, Comorian",
  },
  {
    id: "178",
    alpha_2_code: "CG",
    alpha_3_code: "COG",
    en_short_name: "Congo (Republic of the)",
    textDropDownEn: "Congolese",
  },
  {
    id: "180",
    alpha_2_code: "CD",
    alpha_3_code: "COD",
    en_short_name: "Congo (Democratic Republic of the)",
    textDropDownEn: "Congolese",
  },
  {
    id: "184",
    alpha_2_code: "CK",
    alpha_3_code: "COK",
    en_short_name: "Cook Islands",
    textDropDownEn: "Cook Island",
  },
  {
    id: "188",
    alpha_2_code: "CR",
    alpha_3_code: "CRI",
    en_short_name: "Costa Rica",
    textDropDownEn: "Costa Rican",
  },
  {
    id: "384",
    alpha_2_code: "CI",
    alpha_3_code: "CIV",
    en_short_name: "C\u00f4te d'Ivoire",
    textDropDownEn: "Ivorian",
  },
  {
    id: "191",
    alpha_2_code: "HR",
    alpha_3_code: "HRV",
    en_short_name: "Croatia",
    textDropDownEn: "Croatian",
  },
  {
    id: "192",
    alpha_2_code: "CU",
    alpha_3_code: "CUB",
    en_short_name: "Cuba",
    textDropDownEn: "Cuban",
  },
  {
    id: "531",
    alpha_2_code: "CW",
    alpha_3_code: "CUW",
    en_short_name: "Cura\u00e7ao",
    textDropDownEn: "Cura\u00e7aoan",
  },
  {
    id: "196",
    alpha_2_code: "CY",
    alpha_3_code: "CYP",
    en_short_name: "Cyprus",
    textDropDownEn: "Cypriot",
  },
  {
    id: "203",
    alpha_2_code: "CZ",
    alpha_3_code: "CZE",
    en_short_name: "Czech Republic",
    textDropDownEn: "Czech",
  },
  {
    id: "208",
    alpha_2_code: "DK",
    alpha_3_code: "DNK",
    en_short_name: "Denmark",
    textDropDownEn: "Danish",
  },
  {
    id: "262",
    alpha_2_code: "DJ",
    alpha_3_code: "DJI",
    en_short_name: "Djibouti",
    textDropDownEn: "Djiboutian",
  },
  {
    id: "212",
    alpha_2_code: "DM",
    alpha_3_code: "DMA",
    en_short_name: "Dominica",
    textDropDownEn: "Dominican",
  },
  {
    id: "214",
    alpha_2_code: "DO",
    alpha_3_code: "DOM",
    en_short_name: "Dominican Republic",
    textDropDownEn: "Dominican",
  },
  {
    id: "218",
    alpha_2_code: "EC",
    alpha_3_code: "ECU",
    en_short_name: "Ecuador",
    textDropDownEn: "Ecuadorian",
  },
  {
    id: "818",
    alpha_2_code: "EG",
    alpha_3_code: "EGY",
    en_short_name: "Egypt",
    textDropDownEn: "Egyptian",
  },
  {
    id: "222",
    alpha_2_code: "SV",
    alpha_3_code: "SLV",
    en_short_name: "El Salvador",
    textDropDownEn: "Salvadoran",
  },
  {
    id: "226",
    alpha_2_code: "GQ",
    alpha_3_code: "GNQ",
    en_short_name: "Equatorial Guinea",
    textDropDownEn: "Equatorial Guinean, Equatoguinean",
  },
  {
    id: "232",
    alpha_2_code: "ER",
    alpha_3_code: "ERI",
    en_short_name: "Eritrea",
    textDropDownEn: "Eritrean",
  },
  {
    id: "233",
    alpha_2_code: "EE",
    alpha_3_code: "EST",
    en_short_name: "Estonia",
    textDropDownEn: "Estonian",
  },
  {
    id: "231",
    alpha_2_code: "ET",
    alpha_3_code: "ETH",
    en_short_name: "Ethiopia",
    textDropDownEn: "Ethiopian",
  },
  {
    id: "238",
    alpha_2_code: "FK",
    alpha_3_code: "FLK",
    en_short_name: "Falkland Islands (Malvinas)",
    textDropDownEn: "Falkland Island",
  },
  {
    id: "234",
    alpha_2_code: "FO",
    alpha_3_code: "FRO",
    en_short_name: "Faroe Islands",
    textDropDownEn: "Faroese",
  },
  {
    id: "242",
    alpha_2_code: "FJ",
    alpha_3_code: "FJI",
    en_short_name: "Fiji",
    textDropDownEn: "Fijian",
  },
  {
    id: "246",
    alpha_2_code: "FI",
    alpha_3_code: "FIN",
    en_short_name: "Finland",
    textDropDownEn: "Finnish",
  },
  {
    id: "250",
    alpha_2_code: "FR",
    alpha_3_code: "FRA",
    en_short_name: "France",
    textDropDownEn: "French",
  },
  {
    id: "254",
    alpha_2_code: "GF",
    alpha_3_code: "GUF",
    en_short_name: "French Guiana",
    textDropDownEn: "French Guianese",
  },
  {
    id: "258",
    alpha_2_code: "PF",
    alpha_3_code: "PYF",
    en_short_name: "French Polynesia",
    textDropDownEn: "French Polynesian",
  },
  {
    id: "260",
    alpha_2_code: "TF",
    alpha_3_code: "ATF",
    en_short_name: "French Southern Territories",
    textDropDownEn: "French Southern Territories",
  },
  {
    id: "266",
    alpha_2_code: "GA",
    alpha_3_code: "GAB",
    en_short_name: "Gabon",
    textDropDownEn: "Gabonese",
  },
  {
    id: "270",
    alpha_2_code: "GM",
    alpha_3_code: "GMB",
    en_short_name: "Gambia",
    textDropDownEn: "Gambian",
  },
  {
    id: "268",
    alpha_2_code: "GE",
    alpha_3_code: "GEO",
    en_short_name: "Georgia",
    textDropDownEn: "Georgian",
  },
  {
    id: "276",
    alpha_2_code: "DE",
    alpha_3_code: "DEU",
    en_short_name: "Germany",
    textDropDownEn: "German",
  },
  {
    id: "288",
    alpha_2_code: "GH",
    alpha_3_code: "GHA",
    en_short_name: "Ghana",
    textDropDownEn: "Ghanaian",
  },
  {
    id: "292",
    alpha_2_code: "GI",
    alpha_3_code: "GIB",
    en_short_name: "Gibraltar",
    textDropDownEn: "Gibraltar",
  },
  {
    id: "300",
    alpha_2_code: "GR",
    alpha_3_code: "GRC",
    en_short_name: "Greece",
    textDropDownEn: "Greek, Hellenic",
  },
  {
    id: "304",
    alpha_2_code: "GL",
    alpha_3_code: "GRL",
    en_short_name: "Greenland",
    textDropDownEn: "Greenlandic",
  },
  {
    id: "308",
    alpha_2_code: "GD",
    alpha_3_code: "GRD",
    en_short_name: "Grenada",
    textDropDownEn: "Grenadian",
  },
  {
    id: "312",
    alpha_2_code: "GP",
    alpha_3_code: "GLP",
    en_short_name: "Guadeloupe",
    textDropDownEn: "Guadeloupe",
  },
  {
    id: "316",
    alpha_2_code: "GU",
    alpha_3_code: "GUM",
    en_short_name: "Guam",
    textDropDownEn: "Guamanian, Guambat",
  },
  {
    id: "320",
    alpha_2_code: "GT",
    alpha_3_code: "GTM",
    en_short_name: "Guatemala",
    textDropDownEn: "Guatemalan",
  },
  {
    id: "831",
    alpha_2_code: "GG",
    alpha_3_code: "GGY",
    en_short_name: "Guernsey",
    textDropDownEn: "Channel Island",
  },
  {
    id: "324",
    alpha_2_code: "GN",
    alpha_3_code: "GIN",
    en_short_name: "Guinea",
    textDropDownEn: "Guinean",
  },
  {
    id: "624",
    alpha_2_code: "GW",
    alpha_3_code: "GNB",
    en_short_name: "Guinea-Bissau",
    textDropDownEn: "Bissau-Guinean",
  },
  {
    id: "328",
    alpha_2_code: "GY",
    alpha_3_code: "GUY",
    en_short_name: "Guyana",
    textDropDownEn: "Guyanese",
  },
  {
    id: "332",
    alpha_2_code: "HT",
    alpha_3_code: "HTI",
    en_short_name: "Haiti",
    textDropDownEn: "Haitian",
  },
  {
    id: "334",
    alpha_2_code: "HM",
    alpha_3_code: "HMD",
    en_short_name: "Heard Island and McDonald Islands",
    textDropDownEn: "Heard Island or McDonald Islands",
  },
  {
    id: "336",
    alpha_2_code: "VA",
    alpha_3_code: "VAT",
    en_short_name: "Vatican City State",
    textDropDownEn: "Vatican",
  },
  {
    id: "340",
    alpha_2_code: "HN",
    alpha_3_code: "HND",
    en_short_name: "Honduras",
    textDropDownEn: "Honduran",
  },
  {
    id: "344",
    alpha_2_code: "HK",
    alpha_3_code: "HKG",
    en_short_name: "Hong Kong",
    textDropDownEn: "Hong Kong, Hong Kongese",
  },
  {
    id: "348",
    alpha_2_code: "HU",
    alpha_3_code: "HUN",
    en_short_name: "Hungary",
    textDropDownEn: "Hungarian, Magyar",
  },
  {
    id: "352",
    alpha_2_code: "IS",
    alpha_3_code: "ISL",
    en_short_name: "Iceland",
    textDropDownEn: "Icelandic",
  },
  {
    id: "356",
    alpha_2_code: "IN",
    alpha_3_code: "IND",
    en_short_name: "India",
    textDropDownEn: "Indian",
  },
  {
    id: "360",
    alpha_2_code: "ID",
    alpha_3_code: "IDN",
    en_short_name: "Indonesia",
    textDropDownEn: "Indonesian",
  },
  {
    id: "364",
    alpha_2_code: "IR",
    alpha_3_code: "IRN",
    en_short_name: "Iran",
    textDropDownEn: "Iranian, Persian",
  },
  {
    id: "368",
    alpha_2_code: "IQ",
    alpha_3_code: "IRQ",
    en_short_name: "Iraq",
    textDropDownEn: "Iraqi",
  },
  {
    id: "372",
    alpha_2_code: "IE",
    alpha_3_code: "IRL",
    en_short_name: "Ireland",
    textDropDownEn: "Irish",
  },
  {
    id: "833",
    alpha_2_code: "IM",
    alpha_3_code: "IMN",
    en_short_name: "Isle of Man",
    textDropDownEn: "Manx",
  },
  {
    id: "376",
    alpha_2_code: "IL",
    alpha_3_code: "ISR",
    en_short_name: "Israel",
    textDropDownEn: "Israeli",
  },
  {
    id: "380",
    alpha_2_code: "IT",
    alpha_3_code: "ITA",
    en_short_name: "Italy",
    textDropDownEn: "Italian",
  },
  {
    id: "388",
    alpha_2_code: "JM",
    alpha_3_code: "JAM",
    en_short_name: "Jamaica",
    textDropDownEn: "Jamaican",
  },
  {
    id: "392",
    alpha_2_code: "JP",
    alpha_3_code: "JPN",
    en_short_name: "Japan",
    textDropDownEn: "Japanese",
  },
  {
    id: "832",
    alpha_2_code: "JE",
    alpha_3_code: "JEY",
    en_short_name: "Jersey",
    textDropDownEn: "Channel Island",
  },
  {
    id: "400",
    alpha_2_code: "JO",
    alpha_3_code: "JOR",
    en_short_name: "Jordan",
    textDropDownEn: "Jordanian",
  },
  {
    id: "398",
    alpha_2_code: "KZ",
    alpha_3_code: "KAZ",
    en_short_name: "Kazakhstan",
    textDropDownEn: "Kazakhstani, Kazakh",
  },
  {
    id: "404",
    alpha_2_code: "KE",
    alpha_3_code: "KEN",
    en_short_name: "Kenya",
    textDropDownEn: "Kenyan",
  },
  {
    id: "296",
    alpha_2_code: "KI",
    alpha_3_code: "KIR",
    en_short_name: "Kiribati",
    textDropDownEn: "I-Kiribati",
  },
  {
    id: "408",
    alpha_2_code: "KP",
    alpha_3_code: "PRK",
    en_short_name: "Korea (Democratic People's Republic of)",
    textDropDownEn: "North Korean",
  },
  {
    id: "410",
    alpha_2_code: "KR",
    alpha_3_code: "KOR",
    en_short_name: "Korea (Republic of)",
    textDropDownEn: "South Korean",
  },
  {
    id: "414",
    alpha_2_code: "KW",
    alpha_3_code: "KWT",
    en_short_name: "Kuwait",
    textDropDownEn: "Kuwaiti",
  },
  {
    id: "417",
    alpha_2_code: "KG",
    alpha_3_code: "KGZ",
    en_short_name: "Kyrgyzstan",
    textDropDownEn: "Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz",
  },
  {
    id: "418",
    alpha_2_code: "LA",
    alpha_3_code: "LAO",
    en_short_name: "Lao People's Democratic Republic",
    textDropDownEn: "Lao, Laotian",
  },
  {
    id: "428",
    alpha_2_code: "LV",
    alpha_3_code: "LVA",
    en_short_name: "Latvia",
    textDropDownEn: "Latvian",
  },
  {
    id: "422",
    alpha_2_code: "LB",
    alpha_3_code: "LBN",
    en_short_name: "Lebanon",
    textDropDownEn: "Lebanese",
  },
  {
    id: "426",
    alpha_2_code: "LS",
    alpha_3_code: "LSO",
    en_short_name: "Lesotho",
    textDropDownEn: "Basotho",
  },
  {
    id: "430",
    alpha_2_code: "LR",
    alpha_3_code: "LBR",
    en_short_name: "Liberia",
    textDropDownEn: "Liberian",
  },
  {
    id: "434",
    alpha_2_code: "LY",
    alpha_3_code: "LBY",
    en_short_name: "Libya",
    textDropDownEn: "Libyan",
  },
  {
    id: "438",
    alpha_2_code: "LI",
    alpha_3_code: "LIE",
    en_short_name: "Liechtenstein",
    textDropDownEn: "Liechtenstein",
  },
  {
    id: "440",
    alpha_2_code: "LT",
    alpha_3_code: "LTU",
    en_short_name: "Lithuania",
    textDropDownEn: "Lithuanian",
  },
  {
    id: "442",
    alpha_2_code: "LU",
    alpha_3_code: "LUX",
    en_short_name: "Luxembourg",
    textDropDownEn: "Luxembourg, Luxembourgish",
  },
  {
    id: "446",
    alpha_2_code: "MO",
    alpha_3_code: "MAC",
    en_short_name: "Macao",
    textDropDownEn: "Macanese, Chinese",
  },
  {
    id: "807",
    alpha_2_code: "MK",
    alpha_3_code: "MKD",
    en_short_name: "Macedonia (the former Yugoslav Republic of)",
    textDropDownEn: "Macedonian",
  },
  {
    id: "450",
    alpha_2_code: "MG",
    alpha_3_code: "MDG",
    en_short_name: "Madagascar",
    textDropDownEn: "Malagasy",
  },
  {
    id: "454",
    alpha_2_code: "MW",
    alpha_3_code: "MWI",
    en_short_name: "Malawi",
    textDropDownEn: "Malawian",
  },
  {
    id: "458",
    alpha_2_code: "MY",
    alpha_3_code: "MYS",
    en_short_name: "Malaysia",
    textDropDownEn: "Malaysian",
  },
  {
    id: "462",
    alpha_2_code: "MV",
    alpha_3_code: "MDV",
    en_short_name: "Maldives",
    textDropDownEn: "Maldivian",
  },
  {
    id: "466",
    alpha_2_code: "ML",
    alpha_3_code: "MLI",
    en_short_name: "Mali",
    textDropDownEn: "Malian, Malinese",
  },
  {
    id: "470",
    alpha_2_code: "MT",
    alpha_3_code: "MLT",
    en_short_name: "Malta",
    textDropDownEn: "Maltese",
  },
  {
    id: "584",
    alpha_2_code: "MH",
    alpha_3_code: "MHL",
    en_short_name: "Marshall Islands",
    textDropDownEn: "Marshallese",
  },
  {
    id: "474",
    alpha_2_code: "MQ",
    alpha_3_code: "MTQ",
    en_short_name: "Martinique",
    textDropDownEn: "Martiniquais, Martinican",
  },
  {
    id: "478",
    alpha_2_code: "MR",
    alpha_3_code: "MRT",
    en_short_name: "Mauritania",
    textDropDownEn: "Mauritanian",
  },
  {
    id: "480",
    alpha_2_code: "MU",
    alpha_3_code: "MUS",
    en_short_name: "Mauritius",
    textDropDownEn: "Mauritian",
  },
  {
    id: "175",
    alpha_2_code: "YT",
    alpha_3_code: "MYT",
    en_short_name: "Mayotte",
    textDropDownEn: "Mahoran",
  },
  {
    id: "484",
    alpha_2_code: "MX",
    alpha_3_code: "MEX",
    en_short_name: "Mexico",
    textDropDownEn: "Mexican",
  },
  {
    id: "583",
    alpha_2_code: "FM",
    alpha_3_code: "FSM",
    en_short_name: "Micronesia (Federated States of)",
    textDropDownEn: "Micronesian",
  },
  {
    id: "498",
    alpha_2_code: "MD",
    alpha_3_code: "MDA",
    en_short_name: "Moldova (Republic of)",
    textDropDownEn: "Moldovan",
  },
  {
    id: "492",
    alpha_2_code: "MC",
    alpha_3_code: "MCO",
    en_short_name: "Monaco",
    textDropDownEn: "Mon\u00e9gasque, Monacan",
  },
  {
    id: "496",
    alpha_2_code: "MN",
    alpha_3_code: "MNG",
    en_short_name: "Mongolia",
    textDropDownEn: "Mongolian",
  },
  {
    id: "499",
    alpha_2_code: "ME",
    alpha_3_code: "MNE",
    en_short_name: "Montenegro",
    textDropDownEn: "Montenegrin",
  },
  {
    id: "500",
    alpha_2_code: "MS",
    alpha_3_code: "MSR",
    en_short_name: "Montserrat",
    textDropDownEn: "Montserratian",
  },
  {
    id: "504",
    alpha_2_code: "MA",
    alpha_3_code: "MAR",
    en_short_name: "Morocco",
    textDropDownEn: "Moroccan",
  },
  {
    id: "508",
    alpha_2_code: "MZ",
    alpha_3_code: "MOZ",
    en_short_name: "Mozambique",
    textDropDownEn: "Mozambican",
  },
  {
    id: "104",
    alpha_2_code: "MM",
    alpha_3_code: "MMR",
    en_short_name: "Myanmar",
    textDropDownEn: "Burmese",
  },
  {
    id: "516",
    alpha_2_code: "NA",
    alpha_3_code: "NAM",
    en_short_name: "Namibia",
    textDropDownEn: "Namibian",
  },
  {
    id: "520",
    alpha_2_code: "NR",
    alpha_3_code: "NRU",
    en_short_name: "Nauru",
    textDropDownEn: "Nauruan",
  },
  {
    id: "524",
    alpha_2_code: "NP",
    alpha_3_code: "NPL",
    en_short_name: "Nepal",
    textDropDownEn: "Nepali, Nepalese",
  },
  {
    id: "528",
    alpha_2_code: "NL",
    alpha_3_code: "NLD",
    en_short_name: "Netherlands",
    textDropDownEn: "Dutch, Netherlandic",
  },
  {
    id: "540",
    alpha_2_code: "NC",
    alpha_3_code: "NCL",
    en_short_name: "New Caledonia",
    textDropDownEn: "New Caledonian",
  },
  {
    id: "554",
    alpha_2_code: "NZ",
    alpha_3_code: "NZL",
    en_short_name: "New Zealand",
    textDropDownEn: "New Zealand, NZ",
  },
  {
    id: "558",
    alpha_2_code: "NI",
    alpha_3_code: "NIC",
    en_short_name: "Nicaragua",
    textDropDownEn: "Nicaraguan",
  },
  {
    id: "562",
    alpha_2_code: "NE",
    alpha_3_code: "NER",
    en_short_name: "Niger",
    textDropDownEn: "Nigerien",
  },
  {
    id: "566",
    alpha_2_code: "NG",
    alpha_3_code: "NGA",
    en_short_name: "Nigeria",
    textDropDownEn: "Nigerian",
  },
  {
    id: "570",
    alpha_2_code: "NU",
    alpha_3_code: "NIU",
    en_short_name: "Niue",
    textDropDownEn: "Niuean",
  },
  {
    id: "574",
    alpha_2_code: "NF",
    alpha_3_code: "NFK",
    en_short_name: "Norfolk Island",
    textDropDownEn: "Norfolk Island",
  },
  {
    id: "580",
    alpha_2_code: "MP",
    alpha_3_code: "MNP",
    en_short_name: "Northern Mariana Islands",
    textDropDownEn: "Northern Marianan",
  },
  {
    id: "578",
    alpha_2_code: "NO",
    alpha_3_code: "NOR",
    en_short_name: "Norway",
    textDropDownEn: "Norwegian",
  },
  {
    id: "512",
    alpha_2_code: "OM",
    alpha_3_code: "OMN",
    en_short_name: "Oman",
    textDropDownEn: "Omani",
  },
  {
    id: "586",
    alpha_2_code: "PK",
    alpha_3_code: "PAK",
    en_short_name: "Pakistan",
    textDropDownEn: "Pakistani",
  },
  {
    id: "585",
    alpha_2_code: "PW",
    alpha_3_code: "PLW",
    en_short_name: "Palau",
    textDropDownEn: "Palauan",
  },
  {
    id: "275",
    alpha_2_code: "PS",
    alpha_3_code: "PSE",
    en_short_name: "Palestine, State of",
    textDropDownEn: "Palestinian",
  },
  {
    id: "591",
    alpha_2_code: "PA",
    alpha_3_code: "PAN",
    en_short_name: "Panama",
    textDropDownEn: "Panamanian",
  },
  {
    id: "598",
    alpha_2_code: "PG",
    alpha_3_code: "PNG",
    en_short_name: "Papua New Guinea",
    textDropDownEn: "Papua New Guinean, Papuan",
  },
  {
    id: "600",
    alpha_2_code: "PY",
    alpha_3_code: "PRY",
    en_short_name: "Paraguay",
    textDropDownEn: "Paraguayan",
  },
  {
    id: "604",
    alpha_2_code: "PE",
    alpha_3_code: "PER",
    en_short_name: "Peru",
    textDropDownEn: "Peruvian",
  },
  {
    id: "608",
    alpha_2_code: "PH",
    alpha_3_code: "PHL",
    en_short_name: "Philippines",
    textDropDownEn: "Philippine, Filipino",
  },
  {
    id: "612",
    alpha_2_code: "PN",
    alpha_3_code: "PCN",
    en_short_name: "Pitcairn",
    textDropDownEn: "Pitcairn Island",
  },
  {
    id: "616",
    alpha_2_code: "PL",
    alpha_3_code: "POL",
    en_short_name: "Poland",
    textDropDownEn: "Polish",
  },
  {
    id: "620",
    alpha_2_code: "PT",
    alpha_3_code: "PRT",
    en_short_name: "Portugal",
    textDropDownEn: "Portuguese",
  },
  {
    id: "630",
    alpha_2_code: "PR",
    alpha_3_code: "PRI",
    en_short_name: "Puerto Rico",
    textDropDownEn: "Puerto Rican",
  },
  {
    id: "634",
    alpha_2_code: "QA",
    alpha_3_code: "QAT",
    en_short_name: "Qatar",
    textDropDownEn: "Qatari",
  },
  {
    id: "638",
    alpha_2_code: "RE",
    alpha_3_code: "REU",
    en_short_name: "R\u00e9union",
    textDropDownEn: "R\u00e9unionese, R\u00e9unionnais",
  },
  {
    id: "642",
    alpha_2_code: "RO",
    alpha_3_code: "ROU",
    en_short_name: "Romania",
    textDropDownEn: "Romanian",
  },
  {
    id: "643",
    alpha_2_code: "RU",
    alpha_3_code: "RUS",
    en_short_name: "Russian Federation",
    textDropDownEn: "Russian",
  },
  {
    id: "646",
    alpha_2_code: "RW",
    alpha_3_code: "RWA",
    en_short_name: "Rwanda",
    textDropDownEn: "Rwandan",
  },
  {
    id: "652",
    alpha_2_code: "BL",
    alpha_3_code: "BLM",
    en_short_name: "Saint Barth\u00e9lemy",
    textDropDownEn: "Barth\u00e9lemois",
  },
  {
    id: "654",
    alpha_2_code: "SH",
    alpha_3_code: "SHN",
    en_short_name: "Saint Helena, Ascension and Tristan da Cunha",
    textDropDownEn: "Saint Helenian",
  },
  {
    id: "659",
    alpha_2_code: "KN",
    alpha_3_code: "KNA",
    en_short_name: "Saint Kitts and Nevis",
    textDropDownEn: "Kittitian or Nevisian",
  },
  {
    id: "662",
    alpha_2_code: "LC",
    alpha_3_code: "LCA",
    en_short_name: "Saint Lucia",
    textDropDownEn: "Saint Lucian",
  },
  {
    id: "663",
    alpha_2_code: "MF",
    alpha_3_code: "MAF",
    en_short_name: "Saint Martin (French part)",
    textDropDownEn: "Saint-Martinoise",
  },
  {
    id: "666",
    alpha_2_code: "PM",
    alpha_3_code: "SPM",
    en_short_name: "Saint Pierre and Miquelon",
    textDropDownEn: "Saint-Pierrais or Miquelonnais",
  },
  {
    id: "670",
    alpha_2_code: "VC",
    alpha_3_code: "VCT",
    en_short_name: "Saint Vincent and the Grenadines",
    textDropDownEn: "Saint Vincentian, Vincentian",
  },
  {
    id: "882",
    alpha_2_code: "WS",
    alpha_3_code: "WSM",
    en_short_name: "Samoa",
    textDropDownEn: "Samoan",
  },
  {
    id: "674",
    alpha_2_code: "SM",
    alpha_3_code: "SMR",
    en_short_name: "San Marino",
    textDropDownEn: "Sammarinese",
  },
  {
    id: "678",
    alpha_2_code: "ST",
    alpha_3_code: "STP",
    en_short_name: "Sao Tome and Principe",
    textDropDownEn: "S\u00e3o Tom\u00e9an",
  },
  {
    id: "682",
    alpha_2_code: "SA",
    alpha_3_code: "SAU",
    en_short_name: "Saudi Arabia",
    textDropDownEn: "Saudi, Saudi Arabian",
  },
  {
    id: "686",
    alpha_2_code: "SN",
    alpha_3_code: "SEN",
    en_short_name: "Senegal",
    textDropDownEn: "Senegalese",
  },
  {
    id: "688",
    alpha_2_code: "RS",
    alpha_3_code: "SRB",
    en_short_name: "Serbia",
    textDropDownEn: "Serbian",
  },
  {
    id: "690",
    alpha_2_code: "SC",
    alpha_3_code: "SYC",
    en_short_name: "Seychelles",
    textDropDownEn: "Seychellois",
  },
  {
    id: "694",
    alpha_2_code: "SL",
    alpha_3_code: "SLE",
    en_short_name: "Sierra Leone",
    textDropDownEn: "Sierra Leonean",
  },
  {
    id: "702",
    alpha_2_code: "SG",
    alpha_3_code: "SGP",
    en_short_name: "Singapore",
    textDropDownEn: "Singaporean",
  },
  {
    id: "534",
    alpha_2_code: "SX",
    alpha_3_code: "SXM",
    en_short_name: "Sint Maarten (Dutch part)",
    textDropDownEn: "Sint Maarten",
  },
  {
    id: "703",
    alpha_2_code: "SK",
    alpha_3_code: "SVK",
    en_short_name: "Slovakia",
    textDropDownEn: "Slovak",
  },
  {
    id: "705",
    alpha_2_code: "SI",
    alpha_3_code: "SVN",
    en_short_name: "Slovenia",
    textDropDownEn: "Slovenian, Slovene",
  },
  {
    id: "90",
    alpha_2_code: "SB",
    alpha_3_code: "SLB",
    en_short_name: "Solomon Islands",
    textDropDownEn: "Solomon Island",
  },
  {
    id: "706",
    alpha_2_code: "SO",
    alpha_3_code: "SOM",
    en_short_name: "Somalia",
    textDropDownEn: "Somali, Somalian",
  },
  {
    id: "710",
    alpha_2_code: "ZA",
    alpha_3_code: "ZAF",
    en_short_name: "South Africa",
    textDropDownEn: "South African",
  },
  {
    id: "239",
    alpha_2_code: "GS",
    alpha_3_code: "SGS",
    en_short_name: "South Georgia and the South Sandwich Islands",
    textDropDownEn: "South Georgia or South Sandwich Islands",
  },
  {
    id: "728",
    alpha_2_code: "SS",
    alpha_3_code: "SSD",
    en_short_name: "South Sudan",
    textDropDownEn: "South Sudanese",
  },
  {
    id: "724",
    alpha_2_code: "ES",
    alpha_3_code: "ESP",
    en_short_name: "Spain",
    textDropDownEn: "Spanish",
  },
  {
    id: "144",
    alpha_2_code: "LK",
    alpha_3_code: "LKA",
    en_short_name: "Sri Lanka",
    textDropDownEn: "Sri Lankan",
  },
  {
    id: "729",
    alpha_2_code: "SD",
    alpha_3_code: "SDN",
    en_short_name: "Sudan",
    textDropDownEn: "Sudanese",
  },
  {
    id: "740",
    alpha_2_code: "SR",
    alpha_3_code: "SUR",
    en_short_name: "Suriname",
    textDropDownEn: "Surinamese",
  },
  {
    id: "744",
    alpha_2_code: "SJ",
    alpha_3_code: "SJM",
    en_short_name: "Svalbard and Jan Mayen",
    textDropDownEn: "Svalbard",
  },
  {
    id: "748",
    alpha_2_code: "SZ",
    alpha_3_code: "SWZ",
    en_short_name: "Swaziland",
    textDropDownEn: "Swazi",
  },
  {
    id: "752",
    alpha_2_code: "SE",
    alpha_3_code: "SWE",
    en_short_name: "Sweden",
    textDropDownEn: "Swedish",
  },
  {
    id: "756",
    alpha_2_code: "CH",
    alpha_3_code: "CHE",
    en_short_name: "Switzerland",
    textDropDownEn: "Swiss",
  },
  {
    id: "760",
    alpha_2_code: "SY",
    alpha_3_code: "SYR",
    en_short_name: "Syrian Arab Republic",
    textDropDownEn: "Syrian",
  },
  {
    id: "158",
    alpha_2_code: "TW",
    alpha_3_code: "TWN",
    en_short_name: "Taiwan, Province of China",
    textDropDownEn: "Chinese, Taiwanese",
  },
  {
    id: "762",
    alpha_2_code: "TJ",
    alpha_3_code: "TJK",
    en_short_name: "Tajikistan",
    textDropDownEn: "Tajikistani",
  },
  {
    id: "834",
    alpha_2_code: "TZ",
    alpha_3_code: "TZA",
    en_short_name: "Tanzania, United Republic of",
    textDropDownEn: "Tanzanian",
  },
  {
    id: "764",
    alpha_2_code: "TH",
    alpha_3_code: "THA",
    en_short_name: "Thailand",
    textDropDownEn: "Thai",
  },
  {
    id: "626",
    alpha_2_code: "TL",
    alpha_3_code: "TLS",
    en_short_name: "Timor-Leste",
    textDropDownEn: "Timorese",
  },
  {
    id: "768",
    alpha_2_code: "TG",
    alpha_3_code: "TGO",
    en_short_name: "Togo",
    textDropDownEn: "Togolese",
  },
  {
    id: "772",
    alpha_2_code: "TK",
    alpha_3_code: "TKL",
    en_short_name: "Tokelau",
    textDropDownEn: "Tokelauan",
  },
  {
    id: "776",
    alpha_2_code: "TO",
    alpha_3_code: "TON",
    en_short_name: "Tonga",
    textDropDownEn: "Tongan",
  },
  {
    id: "780",
    alpha_2_code: "TT",
    alpha_3_code: "TTO",
    en_short_name: "Trinidad and Tobago",
    textDropDownEn: "Trinidadian or Tobagonian",
  },
  {
    id: "788",
    alpha_2_code: "TN",
    alpha_3_code: "TUN",
    en_short_name: "Tunisia",
    textDropDownEn: "Tunisian",
  },
  {
    id: "792",
    alpha_2_code: "TR",
    alpha_3_code: "TUR",
    en_short_name: "Turkey",
    textDropDownEn: "Turkish",
  },
  {
    id: "795",
    alpha_2_code: "TM",
    alpha_3_code: "TKM",
    en_short_name: "Turkmenistan",
    textDropDownEn: "Turkmen",
  },
  {
    id: "796",
    alpha_2_code: "TC",
    alpha_3_code: "TCA",
    en_short_name: "Turks and Caicos Islands",
    textDropDownEn: "Turks and Caicos Island",
  },
  {
    id: "798",
    alpha_2_code: "TV",
    alpha_3_code: "TUV",
    en_short_name: "Tuvalu",
    textDropDownEn: "Tuvaluan",
  },
  {
    id: "800",
    alpha_2_code: "UG",
    alpha_3_code: "UGA",
    en_short_name: "Uganda",
    textDropDownEn: "Ugandan",
  },
  {
    id: "804",
    alpha_2_code: "UA",
    alpha_3_code: "UKR",
    en_short_name: "Ukraine",
    textDropDownEn: "Ukrainian",
  },
  {
    id: "784",
    alpha_2_code: "AE",
    alpha_3_code: "ARE",
    en_short_name: "United Arab Emirates",
    textDropDownEn: "Emirati, Emirian, Emiri",
  },
  {
    id: "826",
    alpha_2_code: "GB",
    alpha_3_code: "GBR",
    en_short_name: "United Kingdom of Great Britain and Northern Ireland",
    textDropDownEn: "British, UK",
  },
  {
    id: "581",
    alpha_2_code: "UM",
    alpha_3_code: "UMI",
    en_short_name: "United States Minor Outlying Islands",
    textDropDownEn: "American",
  },
  {
    id: "840",
    alpha_2_code: "US",
    alpha_3_code: "USA",
    en_short_name: "United States of America",
    textDropDownEn: "American",
  },
  {
    id: "858",
    alpha_2_code: "UY",
    alpha_3_code: "URY",
    en_short_name: "Uruguay",
    textDropDownEn: "Uruguayan",
  },
  {
    id: "860",
    alpha_2_code: "UZ",
    alpha_3_code: "UZB",
    en_short_name: "Uzbekistan",
    textDropDownEn: "Uzbekistani, Uzbek",
  },
  {
    id: "548",
    alpha_2_code: "VU",
    alpha_3_code: "VUT",
    en_short_name: "Vanuatu",
    textDropDownEn: "Ni-Vanuatu, Vanuatuan",
  },
  {
    id: "862",
    alpha_2_code: "VE",
    alpha_3_code: "VEN",
    en_short_name: "Venezuela (Bolivarian Republic of)",
    textDropDownEn: "Venezuelan",
  },
  {
    id: "704",
    alpha_2_code: "VN",
    alpha_3_code: "VNM",
    en_short_name: "Vietnam",
    textDropDownEn: "Vietnamese",
  },
  {
    id: "92",
    alpha_2_code: "VG",
    alpha_3_code: "VGB",
    en_short_name: "Virgin Islands (British)",
    textDropDownEn: "British Virgin Island",
  },
  {
    id: "850",
    alpha_2_code: "VI",
    alpha_3_code: "VIR",
    en_short_name: "Virgin Islands (U.S.)",
    textDropDownEn: "U.S. Virgin Island",
  },
  {
    id: "876",
    alpha_2_code: "WF",
    alpha_3_code: "WLF",
    en_short_name: "Wallis and Futuna",
    textDropDownEn: "Wallis and Futuna, Wallisian or Futunan",
  },
  {
    id: "732",
    alpha_2_code: "EH",
    alpha_3_code: "ESH",
    en_short_name: "Western Sahara",
    textDropDownEn: "Sahrawi, Sahrawian, Sahraouian",
  },
  {
    id: "887",
    alpha_2_code: "YE",
    alpha_3_code: "YEM",
    en_short_name: "Yemen",
    textDropDownEn: "Yemeni",
  },
  {
    id: "894",
    alpha_2_code: "ZM",
    alpha_3_code: "ZMB",
    en_short_name: "Zambia",
    textDropDownEn: "Zambian",
  },
  {
    id: "716",
    alpha_2_code: "ZW",
    alpha_3_code: "ZWE",
    en_short_name: "Zimbabwe",
    textDropDownEn: "Zimbabwean",
  },
];
