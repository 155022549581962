import React, { useState } from "react";
import connector from "../../images/Connector.png";
import DeleteButton from "../Buttons/DeleteButton";
import EditButton from "../Buttons/EditButton";
import DateAndTimeChip from "../Chips/DateAndTimeChip";
import { Box, Modal } from "@mui/material";
import EditHandoverNotes from "./EditHandoverNotes";
import DeleteNote from "./DeleteNote";
import TypeNoteChip from "../Chips/TypeNoteChip";
import ViewButton from "../Buttons/ViewButton";
import ViewHandoverNotes from "./ViewHandoverNotes";

function HandoverNoteContainer(props) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const openEditModal = () => {
    setShowEditModal(true);
  };
  const closeEditModal = () => {
    setShowEditModal(false);
  };

  const openViewModal = () => {
    setShowViewModal(true);
  };
  const closeViewModal = () => {
    setShowViewModal(false);
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const styleLive = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "80%",
    borderRadius: 5,
    backgroundColor: "white",
    boxShadow: 24,
  };

  const styleLive2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 750,
    height: 630,
    borderRadius: 5,
    backgroundColor: "white",
    boxShadow: 24,
  };

  const styleLive3 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "80%",
    borderRadius: 5,
    backgroundColor: "white",
    boxShadow: 24,
  };

  const styleDeleteModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    height: 260,
    borderRadius: 2,
    bgcolor: "background.paper",
    boxShadow: 24,
    // p: 4,
  };

  return (
    <div className="notes-wrapper">
      <Modal
        open={showEditModal}
        onClose={closeEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleLive}>
          <EditHandoverNotes
            note={props.noteContent}
            noteId={props.noteId}
            typeOfNote={props.noteType}
            effectiveDateTime={props.effectiveDateTime}
            closemodal={closeEditModal}
            {...props}
          />
        </Box>
      </Modal>
      <Modal
        open={showDeleteModal}
        onClose={closeDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleDeleteModal}>
          <DeleteNote
            note={props.noteContent}
            noteId={props.noteId}
            typeOfNote={props.noteType}
            effectiveDate={props.effectiveDate}
            closemodal={closeDeleteModal}
          />
        </Box>
      </Modal>

      <Modal
        open={showViewModal}
        onClose={closeViewModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleLive3}>
          <ViewHandoverNotes
            note={props.noteContent}
            noteId={props.noteId}
            typeOfNote={props.noteType}
            effectiveDateTime={props.effectiveDateTime}
            closemodal={closeViewModal}
            {...props}
          />
        </Box>
      </Modal>

      <div className="group-note-container">
        <div
          className="group-note-container-block-1"
          style={{ width: "100px", height: "auto" }}
        >
          <DateAndTimeChip date={props.noteDate} />
          <DateAndTimeChip date={props.noteTime} />
          <TypeNoteChip noteType={props.noteType} />
        </div>
        <div
          className="group-note-container-block-2"
          style={{ width: "45px", height: "auto" }}
        >
          <img src={connector} height="300" />
        </div>
        <div className="group-note-container-block-4">
          <div className="group-note-container-line-1">
            <div className="note-container-line1-block1bisDouble left-block">
              <div className="title-cell-container">Done by:</div>
              <div className="content-cell-container">{props.doneBy}</div>
            </div>
            <div className="note-container-line1-block2 left-block">
              <div className="title-cell-container">Record Timestamp:</div>
              <div className="content-cell-container">
                {props.timeStamp}
              </div>{" "}
            </div>
            <div className="note-container-line1-block2 centre-block2">
              <div className="sub-block-2">
                <EditButton handleOpen={openEditModal} />
                <ViewButton handleOpen={openViewModal} />
                <DeleteButton handleOpen={openDeleteModal} />
              </div>
            </div>
          </div>
          <div className="group-note-container-line-1">
            <div className="group-container-line1-block1 left-block">
              <div className="title-cell-container">Immigration Appt.:</div>
              <div className="content-cell-container">
                {" "}
                {props.immmigrationAppointment}
              </div>
            </div>
            <div className="group-container-line1-block1 left-block">
              <div className="title-cell-container">
                {" "}
                Discharges / Flights arrangements:
              </div>
              <div className="content-cell-container">
                {" "}
                {props.dischargesSwot}
              </div>
            </div>
            <div className="group-container-line1-block2 left-block">
              <div className="title-cell-container">New clients:</div>
              <div className="content-cell-container">{props.newClients}</div>
            </div>
          </div>

          <div className="group-note-container-line-1">
            <div className="handover-container-line1-block2 left-block">
              <div className="title-cell-container">
                Dr. Harshal Appointments:
              </div>
              <div className="content-cell-container">{props.medicalInfos}</div>
            </div>
            <div className="handover-container-line1-block2 left-block">
              <div className="title-cell-container">
                (Hospital) appointments:
              </div>
              <div className="content-cell-container">
                {props.hospitalAppointment}
              </div>
            </div>
            <div className="handover-container-line1-block2 left-block">
              <div className="title-cell-container2">Outings:</div>
              <div className="content-cell-container">
                {props.residentialClientMovements}
              </div>
            </div>
            <div className="handover-container-line1-block2 left-block">
              <div className="title-cell-container">
                Zoom session assistance:
              </div>
              <div className="content-cell-container">
                {props.zoomSessionAssistance}
              </div>
            </div>
          </div>

          <div className="group-note-container-line-1">
            <div className="group-container-line1-block1 left-block">
              <div className="title-cell-container">Overnight:</div>
              <div className="content-cell-container"> {props.overnight}</div>
            </div>
            <div className="group-container-line1-block1 left-block">
              <div className="title-cell-container"> Number of clients:</div>
              <div className="content-cell-container">
                {" "}
                {props.numberOfClient}
              </div>
            </div>
            <div className="group-container-line1-block2 left-block">
              <div className="title-cell-container">Swot:</div>
              <div className="content-cell-container">{props.swot}</div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              maxHeight: "100%",
              width: "100%",
              height: "100%",
            }}
          >
            <div className="group-note-container-line-50">
              <div className="title-cell-container">
                General Announcement / Discussion
              </div>
              <div
                style={{
                  maxHeight: "50px",
                  overflowY: "auto",
                }}
              >
                {props.generalAnnouncement}
              </div>
            </div>
            <div className="group-note-container-line-50">
              <div className="title-cell-container">Handover Note</div>
              <div
                style={{
                  maxHeight: "50px",
                  overflowY: "auto",
                }}
              >
                {props.noteContent}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HandoverNoteContainer;
