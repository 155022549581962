import React from "react";
import "../css/dashboard.css";
import { useState } from "react";
import { useEffect } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { CircularProgress } from "@mui/material";
import TopTitleBannerMobile from "../component/TopTitleBannerMobile";
import LineSwotMobile from "../component/Lines/LineSwotMobile";

export default function DashboardContentMobile() {
  const [clientData, setClientData] = useState(null);

  useEffect(() => {
    const clientsCollectionRef = collection(db, "clients");
    const unsubscribe = onSnapshot(clientsCollectionRef, (querySnapshot) => {
      const clients = [];
      querySnapshot.forEach((doc) => {
        clients.push({ id: doc.id, ...doc.data() });
      });
      setClientData(clients);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="right-container-mobile">
      <TopTitleBannerMobile title={"Swot schedule & discharge"} />{" "}
      <div className="swot-container">
        <div className="titles-swot-table">
          <div className="text-title-swot-table-mobile title7-mobile-swot">
            <div className="title-2items">Client name</div>
          </div>
          <div className="text-title-swot-table-mobile title8-mobile-swot">
            <div className="title-2items"> Discharge date</div>
          </div>
          <div className="text-title-swot-table-mobile title9-mobile-swot">
            {" "}
            <div className="title-2items"> Swot</div>
          </div>
        </div>

        {clientData ? (
          clientData
            .filter((client) => client.dateOfAdmission)
            .map((client, index) => (
              <LineSwotMobile
                key={client.id}
                clientId={client.id}
                background={index % 2 === 0 ? "white" : "grey"}
                avatar={client.profilePicture || "1"}
                dos={client.durationOfStay || ""}
                swotChecked={client.swotDone || ""}
                clientName={client.clientName || "------"}
                firstName={client.firstName || "------"}
                lastName={client.lastName || "------"}
                avatarStaff={client.avatarStaff || "Augustine"}
                nameStaff={client.aftercareStaff1 || "------"}
                nameCTS={client.aftercareStaff2 || "------"}
                avatarCTS={client.avatarCTS || "Paula"}
                mAtHome={client.miraclesAtHome || ""}
                admissionDate={client.dateOfAdmission}
                dischargeDate={client.idd}
                nameRecoveryCoach={client.nameRecoveryCoach || ""}
                dateSwot={client.swot || ""}
                flightDate={client.flight}
                swotDone={
                  client.swotDone === "undefined" ? false : client.swotDone
                }
              />
            ))
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
}
