import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";

export default function MainAddictionRibonMobileV2(props) {
  const { translate } = useContext(LanguageContext);
  return (
    <div
      className="ribon-primary-addiction-mobile-v2"
      onClick={props.onClick}
      style={{ cursor: "pointer" }}
    >
      {props.mainAddiction
        ? props.mainAddiction === "Other"
          ? translate("PrimaryAddiction") + props.addictionOther
          : translate("PrimaryAddictionColon") + props.mainAddiction
        : translate("NoPrimaryAddictionRecorded")}
    </div>
  );
}
