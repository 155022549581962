import React, { useContext, useState } from "react";
import { Button, Checkbox } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import TopTitleFormComponent from "./TopTitleFormComponent";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadString,
} from "firebase/storage";
import {
  updateDoc,
  doc as firestoreDoc,
  collection,
  serverTimestamp,
  setDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { showCustomToast } from "../Misc/CustomToast";
import SignaturePadComponentV2 from "../Signature/SignaturePadComponentV2";
import DatePickerComponentV3 from "../DatePickerComponentV3";
import { LanguageContext } from "../../context/LanguageContext";

function AddAcaForm(props) {
  const { translate } = useContext(LanguageContext);
  const [clientIdState, setClientIdState] = useState(props.clientId);
  const [isLoading, setIsLoading] = useState(false);
  const [consentCheckbox, setConsentCheckbox] = useState(false);
  const [nonRefundableCheckbox, setNonRefundableCheckbox] = useState(false);

  const [signature, setSignature] = useState(null);
  const [date1, setDate1] = useState(null);
  const [date2, setDate2] = useState(null);
  const [date3, setDate3] = useState(null);
  const [date4, setDate4] = useState(null);
  const [signatureDataURL, setSignatureDataURL] = useState(null);
  const [isSignatureStarted, setIsSignatureStarted] = useState(false);
  const [isSignatureValidated, setIsSignatureValidated] = useState(false);
  const [text1, setText1] = useState(
    "This agreement releases our rehab (Thailand) Ltd from all duty of care responsibilities and liability for myself once I discharge from the treatment program. "
  );
  const [text2, setText2] = useState(
    "I also acknowledge that my Treatment Program with our rehab commenced on the above stated admission date, and ceased against the professional and clinical advice of the our rehab Clinical Director with my rehabilitation treatment discharge effective on the date below. I agree that my discharge is voluntary, against medical advice and initiated by myself without coercion from any other party."
  );

  const [text2A, setText2A] = useState("I am aware that leaving treatment on:");

  const [text2B, setText2B] = useState(
    " than I originally planned discharging from our rehab on:"
  );
  const [text2C, setText2C] = useState(
    "is against clinical advice. I understand that our rehab’s clinical recommendation to me is to remain in residential treatment until :"
  );

  const [text2D, setText2D] = useState(
    " I further acknowledge that the decision to leave residential treatment this date,"
  );

  const [text2E, setText2E] = useState(
    " was made on my own volition and is not supported by our rehab’s Clinical Team.  "
  );

  const [text3, setText3] = useState(
    "I understand that if I choose to leave our rehab against clinical advice prior to my originally planned discharge date, I will not be eligible for a full refund or partial refund of treatment paid."
  );

  const handleDate1Change = (date) => {
    setDate1(date);
  };

  const handleDate2Change = (date) => {
    setDate2(date);
  };

  const handleDate3Change = (date) => {
    setDate3(date);
  };

  const handleDate4Change = (date) => {
    setDate4(date);
  };

  const handleConsentCheckboxChange = (event) => {
    setConsentCheckbox(event.target.checked);
  };

  const handleNonRefundableCheckboxChange = (event) => {
    setNonRefundableCheckbox(event.target.checked);
  };

  const handleSignaturePad = (signatureDataUrl) => {
    setSignature(signatureDataUrl);
  };

  const handleSignatureSave = (dataURL) => {
    setSignatureDataURL(dataURL);
  };

  const uploadSignature = async () => {
    const storage = getStorage();
    const fileName = `${clientIdState}.png`;
    const signatureRef = ref(storage, `AcaReport/${fileName}`);

    await uploadString(signatureRef, signatureDataURL, "data_url");
    const downloadURL = await getDownloadURL(signatureRef);

    return downloadURL;
  };

  const handleFormSubmit = async () => {
    if (
      consentCheckbox &&
      nonRefundableCheckbox &&
      date1 &&
      date2 &&
      date3 &&
      date4 &&
      signatureDataURL &&
      isSignatureValidated
    ) {
      setIsLoading(true);

      try {
        const signatureURL = await uploadSignature();

        const acaData = {
          consentCheckbox,
          nonRefundableCheckbox,
          date1,
          date2,
          date3,
          date4,
          text1,
          text2A,
          text2B,
          text2C,
          text2D,
          text2E,
          text3,
          signatureData: signatureURL,
          signedOn: serverTimestamp(),
        };

        const acaReportRef = collection(db, "acaReport");
        const acaReportDocRef = doc(acaReportRef, clientIdState);

        await setDoc(acaReportDocRef, acaData);

        const clientDocRef = firestoreDoc(db, "clients", clientIdState);
        await updateDoc(clientDocRef, {
          acaReportOk: true,
          acaReportDateSigned: serverTimestamp(),
        });
        showCustomToast("ACA form correctly submitted!", "success");

        setIsLoading(false);
        props.closemodal();
      } catch (error) {
        showCustomToast("Error submitting form", "error");
        console.error("Error submitting form:", error);
        setIsLoading(false);
      }
    } else {
      showCustomToast(
        "Some mandatory fields are missing, please check",
        "error"
      );
      console.error("All required fields must be filled out.");
    }
  };

  const allChecked =
    consentCheckbox &&
    nonRefundableCheckbox &&
    date1 &&
    date2 &&
    date3 &&
    date4;

  return (
    <>
      <div
        // className="inputAndLabelModalContainerTitle2"
        style={{ width: "auto", height: "100%" }}
      >
        <div className="line1-modal-percent">
          <TopTitleFormComponent
            title={translate("LeaveAgainstClinicalAdvice")}
            closemodal={props.closemodal}
          />
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "3px",
            height: "80%",
            overflowY: "scroll",
            // overflowX: "hidden",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <div className="tab-form-container">
            <div className="checkbox-container-margin">
              <Checkbox
                checked={consentCheckbox}
                onChange={handleConsentCheckboxChange}
              />
              <div className="block-text-check">{text1}</div>
            </div>
          </div>
          <div
            className="tab-form-container"
            style={{ flexDirection: "column" }}
          >
            <div
              style={{
                marginLeft: 20,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignContent: "center",
                alignItems: "center",
                paddingRight: "20px",
              }}
            >
              <div
                className="block-text-check"
                style={{
                  textAlign: "right",
                  width: "100%",
                  paddingRight: "10px",
                }}
              >
                {text2A}
              </div>
              <div style={{ width: 200 }}>
                <DatePickerComponentV3
                  // title="Date of client’s admission"
                  width="150px"
                  // isRequired={true}
                  value={date1}
                  onChange={handleDate1Change}
                />
              </div>
            </div>
            <div
              style={{
                marginLeft: 20,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="block-text-check"
                style={{ textAlign: "right", paddingRight: "10px" }}
              >
                {text2B}
              </div>
              <div style={{ width: 200 }}>
                <DatePickerComponentV3
                  // title="Date of client’s admission"
                  width="150px"
                  // isRequired={true}
                  value={date2}
                  onChange={handleDate2Change}
                />
              </div>
            </div>
            <div
              style={{
                marginLeft: 20,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignContent: "center",
                alignItems: "center",
                paddingRight: "20px",
              }}
            >
              <div
                className="block-text-check"
                style={{ textAlign: "right", paddingRight: "10px" }}
              >
                {text2C}
              </div>
              <div style={{ width: 200 }}>
                <DatePickerComponentV3
                  // title="Date of client’s admission"
                  width="150px"
                  value={date3}
                  onChange={handleDate3Change}
                />
              </div>
            </div>
            <div
              style={{
                marginLeft: 20,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignContent: "center",
                alignItems: "center",
                paddingRight: "20px",
              }}
            >
              <div
                className="block-text-check"
                style={{ textAlign: "right", paddingRight: "10px" }}
              >
                {text2D}
              </div>
              <div style={{ width: 200 }}>
                <DatePickerComponentV3
                  // title="Date of client’s admission"
                  width="150px"
                  value={date4}
                  onChange={handleDate4Change}
                />
              </div>
            </div>
            <div style={{ marginLeft: 20, flexDirection: "column" }}>
              <div className="block-text-check">{text2E}</div>
            </div>
          </div>

          <div className="tab-form-container">
            {/* <div className="SubTitleBold">Non-Refundable Payment</div> */}
            <div className="checkbox-container-margin">
              <Checkbox
                checked={nonRefundableCheckbox}
                onChange={handleNonRefundableCheckboxChange}
              />
              <div className="block-text-check">{text3}</div>
            </div>
          </div>

          <div className="tab-form-container">
            <div style={{ width: "100%", marginBottom: "25px" }}>
              <SignaturePadComponentV2
                onSignatureSave={handleSignatureSave}
                signatureDataURL={signatureDataURL}
                onSignatureStart={() => setIsSignatureStarted(true)}
                onSignatureValidate={setIsSignatureValidated}
              />
            </div>
          </div>
        </div>
        <div className="line4-modal-percent-bis">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              onClick={props.closemodal}
            >
              {translate("Cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={handleFormSubmit}
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : !signatureDataURL || !isSignatureValidated || !allChecked
                  ? {
                      backgroundColor: "grey",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                    }
              }
              disabled={
                isLoading ||
                !signatureDataURL ||
                !allChecked ||
                !isSignatureValidated
              }
            >
              {isLoading ? translate("Adding") : translate("Add")}
            </Button>
          </>
        </div>
      </div>
    </>
  );
}

export default AddAcaForm;
