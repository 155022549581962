export default [
  {
    id: 1,
    textDropDownEn: "-- Choose --",
  },

  {
    id: 2,
    textDropDownEn: "Alcohol",
  },

  {
    id: 3,
    textDropDownEn: "Adderall",
  },

  {
    id: 4,
    textDropDownEn: "Benzodiazepines",
  },

  {
    id: 5,
    textDropDownEn: "Cocaine",
  },

  {
    id: 6,
    textDropDownEn: "Canabis",
  },

  {
    id: 7,
    textDropDownEn: "Opioids",
  },

  {
    id: 8,
    textDropDownEn: "Methamphetamine",
  },

  {
    id: 9,
    textDropDownEn: "Sex",
  },

  {
    id: 10,
    textDropDownEn: "Gambling",
  },

  {
    id: 11,
    textDropDownEn: "Video, internet, gaming, cyber",
  },

  {
    id: 12,
    textDropDownEn: "Love/Relationship",
  },

  {
    id: 13,
    textDropDownEn: "Ketamine",
  },

  {
    id: 14,
    textDropDownEn: "Heroin",
  },

  {
    id: 15,
    textDropDownEn: "Fetanyl",
  },

  {
    id: 16,
    textDropDownEn: "Other",
  },
];
