import React from "react";
import CheckGreen from "../images/check-icon.png";
import CancelIcon from "../images/cancel-icon.png";
import incomingIcon from "../images/incoming-icon.png";

export default function CheckWithDateMobileV3(props) {
  return (
    <div
      className="container-check-with-name"
      title={`Revoked by: ${props.title}`}
      style={{ cursor: "pointer", width: "auto" }}
    >
      {props.checked == true ? (
        <img
          style={{
            width: "24px",
            height: "24px",
          }}
          src={CheckGreen}
        />
      ) : props.checked == false ? (
        <img
          style={{
            width: "24px",
            height: "24px",
          }}
          src={CancelIcon}
        />
      ) : (
        <img
          style={{
            width: "24px",
            height: "24px",
          }}
          src={incomingIcon}
        />
      )}

      {/* <div className="name-for-check" style={{ fontSize: "12px" }}>
        {props.content}{" "}
      </div> */}
    </div>
  );
}
