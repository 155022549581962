import React, { useContext, useState } from "react";
import { Button, Checkbox } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import TopTitleFormComponent from "./TopTitleFormComponent";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadString,
} from "firebase/storage";
import { doc, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import { doc as firestoreDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { showCustomToast } from "../Misc/CustomToast";
import SignaturePadComponentV2 from "../Signature/SignaturePadComponentV2";
import { LanguageContext } from "../../context/LanguageContext";
import TopTitleFormComponentMobileV2 from "./TopTitleFormComponentMobileV2";
import SignaturePadComponentV2Mobile from "../Signature/SignaturePadComponentV2Mobile";

function AddInformedConsentFormMobileV2(props) {
  const { translate } = useContext(LanguageContext);

  const { clientId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [signatureDataURL, setSignatureDataURL] = useState(null);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checked8, setChecked8] = useState(false);
  const [checked9, setChecked9] = useState(false);
  const [checked10, setChecked10] = useState(false);
  const [checked11, setChecked11] = useState(false);
  const [checked11bis, setChecked11bis] = useState(false);
  const [checked12, setChecked12] = useState(false);
  const [checked13, setChecked13] = useState(false);
  const [checked14, setChecked14] = useState(false);
  const [checked15, setChecked15] = useState(false);
  const [checked16, setChecked16] = useState(false);
  const [checked17, setChecked17] = useState(false);
  const [checked18, setChecked18] = useState(false);
  const [checked19, setChecked19] = useState(false);
  const [checked20, setChecked20] = useState(false);
  const [checked21, setChecked21] = useState(false);
  const [checked22, setChecked22] = useState(false);
  const [checked22bis, setChecked22bis] = useState(false);
  const [checked24, setChecked24] = useState(false);
  const [checked26, setChecked26] = useState(false);
  const [isSignatureValidated, setIsSignatureValidated] = useState(false);
  const text1 =
    "Our rehab is a therapeutic treatment facility. We are not a hospital, clinic or provider of specialized medical (physical) care. While we provide nursing care for medical emergencies and supervision of medications as approved by licensed physicians, we refer to one of our outside, licensed medical partners for some issues, sometimes at client’s expense.";
  const text2 =
    "Our rehab is not a “security” or locked facility but our program requires all clients remain on premises unless escorted by staff for an approved activity. Clients leaving our rehab grounds without permission may be considered having voluntarily discharged and may not be allowed to return or remain in the program, at your own expense.";
  const text3 =
    "I understand that if I choose to leave the our rehab facility without express permission or if I Leave Against Clinical Advice (ACA), I will not be eligible for a full refund or partial refund of treatment paid.";
  const text4 =
    "I understand that all medication (both prescription and non-prescription) will be taken upon admission for recording in a medication log and safely and securely stored (if appropriate prescription medication) and dispensed under supervision by the nurse/doctor/authorized staff. If the prescription or non-prescription medication is assessed to be inappropriate/harmful to recovery by our rehab’s authorized doctor/psychiatrist it will be immediately destroyed.";
  const text5 =
    "I understand that I will submit my passport and arrival card to our rehab staff on intake. This will be safely retained for Visa monitoring and Immigration purposes.";
  const text6 =
    "I must attend all therapeutic and clinical activities/meetings unless I am ill or given permission by my Primary Therapist, or the Managing Director, or the Clinical Director to not attend.";
  const text7 =
    "I will do my best to follow all directives and advice/suggestions offered by staff. We offer these in the best interests of you.";
  const text8 =
    "I agree to keep in confidence all information related to the treatment process and client shares and client information. I agree to NOT take pictures, film, or record other clients (without their permission). Confidentiality and safety are a fundamental part of treatment.";
  const text9 =
    "For safety and respect of all clients, other clients or unauthorized staff or visitors will not be allowed in clients rooms for any reason. In the same sense, any intimate physical relationships are prohibited.";
  const text10 =
    "No loaning, borrowing or trading of monetary or material possessions between clients is permitted. Gambling is prohibited at all times.";
  const text11 =
    "Physical, verbal or emotional harm or intimidation of staff or other clients will not be tolerated.";
  const text11bis =
    "Damage to our rehab property may result in discharge and possible criminal charges, as well as the client being required to pay for damage to property.";
  const text12 =
    "Clients will be always considerate and respectful of both other residents and staff. Any form of violent or threatening behavior will result in immediate exclusion from the program without refund.";
  const text13 =
    "All clients’ personal possessions must be searched for contraband (drugs, alcohol or other restricted chemical products, weapons, pornography, sexual paraphernalia, inappropriate or offensive, clothing and items restricted in Thailand by law). In the event that an item is overlooked in the search, a client may turn it in to staff when it is discovered without consequence. If a restricted item is later discovered by staff in a client’s possession, disciplinary actions up to and including discharge may result.";
  const text14 =
    "Our rehab has a strict zero policy rule surrounding drugs and alcohol. No alcoholic beverages or drugs of any kind are allowed on the premises. The rule does not apply to medication prescribed for medical illnesses and administered by our rehab clinical staff.";
  const text15 =
    "Clients who are found to be under the influence of any substance will be put under review for potential exclusion from the program if deemed necessary.";
  const text16 =
    "Our rehab does not allow clients to engage in certain activities on the internet or via phone for the duration of their treatment stay. These activities include: Pornography, Gambling, Dating Websites and Apps, Online Shopping.";
  const text17 =
    "Please do not smoke in the dining area or inside any of the rooms.";
  const text18 =
    "Any outside orders are forbidden (food, beverages, snacks, etc.). Please communicate with staff members if you need to order something and seek Primary Therapist’s, Clinical Director's,or our rehab owner's approval.";
  const text19 =
    "Your electronic devices, including your phone, MUST remain in your room.  Any breach of this rule will result in: 1st a verbal warning, and 2nd your device(s) being confiscated. (Note: You can bring your phone: (1) for shopping if needed and (2) to Thanyapura to use for music in the gym; however, your phone MUST not be used during the van ride.";
  const text20 =
    "Upon admission you can elect to place valuables in the our rehab safe and you will be given a receipt. Access to these items may be facilitated through staff.";
  const text21 =
    "If at any time you feel you have issues that need to be addressed, you are encouraged to speak to staff.";
  const text22 =
    "I have listed my emergency notification person on the Client Information Sheet and do authorize our rehab to contact the party listed in the event of an emergency.";
  const text22bis =
    "Romantic/Sexual/Exclusive relationships with other clients or staff are prohibited.";
  const text24 =
    "I understand that failure to submit to urinalysis may result in my termination from the program without refund.";
  const text26 =
    " I understand the above and agree to comply with these ground rules during my time at our rehab.";
  const [, setIsSignatureStarted] = useState(false);

  const handleSignatureSave = (dataURL) => {
    setSignatureDataURL(dataURL);
  };

  const handleCheckboxChange1 = (event) => {
    setChecked1(event.target.checked);
  };

  const handleCheckboxChange2 = (event) => {
    setChecked2(event.target.checked);
  };

  const handleCheckboxChange3 = (event) => {
    setChecked3(event.target.checked);
  };

  const handleCheckboxChange4 = (event) => {
    setChecked4(event.target.checked);
  };

  const handleCheckboxChange5 = (event) => {
    setChecked5(event.target.checked);
  };

  const handleCheckboxChange6 = (event) => {
    setChecked6(event.target.checked);
  };

  const handleCheckboxChange7 = (event) => {
    setChecked7(event.target.checked);
  };

  const handleCheckboxChange8 = (event) => {
    setChecked8(event.target.checked);
  };

  const handleCheckboxChange9 = (event) => {
    setChecked9(event.target.checked);
  };

  const handleCheckboxChange10 = (event) => {
    setChecked10(event.target.checked);
  };

  const handleCheckboxChange11 = (event) => {
    setChecked11(event.target.checked);
  };

  const handleCheckboxChange11bis = (event) => {
    setChecked11bis(event.target.checked);
  };

  const handleCheckboxChange12 = (event) => {
    setChecked12(event.target.checked);
  };

  const handleCheckboxChange13 = (event) => {
    setChecked13(event.target.checked);
  };

  const handleCheckboxChange14 = (event) => {
    setChecked14(event.target.checked);
  };

  const handleCheckboxChange15 = (event) => {
    setChecked15(event.target.checked);
  };

  const handleCheckboxChange16 = (event) => {
    setChecked16(event.target.checked);
  };

  const handleCheckboxChange17 = (event) => {
    setChecked17(event.target.checked);
  };

  const handleCheckboxChange18 = (event) => {
    setChecked18(event.target.checked);
  };

  const handleCheckboxChange19 = (event) => {
    setChecked19(event.target.checked);
  };

  const handleCheckboxChange20 = (event) => {
    setChecked20(event.target.checked);
  };

  const handleCheckboxChange21 = (event) => {
    setChecked21(event.target.checked);
  };

  const handleCheckboxChange22 = (event) => {
    setChecked22(event.target.checked);
  };

  const handleCheckboxChange22Bis = (event) => {
    setChecked22bis(event.target.checked);
  };

  const handleCheckboxChange24 = (event) => {
    setChecked24(event.target.checked);
  };

  const handleCheckboxChange26 = (event) => {
    setChecked26(event.target.checked);
  };

  const uploadSignature = async () => {
    const storage = getStorage();
    const fileName = `${clientId}.png`;
    const signatureRef = ref(
      storage,
      `MandatoryForms/InformedConsent/${fileName}`
    ); // Modifiez cette ligne

    await uploadString(signatureRef, signatureDataURL, "data_url");
    const downloadURL = await getDownloadURL(signatureRef);

    return downloadURL;
  };

  const handleFormSubmit = async () => {
    if (
      !checked1 ||
      !checked2 ||
      !checked3 ||
      !checked4 ||
      !checked5 ||
      !checked6 ||
      !checked7 ||
      !checked8 ||
      !checked9 ||
      !checked10 ||
      !checked11 ||
      !checked11bis ||
      !checked12 ||
      !checked13 ||
      !checked14 ||
      !checked15 ||
      !checked16 ||
      !checked17 ||
      !checked18 ||
      !checked19 ||
      !checked20 ||
      !checked21 ||
      !checked22 ||
      !checked24 ||
      !checked26 ||
      !checked22bis ||
      !signatureDataURL ||
      !isSignatureValidated
    ) {
      showCustomToast(
        "Please agree to all the terms before submitting.",
        "error"
      );
    }

    try {
      setIsLoading(true);
      const signatureURL = await uploadSignature();
      const docRef = doc(db, "InformedConsent", clientId);
      await setDoc(docRef, {
        checked1,
        checked2,
        checked3,
        checked4,
        checked5,
        checked6,
        checked7,
        checked8,
        checked9,
        checked10,
        checked11,
        checked11bis,
        checked12,
        checked13,
        checked14,
        checked15,
        checked16,
        checked17,
        checked18,
        checked19,
        checked20,
        checked21,
        checked22,
        checked24,
        checked22bis,

        checked26,
        text1,
        text2,
        text3,
        text4,
        text5,
        text6,
        text7,
        text8,
        text9,
        text10,
        text11,
        text11bis,
        text12,
        text13,
        text14,
        text15,
        text16,
        text17,
        text18,
        text19,
        text20,
        text21,
        text22,
        text22bis,
        text24,
        text26,
        signatureURL,
        signedOn: serverTimestamp(),
      });

      const clientDocRef = firestoreDoc(db, "clients", clientId);
      await updateDoc(clientDocRef, {
        InformedConsentOk: true,
        InformedConsentDateSigned: serverTimestamp(),
      });
      showCustomToast(translate("InformedConsentFormSubmitted"), "success");

      setIsLoading(false);
      props.closemodal();
    } catch (e) {
      showCustomToast(translate("ErrorAddingDocument"), "error");

      console.error("Error adding document: ", e);
    }
  };

  const allChecked =
    checked1 &&
    checked2 &&
    checked3 &&
    checked4 &&
    checked5 &&
    checked6 &&
    checked7 &&
    checked8 &&
    checked9 &&
    checked10 &&
    checked11 &&
    checked11bis &&
    checked12 &&
    checked13 &&
    checked14 &&
    checked15 &&
    checked16 &&
    checked17 &&
    checked18 &&
    checked19 &&
    checked20 &&
    checked21 &&
    checked22 &&
    checked22bis &&
    checked24 &&
    checked26;

  return (
    <>
      <div
        // className="inputAndLabelModalContainerTitle2"
        style={{
          width: "auto",
          height: "100%",
          borderTopLeftRadius: "19px",
        }}
      >
        <div
          className="line1-modal-percent"
          style={{ borderBottom: "1px solid lightgray" }}
        >
          <TopTitleFormComponentMobileV2
            title={translate("InformedConsentForm")}
            closemodal={props.closemodal}
          />
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "10px",
            height: "85%",
            overflowY: "scroll",
            paddingLeft: "15px",
            paddingRight: "15px",
            // overflowX: "hidden",
          }}
        >
          <div className="SubTitleBold" style={{ fontSize: "14px" }}>
            By signing this document you acknowledge that you understand and
            agree to the following points:
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked1} onChange={handleCheckboxChange1} />
            <div className="block-text-check">{text1}</div>
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked2} onChange={handleCheckboxChange2} />
            <div className="block-text-check">{text2}</div>
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked3} onChange={handleCheckboxChange3} />
            <div className="block-text-check">{text3}</div>
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked4} onChange={handleCheckboxChange4} />
            <div className="block-text-check">{text4}</div>
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked5} onChange={handleCheckboxChange5} />
            <div className="block-text-check">{text5}</div>
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked6} onChange={handleCheckboxChange6} />
            <div className="block-text-check">{text6}</div>
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked7} onChange={handleCheckboxChange7} />
            <div className="block-text-check">{text7}</div>
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked8} onChange={handleCheckboxChange8} />
            <div className="block-text-check">{text8}</div>
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked9} onChange={handleCheckboxChange9} />
            <div className="block-text-check">{text9} </div>
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked10} onChange={handleCheckboxChange10} />
            <div className="block-text-check">{text10}</div>
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked11} onChange={handleCheckboxChange11} />
            <div className="block-text-check">{text11}</div>
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox
              checked={checked11bis}
              onChange={handleCheckboxChange11bis}
            />
            <div className="block-text-check">{text11bis}</div>
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked12} onChange={handleCheckboxChange12} />
            <div className="block-text-check">{text12}</div>
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked13} onChange={handleCheckboxChange13} />
            <div className="block-text-check">{text13}</div>
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked14} onChange={handleCheckboxChange14} />
            <div className="block-text-check">{text14}</div>
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked15} onChange={handleCheckboxChange15} />
            <div className="block-text-check">{text15}</div>
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked16} onChange={handleCheckboxChange16} />
            <div className="block-text-check">{text16}</div>
          </div>
          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked17} onChange={handleCheckboxChange17} />
            <div className="block-text-check">{text17}</div>
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked18} onChange={handleCheckboxChange18} />
            <div className="block-text-check">{text18}</div>
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked19} onChange={handleCheckboxChange19} />
            <div className="block-text-check">{text19}</div>
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked20} onChange={handleCheckboxChange20} />
            <div className="block-text-check">{text20}</div>
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked21} onChange={handleCheckboxChange21} />
            <div className="block-text-check">{text21}</div>
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked22} onChange={handleCheckboxChange22} />
            <div className="block-text-check">{text22}</div>
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox
              checked={checked22bis}
              onChange={handleCheckboxChange22Bis}
            />
            <div className="block-text-check">{text22bis}</div>
          </div>

          <div className="SubTitleBold" style={{ fontSize: "14px" }}>
            Urinalysis
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked24} onChange={handleCheckboxChange24} />
            <div className="block-text-check">{text24}</div>
          </div>

          <div className="SubTitleBold" style={{ fontSize: "14px" }}>
            Acknowledgement
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked26} onChange={handleCheckboxChange26} />
            <div className="block-text-check">{text26}</div>
          </div>

          <div style={{ width: "100%", marginBottom: "25px" }}>
            <SignaturePadComponentV2Mobile
              onSignatureSave={handleSignatureSave}
              signatureDataURL={signatureDataURL}
              onSignatureStart={() => setIsSignatureStarted(true)}
              onSignatureValidate={setIsSignatureValidated}
            />
          </div>
        </div>
        <div className="line4-modal-percent-bis">
          <div
            style={{
              marginBottom: "0px",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingRight: "0px",
              width: "100%",
              paddingTop: "10px",
            }}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: "white",
                color: "black",
                fontSize: "12px",
              }}
              // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
              onClick={props.closemodal}
            >
              {translate("Cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={handleFormSubmit}
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                      fontSize: "12px",
                    }
                  : !signatureDataURL || !isSignatureValidated || !allChecked
                  ? {
                      backgroundColor: "grey",
                      color: "white",
                      marginLeft: "15px",
                      fontSize: "12px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                      fontSize: "12px",
                    }
              }
              disabled={
                isLoading ||
                !signatureDataURL ||
                !allChecked ||
                !isSignatureValidated
              }
            >
              {isLoading ? translate("Saving") : translate("Save")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddInformedConsentFormMobileV2;
