import React, { useEffect } from "react";
import "../css/dashboard.css";
import { useAuth } from "../contexts/AuthContext";
import CasadaSwitch from "../component/Misc/CasadaSwitch";

export default function RoomLayoutContent() {
  const { currentUser } = useAuth();

  return (
    <div className="right-container">
      <CasadaSwitch title={"Room Layout"} />{" "}
      {/* Utilisation du nouveau composant */}
    </div>
  );
}
