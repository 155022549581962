export default [
  {
    id: 1,
    textDropDownEn: "-- Choose --",
  },
  {
    id: 2,
    textDropDownEn: "Contact Form",
  },
  {
    id: 3,
    textDropDownEn: "Facebook Chat",
  },
  {
    id: 4,
    textDropDownEn: "Google My Business Chat",
  },

  {
    id: 5,
    textDropDownEn: "Instagram Chat",
  },
  {
    id: 6,
    textDropDownEn: "Paid Ads (Bing)",
  },
  {
    id: 7,
    textDropDownEn: "Paid Ads (Google)",
  },

  {
    id: 8,
    textDropDownEn: "Phone Call",
  },
  {
    id: 9,
    textDropDownEn: "Referral",
  },

  {
    id: 10,
    textDropDownEn: "Website Chat",
  },
  {
    id: 11,
    textDropDownEn: "WhatsApp",
  },
];
