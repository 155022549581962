export default [
  {
    id: 1,
    textDropDownEn: "--Choose--",
  },

  {
    id: 2,
    textDropDownEn: "Completed",
  },

  {
    id: 3,
    textDropDownEn: "AMA",
  },

  {
    id: 3,
    textDropDownEn: "Asked to leave",
  },
];
