import { TextField } from "@mui/material";
import React from "react";

export default function TextFieldComponentV3(props) {
  const handleChange = (event) => {
    props.onChange(event.target.value);
  };
  return (
    <div
    // className="input-label-container"
    >
      <div
        style={{
          color: "#a0a0a0",
          fontWeight: "500",
          fontSize: "12px",
          textTransform: "uppercase",
        }}
      >
        {props.title}
      </div>
      <TextField
        //   value={takeProfitState}
        //   onChange={(e) => setTakeProfitState(e.target.value)}
        style={{
          width: props.width ? props.width : "auto",
          background: "#f0f0f0",
          marginTop: 5,
        }}
        multiline
        rows={7}
        value={props.value}
        onChange={handleChange}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none", // Supprime la bordure du TextField
          },
          // "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
          //   border: "none", // Supprime la bordure lorsque le champ est focalisé
          // },
        }}
      />
    </div>
  );
}
