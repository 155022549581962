import React from "react";
import "../css/dashboard.css";
import TopTitleBannerMobile from "../component/TopTitleBannerMobile";
import CollapseComponentMobile from "../component/Misc/CollapseComponentMobile";

export default function DocumentsContentMobile() {
  return (
    <div className="right-container-mobile">
      <TopTitleBannerMobile title={"Documents"} />
      <CollapseComponentMobile />
    </div>
  );
}
