export default [
  {
    id: 1,
    textDropDownEn: "--Choose--",
  },

  {
    id: 2,
    textDropDownEn: "Male",
  },

  {
    id: 3,
    textDropDownEn: "Female",
  },

  {
    id: 4,
    textDropDownEn: "Other",
  },
];
