import React, { useState } from "react";
import connector from "../../images/Connector.png";
import DeleteButton from "../Buttons/DeleteButton";
import EditButton from "../Buttons/EditButton";
import DateAndTimeChip from "../Chips/DateAndTimeChip";
import { Box, Modal } from "@mui/material";
import EditGroupNote from "./EditGroupNotes";
import DeleteNote from "./DeleteNote";
import TypeNoteChip from "../Chips/TypeNoteChip";
import ViewButton from "../Buttons/ViewButton";
import ViewGroupNotes from "./ViewGroupNotes";

function GroupNoteContainer(props) {
  const [showViewModal, setShowViewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const openViewModal = () => {
    setShowViewModal(true);
  };
  const closeViewModal = () => {
    setShowViewModal(false);
  };

  const openEditModal = () => {
    setShowEditModal(true);
  };
  const closeEditModal = () => {
    setShowEditModal(false);
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const styleLive = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 750,
    height: "80%",
    borderRadius: 5,
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const styleDeleteModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    height: 260,
    borderRadius: 2,
    bgcolor: "background.paper",
    /*border: "1px solid #000",*/
    boxShadow: 24,
    // p: 4,
  };

  return (
    <div className="notes-wrapper">
      <Modal
        open={showEditModal}
        onClose={closeEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleLive}>
          <EditGroupNote
            note={props.noteContent}
            noteId={props.noteId}
            typeOfNote={props.noteType}
            effectiveDateTime={props.effectiveDateTime}
            closemodal={closeEditModal}
            {...props}
          />
        </Box>
      </Modal>

      <Modal
        open={showDeleteModal}
        onClose={closeDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleDeleteModal}>
          <DeleteNote
            note={props.noteContent}
            noteId={props.noteId}
            typeOfNote={props.noteType}
            effectiveDate={props.effectiveDate}
            closemodal={closeDeleteModal}
          />
        </Box>
      </Modal>

      <Modal
        open={showViewModal}
        onClose={closeViewModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleLive}>
          <ViewGroupNotes
            note={props.noteContent}
            noteId={props.noteId}
            typeOfNote={props.noteType}
            effectiveDateTime={props.effectiveDateTime}
            closemodal={closeViewModal}
            {...props}
          />
        </Box>
      </Modal>

      <div className="group-note-container">
        <div
          className="group-note-container-block-1"
          style={{ width: "98px", height: "auto" }}
        >
          <DateAndTimeChip date={props.noteDate} />
          <DateAndTimeChip date={props.noteTime} />
          <TypeNoteChip noteType={props.noteType} />
        </div>
        <div
          className="group-note-container-block-2"
          style={{ width: "45px", height: "auto" }}
        >
          <img src={connector} alt="connector " height="300" />
        </div>
        <div className="group-note-container-block-3">
          <div className="group-note-container-line-1">
            {/* <div className="note-container-line1-block1 centre-block">
              <SmallClientChip
                firstName={props.clientFirstName}
                lastName={props.clientLastName}
                avatar="1"
                clientPic={props.clientPic}
              />
            </div> */}
            <div className="note-container-line1-block1bisDouble left-block">
              <div className="title-cell-container">Done by:</div>
              <div className="content-cell-container">{props.doneBy}</div>
            </div>
            <div className="note-container-line1-block2 left-block">
              <div className="title-cell-container">Record timestamp:</div>
              <div className="content-cell-container">{props.timeStamp}</div>
            </div>
            <div className="note-container-line1-block2 centre-block2">
              <div className="sub-block-2">
                {/* <ViewButton />
                <PrintButton /> */}
                <EditButton handleOpen={openEditModal} />
                <ViewButton handleOpen={openViewModal} />
                <DeleteButton handleOpen={openDeleteModal} />
              </div>
            </div>
          </div>
          <div className="group-note-container-line-1">
            <div className="group-container-line1-block1 left-block">
              <div className="title-cell-container"> Group topic:</div>
              <div className="content-cell-container"> {props.groupTopic}</div>
            </div>
            <div className="group-container-line1-block1 left-block">
              <div className="title-cell-container"> Type of group:</div>
              <div className="content-cell-container">
                {props.groupType === "Other"
                  ? props.otherTypeGroupNote
                  : props.groupType}
              </div>
            </div>
            <div className="group-container-line1-block2 left-block">
              <div className="title-cell-container">
                {" "}
                Clients not in attendance:
              </div>
              <div className="content-cell-container">
                {" "}
                {props.notInAttendance}
              </div>
            </div>
          </div>
          <div className="group-note-container-line-2">{props.noteContent}</div>
        </div>
      </div>
    </div>
  );
}

export default GroupNoteContainer;
