import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";

function RatingComponent({ value, onChange }) {
  const { translate } = useContext(LanguageContext);
  return (
    <div style={{ marginBottom: "20px" }}>
      <FormControl
        sx={{ marginLeft: 0, paddingLeft: 0 }}
        style={{
          paddingLeft: "0px",

          marginLeft: "0px",
        }}
      >
        <FormLabel id="demo-form-control-label-placement">
          <div
            style={{
              paddingLeft: "0px",
              color: "#a0a0a0",
              fontWeight: "500",
              fontSize: "12px",
              textTransform: "uppercase",
              paddingBottom: "10px",
            }}
          >
            {" "}
            {translate("OverallLevelOfEngagement")}
          </div>
        </FormLabel>
        <RadioGroup
          className="radio-group-container"
          row
          aria-labelledby="demo-form-control-label-placement"
          name="position"
          value={value}
          onChange={(event) => onChange(event.target.value)}
          sx={{ marginLeft: 0 }}
        >
          <FormControlLabel
            value="1"
            control={<Radio />}
            label={translate("Resistance")}
            labelPlacement="top"
            sx={{ ".MuiFormControlLabel-label": { fontSize: 12 } }}
          />
          <FormControlLabel
            value="2"
            control={<Radio />}
            label=""
            labelPlacement="top"
          />
          <FormControlLabel
            value="3"
            control={<Radio />}
            label=""
            labelPlacement="top"
          />
          <FormControlLabel
            value="4"
            control={<Radio />}
            label=""
            labelPlacement="top"
          />
          <FormControlLabel
            value="5"
            control={<Radio />}
            label={translate("HighlyEngaged")}
            labelPlacement="top"
            sx={{ ".MuiFormControlLabel-label": { fontSize: 12 } }}
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
}

export default RatingComponent;
