import React, { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";

function TitleExportMobileV2(props) {
  const { translate } = useContext(LanguageContext);
  return (
    <div
      id="filter-area"
      style={{
        display: "flex",
        flexDirection: "row",
        paddingBottom: "5px",
        // overflowX: "auto",
        justifyContent: "flex-start",
        alignContent: "flex-end",
        alignItems: "flex-end",
        borderBottom: "3px solid lightgray",
        width: "100%",
        fontSize: "12px",
        // backgroundColor: "yellow",
      }}
    >
      <div style={{ width: "33%" }}>{translate("FirstName")}</div>

      <div style={{ width: "33%" }}>{translate("LastName2")}</div>

      {/* <div style={{ width: "95px" }}>{translate("Gender")}</div> */}

      <div style={{ width: "33%", textAlign: "center" }}>
        {translate("Status")}
      </div>

      {/* <div style={{ width: "95px" }}>{translate("Nationality")}</div>

      <div style={{ width: "95px" }}>{translate("Country")}</div>

      <div style={{ width: "95px" }}>{translate("PrimaryAddiction")}</div>

      <div style={{ width: "95px" }}>{translate("SecondaryAddiction")}</div>

      <div style={{ width: "95px" }}>{translate("LengthOfStay")}</div>

      <div style={{ width: "95px" }}>{translate("InActiveRecovery")}</div>

      <div style={{ width: "95px" }}>{translate("GoogleReviewDone")}</div> */}
    </div>
  );
}

export default TitleExportMobileV2;
