import React from "react";
import PeopleIcon from "@mui/icons-material/People";
import FlightIcon from "@mui/icons-material/Flight";
import EventIcon from "@mui/icons-material/Event";
import { Skeleton } from "@mui/material";

export default function MetricsWidgetV3(props) {
  const renderIcon = (iconName) => {
    switch (iconName) {
      case "People":
        return (
          <PeopleIcon
            style={{
              fontSize: "30px",
              marginRight: "10px",
              backgroundColor: "#f3f4f6",
              paddingLeft: "10px",
              paddingRight: "10px",
              borderRadius: "50px",
              height: "40px",
              width: "40px",
            }}
          />
        );
      case "Flight":
        return (
          <FlightIcon
            style={{
              fontSize: "30px",
              marginRight: "10px",
              backgroundColor: "#f3f4f6",
              paddingLeft: "10px",
              paddingRight: "10px",
              borderRadius: "50px",
              height: "40px",
              width: "40px",
            }}
          />
        );
      case "Event":
        return (
          <EventIcon
            style={{
              fontSize: "30px",
              marginRight: "10px",
              backgroundColor: "#f3f4f6",
              paddingLeft: "10px",
              paddingRight: "10px",
              borderRadius: "50px",
              height: "40px",
              width: "40px",
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      className="metrics-widget-container-v2"
      style={{
        backgroundColor: props.backgroundColor,
        color: props.textColor,
        display: "flex",
        flexDirection: "row",
        // width: "100%",
        width: "220px",
      }}
    >
      <div
        style={{
          fontSize: "16px",
        }}
      >
        {renderIcon(props.iconName)}
      </div>

      <div>
        <div
          style={{
            width: "100%",
            height: "30%",
            textAlign: "start",
            fontSize: "16px",
            fontWeight: "600",
            color: props.textColor2,
          }}
        >
          {props.title}
        </div>
        <div
          style={{
            width: "170px",
            textAlign: "start",
            fontSize: "28px",
            fontWeight: "700",
            lineHeight: "28px",
          }}
        >
          {props.isLoading ? <Skeleton width={150} /> : props.value}
        </div>
        <div
          style={{
            width: "auto",
            textAlign: "start",
            fontSize: "18px",
            lineHeight: "28px",
            fontWeight: "500",
          }}
        >
          {props.isLoading ? <Skeleton width={100} /> : props.date}
        </div>
      </div>
    </div>
  );
}
