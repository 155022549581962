import React from "react";
import AddButtonRoundMobileV4 from "../Buttons/AddButtonRoundMobileV4";

function FormLineTitleMobileV2(props) {
  return (
    <div
      style={{
        marginLeft: "0px",
        display: "flex",
        flexDirection: "row",
        height: "30px",
        borderRadius: "30px",
        border: "1px solid lightgrey",
        width: "100%",
        marginTop: "5px",
        marginBottom: "5px",
        paddingLeft: "15px",
        paddingRight: "5px",
      }}
    >
      <div
        // className="formLineBlockAll"
        style={{
          width: "90%",
          fontSize: "13px",
          fontWeight: "600",
          display: "flex",
          justifyContent: "flex-start",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {props.title}
      </div>
      <div
        style={{
          width: "10%",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <AddButtonRoundMobileV4 handleOpen={props.handleOpen} />
      </div>
    </div>
  );
}

export default FormLineTitleMobileV2;
