import { Input, TextField } from "@mui/material";
import React from "react";

export default function InputComponentV2Mobile(props) {
  const handleChange = (event) => {
    props.onChange(event.target.value);
  };
  return (
    <div
      // className="input-label-container"
      style={{ width: "100%" }}
    >
      <div
        // className="input-label"
        style={{
          color: "#a0a0a0",
          fontWeight: "500",
          fontSize: "12px",
          textTransform: "uppercase",
        }}
      >
        {" "}
        {props.title}{" "}
        {props.isRequired && <span style={{ color: "red" }}>*</span>}
      </div>

      <Input
        variant="outlined"
        // placeholder="Search client…"
        onChange={handleChange}
        value={props.value}
        style={{
          width: "90%",
          height: "40px",
          borderRadius: "5px",
          background: "#f3f3f3",
          // border: "1px solid #BEBCBC",
          marginTop: "2px",
          paddingLeft: "8px",
          fontWeight: "500",
          fontSize: "16px",
        }}
        placeholder={props.placeholder}
      />
    </div>
  );
}
