export default [
  {
    id: 1,
    textDropDownEn: "--Choose--",
  },

  {
    id: 2,
    textDropDownEn: "Normal User",
  },

  {
    id: 3,
    textDropDownEn: "Support Staff",
  },

  {
    id: 4,
    textDropDownEn: "Admission staff",
  },

  {
    id: 5,
    textDropDownEn: "Medical Staff",
  },

  {
    id: 6,
    textDropDownEn: "Hospitality Staff",
  },

  {
    id: 7,

    textDropDownEn: "Therapist",
  },

  {
    id: 8,
    textDropDownEn: "Admin",
  },

  {
    id: 9,
    textDropDownEn: "Super Admin",
  },

  {
    id: 10,
    textDropDownEn: "Clinical Director",
  },

  {
    id: 11,
    textDropDownEn: "Psycho-ed",
  },

  {
    id: 12,
    textDropDownEn: "Finance team",
  },
];
