import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function TitleSearchComponentMaleFemale(props) {
  return (
    <div style={{ marginRight: "10px" }}>
      <div style={{ width: "95px" }}>
        <div
          style={{
            fontSize: "12px",
            paddingBottom: "3px",
            fontWeight: "700",
            textAlign: "start",
            color: "#7a8199",
            textTransform: "uppercase",
          }}
        >
          {props.title}
        </div>
        <div className="title-search-area">
          <FormControl sx={{ width: "100%" }}>
            <Select
              value={props.value}
              onChange={props.onChange}
              label="Gender"
              sx={{
                border: "0.3px solid black",
                borderRadius: 1,
                height: 30,
                fontSize: 12,
              }}
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="(empty)">(empty)</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
}

export default TitleSearchComponentMaleFemale;
