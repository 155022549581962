import { Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import SignaturePad from "react-signature-canvas";

function SignaturePadComponent({ onSignatureSave, onSignatureValidate }) {
  const [, setTrimmedDataURL] = useState(null);
  const [, setIsSignatureStarted] = useState(false);
  const [signatureValidated, setSignatureValidated] = useState(false);

  const sigPad = useRef();

  const getDataURLFromCanvas = () => {
    return sigPad.current.getTrimmedCanvas().toDataURL("image/png");
  };

  const handleClear = () => {
    sigPad.current.clear();
    setTrimmedDataURL(null);
    onSignatureSave(null);
    setSignatureValidated(false);
    onSignatureValidate(false);
  };

  const handleSave = () => {
    const dataURL = getDataURLFromCanvas();
    setTrimmedDataURL(dataURL);
    onSignatureSave(dataURL);
    setSignatureValidated(true);
    onSignatureValidate(true);
  };

  useEffect(() => {
    if (sigPad.current) {
      sigPad.current.onBegin = () => {
        setIsSignatureStarted(true);
        setTrimmedDataURL(null);
        setSignatureValidated(false);
        onSignatureValidate(false);
      };
    }
  }, [sigPad, onSignatureValidate]);

  return (
    <div className="signature-container-column">
      <SignaturePad
        penColor="black"
        velocityFilterWeight={0.7}
        minWidth={0.5}
        maxWidth={2.5}
        canvasProps={{
          // width: 300,
          // height: 200,
          className: "sigCanvas",
        }}
        ref={sigPad}
      />
      <div className="right-signature-container-2">
        <div className="signature-validation-message">
          {signatureValidated ? (
            <span style={{ color: "green", fontWeight: "bold" }}>
              Signature Validated!
            </span>
          ) : (
            <span style={{ color: "red" }}>Signature not validated</span>
          )}
        </div>

        <div className="signature-buttons-container">
          <Button
            variant="contained"
            style={{
              backgroundColor: "red",
              color: "white",
              width: "60%",
            }}
            // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
            onClick={handleClear}
          >
            Clear the pad
          </Button>

          <Button
            variant="contained"
            style={{
              backgroundColor: "green",
              color: "white",
              width: "60%",
              marginTop: "10px",
            }}
            onClick={handleSave}
            // disabled={!trimmedDataURL || !isSignatureStarted}
          >
            Validate signature
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SignaturePadComponent;
