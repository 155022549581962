import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import DatePickerWithTimeComponent from "../DatePickerWithTimeComponent";
import { useAuth } from "../../contexts/AuthContext";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { showCustomToast } from "../Misc/CustomToast";
import DropDownSpecificStaff from "../Dropdowns/DropDownSpecificStaff";

function AddEndOfShiftNote(props) {
  const { currentUser } = useAuth();
  const [note, setNote] = useState("");
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [selectedStaff, setSelectedStaff] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isFormValid = note && startTime && endTime && selectedStaff;

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const handleStartTimeChange = (event) => {
    setStartTime(event);
  };

  const handleEndTimeChange = (event) => {
    setEndTime(event);
  };

  const handleStaffChange = (selectedStaff) => {
    setSelectedStaff(selectedStaff);
  };

  const handleSubmit = async () => {
    if (!isFormValid) {
      showCustomToast("Please fill all required fields", "error");
      return;
    }

    setIsLoading(true);
    const noteData = {
      noteType: "endOfShift",
      noteContent: note,
      shiftStart: startTime,
      shiftEnd: endTime,
      staff: selectedStaff,
      createdAt: serverTimestamp(),
      doneBy: currentUser.displayName,
    };

    try {
      await addDoc(collection(db, "notes"), noteData);
      showCustomToast("End of Shift Note successfully added!", "success");
      props.closemodal();
    } catch (error) {
      console.error("Error adding End of Shift Note:", error);
      showCustomToast("Error when adding End of Shift Note", "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ width: "auto", height: "100%" }}>
      <div className="line1-modal-percent">
        <div className="modal-title">Add End Of Shift Note</div>
        <CloseIcon
          style={{
            marginRight: "16px",
            color: "rgb(214, 213, 213)",
            cursor: "pointer",
          }}
          onClick={props.closemodal}
        />
      </div>

      <div
        style={{
          width: "100%",
          marginTop: "0px",
          height: "75%",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <div className="line2-column-modal-bis">
          <div className="label-textfield">Note</div>
          <TextField
            value={note}
            onChange={handleNoteChange}
            style={{
              width: "95%",
              borderRadius: "5px",
              backgroundColor: "#f2eeef",
            }}
            placeholder="Type your note here"
            multiline
            rows={12}
          />
        </div>
        <div
          className="line3-modal-nopad"
          style={{ paddingLeft: "20px", marginBottom: "20px" }}
        >
          <DatePickerWithTimeComponent
            value={startTime}
            onChange={handleStartTimeChange}
            title="Start of Shift"
          />
          <DatePickerWithTimeComponent
            value={endTime}
            onChange={handleEndTimeChange}
            title="End of Shift"
          />
          <DropDownSpecificStaff
            value={selectedStaff}
            onChange={handleStaffChange}
            title="Select Staff"
          />
        </div>
      </div>

      <div className="line4-modal-percent">
        <Button
          variant="contained"
          style={{ backgroundColor: "white", color: "black", marginRight: 10 }}
          // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
          onClick={props.closemodal}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!isFormValid || isLoading}
          variant="contained"
          style={
            isLoading
              ? { backgroundColor: "grey" }
              : { backgroundColor: "#1565C0", color: "white" }
          }
        >
          {isLoading ? "ADDING NOTE..." : "ADD NOTE"}
        </Button>
      </div>
    </div>
  );
}

export default AddEndOfShiftNote;
