import React, { useState, useEffect, useContext } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import DeleteIcon from "@mui/icons-material/Delete";
import { db } from "../../firebase";
import { showCustomToast } from "../Misc/CustomToast";
import pdfIcon from "../../images/pdf-icon-small.png";
import wordIcon from "../../images/word-icon-small.png";
import excelIcon from "../../images/excel-icon-small.png";
import zIcon from "../../images/icons/7z-small-icon.png";
import csvIcon from "../../images/icons/csv-small-icon.png";
import gifIcon from "../../images/icons/gif-small-icon.png";
import jpgIcon from "../../images/icons/jpg-small-icon.png";
import pngIcon from "../../images/icons/png-small-icon.png";
import pptIcon from "../../images/icons/ppt-small-icon.png";
import rarIcon from "../../images/icons/rar-small-icon.png";
import txtIcon from "../../images/icons/txt-small-icon.png";
import zipIcon from "../../images/icons/zip-small-icon.png";
import uploadicon from "../../images/uploadfile.png";
import CircularProgress from "@mui/material/CircularProgress"; // Importer CircularProgress de Material-UI
import { LanguageContext } from "../../context/LanguageContext";
import DeleteFile from "../Notes/DeleteFile";
import { Box, Modal } from "@mui/material";

function UploaderARAV2MobileV2(props) {
  const { translate } = useContext(LanguageContext);
  const maxSizePicFile = 100 * 1024 * 1024; // 100 Mo
  const [files, setFiles] = useState([]);
  const [fileLinks, setFileLinks] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [uploadingMessageVisible, setUploadingMessageVisible] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const openDeleteModal = (file) => {
    setFileToDelete(file);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setFileToDelete(null);
    setIsDeleteModalOpen(false);
  };

  const confirmDeleteFile = async () => {
    if (fileToDelete) {
      await deleteFile(fileToDelete.url, fileToDelete.name, props.clientId);
      closeDeleteModal();
    }
  };

  const styleDeleteModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  const getFileIcon = (filename) => {
    const extension = filename.split(".").pop().toLowerCase();
    switch (extension) {
      case "pdf":
        return pdfIcon;
      case "doc":
      case "docx":
        return wordIcon;
      case "xls":
      case "xlsx":
        return excelIcon;
      case "ppt":
      case "pptx":
        return pptIcon;
      case "txt":
        return txtIcon;
      case "csv":
        return csvIcon;
      case "zip":
        return zipIcon;
      case "rar":
        return rarIcon;
      case "7z":
        return zIcon;
      case "jpg":
      case "jpeg":
        return jpgIcon;
      case "png":
        return pngIcon;
      case "gif":
        return gifIcon;
      default:
        return "📄";
    }
  };

  const handleFileChange = async (event) => {
    if (event.target.files && event.target.files.length <= 3) {
      const selectedFiles = event.target.files;
      setFiles(selectedFiles);
      await uploadFilesAndGetUrls(selectedFiles, props.clientId);
    } else {
      showCustomToast(translate("UploadLimitExceeded"), "error");
    }
  };

  const fetchFileUrls = async () => {
    const docRef = doc(db, "clients", props.clientId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setFileLinks(docSnap.data().fileUrlsRoi || []);
    } else {
      console.log("No such document!");
    }
  };

  useEffect(() => {
    fetchFileUrls();
  }, [props.clientId]);

  const uploadFilesAndGetUrls = async (fileList, docId) => {
    const storage = getStorage();
    const promises = [];
    setShowProgressBar(true);
    setUploadingFiles([]);
    setUploadingMessageVisible(true); // Afficher le message "Updating" dès que l'upload commence

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      if (file.size > maxSizePicFile) {
        showCustomToast(
          `${translate("FileSizeExceedsLimit")} ${file.name}`,
          "error"
        );
        continue;
      }

      setUploadingFiles((prevState) => [
        ...prevState,
        { name: file.name, progress: 0 },
      ]);

      // Ajouter un timestamp au nom du fichier pour garantir un chemin unique
      const timestamp = Date.now();
      const uniqueFileName = `${timestamp}_${file.name}`;

      const filePath = `Documents/generalInfos/${docId}/${uniqueFileName}`;

      const storageRef = ref(storage, filePath);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on("state_changed", (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        if (progress === 100) {
          setShowProgressBar(false);
          setUploadingMessageVisible(true); // Afficher "Updating" après la fin du téléchargement
        }
        setUploadingFiles((prevState) =>
          prevState.map((f) => (f.name === file.name ? { ...f, progress } : f))
        );
      });

      promises.push(
        new Promise((resolve, reject) => {
          uploadTask
            .then((snapshot) => {
              return getDownloadURL(snapshot.ref);
            })
            .then((url) => {
              resolve({ name: uniqueFileName, url }); // Utiliser le nom de fichier unique ici
            })
            .catch((error) => {
              reject(error);
            });
        })
      );
    }

    try {
      const fileUrls = await Promise.all(promises);
      const clientRef = doc(db, "clients", docId);
      const docSnap = await getDoc(clientRef);
      document.getElementById("uploadFiles").value = "";
      const existingUrls =
        docSnap.exists() && docSnap.data().fileUrls
          ? docSnap.data().fileUrls
          : [];
      await updateDoc(clientRef, {
        fileUrls: [...existingUrls, ...fileUrls],
      });

      setUploadingFiles((prevState) =>
        prevState.filter((f) => !fileUrls.includes(f.name))
      );
      setUploadingMessageVisible(false); // Masquer le message après l'upload
      fetchFileUrls();
      return fileUrls;
    } catch (error) {
      console.error("Erreur lors de l'upload des fichiers: ", error);
      setUploadingMessageVisible(false); // Masquer le message en cas d'erreur
    }
  };

  const deleteFile = async (fileUrl, fileName, noteId) => {
    const storage = getStorage();
    const filePath = `Documents/ScannedRois/${props.clientId}/${fileName}`;
    const storageRef = ref(storage, filePath);

    try {
      await deleteObject(storageRef);
      const newFileLinks = fileLinks.filter((file) => file.url !== fileUrl);
      setFileLinks(newFileLinks);
      const noteRef = doc(db, "clients", noteId);

      await updateDoc(noteRef, {
        fileUrls: newFileLinks,
      });
      document.getElementById("uploadFiles").value = "";

      showCustomToast(translate("FileSuccessfullyDeleted"), "success");
    } catch (error) {
      console.log("Error deleting file:", error);
      showCustomToast(translate("ErrorDeletingFile"), "error");
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Modal
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleDeleteModal}>
          <DeleteFile
            closemodal={closeDeleteModal}
            handleDeleteFile={confirmDeleteFile}
          />
        </Box>
      </Modal>

      <div className="main-upload-container-v3">
        <div className="form2-v2">
          <div className="line5-modal">
            <img
              src={uploadicon}
              width="50px"
              alt=""
              onClick={() => document.getElementById("uploadFiles").click()}
              style={{ cursor: "pointer" }}
            />
            <input
              type="file"
              id="uploadFiles"
              multiple
              onChange={handleFileChange}
              accept="image/png, image/jpeg, image/jpg, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .csv, .zip, .rar, .7z"
              style={{
                width: "0.1px",
                height: "0.1px",
                opacity: 0,
                overflow: "hidden",
                position: "absolute",
                zIndex: -1,
              }}
            />
            <label
              htmlFor="uploadFiles"
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "8px",
                marginTop: "20px",
                paddingLeft: "5px",
                paddingRight: "5px",
                fontWeight: "600",
              }}
            >
              {translate("ImportFile")}
            </label>
            <div style={{ color: "#838383", fontSize: "12px" }}>
              {translate("MaximumFileSize")}
            </div>
          </div>
        </div>
      </div>
      {showProgressBar &&
        uploadingFiles.map((file, index) => (
          <div
            key={index}
            className="file-item-v2"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              borderRadius: "5px",
              backgroundColor: "#f9f9f9",
              padding: "5px 10px",
              width: "100%",
            }}
          >
            <img src={uploadicon} className="file-icon" alt="file icon" />
            <span className="file-name" style={{ flex: 1 }}>
              {file.name}
            </span>
            <div
              style={{
                width: "100%",
                height: "8px",
                borderRadius: "4px",
                backgroundColor: "#e0e0e0",
                overflow: "hidden",
                flex: 2,
                marginRight: "10px",
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: `${file.progress}%`,
                  backgroundColor: "#3f51b5",
                  transition: "width 0.2s ease-in-out",
                }}
              ></div>
            </div>
            <span
              style={{ width: "40px", textAlign: "right", fontSize: "12px" }}
            >
              {Math.round(file.progress)}%
            </span>
          </div>
        ))}

      {/* {showProgressBar && (
        <div style={{ marginBottom: "20px" }}>
          <div
            className="select-title-v2"
            style={{ paddingTop: "10px", fontWeight: "500" }}
          >
            {translate("UploadingFile")}
          </div>
          <CircularProgress style={{ display: "block", margin: "auto" }} />
        </div>
      )} */}

      {uploadingMessageVisible && !showProgressBar && (
        <div
          style={{
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "20px",
            fontWeight: "bold",
            color: "green",
            fontWeight: "600",
          }}
        >
          {translate("Updating")}
        </div>
      )}

      {fileLinks?.length > 0 && !showProgressBar && (
        <div
          style={{ width: "100%", paddingLeft: "0px", paddingRight: "25px" }}
        >
          <div
            style={{
              border: "1px solid lightgrey",
              width: "100%",
              borderRadius: "10px",
            }}
          >
            <div
              className="select-title-v2"
              style={{ paddingTop: "10px", fontWeight: "500" }}
            >
              {translate("UploadedFiles")}

              <div className="ul-container-v2">
                {fileLinks.map((file, index) => (
                  <div
                    className="file-item-v2"
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                    key={index}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {file.name.match(/\.(jpg|jpeg|png)$/) ? (
                        <a
                          href={file.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={file.url}
                            alt="preview"
                            style={{ width: "80px", marginRight: "10px" }}
                          />
                        </a>
                      ) : (
                        <a
                          href={file.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={getFileIcon(file.name)}
                            className="file-icon"
                            alt="file icon"
                            style={{ width: "50px", marginRight: "10px" }}
                          />
                        </a>
                      )}
                      <a
                        href={file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <span className="file-name">{file.name}</span>
                      </a>
                    </div>

                    <DeleteIcon
                      onClick={() =>
                        openDeleteModal({ url: file.url, name: file.name })
                      }
                      style={{ cursor: "pointer", marginLeft: "10px" }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UploaderARAV2MobileV2;
