import React from "react";
import "../css/dashboard.css";
import Navbar from "../component/Navbar";
import ForgotPasswordContent from "./ForgotPasswordContent";

export default function ForgotPassword() {
  return (
    <div className="main-container">
      <Navbar linkActive={9} />
      <ForgotPasswordContent />
    </div>
  );
}
