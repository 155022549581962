import React, { useRef } from "react";
import { Button, Checkbox } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import TopTitleFormComponent from "./TopTitleFormComponent";
import InputComponent from "../InputComponent";
import ReactToPrint from "react-to-print";

function ViewReleaseOfInformation(props) {
  const printRef = useRef();
  const clientSignaturePic = props.signatureClientUrl;

  function formatDate(timestamp) {
    const date = timestamp.toDate();
    const day = date.getDate().toString().padStart(2, "0");
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const timeZone = date
      .toLocaleString("en", { timeZoneName: "short" })
      .split(" ")
      .pop();

    return `${day} ${month} ${year} ${hours}:${minutes} ${timeZone}`;
  }

  return (
    <>
      <style>
        {`
          @media print {
            #printableContent {
              height: auto !important;
              overflow-y: visible !important;
            }
          }
        `}
      </style>
      <div
        // className="inputAndLabelModalContainerTitle2"
        style={{ width: "auto", height: "100%" }}
      >
        <div className="line1-modal-percent">
          <TopTitleFormComponent
            title="RELEASE OF INFORMATIONS FORM"
            closemodal={props.closemodal}
          />
        </div>

        <div
          id="printableContent"
          style={{
            width: "100%",
            marginTop: "10px",
            height: "80%",
            overflowY: "scroll",
            // overflowX: "hidden",
          }}
          ref={printRef}
        >
          <div className="client-name-container">
            Release of information form for: {props.firstName} {props.lastName}
          </div>
          <br />
          <div className="top-text-block-roi">
            This is to confirm that I,{" "}
            <span style={{ fontWeight: "bold" }}>
              {props.firstName} {props.lastName}
            </span>
            , hereby authorise our rehab to consult with the following
            individuals and organisations about my treatment. I give my consent
            to:
          </div>
          <div className="SubTitleBold5">
            Records may be released to and obtained from the following
            individual or organization:
          </div>
          <div className="tab-form-container">
            <div className="SubTitleBold5">Company / Individual Name: </div>
            <div className="text-block2">{props.companyIndividualName}</div>

            <div className="SubTitleBold5">Relationship: </div>
            <div className="text-block2">{props.relationship}</div>

            {/* <div className="SubTitleBold5">Phone number: </div>
          <div className="text-block2">{props.phoneNumber}</div> */}

            <div className="SubTitleBold5">Phone number: </div>
            <div className="text-block2">{props.phoneNumber}</div>

            <div className="SubTitleBold5">Email:</div>
            <div className="text-block2">{props.email}</div>

            <div className="SubTitleBold5">
              The purpose of releasing / obtaining these records is:
            </div>
            <div className="text-block2">{props.purpose}</div>
          </div>
          <div className="SubTitleBold4">
            The specific and relevant information that may be released /
            obtained is:
          </div>
          <div className="yes-no-container-column">
            <div className="checkbox-container">
              <Checkbox checked={props.acknowledgmentOfPresence} />
              Acknowledgment of Presence in Treatment
            </div>

            <div className="checkbox-container">
              <Checkbox checked={props.neededInfos} />
              Needed information to ensure payment for treatment
            </div>

            <div className="checkbox-container">
              <Checkbox checked={props.clinicalInformation} />
              Clinical information for insurance
            </div>
            <div className="checkbox-container">
              <Checkbox checked={props.psychEvaluation} />
              Psychiatric or psychological evaluation
            </div>
            <div className="checkbox-container">
              <Checkbox checked={props.referralLetter} />
              Referral letter
            </div>
            <div className="checkbox-container">
              <Checkbox checked={props.labData} />
              Lab data / drug screens
            </div>
            <div className="checkbox-container">
              <Checkbox checked={props.progressNotes} />
              Progress notes
            </div>

            <div className="checkbox-container">
              <Checkbox checked={props.medicalRecords} />
              Medical / hospital records
            </div>
            <div className="checkbox-container">
              <Checkbox checked={props.progressInTreatment} />
              Progress in treatment
            </div>
            <div className="checkbox-container">
              <Checkbox checked={props.psychosocialAssessment} />
              Psychosocial assessment
            </div>
            <div className="checkbox-container">
              <Checkbox checked={props.medicationInfo} />
              Medication information
            </div>
            <div className="checkbox-container">
              <Checkbox checked={props.treatmentPlan} />
              Treatment plan
            </div>
            <div className="checkbox-container">
              <Checkbox checked={props.physicalAssessment} />
              Physical Assessment
            </div>
            <div className="checkbox-container">
              <Checkbox checked={props.dischargeSummary} />
              Discharge summary
            </div>
            <div className="checkbox-container">
              <Checkbox checked={props.familyHistory} />
              Family/ Significant Other history
            </div>
            <div className="checkbox-container">
              <Checkbox checked={props.dischargePlan} />
              Discharge plan
            </div>
            <div className="checkbox-container">
              <Checkbox checked={props.admissionDischargeDates} />
              Admission and discharge dates
            </div>
            <div className="checkbox-container">
              <Checkbox checked={props.otherInfo} />
              Others
              {props.otherInfo ? (
                <InputComponent value={props.otherInfoInput} />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="SubTitleBold4">
            This Consent of Release of Information is valid up to 90 days
            post-discharge.
          </div>

          <div className="SubTitleBold4">
            I understand that I can revoke this Consent of Release of
            Information at any time by informing our rehab’s staff.
          </div>
          <div className="text-block-2">
            Timestamp signature : {formatDate(props.signedOn)}{" "}
          </div>
          <div className="signature-title">
            Client signature (
            <span style={{ fontWeight: "bold" }}>
              {props.firstName} {props.lastName}
            </span>
            ):
          </div>
          <img
            className="signature-pic-roi"
            src={clientSignaturePic}
            alt="Signature Client"
          />
        </div>

        <div className="line4-modal-percent-bis">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              onClick={props.closemodal}
            >
              Cancel
            </Button>

            <ReactToPrint
              trigger={() => (
                <Button variant="contained" style={{ marginLeft: "20px" }}>
                  Print
                </Button>
              )}
              content={() => printRef.current}
              onAfterPrint={props.closemodal}
            />
          </>
        </div>
      </div>
    </>
  );
}

export default ViewReleaseOfInformation;
