import React from "react";
import "../css/dashboard.css";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import DocumentsContentMobile from "./DocumentsContentMobile";
import NavbarV2 from "../component/NavbarV2";
import MedicationContent from "./MedicationContent";

export default function Medication() {
  const navigate = useNavigate();

  const handleMenuOpen = () => {
    navigate("/menumobile");
  };

  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  return (
    <div className="main-container">
      {isMobile ? (
        <>
          <div className="menu-button-container">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuOpen}
              className="menu-button"
            >
              <MenuIcon />
            </IconButton>
          </div>

          <DocumentsContentMobile />
        </>
      ) : (
        <>
          <NavbarV2 linkActive={17} />
          <MedicationContent />
        </>
      )}
    </div>
  );
}
