import React, { useContext, useRef, useState } from "react";
import { Button, Checkbox } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import TopTitleFormComponent from "./TopTitleFormComponent";
import { getDoc, doc } from "firebase/firestore";
import { useEffect } from "react";
import { db } from "../../firebase";
import ReactToPrint from "react-to-print";
import { LanguageContext } from "../../context/LanguageContext";

function ViewGeneralLiability(props) {
  const { translate } = useContext(LanguageContext);
  const [signaturePic, setSignaturePic] = useState(null);
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [text3, setText3] = useState("");
  const [text4, setText4] = useState("");

  const printRef = useRef();

  function formatDate(timestamp) {
    const date = timestamp.toDate();
    const day = date.getDate().toString().padStart(2, "0");
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    const timeZone = date
      .toLocaleString("en", { timeZoneName: "short" })
      .split(" ")
      .pop();

    return `${day} ${month} ${year} ${hours}:${minutes} ${timeZone}`;
  }

  const fetchSignatureUrl = async () => {
    const docRef = doc(db, "GeneralLiability", props.clientId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const { signatureURL, text1, text2, text3 } = docSnap.data();
      setSignaturePic(signatureURL);
      setText1(text1);
      setText2(text2);
      setText3(text3);
      setText4(text4);
    } else {
      console.log("No such document (signaturePic ref)!");
    }
  };

  useEffect(() => {
    fetchSignatureUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <style>
        {`
          @media print {
            #printableContent {
              height: auto !important;
              overflow-y: visible !important;
            }
          }
        `}
      </style>
      <div
        // className="inputAndLabelModalContainerTitle2"
        style={{ width: "auto", height: "100%" }}
      >
        <div
          className="line1-modal-percent"
          style={{ borderBottom: "1px solid lightgray" }}
        >
          <TopTitleFormComponent
            title={translate("GeneralLiabilityReleaseForm")}
            closemodal={props.closemodal}
          />
        </div>

        <div
          id="printableContent"
          style={{
            width: "100%",
            marginTop: "10px",
            height: "80%",
            overflowY: "scroll",
          }}
          ref={printRef}
        >
          <div className="client-name-container">
            General liability form for: {props.firstName} {props.lastName}
          </div>

          <div className="text-block">{text1}</div>

          <div className="checkbox-container-margin">
            <Checkbox checked={true} />I fully understand and agree to the above
            terms.
          </div>
          <div className="text-block">{text2}</div>

          <div className="checkbox-container-margin">
            <Checkbox checked={true} />I fully understand and agree to the above
            terms.
          </div>

          <div className="text-block">{text3}</div>

          <div className="checkbox-container-margin">
            <Checkbox checked={true} />I fully understand and agree to the above
            terms.
          </div>

          <div className="text-block">{text4}</div>

          <div className="checkbox-container-margin">
            <Checkbox checked={true} />I fully understand and agree to the above
            terms.
          </div>

          <div className="signature-date-container">
            <div className="text-block-2">
              Timestamp signature :{" "}
              {formatDate(props.GeneralLiabilityDateSigned)}{" "}
            </div>
            <div className="signature-title">
              Client signature (
              <span style={{ fontWeight: "bold" }}>
                {props.firstName} {props.lastName}
              </span>
              ):
            </div>
            <img
              alt="Signature"
              className="signature-pic-roi"
              src={signaturePic}
            />
          </div>
        </div>
        <div className="line4-modal-percent-bis">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              onClick={props.closemodal}
            >
              {translate("Cancel")}
            </Button>

            <ReactToPrint
              trigger={() => (
                <Button variant="contained" style={{ marginLeft: "20px" }}>
                  {translate("Print")}
                </Button>
              )}
              content={() => printRef.current}
              onAfterPrint={props.closemodal}
            />
          </>
        </div>
      </div>
    </>
  );
}

export default ViewGeneralLiability;
