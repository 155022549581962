import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import Card from "./Card";
import "./scroll.css";
import { Droppable } from "react-beautiful-dnd";
import Card from "./Card";
import Skeleton from "@mui/material/Skeleton";

const Container = styled.div`
  border-radius: 2.5px;
  width: 100%;
  height: 300px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-right: 0px;
`;

const Title = styled.h3`
  padding: 8px;
  background-color: pink;
  text-align: center;
`;

const TaskList = styled.div`
  padding: 0px;
  transistion: background-color 0.2s ease;
  background-color: #ffffff;
  flex-grow: 1;
  min-height: 100px;
`;

export default function ColumnMobileV2({
  title,
  tasks,
  id,
  borderColor,
  renderSkeleton,
  onSelectCard,
}) {
  const [counter, setCounter] = useState(tasks.length);

  useEffect(() => {
    setCounter(tasks.length);
  }, [tasks]);

  return (
    <Container className="column">
      <div style={{ paddingLeft: "0px", paddingRight: "0px" }}>
        <div
          style={{
            width: "100%",
            height: "80px",
            backgroundColor: "#f5f5f9",
            margin: 0,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{ borderLeft: `5px solid ${borderColor}`, width: "8px" }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "15px",
              justifyContent: "center",
            }}
          >
            <span
              style={{ color: "black", fontSize: "18px", fontWeight: "650" }}
            >
              {" "}
              {title}
            </span>
            <span
              style={{ color: "#a4a4a6", fontSize: "18px", fontWeight: "650" }}
            >
              {" "}
              # {counter}
            </span>
          </div>
        </div>
        <div style={{ paddingTop: "10px" }}>
          <Droppable droppableId={id}>
            {(provided, snapshot) => (
              <TaskList
                ref={provided.innerRef}
                {...provided.droppableProps}
                isDraggingOver={snapshot.isDraggingOver}
              >
                {renderSkeleton
                  ? Array(4)
                      .fill()
                      .map((_, index) => (
                        <Skeleton
                          key={index}
                          variant="rectangular"
                          width="100%"
                          height={120}
                          sx={{
                            marginBottom: "10px",
                            borderRadius: "10px", // Add border-radius
                            backgroundColor: "#e0e0e0", // Change grey color inside
                            animation: "pulse 0.4s ease-in-out infinite", // Adjust the speed of the animation
                          }}
                        />
                      ))
                  : tasks.map((task, index) => (
                      <Card
                        key={index}
                        index={index}
                        task={task}
                        firstName={task.firstName}
                        lastName={task.lastName}
                        sex={task.gender}
                        country={task.country}
                        addiction={task.addiction}
                        onSelectCard={onSelectCard}
                      />
                    ))}
              </TaskList>
            )}
          </Droppable>
        </div>
      </div>
    </Container>
  );
}
