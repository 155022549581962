export default [
  {
    id: 1,
    textDropDownEn: "--Choose--",
  },

  {
    id: 2,
    textDropDownEn: "Married",
  },

  {
    id: 3,
    textDropDownEn: "Single",
  },

  {
    id: 4,
    textDropDownEn: "Divorced",
  },

  {
    id: 5,
    textDropDownEn: "Separated",
  },

  {
    id: 6,
    textDropDownEn: "Widowed",
  },

  // {
  //   id: 7,
  //   textDropDownEn: "Never married",
  // },
];
