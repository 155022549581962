import { Input } from "@mui/material";
import React from "react";

function TitleSearchComponent(props) {
  return (
    <div
      style={{
        marginRight: "10px",
      }}
    >
      <div style={{ width: props.width }}>
        <div
          style={{
            fontSize: "12px",
            paddingBottom: "3px",
            fontWeight: "700",
            textAlign: "start",
            color: "#7a8199",
            textTransform: "uppercase",
          }}
        >
          {props.title}
        </div>
        <div className="title-search-area">
          <Input
            placeholder="type..."
            value={props.value} // Ajouter cette ligne
            onChange={props.onChange} // Ajouter cette ligne
            sx={{
              width: props.inputWidth, // Utilisez inputWidth pour définir la largeur de l'input
              padding: "5px",
              fontSize: "14px",
              height: "30px",
              border: "1px solid black", // Couleur de bordure fixée à noir
              borderRadius: 1,
              "&:hover": {
                borderColor: "primary.light", // Change la couleur de la bordure au survol
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default TitleSearchComponent;
