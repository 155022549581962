import React, { useContext, useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Tooltip from "@mui/material/Tooltip";
import { LanguageContext } from "../../context/LanguageContext";

function TitleNoteCompactClient(props) {
  const { translate } = useContext(LanguageContext);
  const [clientSortOrder, setClientSortOrder] = useState("asc");
  const [addictionSortOrder, setAddictionSortOrder] = useState("asc");
  const [akaSortOrder, setAkaSortOrder] = useState("asc");
  const [admissionSortOrder, setAdmissionSortOrder] = useState("asc");
  const [pddSortOrder, setPddSortOrder] = useState("asc");
  const [ilsSortOrder, setIlsSortOrder] = useState("asc");

  const handleClientSort = () => {
    const newOrder = clientSortOrder === "asc" ? "desc" : "asc";
    setClientSortOrder(newOrder);
    props.handleSort(
      `client${newOrder.charAt(0).toUpperCase() + newOrder.slice(1)}`
    );
  };

  const handleAddictionSort = () => {
    const newOrder = addictionSortOrder === "asc" ? "desc" : "asc";
    setAddictionSortOrder(newOrder);
    props.handleSort(
      `addiction${newOrder.charAt(0).toUpperCase() + newOrder.slice(1)}`
    );
  };

  const handleAkaSort = () => {
    const newOrder = akaSortOrder === "asc" ? "desc" : "asc";
    setAkaSortOrder(newOrder);
    props.handleSort(
      `aka${newOrder.charAt(0).toUpperCase() + newOrder.slice(1)}`
    );
  };

  const handleAdmissionSort = () => {
    const newOrder = admissionSortOrder === "asc" ? "desc" : "asc";
    setAdmissionSortOrder(newOrder);
    props.handleSort(
      `admission${newOrder.charAt(0).toUpperCase() + newOrder.slice(1)}`
    );
  };

  const handlePddSort = () => {
    const newOrder = pddSortOrder === "asc" ? "desc" : "asc";
    setPddSortOrder(newOrder);
    props.handleSort(
      `pdd${newOrder.charAt(0).toUpperCase() + newOrder.slice(1)}`
    );
  };

  const handleIlsSort = () => {
    const newOrder = ilsSortOrder === "asc" ? "desc" : "asc";
    setIlsSortOrder(newOrder);
    props.handleSort(
      `ils${newOrder.charAt(0).toUpperCase() + newOrder.slice(1)}`
    );
  };

  return (
    <div style={{ paddingLeft: "25px" }}>
      <div
        style={{
          width: "90%",
          height: "30px",
          display: "flex",
          flexDirection: "row",
          borderBottom: "1px solid lightgrey",
          cursor: "pointer",
          transition: "background-color 0.3s ease",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "18%",
              height: "30px",
              display: "flex",
              flexDirection: "row",
              color: "#a3a3a4",
              textAlign: "left",
              fontWeight: "500",
            }}
          >
            <Tooltip title="Client name" arrow>
              <div
                style={{
                  paddingRight: "0px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {translate("ClientC")}
              </div>
            </Tooltip>

            <div style={{}}>
              {clientSortOrder === "asc" ? (
                <ArrowDropUpIcon
                  style={{
                    cursor: "pointer",
                    margin: "0",
                    fontSize: "2rem",
                  }}
                  onClick={handleClientSort}
                />
              ) : (
                <ArrowDropDownIcon
                  style={{
                    cursor: "pointer",
                    margin: "0",
                    fontSize: "2rem",
                  }}
                  onClick={handleClientSort}
                />
              )}
            </div>
          </div>

          <div
            style={{
              width: "11%",
              height: "25px",
              display: "flex",
              color: "#a3a3a4",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "500",
            }}
          >
            <Tooltip title="Addiction type" arrow>
              <div
                style={{
                  paddingRight: "0px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {translate("ADDICTION")}
              </div>
            </Tooltip>

            <div style={{ paddingBottom: "3px" }}>
              {addictionSortOrder === "asc" ? (
                <ArrowDropUpIcon
                  style={{
                    cursor: "pointer",
                    margin: "0",
                    fontSize: "2rem",
                  }}
                  onClick={handleAddictionSort}
                />
              ) : (
                <ArrowDropDownIcon
                  style={{
                    cursor: "pointer",
                    margin: "0",
                    fontSize: "2rem",
                  }}
                  onClick={handleAddictionSort}
                />
              )}
            </div>
          </div>

          <div
            style={{
              width: "7%",
              height: "25px",
              display: "flex",
              color: "#a3a3a4",
              textAlign: "left",
              fontWeight: "500",
            }}
          >
            <Tooltip title="Known name (As Known As)" arrow>
              <div
                style={{
                  paddingRight: "0px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {translate("AKA")}
              </div>
            </Tooltip>

            {akaSortOrder === "asc" ? (
              <ArrowDropUpIcon
                style={{
                  cursor: "pointer",
                  margin: "0",
                  fontSize: "2rem",
                }}
                onClick={handleAkaSort}
              />
            ) : (
              <ArrowDropDownIcon
                style={{
                  cursor: "pointer",
                  margin: "0",
                  fontSize: "2rem",
                }}
                onClick={handleAkaSort}
              />
            )}
          </div>

          <div
            style={{
              width: "7%",
              height: "25px",
              display: "flex",
              color: "#a3a3a4",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "500",
            }}
          >
            <Tooltip title="Client age" arrow>
              {translate("AGE")}
            </Tooltip>
          </div>
          <div
            style={{
              width: "11%",
              height: "25px",
              display: "flex",
              color: "#a3a3a4",
              textAlign: "left",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "500",
            }}
          >
            <Tooltip title="Admission date" arrow>
              <div
                style={{
                  paddingRight: "0px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {translate("ADMISSION")}
              </div>
            </Tooltip>

            {admissionSortOrder === "asc" ? (
              <ArrowDropUpIcon
                style={{
                  cursor: "pointer",
                  margin: "0",
                  fontSize: "2rem",
                }}
                onClick={handleAdmissionSort}
              />
            ) : (
              <ArrowDropDownIcon
                style={{
                  cursor: "pointer",
                  margin: "0",
                  fontSize: "2rem",
                }}
                onClick={handleAdmissionSort}
              />
            )}
          </div>

          <div
            style={{
              width: "11%",
              height: "25px",
              display: "flex",
              color: "#a3a3a4",
              // textAlign: "left",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "500",
            }}
          >
            <Tooltip title="Intended residential discharge date" arrow>
              <div
                style={{
                  paddingRight: "0px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                I.D.D
              </div>
            </Tooltip>

            {pddSortOrder === "asc" ? (
              <ArrowDropUpIcon
                style={{
                  cursor: "pointer",
                  margin: "0",
                  fontSize: "2rem",
                }}
                onClick={handlePddSort}
              />
            ) : (
              <ArrowDropDownIcon
                style={{
                  cursor: "pointer",
                  margin: "0",
                  fontSize: "2rem",
                }}
                onClick={handlePddSort}
              />
            )}
          </div>

          <div
            style={{
              width: "8%",
              height: "25px",
              display: "flex",
              color: "#a3a3a4",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "500",
            }}
          >
            <Tooltip title="Intended Length of Stay" arrow></Tooltip>
            <div
              style={{
                paddingRight: "0px",
                display: "flex",
                alignItems: "center",
              }}
            >
              I.L.S
            </div>
          </div>

          <div
            style={{
              width: "9%",
              height: "25px",
              display: "flex",
              color: "#a3a3a4",

              alignItems: "center",
              justifyContent: "center",
              fontWeight: "500",
            }}
          >
            <Tooltip title="Assigned therapist name" arrow>
              {translate("THERAPIST")}
            </Tooltip>
          </div>
          <div
            style={{
              width: "8%",
              height: "25px",
              display: "flex",
              textAlign: "left",
              color: "#a3a3a4",
              alignItems: "center",
              fontWeight: "500",
            }}
          >
            <Tooltip title="Room number" arrow>
              {translate("ROOM")}
            </Tooltip>
          </div>
          <div
            style={{
              // backgroundColor: "lightcoral",
              width: "10%",
              height: "25px",
              display: "flex",
              justifyContent: "center",
              color: "#a3a3a4",
              alignItems: "center",
              fontWeight: "500",
            }}
          >
            <Tooltip title="Assigned support staff name" arrow>
              {translate("Alerts")}
            </Tooltip>
          </div>
          {/* <div
            style={{
              // backgroundColor: "lightcoral",
              width: "5%",
              height: "25px",
              display: "flex",
              textAlign: "left",
              alignItems: "center",

              color: "#a3a3a4",
              fontWeight: "500",
            }}
          >
            {" "}
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default TitleNoteCompactClient;
