import { Box, Button, Modal, Slide } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import AddIntakeForm from "../forms/AddIntakeForm";
import AddReleaseOfInformationForm from "../forms/AddReleaseOfInformationForm";
import AddGeneralLiabilityForm from "../forms/AddGeneralLiabilityForm";
import AddInformedConsentForm from "../forms/AddInformedConsentForm";
import AddConfidentialityPolicy from "../forms/AddConfidentialityPolicy";
import AddClientRights from "../forms/AddClientRights";
import AddAgressionControl from "../forms/AddAgressionControl";
import { format } from "date-fns";
import FormLineIC from "../Lines/FormLineIC";
import FormLineCP from "../Lines/FormLineCP";
import FormLineTitle from "../Lines/FormLineTitle";
import { collection, doc, getDoc, onSnapshot, query } from "firebase/firestore";
import { db } from "../../firebase";
import TitleMediumRoi from "../Titles/TitleMediumRoi";
import FormLineRoi from "../FormLineRoi";
import Joyride, { ACTIONS, STATUS } from "react-joyride";
import { LanguageContext } from "../../context/LanguageContext";
import FormLine3NoMarginV2 from "../Lines/FormLine3NoMarginV2";
import UploaderARAV2 from "../Updloader/UploaderARAV2";
import FormLine3NoMarginV2Mobile from "../Lines/FormLine3NoMarginV2Mobile";
import FormLineICMobileV2 from "../Lines/FormLineICMobileV2";
import FormLineCPMobileV2 from "../Lines/FormLineCPMobileV2";
import FormLineTitleMobileV2 from "../Lines/FormLineTitleMobileV2";
import TitleMediumRoiMobileV2 from "../Titles/TitleMediumRoiMobileV2";
import FormLineRoiMobileV2 from "../FormLineRoiMobileV2";
import UploaderARAV2MobileV2 from "../Updloader/UploaderARAV2MobileV2";
import AddGeneralLiabilityFormMobileV2 from "../forms/AddGeneralLiabilityFormMobileV2";
import AddInformedConsentFormMobileV2 from "../forms/AddInformedConsentFormMobileV2";
import AddConfidentialityPolicyMobileV2 from "../forms/AddConfidentialityPolicyMobileV2";
import AddReleaseOfInformationFormMobileV2 from "../forms/AddReleaseOfInformationFormMobileV2";

function FormsMobileV2(props) {
  const { translate } = useContext(LanguageContext);

  const styleModalGeneral2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 850,
    height: "80%",
    borderRadius: 5,
    // overflowY: "scroll",
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const styleModalUploadPdf = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    height: "auto",
    borderRadius: 5,
    // overflowY: "scroll",
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const steps = [
    {
      target: "#admission-form",
      content:
        "This is the 'Admission form' section where you can manage and add new admission forms for the client.",
    },
    {
      target: "#mandatory-forms",
      content:
        "This is the 'Mandatory forms' section where you can add and manage the required forms for the client.",
    },
    {
      target: "#authorization-release",
      content:
        "This is the 'Authorization for release of confidential information' section. Here you can manage and add new authorization forms for releasing confidential information.",
    },
  ];

  const [run, setRun] = useState(false);
  const [showHelpButton, setShowHelpButton] = useState(true);

  const handleHelpClick = () => {
    setRun(true);
    setShowHelpButton(false); // Cache le bouton "HELP" après le clic
  };

  const handleJoyrideCallback = (data) => {
    const { status, action } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      setShowHelpButton(true);
    } else if ([ACTIONS.CLOSE].includes(action)) {
      setRun(false);
      setShowHelpButton(true);
    }
  };

  const customStyles = {
    options: {
      zIndex: 10000,
    },
    tooltip: {
      backgroundColor: "#fff",
      color: "#000",
      animation: "fadeIn 0.3s ease-in-out",
    },
    tooltipContent: {
      textAlign: "justify",
      marginTop: "10px",
    },
    beacon: {
      offsetY: -50,
    },
    buttonNext: {
      backgroundColor: "#1565c0",
      color: "#fff",
    },
    buttonBack: {
      color: "blue",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 1000,
    },

    beaconInner: {
      backgroundColor: "#1565c0",
      borderColor: "#1565c0",
    },
    beaconOuter: {
      backgroundColor: "rgba(21, 101, 192, 0.5)",
      borderColor: "rgba(21, 101, 192, 0.5)",
    },
  };

  const prevClientIdRef = useRef();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [clientDataRoi, setClientDataRoi] = useState([]);
  const [clientId, setClientId] = useState(props.clientId || "");
  const [openIntakeForm, setOpenIntakeForm] = useState(false);
  const [openRoiForm, setOpenRoiForm] = useState(false);
  const [openGeneralLiabilityForm, setOpenGeneralLiabilityForm] =
    useState(false);
  const [openInformedConsentForm, setOpenInformedConsentForm] = useState(false);
  const [openConfidentialityPolicyForm, setOpenConfidentialityPolicyForm] =
    useState(false);
  const [openClientRightsForm, setOpenClientRightsForm] = useState(false);
  const [openAgressionPolicyForm, setOpenAgressionPolicyForm] = useState(false);
  const handleOpenRoiForm = () => setOpenRoiForm(true);
  const handleOpenGeneralLiabilityForm = () =>
    setOpenGeneralLiabilityForm(true);
  const handleOpenInformedConsentForm = () => setOpenInformedConsentForm(true);
  const handleOpenConfidentialityPolicyForm = () =>
    setOpenConfidentialityPolicyForm(true);
  const handleCloseIntakeForm = () => setOpenIntakeForm(false);
  const handleCloseRoiForm = () => setOpenRoiForm(false);
  const handleCloseGeneralLiabilityForm = () =>
    setOpenGeneralLiabilityForm(false);
  const handleCloseInformedConsentForm = () =>
    setOpenInformedConsentForm(false);
  const handleCloseConfidentialityPolicyForm = () =>
    setOpenConfidentialityPolicyForm(false);
  const handleCloseClientRightsForm = () => setOpenClientRightsForm(false);
  const handleCloseAgressionPolicyForm = () =>
    setOpenAgressionPolicyForm(false);

  useEffect(() => {
    setClientId(props.clientId || "");
  }, [props.selectedTherapist]);

  useEffect(() => {
    setClientId(props.clientId || "");
  }, [props.clientId]);

  function formatDate(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    return format(date, "d MMMM yyyy"); // "d" pour le jour, "MMMM" pour le mois en texte, "yyyy" pour l'année
  }

  function formatDate2(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    return format(date, "d MM yyyy"); // "d" pour le jour, "MMMM" pour le mois en texte, "yyyy" pour l'année
  }

  useEffect(() => {}, [clientDataRoi]);

  useEffect(() => {
    let unsubscribe;

    const fetchData = async () => {
      try {
        const roiDocRef = doc(db, "roi", clientId);
        const roiDocSnap = await getDoc(roiDocRef);

        if (roiDocSnap.exists()) {
          const roiCollectionRef = collection(roiDocRef, "subcollection");

          // Utilisez onSnapshot pour écouter les modifications en temps réel
          unsubscribe = onSnapshot(
            roiCollectionRef,
            (roiCollectionSnapshot) => {
              if (roiCollectionSnapshot.empty) {
                // Si la sous-collection est vide, réinitialisez clientDataRoi à un tableau vide

                setClientDataRoi([]);
              } else {
                const data = roiCollectionSnapshot.docs.map((doc) => ({
                  id: doc.id,
                  ...doc.data(),
                }));
                setClientDataRoi(data);
              }
            },
            (error) => {
              console.error("Error getting subcollection: ", error);
              setClientDataRoi([]);
            }
          );
        } else {
          // Si roiDocSnap n'existe pas, réinitialisez également clientDataRoi à un tableau vide
          setClientDataRoi([]);
        }
      } catch (e) {
        console.error("Error fetching client data: ", e);
      }
    };

    if (clientId && clientId !== prevClientIdRef.current) {
      fetchData();
    }

    prevClientIdRef.current = clientId;

    // Retournez la fonction de désinscription pour nettoyer l'effet
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [clientId]);

  useEffect(() => {}, [triggerUpdate]);

  useEffect(() => {
    let unsubscribe;

    if (clientId) {
      const roiCollectionRef = collection(db, "roi");

      unsubscribe = onSnapshot(roiCollectionRef, (snapshot) => {
        // Vérifiez si un document avec le clientId correspondant a été ajouté
        const newRoiDoc = snapshot
          .docChanges()
          .find(
            (change) => change.type === "added" && change.doc.id === clientId
          );

        if (newRoiDoc) {
          setTriggerUpdate((prevState) => !prevState);
        }
      });
    }

    // Retournez la fonction de désinscription pour nettoyer l'effet
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [clientId]);

  const [, setDocumentOfSubcollection] = useState([]);

  useEffect(() => {
    const roiDocument = doc(db, "roi", clientId);
    const subcollectionQuery = query(collection(roiDocument, "subcollection"));

    const unsubscribe = onSnapshot(subcollectionQuery, (snapshot) => {
      const newDocumentOfSubcollection = snapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setDocumentOfSubcollection(newDocumentOfSubcollection);
    });

    return () => {
      unsubscribe();
    };
  }, [clientId]);

  return (
    <div>
      <Joyride
        steps={steps}
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
        run={run}
        callback={handleJoyrideCallback}
        disableScrolling={true}
        disableBeacon={true}
        styles={customStyles}
      />
      <Modal
        open={openIntakeForm}
        onClose={handleCloseIntakeForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalGeneral2}>
          <AddIntakeForm
            clientId={props.clientId}
            {...props}
            closemodal={handleCloseIntakeForm}
          />
        </Box>
      </Modal>

      {/* <Modal
        open={openRoiForm}
        onClose={handleCloseRoiForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        
        <Box sx={styleModalGeneral2}>
          <AddReleaseOfInformationForm
            closemodal={handleCloseRoiForm}
            clientId={props.clientId}
            {...props}
          />
        </Box>
      </Modal> */}

      <Modal
        open={openRoiForm}
        onClose={handleCloseRoiForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Slide direction="left" in={openRoiForm}>
          <Box
            sx={{
              position: "fixed",
              top: "5%",
              right: 0,
              width: "90%",
              height: "90%",
              minHeight: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              marginRight: "15px",
              pl: 0,
              pr: 0,
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
              zIndex: "1",
            }}
          >
            <AddReleaseOfInformationFormMobileV2
              closemodal={handleCloseRoiForm}
              clientId={props.clientId}
              {...props}
            />
          </Box>
        </Slide>
      </Modal>

      <Modal
        open={openGeneralLiabilityForm}
        onClose={handleCloseGeneralLiabilityForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Slide direction="left" in={openGeneralLiabilityForm}>
          {/* <div className="modal-content"> */}
          <Box
            sx={{
              position: "fixed",
              top: "5%",
              right: 0,
              width: "90%",
              height: "90%",
              minHeight: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              marginRight: "15px",
              pl: 0,
              pr: 0,
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
              zIndex: "1",
            }}
          >
            {/* <Box sx={styleModalGeneral2}> */}
            <AddGeneralLiabilityFormMobileV2
              closemodal={handleCloseGeneralLiabilityForm}
              clientId={props.clientId}
            />
          </Box>
          {/* </div> */}
        </Slide>
      </Modal>

      <Modal
        open={openInformedConsentForm}
        onClose={handleCloseInformedConsentForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Slide direction="left" in={openInformedConsentForm}>
          <Box
            sx={{
              position: "fixed",
              top: "5%",
              right: 0,
              width: "90%",
              height: "90%",
              minHeight: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              marginRight: "15px",
              pl: 0,
              pr: 0,
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
              zIndex: "1",
            }}
          >
            <AddInformedConsentFormMobileV2
              clientId={props.clientId}
              closemodal={handleCloseInformedConsentForm}
            />
          </Box>
        </Slide>
      </Modal>

      <Modal
        open={openConfidentialityPolicyForm}
        onClose={handleCloseConfidentialityPolicyForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Slide direction="left" in={openConfidentialityPolicyForm}>
          <Box
            sx={{
              position: "fixed",
              top: "5%",
              right: 0,
              width: "90%",
              height: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              marginRight: "15px",
              pl: 0,
              pr: 0,
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {" "}
            {/* Remplacez `modal-content` par votre structure de modal */}
            {/* Contenu du modal ici */}
            <AddConfidentialityPolicyMobileV2
              closemodal={handleCloseConfidentialityPolicyForm}
              clientId={props.clientId}
            />
          </Box>
        </Slide>
        {/* <Box sx={styleModalGeneral2}>
          <AddConfidentialityPolicy
            closemodal={handleCloseConfidentialityPolicyForm}
            clientId={props.clientId}
          />
        </Box> */}
      </Modal>

      <Modal
        open={openClientRightsForm}
        onClose={handleCloseClientRightsForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalGeneral2}>
          <AddClientRights
            closemodal={handleCloseClientRightsForm}
            clientId={props.clientId}
          />
        </Box>
      </Modal>

      <Modal
        open={openAgressionPolicyForm}
        onClose={handleCloseAgressionPolicyForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalGeneral2}>
          <AddAgressionControl
            closemodal={handleCloseAgressionPolicyForm}
            clientId={props.clientId}
          />
        </Box>
      </Modal>

      <div className="container-main-plan-mobile-v3">
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "0px",
              paddingLeft: "10px",
              justifyContent: "space-between",
              paddingRight: "20px",
            }}
          >
            <div style={{ fontSize: "18px", fontWeight: "600" }}>
              {translate("AdmissionForms")}
            </div>
            {/* {showHelpButton && (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleHelpClick}
                sx={{ fontSize: "10px" }} // Facultatif : Changez la taille de la police ici
              >
                {translate("Help")}
              </Button>
            )} */}
          </div>
        </div>
      </div>

      <div className="container-main-plan-mobile-v3">
        <div
          id="mandatory-forms"
          // className="tab-container-title-2C"
          style={{
            paddingLeft: "10px",
            fontSize: "14px",
            fontWeight: "500",
            borderTop: "1px solid #cccccc",
            paddingTop: "20px",
          }}
        >
          {translate("MandatoryForms")}
        </div>

        {props.dateOfAdmission ? (
          <div style={{ paddingLeft: "0px" }}>
            <div
              className="tab-form-container-line"
              style={{ paddingTop: "10px" }}
            >
              {/* <Title2NoMargin /> */}
              <FormLine3NoMarginV2Mobile
                title="General liability release"
                color="2"
                signed={props.GeneralLiabilityOk}
                date={formatDate2(props.GeneralLiabilityDateSigned)}
                handleOpen={handleOpenGeneralLiabilityForm}
                {...props}
              />
            </div>

            <div className="form-line-button">
              <FormLineICMobileV2
                title="Informed consent"
                color="1"
                signed={props.InformedConsentOk}
                date={formatDate2(props.InformedConsentDateSigned)}
                handleOpen={handleOpenInformedConsentForm} // Pass the handleOpen function directly to the component
                {...props}
              />
            </div>

            <div className="form-line-button">
              <FormLineCPMobileV2
                title="Confidentiality policy"
                color="1"
                signed={props.ConfidentialityPolicysOk}
                date={formatDate2(props.ConfidentialityPolicyDateSigned)}
                handleOpen={handleOpenConfidentialityPolicyForm} // Pass the handleOpen function directly to the component
                {...props}
              />
            </div>
          </div>
        ) : (
          <div className="error-discharge-message">
            {translate("UnableToAddMandatoryForms")}
          </div>
        )}
      </div>

      <div
      // className="container-main-plan"
      >
        <div
          id="authorization-release"
          // className="tab-container-title-2C"
          style={{
            paddingLeft: "10px",
            fontSize: "14px",
            fontWeight: "500",
            borderTop: "1px solid #cccccc",
            paddingTop: "20px",
            marginTop: "10px",
          }}
        >
          {translate("AuthorizationReleaseConfidentialInfo")}
        </div>

        {props.dateOfAdmission ? (
          <div
          //style={{ backgroundColor: "pink" }}
          >
            <div
              className="form-line-button-title"
              style={{ marginTop: "20px", marginBottom: "10px" }}
            >
              <FormLineTitleMobileV2
                title={translate("AddRCIForm")}
                color="2"
                {...props}
                handleOpen={handleOpenRoiForm}
              />
              {/* <AddButtonRound handleOpen={handleOpenRoiForm} /> */}
              {/* <Button
                style={{ marginLeft: "25px" }}
                variant="contained"
                onClick={handleOpenUploadDoc}
              >
                UPLOAD/DOWNLOAD SCANNED FILES
              </Button> */}
            </div>

            <div
              // className="roi-container-2bis"
              style={{}}
            >
              {/* {clientDataRoi && clientDataRoi.length > 0 ? (
                <TitleMediumRoiMobileV2 />
              ) : (
                ""
              )} */}

              <div className="roi-container-2bis">
                {clientDataRoi.map((item, index) => (
                  <FormLineRoiMobileV2
                    key={index}
                    title={item.companyIndividualName}
                    relationship={item.relationship}
                    signedOn={item.signedOn}
                    revoked={item.revoked}
                    {...item}
                    firstName={props.firstName}
                    lastName={props.lastName}
                    clientId={clientId}
                    subcollectionDocumentId={item.id}
                  />
                ))}
              </div>
            </div>
            {/* <div style={{ width: "50%", marginLeft: 0 }}>
              <UploaderAraEncapsulated {...props} />
            </div> */}

            <div style={{ width: "100%", marginLeft: 0 }}>
              <UploaderARAV2MobileV2 {...props} />
            </div>
          </div>
        ) : (
          <div className="error-discharge-message">
            {translate("UnableToAddROIForm")}
          </div>
        )}
      </div>

      <br />
      <br />
    </div>
  );
}

export default FormsMobileV2;
