import { Collapse } from "antd";
import UpdloaderDocument from "../Updloader/UpdloaderDocument";
const { Panel } = Collapse;
const text = `
 TEST
`;
const CollapseComponent = () => {
  const onChange = (key) => {
    console.log(key);
  };
  return (
    <div className="documents-container">
      <Collapse defaultActiveKey={["1"]} onChange={onChange}>
        <Panel
          header="ADMISSION FORMS"
          key="1"
          style={{ fontSize: "16px", fontWeight: "600" }}
        >
          <UpdloaderDocument repository="AdmissionForms" />
        </Panel>
        <Panel
          header="ESSENTIAL ADMISSION DOCUMENTS"
          key="2"
          style={{ fontSize: "16px", fontWeight: "600" }}
        >
          <UpdloaderDocument repository="EssentialAdmission" />
        </Panel>
        <Panel
          header="ASSESMENTS & TREATMENT PLAN"
          key="3"
          style={{ fontSize: "16px", fontWeight: "600" }}
        >
          <UpdloaderDocument repository="AssessmentTreatmentPlan" />
        </Panel>
        <Panel
          header="MEDICAL REPORTS"
          key="4"
          style={{ fontSize: "16px", fontWeight: "600" }}
        >
          <UpdloaderDocument repository="MedicalReport" />
        </Panel>
      </Collapse>
    </div>
  );
};
export default CollapseComponent;
