import React, { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import UploaderGeneralEncapsulatedV2Mobile from "../Updloader/UploaderGeneralEncapsulatedV2Mobile";
import UploaderTherapistencapsulatedV2Mobile from "../Updloader/UploaderTherapistencapsulatedV2Mobile";

function UploadMobileV2(props) {
  const { translate } = useContext(LanguageContext);
  return (
    <div className="tab-container-white-v2">
      <div className="upload-content-container-mobile">
        <div className="tab-form-container5-mobile100-bis">
          {" "}
          <div
            className="tab-container-title3-mobile-v2"
            style={{ fontSize: "14px" }}
          >
            {translate("GeneralDocuments")}
          </div>
          <UploaderGeneralEncapsulatedV2Mobile {...props} />
        </div>
        <div className="tab-form-container5-mobile100">
          <div
            className="tab-container-title3-mobile-v2"
            style={{ fontSize: "14px" }}
          >
            {translate("TherapistsDocuments")}
          </div>

          <UploaderTherapistencapsulatedV2Mobile {...props} />
        </div>
      </div>
    </div>
  );
}

export default UploadMobileV2;
