import React, { useContext } from "react";
import "../css/dashboard.css";
import TopTitleBannerV2 from "../component/TopTitleBannerV2";
import { LanguageContext } from "../context/LanguageContext";
import UnderConstruction from "../component/Misc/UnderConstruction";
import InvoicingMain from "../component/Misc/InvoicingMain";

export default function InvoicingContent() {
  const { translate } = useContext(LanguageContext);
  return (
    <div className="right-container">
      <TopTitleBannerV2 title={translate("Invoicing")} />
      <InvoicingMain />
    </div>
  );
}
