import React, { useRef } from "react";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactToPrint from "react-to-print";

function ViewEndOfShiftNote(props) {
  const printRef = useRef();

  const formatDate = (timestamp) => {
    if (!timestamp) return "";

    const date = timestamp.toDate();
    const day = date.getDate().toString().padStart(2, "0");
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day} ${month} ${year} ${hours}:${minutes}`;
  };

  const handlePrint = () => {
    const oldHeight = printRef.current.style.height;
    const oldOverflowY = printRef.current.style.overflowY;

    // Set the height to the scroll height of the content
    printRef.current.style.height = printRef.current.scrollHeight + "px";
    printRef.current.style.overflowY = "visible";

    html2canvas(printRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("note.pdf");

      // Revert the height and overflowY properties
      printRef.current.style.height = oldHeight;
      printRef.current.style.overflowY = oldOverflowY;
    });
  };

  // const handlePrint = () => {
  //   html2canvas(printRef.current).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF("p", "mm", "a4");
  //     const imgProps = pdf.getImageProperties(imgData);
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  //     pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
  //     pdf.save("end_of_shift_note.pdf");
  //   });
  // };

  return (
    <>
      <style>
        {`
          @media print {
            #printableContent {
              height: auto !important;
              overflow-y: visible !important;
            }
          }
        `}
      </style>

      <div style={{ width: "auto", height: "100%" }}>
        <div className="line1-modal-percent">
          <div className="modal-title">VIEW END OF SHIFT NOTE</div>
          <CloseIcon
            onClick={props.closemodal}
            style={{
              marginRight: "16px",
              color: "rgb(214, 213, 213)",
              cursor: "pointer",
            }}
          />
        </div>

        <div
          id="printableContent"
          style={{
            width: "100%",
            marginTop: "10px",
            height: "75%",
            overflowY: "scroll",
            // overflowX: "hidden",
          }}
          ref={printRef}
        >
          <div className="top-title-view-modal">
            End of Shift note
            {/* - {formatDate(groupNoteStartDateTime) */}{" "}
            <span style={{ fontSize: "14px" }}> done by :</span>
            <span style={{ fontSize: "16px", fontWeight: "bold" }}>
              {" "}
              {props.doneBy}
            </span>
          </div>
          <div className="viewBlock">
            <div className="titleInput">Done by</div>
            <div className="contentInput">{props.doneBy}</div>
          </div>
          <div className="viewBlock">
            <div className="titleInput">Start Date and Time</div>
            <div className="contentInput">
              {/* {formatDate(props.shiftStart)} */}
              {props.shiftStart}
            </div>
          </div>
          <div className="viewBlock">
            <div className="titleInput">End Date and Time</div>
            <div className="contentInput">
              {/* {formatDate(props.shiftEnd)} */}
              {props.shiftEnd}
            </div>
          </div>
          <div className="viewBlock">
            <div className="titleInput">Note</div>
            <div className="contentInput">{props.noteContent}</div>
          </div>
          <div className="viewBlock">
            <div className="titleInput">Staff</div>
            <div className="contentInput">{props.staff}</div>
          </div>
        </div>

        <div className="line4-modal-percent">
          <Button
            onClick={props.closemodal}
            variant="contained"
            style={{ backgroundColor: "white", color: "black" }}
          >
            Close
          </Button>
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" style={{ marginLeft: "20px" }}>
                Print
              </Button>
            )}
            content={() => printRef.current}
            onAfterPrint={props.closemodal}
          />
          {/* <Button
          onClick={handlePrint}
          variant="contained"
          style={{ marginLeft: "20px" }}
        >
          Print
        </Button> */}
        </div>
      </div>
    </>
  );
}

export default ViewEndOfShiftNote;
