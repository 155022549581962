import { Alert, Tooltip } from "@mui/material";
import React from "react";

function InternetCheck() {
  const isOnline = navigator.onLine;
  return (
    <div style={{ marginTop: "5px" }}>
      {isOnline ? (
        <Tooltip title="Your internet connection is actually working.">
          <Alert
            style={{
              marginLeft: "0px",
              marginTop: "0px",
              backgroundColor: "transparent",
              color: "white",
              fontSize: "12px",
            }}
            severity="success"
          >
            Internet online
          </Alert>
        </Tooltip>
      ) : (
        <Tooltip title="Your internet connection is not working.">
          <Alert
            style={{
              marginLeft: "0px",
              marginTop: "0px",
              backgroundColor: "transparent",
              color: "white",
              fontSize: "12px",
            }}
            severity="error"
          >
            {" "}
            Internet offline
          </Alert>
        </Tooltip>
      )}
    </div>
  );
}

export default InternetCheck;
