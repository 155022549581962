import React, { useContext, useEffect, useState } from "react";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { format } from "date-fns";
import {
  Autocomplete,
  Button,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import TitleSearchComponent from "../Misc/TitleSearchComponent";
import ClientRow from "../Misc/ClientRow";
import Papa from "papaparse";
import TitleSearchComponentMaleFemale from "../Misc/TitleSearchComponentMaleFemale";
import TitleSearchComponentStatus from "../Misc/TitleSearchComponentStatus";
import TitleSearchComponentAddiction from "../Misc/TitleSearchComponentAddiction";
import TitleSearchComponentYesNo from "../Misc/TitleSearchComponentYesNo";
import TitleSearchComponentLenghtOfStay from "../Misc/TitleSearchComponentLenghtOfStay";
import ButtonDeleteRed from "../Misc/ButtonDeleteRed";
import Joyride, { ACTIONS, STATUS } from "react-joyride";
import { LanguageContext } from "../../context/LanguageContext";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import countries from "../../api/countries";
import nationalities from "../../api/nationalities";
import addictions from "../../api/addictions";
import intendedDurationOfStay from "../../api/intendedDurationOfStay";
import TitleExportMobileV2 from "../Misc/TitleExportMobileV2";
import ClientRowMobileV2 from "../Misc/ClientRowMobileV2";

function AllClientExportMobileV2(props) {
  const { translate } = useContext(LanguageContext);
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [clientDisplayLimit, setClientDisplayLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filteredClients, setFilteredClients] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
  const [progress, setProgress] = useState(0);
  const [run, setRun] = useState(false);
  const [showHelpButton, setShowHelpButton] = useState(true);
  const [applyFilters, setApplyFilters] = useState(false);

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  const resetFilters = () => {
    setFilters({
      status: "",
      firstName: "",
      lastName: "",
      gender: "",
      email: "",
      nationality: "",
      country: "",
      primaryAddiction: "",
      secondaryAddiction: "",
      recoveryRadio: "",
      googleReview: "",
      durationOfStay: "",
    });
  };

  const handleHelpClick = () => {
    setRun(true);
    setShowHelpButton(false); // Cache le bouton "HELP" après le clic
  };

  const handleFilterChange = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const [filters, setFilters] = useState({
    status: "",
    firstName: "",
    lastName: "",
    gender: "",
    email: "",
    nationality: "",
    country: "",
    primaryAddiction: "",
    secondaryAddiction: "",
    recoveryRadio: "",
    googleReview: "",
    durationOfStay: "",
  });

  const customStyles = {
    options: {
      zIndex: 10000,
    },
    tooltip: {
      backgroundColor: "#fff",
      color: "#000",
      animation: "fadeIn 0.3s ease-in-out",
    },
    tooltipContent: {
      textAlign: "justify",
      marginTop: "10px",
    },
    beacon: {
      offsetY: -50, // Ajustez cette valeur pour positionner le beacon au-dessus
    },
    buttonNext: {
      backgroundColor: "#1565c0",
      color: "#fff",
    },
    buttonBack: {
      color: "blue",
    },
    overlay: {
      position: "fixed", // Utilisez 'fixed' pour que l'overlay couvre toute la page
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Ajustez l'opacité de l'overlay
      zIndex: 1000, // Assurez-vous que l'overlay est au-dessus de tout le reste
    },
    spotlight: {
      // backgroundColor: "rgba(0, 0, 0, 0.7)",
      position: "absolute",
      borderRadius: 0,
    },
    // beacon: {
    //   placement: "top",
    // },
    beaconInner: {
      backgroundColor: "#1565c0", // Couleur du cercle intérieur
      borderColor: "#1565c0",
    },
    beaconOuter: {
      backgroundColor: "rgba(21, 101, 192, 0.5)", // Couleur de l'animation concentrique
      borderColor: "rgba(21, 101, 192, 0.5)",
    },
  };

  const steps = [
    {
      target: "#export-button",
      content:
        "Click here to export the filtered client data to a CSV file. The export process will begin immediately, and you will see a progress indicator within the button showing the export status.",
    },
    {
      target: "#filter-area",
      content:
        "Use this area to apply filters and refine the list of clients displayed. Adjust the filters to narrow down the list based on your criteria.",
    },
    {
      target: "#delete-button",
      content:
        "Click this button to reset all applied filters and show the complete list of clients again.",
    },
  ];

  const handleJoyrideCallback = (data) => {
    const { status, action } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      setShowHelpButton(true);
    } else if ([ACTIONS.CLOSE].includes(action)) {
      setRun(false);
      setShowHelpButton(true);
    }
  };

  function convertToCSV(data) {
    const csv = Papa.unparse(data, {
      header: true,
    });
    return csv;
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  // };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setApplyFilters(true); // Applique les filtres une fois le menu fermé
  };

  useEffect(() => {
    const clientCollection = collection(db, "clients");

    getDocs(clientCollection).then((snapshot) => {
      setTotalRecords(snapshot.size);

      const clientData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setClients(clientData);
      setIsLoading(false);
    });
  }, []);

  function formatDate(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    return format(date, "d MMMM yyyy");
  }

  useEffect(() => {
    if (!applyFilters) {
      // Si les filtres ne sont pas appliqués, on montre tous les clients
      setFilteredClients(clients);
    } else {
      const newFilteredClients = clients.filter((client) => {
        return (
          (filters.status === "" ||
            filters.status === "All" ||
            (client.clientStatus &&
              client.clientStatus
                .toLowerCase()
                .includes(filters.status.toLowerCase()))) &&
          (filters.firstName === "" ||
            (client.firstName &&
              client.firstName
                .toLowerCase()
                .includes(filters.firstName.toLowerCase()))) &&
          (filters.lastName === "" ||
            (client.lastName &&
              client.lastName
                .toLowerCase()
                .includes(filters.lastName.toLowerCase()))) &&
          (filters.email === "" ||
            (client.personalEmail &&
              client.personalEmail
                .toLowerCase()
                .includes(filters.email.toLowerCase()))) &&
          (filters.nationality === "" ||
            (client.nationality &&
              client.nationality
                .toLowerCase()
                .includes(filters.nationality.toLowerCase()))) &&
          (filters.country === "" ||
            (client.country &&
              client.country
                .toLowerCase()
                .includes(filters.country.toLowerCase()))) &&
          (filters.primaryAddiction === "(empty)"
            ? !client.primaryAddiction || client.primaryAddiction === ""
            : filters.primaryAddiction === "" ||
              (client.primaryAddiction &&
                client.primaryAddiction
                  .toLowerCase()
                  .includes(filters.primaryAddiction.toLowerCase()))) &&
          (filters.secondaryAddiction === "(empty)"
            ? !client.secondaryAddiction || client.secondaryAddiction === ""
            : filters.secondaryAddiction === "" ||
              (client.secondaryAddiction &&
                client.secondaryAddiction
                  .toLowerCase()
                  .includes(filters.secondaryAddiction.toLowerCase()))) &&
          (filters.durationOfStay === "(empty)"
            ? !client.durationOfStay || client.durationOfStay === ""
            : filters.durationOfStay === "" ||
              (client.durationOfStay &&
                client.durationOfStay
                  .toLowerCase()
                  .includes(filters.durationOfStay.toLowerCase()))) &&
          (filters.recoveryRadio === "(empty)"
            ? !client.recoveryRadio || client.recoveryRadio === ""
            : filters.recoveryRadio === "" ||
              (client.recoveryRadio &&
                client.recoveryRadio.toLowerCase() ===
                  filters.recoveryRadio.toLowerCase())) &&
          (filters.googleReview === "(empty)"
            ? !client.googleReview || client.googleReview === ""
            : filters.googleReview === "" ||
              (client.googleReview &&
                client.googleReview.toLowerCase() ===
                  filters.googleReview.toLowerCase())) &&
          (filters.gender === "(empty)"
            ? !client.gender || client.gender === ""
            : filters.gender === "" ||
              (client.gender &&
                client.gender.toLowerCase() === filters.gender.toLowerCase()))
        );
      });

      setFilteredClients(newFilteredClients);
      setApplyFilters(false); // Réinitialise l'état d'application des filtres
    }
  }, [applyFilters, clients, filters]);

  const countryOptions = countries.map((country) => country.textDropDownEn);
  const nationalityOptions = nationalities.map(
    (country) => country.textDropDownEn
  );
  const addictionOptions = addictions.map(
    (addiction) => addiction.textDropDownEn
  );

  const lenghtOfStayOptions = intendedDurationOfStay.map(
    (los) => los.textDropDownEn
  );

  async function fetchClientData() {
    let combinedData = [];
    const totalClients = filteredClients.length;

    const timestampFields = [
      "actualDischargeDate",
      "dateOfAdmission",
      "recoveryEndDate",
      "idd",
      "startDateMAtHome",
      "ccEndDate",
      "projectedResidentialDischargeDate",
      "dateOfCreation",
      "recoveryStartDate",
      "projectedProgramCompletionDate",
      "dob",
      "ccStartDate",
      "endDateMAtHome",
      "swot",
      "lastModification",
      "eta",
      "endDateAftercare",
      "recordedAt",
      "startDateAftercare",
      "DischargeReportDateSigned",
    ];

    const keyMap = {
      emergencyCheckbox0: "Acknowledgment Of Presence",
      emergencyCheckbox1: "Clinical Information",
      emergencyCheckbox2: "Psych Evaluation",
      emergencyCheckbox3: "Referral Letter",
      emergencyCheckbox4: "Lab Data",
      emergencyCheckbox5: "Progress Notes",
      emergencyCheckbox6: "Medical Records",
      emergencyCheckbox7: "Progress In Treatment",
      emergencyCheckbox8: "Psychosocial Assessment",
      emergencyCheckbox9: "Medication Info",
      emergencyCheckbox10: "Treatment Plan",
      emergencyCheckbox11: "Physical Assessment",
      emergencyCheckbox12: "Discharge Summary",
      emergencyCheckbox13: "Family History",
      emergencyCheckbox14: "Discharge Plan",
      emergencyCheckbox15: "Admission Discharge Dates",
      emergencyCheckboxContent15: "Other Info",
      emergencyCheckboxContent15Input: "Other Info Input",
    };

    for (let i = 0; i < totalClients; i++) {
      const client = filteredClients[i];
      const clientDoc = await getDoc(doc(db, "clients", client.id));
      let clientData = clientDoc.exists() ? clientDoc.data() : {};

      clientData = Object.keys(clientData).reduce((acc, key) => {
        const newKey = keyMap[key] || key;
        acc[newKey] = timestampFields.includes(key)
          ? formatDate(clientData[key])
          : clientData[key];
        return acc;
      }, {});

      const assessmentDoc = await getDoc(
        doc(db, "preAdmissionAssessments", client.id)
      );
      let assessmentData = assessmentDoc.exists() ? assessmentDoc.data() : {};

      assessmentData = Object.keys(assessmentData).reduce((acc, key) => {
        const newKey = keyMap[key] || key;
        acc[newKey] = timestampFields.includes(key)
          ? formatDate(assessmentData[key])
          : assessmentData[key];
        return acc;
      }, {});

      const combinedClientData = {
        ...clientData,
        ...assessmentData,
      };

      combinedData.push(combinedClientData);

      setProgress(((i + 1) / totalClients) * 100);
    }

    return combinedData;
  }

  const handleExport = async () => {
    setIsExporting(true);
    setProgress(0);
    const data = await fetchClientData();
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "exported_data.csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
    setIsExporting(false);
  };

  return (
    <div>
      <Joyride
        steps={steps}
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
        run={run}
        callback={handleJoyrideCallback}
        disableScrolling={true}
        disableBeacon={true}
        styles={customStyles}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
          paddingLeft: "20px",
          paddingRight: "10px",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "auto",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            marginBottom: "0px",
          }}
        >
          <Button
            id="filters-button"
            aria-controls={openMenu ? "filters-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
            variant="outlined"
            color="primary"
            disableElevation
            onClick={handleMenuClick}
            endIcon={<FilterListIcon />}
            sx={{ fontSize: "12px" }}
          >
            {translate("Filters")}
          </Button>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <StyledMenu
              id="filters-menu"
              MenuListProps={{
                "aria-labelledby": "filters-button",
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
              keepMounted={true}
            >
              <MenuItem>
                <TextField
                  label={translate("FirstName")}
                  variant="outlined"
                  style={{ width: 300, marginBottom: 10 }}
                  value={filters.firstName}
                  onChange={(e) =>
                    setFilters({ ...filters, firstName: e.target.value })
                  }
                />
              </MenuItem>

              <MenuItem>
                <TextField
                  label={translate("LastName2")}
                  variant="outlined"
                  style={{ width: 300, marginBottom: 10 }}
                  value={filters.lastName}
                  onChange={(e) =>
                    setFilters({ ...filters, lastName: e.target.value })
                  }
                />
              </MenuItem>

              <MenuItem>
                <Autocomplete
                  options={["Male", "Female", "(empty)"]}
                  getOptionLabel={(option) => option}
                  style={{ width: 300, marginBottom: 10 }}
                  value={filters.gender}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translate("Gender")}
                      variant="outlined"
                    />
                  )}
                  onChange={(event, value) =>
                    setFilters({ ...filters, gender: value })
                  }
                />
              </MenuItem>

              <MenuItem>
                <Autocomplete
                  options={[
                    "In House",
                    "Aftercare",
                    "Incoming",
                    "Inactive",
                    "Clinical Assessment",
                    "Potential",
                    "All",
                  ]}
                  getOptionLabel={(option) => option}
                  style={{ width: 300, marginBottom: 10 }}
                  value={filters.status}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translate("Status")}
                      variant="outlined"
                    />
                  )}
                  onChange={(event, value) =>
                    setFilters({ ...filters, status: value })
                  }
                />
              </MenuItem>

              <MenuItem>
                <Autocomplete
                  options={nationalityOptions}
                  getOptionLabel={(option) => option}
                  style={{ width: 300, marginBottom: 10 }}
                  value={filters.nationality}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translate("Nationality")}
                      variant="outlined"
                    />
                  )}
                  onChange={(event, value) =>
                    setFilters({ ...filters, nationality: value })
                  }
                />
              </MenuItem>

              <MenuItem>
                <Autocomplete
                  options={countryOptions}
                  getOptionLabel={(option) => option}
                  style={{ width: 300, marginBottom: 10 }}
                  value={filters.country}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translate("Country")}
                      variant="outlined"
                    />
                  )}
                  onChange={(event, value) =>
                    setFilters({ ...filters, country: value })
                  }
                />
              </MenuItem>

              <MenuItem>
                <Autocomplete
                  options={addictionOptions}
                  getOptionLabel={(option) => option}
                  style={{ width: 300, marginBottom: 10 }}
                  value={filters.primaryAddiction}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translate("PrimaryAddiction")}
                      variant="outlined"
                    />
                  )}
                  onChange={(event, value) =>
                    setFilters({ ...filters, primaryAddiction: value })
                  }
                />
              </MenuItem>

              <MenuItem>
                <Autocomplete
                  options={addictionOptions}
                  getOptionLabel={(option) => option}
                  style={{ width: 300, marginBottom: 10 }}
                  value={filters.secondaryAddiction}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translate("SecondaryAddiction")}
                      variant="outlined"
                    />
                  )}
                  onChange={(event, value) =>
                    setFilters({ ...filters, secondaryAddiction: value })
                  }
                />
              </MenuItem>

              <MenuItem>
                <Autocomplete
                  options={lenghtOfStayOptions}
                  getOptionLabel={(option) => option}
                  style={{ width: 300, marginBottom: 10 }}
                  value={filters.durationOfStay}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translate("LengthOfStay")}
                      variant="outlined"
                    />
                  )}
                  onChange={(event, value) =>
                    setFilters({ ...filters, durationOfStay: value })
                  }
                />
              </MenuItem>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <MenuItem>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => resetFilters()}
                    sx={{ fontSize: "10px" }}
                  >
                    {translate("ResetFilters")}
                  </Button>
                </MenuItem>

                <MenuItem>
                  <Button
                    variant="contained" // Plein
                    color="primary" // Bleu
                    onClick={() => setApplyFilters(true)}
                    sx={{ fontSize: "10px" }}
                  >
                    {translate("ApplyFilters")}
                  </Button>
                </MenuItem>
              </div>
            </StyledMenu>
          </div>
        </div>
        <div id="export-button">
          <Button
            variant="outlined"
            onClick={handleExport}
            disabled={isExporting}
            style={{ position: "relative" }}
            sx={{ fontSize: "12px" }}
          >
            {isExporting ? (
              <>
                <CircularProgress
                  variant="determinate"
                  value={progress}
                  size={24}
                  thickness={4}
                  sx={{
                    color: "white",
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                />
                <span style={{ marginLeft: "35px" }}>
                  Exporting... {Math.round(progress)}%
                </span>
              </>
            ) : (
              `${translate("ExportData2")} : ${filteredClients?.length} item(s)`
            )}
          </Button>
        </div>
      </div>

      <div
        style={{
          paddingLeft: "20px",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          marginTop: "20px",
        }}
      >
        <TitleExportMobileV2 />
      </div>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "500px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div
          className="client-widgets-global-container"
          style={{
            paddingLeft: "0px",
            display: "flex",
            flexDirection: "column",
            paddingTop: "5px",
            marginBottom: "20px",
            width: "100%",
          }}
        >
          {filteredClients.slice(0, clientDisplayLimit).map((client) => (
            <ClientRowMobileV2
              key={client.id}
              clientStatus={client.clientStatus}
              firstName={client.firstName}
              lastName={client.lastName}
              gender={client.gender}
              email={client.email}
              nationality={client.nationality}
              country={client.country}
              primaryAddiction={client.primaryAddiction}
              secondaryAddiction={client.secondaryAddiction}
              inActiveRecovery={client.recoveryRadio}
              googleReviewDone={client.googleReview}
              // inActiveRecovery={client.recoveryRadio ? "Yes" : "No"}
              // googleReviewDone={client.googleReview ? "Yes" : "No"}
              personalEmail={client.personalEmail}
              durationOfStay={client.durationOfStay}
              idClient={client.id}
              clientData={client}
            />
          ))}
        </div>
      )}

      {filteredClients.length > 10 && (
        <div
          className="seemore-container-mobile-v2"
          // style={{ backgroundColor: "lightpink" }}
        >
          {clientDisplayLimit < filteredClients.length && (
            <Button
              onClick={() => setClientDisplayLimit(clientDisplayLimit + 10)}
            >
              {translate("SeeMore")}
            </Button>
          )}
          {clientDisplayLimit > 10 && (
            <Button
              onClick={() => setClientDisplayLimit(clientDisplayLimit - 10)}
            >
              {translate("SeeLess")}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

export default AllClientExportMobileV2;
