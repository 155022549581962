import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import DatePickerWithTimeComponent from "../DatePickerWithTimeComponent";
import { useAuth } from "../../contexts/AuthContext";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { showCustomToast } from "../Misc/CustomToast";
import UploaderDocForNote from "../Updloader/UploaderDocForNote";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import TopTitleFormComponent from "../forms/TopTitleFormComponent";

function AddMedicalNote(props) {
  const { currentUser } = useAuth();

  //Tous les states des données récupérées par client

  const [isLoading, setIsLoading] = useState(false);

  const [medicalNote, setMedicalNote] = useState("");
  const [medicalNoteDateTime, setMedicalNoteDateTime] = useState(new Date());
  const [files, setFiles] = useState([]);

  const handleMedicalNoteChange = (event) => {
    setMedicalNote(event.target.value);
  };

  const handleDateTimeChange = (event) => {
    setMedicalNoteDateTime(event);
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length <= 10) {
      setFiles(event.target.files);
    } else {
      showCustomToast("You can only upload up to 10 files.", "error");
    }
  };

  const uploadFilesAndGetUrls = async (docId) => {
    const storage = getStorage();
    const fileUrls = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const filePath = `Documents/medicalNotes/${docId}/${file.name}`;
      const storageRef = ref(storage, filePath);

      await uploadBytes(storageRef, file);
      const fileUrl = await getDownloadURL(storageRef);
      fileUrls.push({ url: fileUrl, name: file.name });
    }

    return fileUrls;
  };

  const handleAddMedicalNote = async (note, clientID, effectiveDateTime) => {
    const noteData = {
      noteType: "medicalNote",
      noteContent: note,
      clientId: clientID,
      createdAt: serverTimestamp(),
      effectiveDateTime: effectiveDateTime,
      doneBy: currentUser.displayName,
      clientFirstName: props.firstName,
      clientLastName: props.lastName,
      clientProfilePicture: props.profilePicture || "",
    };

    if (medicalNote && medicalNoteDateTime) {
      setIsLoading(true);
      try {
        const docRef = await addDoc(collection(db, "notes"), noteData);
        const fileUrls = await uploadFilesAndGetUrls(docRef.id);
        await updateDoc(doc(db, "notes", docRef.id), {
          fileUrls: fileUrls,
        });
        showCustomToast(`Medical note successfully added!`, "success");
        setIsLoading(false);
        props.closemodal();
      } catch (error) {
        console.log("error adding note :", error);
        showCustomToast(`Problem when adding note`, "error");
      }
    } else {
      showCustomToast(
        `Some mandatory fields are empty. Please check.`,
        "error"
      );
    }
  };

  const handleSubmit = () => {
    handleAddMedicalNote(medicalNote, props.clientId, medicalNoteDateTime);
  };

  return (
    <>
      <div
        className="inputAndLabelModalContainerTitle2"
        style={{ width: "auto", height: "100%" }}
      >
        <div className="line1-modal-percent">
          <TopTitleFormComponent
            title="ADD MEDICAL NOTE"
            closemodal={props.closemodal}
          />
        </div>

        <div
          // style={{ marginTop: "60px", height: "550px", overflowY: "scroll" }}
          style={{
            width: "100%",
            marginTop: "10px",
            height: "80%",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <div
            className="line2-column-modal"
            style={{
              paddingLeft: "10px",
              // backgroundColor: "pink",
            }}
          >
            <div className="label-textfield" style={{ paddingLeft: "10px" }}>
              Note content
            </div>
            <TextField
              value={medicalNote}
              onChange={handleMedicalNoteChange}
              style={{
                width: "95%",
                borderRadius: "5px",
                backgroundColor: "#f2eeef",
              }}
              placeholder="Type medical note here"
              multiline
              rows={14}
            />
          </div>
          <div
            className="line3-modal-nopad"
            style={{
              // marginLeft: "20px",
              paddingLeft: "20px",
              marginBottom: "20px",
              paddingTop: "10px",
            }}
          >
            <DatePickerWithTimeComponent
              title="Effective date and time"
              value={medicalNoteDateTime}
              onChange={handleDateTimeChange}
            />
          </div>

          <UploaderDocForNote
            handleFileChange={handleFileChange}
            files={files}
          />
        </div>
        <div className="line4-modal-percent-bis">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
              onClick={props.closemodal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                    }
              }
              disabled={isLoading}
              onClick={handleSubmit}
            >
              {isLoading ? "ADDING NOTE..." : "ADD NOTE"}
            </Button>
          </>
        </div>
      </div>
    </>
  );
}

export default AddMedicalNote;
