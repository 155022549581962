import React from "react";
import "../css/dashboard.css";
import UnderConstruction from "../component/Misc/UnderConstruction";
import TopTitleBannerMobile from "../component/TopTitleBannerMobile";

export default function AccountingContentMobile() {
  return (
    <div className="right-container-mobile">
      <TopTitleBannerMobile title={"Accounting dashboard"} />
      <UnderConstruction />
    </div>
  );
}
