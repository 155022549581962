import React, { useContext, useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  Timestamp,
} from "firebase/firestore";
import {
  Button,
  Slide,
  Box,
  IconButton,
  CircularProgress,
  TextField,
  Select,
  MenuItem,
  Modal,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import AlertLine from "../Lines/AlertLine";
import TitleAlerts from "../Titles/TitleAlerts";
import { Add } from "@mui/icons-material";
import { LanguageContext } from "../../context/LanguageContext";
import BinSyliszed from "../../images/binstyliszed.png";
import { showCustomToast } from "../Misc/CustomToast";

function Alerts(props) {
  const { translate } = useContext(LanguageContext);
  const clientID = props.clientId;
  const [isDeleting, setIsDeleting] = useState(false);
  const { currentUser } = useAuth();
  const [alerts, setAlerts] = useState([]);
  const [openSlide, setOpenSlide] = useState(false);
  const [editAlert, setEditAlert] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [alertToDelete, setAlertToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const initialAlertData = {
    type: "",
    content: "",
    createdBy: currentUser.displayName,
    modifiedBy: "",
    isActive: true,
    clientID,
    createdAt: Timestamp.now(),
    clientIdentifier: props.clientIdentifier,
    firstName: props.firstName,
    lastName: props.lastName,
  };

  const [alertData, setAlertData] = useState(initialAlertData);

  const getAlertTypeText = (type) => {
    switch (type) {
      case 1:
        return "Reminder";
      case 2:
        return "Allergy";
      case 3:
        return "Alert";
      default:
        return "Unknown";
    }
  };

  useEffect(() => {
    if (clientID) {
      const alertsRef = collection(db, "clients", clientID, "alerts");
      const q = query(alertsRef, where("clientID", "==", clientID));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const alertsArray = [];
        querySnapshot.forEach((doc) => {
          alertsArray.push({ id: doc.id, ...doc.data() });
        });
        setAlerts(alertsArray);
      });

      return () => unsubscribe();
    }
  }, [clientID]);

  const handleSlideOpen = (alert = null) => {
    if (alert) {
      setEditAlert(alert);
      setAlertData(alert);
    } else {
      setEditAlert(null);
      setAlertData(initialAlertData);
    }
    setOpenSlide(true);
  };

  const handleSlideClose = () => {
    setOpenSlide(false);
    setEditAlert(null);
  };

  const handleSave = async () => {
    if (!alertData.type || !alertData.content) {
      showCustomToast(translate("PleaseSelectTypeAndContent"), "error");
      return;
    }

    setIsLoading(true);
    const alertsRef = collection(db, "clients", clientID, "alerts");
    const newAlert = {
      ...alertData,
      typeText: getAlertTypeText(alertData.type),
      modifiedBy: editAlert ? currentUser.displayName : "",
    };

    try {
      if (editAlert) {
        const alertRef = doc(alertsRef, editAlert.id);
        await updateDoc(alertRef, newAlert);
      } else {
        await addDoc(alertsRef, newAlert);
      }
      handleSlideClose();
    } catch (error) {
      console.error("Error saving alert:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteOpen = (alert) => {
    setAlertToDelete(alert);
    setOpenDeleteDialog(true);
  };

  const handleDeleteClose = () => {
    setOpenDeleteDialog(false);
  };

  // const handleDeleteConfirm = async () => {
  //   if (alertToDelete) {
  //     await handleDelete(alertToDelete.id);
  //   }
  //   handleDeleteClose();
  // };
  const handleDeleteConfirm = async () => {
    if (alertToDelete) {
      setIsDeleting(true); // Définir isDeleting à true au début de la suppression
      await handleDelete(alertToDelete.id);
      setIsDeleting(false); // Réinitialiser isDeleting à false après la suppression
    }
    handleDeleteClose();
  };

  const handleDelete = async (id) => {
    const alertRef = doc(db, "clients", clientID, "alerts", id);
    await deleteDoc(alertRef);
  };

  const renderAlerts = () =>
    alerts
      .sort(
        (a, b) =>
          Timestamp.fromMillis(b.createdAt.seconds * 1000).toDate() -
          Timestamp.fromMillis(a.createdAt.seconds * 1000).toDate()
      )
      .map((alert, index) => {
        let dateObject;
        if (alert.createdAt && alert.createdAt instanceof Timestamp) {
          dateObject = Timestamp.fromMillis(
            alert.createdAt.seconds * 1000
          ).toDate();
        } else {
          dateObject = new Date(alert.createdAt);
        }

        const formattedDate =
          dateObject.toLocaleDateString("fr-FR") +
          " " +
          dateObject.toLocaleTimeString("fr-FR");

        return (
          <AlertLine
            key={index}
            typeOfAlert={alert.type}
            notedBy={alert.createdBy}
            date={formattedDate}
            content={alert.content}
            onEdit={() => handleSlideOpen(alert)}
            onDelete={() => handleDeleteOpen(alert)}
          />
        );
      });

  return (
    <div
      className="tab-container-white-v2"
      style={{
        width: "100%",
        paddingLeft: "0px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 10,
          width: "100%",
          justifyContent: "space-between",
          paddingBottom: "10px",
        }}
      >
        <Button
          id="add-alerts"
          variant="outlined"
          color="primary"
          startIcon={<Add />}
          onClick={() => handleSlideOpen()}
          style={{ marginRight: alerts.length > 0 ? 10 : 0 }}
        >
          {translate("AddAlert")}
        </Button>
      </div>

      {alerts.length > 0 && <TitleAlerts />}

      <Modal
        open={openDeleteDialog}
        onClose={handleDeleteClose}
        aria-labelledby="delete-confirmation-dialog"
        aria-describedby="confirm-deletion-of-alert"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px", // Ajoute un borderRadius de 15px
            border: "none", // Supprime la bordure noire
          }}
        >
          <div
            style={{
              width: "100%",
              height: "70%",
              borderTopRightRadius: "10px",
              borderTopLeftRadius: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <img src={BinSyliszed} style={{ width: "60px" }} alt="Delete" />
            <div className="line2-column-modal">
              <div className="confirmation-delete-bis">
                {translate("ConfirmDeleteAlert")}
              </div>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              height: "30%",
              display: "flex",
              justifyContent: "center",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            <>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  fontSize: "12px",
                }}
                onClick={handleDeleteClose}
              >
                {translate("Cancel")}
              </Button>

              <Button
                variant="contained"
                style={{
                  backgroundColor: isDeleting ? "grey" : "red", // Griser le bouton lors du chargement
                  color: isDeleting ? "grey" : "white",
                  marginLeft: "15px",
                  fontSize: "12px",
                  width: "120px",
                }}
                disabled={isDeleting} // Désactiver le bouton lors du chargement
                onClick={handleDeleteConfirm}
              >
                {isDeleting ? (
                  <CircularProgress size={24} color="inherit" /> // Ajouter l'animation de chargement
                ) : (
                  translate("Delete")
                )}
              </Button>
            </>
          </div>
        </Box>
      </Modal>

      <div style={{ width: "100%" }}>{renderAlerts()}</div>

      <Slide direction="left" in={openSlide}>
        <Box
          sx={{
            position: "fixed",
            top: "5%",
            right: 0,
            width: "30%",
            height: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            marginRight: "15px",
            pl: 0,
            pr: 0,
            borderRadius: "15px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
              borderBottom: "1px solid lightgray",
            }}
          >
            <div
              style={{
                margin: 0,
                paddingLeft: "35px",
                paddingTop: "10px",
                paddingBottom: "10px",
                fontSize: "22px",
                fontWeight: "700",
              }}
            >
              {editAlert ? translate("EditAlert") : translate("AddAlert")}
            </div>
            <div style={{ paddingRight: "15px" }}>
              <IconButton onClick={handleSlideClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </Box>

          <Box sx={{ flexGrow: 1, overflowY: "auto", mt: 0, pl: 2, pr: 2 }}>
            <div style={{ paddingLeft: "15px", paddingTop: "20px" }}>
              <Select
                autoFocus
                margin="dense"
                id="type"
                label="Type"
                value={alertData.type || ""}
                onChange={(e) =>
                  setAlertData({ ...alertData, type: e.target.value })
                }
                style={{ minWidth: "200px" }}
                renderValue={(value) =>
                  value ? getAlertTypeText(value) : translate("Choose")
                }
              >
                {/* <MenuItem value="" disabled>
                  Choose
                </MenuItem> */}
                <MenuItem value={1}>Reminders</MenuItem>
                <MenuItem value={2}>Allergy</MenuItem>
                <MenuItem value={3}>Alert</MenuItem>
              </Select>
            </div>

            <div style={{ paddingLeft: "15px", paddingTop: "20px" }}>
              <TextField
                margin="dense"
                id="content"
                label={translate("Content")}
                type="text"
                fullWidth
                multiline
                rows={6}
                value={alertData.content}
                onChange={(e) =>
                  setAlertData({ ...alertData, content: e.target.value })
                }
              />
            </div>
            {editAlert && (
              <>
                {/* <TextField
                  margin="dense"
                  id="createdBy"
                  label="Added By"
                  type="text"
                  fullWidth
                  value={new Date(editAlert.createdAt).toLocaleString()}
                  InputProps={{
                    readOnly: true,
                  }}
                /> */}
                <div style={{ paddingLeft: "15px", paddingTop: "20px" }}>
                  <TextField
                    margin="dense"
                    id="createdBy"
                    label="Created By"
                    type="text"
                    fullWidth
                    value={editAlert.createdBy}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
              </>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              borderTop: "1px solid lightgray",
              padding: "10px 20px",
              mt: "auto",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              style={{ marginRight: "10px" }}
              onClick={handleSlideClose}
            >
              {translate("Cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : translate("Save")}
            </Button>
          </Box>
        </Box>
      </Slide>
    </div>
  );
}

export default Alerts;
