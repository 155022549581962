import React, { useContext, useEffect, useState } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { format } from "date-fns";
import MetricsWidget from "../MetricsWidget";
import { useNavigate } from "react-router-dom";
import { Pie, Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  GeoFeature,
} from "chart.js";
import MetricsWidgetV2 from "../MetricsWidgetV2";
import { LanguageContext } from "../../context/LanguageContext";
import MetricsWidgetV2Mobile from "../MetricsWidgetV2Mobile";
import MetricsWidgetV2MobileV2 from "../MetricsWidgetV2MobileV2";

// ChartJS.register(
//   ChartDataLabels,
//   ArcElement,
//   Tooltip,
//   Legend,
//   BarElement,
//   CategoryScale,
//   LinearScale
// );

ChartJS.register(
  ChartDataLabels,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

function AllClientAvailabilityMobileV2(props) {
  const { translate } = useContext(LanguageContext);
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [, setTotalRecords] = useState(0);
  const [, setClientsIncoming] = useState([]);
  const [, setIsLoadingIncoming] = useState(true);
  const [, setTotalRecordsIncoming] = useState(0);
  const [nextDischargeClient, setNextDischargeClient] = useState(null);
  const [addictionTypes, setAddictionTypes] = useState({});
  const [clientsByTherapist, setClientsByTherapist] = useState({});
  const [averageTreatmentDuration, setAverageTreatmentDuration] = useState(0);
  const [bedOccupancyRate, setBedOccupancyRate] = useState(0);
  const [, setAllClients] = useState([]);
  const [allAddictionTypes, setAllAddictionTypes] = useState({});
  const TOTAL_BEDS = 25;

  const calculateAddictionTypes = (clients) => {
    const addictionCounts = clients.reduce((acc, client) => {
      const addiction = client.primaryAddiction;
      if (addiction) {
        acc[addiction] = (acc[addiction] || 0) + 1;
      }
      return acc;
    }, {});
    setAddictionTypes(addictionCounts);
  };

  const calculateAllAddictionTypes = (clients) => {
    const addictionCounts = clients.reduce((acc, client) => {
      const addiction = client.primaryAddiction;
      if (addiction) {
        acc[addiction] = (acc[addiction] || 0) + 1;
      }
      return acc;
    }, {});
    setAllAddictionTypes(addictionCounts);
  };

  const calculateClientsByTherapist = (clients) => {
    const therapistCounts = clients.reduce((acc, client) => {
      const therapist = client.selectedTherapist;
      if (therapist) {
        acc[therapist] = (acc[therapist] || 0) + 1;
      }
      return acc;
    }, {});
    setClientsByTherapist(therapistCounts);
  };

  useEffect(() => {
    const fetchClients = async () => {
      setIsLoading(true);
      const clientsSnapshot = await getDocs(collection(db, "clients"));
      const allClientsData = clientsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setAllClients(allClientsData);

      const inHouseClients = allClientsData.filter(
        (client) => client.clientStatus === "In House"
      );
      setClients(inHouseClients);
      setTotalRecords(inHouseClients.length);

      calculateAddictionTypes(inHouseClients);
      calculateAllAddictionTypes(allClientsData);
      calculateClientsByTherapist(inHouseClients);
      setAverageTreatmentDuration(
        calculateAverageTreatmentDuration(allClientsData)
      ); // Calculer pour tous les clients
      setBedOccupancyRate(
        calculateBedOccupancyRate(allClientsData, TOTAL_BEDS)
      ); // Calculer pour tous les clients

      setIsLoading(false);
    };

    fetchClients();
  }, []);

  // Assume you have a `clients` state with all clients data

  const calculateAverageTreatmentDuration = (clients) => {
    if (clients.length === 0) return 0;
    const totalDuration = clients.reduce((sum, client) => {
      const duration = client.durationOfStay;
      return sum + (duration ? parseInt(duration) : 0);
    }, 0);
    return totalDuration / clients.length;
  };

  // const calculateBedOccupancyRate = (clients, totalBeds) => {
  //   const occupiedBeds = clients.length;
  //   return (occupiedBeds / totalBeds) * 100;
  // };

  const calculateBedOccupancyRate = (clients, totalBeds) => {
    const occupiedBeds = clients.length;
    return (occupiedBeds / totalBeds) * 100;
  };

  const addictionColors = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#9966FF",
    "#FF9F40",
    "#F7464A",
    "#46BFBD",
    "#FDB45C",
    "#949FB1",
    "#4D5360",
    "#AC64AD",
    "#DA92DB",
    "#A4DF75",
  ];

  const addictionChartData = {
    labels: Object.keys(addictionTypes),
    datasets: [
      {
        label: "Addiction Types",
        data: Object.values(addictionTypes),
        backgroundColor: addictionColors.slice(
          0,
          Object.keys(addictionTypes).length
        ),
      },
    ],
  };

  const addictionChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || "";
            const value = context.raw || 0;
            return `${label}: ${value}`;
          },
        },
      },
      datalabels: {
        formatter: (value, context) => {
          const label = context.chart.data.labels[context.dataIndex];
          const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
          const percentage = ((value / total) * 100).toFixed(0) + "%";
          return `${label}: ${percentage}`;
        },
        color: "#fff",
        font: {
          weight: "bold",
          size: 14, // Taille de la police
        },
      },
    },
  };

  const therapistChartData = {
    labels: Object.keys(clientsByTherapist),
    datasets: [
      {
        label: "Clients by Therapist",
        data: Object.values(clientsByTherapist),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
        ],
      },
    ],
  };

  const therapistChartOptions = {
    indexAxis: "y", // Change to horizontal bar chart
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false, // Désactiver l'affichage de la légende
      },
      datalabels: {
        anchor: "end",
        align: "start",
        formatter: (value, context) => {
          return `${value}`;
        },
        color: "#000",
        font: {
          weight: "bold",
          size: 12,
        },
      },
    },
  };

  const allAddictionChartData = {
    labels: Object.keys(allAddictionTypes),
    datasets: [
      {
        label: "Addiction Types",
        data: Object.values(allAddictionTypes),
        backgroundColor: addictionColors.slice(
          0,
          Object.keys(allAddictionTypes).length
        ),
      },
    ],
  };

  const allAddictionChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || "";
            const value = context.raw || 0;
            return `${label}: ${value}`;
          },
        },
      },
      datalabels: {
        formatter: (value, context) => {
          const label = context.chart.data.labels[context.dataIndex];
          const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
          const percentage = ((value / total) * 100).toFixed(0) + "%";
          return `${label}: ${percentage}`;
        },
        color: "#fff",
        font: {
          weight: "bold",
          size: 14,
        },
      },
    },
  };

  useEffect(() => {
    setIsLoadingIncoming(true);

    const clientQuery = query(
      collection(db, "clients"),
      where("clientStatus", "==", "Incoming")
    );

    const unsubscribe = onSnapshot(
      clientQuery,
      async (snapshot) => {
        const clientPromises = snapshot.docs.map(async (docList) => {
          const clientData = docList.data();
          clientData.id = docList.id;

          // Asynchronously fetch pre-admission assessments
          const assessmentDocRef = doc(
            db,
            "preAdmissionAssessments",
            clientData.id
          );
          const assessmentSnapshot = await getDoc(assessmentDocRef);
          if (assessmentSnapshot.exists()) {
            const assessmentData = assessmentSnapshot.data();
            return { ...clientData, ...assessmentData }; // merge assessment data into clientData
          } else {
            return clientData;
          }
        });

        // Wait for all clients and their dependent data to be fetched
        const clientsIncoming = await Promise.all(clientPromises);
        setClientsIncoming(clientsIncoming);
        setTotalRecordsIncoming(clientsIncoming.length);
        setIsLoadingIncoming(false);
      },
      (error) => {
        console.error("Failed to fetch Incoming clients:", error);
        setIsLoadingIncoming(false);
      }
    );

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  useEffect(() => {
    if (clients.length > 0) {
      const now = new Date();
      const closestClient = clients.reduce((closest, client) => {
        // Convertir le Timestamp Firestore en JavaScript Date
        const dischargeDate = new Date(
          client.projectedResidentialDischargeDate?.seconds * 1000
        );

        if (isNaN(dischargeDate.getTime()) || dischargeDate <= now) {
          return closest; // Ignore invalid or past dates
        }
        if (
          closest === null ||
          dischargeDate <
            new Date(closest.projectedResidentialDischargeDate?.seconds * 1000)
        ) {
          return client;
        }
        return closest;
      }, null);

      setNextDischargeClient(closestClient);
    }
  }, [clients]);

  function formatDate(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    return format(date, "d MMMM yyyy");
  }

  function formatDate2(date) {
    if (!date || isNaN(date.getTime())) {
      console.log("Invalid date provided to formatDate");
      return "Invalid date"; // Retourner une chaîne indiquant que la date est invalide
    }
    try {
      return format(date, "dd MMMM yyyy"); // Format correct avec date-fns
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Formatting error";
    }
  }

  return (
    <div
      style={{
        width: "auto",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
      <div
        style={{
          width: "auto",
          height: "auto",
          display: "flex",
          flexDirection: "row",
          // justifyContent: "space-between",
          paddingLeft: "5%",
          paddingRight: "5%",
        }}
      >
        <div
          style={{
            marginRight: "40px",
            display: "flex",
            flexDirection: "row",
            width: "auto",
            cursor: "pointer",
          }}
          // onClick={() => handleWidgetClick("In house")}
        >
          <MetricsWidgetV2MobileV2
            title={translate("InHouseClients")}
            value={clients.length.toString()}
            title2={
              clients.length > 1 ? translate("Clients") : translate("Client")
            }
            textColor="black"
            textColor2="#7a8199"
            iconName="People"
            isLoading={isLoading.inHouse}
          />
        </div>

        <MetricsWidgetV2MobileV2
          title={translate("BedOccRate")}
          value={`${bedOccupancyRate.toFixed(0)}%`}
          // title2={`on a total of ${TOTAL_BEDS} rooms`}
          textColor="black"
          textColor2="#7a8199"
          iconName="People"
          isLoading={isLoading.inHouse}
        />

        {/* <MetricsWidgetV2MobileV2
          title={translate("AvTreatmentDur")}
          value={averageTreatmentDuration.toFixed(0)}
          // title2={`on a total of ${TOTAL_BEDS} rooms`}
          textColor="black"
          textColor2="#7a8199"
          iconName="People"
          isLoading={isLoading.inHouse}
          title2={translate("Day")}
        /> */}
      </div>

      <div
        style={{
          marginTop: "0px",
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          paddingLeft: "5%",
          paddingRight: "5%",
        }}
      >
        <div
          style={{
            width: "100%",
            textAlign: "center",
            border: "1px solid lightgrey",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "15px",
            paddingBottom: "15px",
            borderRadius: "15px",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              color: "#7a8199",
              fontSize: "15px",
              fontWeight: "600",
              paddingBottom: "15px",
              textAlign: "left",
            }}
          >
            {translate("ClientsByAddictionTypeInhouse")}
          </div>
          <Pie data={addictionChartData} options={addictionChartOptions} />
        </div>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            border: "1px solid lightgrey",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "15px",
            paddingBottom: "15px",
            borderRadius: "15px",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              color: "#7a8199",
              fontSize: "15px",
              fontWeight: "600",
              paddingBottom: "15px",
              textAlign: "left",
            }}
          >
            {translate("ClientsByAddictionTypeAllClients")}
          </div>
          <Pie
            data={allAddictionChartData}
            options={allAddictionChartOptions}
          />
        </div>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            border: "1px solid lightgrey",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "15px",
            paddingBottom: "15px",
            borderRadius: "15px",
            marginBottom: "40px",
          }}
        >
          <div
            style={{
              color: "#7a8199",
              fontSize: "15px",
              fontWeight: "600",
              paddingBottom: "15px",
              textAlign: "left",
            }}
          >
            {translate("ClientsByTherapistInhouse")}
          </div>
          <Bar data={therapistChartData} options={therapistChartOptions} />
        </div>
      </div>
    </div>
  );
}

export default AllClientAvailabilityMobileV2;
