import React, { useContext, useState } from "react";
import { Box, Button, Modal, Slide } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import AddButtonRound from "../Buttons/AddButtonRound";
import AddObjectiveForm from "./AddObjectiveForm";
import TopTitleFormComponent from "./TopTitleFormComponent";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { toast } from "react-toastify";
import { useAuth } from "../../contexts/AuthContext";
import TextFieldComponentV2 from "../TextFieldComponentV2";
import { LanguageContext } from "../../context/LanguageContext";
import TopTitleFormComponentMobileV2 from "./TopTitleFormComponentMobileV2";
import TextFieldComponentV2Mobile from "../TextFieldComponentV2Mobile";
import AddButtonRoundMobileV4 from "../Buttons/AddButtonRoundMobileV4";
import AddObjectiveFormMobileV2 from "./AddObjectiveFormMobileV2";

function AddTreatmentPlanMobileV2(props) {
  const { translate } = useContext(LanguageContext);
  const { currentUser } = useAuth();
  //Tous les states des données récupérées par client

  const styleModalGeneral = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "80%",
    borderRadius: 5,
    // overflowY: "scroll",
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const [isLoading, setIsloading] = useState(false);
  const [presentingIssues, setPresentingIssues] = useState("");
  const [longTermGoals, setLongTermGoals] = useState("");
  const [openObjectiveForm, setOpenObjectiveForm] = useState(false);
  const [objectives, setObjectives] = useState([]);

  const handlePresentingIssuesChange = (event) => {
    setPresentingIssues(event);
  };

  const handleLongTermGoalsChange = (event) => {
    setLongTermGoals(event);
  };

  const handleOpenObjectiveForm = () => setOpenObjectiveForm(true);

  const handleCloseObjectiveForm = () => setOpenObjectiveForm(false);

  const addObjective = (objective, intervention) => {
    setObjectives([...objectives, { objective, intervention }]);
  };

  const saveTreatmentPlanAndObjectives = async () => {
    if (!presentingIssues || !longTermGoals) {
      // Affichez un toast d'échec si l'un des champs est vide
      toast.error(translate("PleaseFillRequiredFields"), {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        style: {
          minWidth: "500px", // Ajuste la largeur minimale du toast
        },
      });

      return;
    }

    setIsloading(true);
    const treatmentPlanDoc = {
      presentingIssues,
      longTermGoals,
      createdAt: serverTimestamp(),
      createdBy: currentUser?.displayName,
    };

    try {
      // Utilisez setDoc et spécifiez clientId comme document ID
      const treatmentPlanRef = doc(db, "treatmentPlans", props.clientId);
      await setDoc(treatmentPlanRef, treatmentPlanDoc);

      for (const objective of objectives) {
        await addDoc(collection(treatmentPlanRef, "objectives"), objective);
      }

      setIsloading(false);
      props.closemodal();
      toast.success(translate("TreatmentPlanSuccessfullyAdded"), {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        style: {
          minWidth: "500px", // Ajuste la largeur minimale du toast
        },
      });
    } catch (error) {
      console.error("Error saving treatment plan and objectives: ", error);
      setIsloading(false);
      toast.error(translate("FailedToSaveTreatmentPlan"), {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        style: {
          minWidth: "500px", // Ajuste la largeur minimale du toast
        },
      });
    }
  };

  return (
    <>
      <Modal
        open={openObjectiveForm}
        onClose={handleCloseObjectiveForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Slide direction="left" in={openObjectiveForm}>
          <Box
            sx={{
              position: "fixed",
              top: "5%",
              right: "0%",
              width: "90%",
              height: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              marginRight: "15px",
              pl: 0,
              pr: 0,
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
              zIndex: -1,
            }}
          >
            <AddObjectiveFormMobileV2
              addObjective={addObjective}
              closemodal={handleCloseObjectiveForm}
            />
          </Box>
        </Slide>
      </Modal>
      <div
        // className="inputAndLabelModalContainerTitle2"
        style={{ width: "auto", height: "100%" }}
      >
        <div
          className="line1-modal-percent"
          style={{ borderBottom: "1px solid lightgrey" }}
        >
          <TopTitleFormComponentMobileV2
            title={translate("TreatmentPlan")}
            closemodal={props.closemodal}
          />
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "6px",
            height: "80%",
            overflowY: "scroll",
            // overflowX: "hidden",
            paddingLeft: "35px",
            paddingRight: "25px",
          }}
        >
          <TextFieldComponentV2Mobile
            title={translate("PresentingIssues")}
            isRequired={true}
            width="400px"
            placeholder="type here"
            value={presentingIssues}
            onChange={handlePresentingIssuesChange}
          />

          <div style={{ paddingTop: "20px" }}>
            <TextFieldComponentV2Mobile
              title={translate("LongTermGoals")}
              isRequired={true}
              value={longTermGoals}
              width="400px"
              onChange={handleLongTermGoalsChange}
              placeholder="Format:
          Goal 1:
          Goal 2:
          Goal 3:
          Etc."
            />
          </div>

          <div
            className="SubTitleBold"
            style={{ paddingTop: "20px", paddingLeft: "0px", fontSize: "14px" }}
          >
            {translate("ObjectivesAssociatedInterventions")}
          </div>

          <div
            style={{
              marginLeft: "0px",
              display: "flex",
              flexDirection: "row",
              height: "30px",
              borderRadius: "30px",
              border: "1px solid lightgrey",
              width: "100%",
              marginTop: "25px",
              marginBottom: "5px",
              paddingLeft: "15px",
              paddingRight: "5px",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "12px",
                fontWeight: "500",
              }}
            >
              {translate("AddObjective")}
            </div>
            <div style={{ marginBottom: "0px" }}>
              <AddButtonRoundMobileV4 handleOpen={handleOpenObjectiveForm} />
            </div>
          </div>

          <div className="objectives-list" style={{ paddingLeft: "0px" }}>
            {objectives.map((objective, index) => (
              <div key={index} className="objective-item">
                <div className="title-objective-treatment">
                  {translate("Objective")} {index + 1}:
                </div>
                <div
                  className="text-container"
                  style={{
                    marginTop: "0px",
                    marginBottom: "0px",
                    backgroundColor: "white",
                    paddingLeft: "4px",
                    paddingRight: "4px",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    borderRadius: "5px",
                    width: "100%",
                  }}
                >
                  {objective.objective}
                </div>
                <div className="title-objective-treatment">
                  {translate("Intervention")} {index + 1}:
                </div>
                <div
                  className="text-container"
                  style={{
                    marginTop: "0px",
                    marginBottom: "10px",
                    backgroundColor: "white",
                    paddingLeft: "4px",
                    paddingRight: "4px",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    borderRadius: "5px",
                    width: "100%",
                  }}
                >
                  {objective.intervention}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="line4-modal-percent-bis">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
              onClick={props.closemodal}
            >
              {translate("Cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={saveTreatmentPlanAndObjectives}
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : objectives.length < 1
                  ? {
                      backgroundColor: "gray",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                    }
              }
              disabled={isLoading || objectives.length < 1}
              //   onClick={handlePostRecord}
            >
              {isLoading ? translate("Adding") : translate("AddTreatment")}
            </Button>
          </>
        </div>
      </div>
    </>
  );
}

export default AddTreatmentPlanMobileV2;
