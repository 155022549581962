import React from "react";
import TaskIcon from "@mui/icons-material/Task";
import EditIcon from "@mui/icons-material/Edit";

function EditButtonMobile(props) {
  return (
    <div
      className="square-button-mobile check-button-color centered-content button-pointer"
      title="Edit"
      onClick={props.handleOpen}
    >
      <EditIcon style={{ fontSize: 18, color: "white" }} />
      {/* <span className="tooltip-text">{props.title}</span> */}
    </div>
  );
}

export default EditButtonMobile;
