import React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { Timestamp } from "firebase/firestore";
import dayjs from "dayjs";
// Importez votre CSS si nécessaire

export default function DatePickerV2ComponentNoTitle(props) {
  const handleChange = (date) => {
    const timestamp = Timestamp.fromMillis(date.valueOf());
    props.onChange(timestamp);
  };

  const dateObject =
    props.value && typeof props.value.toMillis === "function"
      ? dayjs(new Date(props.value.toMillis()))
      : dayjs(props.value);

  return (
    <div className="input-label-container-newdesign">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          value={dateObject}
          onChange={handleChange}
          format="DD/MM/YYYY"
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              error={false}
              sx={{
                width: 250, // Spécifiez la largeur ici
                "& .MuiOutlinedInput-input": {
                  height: "auto",
                  padding: "5px", // Ajustez le padding ici pour la hauteur
                  fontSize: "18px", // Ajustez la taille de la police si nécessaire
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "grey", // Ou la couleur que vous préférez
                },
                "& .MuiSvgIcon-root": {
                  // Ajustez la taille de l'icône si nécessaire
                },
              }}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
}
