import React, { useState, useEffect, useContext } from "react";
import { Button } from "@mui/material";
import TextFieldComponentLarge from "../TextFieldComponentLarge";
import TextFieldComponentExtraLarge from "../TextFieldComponentExtraLarge";
import TopTitleFormComponent from "./TopTitleFormComponent";
import {
  collection,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { showCustomToast } from "../Misc/CustomToast";
import { useAuth } from "../../contexts/AuthContext";
import { LanguageContext } from "../../context/LanguageContext";
import TextFieldComponentV2 from "../TextFieldComponentV2";

function AddReviewForm(props) {
  const { translate } = useContext(LanguageContext);
  const { currentUser } = useAuth();
  const [isLoading, setIsloading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [objectives, setObjectives] = useState(props.objectives || []);
  const [reviewFields, setReviewFields] = useState([]);

  useEffect(() => {
    setReviewFields(
      objectives.map((objective) => ({
        objectiveReview: objective.objectiveReview || "",
        interventionReview: objective.interventionReview || "",
      }))
    );
  }, [objectives]);

  const handleReviewFieldChange = (index, field, value) => {
    const updatedReviewFields = [...reviewFields];
    updatedReviewFields[index][field] = value;
    setReviewFields(updatedReviewFields);
  };

  const updateObjectiveReviews = async () => {
    if (!reviewFields || reviewFields.length === 0) {
      showCustomToast("No reviews to update", "error");
      return;
    }

    setIsloading(true);

    try {
      const treatmentPlanRef = doc(db, "treatmentPlans", props.clientId);
      const objectivesCollectionRef = collection(
        treatmentPlanRef,
        "objectives"
      );

      // Update treatmentPlan document
      const reviewDone = true;
      const reviewDoneAt = serverTimestamp();
      const reviewDoneBy = currentUser?.displayName;
      await updateDoc(treatmentPlanRef, {
        reviewDone,
        reviewDoneAt,
        reviewDoneBy,
      });

      // Update objectives
      for (let index = 0; index < objectives.length; index++) {
        const objective = objectives[index];
        if (objective.id) {
          const objectiveRef = doc(objectivesCollectionRef, objective.id);
          const updatedObjective = {
            objectiveReview: reviewFields[index].objectiveReview,
            interventionReview: reviewFields[index].interventionReview,
          };
          await updateDoc(objectiveRef, updatedObjective);
        } else {
          console.error("Objective ID is missing or undefined");
        }
      }

      setIsloading(false);
      props.closemodal();
      showCustomToast(translate("ObjectiveReviewsUpdated"), "success");
    } catch (error) {
      console.error("Error updating objective reviews: ", error);
      setIsloading(false);
      showCustomToast(translate("FailedToUpdateObjectiveReviews"), "error");
    }
  };

  return (
    <>
      <div
        // className="inputAndLabelModalContainerTitle2"
        style={{ width: "auto", height: "100%" }}
      >
        <div className="line1-modal-percent">
          <TopTitleFormComponent
            title={translate("AddReviews")}
            closemodal={props.closemodal}
          />
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "4px",
            height: "75%",
            overflowY: "scroll",
            paddingLeft: "35px",
            // overflowX: "hidden",
          }}
        >
          <div>
            <TextFieldComponentV2
              title={translate("PresentingIssues")}
              isRequired={true}
              readOnly={true}
              value={props.presentingIssues}
              width="500px"
            />

            <div style={{ paddingTop: "15px" }}>
              <TextFieldComponentV2
                title={translate("LongTermGoals")}
                isRequired={true}
                readOnly={true}
                value={props.longTermGoals}
                width="500px"
              />
            </div>

            <div
              className="SubTitleBold"
              style={{
                fontWeight: "600",
                paddingLeft: "10px",
                fontSize: "16px",
                paddingLeft: "10px",
                paddingTop: "10px",
                paddingTop: "10px",
              }}
            >
              {translate("ObjectivesAssociatedInterventions")}
            </div>

            <div className="objectives-list">
              {objectives.map((objective, index) => (
                <div key={index} className="objective-item">
                  <div className="title-objective-treatment">
                    {translate("Objective")} {index + 1}:
                  </div>
                  <input value={objective.objective} readOnly />
                  <div className="objective-item-red">
                    <div className="title-objective-treatment">
                      {translate("Objective")} {index + 1} -{" "}
                      {translate("Review")}:
                    </div>
                    <input
                      placeholder={translate("TypeReviewHere")}
                      style={{ width: "90%" }}
                      value={reviewFields[index]?.objectiveReview || ""}
                      onChange={(event) =>
                        handleReviewFieldChange(
                          index,
                          "objectiveReview",
                          event.target.value
                        )
                      }
                    />
                  </div>

                  <div className="title-objective-treatment">
                    {translate("Intervention")} {index + 1}:
                  </div>
                  <input
                    value={objective.intervention}
                    style={{ width: "90%" }}
                    readOnly
                  />

                  <div className="objective-item-red">
                    <div className="title-objective-treatment">
                      {translate("Intervention")} {index + 1} -{" "}
                      {translate("Review")}:
                    </div>
                    <textarea
                      placeholder="Type review here"
                      style={{ width: "90%" }}
                      value={reviewFields[index]?.interventionReview || ""}
                      onChange={(event) =>
                        handleReviewFieldChange(
                          index,
                          "interventionReview",
                          event.target.value
                        )
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="line4-modal-percent">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              onClick={props.closemodal}
            >
              {translate("Cancel")}
            </Button>

            <Button
              variant="contained"
              onClick={updateObjectiveReviews}
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : objectives.length < 1
                  ? {
                      backgroundColor: "gray",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                    }
              }
              disabled={isLoading}
            >
              {isLoading ? translate("Updating") : translate("UpdateReviews")}
            </Button>
          </>
        </div>
      </div>
    </>
  );
}

export default AddReviewForm;
