// import React from "react";

// function TutorialVideosContent() {
//   const videos = [
//     {
//       url: "https://www.loom.com/embed/625b130929db4a74b52316d7f23a27af?sid=4aa9ae14-d40c-4e1d-930b-ac07785bebc5",
//       title: "Add a client",
//     },
//     {
//       url: "https://www.loom.com/embed/bc7ffdc70f1e421989a6b66d5233421e?sid=608f799b-57ec-4172-aca1-01ce8a6add3b",
//       title: "Add a note",
//     },
//     {
//       url: "https://www.loom.com/embed/271e5dd87f2647f8823366a5dfc8a7c4?sid=2d383652-8ee8-42cd-83d2-d033ffee2ea0",
//       title: "Check the bookings",
//     },
//     {
//       url: "https://www.loom.com/embed/9508292630e94ac791a17e77d1f78c68?sid=8e522f98-8cda-4e0e-a23a-702a1724dfba",
//       title: "Add a document",
//     },
//   ];

//   const videoItems = videos.map((video, index) => (
//     <div
//       key={index}
//       style={{
//         width: "50%",
//         height: "50vh",
//         padding: "20px",
//       }}
//     >
//       <h2 style={{ fontWeight: "bold", marginLeft: 40 }}>{video.title}</h2>
//       <iframe
//         src={video.url}
//         frameBorder="0"
//         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//         allowFullScreen
//         style={{
//           width: "100%",
//           height: "calc(100% - 60px)",
//           marginLeft: 40,
//         }}
//       ></iframe>
//     </div>
//   ));

//   return (
//     <div
//       className="empty-content"
//       style={{
//         display: "flex",
//         flexWrap: "wrap",
//         width: "100%",
//         height: "100vh",
//         overflowY: "auto",
//       }}
//     >
//       {videoItems}
//     </div>
//   );
// }

// export default TutorialVideosContent;
import React, { useState } from "react";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";

function TutorialVideosContent() {
  const [runTour, setRunTour] = useState(false);
  const steps = [
    {
      target: ".video-1",
      content: "This is the CLIENT category video. Click to watch!",
    },
    {
      target: ".video-2",
      content: "Here is the NOTES category video. Click to learn more!",
    },
    {
      target: ".video-3",
      content: "This video explains the DOCUMENTS category.",
    },
    {
      target: ".video-4",
      content: "Learn about SETTINGS, STAFFS CONTACT, and TUTORIALS here.",
    },
  ];

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTour(false);
    }
  };

  const videos = [
    {
      url: "https://www.loom.com/embed/625b130929db4a74b52316d7f23a27af?sid=4aa9ae14-d40c-4e1d-930b-ac07785bebc5",
      title: "CLIENT category",
      className: "video-1",
    },
    {
      url: "https://www.loom.com/embed/bc7ffdc70f1e421989a6b66d5233421e?sid=608f799b-57ec-4172-aca1-01ce8a6add3b",
      title: "NOTES category",
      className: "video-2",
    },
    {
      url: "https://www.loom.com/embed/271e5dd87f2647f8823366a5dfc8a7c4?sid=2d383652-8ee8-42cd-83d2-d033ffee2ea0",
      title: "DOCUMENTS category",
      className: "video-3",
    },
    {
      url: "https://www.loom.com/embed/9508292630e94ac791a17e77d1f78c68?sid=8e522f98-8cda-4e0e-a23a-702a1724dfba",
      title: "SETTINGS, STAFFS CONTACT and TUTORIALS categories",
      className: "video-4",
    },
  ];

  const videoItems = videos.map((video, index) => (
    <div
      key={index}
      className={video.className}
      style={{
        width: "50%",
        height: "50vh",
        padding: "20px",
      }}
    >
      <h2 style={{ fontWeight: "bold", marginLeft: 40 }}>{video.title}</h2>
      <iframe
        src={video.url}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{
          width: "100%",
          height: "calc(100% - 60px)",
          marginLeft: 40,
        }}
      ></iframe>
    </div>
  ));

  return (
    <div
      className="empty-content"
      style={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        height: "100vh",
        overflowY: "auto",
        position: "relative",
      }}
    >
      <button
        onClick={() => setRunTour(true)}
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          padding: "10px 20px",
          backgroundColor: "#4CAF50",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        Start Tour
      </button>
      <Joyride
        steps={steps}
        run={runTour}
        continuous
        showProgress
        showSkipButton
        callback={handleJoyrideCallback}
        styles={{
          options: {
            arrowColor: "#fff",
            backgroundColor: "#333",
            overlayColor: "rgba(0, 0, 0, 0.6)",
            primaryColor: "#4CAF50",
            textColor: "#fff",
            width: 300,
            zIndex: 1000,
          },
          spotlight: {
            borderRadius: "50%",
          },
        }}
        spotlightClicks={true}
      />
      {videoItems}
    </div>
  );
}

export default TutorialVideosContent;
