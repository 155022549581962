import React, { useContext, useEffect, useRef, useState } from "react";
import "../css/dashboard.css";
import "../css/client.css";
import { Box, Modal, Avatar } from "@mui/material";
import AddClient from "../component/forms/AddClient";
import {
  addDoc,
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import { roles } from "../firebase.collection";
import Joyride, { ACTIONS, STATUS } from "react-joyride";
import { LanguageContext } from "../context/LanguageContext";
import logoAi from "../images/ai-logo.png";
import { db } from "../firebase";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import { TextField } from "@mui/material";
import LottieLoader from "../component/Misc/loaderLottie.json";
import Lottie from "react-lottie";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EmailIcon from "@mui/icons-material/Email";
import Tooltip from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";

export default function ArtificialleIntelligenceContentMobileV2({
  selectedTab,
}) {
  const [copyMessage, setCopyMessage] = useState(false);
  const { translate } = useContext(LanguageContext);
  const [showChips, setShowChips] = useState(true); // Ajouter cet état
  const { currentUser } = useAuth();
  const userIdState = currentUser ? currentUser.uid : "";
  const [run, setRun] = useState(false);
  const [showHelpButton, setShowHelpButton] = useState(true);
  const [prompt, setPrompt] = useState("");
  const [chipsDisappearing, setChipsDisappearing] = useState(false);
  const [sentQuestion, setSentQuestion] = useState("");

  const [clientInfo, setClientInfo] = useState([]);
  const [clientNotes, setClientNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [userQuestion, setUserQuestion] = useState("");
  const [response, setResponse] = useState("");
  const [fetchingResponse, setFetchingResponse] = useState(false);
  const [answerIsLoading, setAnswerIsLoading] = useState(false);
  const [showInstructions, setShowInstructions] = useState(true);
  const [showTypedText, setShowTypedText] = useState(false);
  const [isTypingFinished, setIsTypingFinished] = useState(false);
  const [clientMetrics, setClientMetrics] = useState({});
  const [initialPrompt, setInitialPrompt] = useState("");
  const [conversationHistory, setConversationHistory] = useState([]);
  const [photoUrl, setPhotoUrl] = useState("");
  const endOfMessagesRef = useRef(null);
  const [remainingPrompts, setRemainingPrompts] = useState(0);
  const [clientsNotAnonymized, setClientsNotAnonymized] = useState([]);
  const [promptTokens, setPromptTokens] = useState(0);
  const [responseTokens, setResponseTokens] = useState(0);
  const [selectedClientInfo, setSelectedClientInfo] = useState(null);
  const [selectedClientNotes, setSelectedClientNotes] = useState([]);
  const [selectedClientPreAdmission, setSelectedClientPreAdmission] =
    useState(null);
  const [selectedClientTreatmentPlans, setSelectedClientTreatmentPlans] =
    useState(null);
  const [selectedClient, setSelectedClient] = useState("All Clients");

  const countTokens = (text) => {
    const words = text.split(/\s+/);
    const wordCount = words.length;
    const characterCount = text.length;

    // Estimation approximative : 1 token pour chaque 4 caractères ou 0.75 tokens par mot
    const tokens = Math.ceil(characterCount / 4) + Math.ceil(wordCount / 0.75);
    return tokens;
  };

  const handleClientChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedClient(selectedValue);
    if (selectedValue === "All Clients") {
      setSelectedClientInfo(null);
      setSelectedClientNotes([]);
      setSelectedClientPreAdmission(null);
      setSelectedClientTreatmentPlans(null);
    } else {
      setClientInfo([]);
      setClientNotes([]);
      setClientMetrics({});
    }
  };

  const fetchClients = async () => {
    const querySnapshot = await getDocs(collection(db, "clients"));
    const clientsData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setClientsNotAnonymized(clientsData);
  };

  // Utilisation de useEffect pour récupérer les clients au montage du composant
  useEffect(() => {
    fetchClients();
  }, []);

  const steps = [
    {
      target: "#search-client",
      content:
        "Use this search bar to quickly find a client by their name or details. Start typing the first few letters, and it will suggest matching clients.",
    },
    {
      target: "#add-client",
      content:
        "Click this button to add a new client. Fill in the necessary information in the form that appears.",
    },
    {
      target: "#display-client",
      content:
        "Use this switch to toggle between grid view and list view for the clients.",
    },
    {
      target: "#access-client",
      content:
        "This section allows you to list clients by their status (inhouse, aftercare, incoming, etc.). The tabs display the corresponding clients. For inhouse clients, you can list them by therapists. You can click on the card representing the client to view more details.",
    },
  ];

  const customStyles = {
    options: {
      zIndex: 10000,
    },
    tooltip: {
      backgroundColor: "#fff",
      color: "#000",
      animation: "fadeIn 0.3s ease-in-out",
    },
    tooltipContent: {
      textAlign: "justify",
      marginTop: "10px",
    },
    beacon: {
      offsetY: -50,
    },
    buttonNext: {
      backgroundColor: "#1565c0",
      color: "#fff",
    },
    buttonBack: {
      color: "blue",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 1000,
    },
    spotlight: {
      position: "absolute",
      borderRadius: 0,
    },
    beaconInner: {
      backgroundColor: "#1565c0", // Couleur du cercle intérieur
      borderColor: "#1565c0",
    },
    beaconOuter: {
      backgroundColor: "rgba(21, 101, 192, 0.5)", // Couleur de l'animation concentrique
      borderColor: "rgba(21, 101, 192, 0.5)",
    },
  };

  useEffect(() => {
    if (currentUser) {
      // Charger la conversation existante à partir de Firestore
      const chatDocRef = doc(db, "aichats", currentUser.uid);
      const unsubscribe = onSnapshot(chatDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setConversationHistory(data.conversation || []);
        }
      });
      return () => unsubscribe();
    }
  }, [currentUser]);

  const saveConversation = async (updatedConversation) => {
    if (currentUser) {
      const chatDocRef = doc(db, "aichats", currentUser.uid);
      await setDoc(
        chatDocRef,
        { conversation: updatedConversation },
        { merge: true }
      );
    }
  };

  useEffect(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [conversationHistory, answerIsLoading]);

  useEffect(() => {
    if (currentUser) {
      const unsubscribe = onSnapshot(
        query(roles, where(documentId(), "==", userIdState)),
        (querySnapshot) => {
          const doc = querySnapshot.docs[0];
          if (doc && doc.exists) {
            const aiCounter = doc.data().aiCounter;
            setRemainingPrompts(aiCounter);
          }
        }
      );
      return () => unsubscribe();
    }
  }, [currentUser, userIdState]);

  useEffect(() => {
    fetchDataBasedOnSelection();
  }, [selectedClient]);

  const fetchDataBasedOnSelection = async () => {
    if (selectedClient !== "All Clients") {
      await fetchSelectedClientData();
    } else {
      await fetchGlobalClientData();
    }
  };

  const decrementAICounter = async () => {
    try {
      const userDocRef = doc(db, "roles", userIdState);
      await updateDoc(userDocRef, {
        aiCounter: remainingPrompts - 1,
      });
    } catch (error) {
      console.error("Error decrementing aiCounter:", error);
    }
  };

  const estimateTokens = (text) => {
    const words = text.split(/\s+/);
    const wordCount = words.length;
    const characterCount = text.length;

    const tokens = Math.ceil(characterCount / 4) + Math.ceil(wordCount / 0.75);
    return tokens;
  };

  const formatDate = (date) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options).replace(",", " at");
  };

  const convertTimestamps = (data) => {
    if (Array.isArray(data)) {
      return data.map((item) => convertTimestamps(item));
    } else if (typeof data === "object" && data !== null) {
      const convertedObject = {};
      for (const key in data) {
        if (
          data[key] &&
          data[key].seconds !== undefined &&
          data[key].nanoseconds !== undefined
        ) {
          convertedObject[key] = new Date(
            data[key].seconds * 1000 + data[key].nanoseconds / 1000000
          ).toISOString();
        } else {
          convertedObject[key] = convertTimestamps(data[key]);
        }
      }
      return convertedObject;
    } else {
      return data;
    }
  };

  // const fetchGlobalClientData = async () => {
  //   setLoading(true);
  //   setProgress(10);
  //   try {
  //     const clientsQuery = query(
  //       collection(db, "clients"),
  //       where("clientStatus", "!=", "Inactive")
  //     );

  //     const clientsSnapshot = await getDocs(clientsQuery);
  //     setProgress(20);

  //     const clientsData = [];
  //     clientsSnapshot.forEach((doc) => {
  //       clientsData.push({ id: doc.id, ...doc.data() });
  //     });

  //     const filteredClientsData = clientsData.map((client) => {
  //       const {
  //         profilePicture,
  //         fileUrls,
  //         lastName,
  //         searchTerms,
  //         personalEmail,
  //         fileUrlsRoi,
  //         filesUrlPreAdm,
  //         emergencyCheckbox0,
  //         emergencyCheckbox1,
  //         emergencyCheckbox2,
  //         emergencyCheckbox3,
  //         emergencyCheckbox4,
  //         emergencyCheckbox5,
  //         emergencyCheckbox6,
  //         emergencyCheckbox7,
  //         emergencyCheckbox8,
  //         emergencyCheckbox9,
  //         emergencyCheckbox10,
  //         emergencyCheckbox11,
  //         emergencyCheckbox12,
  //         emergencyCheckbox13,
  //         emergencyCheckbox14,
  //         emergencyCheckbox15,
  //         emergencyCheckboxContent15,
  //         emergencyCheckboxContent15Input,
  //         emergencyContactAddress,
  //         emergencyContactCity,
  //         emergencyContactCountry,
  //         emergencyContactEmail,
  //         emergencyContactFirstName,
  //         emergencyContactLastName,
  //         emergencyContactNationality,
  //         emergencyContactPhoneNumber,
  //         emergencyContactRelationship,
  //         emergencyRelationshipOther,
  //         ...filteredClientData
  //       } = client;
  //       return filteredClientData;
  //     });

  //     const preAdmissionDocs = await getDocs(
  //       collection(db, "preAdmissionAssessments")
  //     );

  //     const allPreAdmissionData = preAdmissionDocs.docs.map((doc) => {
  //       const preAdmission = doc.data();
  //       const clientId = doc.id; // Assuming the document ID is the client UID

  //       // Find the corresponding client from clientsData
  //       const matchingClient = clientsData.find(
  //         (client) => client.id === clientId
  //       );

  //       // Remove clientId, clientIdentifier, and firstName from the preAdmission data
  //       const {
  //         clientId: removedClientId,
  //         clientIdentifier: removedClientIdentifier,
  //         firstName: removedFirstName,
  //         ...preAdmissionFields
  //       } = preAdmission;

  //       // Convert each field of pre-admission to a "key: value" string, then join them with a comma
  //       const preAdmissionFieldStrings = Object.entries(preAdmissionFields)
  //         .map(([key, value]) => `${key}: ${value}`)
  //         .join(", ");

  //       // Format the final pre-admission data for each client
  //       return `Preadmission data for ${
  //         matchingClient?.firstName || "Unknown"
  //       }, with the client identifier: ${
  //         matchingClient?.clientIdentifier || "Unknown"
  //       }, with uid: ${clientId}, ${preAdmissionFieldStrings}`;
  //     });

  //     const preAdmissionSummary = allPreAdmissionData; // No need to convert timestamps for simple strings now

  //     const convertedClientsData = convertTimestamps(filteredClientsData);

  //     setClientInfo(convertedClientsData);
  //     setPreAdmissionData(preAdmissionSummary);

  //     setProgress(40);

  //     const clientsDataText = JSON.stringify(filteredClientsData);
  //     const clientsDataTokensCount = estimateTokens(clientsDataText);
  //     console.log("Total Tokens for Clients Info:", clientsDataTokensCount);

  //     const preAdmissionDataText = preAdmissionSummary.join("\n");
  //     const preAdmissionTokensCount = estimateTokens(preAdmissionDataText);
  //     console.log(
  //       "Total Tokens for Pre-Admission Data:",
  //       preAdmissionTokensCount
  //     );

  //     const twoWeeksAgo = Timestamp.fromDate(
  //       new Date(Date.now() - 14 * 24 * 60 * 60 * 1000)
  //     );

  //     const notesQuery = query(
  //       collection(db, "notes"),
  //       where("effectiveDateTime", ">=", twoWeeksAgo)
  //     );
  //     const notesSnapshot = await getDocs(notesQuery);
  //     setProgress(60);
  //     const notes = [];
  //     notesSnapshot.forEach((doc) => {
  //       notes.push(doc.data());
  //     });

  //     const convertedNotes = convertTimestamps(notes).map((note) => {
  //       const {
  //         clientLastName,
  //         clientProfilePicture,
  //         fileUrls,
  //         ...filteredNote
  //       } = note;
  //       return filteredNote;
  //     });
  //     setClientNotes(convertedNotes);

  //     const notesText = convertedNotes
  //       .map((note) => JSON.stringify(note))
  //       .join("\n");
  //     const notesTokensCount = estimateTokens(notesText);
  //     console.log("Total Tokens for Client Notes:", notesTokensCount);

  //     const totalTokens = clientsDataTokensCount + notesTokensCount;
  //     console.log("Total Tokens for All Data:", totalTokens);

  //     const clientMetrics = {
  //       inHouseCount: 0,
  //       otherStatusCount: 0,
  //       maleCount: 0,
  //       femaleCount: 0,
  //       inHousePrimaryAddictionDistribution: {},
  //       allPrimaryAddictionDistribution: {},
  //       statusDistribution: {}, // Ajout d'un objet pour le décompte par status
  //     };

  //     clientsData.forEach((clientData) => {
  //       // Count inHouse and other statuses
  //       if (clientData.clientStatus === "In House") {
  //         clientMetrics.inHouseCount++;
  //       } else {
  //         clientMetrics.otherStatusCount++;
  //       }

  //       // Count gender distribution
  //       if (clientData.gender === "Male") {
  //         clientMetrics.maleCount++;
  //       } else if (clientData.gender === "Female") {
  //         clientMetrics.femaleCount++;
  //       }

  //       // Count primary addiction distribution
  //       if (clientData.primaryAddiction) {
  //         if (clientData.clientStatus === "In House") {
  //           clientMetrics.inHousePrimaryAddictionDistribution[
  //             clientData.primaryAddiction
  //           ] =
  //             (clientMetrics.inHousePrimaryAddictionDistribution[
  //               clientData.primaryAddiction
  //             ] || 0) + 1;
  //         }
  //         clientMetrics.allPrimaryAddictionDistribution[
  //           clientData.primaryAddiction
  //         ] =
  //           (clientMetrics.allPrimaryAddictionDistribution[
  //             clientData.primaryAddiction
  //           ] || 0) + 1;
  //       }

  //       // Count client status distribution
  //       if (clientData.clientStatus) {
  //         clientMetrics.statusDistribution[clientData.clientStatus] =
  //           (clientMetrics.statusDistribution[clientData.clientStatus] || 0) +
  //           1;
  //       }
  //     });

  //     setClientMetrics(clientMetrics);

  //     // Calculate and log tokens for clientMetrics
  //     const clientMetricsText = JSON.stringify(clientMetrics, null, 2);
  //     const clientMetricsTokensCount = estimateTokens(clientMetricsText);

  //     console.log("Total Tokens for Client Metrics:", clientMetricsTokensCount);

  //     setProgress(100);
  //   } catch (error) {
  //     console.error("Error fetching global client data: ", error);
  //   }
  //   setLoading(false);
  // };

  const fetchGlobalClientData = async () => {
    setLoading(true);
    setProgress(10);
    try {
      const clientsQuery = query(
        collection(db, "clients"),
        where("clientStatus", "!=", "Inactive")
      );

      const clientsSnapshot = await getDocs(clientsQuery);
      setProgress(20);

      const clientsData = [];
      clientsSnapshot.forEach((doc) => {
        clientsData.push({ id: doc.id, ...doc.data() });
      });

      const filteredClientsData = clientsData.map((client) => {
        const {
          profilePicture,
          fileUrls,
          lastName,
          searchTerms,
          personalEmail,
          fileUrlsRoi,
          filesUrlPreAdm,
          emergencyCheckbox0,
          emergencyCheckbox1,
          emergencyCheckbox2,
          emergencyCheckbox3,
          emergencyCheckbox4,
          emergencyCheckbox5,
          emergencyCheckbox6,
          emergencyCheckbox7,
          emergencyCheckbox8,
          emergencyCheckbox9,
          emergencyCheckbox10,
          emergencyCheckbox11,
          emergencyCheckbox12,
          emergencyCheckbox13,
          emergencyCheckbox14,
          emergencyCheckbox15,
          emergencyCheckboxContent15,
          emergencyCheckboxContent15Input,
          emergencyContactAddress,
          emergencyContactCity,
          emergencyContactCountry,
          emergencyContactEmail,
          emergencyContactFirstName,
          emergencyContactLastName,
          emergencyContactNationality,
          emergencyContactPhoneNumber,
          emergencyContactRelationship,
          emergencyRelationshipOther,
          ...filteredClientData
        } = client;
        return filteredClientData;
      });

      const preAdmissionDocs = await getDocs(
        collection(db, "preAdmissionAssessments")
      );

      const preAdmissionDataMap = {};
      preAdmissionDocs.forEach((doc) => {
        const data = doc.data();
        preAdmissionDataMap[doc.id] = {
          eta: data.eta || null,
          durationOfStay: data.durationOfStay || null,
        };
      });

      filteredClientsData.forEach((client) => {
        const preAdmissionData = preAdmissionDataMap[client.id];
        if (preAdmissionData) {
          client.eta = preAdmissionData.eta;
          client.durationOfStay = preAdmissionData.durationOfStay;
        } else {
          client.eta = null;
          client.durationOfStay = null;
        }
      });

      const convertedClientsData = convertTimestamps(filteredClientsData);
      setClientInfo(convertedClientsData);

      setProgress(40);

      const clientsDataText = JSON.stringify(filteredClientsData);
      const clientsDataTokensCount = estimateTokens(clientsDataText);

      const twoWeeksAgo = Timestamp.fromDate(
        new Date(Date.now() - 14 * 24 * 60 * 60 * 1000)
      );

      const oneMonthAgo = Timestamp.fromDate(
        new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
      );

      const notesQuery = query(
        collection(db, "notes"),
        where("effectiveDateTime", ">=", oneMonthAgo)
      );
      const notesSnapshot = await getDocs(notesQuery);
      setProgress(60);
      const notes = [];
      notesSnapshot.forEach((doc) => {
        notes.push(doc.data());
      });

      const convertedNotes = convertTimestamps(notes).map((note) => {
        const {
          clientLastName,
          clientProfilePicture,
          fileUrls,
          ...filteredNote
        } = note;
        return filteredNote;
      });
      setClientNotes(convertedNotes);

      const notesText = convertedNotes
        .map((note) => JSON.stringify(note))
        .join("\n");
      const notesTokensCount = estimateTokens(notesText);

      const totalTokens = clientsDataTokensCount + notesTokensCount;

      const clientMetrics = {
        inHouseCount: 0,
        otherStatusCount: 0,
        maleCount: 0,
        femaleCount: 0,
        inHousePrimaryAddictionDistribution: {},
        allPrimaryAddictionDistribution: {},
        statusDistribution: {},
      };

      clientsData.forEach((clientData) => {
        if (clientData.clientStatus === "In House") {
          clientMetrics.inHouseCount++;
        } else {
          clientMetrics.otherStatusCount++;
        }

        if (clientData.gender === "Male") {
          clientMetrics.maleCount++;
        } else if (clientData.gender === "Female") {
          clientMetrics.femaleCount++;
        }

        if (clientData.primaryAddiction) {
          if (clientData.clientStatus === "In House") {
            clientMetrics.inHousePrimaryAddictionDistribution[
              clientData.primaryAddiction
            ] =
              (clientMetrics.inHousePrimaryAddictionDistribution[
                clientData.primaryAddiction
              ] || 0) + 1;
          }
          clientMetrics.allPrimaryAddictionDistribution[
            clientData.primaryAddiction
          ] =
            (clientMetrics.allPrimaryAddictionDistribution[
              clientData.primaryAddiction
            ] || 0) + 1;
        }

        if (clientData.clientStatus) {
          clientMetrics.statusDistribution[clientData.clientStatus] =
            (clientMetrics.statusDistribution[clientData.clientStatus] || 0) +
            1;
        }
      });

      setClientMetrics(clientMetrics);

      const clientMetricsText = JSON.stringify(clientMetrics, null, 2);
      const clientMetricsTokensCount = estimateTokens(clientMetricsText);

      setProgress(100);
    } catch (error) {
      console.error("Error fetching global client data: ", error);
    }
    setLoading(false);
  };

  const fetchSelectedClientData = async () => {
    if (selectedClient !== "All Clients") {
      const clientDoc = await getDoc(doc(db, "clients", selectedClient));
      const clientData = clientDoc.data();

      const {
        profilePicture,
        lastName,
        fileUrls,
        searchTerms,
        personalEmail,
        fileUrlsRoi,
        filesUrlPreAdm,
        emergencyCheckbox0,
        emergencyCheckbox1,
        emergencyCheckbox2,
        emergencyCheckbox3,
        emergencyCheckbox4,
        emergencyCheckbox5,
        emergencyCheckbox6,
        emergencyCheckbox7,
        emergencyCheckbox8,
        emergencyCheckbox9,
        emergencyCheckbox10,
        emergencyCheckbox11,
        emergencyCheckbox12,
        emergencyCheckbox13,
        emergencyCheckbox14,
        emergencyCheckbox15,
        emergencyCheckboxContent15,
        emergencyCheckboxContent15Input,
        emergencyContactLastName,
        emergencyRelationshipOther,
        ...filteredClientData
      } = clientData;

      setSelectedClientInfo(convertTimestamps(filteredClientData));

      const notesQuery = query(
        collection(db, "notes"),
        where("clientId", "==", selectedClient)
      );
      // const notesSnapshot = await getDocs(notesQuery);
      // const notesData = notesSnapshot.docs.map((doc) =>
      //   convertTimestamps(doc.data())
      // );
      // setSelectedClientNotes(notesData);
      // Fetch notes for the selected client
      const notesSnapshot = await getDocs(notesQuery);
      const notesData = notesSnapshot.docs.map((doc) => {
        const noteData = convertTimestamps(doc.data());
        // Destructure to exclude clientLastName
        const { clientLastName, clientProfilePicture, ...filteredNoteData } =
          noteData;
        return filteredNoteData;
      });
      setSelectedClientNotes(notesData);

      const preAdmissionDoc = await getDoc(
        doc(db, "preAdmissionAssessments", selectedClient)
      );
      const preAdmissionData = preAdmissionDoc.data();
      setSelectedClientPreAdmission(convertTimestamps(preAdmissionData));

      const treatmentPlansDoc = await getDoc(
        doc(db, "treatmentPlans", selectedClient)
      );
      const treatmentPlansData = treatmentPlansDoc.data();
      setSelectedClientTreatmentPlans(convertTimestamps(treatmentPlansData));
    }
  };

  const formatNotesByType = (notes) => {
    const notesByType = {};
    const generalNotes = [];

    notes.forEach((note) => {
      const noteType = note.noteType || "Unknown";
      const content = note.noteContent || "No content available";
      const effectiveDateAndTime = note.effectiveDateTime || "Unknown date";

      // Check if the note is general (handoverNote or groupNote)
      if (noteType === "handoverNote" || noteType === "groupNote") {
        generalNotes.push(
          `Type: ${noteType}\nContent: ${content}\nEffective Date and Time: ${effectiveDateAndTime}`
        );
      } else {
        // Otherwise, treat it as client-specific
        const clientName = note.clientFirstName || "Unknown";
        const clientId = note.clientId || "Unknown";

        if (!notesByType[noteType]) {
          notesByType[noteType] = [];
        }

        notesByType[noteType].push(
          `Client: ${clientName} (ID: ${clientId})\nContent: ${content}\nEffective Date and Time: ${effectiveDateAndTime}`
        );
      }
    });

    let formattedNotes = "";

    // Format client-specific notes
    for (const [type, notesList] of Object.entries(notesByType)) {
      formattedNotes += `Notes type "${type}":\n${notesList.join("\n\n")}\n\n`;
    }

    // Format general notes separately
    if (generalNotes.length > 0) {
      formattedNotes += `General Notes:\n${generalNotes.join("\n\n")}\n\n`;
    }

    return formattedNotes;
  };

  async function getOpenAIResponse(prompt) {
    console.log("Prompt:", prompt);
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4o-mini",
          messages: [
            { role: "system", content: "You are a helpful assistant." },
            { role: "user", content: prompt },
          ],
          max_tokens: 16384,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );

      const promptTokens = response.data.usage.prompt_tokens;
      const completionTokens = response.data.usage.completion_tokens;
      const totalTokens = response.data.usage.total_tokens;

      setPromptTokens(promptTokens);
      setResponseTokens(completionTokens);

      return response.data.choices[0].message.content;
    } catch (error) {
      console.error("Error fetching OpenAI response:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        console.error("Request data:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
      throw error;
    }
  }

  function queryRole() {
    onSnapshot(
      query(roles, where(documentId(), "==", userIdState)),
      (querySnapshot) => {
        const doc = querySnapshot.docs[0];
        if (doc && doc.exists) {
          const role = doc.data().role;
          const photoUrlFromDb = doc.data().photoUrl;
          // Mettre à jour le nom et la photo avec ceux de Firestore s'ils existent

          if (photoUrlFromDb) {
            setPhotoUrl(photoUrlFromDb);
          } else if (currentUser) {
            setPhotoUrl(currentUser.photoURL);
          }
        } else {
          console.log("Problem loading roles");
        }
      }
    );
  }

  useEffect(() => {
    if (currentUser) {
      queryRole();
    } else {
      console.log("No user id");
    }
  }, [currentUser]);

  const handleGenerateSummary = async (currentPrompt) => {
    let clientSpecificInfo = "";

    if (selectedClient !== "All Clients") {
      clientSpecificInfo = generateSummaryForSelectedClient();
    } else {
      clientSpecificInfo = generateSummaryForAllClients();
    }

    const newInitialPrompt = `
      ${clientSpecificInfo}
      ${enrichedInterfacePrompt}
      All dates are in ISO 8601 format (YYYY-MM-DDTHH:MM:SSZ).
      Q: ${currentPrompt}
    `;

    setInitialPrompt(newInitialPrompt);

    const conversationHistoryText = conversationHistory
      .map((entry) => `A: ${entry.response}`)
      .join("\n\n");
    const promptToUse = `${newInitialPrompt}${
      conversationHistoryText ? conversationHistoryText + "\n" : ""
    }Q: ${currentPrompt}`;

    if (
      (selectedClient === "All Clients" && clientInfo.length > 0) ||
      (selectedClient !== "All Clients" && selectedClientInfo)
    ) {
      setFetchingResponse(true);

      try {
        const aiResponse = await getOpenAIResponse(promptToUse);

        setResponse(aiResponse);
        return aiResponse;
      } catch (error) {
        console.error("Error fetching AI response:", error);
        return null;
      } finally {
        setFetchingResponse(false);
      }
    }
    return null;
  };

  const enrichedInterfacePrompt = ` 
  
 Create a user interface for an application called "Rehapp," which is an Electronic Health Record (EHR) designed for rehabilitation centers. The application includes multiple sections and key functionalities to manage clients, rooms, documents, data, and support. Here are the details of the interface components:

Sidebar Navigation Menu:
- A fixed sidebar on the left of the screen includes sections for client management (Clients, Notes, Room availability, Room booking), CRM, and administration (Metrics, Export Data, Documents).
- At the bottom, there are links to "My Profile" and "Support."

"Room booking" Section:
- This section shows a monthly calendar view with client names, nights booked per date, and expected lengths of stay (L.O.S).
- Days are color-coded: blue for booked nights, green for the current date, and red for upcoming departures.
- A summary of total nights booked is displayed at the bottom of the calendar.

"Room availability" Section:
- This section displays a bar chart showing the number of available rooms for a specified date range.
- A date selector allows users to customize the displayed period.

"Notes" Section:
- The notes management interface includes a list of notes by date, client, and content.
- Each note is categorized (e.g., "Aftercare note," "Medical note") and can be filtered by note type, client name, and date.

Search and Add Functionality:
- A search bar at the top allows real-time client searches.
- A "+" button enables adding a new client or note, depending on the current section.

Client Profile Page:
- Each client profile contains tabs for general information, pre-admission, admission forms, clinical information, and notes.
- Key information, such as the client's name, age, and assigned therapist, is visible at the top.
- An "Add Alert" button allows adding alerts for the client (e.g., reminders, allergies).

"Support" Section:
- Displays a list of support tickets with ticket number, name, issue type, priority, and current status.
- A "Add new ticket" button opens a form to submit a new support request, including fields for name, description, priority, and issue type.

"Export Data" Section:
- Shows a list of clients with information that can be filtered by name, nationality, primary addiction, and length of stay.
- A button enables exporting the displayed data.

"Documents" Section:
- Allows document upload with dedicated sections like "Admission Forms" and "Clinical Documents."
- Each document category is displayed with an upload option for each file.

"CRM" Section:
- Uses a Kanban view to manage leads, with columns such as "New Leads," "Contacted," "Qualified," and "Incoming."
- Each lead is displayed with gender, country, and type of addiction.

"My Profile" Section:
- Allows the user to view and edit profile information, including photo, email, name, and phone number.
- A "Log Out" button is available at the bottom.

Use modern UI elements, with color-coded status indicators (e.g., green for "In House," red for "Primary Addiction") for better readability. The interface should be clear, intuitive, and suitable for intensive use in a healthcare setting.
  `;

  const generateSummaryForSelectedClient = () => {
    const notesSummary =
      selectedClientNotes.length > 0
        ? formatNotesByType(selectedClientNotes)
        : "No notes available.";

    return `
      Here is the selected client information:
      ${JSON.stringify(selectedClientInfo, null, 2)}

       Here are the notes for the selected client:
    ${notesSummary}

      Pre-admission Data:
      ${
        selectedClientPreAdmission
          ? JSON.stringify(selectedClientPreAdmission, null, 2)
          : "No pre-admission data available."
      }

      Treatment Plans:
      ${
        selectedClientTreatmentPlans
          ? JSON.stringify(selectedClientTreatmentPlans, null, 2)
          : "No treatment plans available."
      }
    `;
  };

  const generateSummaryForAllClients = () => {
    const inHouseClients = clientInfo.filter(
      (client) => client.clientStatus === "In House"
    );

    const inHouseClientsList = inHouseClients
      .map((client) => `- ${client.firstName} (${client.clientIdentifier})`)
      .join("\n");

    const maleCount = clientInfo.filter(
      (client) => client.gender === "Male"
    ).length;
    const femaleCount = clientInfo.filter(
      (client) => client.gender === "Female"
    ).length;
    const totalClients = maleCount + femaleCount;

    const addictionDistribution = {};
    inHouseClients.forEach((client) => {
      const addiction = client.primaryAddiction || "Unknown";
      addictionDistribution[addiction] =
        (addictionDistribution[addiction] || 0) + 1;
    });

    const addictionDistributionList = Object.entries(addictionDistribution)
      .map(([addiction, count]) => `- ${addiction}: ${count}`)
      .join("\n");

    const dischargeByMonth = {};
    inHouseClients.forEach((client) => {
      const dischargeDate = client.projectedResidentialDischargeDate;
      if (dischargeDate) {
        const monthYear = new Date(dischargeDate).toISOString().slice(0, 7);
        dischargeByMonth[monthYear] = dischargeByMonth[monthYear] || [];
        dischargeByMonth[monthYear].push(client.clientIdentifier);
      }
    });

    const dischargeSummary = Object.entries(dischargeByMonth)
      .map(
        ([monthYear, clients]) =>
          `For ${monthYear}, the In House clients with scheduled discharges are: ${clients.join(
            ", "
          )}`
      )
      .join("\n");

    const promptIntroduction = `
      Here is the list of In House clients with their client identifiers:
      ${inHouseClientsList}

      Scheduled discharges by month for In House clients:
      ${dischargeSummary}

      Addiction distribution among In House clients:
      ${addictionDistributionList}
    `;

    // const notesSummary =
    //   clientNotes.length > 0
    //     ? clientNotes.map((note) => JSON.stringify(note, null, 2)).join("\n")
    //     : "No notes available.";

    const notesSummary =
      clientNotes.length > 0
        ? formatNotesByType(clientNotes)
        : "No notes available.";

    const clientInfoSummary = clientInfo
      .map((info) => {
        const clientName = info.firstName || "Unknown";
        const clientIdentifier = info.clientIdentifier || "Unknown";
        const clientStatus = info.clientStatus || "Unknown";
        const age = info.age ? `${info.age} years old` : "Unknown age";

        const admissionDate = info.dateOfAdmission
          ? new Date(info.dateOfAdmission).toISOString()
          : "Unknown admission date";
        const dischargeDate = info.projectedResidentialDischargeDate
          ? new Date(info.projectedResidentialDischargeDate).toISOString()
          : "Unknown discharge date";

        const primaryAddiction = info.primaryAddiction || "Unknown addiction";
        const country = info.country || "Unknown country";

        return `${clientName}, with client identifier ${clientIdentifier}, has the status "${clientStatus}". They were admitted on ${admissionDate} and are projected to be discharged on ${dischargeDate}. Their primary addiction is ${primaryAddiction}, and they are from ${country}.`;
      })
      .join("\n\n");

    const clientMetricsSummary = `
      Here are the client metrics:
      - In House Count: ${clientMetrics.inHouseCount}
      - Other Status Count: ${clientMetrics.otherStatusCount}
      - Male Count: ${clientMetrics.maleCount}
      - Female Count: ${clientMetrics.femaleCount}

      Status Distribution:
      ${Object.entries(clientMetrics.statusDistribution)
        .map(([status, count]) => `  - ${status}: ${count}`)
        .join("\n")}

      In House Primary Addiction Distribution:
      ${Object.entries(clientMetrics.inHousePrimaryAddictionDistribution)
        .map(([addiction, count]) => `  - ${addiction}: ${count}`)
        .join("\n")}

      All Clients Primary Addiction Distribution:
      ${Object.entries(clientMetrics.allPrimaryAddictionDistribution)
        .map(([addiction, count]) => `  - ${addiction}: ${count}`)
        .join("\n")}
    `;

    return `
      ${promptIntroduction}
      Here is the global client info:
      ${clientInfoSummary}

      Here are the notes from the last two weeks:
      ${notesSummary}

      ${clientMetricsSummary}
    `;
  };

  const handleSendClick = async () => {
    if (prompt.trim() !== "") {
      const currentPrompt = prompt;

      const promptTokenCount = countTokens(currentPrompt);
      setPromptTokens(promptTokenCount);

      setUserQuestion(currentPrompt);
      setSentQuestion(currentPrompt);
      setPrompt("");
      setAnswerIsLoading(true);
      setShowInstructions(false);

      try {
        const aiResponse = await handleGenerateSummary(currentPrompt);
        const responseTokenCount = countTokens(aiResponse);
        setResponseTokens(responseTokenCount);
        await addDoc(collection(db, "prompts"), {
          content: currentPrompt,
          response: aiResponse,
          timestamp: Timestamp.now(),
          username: currentUser.displayName,
          clientTarget: selectedClient === "All Clients" ? "All Clients" : `${selectedClientInfo.firstName} ${selectedClientInfo.lastName}`,
        });
       

        if (aiResponse) {
          const responseTokensCount = estimateTokens(aiResponse);
          setResponseTokens(responseTokensCount);
          updateConversationHistory(currentPrompt, aiResponse);
          await decrementAICounter();
        }
      } catch (error) {
        console.error("Error generating summary:", error);
      } finally {
        setAnswerIsLoading(false);
      }
    }
  };

  const updateConversationHistory = (question, response) => {
    setConversationHistory((prevHistory) => {
      const updatedConversation = [...prevHistory, { question, response }];

      // Garde uniquement les 5 dernières questions-réponses
      const limitedConversation = updatedConversation.slice(-5);

      saveConversation(limitedConversation); // Enregistre la conversation dans Firestore
      return limitedConversation;
    });
  };

  useEffect(() => {
    if (answerIsLoading) {
      executeGenerateSummary();
    }
  }, [answerIsLoading]);

  const executeGenerateSummary = async () => {
    const currentPrompt = prompt; // La question actuelle de l'utilisateur
    await handleGenerateSummary(currentPrompt);
    // setAnswerIsLoading(false);
  };

  useEffect(() => {
    fetchGlobalClientData();
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status, action } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      setShowHelpButton(true);
    } else if ([ACTIONS.CLOSE].includes(action)) {
      setRun(false);
      setShowHelpButton(true);
    }
  };

  const styleModalGeneral = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 600,
    backgroundColor: "white",
    borderRadius: 5,
    boxShadow: 24,
  };

  const [openAddClient, setOpenAddClient] = useState(false);

  const handleCloseAddClient = () => setOpenAddClient(false);

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setPrompt(newValue);

    if (newValue.length > 0 && showChips) {
      setChipsDisappearing(true);
      setTimeout(() => {
        setShowChips(false);
        setChipsDisappearing(false);
      }, 300); // Durée de l'animation
    } else if (newValue.length === 0 && !showChips) {
      setShowChips(true); // Réaffiche les chips si le champ est vide
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieLoader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (response) {
      setIsTypingFinished(false); // Réinitialiser l'état avant chaque nouvelle réponse
    }
  }, [response]);

  useEffect(() => {
    if (response) {
      setShowTypedText(true);
    }
  }, [response]);

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Empêcher le saut de ligne lorsque la touche "Enter" est pressée
      if (!answerIsLoading && remainingPrompts > 0 && prompt.length > 0) {
        handleSendClick();
      }
    }
  };

  return (
    <div className="right-container-mobile" style={{ paddingLeft: "5px" }}>
      <Joyride
        steps={steps}
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
        run={run}
        callback={handleJoyrideCallback}
        disableScrolling={true}
        disableBeacon={true}
        styles={customStyles}
      />
      <Modal
        open={openAddClient}
        onClose={handleCloseAddClient}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalGeneral}>
          <AddClient closemodal={handleCloseAddClient} />
        </Box>
      </Modal>

      <div
        className="top-component-list-clients"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "50px",
        }}
      >
        {" "}
      </div>
      <div
        id="access-client"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "70vh",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <div
            style={{
              height: "80vh",
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              paddingTop: "10px",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {showInstructions && (
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={logoAi}
                    width={40}
                    height={35}
                    alt=""
                    style={{
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                      border: "1px solid lightgrey",
                      borderRadius: "10px",
                      padding: "10px",
                    }}
                  />
                </div>

                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    paddingTop: "10px",
                    textAlign: "center",
                  }}
                >
                  {translate("AskMeAnything")}
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    paddingTop: "10px",
                    color: "#828282",
                    width: "100%",
                    paddingTop: "10px",
                    textAlign: "center",
                  }}
                >
                  {translate("PromptInstruction")}
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "red",
                    width: "100%",
                    textAlign: "center",
                    fontWeight: "500",
                  }}
                >
                  {translate("AnonymizedRequests")}
                </div>
              </div>
            )}

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                paddingTop: "20px",
              }}
            >
              {conversationHistory.map((entry, index) => (
                <div key={index} style={{ marginBottom: "20px" }}>
                  {/* Affiche la question */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flexDirection: "row",
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: "#2696fe",
                        borderRadius: "20px",
                        padding: "10px",
                        marginBottom: "20px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        textAlign: "right",
                        width: "auto",
                        paddingRight: "20px",
                        paddingLeft: "20px",
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      {entry.question}
                    </span>
                    <div className="avatar-container-ai">
                      <Avatar
                        src={photoUrl}
                        style={{
                          width: "25px",
                          height: "25px",
                          border: "1px solid lightgrey",
                        }}
                      />
                    </div>
                  </div>

                  {/* Affiche la réponse */}
                  {entry.response && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                          borderRadius: "40px",
                          width: "45px",
                          height: "45px",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          marginTop: "15px",
                          marginRight: "15px",
                        }}
                      >
                        <img src={logoAi} width={20} height={17} alt="" />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{
                            // backgroundColor: "#e2edfe",
                            backgroundColor: "#f8f8f8",
                            borderRadius: "20px",
                            padding: "10px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            textAlign: "left",
                            width: "auto",
                            paddingRight: "20px",
                            paddingLeft: "20px",
                            fontSize: "12px",
                          }}
                        >
                          <ReactMarkdown
                            children={entry.response}
                            style={{
                              whiteSpace: "pre-line",
                              lineHeight: "1.6",
                              paddingTop: "5px",
                            }}
                          />
                        </span>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "10px",
                            paddingRight: "10px",
                            paddingTop: "5px",
                          }}
                        >
                          <Tooltip title="Copy to clipboard" arrow>
                            <ContentCopyIcon
                              style={{
                                cursor: "pointer",
                                marginRight: "10px",
                                color: "lightgrey",
                                fontSize: "14px",
                              }}
                              onClick={() => {
                                navigator.clipboard.writeText(entry.response);
                                setCopyMessage(true);
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Send via Gmail" arrow>
                            <EmailIcon
                              style={{
                                cursor: "pointer",
                                color: "lightgrey",
                                fontSize: "14px",
                              }}
                              onClick={() => {
                                const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&body=${encodeURIComponent(
                                  entry.response
                                )}`;
                                window.open(gmailLink, "_blank");
                              }}
                            />
                          </Tooltip>
                          <Snackbar
                            open={copyMessage}
                            autoHideDuration={2000}
                            onClose={() => setCopyMessage(false)}
                            message="Copied to clipboard"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}

              {answerIsLoading && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "row",
                  }}
                >
                  <span
                    style={{
                      backgroundColor: "#2696fe",
                      borderRadius: "20px",
                      padding: "10px",
                      marginBottom: "20px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      textAlign: "right",
                      width: "auto",
                      paddingRight: "20px",
                      paddingLeft: "20px",
                      color: "white",
                    }}
                  >
                    {sentQuestion}
                  </span>
                  <div className="avatar-container-ai">
                    <Avatar
                      src={photoUrl}
                      style={{
                        width: "25px",
                        height: "25px",
                        border: "1px solid lightgrey",
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            {/* Réponse en cours */}
            {answerIsLoading ? (
              <div
                style={{
                  width: "auto",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignContent: "flex-start",
                  alignItems: "center",
                  alignSelf: "flex-start",
                  textAlign: "left",
                  marginLeft: "13%",
                }}
              >
                <Lottie options={defaultOptions} height={40} width={100} />{" "}
                <span
                  style={{
                    fontSize: "16px",
                    fontStyle: "italic",
                    fontWeight: "500",
                    color: "#a4a4a4",
                  }}
                >
                  Searching
                </span>
              </div>
            ) : (
              ""
            )}

            {/* Boîte de texte pour poser une nouvelle question */}
            <div
              style={{
                width: "100%",
                paddingLeft: "0px",
                paddingRight: "0px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                alignSelf: "center",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  width: "90%",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  marginBottom: "100px",
                  marginTop: "10px",
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    color: "red",
                    textAlign: "center",
                    fontSize: "10px",
                  }}
                >
                  {remainingPrompts > 0
                    ? `**Demo version** - Remaining prompts: ${remainingPrompts}`
                    : "You've used all the credits of the demo version"}
                </span>
                <TextField
                  variant="outlined"
                  rows={prompt.length > 0 ? 4 : 1}
                  multiline
                  onChange={handleInputChange}
                  value={prompt}
                  fullWidth
                  style={{ fontSize: "12px" }}
                  onKeyPress={handleInputKeyPress}
                />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "0px",
                    alignContent: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  {/* <span>{prompt.length}/1000</span> */}

                  <div
                    style={{
                      width: "100%",
                      marginRight: "0px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <label
                      htmlFor="client-select"
                      style={{
                        fontWeight: "500",
                        paddingRight: "5px",
                        width: "auto",
                        textAlign: "right",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        fontSize: "12px",
                      }}
                    >
                      Search on
                    </label>
                    <select
                      id="client-select"
                      value={selectedClient}
                      onChange={handleClientChange}
                      style={{
                        width: "50%",
                        padding: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        backgroundColor: "#fff",
                        fontSize: "10px",
                        height: "30px",
                        overflow: "auto", // For better height management
                      }}
                    >
                      <option value="All Clients">All Clients</option>
                      {clientsNotAnonymized.map((client) => (
                        <option key={client.id} value={client.id}>
                          {client.firstName} {client.lastName}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div
                    style={{
                      fontSize: "12px",
                      color: "gray",
                      marginTop: "10px",
                    }}
                  >
                    <div>
                      Prompt size: {promptTokens} tokens (
                      <span
                        style={{
                          fontWeight: "bold",
                          color:
                            (promptTokens / 128000) * 100 > 80
                              ? "red"
                              : "green",
                        }}
                      >
                        {((promptTokens / 128000) * 100).toFixed(2)}%
                      </span>{" "}
                      of max 128000)
                    </div>
                    <div>
                      Response size: {responseTokens} tokens (
                      <span
                        style={{
                          fontWeight: "bold",
                          color:
                            (responseTokens / 16000) * 100 > 80
                              ? "red"
                              : "green",
                        }}
                      >
                        {((responseTokens / 16000) * 100).toFixed(2)}%
                      </span>{" "}
                      of max 16000)
                    </div>
                  </div>
                </div>
              </div>

              <div ref={endOfMessagesRef} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
