import { Avatar, AvatarGroup } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function getStatusStyle(status) {
  const statusColors = {
    "In House": "green",
    Aftercare: "#e7900d",
    Incoming: "#6366f1",
    "Miracles@home": "#f02ddd",
    Inactive: "#f02d2d",
    "Hot Lead": "#2dc9f0",
    "Clinical Assessment": "#f0742d",
    "Continued Therapy": "#bc3efc",
    "Denied Admission": "#8a2be2", // Supposant que "Denied" est pour "chip-denied-admission"
    Potential: "#0d47a1", // Bleu foncé
  };

  return {
    width: "95px",
    color: "white",
    paddingLeft: "3px",
    marginRight: "12px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    backgroundColor: statusColors[status] || "#e0e0e0",
    borderRadius: "4px",
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
  };
}

function ClientRowAvailability({
  clientStatus,
  firstName,
  lastName,
  gender,
  nationality,
  country,
  primaryAddiction,
  secondaryAddiction,
  inActiveRecovery,
  eta,
  googleReviewDone,
  personalEmail,
  admissionDate,
  durationOfStay,
  flight,
  paymentDetail,
  vipTransfer,
  passportStatus,
  profilPicture,
  idClient,
  clientData,
}) {
  const navigate = useNavigate();
  const [shouldNavigate, setShouldNavigate] = React.useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const handleNavigate = () => {
    setShouldNavigate(true);
  };

  React.useEffect(() => {
    if (shouldNavigate) {
      navigate(`/client/${idClient}`, {
        state: clientData, // Passez l'objet client complet
      });
    }
  }, [shouldNavigate]);
  const fullName = firstName + " " + lastName;
  const textStyle = {
    width: "95px",
    paddintRight: "10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  const textStyle2 = {
    width: "95px",
    paddingRight: "10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
    justifyContent: "center",
  };

  const textStyle3 = {
    width: "140px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: "10px",
  };

  const textStyle4 = {
    width: "110px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingRight: "10px",
    paddingLeft: "5px",
  };

  const textStyle5 = {
    width: "120px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingRight: "10px",
    fontSize: "12px",
  };

  const textStyle6 = {
    width: "120px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: "10px",
    fontSize: "14px",
    fontWeight: "500",
    color: "black",
  };

  const textStyleGlobalWrap = {
    width: "180px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: "10px",
  };

  return (
    <div
      style={{
        paddingLeft: "10px",
        cursor: "pointer",
        marginBottom: "5px",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleNavigate}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "5px",
          // padding: "10px",
          paddingLeft: "10px",
          color: "grey",
          fontSize: "14px",
          fontWeight: "450",
          height: "60px",
          backgroundColor: isHovered ? "#d9e0ea" : "white",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "180px",
          }}
        >
          <div style={{ width: "60px" }}>
            <Avatar
              src={profilPicture}
              alt="Profile Picture"
              sx={{
                marginRight: "10px",
                borderRadius: "8px", // Ajustez cette valeur pour contrôler l'arrondi des bords
                width: 50, // Ajustez la largeur pour obtenir une forme carrée
                height: 50, // Ajustez la hauteur pour obtenir une forme carrée
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "120px",
              height: "auto",
              justifyContent: "center",
            }}
          >
            <div style={textStyle6}>{fullName}</div>
            <div style={textStyle5}>{country}</div>
          </div>
        </div>

        <div style={textStyle4}>{primaryAddiction}</div>
        <div style={{ ...textStyle, paddingLeft: "5px" }}>{eta}</div>
        <div style={{ ...textStyle, paddingLeft: "5px" }}>{admissionDate}</div>
        <div style={{ ...textStyle, paddingLeft: "5px" }}>{durationOfStay}</div>
        {/* <div style={textStyle}>{personalEmail}</div> */}
        <div style={{ ...textStyle, paddingLeft: "5px" }}>{flight}</div>
        <div style={{ ...textStyle }}>{paymentDetail}</div>
        <div style={{ ...textStyle, paddingLeft: "5px" }}>{passportStatus}</div>
        <div style={textStyle2}>{vipTransfer}</div>
      </div>
    </div>
  );
}

export default ClientRowAvailability;
