import React, { useContext, useState } from "react";
import { Box, Button, Modal, Slide } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import TextFieldComponentLarge from "../TextFieldComponentLarge";
import TextFieldComponentExtraLarge from "../TextFieldComponentExtraLarge";
import AddButtonRound from "../Buttons/AddButtonRound";
import AddObjectiveForm from "./AddObjectiveForm";
import TopTitleFormComponent from "./TopTitleFormComponent";
import {
  collection,
  serverTimestamp,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { showCustomToast } from "../Misc/CustomToast";
import { LanguageContext } from "../../context/LanguageContext";
import TextFieldComponentV2 from "../TextFieldComponentV2";
import TopTitleFormComponentMobileV2 from "./TopTitleFormComponentMobileV2";
import TextFieldComponentV2Mobile from "../TextFieldComponentV2Mobile";
import AddButtonRoundMobileV4 from "../Buttons/AddButtonRoundMobileV4";
import AddObjectiveFormMobileV2 from "./AddObjectiveFormMobileV2";

function EditTreatmentPlanDesktopMobileV2(props) {
  const { translate } = useContext(LanguageContext);
  const { currentUser } = useAuth();
  //Tous les states des données récupérées par client

  const styleModalGeneral = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "80%",
    borderRadius: 5,
    // overflowY: "scroll",
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const [isLoading, setIsloading] = useState(false);
  const [presentingIssues, setPresentingIssues] = useState(
    props.presentingIssues || ""
  );
  const [longTermGoals, setLongTermGoals] = useState(props.longTermGoals || "");
  const [openObjectiveForm, setOpenObjectiveForm] = useState(false);
  const [objectives, setObjectives] = useState([props.objectives] || []);

  const [editingObjectiveIndex, setEditingObjectiveIndex] = useState(null);
  const [editedObjective, setEditedObjective] = useState(null);
  const [editedIntervention, setEditedIntervention] = useState(null);

  const handleEditObjective = (index) => {
    setEditingObjectiveIndex(index);
    setEditedObjective(objectives[0][index].objective);
    setEditedIntervention(objectives[0][index].intervention);
  };

  const saveEditedObjective = () => {
    const updatedObjectives = [...objectives[0]];
    updatedObjectives[editingObjectiveIndex] = {
      ...updatedObjectives[editingObjectiveIndex],
      objective: editedObjective,
      intervention: editedIntervention,
    };
    setObjectives([updatedObjectives]);
    setEditingObjectiveIndex(null);
  };

  const handlePresentingIssuesChange = (event) => {
    setPresentingIssues(event);
  };

  const handleLongTermGoalsChange = (event) => {
    setLongTermGoals(event);
  };

  const handleOpenObjectiveForm = () => setOpenObjectiveForm(true);

  const handleCloseObjectiveForm = () => setOpenObjectiveForm(false);

  const addObjective = (objective, intervention) => {
    setObjectives([...objectives, { objective, intervention }]);
  };

  const updateTreatmentPlanAndObjectives = async () => {
    if (!presentingIssues || !longTermGoals) {
      showCustomToast("Please fill in all required fields", "error");
      return;
    }

    setIsloading(true);
    const treatmentPlanDoc = {
      presentingIssues,
      longTermGoals,
      createdAt: serverTimestamp(),
      createdBy: currentUser?.displayName,
    };

    try {
      // Utilisez setDoc et spécifiez clientId comme document ID
      const treatmentPlanRef = doc(db, "treatmentPlans", props.clientId);
      await updateDoc(treatmentPlanRef, treatmentPlanDoc);

      const objectivesCollectionRef = collection(
        treatmentPlanRef,
        "objectives"
      );

      for (const objective of objectives[0]) {
        if (objective.id) {
          const objectiveRef = doc(objectivesCollectionRef, objective.id);
          await updateDoc(objectiveRef, objective);
        } else {
          console.error("Objective ID is missing or undefined");
        }
      }

      setIsloading(false);
      props.closemodal();
      showCustomToast(translate("TreatmentPlanUpdated"), "success");
    } catch (error) {
      console.error("Error saving treatment plan and objectives: ", error);
      setIsloading(false);
      showCustomToast(translate("FailedToSaveTreatmentPlan"), "error");
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Modal
        open={openObjectiveForm}
        onClose={handleCloseObjectiveForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Slide direction="left" in={openObjectiveForm}>
          <Box
            sx={{
              position: "fixed",
              top: "5%",
              right: "0%",
              width: "90%",
              height: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              marginRight: "15px",
              pl: 0,
              pr: 0,
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
              zIndex: -1,
            }}
          >
            <AddObjectiveFormMobileV2
              addObjective={addObjective}
              closemodal={handleCloseObjectiveForm}
            />
          </Box>
        </Slide>
      </Modal>
      <div
        // className="inputAndLabelModalContainerTitle2"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <div
          className="line1-modal-percent"
          style={{ borderBottom: "1px solid lightgrey" }}
        >
          <TopTitleFormComponentMobileV2
            title={translate("EditTreatmentPlan")}
            closemodal={props.closemodal}
          />
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "4px",
            height: "75%",
            overflowY: "scroll",
            paddingLeft: "5px",
            // overflowX: "scroll",
          }}
        >
          <div
            style={{ marginLeft: 0, paddingLeft: "30px", paddingRight: "20px" }}
          >
            <TextFieldComponentV2Mobile
              title={translate("PresentingIssues")}
              isRequired={true}
              placeholder="type here"
              value={presentingIssues}
              onChange={handlePresentingIssuesChange}
              width="100%"
            />

            <div style={{ marginTop: "10px" }}>
              <TextFieldComponentV2Mobile
                title={translate("LongTermGoals")}
                isRequired={true}
                value={longTermGoals}
                onChange={handleLongTermGoalsChange}
                width="100%"
                placeholder="Format:
          Goal 1:
          Goal 2:
          Goal 3:
          Etc."
              />
            </div>
          </div>

          <div
            className="SubTitleBold"
            style={{
              fontWeight: "600",
              paddingLeft: "10px",
              fontSize: "16px",
              paddingLeft: "30px",
              paddingTop: "10px",
            }}
          >
            {translate("ObjectivesAssociatedInterventions")}
          </div>

          <div className="objectives-list" style={{ paddingLeft: "30px" }}>
            {objectives[0].map((objective, index) => (
              <div key={index} className="objective-item">
                <div className="title-objective-treatment">
                  Objective {index + 1}:
                </div>
                {editingObjectiveIndex === index ? (
                  <input
                    value={editedObjective}
                    onChange={(e) => setEditedObjective(e.target.value)}
                  />
                ) : (
                  <div
                    className="text-container"
                    style={{
                      marginTop: "0px",
                      marginBottom: "0px",
                      backgroundColor: "rgb(237, 237, 237)",
                      paddingLeft: "4px",
                      paddingRight: "4px",
                      paddingTop: "2px",
                      paddingBottom: "2px",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                  >
                    {objective.objective}
                  </div>
                )}
                <div className="title-objective-treatment">
                  Intervention {index + 1}:
                </div>
                {editingObjectiveIndex === index ? (
                  <input
                    value={editedIntervention}
                    onChange={(e) => setEditedIntervention(e.target.value)}
                  />
                ) : (
                  <div
                    className="text-container"
                    style={{
                      marginTop: "0px",
                      marginBottom: "10px",
                      backgroundColor: "rgb(237, 237, 237)",
                      paddingLeft: "4px",
                      paddingRight: "4px",
                      paddingTop: "2px",
                      paddingBottom: "2px",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                  >
                    {objective.intervention}
                  </div>
                )}
                {editingObjectiveIndex === index ? (
                  <div>
                    <button onClick={saveEditedObjective}>Save</button>
                    <button onClick={() => setEditingObjectiveIndex(null)}>
                      Cancel
                    </button>
                  </div>
                ) : (
                  <button onClick={() => handleEditObjective(index)}>
                    Edit
                  </button>
                )}
              </div>
            ))}
          </div>

          <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <div
              style={{
                marginLeft: "0px",
                display: "flex",
                flexDirection: "row",
                height: "30px",
                borderRadius: "30px",
                border: "1px solid lightgrey",
                width: "100%",
                marginTop: "25px",
                marginBottom: "30px",
                paddingLeft: "10px",
                paddingRight: "10px",
                justifyContent: "space-between",
                marginLeft: "0px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                {translate("AddObjective")}
              </div>
              <div
                style={{
                  marginBottom: "0px",
                }}
              >
                <AddButtonRoundMobileV4 handleOpen={handleOpenObjectiveForm} />
              </div>
            </div>
          </div>

          {/* <div
            className="objectives-and-reviews-container"
            style={{ marginBottom: "20px", flexDirection: "row" }}
          >
            <AddButtonRound handleOpen={handleOpenObjectiveForm} />
          </div> */}
        </div>
        <div className="line4-modal-percent">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
              onClick={props.closemodal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={updateTreatmentPlanAndObjectives}
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : objectives.length < 1
                  ? {
                      backgroundColor: "gray",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                    }
              }
              disabled={isLoading || objectives.length < 1}
              //   onClick={handlePostRecord}
            >
              {isLoading ? "UPDATING..." : "UPDATE TREATMENT"}
            </Button>
          </>
        </div>
      </div>
    </div>
  );
}

export default EditTreatmentPlanDesktopMobileV2;
