import React, { useContext, useState } from "react";
import { Button, Checkbox } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import TopTitleFormComponent from "./TopTitleFormComponent";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadString,
} from "firebase/storage";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { updateDoc, doc as firestoreDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { showCustomToast } from "../Misc/CustomToast";
import SignaturePadComponentV2 from "../Signature/SignaturePadComponentV2";
import { LanguageContext } from "../../context/LanguageContext";

function AddConfidentialityPolicy(props) {
  const { translate } = useContext(LanguageContext);
  const { clientId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [signatureDataURL, setSignatureDataURL] = useState(null);
  const [isSignatureStarted, setIsSignatureStarted] = useState(false);
  const [text1, setText1] = useState(
    " Our rehab operates a strict confidentiality policy. Your records, both oral and written, will be kept in confidence, according to standards set down in UK, AUS, Europe, and US legislation."
  );
  const [text2, setText2] = useState(
    "This policy is designed to provide a maximum amount of trust between clients and staff. Any information given to staff by clients will be kept in confidence. However, clients should note that any disclosure to a member of the Clinical Team may be handed over to another member of the Clinical Team, as this is designed to provide clients with a holistic care approach and to ensure continuity of care."
  );
  const [text3, setText3] = useState(
    "There are a few important exceptions to when counselors may be required to break confidentiality: If ordered by court; if you appear to have a serious intention to harm yourself or another person; if a client discloses any information about the ongoing abuse of a minor or elder abuse; if you have broken the ground rules around drugs and alcohol (in this instance, other residents would be informed of your relapse)."
  );
  const [isSignatureValidated, setIsSignatureValidated] = useState(false);

  const handleCheckboxChange1 = (event) => {
    setChecked1(event.target.checked);
  };

  const handleCheckboxChange2 = (event) => {
    setChecked2(event.target.checked);
  };

  const handleCheckboxChange3 = (event) => {
    setChecked3(event.target.checked);
  };

  const handleSignatureSave = (dataURL) => {
    setSignatureDataURL(dataURL);
  };

  const uploadSignature = async () => {
    const storage = getStorage();
    const fileName = `${clientId}.png`;
    const signatureRef = ref(
      storage,
      `MandatoryForms/ConfidentialityPolicy/${fileName}`
    );

    await uploadString(signatureRef, signatureDataURL, "data_url");
    const downloadURL = await getDownloadURL(signatureRef);

    return downloadURL;
  };

  const handleFormSubmit = async () => {
    if (
      !checked1 ||
      !checked2 ||
      !checked3 ||
      !signatureDataURL ||
      !isSignatureValidated
    ) {
      showCustomToast(
        "Please agree to all the terms before submitting.",
        "error"
      );
    }

    try {
      setIsLoading(true);
      const signatureURL = await uploadSignature();
      const docRef = doc(db, "ConfidentialityPolicy", props.clientId);
      await setDoc(docRef, {
        checked1,
        checked2,
        checked3,
        text1,
        text2,
        text3,
        signatureURL,
        signedOn: serverTimestamp(),
      });

      const clientDocRef = firestoreDoc(db, "clients", props.clientId);
      await updateDoc(clientDocRef, {
        ConfidentialityPolicysOk: true,
        ConfidentialityPolicyDateSigned: serverTimestamp(),
      });

      showCustomToast(
        translate("ConfidentialityPolicyFormSubmitted"),
        "success"
      );

      setIsLoading(false);
      props.closemodal();
    } catch (e) {
      showCustomToast("Error adding document", "error");

      console.error("Error adding document: ", e);
    }
  };

  const allChecked = checked1 && checked2 && checked3;

  return (
    <>
      <div
        // className="inputAndLabelModalContainerTitle2"
        style={{ width: "auto", height: "100%" }}
      >
        <div
          className="line1-modal-percent"
          style={{ borderBottom: "1px solid lightgray" }}
        >
          <TopTitleFormComponent
            title={translate("ConfidentialityPolicyForm")}
            closemodal={props.closemodal}
          />
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "3px",
            height: "80%",
            overflowY: "scroll",
            // overflowX: "hidden",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <div className="SubTitleBold">
            By signing this document you acknowledge that you understand and
            agree to the following points:
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked1} onChange={handleCheckboxChange1} />
            <div className="block-text-check">{text1}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked2} onChange={handleCheckboxChange2} />
            <div className="block-text-check">{text2}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked3} onChange={handleCheckboxChange3} />
            <div className="block-text-check">{text3}</div>
          </div>

          <div style={{ width: "100%", marginBottom: "25px" }}>
            <SignaturePadComponentV2
              onSignatureSave={handleSignatureSave}
              signatureDataURL={signatureDataURL}
              onSignatureStart={() => setIsSignatureStarted(true)}
              onSignatureValidate={setIsSignatureValidated}
            />
          </div>
        </div>
        <div className="line4-modal-percent-bis">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
              onClick={props.closemodal}
            >
              {translate("Cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={handleFormSubmit}
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : !signatureDataURL || !isSignatureValidated || !allChecked
                  ? {
                      backgroundColor: "grey",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                    }
              }
              disabled={
                isLoading ||
                !signatureDataURL ||
                !allChecked ||
                !isSignatureValidated
              }
            >
              {isLoading ? translate("Saving") : translate("Save")}
            </Button>
          </>
        </div>
      </div>
    </>
  );
}

export default AddConfidentialityPolicy;
