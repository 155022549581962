import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import DatePickerWithTimeComponent from "../DatePickerWithTimeComponent";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { showCustomToast } from "../Misc/CustomToast";
import TopTitleFormComponent from "../forms/TopTitleFormComponent";

function AddQuickNote(props) {
  const location = useLocation();
  const state = location.state;

  const { currentUser } = useAuth();

  //Tous les states des données récupérées par client

  const [quickNoteText, setQuickNoteText] = useState("");
  const [effectiveDateTime, setEffectiveDateTime] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const handleClose2 = () => setOpen2(false);
  const [open2, setOpen2] = React.useState(false);

  const handleQuickNoteChange = (event) => {
    setQuickNoteText(event);
  };

  const handleDateTimeChange = (event) => {
    setEffectiveDateTime(event);
  };

  const handleAddQuickNote = async (note, clientID, effectiveDateTime) => {
    const noteData = {
      noteType: "quickNote",
      noteContent: note,
      clientId: clientID,
      createdAt: serverTimestamp(),
      effectiveDateTime: effectiveDateTime,
      doneBy: currentUser.displayName,
      clientFirstName: props.firstName,
      clientLastName: props.lastName,
      clientProfilePicture: props.profilePicture || "",
    };

    if (quickNoteText && effectiveDateTime) {
      setIsLoading(true);
      try {
        const docRef = await addDoc(collection(db, "notes"), noteData);
        showCustomToast(`Quick note successfully added!`, "success");

        setIsLoading(false);
        props.closemodal();
      } catch (error) {
        console.log("error adding note :", error);
        showCustomToast(`Problem when adding note`, "error");
      }
    } else {
      showCustomToast(
        `Some mandatory fields are empty. Please check.`,
        "error"
      );
    }
  };

  const handleSubmit = () => {
    handleAddQuickNote(quickNoteText, props.clientId, effectiveDateTime);
  };

  const handleChange = (event) => {
    setQuickNoteText(event.target.value);
  };

  return (
    <>
      <div
        style={{ width: "auto", height: "100%" }}
        // className="inputAndLabelModalContainerTitle2"
      >
        {/* <div className="inputAndLabelModalContainerTitle2"> */}
        <div className="line1-modal-percent">
          <TopTitleFormComponent
            title="ADD QUICK NOTE"
            closemodal={props.closemodal}
          />
          {/* <div className="modal-title">Add Quick Note</div>
          <CloseIcon
            style={{
              marginRight: "16px",
              color: "rgb(214, 213, 213)",
              cursor: "pointer",
            }}
            onClick={props.closemodal}
          /> */}
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "10px",
            height: "80%",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <div className="line2-column-modal-v2">
            <div className="label-textfield2">Note content</div>
            <TextField
              name="quickNote"
              value={quickNoteText}
              onChange={handleChange}
              style={{
                width: "95%",
                borderRadius: "5px",
                backgroundColor: "#f2eeef",
              }}
              placeholder="Type quick session note here"
              multiline
              rows={14}
            />
            <div
              className="line3-modal-nopad"
              style={{
                // marginLeft: "20px",

                paddingTop: "10px",
                marginBottom: "20px",
              }}
            >
              <DatePickerWithTimeComponent
                title="Effective date and time"
                value={effectiveDateTime}
                onChange={handleDateTimeChange}
                leftMargin="none"
              />
            </div>
          </div>
        </div>
        <div className="line4-modal-percent-bis">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
              onClick={props.closemodal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                    }
              }
              disabled={isLoading}
              onClick={handleSubmit}
            >
              {isLoading ? "ADDING NOTES..." : "ADD NOTES"}
            </Button>
          </>
        </div>
      </div>
    </>
  );
}

export default AddQuickNote;
