export default [
  {
    id: 1,
    textDropDownEn: "--Choose--",
  },

  {
    id: 2,
    textDropDownEn: "Home",
  },

  {
    id: 3,
    textDropDownEn: "Recovery Facility",
  },

  {
    id: 4,
    textDropDownEn: "Hospital",
  },
  {
    id: 5,
    textDropDownEn: "Other Rehab",
  },

  {
    id: 6,
    textDropDownEn: "Self-Discharged",
  },

  {
    id: 7,
    textDropDownEn: "Other",
  },
];
