import React from "react";
import GradingIcon from "@mui/icons-material/Grading";
import { IconButton } from "@mui/material";

function ReviewButtonMobileV2(props) {
  return (
    <IconButton
      // className="square-button-2-mobile-v2 review-button-color"
      title="Revoke"
      style={{ backgroundColor: "#ff6f50", color: "white" }}
      onClick={props.handleOpen}
    >
      <GradingIcon style={{ color: "white", fontSize: "12px" }} />
    </IconButton>
  );
}

export default ReviewButtonMobileV2;
