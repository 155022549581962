import React from "react";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"; // Change to RemoveRedEye icon

function ViewButton(props) {
  return (
    <IconButton
      aria-label="view"
      title="View"
      onClick={props.handleOpen2}
      style={{ backgroundColor: "#007bff", color: "white" }} // Define circle background and icon color
    >
      <RemoveRedEyeIcon />
    </IconButton>
  );
}

export default ViewButton;
