import React from "react";
import TitleSearchComponentNoFilterCenter from "./TitleSearchComponentNoFilterCenter";

function TitleComponentV2({ filters, setFilters }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        paddingBottom: "0px",
        overflowX: "auto",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        borderBottom: "3px solid lightgray",
        paddingLeft: "0px",
        height: "50px",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        marginLeft: "15px",
      }}
    >
      {/* <div
        style={{
          width: "auto",
          // borderLeft: "1px solid black"
          height: "0px",
        }}
      > */}
      <TitleSearchComponentNoFilterCenter width="180px" title="Full Name" />
      {/* </div> */}

      <TitleSearchComponentNoFilterCenter
        width="110px"
        title="Primary Addiction"
      />
      <TitleSearchComponentNoFilterCenter width="95px" title="Aka" />
      <TitleSearchComponentNoFilterCenter width="95px" title="Age" />
      <TitleSearchComponentNoFilterCenter width="95px" title="Arrival Date" />
      <TitleSearchComponentNoFilterCenter width="95px" title="I.D.D." />
      <TitleSearchComponentNoFilterCenter width="95px" title="I.L.S." />
      <TitleSearchComponentNoFilterCenter
        width="95px"
        title="Assigned Therapist"
      />
      <TitleSearchComponentNoFilterCenter width="95px" title="Room #" />
      <TitleSearchComponentNoFilterCenter width="95px" title="Alerts" />
    </div>
  );
}

export default TitleComponentV2;
