import React from "react";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

function AddButtonRoundMobileV4(props) {
  return (
    <IconButton
      // className="add-round-button"
      onClick={props.handleOpen}
      title={props.title}
      // size="small"
      style={{ backgroundColor: "#f0f0f0", margin: "0px" }} // Style the button background and margin
    >
      <AddIcon sx={{ fontSize: 11 }} />
    </IconButton>
  );
}

export default AddButtonRoundMobileV4;
