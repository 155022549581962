import React from "react";

export default function StatusRibonMobileV2(props) {
  return (
    <>
      <div onClick={props.onClick} style={{ cursor: "pointer" }}>
        {props.status === "In House" ? (
          <div className="ribon-container-mobile-v2 ribon-color-inhouse">
            In House
          </div>
        ) : props.status === "Aftercare" ? (
          <div className="ribon-container-mobile-v2 ribon-color-aftercare">
            Aftercare
          </div>
        ) : props.status === "Incoming" ? (
          <div className="ribon-container-mobile-v2 ribon-color-incoming">
            Incoming
          </div>
        ) : props.status === "Miracles@home" ? (
          <div className="ribon-container-mobile-v2 ribon-color-mathome">
            Miracles@home
          </div>
        ) : props.status === "Clinical Assessment" ? (
          <div className="ribon-container-mobile-v2 ribon-color-clinical-assessment">
            Clinical Assessment
          </div>
        ) : props.status === "Continued Therapy" ? (
          <div className="ribon-container-mobile-v2 ribon-color-continued-therapy">
            Continued Therapy
          </div>
        ) : props.status === "Hot Lead" ? (
          <div className="ribon-container-mobile-v2 ribon-color-hot-lead">
            Hot Lead
          </div>
        ) : props.status === "Denied Admission" ? (
          <div className="ribon-container-mobile-v2 ribon-color-denied-admission">
            Denied Admission
          </div>
        ) : props.status === "Potential" ? (
          <div className="ribon-container-mobile-v2 ribon-color-potential">
            Potential
          </div>
        ) : (
          <div className="ribon-container-mobile-v2 ribon-color-inactive">
            Inactive
          </div>
        )}
      </div>
    </>
  );
}
