import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import DatePickerWithTimeComponent from "../DatePickerWithTimeComponent";
import { useAuth } from "../../contexts/AuthContext";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { toast } from "react-toastify";
import { db } from "../../firebase";
import { showCustomToast } from "../Misc/CustomToast";
import TopTitleFormComponent from "../forms/TopTitleFormComponent";

function AddNonTherapyNote(props) {
  const location = useLocation();
  const state = location.state;

  const { currentUser } = useAuth();

  //Tous les states des données récupérées par client

  const [isLoading, setIsLoading] = useState(false);

  const [nonTherapyNote, setNonTherapyNote] = useState("");
  const [nonTherapyNoteDateTime, setNonTherapyNoteDateTime] = useState(
    new Date()
  );

  const handleNonTherapyNoteChange = (event) => {
    setNonTherapyNote(event.target.value);
  };

  const handleDateTimeChange = (event) => {
    setNonTherapyNoteDateTime(event);
  };

  const handleAddNonTherapyNote = async (note, clientID, effectiveDateTime) => {
    const noteData = {
      noteType: "supportStaffNote",
      noteContent: note,
      clientId: clientID,
      createdAt: serverTimestamp(),
      effectiveDateTime: effectiveDateTime,
      doneBy: currentUser.displayName,
      clientFirstName: props.firstName,
      clientLastName: props.lastName,
      clientProfilePicture: props.profilePicture || "",
    };

    if (nonTherapyNote && nonTherapyNoteDateTime) {
      setIsLoading(true);
      try {
        const docRef = await addDoc(collection(db, "notes"), noteData);
        showCustomToast(`Non therapy note successfully added!`, "success");

        setIsLoading(false);
        props.closemodal();
      } catch (error) {
        console.log("error adding note :", error);
        showCustomToast(`Problem when adding note`, "error");
      }
    } else {
      showCustomToast(
        `Some mandatory fields are empty. Please check.`,
        "error"
      );
    }
  };

  const handleSubmit = () => {
    handleAddNonTherapyNote(
      nonTherapyNote,
      props.clientId,
      nonTherapyNoteDateTime
    );
  };

  const handleChange = (event) => {
    setNonTherapyNote(event.target.value);
  };

  return (
    <>
      <div
        style={{ width: "auto", height: "100%" }}
        // className="inputAndLabelModalContainerTitle2"
      >
        <div className="line1-modal-percent">
          <TopTitleFormComponent
            title="ADD 1 TO 1 SUPPORT STAFF NOTE"
            closemodal={props.closemodal}
          />
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "0px",
            height: "80%",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <div className="line2-column-modal-bis">
            <div className="label-textfield" style={{ paddingLeft: "10px" }}>
              Note content
            </div>
            <TextField
              value={nonTherapyNote}
              onChange={handleNonTherapyNoteChange}
              style={{
                width: "95%",
                borderRadius: "5px",
                backgroundColor: "#f2eeef",
              }}
              placeholder="Type 1 to 1 non therapy session note here"
              multiline
              rows={14}
            />
          </div>
          <div
            className="line3-modal"
            style={{
              // marginLeft: "20px",
              paddingLeft: "20px",
              marginBottom: "20px",
              paddingTop: "10px",
            }}
          >
            <DatePickerWithTimeComponent
              value={nonTherapyNoteDateTime}
              onChange={handleDateTimeChange}
              title="Effective date and time"
            />
          </div>
        </div>
        <div className="line4-modal-percent-bis">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
              onClick={props.closemodal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                    }
              }
              disabled={isLoading}
              onClick={handleSubmit}
            >
              {isLoading ? "ADDING NOTE..." : "ADD NOTE"}
            </Button>
          </>
        </div>
      </div>
    </>
  );
}

export default AddNonTherapyNote;
