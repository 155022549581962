import React, { useState } from "react";

import { useTheme } from "@mui/material/styles";

import AllNotesContainer from "../containers/AllNotesContainer";
import AllNotesContainerV2Mobile from "../containers/AllNotesContainerV2Mobile";

function ClientNotesMainV2Mobile(props) {
  const theme = useTheme();

  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="tab-container-white-v2-mobile">
      <AllNotesContainerV2Mobile />
    </div>
  );
}

export default ClientNotesMainV2Mobile;
