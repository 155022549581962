import PeopleIcon from "@mui/icons-material/People";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ArticleIcon from "@mui/icons-material/Article";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import AssessmentIcon from "@mui/icons-material/Assessment";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import LegendToggleIcon from "@mui/icons-material/LegendToggle";
import SupportIcon from "@mui/icons-material/Support";
import PieChartIcon from "@mui/icons-material/PieChart";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HomeIcon from "@mui/icons-material/Home";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import ReceiptIcon from "@mui/icons-material/Receipt";
import LocalPharmacyIcon from "@mui/icons-material/LocalPharmacy";
import ViewModuleIcon from "@mui/icons-material/ViewModule";

export default [
  {
    id: 0,
    name: "Dashboard",
    icon: <DashboardIcon fontSize="large" />,
    link: "/",
    roles: [
      "Super Admin",
      "Admin",
      "Support Staff",
      "Psycho-ed",
      "Finance team",
      "Therapist",
      "Admission staff",
      "Medical Staff",
      "Hospitality Staff",
      "Clinical Director",
    ],
  },
  {
    id: 1,
    name: "Clients",
    icon: <PeopleIcon fontSize="large" />,
    link: "/clients",
    roles: [
      "Super Admin",
      "Admin",
      "Support Staff",
      "Psycho-ed",
      "Finance team",
      "Therapist",
      "Admission staff",
      "Medical Staff",
      "Hospitality Staff",
      "Clinical Director",
    ],
  },

  {
    id: 2,
    name: "Notes",
    icon: <ArticleIcon fontSize="large" />,
    link: "/notes",
    roles: [
      "Super Admin",
      "Admin",
      "Support Staff",
      "Psycho-ed",
      "Finance team",
      "Therapist",
      "Medical Staff",
      "Hospitality Staff",
      "Clinical Director",
    ],
  },

  // {
  //   id: 4,
  //   name: "Admissions",
  //   icon: <TrendingUpIcon fontSize="large" />,
  //   link: "/salesteam",
  //   roles: ["Admin", "Super Admin", "Clinical Director", "Admission staff"],
  // },

  // {
  //   id: 6,
  //   name: "Swot & Discharge",
  //   icon: <DashboardIcon fontSize="large" />,
  //   link: "/dashboard",
  //   roles: [
  //     "Super Admin",
  //     "Admin",
  //     "Support Staff",
  //     "Psycho-ed",
  //     "Finance team",
  //     "Therapist",
  //     "Admission staff",
  //     "Medical Staff",
  //     "Hospitality Staff",
  //     "Clinical Director",
  //   ],
  // },

  {
    id: 14,
    name: "MyProfile",
    icon: <ManageAccountsIcon fontSize="large" />,
    link: "/account",
    roles: [
      "Super Admin",
      "Admin",
      "Support Staff",
      "Psycho-ed",
      "Finance team",
      "Therapist",
      "Normal User",
      "Admission staff",
      "Medical Staff",
      "Hospitality Staff",
      "Clinical Director",
    ],
  },

  // {
  //   id: 8,
  //   name: "Roles",
  //   icon: <AdminPanelSettingsIcon fontSize="large" />,
  //   link: "/roles",
  //   roles: ["Super Admin", "Clinical Director"],
  // },

  // {
  //   id: 11,
  //   name: "Staff contacts",
  //   icon: <ImportContactsIcon fontSize="medium" />,
  //   link: "/stafflist",
  //   roles: [
  //     "Super Admin",
  //     "Admin",
  //     "Support Staff",
  //     "Finance team",
  //     "Psycho-ed",
  //     "Therapist",
  //     "Medical Staff",
  //     "Admission staff",
  //     "Hospitality Staff",
  //     "Clinical Director",
  //   ],
  // },

  {
    id: 5,
    name: "RoomAvailability",
    icon: <AssessmentIcon fontSize="large" />,
    link: "/availability",
    roles: ["Admin", "Super Admin", "Clinical Director", "Admission staff"],
  },

  {
    id: 12,
    name: "RoomBooking",
    icon: <CalendarMonthIcon fontSize="large" />,
    link: "/cheatsheet",
    roles: ["Super Admin", "Admin"],
  },

  {
    id: 13,
    name: "Metrics",
    icon: <PieChartIcon fontSize="large" />,
    link: "/supportdashboard",
    roles: ["Super Admin", "Admin"],
  },
  {
    id: 9,
    name: "ExportData",
    icon: <SystemUpdateAltIcon fontSize="large" />,
    link: "/export",
    roles: ["Admin", "Super Admin"],
  },
  // {
  //   id: 10,
  //   name: "Tutorials",
  //   icon: <VideoLibraryIcon fontSize="large" />,
  //   link: "/tutorials",
  //   roles: [
  //     "Super Admin",
  //     "Admin",
  //     "Support Staff",
  //     "Psycho-ed",
  //     "Finance team",
  //     "Therapist",
  //     "Medical Staff",
  //     "Admission staff",
  //     "Hospitality Staff",
  //     "Clinical Director",
  //   ],
  // },

  {
    id: 3,
    name: "Documents",
    icon: <FolderOpenIcon fontSize="large" />,
    link: "/documents",
    roles: [
      "Super Admin",
      "Admin",
      "Support Staff",
      "Psycho-ed",
      "Finance team",
      "Therapist",
      "Medical Staff",
      "Hospitality Staff",
      "Clinical Director",
    ],
  },
  {
    id: 7,
    name: "Support",
    icon: <SupportIcon fontSize="large" />,
    link: "/support",
    roles: ["Super Admin", "Admin", "Support Staff"],
  },

  {
    id: 15,
    name: "CRM",
    icon: <ContactPhoneIcon fontSize="large" />, // Import ContactPhoneIcon
    link: "/crm",
    roles: ["Super Admin", "Admin", "Sales Team"],
  },

  {
    id: 16,
    name: "Invoicing",
    icon: <ReceiptIcon fontSize="large" />, // Import ReceiptIcon
    link: "/invoicing",
    roles: ["Finance team", "Admin", "Super Admin"],
  },

  {
    id: 18,
    name: "Layout",
    icon: <ViewModuleIcon fontSize="large" />,
    link: "/roomlayout",
    roles: [
      "Super Admin",
      "Admin",
      "Support Staff",
      "Psycho-ed",
      "Finance team",
      "Therapist",
      "Medical Staff",
      "Hospitality Staff",
      "Clinical Director",
    ],
  },

  {
    id: 17,
    name: "Medication",
    icon: <LocalPharmacyIcon fontSize="large" />, // Import LocalPharmacyIcon
    link: "/medication",
    roles: ["Medical Staff", "Admin", "Super Admin"],
  },
];
