import React, { useEffect, useState } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import { Avatar, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import anonymous from "../../images/anonymous.jpg";
import BlockMiddleLayout2 from "./BlockMiddleLayout2";

function RoomLayoutMap2() {
  const [roomData, setRoomData] = useState({});

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "RoomNumbers"),
      (snapshot) => {
        const rooms = {};
        snapshot.forEach((doc) => {
          rooms[doc.id] = doc.data();
        });
        setRoomData(rooms);
      }
    );

    return () => unsubscribe();
  }, []);

  return (
    <div
      style={{
        paddingLeft: "60px",
        paddingRight: "40px",
        paddingTop: "0px",
        paddingBottom: "10px",
      }}
    >
      <div
        className="empty-content"
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          height: "87vh",
          borderRadius: "0px",
          flexDirection: "row",
        }}
      >
        {/* Left side (Villa 1 and Villa 2) */}
        <div
          style={{
            // width: "40%",
            // display: "flex",
            // flexDirection: "column",
            // gap: "1px",
            width: "40%",
            height: "100%",
            paddingTop: "13px",
            paddingBottom: "13px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "1px",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "300px",
              display: "flex",
              flexDirection: "column",
              gap: "1px",
            }}
          >
            {/* Top row (Villa 2) */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1px",
                height: "auto",
              }}
            >
              <div
                style={{
                  width: "33.3%",
                  height: "300px",
                }}
              >
                <RoomBlock100
                  number="2A  "
                  roomData={roomData["2A"]}
                  height="150px"
                />
                <RoomBlock100
                  number="2C"
                  roomData={roomData["2C"]}
                  height="200px"
                />
              </div>

              <div
                style={{
                  width: "33.3%",
                  height: "300px",
                }}
              >
                <RoomBlock100 number="Pool" height="100px" isPool />
                <RoomBlock100
                  number="VILLA 2"
                  // roomData="EMPTY"
                  height="125px"
                  backgroundColor="#fcf4a9"
                />
                <RoomBlock100
                  number="2B"
                  roomData={roomData["2B"]}
                  height="125px"
                />
              </div>

              <div
                style={{
                  width: "33.3%",
                  height: "350px",
                }}
              >
                <RoomBlock100
                  number="ROOM 2"
                  // roomData="EMPTY"
                  height="350px"
                  backgroundColor="#fdc6f3"
                />
              </div>
            </div>
          </div>

          {/* Spacer */}
          {/* <div style={{ height: "100px" }}></div> */}

          <div
            style={{
              width: "100%",
              height: "auto",
              display: "flex",
              flexDirection: "column",
              gap: "1px",
            }}
          >
            {/* Top row (Villa 2) */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1px",
                height: "auto",
              }}
            >
              <div
                style={{
                  width: "33.3%",
                  // height: "450px",
                }}
              >
                <RoomBlock100
                  number="1A  "
                  roomData={roomData["1A"]}
                  height="200px"
                />
                <RoomBlock100
                  number="1C"
                  roomData={roomData["1C"]}
                  height="150px"
                />
              </div>

              <div
                style={{
                  width: "33.3%",
                  // height: "450px",
                }}
              >
                <RoomBlock100
                  number="1B"
                  roomData={roomData["1B"]}
                  height="125px"
                />
                <RoomBlock100
                  number="VILLA 1"
                  // roomData="EMPTY"
                  height="125px"
                  backgroundColor="#fcf4a9"
                />
                <RoomBlock100 number="Pool" height="100px" isPool />
              </div>

              <div
                style={{
                  width: "33.3%",
                  // height: "450px",
                }}
              >
                <RoomBlock100
                  number="ROOM 1"
                  roomData="Therapy Room"
                  height="350px"
                  backgroundColor="#fdc6f3"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Middle layout */}
        <div
          style={{
            paddingTop: "13px",
            width: "20%",
            height: "100%",
            paddingBottom: "13px",
          }}
        >
          <BlockMiddleLayout2 />
        </div>

        {/* Right side (Villa 3 and Villa 4) */}
        <div
          style={{
            width: "40%",
            height: "100%",
            paddingTop: "13px",
            paddingBottom: "13px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "1px",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "300px",
              display: "flex",
              flexDirection: "column",
              gap: "1px",
            }}
          >
            {/* Top row (Villa 2) */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1px",
                height: "auto",
              }}
            >
              <div
                style={{
                  width: "33.3%",
                  height: "350px",
                }}
              >
                <RoomBlock100
                  number="ROOM 3"
                  // roomData="EMPTY"
                  height="350px"
                  backgroundColor="#fdc6f3"
                />
              </div>

              <div
                style={{
                  width: "33.3%",
                  height: "300px",
                }}
              >
                <RoomBlock100 number="Pool" height="100px" isPool />
                <RoomBlock100
                  number="VILLA 2"
                  // roomData="EMPTY"
                  height="125px"
                  backgroundColor="#fcf4a9"
                />
                <RoomBlock100
                  number="3B"
                  roomData={roomData["3B"]}
                  height="125px"
                />
              </div>
              <div
                style={{
                  width: "33.3%",
                  height: "300px",
                }}
              >
                <RoomBlock100
                  number="3C"
                  roomData={roomData["3C"]}
                  height="150px"
                />
                <RoomBlock100
                  number="3A"
                  roomData={roomData["3A"]}
                  height="200px"
                />
              </div>
            </div>
          </div>

          {/* Spacer */}
          {/* <div style={{ height: "100px" }}></div> */}

          <div
            style={{
              width: "100%",
              height: "350px",
              display: "flex",
              flexDirection: "column",
              gap: "1px",
            }}
          >
            {/* Top row (Villa 2) */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1px",
                height: "auto",
              }}
            >
              <div
                style={{
                  width: "33.3%",
                  height: "350px",
                }}
              >
                <RoomBlock100
                  number="GYM"
                  // roomData="EMPTY"
                  height="350px"
                  backgroundColor="#fdc6f3"
                />
              </div>

              <div
                style={{
                  width: "33.3%",
                  height: "300px",
                }}
              >
                <RoomBlock100
                  number="4B"
                  roomData={roomData["4B"]}
                  height="125px"
                />
                <RoomBlock100
                  number="VILLA 4"
                  // roomData="EMPTY"
                  height="125px"
                  backgroundColor="#fcf4a9"
                />
                <RoomBlock100 number="Pool" height="100px" isPool />
              </div>

              <div
                style={{
                  width: "33.3%",
                  height: "300px",
                }}
              >
                <RoomBlock100
                  number="4A  "
                  roomData={roomData["4A"]}
                  height="200px"
                />
                <RoomBlock100
                  number="4C"
                  roomData={roomData["4C"]}
                  height="150px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function RoomBlock({
  number,
  roomData,
  isPool,
  height = "150px",
  backgroundColor,
}) {
  const [loading, setLoading] = useState(true); // Etat pour le chargement de l'image
  const navigate = useNavigate();

  useEffect(() => {
    if (roomData?.profilePictureUrl) {
      const img = new Image();
      img.src = roomData.profilePictureUrl;
      img.onload = () => setLoading(false);
      img.onerror = () => setLoading(false);
    } else {
      setLoading(false);
    }
  }, [roomData]);

  const handleNavigate = () => {
    if (roomData?.clientId) {
      navigate(`/client/${roomData.clientId}`);
    }
  };

  const centeredFlexStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  };

  return (
    <div
      style={{
        width: "33.3%",
        height: height,
        display: "flex",
        flexDirection: "column",
        border: "1px solid black",
        boxSizing: "border-box",
        // backgroundColor:
        //   backgroundColor || (isPool ? "lightblue" : "transparent"),
        // backgroundColor:
        //   backgroundColor ||
        //   (isPool
        //     ? "lightblue"
        //     : roomData?.clientFirstName
        //     ? // && !roomData?.profilePictureUrl
        //       "#e1eeff"
        //     : "transparent"),
        backgroundColor:
          backgroundColor ||
          (isPool
            ? "lightblue"
            : roomData?.clientFirstName
            ? // && roomData?.profilePictureUrl
              "#e1eeff"
            : "transparent"),

        backgroundImage: isPool
          ? "url('https://www.publicdomainpictures.net/pictures/320000/velka/swimming-pool-water-texture.jpg')"
          : "none",
        backgroundSize: "cover",
        backgroundPosition: "center",
        ...centeredFlexStyle,
      }}
    >
      <div
        style={{
          fontSize: "16px",
          fontWeight: "bold",
          background: "#1e293b",
          borderRadius: "10px",
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingBottom: "3px",
          color: "white",
        }}
      >
        {number}
      </div>

      <div
        onClick={handleNavigate}
        style={{
          cursor: "pointer",
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {/* Gestion si roomData est une string */}
        {typeof roomData === "string" && (
          <div
            style={{
              fontWeight: "bold",
              textAlign: "center",
              paddingTop: "10px",
              color: "#000",
            }}
          >
            {roomData}
          </div>
        )}

        {/* Gestion si roomData est un objet */}
        {typeof roomData === "object" && roomData && (
          <>
            {loading ? (
              <Skeleton
                variant="circular"
                width={50}
                height={50}
                style={{ marginTop: 8 }}
              />
            ) : (
              <Avatar
                src={roomData.profilePictureUrl || anonymous}
                alt={`${roomData?.clientFirstName || "No"} ${
                  roomData?.clientLastName || "Client"
                }`}
                style={{
                  width: 50,
                  height: 50,
                  marginTop: 8,
                }}
              />
            )}

            {roomData.clientFirstName ? (
              <div
                style={{
                  fontWeight: "bold",
                  width: "100%",
                  textAlign: "center",
                  paddingTop: "5px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                {roomData.clientFirstName} {roomData.clientLastName}
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                  paddingTop: "10px",
                }}
              >
                No client assigned
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function RoomBlock100({
  number,
  roomData,
  isPool,
  height = "150px",
  backgroundColor,
}) {
  const [loading, setLoading] = useState(true); // Etat pour le chargement de l'image
  const navigate = useNavigate();

  useEffect(() => {
    if (roomData?.profilePictureUrl) {
      const img = new Image();
      img.src = roomData.profilePictureUrl;
      img.onload = () => setLoading(false);
      img.onerror = () => setLoading(false);
    } else {
      setLoading(false);
    }
  }, [roomData]);

  const handleNavigate = () => {
    if (roomData?.clientId) {
      navigate(`/client/${roomData.clientId}`);
    }
  };

  const centeredFlexStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  };

  return (
    <div
      style={{
        width: "100%",
        height: height,
        display: "flex",
        flexDirection: "column",
        border: "1px solid black",
        boxSizing: "border-box",
        // backgroundColor:
        //   backgroundColor || (isPool ? "lightblue" : "transparent"),

        // backgroundColor:
        //   backgroundColor ||
        //   (isPool
        //     ? "lightblue"
        //     : roomData?.clientFirstName
        //     ? // && !roomData?.profilePictureUrl
        //       "#ededed"
        //     : "transparent"),
        backgroundColor:
          backgroundColor ||
          (isPool
            ? "lightblue"
            : roomData?.clientFirstName
            ? // && roomData?.profilePictureUrl
              "#e1eeff"
            : "transparent"),

        backgroundImage: isPool
          ? "url('https://www.publicdomainpictures.net/pictures/320000/velka/swimming-pool-water-texture.jpg')"
          : "none",
        backgroundSize: "cover",
        backgroundPosition: "center",
        ...centeredFlexStyle,
      }}
    >
      <div
        style={{
          fontSize: "16px",
          fontWeight: "bold",
          background: "#1e293b",
          borderRadius: "10px",
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingBottom: "3px",
          color: "white",
        }}
      >
        {number}
      </div>

      <div
        onClick={handleNavigate}
        style={{
          cursor: "pointer",
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {/* Gestion si roomData est une string */}
        {typeof roomData === "string" && (
          <div
            style={{
              fontWeight: "bold",
              textAlign: "center",
              paddingTop: "10px",
              color: "#000",
            }}
          >
            {roomData}
          </div>
        )}

        {/* Gestion si roomData est un objet */}
        {typeof roomData === "object" && roomData && (
          <>
            {loading ? (
              <Skeleton
                variant="circular"
                width={50}
                height={50}
                style={{ marginTop: 8 }}
              />
            ) : (
              <Avatar
                src={roomData.profilePictureUrl || anonymous}
                alt={`${roomData?.clientFirstName || "No"} ${
                  roomData?.clientLastName || "Client"
                }`}
                style={{
                  width: 50,
                  height: 50,
                  marginTop: 8,
                }}
              />
            )}

            {roomData.clientFirstName ? (
              <div
                style={{
                  fontWeight: "bold",
                  width: "100%",
                  textAlign: "center",
                  paddingTop: "5px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                {roomData.clientFirstName} {roomData.clientLastName}
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                  paddingTop: "10px",
                }}
              >
                No client assigned
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default RoomLayoutMap2;
