import React, { useEffect, useState } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import { Avatar, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import anonymous from "../../images/anonymous.jpg";

function RoomLayoutMapMobile() {
  const [roomData, setRoomData] = useState({});

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "RoomNumbers"),
      (snapshot) => {
        const rooms = {};
        snapshot.forEach((doc) => {
          rooms[doc.id] = doc.data();
        });
        setRoomData(rooms);
      }
    );

    return () => unsubscribe();
  }, []);

  return (
    <div
      style={{
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "0px",
        paddingBottom: "10px",
      }}
    >
      <div
        className="empty-content"
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          height: "auto",
          borderRadius: "0px",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          {/* ROOM 8 */}
          <div
            style={{
              width: "100%",
              height: "auto",
              display: "flex",
              flexDirection: "column",
              gap: "1px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingLeft: "0px",
                width: "100%",
                height: "auto",
                alignContent: "flex-start",
                alignSelf: "flex-start",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "1px",
              }}
            >
              <RoomBlock
                number="A1"
                roomData={roomData["A1"]}
                height="200px"
                backgroundColor="#ebeeee"
              />
              <RoomBlock number="Pool" isPool height="100px" />
              <RoomBlock
                number="A2"
                roomData={roomData["A2"]}
                height="150px"
                backgroundColor="#ebeeee"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingLeft: "0px",
                gap: "1px",
              }}
            >
              <RoomBlock
                number="A3"
                roomData={roomData["A3"]}
                height="150px"
                backgroundColor="#ebeeee"
              />
              <RoomBlock
                number="A4"
                roomData={roomData["A4"]}
                height="150px"
                backgroundColor="#ebeeee"
              />
              <RoomBlock
                number="A5"
                roomData={roomData["A5"]}
                height="150px"
                backgroundColor="#ebeeee"
              />
            </div>
          </div>

          {/* ROOM 7 */}
          {/* <div
            style={{
              width: "100%",
              height: "auto",
              display: "flex",
              flexDirection: "column",
              gap: "1px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: "1px" }}>
              <RoomBlock number="7D" roomData={roomData["7D"]} height="150px" />
              <RoomBlock
                number="7C"
                roomData="Clinical Director - Paula"
                height="150px"
                backgroundColor="#f195e0"
              />
              <RoomBlock number="7B" roomData={roomData["7B"]} height="150px" />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignContent: "flex-end",
                alignItems: "flex-end",
                gap: "1px",
              }}
            >
              <RoomBlock number="7E" roomData={roomData["7E"]} height="200px" />
              <RoomBlock number="Pool" isPool height="100px" />
              <RoomBlock number="7A" roomData={roomData["7A"]} height="150px" />
            </div>
          </div> */}

          {/* ROOM 5 */}
          <div
            style={{
              width: "100%",
              gap: "1px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1px",
              }}
            >
              <RoomBlock
                number="B1"
                roomData={roomData["B1"]}
                height="150px"
                backgroundColor="#ebeeee"
              />
              <RoomBlock number="Pool" isPool height="100px" />
              <RoomBlock
                number="B2"
                roomData={roomData["B2"]}
                height="200px"
                backgroundColor="#ebeeee"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1px",
                marginTop: "1px",
              }}
            >
              <RoomBlock
                number="B3"
                roomData={roomData["B3"]}
                height="150px"
                backgroundColor="#ebeeee"
              />
              <RoomBlock
                number="B4"
                roomData={roomData["B4"]}
                height="150px"
                backgroundColor="#ebeeee"
              />
              <RoomBlock
                number="B5"
                roomData={roomData["B5"]}
                height="150px"
                backgroundColor="#ebeeee"
              />
            </div>
          </div>

          {/* ROOM 6 */}
          {/* <div style={{ width: "100%", gap: "1px" }}>
            <div style={{ display: "flex", flexDirection: "row", gap: "1px" }}>
              <RoomBlock number="6B" roomData={roomData["6B"]} height="150px" />
              <RoomBlock
                number="6C"
                roomData="Nurse Office"
                height="150px"
                backgroundColor="#d2d0d1"
              />
              <RoomBlock number="6D" roomData={roomData["6D"]} height="150px" />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                gap: "1px",
                marginTop: "1px",
              }}
            >
              <RoomBlock number="6A" roomData={roomData["6A"]} height="150px" />
              <RoomBlock number="Pool" isPool height="100px" />
              <RoomBlock number="6E" roomData={roomData["6E"]} height="200px" />
            </div>
          </div> */}
        </div>

        <div
          style={{
            width: "40%",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            gap: "1px",
          }}
        ></div>
      </div>
    </div>
  );
}

function RoomBlock({
  number,
  roomData,
  isPool,
  height = "150px",
  backgroundColor,
}) {
  const [loading, setLoading] = useState(true); // Etat pour le chargement de l'image
  const navigate = useNavigate();

  useEffect(() => {
    if (roomData?.profilePictureUrl) {
      const img = new Image();
      img.src = roomData.profilePictureUrl;
      img.onload = () => setLoading(false);
      img.onerror = () => setLoading(false);
    } else {
      setLoading(false);
    }
  }, [roomData]);

  const handleNavigate = () => {
    if (roomData?.clientId) {
      navigate(`/client/${roomData.clientId}`);
    }
  };

  const centeredFlexStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  };

  return (
    <div
      style={{
        width: "33.3%",
        height: height,
        display: "flex",
        flexDirection: "column",
        border: "1px solid black",
        boxSizing: "border-box",
        // backgroundColor:
        //   backgroundColor || (isPool ? "lightblue" : "transparent"),
        backgroundColor:
          backgroundColor ||
          (isPool
            ? "lightblue"
            : roomData?.clientFirstName
            ? // && roomData?.profilePictureUrl
              "#e1eeff"
            : "transparent"),

        backgroundImage: isPool
          ? "url('https://www.publicdomainpictures.net/pictures/320000/velka/swimming-pool-water-texture.jpg')"
          : "none",
        backgroundSize: "cover",
        backgroundPosition: "center",
        ...centeredFlexStyle,
      }}
    >
      <div
        style={{
          fontSize: "16px",
          fontWeight: "bold",
          background: "#1e293b",
          borderRadius: "10px",
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingBottom: "3px",
          color: "white",
        }}
      >
        {number}
      </div>

      <div
        onClick={handleNavigate}
        style={{
          cursor: "pointer",
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {/* Gestion si roomData est une string */}
        {typeof roomData === "string" && (
          <div
            style={{
              fontWeight: "bold",
              textAlign: "center",
              paddingTop: "10px",
              color: "#000",
            }}
          >
            {roomData}
          </div>
        )}

        {/* Gestion si roomData est un objet */}
        {typeof roomData === "object" && roomData && (
          <>
            {loading ? (
              <Skeleton
                variant="circular"
                width={50}
                height={50}
                style={{ marginTop: 8 }}
              />
            ) : (
              <Avatar
                src={roomData.profilePictureUrl || anonymous}
                alt={`${roomData?.clientFirstName || "No"} ${
                  roomData?.clientLastName || "Client"
                }`}
                style={{
                  width: 50,
                  height: 50,
                  marginTop: 8,
                }}
              />
            )}

            {roomData.clientFirstName ? (
              <div
                style={{
                  fontWeight: "bold",
                  width: "100%",
                  textAlign: "center",
                  paddingTop: "5px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                {roomData.clientFirstName} {roomData.clientLastName}
              </div>
            ) : (
              <div style={{ color: "red" }}>No client</div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default RoomLayoutMapMobile;
