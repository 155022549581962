import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function DatePickerWithTimeComponent(props) {
  const handleChange = (event) => {
    props.onChange(event);
  };
  const dateObject =
    props.value && typeof props.value.toDate === "function"
      ? props.value.toDate()
      : props.value;
  return (
    <div
      className={
        props.leftMargin === "none"
          ? "input-label-container2"
          : "input-label-container"
      }
    >
      <div className="input-label">
        {props.title}
        {props.isRequired && (
          <span style={{ color: "red", marginLeft: "5px" }}>*</span>
        )}
      </div>
      <DatePicker
        style={{ width: "300px" }}
        dateFormat="dd/MM/yyyy HH:mm:ss"
        popperPlacement="bottom-start"
        className="datepicker-element"
        selected={dateObject}
        onChange={handleChange}
        showTimeSelect
        showYearDropdown
        scrollableMonthYearDropdown
        required
        // value={props.therapyNoteDateTime}
      />
    </div>
  );
}
