import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import { deleteUser, getAuth } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { getFunctions } from "firebase/functions";
import { db } from "../../firebase";
import { deleteDoc, doc } from "firebase/firestore";
import { showCustomToast } from "../Misc/CustomToast";

const DeleteUserModal = (props) => {
  const functions = getFunctions();
  const [isLoading, setIsLoading] = useState(false);
  const auth = getAuth();
  const user = auth.currentUser;
  const nameUser = user.displayName;

  const deleteUserFunction = httpsCallable(functions, "deleteUser"); // Renommé deleteUserFunction

  const handleDeleteUser = async () => {
    // Fonction asynchrone
    setIsLoading(true);
    try {
      // Effacer l'utilisateur de l'authentification
      await deleteUserFunction({ uid: props.uid });

      // Effacer les données de l'utilisateur de Firestore

      const userDoc = doc(db, "roles", props.uid);
      await deleteDoc(userDoc);

      // Afficher un toast de succès
      showCustomToast("User deleted successfully.", "success");

      setIsLoading(false);
      props.onClose();
    } catch (error) {
      // Une erreur s'est produite lors de la suppression de l'utilisateur
      console.error("Error deleting user: ", error);
      setIsLoading(false);

      // Afficher un toast d'erreur
      showCustomToast("Error deleting user.", "error");
    }
  };

  return (
    <>
      <div className="inputAndLabelModalContainerTitle2">
        <div className="line1-modal">
          <div className="modal-title">
            Delete User{" "}
            <span style={{ fontSize: "14px" }}>
              ( {nameUser}) : {props.uid}
            </span>
            {/* <span style={{ fontSize: "10px" }}>(note Id: {props.noteId})</span> */}
          </div>

          <CloseIcon
            style={{
              marginRight: "16px",
              color: "rgb(214, 213, 213)",
              cursor: "pointer",
            }}
            onClick={props.onClose}
          />
        </div>
        <div className="line2-column-modal">
          <div className="confirmation-delete">
            Are you sure you want to delete this user ?
          </div>
        </div>
      </div>

      <div className="line4-modal">
        <>
          <Button
            variant="contained"
            style={{ backgroundColor: "white", color: "black" }}
            // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
            onClick={props.onClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={
              isLoading
                ? {
                    backgroundColor: "red",
                    color: "white",
                    marginLeft: "15px",
                  }
                : {
                    backgroundColor: "red",
                    color: "white",
                    marginLeft: "15px",
                  }
            }
            disabled={isLoading}
            onClick={handleDeleteUser}
          >
            {isLoading ? "DELETING USER..." : "DELETE USER"}
          </Button>
        </>
      </div>
    </>
  );
};

export default DeleteUserModal;
