import { Collapse } from "antd";
import UpdloaderDocument from "../Updloader/UpdloaderDocument";
import UpdloaderDocumentMobile from "../Updloader/UpdloaderDocumentMobile";
import { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
const { Panel } = Collapse;
const text = `
 TEST
`;
const CollapseComponentMobile = () => {
  const { translate } = useContext(LanguageContext);
  const onChange = (key) => {
    console.log(key);
  };
  return (
    <div
      className="documents-container"
      style={{ width: "50%", paddingLeft: "40px" }}
    >
      <Collapse
        // defaultActiveKey={["1"]}
        onChange={onChange}
        style={{ marginTop: 40 }}
      >
        <Panel
          header="ADMISSION FORMS"
          key="1"
          style={{ fontSize: "16px", fontWeight: "600" }}
        >
          <UpdloaderDocumentMobile
            repository="AdmissionForms"
            inputId="AdmissionForms"
          />
        </Panel>

        <Panel
          header="CLINICAL DOCUMENTS"
          key="2"
          style={{ fontSize: "16px", fontWeight: "600" }}
        >
          <UpdloaderDocumentMobile
            repository="ClinicalDocuments"
            inputId="ClinicalDocuments"
          />
        </Panel>
        <Panel
          header="PROGRAM MANUALS"
          key="3"
          style={{ fontSize: "16px", fontWeight: "600" }}
        >
          <UpdloaderDocumentMobile
            repository="ProgramManuals"
            inputId="ProgramManuals"
          />
        </Panel>

        <Panel
          header="INFORMATION TECHNOLOGY"
          key="4"
          style={{ fontSize: "16px", fontWeight: "600" }}
        >
          <UpdloaderDocumentMobile
            repository="InformationTechnology"
            inputId="InformationTechnology"
          />
        </Panel>

        <Panel
          header="SALES & MARKETING"
          key="5"
          style={{ fontSize: "16px", fontWeight: "600" }}
        >
          <UpdloaderDocumentMobile
            repository="SalesMarketing"
            inputId="SalesMarketing"
          />
        </Panel>
      </Collapse>
    </div>
  );
};
export default CollapseComponentMobile;
