import React, { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";

function TitleMediumTreatmentForm() {
  const { translate } = useContext(LanguageContext);
  return (
    <div className="title-line-medium-roi-60" style={{ marginLeft: "0px" }}>
      <div className="title-treatment-block1" style={{ paddingLeft: "10px" }}>
        {translate("PresentingIssues")}
      </div>
      {/* <div className="title-treatment-block1bis">Relationship</div> */}
      <div className="title-treatment-block2">{translate("Added")}</div>
      <div className="title-treatment-block2bis">{translate("Reviewed")}</div>
      <div
        className="title-treatment-block3"
        style={{ textAlign: "center", justifyContent: "center" }}
      >
        {translate("Actions")}
      </div>
    </div>
  );
}

export default TitleMediumTreatmentForm;
