import React, { useState } from "react";
import { Box, Modal } from "@mui/material";
import DateAndTimeChip from "../Chips/DateAndTimeChip";
import TypeNoteChip from "../Chips/TypeNoteChip";
import ViewButton from "../Buttons/ViewButton";
import EditButton from "../Buttons/EditButton";
import DeleteButton from "../Buttons/DeleteButton";
import DeleteNote from "./DeleteNote";
import EditEndOfShiftNote from "./EditEndOfShiftNote";
import ViewEndOfShiftNote from "./ViewEndOfShiftNote";
import connector from "../../images/Connector.png";

function EndOfShiftNoteContainer(props) {
  const [showViewModal, setShowViewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const openViewModal = () => {
    setShowViewModal(true);
  };
  const closeViewModal = () => {
    setShowViewModal(false);
  };

  const openEditModal = () => {
    setShowEditModal(true);
  };
  const closeEditModal = () => {
    setShowEditModal(false);
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const styleLive = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 750,
    height: "80%",
    borderRadius: 5,
    backgroundColor: "white",
    boxShadow: 24,
  };

  const styleDeleteModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    height: 260,
    borderRadius: 2,
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  console.log("shiftStartRaw:", props.shiftStartRaw);
  console.log("shiftEndRaw:", props.shiftEndRaw);

  return (
    <div className="notes-wrapper-v2">
      <Modal open={showEditModal} onClose={closeEditModal}>
        <Box sx={styleLive}>
          <EditEndOfShiftNote
            noteId={props.noteId}
            noteContent={props.noteContent}
            shiftStart={props.shiftStartRaw}
            shiftEnd={props.shiftEndRaw}
            staff={props.staff}
            closemodal={closeEditModal}
            {...props}
          />
        </Box>
      </Modal>

      <Modal open={showDeleteModal} onClose={closeDeleteModal}>
        <Box sx={styleDeleteModal}>
          <DeleteNote
            note={props.noteContent}
            noteId={props.noteId}
            typeOfNote={props.noteType}
            effectiveDate={props.effectiveDate}
            closemodal={closeDeleteModal}
          />
        </Box>
      </Modal>

      <Modal open={showViewModal} onClose={closeViewModal}>
        <Box sx={styleLive}>
          <ViewEndOfShiftNote
            noteId={props.noteId}
            noteContent={props.noteContent}
            shiftStart={props.shiftStart}
            shiftEnd={props.shiftEnd}
            staff={props.staff}
            closemodal={closeViewModal}
            {...props}
          />
        </Box>
      </Modal>

      <div className="note-container">
        <div
          className="note-container-block-1"
          style={{ width: "100px", height: "auto" }}
        >
          <DateAndTimeChip date={props.noteDate} />
          <DateAndTimeChip date={props.noteTime} />
          <TypeNoteChip noteType={props.noteType} />
        </div>
        <div className="note-container-block-2" style={{ width: "45px" }}>
          <img src={connector} alt="connector " height="300" />
        </div>

        <div className="note-container-block-3">
          <div className="note-container-line-1">
            <div
              className="note-container-line1-block1 left-block"
              // onClick={handleNavigate}
              style={{ cursor: "pointer" }}
            >
              <div className="title-cell-container">Done by (staff):</div>
              <div className="content-cell-container">{props.staff}</div>
            </div>
            <div className="note-container-line1-block1bis left-block">
              <div className="title-cell-container">Start shift:</div>
              <div className="content-cell-container">
                {props.shiftStart}
                {" - "}
                {props.noteTime}
              </div>{" "}
              <span
                style={{ fontSize: "10px", textAlign: "left", width: "100%" }}
              >
                {props.lastModification &&
                  `(Last modification: ${props.lastModification})`}
              </span>
            </div>
            <div className="note-container-line1-block2 left-block">
              <div className="title-cell-container">End shift:</div>
              <div className="content-cell-container">
                {props.shiftEnd}
                {" - "}
                {props.noteDateEndTime}
              </div>{" "}
              <span
                style={{ fontSize: "10px", textAlign: "left", width: "100%" }}
              >
                {props.lastModification &&
                  `(Last modification: ${props.lastModification})`}
              </span>
            </div>
            <div className="note-container-line1-block2 centre-block2">
              <div className="sub-block-2">
                <EditButton handleOpen={openEditModal} />
                <ViewButton handleOpen={openViewModal} />
                <DeleteButton handleOpen={openDeleteModal} />
              </div>
            </div>
          </div>
          <div
            className="note-container-line-2"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {props.noteContent}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EndOfShiftNoteContainer;
