import React from "react";

function TutorialVideosContent() {
  const videos = [
    {
      url: "https://www.loom.com/share/625b130929db4a74b52316d7f23a27af?sid=4aa9ae14-d40c-4e1d-930b-ac07785bebc5",
      title: "CLIENT category",
    },
    {
      url: "https://www.loom.com/share/bc7ffdc70f1e421989a6b66d5233421e?sid=608f799b-57ec-4172-aca1-01ce8a6add3b",
      title: "NOTES category",
    },
    {
      url: "https://www.loom.com/share/271e5dd87f2647f8823366a5dfc8a7c4?sid=2d383652-8ee8-42cd-83d2-d033ffee2ea0",
      title: "DOCUMENTS category",
    },
    {
      url: "https://www.loom.com/share/9508292630e94ac791a17e77d1f78c68?sid=8e522f98-8cda-4e0e-a23a-702a1724dfba",
      title: "SETTINGS, STAFFS CONTACT and TUTORIALS categories",
    },
  ];

  const videoItems = videos.map((video, index) => (
    <div
      key={index}
      style={{
        width: "100%", // Changer la largeur à 100%
        marginBottom: "20px", // Ajout d'une marge en bas pour espacer les éléments
      }}
    >
      <h2 style={{ fontWeight: "bold", marginLeft: 40 }}>{video.title}</h2>
      <a
        href={video.url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ marginLeft: 40 }}
      >
        Click here to view
      </a>
    </div>
  ));

  return (
    <div
      className="empty-content"
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column", // Changer le flexDirection à "column"
        width: "100%",
        height: "100vh",
      }}
    >
      {videoItems}
    </div>
  );
}

export default TutorialVideosContent;
