export default [
  {
    id: 1,
    textDropDownEn: "--Choose--",
    subcategories: [],
  },

  {
    id: 2,
    textDropDownEn: "ALCOHOL",
    subcategories: [{ id: 1, textDropDownEn: "Alcohol" }],
  },

  {
    id: 3,
    textDropDownEn: "CANNABINOIDS",
    subcategories: [
      { id: 1, textDropDownEn: "--Choose-- " },
      { id: 2, textDropDownEn: "Marijuana " },
    ],
  },

  {
    id: 4,
    textDropDownEn: "NARCOTICS /OPIOIDS",
    subcategories: [
      { id: 1, textDropDownEn: "--Choose--" },
      { id: 2, textDropDownEn: "Heroin" },
      { id: 3, textDropDownEn: "Fentanyl" },
      { id: 4, textDropDownEn: "Methadone/Suboxone" },
      { id: 5, textDropDownEn: "Oxycodone (OxyContin,Percocet)" },
      { id: 6, textDropDownEn: "Hydrocodone(Vicodin,Lortab) " },
      { id: 7, textDropDownEn: "Roxycontin/Roxycodone" },
      { id: 8, textDropDownEn: "Ultram (Tramadol)" },
      { id: 9, textDropDownEn: "Other" },
    ],
  },

  {
    id: 5,
    textDropDownEn: "DEPRESSANTS Sedative/Hypnotics/Anxiolitics",
    subcategories: [
      { id: 1, textDropDownEn: "--Choose--" },
      { id: 2, textDropDownEn: "Valium (Diazepam)" },
      { id: 3, textDropDownEn: "Xanax (Alprazolam)" },
      { id: 4, textDropDownEn: "Ativan (Lorazepam)" },
      { id: 5, textDropDownEn: "Klonopin (Clonazepam)" },
      { id: 6, textDropDownEn: "Restoril  (Temazepam)" },
    ],
  },

  {
    id: 6,
    textDropDownEn: "STIMULANTS",
    subcategories: [
      { id: 1, textDropDownEn: "--Choose--" },
      { id: 2, textDropDownEn: "Cocaine" },
      { id: 3, textDropDownEn: "Crack Cocaine" },
      { id: 4, textDropDownEn: "Crystal Meth" },
      { id: 5, textDropDownEn: "Adderal, Ritalin, Vyvanse" },
      { id: 6, textDropDownEn: "Amphetamines (Diet Pills)" },
    ],
  },

  {
    id: 7,
    textDropDownEn: "INHALANTS",
    subcategories: [
      { id: 1, textDropDownEn: "--Choose--" },
      { id: 2, textDropDownEn: "Nitrous/Whippets/Poppers " },
    ],
  },

  {
    id: 8,
    textDropDownEn: "HALLUCINOGENS",
    subcategories: [
      { id: 1, textDropDownEn: "--Choose--" },
      { id: 2, textDropDownEn: "Ecstasy ( MDMA)" },
      { id: 3, textDropDownEn: "Psilocybin (mushrooms)" },
      { id: 4, textDropDownEn: "Acid/LSD/PCP" },
      { id: 5, textDropDownEn: "Spice (K2), Bathsalts, Kava,Khat, Kratom" },
      { id: 6, textDropDownEn: "Ketamine" },
    ],
  },

  {
    id: 9,
    textDropDownEn: "NICOTINE/TOBACCO",
    subcategories: [{ id: 1, textDropDownEn: "Nicotine/tobacco" }],
  },

  {
    id: 10,
    textDropDownEn: "OTHERS DRUGS",
    subcategories: [{ id: 1, textDropDownEn: "Other drug" }],
  },
];
