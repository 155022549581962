export const translations = {
  en: {
    Dashboard: "Home",
    Clients: "Clients",
    ClientsC: "CLIENTS",
    Client: "Client",
    ClientC: "CLIENT",
    Notes: "Notes",
    MyProfile: "My Profile",
    RoomAvailability: "Room availability",
    RoomBooking: "Room booking",
    Metrics: "Metrics",
    ExportData: "Export Data",
    Documents: "Documents",
    SearchClient: "Search client...",
    Settings: "Settings",
    SignOut: "Sign out",
    NextDeparture: "NEXT DEPARTURE",
    NextIncoming: "NEXT INCOMING",
    Incoming: "# INCOMING",
    NoDischarges: "No Discharges",
    NoUpcomingClient: "No upcoming client",
    LastClientNotes: "Last Client Notes",
    Date: "Date",
    DateC: "DATE",
    Content: "Content",
    ContentC: "CONTENT",
    Type: "TYPE",
    Edit: "Edit",
    Delete: "Delete",
    ShowMoreNotes: "Show More Notes",
    ShowLessNotes: "Show Less Notes",
    ClickHereForHelp: "Click here to get onscreen help",
    AddClient: "Add Client",
    AddTheClient: "Add the client",
    AddNewClient: "Add New Client",
    FirstName: "First Name",
    LastName: "Last Name",
    LastName2: "Last Name",
    Nickname: "Nickname",
    Gender: "Gender",
    Email: "Email",
    PhoneNumber: "Phone Number",
    Country: "Country",
    PrimaryAddiction: "Primary Addiction",
    PrimaryAddictionT: "Primary Addiction",
    DurationOfStay: "Duration of Stay",
    ETA: "ETA",
    ClientSource: "Client Source",
    ProjectedResidentialDischargeDate: "Projected Residential Discharge Date",
    ProjectedProgramCompletionDate: "Projected Program Completion Date",
    ProjectedResidentialDischargeDateRequired:
      "Projected Residential Discharge Date is required",
    ProjectedProgramCompletionDateRequired:
      "Projected Program Completion Date is required",
    PersonalInfos: "Personal Infos",
    AddictionInfos: "Addiction Infos",
    DischargeInfos: "Discharge Infos",
    FirstNameRequired: "First Name is required",
    LastNameRequired: "Last Name is required",
    NicknameRequired: "Nickname is required",
    EmailRequired: "Email is required",
    ETARequired: "ETA is required",
    Back: "Back",
    Next: "Next",
    Finish: "Finish",
    DemoTestNoClient: "Demo version : No client added :)",
    ClientName: "Client name",
    TypeQuickSessionNote: "Type quick session note here",
    NoteContent: "Note content",
    EffectiveDateTime: "Effective date and time of the note",
    EffectiveDate: "Effective date and time",
    NoteContent: "Note content",
    TypeNoteHere: "Type your note here",
    StartShift: "Start of Shift",
    TypeNoteHere: "Type your note here",
    EndShift: "End of Shift",
    SelectStaff: "Select Staff",
    EffectiveDateOnly: "Effective date",
    FamilyNoteRelationship: "Family note relationship",
    GroupTopic: "Group Topic",
    GroupDateStartTime: "Group Date and start time",
    TypeOfGroup: "Type of group",
    GroupNote: "Group note",
    TypeGroupNoteHere: "Type group note here",
    ClientsNotInAttendance: "Clients NOT in attendance",
    TypeNamesHere:
      "Type names here (first name / or preferred name and first initial of last name) and reason why client is not in group, if known...",
    ImmigrationAppointment: "Immigration appointment / visa",
    DischargesFlights: "Discharges / Flights arrangements",
    NewClients: "New clients",
    PsychiatristAppointments: "Psychiatrist Appointments",
    HospitalAppointments: "Hospital appointments",
    Outings: "Outings",
    ZoomSessionAssistance: "Zoom Session Assistance",
    StaffToday: "Staff today",
    Overnight: "Overnight",
    NumberOfClients: "Number of clients",
    Swot: "Swot",
    GeneralAnnouncement: "General announcement / Discussion",
    EditFamilyNote: "Edit Family Note",
    EditQuickNote: "Edit Quick Note",
    EditAftercareNote: "Edit Aftercare Note",
    EditGroupNote: "Edit Group Note",
    EditMedicalNote: "Edit Medical Note",
    EditSupportStaffNote: "Edit Support Staff Note",
    EditTherapyNote: "Edit Therapy Note",
    EditHandoverNote: "Edit Handover Note",
    Cancel: "Cancel",
    Save: "Save",
    TypeGroupNoteHere: "Type group note here",
    TypeMedicalNoteHere: "Type medical note here",
    ImportFile: "Import file",
    MaximumFileSize: "Maximum file size : 50 MB",
    SelectedFile: "Selected file",
    FileAlreadyUploaded: "File already uploaded",
    AreYouSureToDeleteFile: "Are you sure you want to delete this file?",
    Confirm: "Confirm",
    ConfirmDeletion: "Confirm Deletion",
    TotalRecord: "total record",
    Filters: "Filters",
    SelectClientType: "Select Client Type",
    SelectTherapist: "Select Therapist",
    SelectAddiction: "Select Addiction",
    SelectILS: "Select ILS",
    ResetFilters: "Reset Filters",
    ADDICTION: "ADDICTION",
    AKA: "AKA",
    AGE: "AGE",
    ADMISSION: "ADMISSION",
    THERAPIST: "THERAPIST",
    ROOM: "ROOM #",
    SUPPORT_STAFF: "SUPPORT STAFF",
    PleaseSelectBothDates: "Please select both start and end dates.",
    SelectRangeDate: "Select range date:",
    SeeMore: "See more",
    TO: "TO",
    RoomsAvailablePerDay: "Rooms available / day",
    DistributionPerDay: "Distribution / day",
    RoomAvailability: "Room availability",
    RoomBooking: "Room booking",
    FullscreenMode: "Fullscreen Mode",
    NoCredentials:
      "--You don't have Super-admin or Admin credentials to access this page--",
    ExitFullscreen: "Exit Fullscreen",
    TodaysDate: "Today's date",
    IncomingDischarges: "Incoming Discharges",
    NightsMo: "Nights Mo.",
    LOSToDate: "L.O.S TO DATE",
    LOSIntended: "L.O.S.(Intended)",
    TotalNightsBooked: "Total nights booked",
    TotalIncomingNights: "Total Incoming Nights",
    TotalPotentialNights: "Total Potential Nights",
    TotalProjectedNights: "Total projected nights",
    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December",
    InHouseClients: "# In-House Clients",
    BedOccRate: "Bed occ. rate",
    AvTreatmentDur: "Av. treatment dur.",
    ClientsbyAddictionTypeInhouse: "Clients by Addiction Type (Inhouse)",
    ClientsByAddictionTypeAllClients: "Clients by Addiction Type (All Clients)",
    ClientsByTherapistInhouse: "Clients by Therapist (Inhouse)",
    Day: "Days",
    Status: "Status",
    Nationality: "Nationality",
    SecondaryAddiction: "Secondary addiction",
    LengthOfStay: "Length Of Stay",
    InActiveRecovery: "In active recovery?",
    GoogleReviewDone: "Google review done?",
    ExportData: "Export Data",
    ExportData2: "Export Data",
    Documents: "Documents",
    UPLOADING: "UPLOADING...",
    UPLOAD: "UPLOAD",
    NoFileSelected: "No file selected, please select one and put a file name",
    ChooseFile: "Choose file",
    FileRequirements:
      "(one at a time, only PDF, JPG or PNG with a maximum size of 5MB)",
    UploadDocument: "Upload a document file",
    NoFilesFound: "No files found",
    NoDeletePermissions: "You don't have permissions to delete this file",
    OneFileLimit: "You can only upload one file",
    FileDeleted: "File successfully deleted!",
    ErrorDeletingFile: "Error deleting file",
    FileSizeExceedsLimit: "File size exceeds the limit:",
    FilesUploaded: "Files successfully uploaded!",
    ErrorUpdatingClient: "Error updating client:",
    NoFilesSelected: "No files selected for upload.",
    FileName: "File Name",
    TryOurAI: "Try our A.I.",
    ArtificialIntelligence: "Artificial Intelligence",
    Photo: "Photo",
    Email: "Email",
    NameProfile: "Name",
    PhoneNumber: "Phone Number",
    Role: "Role",
    Profile: "Profile",
    ProfileUpdatedSuccessfully: "Profile updated successfully",
    ErrorUpdatingProfile: "Error updating profile:",
    User: "User",
    SuccessfullyLoggedOut: "successfully logged out",
    LogOut: "Log Out",
    Name: "Name",
    AskMeAnything: "Ask me anything",
    PromptInstruction:
      "In the prompt below, ask me any questions about this EHR and any questions about clients. You can ask anything in any language.",
    AnonymizedRequests:
      "All requests are anonymized (the clients' real names are not submitted to the AI model).",
    AskAbout: "Ask about:",
    HowToAddClient: "How to add a client?",
    GenerateReport: "Can I generate a report on client progress?",
    JanesProgress: "How is Jane's progress?",
    JohnsRecentNotes: "What are John's recent notes?",
    SummaryOfNotes: "Give me a summary of last week's notes.",
    NoteAddedSuccess: "Note successfully added",
    SelectedClient: "Selected client",
    NoClientSelected: "--No client selected--",
    SelectAClient: "Select a client",
    TypeNamesAndReason:
      "Type names here (first name / or preferred name and first initial of last name) and reason why client is not in group, if known...",
    IfOtherDescribe: "If other, describe",
    StartOfShift: "Start of Shift",
    EndOfShift: "End of Shift",
    SelectStaff: "Select Staff",
    Add: "Add",
    EditEndOfShiftNote: "Edit End Of Shift Note",
    WelcomeToRehabAppDemo: "Welcome to Rehab-App (Demo)",
    ContinueWithGoogle: "Continue with Google",
    LogInWithGoogle: "Log in with Google",
    InternetConnectionWorking: "Your internet connection is working",
    InternetConnectionNotWorking:
      "Your internet connection is not working. Please check your internet connection.",
    SuccessfullyLoggedInWithGoogle:
      "Successfully logged in using your Google account",
    MustLogInWithMiraclesAsiaEmail:
      "You must log in with an email address ending in @miraclesasia.com to access this application.",
    FailedToLogInWithGoogle: "Failed to log in with your Google account",
    NoRoleFoundForUser: "No role found for this user in Firestore",
    MustLogInWithGmailOrGsuite:
      "You must log in with a Gmail or G Suite address to access this application.",

    ClientNameRequired: "Client name is required",
    ContentRequired: "Content is required",
    EffectiveDateRequired: "Effective date is required",
    ImmigrationAppointmentRequired: "Immigration appointment is required",
    DischargesFlightsRequired: "Discharges / flights are required",
    NewClientsRequired: "New clients are required",
    PsychiatristAppointmentsRequired: "Psychiatrist appointments are required",
    HospitalAppointmentsRequired: "Hospital appointments are required",
    OutingsRequired: "Outings are required",
    ZoomSessionAssistanceRequired: "Zoom session assistance is required",
    StaffTodayRequired: "Staff today is required",
    OvernightRequired: "Overnight is required",
    NumberOfClientsRequired: "Number of clients is required",
    SwotRequired: "SWOT is required",
    GeneralAnnouncementRequired: "General announcement is required",
    GroupTopicRequired: "Group topic is required",
    StartTimeRequired: "Start time is required",
    GroupTypeRequired: "Group type is required",
    NotInAttendanceRequired: "Not in attendance is required",
    StartOfShiftRequired: "Start of shift is required",
    EndOfShiftRequired: "End of shift is required",
    StaffRequired: "Staff is required",
    PleaseFillAllRequiredFields: "Please fill all required fields",
    PersonalInformation: "Personal Information",
    FirstName: "First name",
    LastName: "Last name",
    Nickname: "Nickname",
    Email: "Email",
    PhoneNumber: "Phone number",
    Birthday: "Birthday",
    AgeNotEditable: "Age (not editable)",
    MaritalStatus: "Marital status",
    LivingWith: "Living with",
    IfOtherDescribe: "If other, describe",
    Gender: "Gender",
    IfOtherGenderDescribe: "If other gender, describe",
    SexualOrientation: "Sexual orientation",
    PhysicalAddressOfResidence: "Physical address of residence",
    City: "City",
    ProvinceState: "Province / State",
    Country: "Country",
    Nationality: "Nationality",
    ClientSource: "Client source",
    PassportNumber: "Passport number",
    VisaExpirationDate: "Visa expiration date",
    TreatmentInformation: "Treatment Information",
    ClientStatus: "Client status",
    RoomNumber: "Room number",
    AssignedTherapist: "Assigned Therapist",
    AssignedSupportStaff: "Assigned Support Staff",
    AdmissionAndDischarge: "Admission & Discharge",
    ActualAdmissionDate: "Actual admission date",
    ActualDischargeDate: "Actual discharge date",
    ProjectedDischargeDate: "Projected discharge date",
    ProjAftercareCompletionDate: "Proj. aftercare completion date",
    PassportPictures: "Passport Pictures",
    ImportFile: "Import File",
    MaximumFileSize: "Maximum file size: 100MB",
    UploadingFile: "Uploading file...",
    UploadedFiles: "Uploaded file(s)",
    EmergencyContact: "Emergency contact",
    EmergencyContactFirstName: "Emergency contact first name",
    EmergencyContactLastName: "Emergency contact last name",
    Relationship: "Relationship",
    IfOtherDescribe: "If other, describe",
    ContactPhoneNumber: "Contact phone number",
    ContactEmail: "Contact Email",
    PhysicalAddressOfResidence: "Physical address of residence",
    City: "City",
    Nationality: "Nationality",
    StateProvince: "State / Province",
    Country: "Country",
    PersonPayingForTreatment: "Person paying for the treatment",
    PhoneNumberOfPersonPaying: "Phone number of person paying",
    SavingModifications: "SAVING MODIFICATIONS...",
    ClickToSave: "CLICK TO SAVE",
    SaveModifications: "SAVE MODIFICATIONS",
    UploadLimitExceeded: "You can only upload up to 3 files.",
    FileSizeExceedsLimit: "File size exceeds the limit:",
    FilesSuccessfullyUploaded: "File(s) successfully uploaded!",
    FileSuccessfullyDeleted: "File successfully deleted!",
    ErrorDeletingFile: "Error deleting file",
    GeneralInformation: "General Informations",
    ActivityLogs: "Activity Logs",
    Help: "Help",
    Close: "Close",
    NoRecentActivity: "No recent activity",
    Language: "Language",
    Admin: "ADMIN",
    NoClientIdentifier: "No client identifier",
    NoTherapistAssignedYet: "No therapist assigned yet",
    AssignedTherapist: "Assigned therapist:",
    IntendedDurationOfStay: "Intended duration of stay",
    AdmissionDate: "Admission Date",
    MandatoryForms: "Mandatory forms",
    AreYouSureToDeleteFile: "Are you sure you want to delete this file?",
    AreYouSureToDeleteNote: "Are you sure you want to delete this note?",
    FileSuccessfullyDeleted: "File successfully deleted!",
    ErrorDeletingFile: "Error deleting file",
    NoteSuccessfullyDeleted: "Note successfully deleted!",
    ErrorDeletingNote: "Error deleting note:",
    Deleting: "Deleting...",
    Updating: "Updating...",
    NoRecentActivity: "No Recent Activity",
    LastModification: "Last Modification",
    ClientAddedBy: "Client Added By",
    EstimateDateOfArrival: "Estimate Date of Arrival",
    IntendedDurationOfStay: "Intended duration of stay",
    PassportStatus: "Passport status",
    PassportCopy: "Passport copy",
    PrevClientIdentifier: "Prev. client identifier",
    FlightDetails: "Flight details",
    Itinerary: "Itinerary",
    VIPTransfer: "VIP transfer",
    PaymentDetails: "Payment details",
    MaritalStatus: "Marital status",
    CurrentPartnerIfNotMarried: "If not married, current partner?",
    ChildrensOrDependents: "Childrens or dependents",
    CurrentLivingArrangements: "Current living arrangements",
    DietaryRequirements: "Dietary requirements",
    FundingSource: "Funding Source",
    PrimAddSubstOrProcAbove: "Prim. Add. (either subst. or proc. above)",
    IfOtherSelectedDescribe: "If other selected, describe",
    SecondaryAddiction: "Secondary Addiction",
    IfOtherSelectedDescribeSecondary: "If other selected, describe",
    SubstanceAddictions: "Substance Addiction(s)",
    DoCCurrentUsageLevels:
      "DoC Current Usage Levels (frequency, amount) and method (smoking, injection, snorting, orally)",
    AnyCurrentWithdrawalSymptoms: "Any Current Withdrawal Symptoms",
    ProcessAddictions: "Process Addiction(s)",
    TypeProcessAddiction:
      "type, e.g., gambling, sex, porn, food, gaming; and frequency of engaging in process addiction behavior",
    AddictionTreatmentHistory: "Addiction Treatment History",
    MainUnwantedConsequences: "Main Unwanted Consequences of Addiction",
    HowLongHasAddictionBeenAProblem:
      "How long has your using/drinking/process addiction been a problem?",
    WhatLedYouToSeekTreatmentNow: "What Led You to Seek Treatment Now?",
    TreatmentGoals: "Treatment Goals",
    BarriersForRehab: "Barriers for Rehab",
    MotivationForTreatment: "Motivation for Treatment",
    SavingModifications: "SAVING MODIFICATIONS...",
    ClickToSave: "CLICK TO SAVE",
    SaveModifications: "SAVE MODIFICATIONS",
    Addictions: "addictions",
    PreAdmissionAssessment: "Pre-Admission Assessment",
    AdmissionForms: "Admission forms",
    AuthorizationReleaseConfidentialInfo:
      "Authorization for release of confidential information",
    AddRCIForm: "Add a R.C.I. form",
    UnableToAddMandatoryForms:
      "Unable to add Mandatory forms if the client is not admitted.",
    UnableToAddROIForm:
      "Unable to add R.O.I. form if the client is not admitted.",
    CompanyIndividualName: "Company / Individual name",
    Filled: "Filled",
    Revoked: "Revoked",
    Actions: "Actions",
    Relationship: "Relationship",
    SelectedClient: "Selected Client:",
    ConfidentialityPolicyForm: "Confidentiality Policy Form",
    PleaseSignBelow: "Please sign below and click on Validate once done",
    SignatureNotValidated: "Signature not validated",
    SignatureValidated: "Signature validated!",
    Clear: "Clear",
    Validate: "Validate",
    Saving: "Saving...",
    GeneralLiabilityReleaseForm: "General Liability Release Form",
    GeneralLiabilityFormSubmitted:
      "General liability form correctly submitted!",
    ErrorAddingDocument: "Error adding document",
    InformedConsentForm: "Informed Consent Form",
    InformedConsentFormSubmitted: "Informed consent form correctly submitted!",
    ConfidentialityPolicyFormSubmitted:
      "Confidentiality policy form correctly submitted!",
    PleaseAgreeToTerms: "Please agree to all the terms before submitting.",
    Print: "Print",
    Intervention: "Intervention",
    AddTreatment: "Add Treatment",
    Adding: "Adding...",
    Objective: "Objective",
    AddObjective: "Add Objective",
    ObjectivesAssociatedInterventions:
      "Objectives and Associated Interventions Whilst in Treatment at the rehab:",
    PresentingIssues: "Presenting Issue(s):",
    LongTermGoals: "Long-Term Goals (beyond treatment at the rehab):",
    FailedToSaveTreatmentPlan:
      "Failed to save treatment plan. Please try again.",
    TreatmentPlanSuccessfullyAdded: "Treatment plan successfully added!",
    PleaseFillRequiredFields: "Please fill in all required fields.",
    ObjectiveIntervention: "Objective / Intervention",
    TreatmentPlan: "Treatment Plan",
    DischargeSummary: "Discharge summary",
    DischargeDate: "Discharge date",
    DischargeType: "Discharge type",
    DischargeTo: "Discharge to",
    DateOfAdmission: "Date of admission",
    TotalLengthOfStay: "Total length of stay",
    MedicationPrescribedUponDischarge: "Medication prescribed upon discharge",
    IdentifiedIssuesUponAdmission: "Identified issues upon admission",
    EmergingIssuesDuringTreatment:
      "Emerging issues identified during treatment",
    OverallLevelOfEngagement: "Overall level of engagement in the program",
    Resistance: "Resistance",
    HighlyEngaged: "Highly engaged",
    Progress: "Progress",
    FamilyInvolvement: "Family / significant Other Involvement",
    RoadblocksConcerns: "Roadblocks/Concerns",
    ClientPrognosis: "Client prognosis",
    RecommendedDischargePlan: "Recommended Discharge Plan",
    StaffMemberName: "Staff Member Name",
    Submit: "Submit",
    LeaveAgainstClinicalAdvice: "Leave Against Clinical Advice (ACA)",
    EmergencyDischarge: "Emergency Discharge",
    TreatmentPlanFor: "Treatment Plan for:",
    EditTreatmentPlan: "Edit Treatment Plan",
    Review: "Review",
    TypeReviewHere: "Type review here",
    AddReviews: "Add Reviews",
    UpdateReviews: "UPDATE REVIEWS",
    Updating: "UPDATING...",
    ObjectiveReviewsUpdated: "Objective reviews successfully updated!",
    FailedToUpdateObjectiveReviews:
      "Failed to update objective reviews. Please try again.",
    TreatmentPlanUpdated: "Treatment plan successfully updated!",
    FailedToSaveTreatmentPlan:
      "Failed to save treatment plan. Please try again.",
    SelectNoteType: "Select Note Type",
    StartDate: "Start Date",
    EndDate: "End Date",
    AddNote: "Add Note",
    TotalNote: "Total Note",
    GeneralDocuments: "General Documents",
    TherapistsDocuments: "Therapists Documents",
    CRM: "CRM",
    Medication: "Medication",
    Invoicing: "Invoicing",
    Support: "Support",
    AddAlert: "Add Alert",
    EditAlert: "Edit Alert",
    PleaseSelectTypeAndContent:
      "Please select a type and enter content for the alert",
    ConfirmDeleteAlert: "Are you sure you want to delete the alert?",
    ShowMore: "Show more",
    ShowLess: "Show less",
    Alerts: "ALERTS",
    ClinicalInformations: "Clinical Informations",
    AddTreatmentPlan: "Add Treatment Plan",
    NoTreatmentPlanAdded:
      "NO TREATMENT PLAN ADDED. PLEASE ADD A TREATMENT PLAN",
    Added: "Added",
    Reviewed: "Reviewed",
    DischargeForms: "Discharge forms",
    PrimaryAddictionColon: "Primary Addiction:",
    NoPrimaryAddictionRecorded: "No primary addiction recorded",
    NoAdmissionDateYet: "No admission date yet",
    NoDurationOfStayYet: "No duration of stay yet",
    YearsOld: "years old",
    ClientProfile: "Client profile",
    DateOfBirth: "Date of birth",
    DateOfBirthRequired: "Date of Birth is required",
    Create: "Create",
    AddNewLead: "Add New Lead",
    UpdateLead: "Update Lead",
    PhoneNumberRequired: "Phone number is required",
    FailedToCreateOrUpdateLead:
      "Failed to create/update lead. Please try again.",
    LeadUpdatedSuccessfully: "Lead updated successfully!",
    LeadCreatedSuccessfully: "Lead created successfully!",

    AddNewTicket: "Add new ticket",
    WriteDescription: "Write a description of your problem below",
    UploadScreenshots:
      "Upload screenshot(s) of the issue below if you have some",
    TicketName: "Ticket Name",
    Priority: "Priority",
    TicketType: "Ticket type",
    Medium: "Medium",
    Low: "Low",
    High: "High",
    PageConcerned: "Page concerned",
    AgeNonEditable: "Age (non editable)",
    PreviousWeek: "Previous Week",
    NextWeek: "Next Week",
    MandatoryFormsSection:
      "This is the 'Mandatory forms' section where you can add, view, and manage all the essential forms that are required for the client's admission and treatment process. Ensure that all necessary documents are completed and up-to-date to comply with regulations and provide comprehensive care.",
    AuthorizationSection:
      "This is the 'Authorization for release of confidential information' section. Here, you have the ability to manage and add new authorization forms that allow the release of a client's confidential information to authorized parties. It's important to handle these documents carefully to maintain privacy and adhere to legal requirements.",
    SearchBar:
      "This is the search bar where you can search for clients by entering their name or other relevant information. It helps you quickly locate and access a client's details, making your workflow more efficient.",
    AIServicePrompt:
      "Try our AI service here! This feature leverages advanced artificial intelligence to assist you in various tasks, making your experience smoother and more intuitive. Click here to explore how AI can enhance your productivity and offer smart solutions.",
    ProfileWidget:
      "This is your profile widget where you can manage your account settings, view your profile information, and access personal preferences. It provides a quick way to customize your experience and stay updated with your account details.",
    InHouseClientsInfo:
      "Here you can see the total number of clients who are currently in-house. Click on this widget for more details about these clients.",
    NextClientDepartureInfo:
      "This widget displays the next client scheduled for departure, including their name and planned discharge date. Click to view more information about this client.",
    NextIncomingClientInfo:
      "This widget shows the next incoming client, with their expected arrival date. Click to learn more about this incoming client.",
    UpcomingClientsInfo:
      "This widget presents the total number of clients who are scheduled to arrive soon. Click for more details about these upcoming clients.",
    DashboardHelp:
      "Access the dashboard for an overview of activities and key statistics.",
    ClientsHelp:
      "Manage all clients by type (In House, Aftercare, Incoming, etc.).",
    NotesHelp:
      "View and filter notes by type to keep track of client interactions.",
    RoomAvailabilityHelp: "View the number of available rooms per day.",
    RoomBookingHelp:
      "See detailed daily bookings with client names and specific details.",
    CRMHelp:
      "Present a kanban with client cards by status, allowing you to manage lead qualification progress.",
    MetricsHelp: "Show various metrics and statistics.",
    ExportDataHelp:
      "Export client data in case the client wants to retrieve their data.",
    DocumentsHelp: "Store documents accessible to all other users.",
    SupportHelp: "Send tickets to support for assistance.",
    LatestSupportTickets: "Latest Support Tickets",
    NoTicketsFound: "No tickets found",
    Reset: "Reset",
    PleaseSendTicket: "Please send us a ticket about any issue you have",
    SupportTickets: "Support tickets",
    WelcomeToRehappApp: "Welcome to Rehapp",
    AskMeExample: "Ask me, for example",
    GiveMeInhouseClientsList: "Give me the list of Inhouse clients",
    WhatIsNextBirthday: "What is the next birthday",
    SummarizeLatestNotes: "Summarize the latest notes",
    WhereCanISeeInhouseClientsList: "Where can I see the list of Inhouse clients"
  },

  fr: {
    AskMeExample: "Demandez-moi par exemple",
  GiveMeInhouseClientsList: "Donne-moi la liste des clients Inhouse",
  WhatIsNextBirthday: "Quel est le prochain anniversaire",
  SummarizeLatestNotes: "Fais-moi un résumé des dernières notes",
  WhereCanISeeInhouseClientsList: "Où puis-je voir la liste des clients Inhouse",
    WelcomeToRehappApp: "Bienvenue sur Rehapp",
    LatestSupportTickets: "Derniers tickets de support",
    NoTicketsFound: "Aucun ticket trouvé",
    Reset: "Réinitialiser",
    PleaseSendTicket:
      "Veuillez nous envoyer un ticket pour tout problème que vous avez",
    SupportTickets: "Tickets de support",
    DashboardHelp:
      "Accédez au tableau de bord pour une vue d'ensemble des activités et des statistiques clés.",
    ClientsHelp:
      "Gérez tous les clients par type (En interne, Après-soins, À venir, etc.).",
    NotesHelp:
      "Permet de voir les notes et de les filtrer par type de notes pour suivre les interactions avec les clients.",
    RoomAvailabilityHelp:
      "Permet de voir le nombre de chambres disponibles par jour.",
    RoomBookingHelp:
      "Montre le détail très précis par jour des réservations effectuées, avec les noms des clients et des détails spécifiques.",
    CRMHelp:
      "Présente un kanban avec les cartes clients par statut, permettant de gérer l'état d'avancement de qualification des leads.",
    MetricsHelp: "Affiche diverses métriques et statistiques.",
    ExportDataHelp:
      "Exporte les données clients au cas où le client souhaite récupérer ses données.",
    DocumentsHelp:
      "Permet de stocker des documents accessibles à tous les autres utilisateurs.",
    SupportHelp:
      "Permet d'envoyer des tickets au support pour obtenir de l'aide.",
    InHouseClientsInfo:
      "Ici, vous pouvez voir le nombre total de clients qui sont actuellement en cours d'admission. Cliquez sur ce widget pour plus de détails sur ces clients.",
    NextClientDepartureInfo:
      "Ce widget affiche le prochain client prévu pour le départ, y compris son nom et la date de sortie prévue. Cliquez pour voir plus d'informations sur ce client.",
    NextIncomingClientInfo:
      "Ce widget montre le prochain client entrant, avec sa date d'arrivée prévue. Cliquez pour en savoir plus sur ce client entrant.",
    UpcomingClientsInfo:
      "Ce widget présente le nombre total de clients qui sont prévus d'arriver bientôt. Cliquez pour plus de détails sur ces clients à venir.",

    MandatoryFormsSection:
      "Ceci est la section 'Formulaires obligatoires' où vous pouvez ajouter, visualiser et gérer tous les formulaires essentiels requis pour l'admission et le processus de traitement du client. Assurez-vous que tous les documents nécessaires sont complétés et à jour pour respecter les réglementations et fournir des soins complets.",
    AuthorizationSection:
      "Ceci est la section 'Autorisation de divulgation d'informations confidentielles'. Ici, vous avez la possibilité de gérer et d'ajouter de nouveaux formulaires d'autorisation qui permettent la divulgation des informations confidentielles d'un client à des parties autorisées. Il est important de traiter ces documents avec soin pour maintenir la confidentialité et respecter les exigences légales.",
    SearchBar:
      "Ceci est la barre de recherche où vous pouvez rechercher des clients en entrant leur nom ou d'autres informations pertinentes. Cela vous aide à localiser rapidement et à accéder aux détails d'un client, rendant votre flux de travail plus efficace.",
    AIServicePrompt:
      "Essayez notre service IA ici ! Cette fonctionnalité utilise une intelligence artificielle avancée pour vous aider dans diverses tâches, rendant votre expérience plus fluide et intuitive. Cliquez ici pour explorer comment l'IA peut améliorer votre productivité et offrir des solutions intelligentes.",
    ProfileWidget:
      "Ceci est votre widget de profil où vous pouvez gérer les paramètres de votre compte, visualiser vos informations de profil et accéder à vos préférences personnelles. Il fournit un moyen rapide de personnaliser votre expérience et de rester à jour avec les détails de votre compte.",

    PreviousWeek: "Semaine précédente",
    NextWeek: "Semaine suivante",
    AgeNonEditable: "Âge (non modifiable)",
    PageConcerned: "Page concernée",
    AddNewTicket: "Ajouter un nouveau ticket",
    WriteDescription: "Écrivez une description de votre problème ci-dessous",
    UploadScreenshots:
      "Téléchargez des captures d'écran du problème ci-dessous si vous en avez",
    TicketName: "Nom du ticket",
    Priority: "Priorité",
    TicketType: "Type de ticket",
    Medium: "Moyenne",
    Low: "Faible",
    High: "Élevée",
    DateOfBirth: "Date de naissance",
    DateOfBirthRequired: "La date de naissance est requise",
    Create: "Créer",
    AddNewLead: "Ajouter un nouveau lead",
    UpdateLead: "Mettre à jour le lead",
    PhoneNumberRequired: "Le numéro de téléphone est requis",
    FailedToCreateOrUpdateLead:
      "Échec de la création/mise à jour du lead. Veuillez réessayer.",
    LeadUpdatedSuccessfully: "Lead mis à jour avec succès!",
    LeadCreatedSuccessfully: "Lead créé avec succès!",
    ClientProfile: "Profil du client",
    YearsOld: "ans",
    NoPrimaryAddictionRecorded: "Aucune addiction principale enregistrée",
    Dashboard: "Accueil",
    Clients: "Clients",
    ClientsC: "CLIENTS",
    Client: "Client",
    ClientC: "CLIENT",
    Notes: "Notes",
    MyProfile: "Mon Profil",
    RoomAvailability: "Disponibilité des chambres",
    RoomBooking: "Réservation de chambre",
    Metrics: "Métriques",
    ExportData: "Exporter les données",
    Documents: "Documents",
    SearchClient: "Rechercher un client...",
    Settings: "Paramètres",
    SignOut: "Déconnexion",
    NextDeparture: "PROCHAIN DEPART",
    NextIncoming: "PROCHAINE ARRIVEE",
    Incoming: "# ARRIVEES",
    Incoming2: "Incoming",
    NoDischarges: "Aucun départ",
    NoUpcomingClient: "Aucun client à venir",
    LastClientNotes: "Notes récentes",
    Date: "Date",
    DateC: "DATE",
    Content: "Contenu",
    ContentC: "CONTENU",
    Type: "TYPE",
    Edit: "Modifier",
    Delete: "Supprimer",
    ShowMoreNotes: "Voir plus de notes",
    ShowLessNotes: "Voir moins de notes",
    ClickHereForHelp: "Cliquez ici pour obtenir de l'aide à l'écran",
    AddClient: "Ajouter un client",
    AddTheClient: "Ajouter le client",
    AddNewClient: "Ajouter un nouveau client",
    FirstName: "Prénom",
    LastName: "Nom de famille",
    LastName2: "Nom",
    Nickname: "Surnom",
    Gender: "Genre",
    Email: "E-mail",
    PhoneNumber: "Numéro de téléphone",
    Country: "Pays",
    PrimaryAddiction: "Addiction principale",
    PrimaryAddictionT: "1ere Addiction",
    DurationOfStay: "Durée du séjour",
    ETA: "Heure d'arrivée",
    ClientSource: "Source du client",
    ProjectedResidentialDischargeDate: "Date de sortie résidentielle prévue",
    ProjectedProgramCompletionDate: "Date prévue de fin de programme",
    ProjectedResidentialDischargeDateRequired:
      "La date de sortie résidentielle prévue est requise",
    ProjectedProgramCompletionDateRequired:
      "La date prévue de fin de programme est requise",
    PersonalInfos: "Infos personnelles",
    AddictionInfos: "Infos sur l'addiction",
    DischargeInfos: "Infos sur la sortie",
    FirstNameRequired: "Le prénom est requis",
    LastNameRequired: "Le nom de famille est requis",
    NicknameRequired: "Le surnom est requis",
    EmailRequired: "L'email est requis",
    ETARequired: "La date d'arrivée est requise",
    Back: "Retour",
    Next: "Suivant",
    Finish: "Terminer",
    DemoTestNoClient: "Version de démonstration : Aucun client ajouté :)",
    ClientName: "Nom du client",
    TypeQuickSessionNote: "Tapez une note de session rapide ici",
    NoteContent: "Contenu de la note",
    EffectiveDateTime: "Date et heure effectives de la note",
    EffectiveDate: "Date et heure effectives",
    NoteContent: "Contenu de la note",
    TypeNoteHere: "Tapez votre note ici",
    StartShift: "Début du quart de travail",
    TypeNoteHere: "Tapez votre note ici",
    EndShift: "Fin du quart de travail",
    SelectStaff: "Sélectionnez le personnel",
    EffectiveDateOnly: "Date effective",
    FamilyNoteRelationship: "Relation de la note de famille",
    GroupTopic: "Sujet du groupe",
    GroupDateStartTime: "Date et heure de début du groupe",
    TypeOfGroup: "Type de groupe",
    GroupNote: "Note de groupe",
    TypeGroupNoteHere: "Tapez la note de groupe ici",
    ClientsNotInAttendance: "Clients NON présents",
    TypeNamesHere:
      "Tapez les noms ici (prénom / ou prénom préféré et initiale du nom de famille) et la raison pour laquelle le client n'est pas en groupe, si connue...",
    ImmigrationAppointment: "Rendez-vous d'immigration / visa",
    DischargesFlights: "Arrangements de sorties / vols",
    NewClients: "Nouveaux clients",
    PsychiatristAppointments: "Rendez-vous avec le psychiatre",
    HospitalAppointments: "Rendez-vous à l'hôpital",
    Outings: "Sorties",
    ZoomSessionAssistance: "Assistance de session Zoom",
    StaffToday: "Personnel aujourd'hui",
    Overnight: "De nuit",
    NumberOfClients: "Nombre de clients",
    Swot: "Swot",
    GeneralAnnouncement: "Annonce générale / Discussion",
    EditFamilyNote: "Modifier la note de famille",
    EditQuickNote: "Modifier la note rapide",
    EditAftercareNote: "Modifier la note de suivi",
    EditGroupNote: "Modifier la note de groupe",
    EditMedicalNote: "Modifier la note médicale",
    EditSupportStaffNote: "Modifier la note du personnel de soutien",
    EditTherapyNote: "Modifier la note de thérapie",
    EditHandoverNote: "Modifier la note de passation",
    Cancel: "Annuler",
    Save: "Sauvegarder",
    TypeGroupNoteHere: "Tapez votre note de groupe ici",
    TypeMedicalNoteHere: "Tapez votre note médicale ici",
    ImportFile: "Importer un fichier",
    MaximumFileSize: "Taille maximale du fichier : 50 Mo",
    SelectedFile: "Fichier sélectionné",
    FileAlreadyUploaded: "Fichier déjà téléchargé",
    AreYouSureToDeleteFile: "Êtes-vous sûr de vouloir supprimer ce fichier ?",
    Confirm: "Confirmer",
    ConfirmDeletion: "Confirmer la suppression",
    TotalRecord: "enregistrement",
    Filters: "Filtres",
    SelectClientType: "Sélectionner le type de client",
    SelectTherapist: "Sélectionner le thérapeute",
    SelectAddiction: "Sélectionner l'addiction",
    SelectILS: "Sélectionner l'ILS",
    ResetFilters: "Réinitialiser les filtres",
    ADDICTION: "ADDICTION",
    AKA: "SURNOM",
    AGE: "ÂGE",
    ADMISSION: "ADMISSION",
    THERAPIST: "THÉRAPEUTE",
    ROOM: "CHAMBRE #",
    SUPPORT_STAFF: "SUPPORT",
    PleaseSelectBothDates:
      "Veuillez sélectionner les dates de début et de fin.",
    SelectRangeDate: "Sélectionner une plage de dates :",
    SeeMore: "Voir plus",
    TO: "À",
    RoomsAvailablePerDay: "Chambres disponibles / jour",
    DistributionPerDay: "Répartition / jour",
    RoomAvailability: "Disponibilité des chambres",
    RoomBooking: "Réservation de chambres",
    FullscreenMode: "Mode plein écran",
    NoCredentials:
      "--Vous n'avez pas les identifiants Super-admin ou Admin pour accéder à cette page--",
    ExitFullscreen: "Quitter le plein écran",
    TodaysDate: "Date d'aujourd'hui",
    IncomingDischarges: "Départs à venir",
    NightsMo: "Nuits sur le mois",
    LOSToDate: "Durée de résidence à ce jour",
    LOSIntended: "Durée de résidence (Prévu)",
    TotalNightsBooked: "Total des nuits réservées",
    TotalIncomingNights: "Total des nuits à venir",
    TotalPotentialNights: "Total des nuits potentielles",
    TotalProjectedNights: "Total des nuits projetées",
    January: "Janvier",
    February: "Février",
    March: "Mars",
    April: "Avril",
    May: "Mai",
    June: "Juin",
    July: "Juillet",
    August: "Août",
    September: "Septembre",
    October: "Octobre",
    November: "Novembre",
    December: "Décembre",
    InHouseClients: "# Clients Inhouse",
    BedOccRate: "Taux d'occ. des lits",
    AvTreatmentDur: "Durée moy. de trait.",
    ClientsByAddictionTypeInhouse: "Clients par type de dépendance (Internes)",
    ClientsByAddictionTypeAllClients:
      "Clients par type de dépendance (Tous les clients)",
    ClientsByTherapistInhouse: "Clients par thérapeute (Internes)",
    Day: "Jours",
    Status: "Statut",
    Nationality: "Nationalité",
    SecondaryAddiction: "Seconde Addiction",
    LengthOfStay: "Durée du séjour",
    InActiveRecovery: "En récupération active?",
    GoogleReviewDone: "Revue Google faite?",
    ExportData: "Export des données",
    ExportData2: "Exporter les données",
    Documents: "Documents",
    UPLOADING: "TÉLÉCHARGEMENT...",
    UPLOAD: "TÉLÉCHARGER",
    NoFileSelected:
      "Aucun fichier sélectionné, veuillez en sélectionner un et mettre un nom de fichier",
    ChooseFile: "Choisir le fichier",
    FileRequirements:
      "(un à la fois, seulement PDF, JPG ou PNG avec une taille maximale de 5MB)",
    UploadDocument: "Télécharger un fichier de document",
    NoFilesFound: "Aucun fichier trouvé",
    NoDeletePermissions:
      "Vous n'avez pas la permission de supprimer ce fichier",
    OneFileLimit: "Vous ne pouvez télécharger qu'un seul fichier",
    FileDeleted: "Fichier supprimé avec succès !",
    ErrorDeletingFile: "Erreur lors de la suppression du fichier",
    FileSizeExceedsLimit: "La taille du fichier dépasse la limite :",
    FilesUploaded: "Fichiers téléchargés avec succès !",
    ErrorUpdatingClient: "Erreur lors de la mise à jour du client :",
    NoFilesSelected: "Aucun fichier sélectionné pour le téléchargement.",
    FileName: "Nom du fichier",
    AreYouSureYouWantToDeleteTheFile:
      "Êtes-vous sûr de vouloir supprimer le fichier",
    permanently: "définitivement ?",
    TryOurAI: "Essayez notre I.A.",
    ArtificialIntelligence: "Intelligence Artificielle",
    Photo: "Photo",
    Email: "Email",
    PhoneNumber: "Numéro de téléphone",
    Role: "Rôle",
    Edit: "Modifier",
    ChooseFile: "Choisir un fichier",
    Profile: "Profil",
    ProfileUpdatedSuccessfully: "Profil mis à jour avec succès",
    ErrorUpdatingProfile: "Erreur lors de la mise à jour du profil :",
    User: "Utilisateur",
    SuccessfullyLoggedOut: "déconnecté avec succès",
    LogOut: "Se déconnecter",
    Name: "Nom",
    AskMeAnything: "Demandez-moi n'importe quoi",
    PromptInstruction:
      "Dans le champ ci-dessous, posez-moi toutes vos questions sur cet EHR et sur les clients. Vous pouvez poser des questions dans n'importe quelle langue.",
    AnonymizedRequests:
      "Toutes les demandes sont anonymisées (les vrais noms des clients ne sont pas soumis au modèle d'IA).",
    AskAbout: "Demandez sur :",
    HowToAddClient: "Comment ajouter un client ?",
    GenerateReport: "Puis-je générer un rapport sur les progrès des clients ?",
    JanesProgress: "Comment se passe le progrès de Jane ?",
    JohnsRecentNotes: "Quelles sont les dernières notes de John ?",
    SummaryOfNotes: "Donnez-moi un résumé des notes de la semaine dernière.",
    NoteAddedSuccess: "Note ajoutée avec succès",
    SelectedClient: "Client sélectionné",
    NoClientSelected: "--Aucun client sélectionné--",
    SelectAClient: "Sélectionnez un client",
    TypeNamesAndReason:
      "Tapez les noms ici (prénom / ou nom préféré et initiale du nom de famille) et la raison pour laquelle le client n'est pas dans le groupe, si connu...",
    IfOtherDescribe: "Si autre, décrivez",
    StartOfShift: "Début de service",
    EndOfShift: "Fin de service",
    SelectStaff: "Sélectionner le personnel",
    Add: "Ajouter",
    EditEndOfShiftNote: "Modifier la note de fin de quart",
    WelcomeToRehabAppDemo: "Bienvenue sur Rehab-App (Démo)",
    ContinueWithGoogle: "Continuer avec Google",
    LogInWithGoogle: "Se connecter avec Google",
    InternetConnectionWorking: "Votre connexion Internet fonctionne",
    InternetConnectionNotWorking:
      "Votre connexion Internet ne fonctionne pas. Veuillez vérifier votre connexion Internet.",
    SuccessfullyLoggedInWithGoogle:
      "Connecté avec succès en utilisant votre compte Google",
    MustLogInWithMiraclesAsiaEmail:
      "Vous devez vous connecter avec une adresse e-mail se terminant par @miraclesasia.com pour accéder à cette application.",
    FailedToLogInWithGoogle: "Échec de la connexion avec votre compte Google",
    NoRoleFoundForUser: "Aucun rôle trouvé pour cet utilisateur dans Firestore",
    MustLogInWithGmailOrGsuite:
      "Vous devez vous connecter avec une adresse Gmail ou G Suite pour accéder à cette application.",
    ClientNameRequired: "Le nom du client est requis",
    ContentRequired: "Le contenu est requis",
    EffectiveDateRequired: "La date effective est requise",
    ImmigrationAppointmentRequired: "Le rendez-vous d'immigration est requis",
    DischargesFlightsRequired: "Les départs / vols sont requis",
    NewClientsRequired: "Les nouveaux clients sont requis",
    PsychiatristAppointmentsRequired:
      "Les rendez-vous psychiatriques sont requis",
    HospitalAppointmentsRequired: "Les rendez-vous à l'hôpital sont requis",
    OutingsRequired: "Les sorties sont requises",
    ZoomSessionAssistanceRequired: "L'assistance aux sessions Zoom est requise",
    StaffTodayRequired: "Le personnel d'aujourd'hui est requis",
    OvernightRequired: "La nuit est requise",
    NumberOfClientsRequired: "Le nombre de clients est requis",
    SwotRequired: "SWOT est requis",
    GeneralAnnouncementRequired: "L'annonce générale est requise",
    GroupTopicRequired: "Le sujet du groupe est requis",
    StartTimeRequired: "L'heure de début est requise",
    GroupTypeRequired: "Le type de groupe est requis",
    NotInAttendanceRequired: "Non présents sont requis",
    StartOfShiftRequired: "Le début du quart est requis",
    EndOfShiftRequired: "La fin du quart est requise",
    StaffRequired: "Le personnel est requis",
    PleaseFillAllRequiredFields: "Veuillez remplir tous les champs requis",
    PersonalInformation: "Informations personnelles",
    FirstName: "Prénom",
    LastName: "Nom de famille",
    Nickname: "Surnom",
    Email: "Email",
    PhoneNumber: "Numéro de téléphone",
    Birthday: "Anniversaire",
    AgeNotEditable: "Âge (non modifiable)",
    MaritalStatus: "État civil",
    LivingWith: "Vit avec",
    IfOtherDescribe: "Si autre, décrire",
    Gender: "Genre",
    IfOtherGenderDescribe: "Si autre genre, décrire",
    SexualOrientation: "Orientation sexuelle",
    PhysicalAddressOfResidence: "Adresse physique de résidence",
    City: "Ville",
    ProvinceState: "Province / État",
    Country: "Pays",
    Nationality: "Nationalité",
    ClientSource: "Source du client",
    PassportNumber: "Numéro de passeport",
    VisaExpirationDate: "Date d'expiration du visa",
    TreatmentInformation: "Informations sur le traitement",
    ClientStatus: "Statut du client",
    RoomNumber: "Numéro de chambre",
    AssignedTherapist: "Thérapeute assigné",
    AssignedSupportStaff: "Personnel de soutien assigné",
    AdmissionAndDischarge: "Admission & Décharge",
    ActualAdmissionDate: "Date d'admission réelle",
    ActualDischargeDate: "Date de sortie réelle",
    ProjectedDischargeDate: "Date de sortie prévue",
    ProjAftercareCompletionDate: "Date d'achèvement des soins prévus",
    PassportPictures: "Photos de passeport",
    ImportFile: "Importer un fichier",
    MaximumFileSize: "Taille maximale du fichier : 100 Mo",
    UploadingFile: "Téléchargement du fichier...",
    UploadedFiles: "Fichier(s) téléchargé(s)",
    EmergencyContact: "Contact d'urgence",
    EmergencyContactFirstName: "Prénom du contact d'urgence",
    EmergencyContactLastName: "Nom de famille du contact d'urgence",
    Relationship: "Relation",
    IfOtherDescribe: "Si autre, décrire",
    ContactPhoneNumber: "Numéro de téléphone du contact",
    ContactEmail: "Email du contact",
    PhysicalAddressOfResidence: "Adresse physique de résidence",
    City: "Ville",
    Nationality: "Nationalité",
    StateProvince: "État / Province",
    Country: "Pays",
    PersonPayingForTreatment: "Personne payant pour le traitement",
    PhoneNumberOfPersonPaying: "Numéro de téléphone de la personne payante",
    SavingModifications: "ENREGISTREMENT DES MODIFICATIONS...",
    ClickToSave: "CLIQUEZ POUR ENREGISTRER",
    SaveModifications: "ENREGISTRER LES MODIFICATIONS",
    UploadLimitExceeded:
      "Vous pouvez uniquement télécharger jusqu'à 3 fichiers.",
    FileSizeExceedsLimit: "La taille du fichier dépasse la limite :",
    FilesSuccessfullyUploaded: "Fichier(s) téléchargé(s) avec succès !",
    FileSuccessfullyDeleted: "Fichier supprimé avec succès !",
    ErrorDeletingFile: "Erreur lors de la suppression du fichier",
    GeneralInformation: "Informations Générales",
    ActivityLogs: "Journaux d'Activité",
    Help: "Aide",
    Close: "Fermer",
    NoRecentActivity: "Aucune activité récente",
    Language: "Langue",
    Admin: "ADMIN",
    NoClientIdentifier: "Aucun identifiant client",
    NoTherapistAssignedYet: "Aucun thérapeute assigné pour le moment",
    AssignedTherapist: "Thérapeute assigné :",
    IntendedDurationOfStay: "Durée prévue du séjour",
    AdmissionDate: "Date d'admission",
    MandatoryForms: "Formulaires obligatoires",
    AreYouSureToDeleteNote: "Êtes-vous sûr de vouloir supprimer cette note ?",
    AreYouSureToDeleteFile: "Êtes-vous sûr de vouloir supprimer ce fichier ?",
    FileSuccessfullyDeleted: "Fichier supprimé avec succès !",
    ErrorDeletingFile: "Erreur lors de la suppression du fichier",
    NoteSuccessfullyDeleted: "Note supprimée avec succès !",
    ErrorDeletingNote: "Erreur lors de la suppression de la note :",
    Deleting: "Suppression...",
    Updating: "Mise à jour...",
    NoRecentActivity: "Aucune activité récente",
    LastModification: "Dernière modification",
    ClientAddedBy: "Client ajouté par",
    EstimateDateOfArrival: "Date d'arrivée estimée",
    IntendedDurationOfStay: "Durée de séjour prévue",
    PassportStatus: "Statut du passeport",
    PassportCopy: "Copie du passeport",
    PrevClientIdentifier: "Identifiant client précédent",
    FlightDetails: "Détails du vol",
    Itinerary: "Itinéraire",
    VIPTransfer: "Transfert VIP",
    PaymentDetails: "Détails de paiement",
    MaritalStatus: "Statut marital",
    CurrentPartnerIfNotMarried: "Partenaire actuel si non marié?",
    ChildrensOrDependents: "Enfants ou personnes à charge",
    CurrentLivingArrangements: "Arrangements de vie actuels",
    DietaryRequirements: "Exigences alimentaires",
    FundingSource: "Source de financement",
    PrimAddSubstOrProcAbove: "Add. principale (soit subst. ou proc. ci-dessus)",
    IfOtherSelectedDescribe: "Si autre sélectionné, décrire",
    SecondaryAddiction: "Addiction secondaire",
    IfOtherSelectedDescribeSecondary: "Si autre sélectionné, décrire",
    SubstanceAddictions: "Addiction(s) aux substances",
    DoCCurrentUsageLevels:
      "Niveaux d'utilisation actuels (fréquence, quantité) et méthode (fumage, injection, reniflement, oralement)",
    AnyCurrentWithdrawalSymptoms: "Symptômes de sevrage actuels",
    ProcessAddictions: "Addiction(s) aux processus",
    TypeProcessAddiction:
      "type, par ex. jeu, sexe, pornographie, nourriture, jeux vidéo ; et fréquence des comportements d'addiction aux processus",
    AddictionTreatmentHistory: "Historique du traitement de l'addiction",
    MainUnwantedConsequences:
      "Principales conséquences indésirables de l'addiction",
    HowLongHasAddictionBeenAProblem:
      "Depuis combien de temps votre addiction (usage/alcool/processus) est-elle un problème?",
    WhatLedYouToSeekTreatmentNow:
      "Qu'est-ce qui vous a poussé à rechercher un traitement maintenant?",
    TreatmentGoals: "Objectifs de traitement",
    BarriersForRehab: "Barrières pour la réadaptation",
    MotivationForTreatment: "Motivation pour le traitement",
    SavingModifications: "SAUVEGARDE DES MODIFICATIONS...",
    ClickToSave: "CLIQUEZ POUR ENREGISTRER",
    SaveModifications: "ENREGISTRER LES MODIFICATIONS",
    Addictions: "addictions",
    PreAdmissionAssessment: "Évaluation pré-admission",
    AdmissionForms: "Formulaires d'admission",
    AuthorizationReleaseConfidentialInfo:
      "Autorisation de divulgation d'informations confidentielles",
    AddRCIForm: "Ajouter un formulaire R.C.I.",
    UnableToAddMandatoryForms:
      "Impossible d'ajouter des formulaires obligatoires si le client n'est pas admis.",
    UnableToAddROIForm:
      "Impossible d'ajouter un formulaire de R.O.I. si le client n'est pas admis.",
    CompanyIndividualName: "Nom de l'entreprise / Individu",
    Filled: "Rempli",
    Revoked: "Révoqué",
    Actions: "Actions",
    Relationship: "Relation",
    SelectedClient: "Client sélectionné :",
    ConfidentialityPolicyForm: "Formulaire de politique de confidentialité",
    PleaseSignBelow:
      "Veuillez signer ci-dessous et cliquer sur Valider une fois terminé",
    SignatureNotValidated: "Signature non validée",
    SignatureValidated: "Signature validée!",
    Clear: "Effacer",
    Validate: "Valider",
    Saving: "Enregistrement...",
    GeneralLiabilityReleaseForm: "Décharge de responsabilité générale",
    GeneralLiabilityFormSubmitted:
      "Formulaire de décharge de responsabilité générale soumis avec succès !",
    ErrorAddingDocument: "Erreur lors de l'ajout du document",
    InformedConsentForm: "Consentement éclairé",
    InformedConsentFormSubmitted:
      "Formulaire de consentement éclairé soumis avec succès !",
    ConfidentialityPolicyFormSubmitted:
      "Politique de confidentialité soumis avec succès !",
    PleaseAgreeToTerms: "Veuillez accepter tous les termes avant de soumettre.",
    Print: "Imprimer",
    Intervention: "Intervention",
    AddTreatment: "Ajouter un traitement",
    Adding: "Ajout en cours...",
    Objective: "Objectif",
    AddObjective: "Ajouter un objectif",
    ObjectivesAssociatedInterventions:
      "Objectifs et interventions associées pendant le traitement au centre de réhabilitation :",
    PresentingIssues: "Problème(s) présenté(s) :",
    LongTermGoals: "Objectifs à long terme (au-delà du traitement au centre) :",
    FailedToSaveTreatmentPlan:
      "Échec de l'enregistrement du plan de traitement. Veuillez réessayer.",
    TreatmentPlanSuccessfullyAdded: "Plan de traitement ajouté avec succès !",
    PleaseFillRequiredFields: "Veuillez remplir tous les champs obligatoires.",
    ObjectiveIntervention: "Objectif / Intervention",
    TreatmentPlan: "Plan de traitement",
    DischargeSummary: "Résumé de la sortie",
    DischargeDate: "Date de sortie",
    DischargeType: "Type de sortie",
    DischargeTo: "Sortie vers",
    DateOfAdmission: "Date d'admission",
    TotalLengthOfStay: "Durée totale du séjour",
    MedicationPrescribedUponDischarge: "Médicaments prescrits à la sortie",
    IdentifiedIssuesUponAdmission: "Problèmes identifiés à l'admission",
    EmergingIssuesDuringTreatment:
      "Problèmes émergents identifiés pendant le traitement",
    OverallLevelOfEngagement: "Niveau général d'engagement dans le programme",
    Resistance: "Résistance",
    HighlyEngaged: "Très engagé",
    Progress: "Progrès",
    FamilyInvolvement: "Implication de la famille / du partenaire significatif",
    RoadblocksConcerns: "Obstacles/Préoccupations",
    ClientPrognosis: "Pronostic du client",
    RecommendedDischargePlan: "Plan de sortie recommandé",
    StaffMemberName: "Nom du membre du personnel",
    Submit: "Soumettre",
    LeaveAgainstClinicalAdvice: "Départ Contre Avis Médical (ACA)",
    EmergencyDischarge: "Sortie d'urgence",
    TreatmentPlanFor: "Plan de traitement pour:",
    EditTreatmentPlan: "Modifier le plan de traitement",
    Review: "Revue",
    TypeReviewHere: "Tapez la revue ici",
    AddReviews: "Ajouter des revues",
    UpdateReviews: "METTRE À JOUR LES REVUES",
    Updating: "MISE À JOUR EN COURS...",
    ObjectiveReviewsUpdated:
      "Les revues des objectifs ont été mises à jour avec succès !",
    FailedToUpdateObjectiveReviews:
      "Échec de la mise à jour des revues des objectifs. Veuillez réessayer.",
    TreatmentPlanUpdated:
      "Le plan de traitement a été mis à jour avec succès !",
    FailedToSaveTreatmentPlan:
      "Échec de l'enregistrement du plan de traitement. Veuillez réessayer.",

    SelectNoteType: "Sélectionner le type de note",
    StartDate: "Date de début",
    EndDate: "Date de fin",
    AddNote: "Ajouter une note",
    TotalNote: "Note totale",
    GeneralDocuments: "Documents généraux",
    TherapistsDocuments: "Documents des thérapeutes",
    CRM: "CRM",
    Medication: "Médication",
    Invoicing: "Facturation",
    Support: "Support",
    AddAlert: "Ajouter une alerte",
    EditAlert: "Modifier l'alerte",
    PleaseSelectTypeAndContent:
      "Veuillez sélectionner un type et entrer le contenu pour l'alerte",
    ConfirmDeleteAlert: "Êtes-vous sûr de vouloir supprimer l'alerte ?",
    ShowMore: "Voir plus",
    ShowLess: "Voir moins",
    Alerts: "ALERTES",
    ClinicalInformations: "Informations Cliniques",
    AddTreatmentPlan: "Ajouter un Plan de Traitement",
    NoTreatmentPlanAdded:
      "AUCUN PLAN DE TRAITEMENT AJOUTÉ. VEUILLEZ AJOUTER UN PLAN DE TRAITEMENT",
    Added: "Ajouté",
    Reviewed: "Revu",
    DischargeForms: "Formulaires de sortie",
    PrimaryAddictionColon: "Addiction Principale :",
    NoAdmissionDateYet: "Aucune date d'admission pour le moment",
    NoDurationOfStayYet: "Aucune durée de séjour pour le moment",
  },

  th: {
    AskMeExample: "ถามฉันได้ เช่น",
    GiveMeInhouseClientsList: "ให้ฉันดูรายชื่อของลูกค้าที่พักในศูนย์",
    WhatIsNextBirthday: "วันเกิดถัดไปคือวันไหน",
    SummarizeLatestNotes: "สรุปบันทึกล่าสุดให้ฉันดู",
    WhereCanISeeInhouseClientsList: "ฉันสามารถดูรายชื่อของลูกค้าที่พักในศูนย์ได้ที่ไหน",
    WelcomeToRehappApp: "ยินดีต้อนรับสู่แอป Rehapp",
    LatestSupportTickets: "ตั๋วสนับสนุนล่าสุด",
    NoTicketsFound: "ไม่พบตั๋ว",
    Reset: "รีเซ็ต",
    PleaseSendTicket: "กรุณาส่งตั๋วเกี่ยวกับปัญหาที่คุณมี",
    SupportTickets: "ตั๋วสนับสนุน",
    DashboardHelp: "เข้าถึงแดชบอร์ดเพื่อดูภาพรวมกิจกรรมและสถิติที่สำคัญ",
    ClientsHelp:
      "จัดการลูกค้าทั้งหมดตามประเภท (อยู่ในบ้าน ดูแลภายหลัง กำลังจะมา เป็นต้น)",
    NotesHelp: "ดูและกรองบันทึกตามประเภทเพื่อติดตามการโต้ตอบกับลูกค้า",
    RoomAvailabilityHelp: "ดูจำนวนห้องว่างต่อวัน",
    RoomBookingHelp: "ดูรายละเอียดการจองรายวันพร้อมชื่อและรายละเอียดของลูกค้า",
    CRMHelp:
      "นำเสนอบอร์ดคัมบังพร้อมการ์ดลูกค้าตามสถานะ ช่วยให้จัดการความคืบหน้าในการคัดเลือกลูกค้า",
    MetricsHelp: "แสดงเมตริกและสถิติต่างๆ",
    ExportDataHelp: "ส่งออกข้อมูลลูกค้าในกรณีที่ลูกค้าต้องการรับข้อมูลของตน",
    DocumentsHelp: "จัดเก็บเอกสารที่ผู้ใช้คนอื่นสามารถเข้าถึงได้",
    SupportHelp: "ส่งตั๋วไปยังฝ่ายสนับสนุนเพื่อขอความช่วยเหลือ",
    InHouseClientsInfo:
      "ที่นี่คุณสามารถดูจำนวนลูกค้าทั้งหมดที่อยู่ในสถานที่ปัจจุบัน คลิกที่วิดเจ็ตนี้เพื่อดูรายละเอียดเพิ่มเติมเกี่ยวกับลูกค้าเหล่านี้",
    NextClientDepartureInfo:
      "วิดเจ็ตนี้แสดงลูกค้าคนถัดไปที่มีกำหนดการออกเดินทางรวมถึงชื่อและวันที่ออกที่วางแผนไว้ คลิกเพื่อดูข้อมูลเพิ่มเติมเกี่ยวกับลูกค้าคนนี้",
    NextIncomingClientInfo:
      "วิดเจ็ตนี้แสดงลูกค้าคนถัดไปที่จะเข้ามาพร้อมกับวันที่คาดว่าจะมาถึง คลิกเพื่อเรียนรู้เพิ่มเติมเกี่ยวกับลูกค้าคนนี้",
    UpcomingClientsInfo:
      "วิดเจ็ตนี้แสดงจำนวนลูกค้าทั้งหมดที่มีกำหนดจะมาถึงในไม่ช้า คลิกเพื่อดูรายละเอียดเพิ่มเติมเกี่ยวกับลูกค้าเหล่านี้",

    MandatoryFormsSection:
      "นี่คือส่วน 'แบบฟอร์มที่จำเป็น' ซึ่งคุณสามารถเพิ่ม ดู และจัดการแบบฟอร์มที่จำเป็นทั้งหมดที่ต้องใช้สำหรับการเข้ารับและกระบวนการรักษาของลูกค้า โปรดตรวจสอบให้แน่ใจว่าเอกสารที่จำเป็นทั้งหมดถูกกรอกและเป็นปัจจุบันเพื่อให้ปฏิบัติตามกฎระเบียบและให้การดูแลอย่างครอบคลุม",
    AuthorizationSection:
      "นี่คือส่วน 'การอนุญาตให้เปิดเผยข้อมูลลับ' ที่นี่คุณมีความสามารถในการจัดการและเพิ่มแบบฟอร์มอนุญาตใหม่ที่อนุญาตให้เปิดเผยข้อมูลลับของลูกค้าแก่ฝ่ายที่ได้รับอนุญาต การจัดการเอกสารเหล่านี้อย่างระมัดระวังเป็นสิ่งสำคัญเพื่อรักษาความเป็นส่วนตัวและปฏิบัติตามข้อกำหนดทางกฎหมาย",
    SearchBar:
      "นี่คือแถบค้นหาที่คุณสามารถค้นหาลูกค้าโดยการป้อนชื่อหรือตัวข้อมูลที่เกี่ยวข้องอื่นๆ ช่วยให้คุณค้นหาและเข้าถึงรายละเอียดของลูกค้าได้อย่างรวดเร็ว ทำให้การทำงานของคุณมีประสิทธิภาพมากขึ้น",
    AIServicePrompt:
      "ลองใช้บริการ AI ของเราที่นี่! ฟีเจอร์นี้ใช้ประโยชน์จากปัญญาประดิษฐ์ที่ทันสมัยเพื่อช่วยคุณในงานต่างๆ ทำให้ประสบการณ์ของคุณราบรื่นและเป็นมิตรมากขึ้น คลิกที่นี่เพื่อสำรวจว่า AI สามารถเพิ่มประสิทธิภาพการทำงานของคุณและเสนอทางออกที่ชาญฉลาดได้อย่างไร",
    ProfileWidget:
      "นี่คือวิดเจ็ตโปรไฟล์ของคุณที่คุณสามารถจัดการการตั้งค่าบัญชี ดูข้อมูลโปรไฟล์และเข้าถึงความชอบส่วนบุคคลได้ มันให้วิธีที่รวดเร็วในการปรับแต่งประสบการณ์ของคุณและติดตามข้อมูลบัญชีของคุณ",

    PreviousWeek: "สัปดาห์ที่แล้ว",
    NextWeek: "สัปดาห์หน้า",
    AgeNonEditable: "อายุ (แก้ไขไม่ได้)",
    PageConcerned: "หน้าที่เกี่ยวข้อง",
    AddNewTicket: "เพิ่มตั๋วใหม่",
    WriteDescription: "เขียนคำอธิบายปัญหาของคุณด้านล่าง",
    UploadScreenshots: "อัปโหลดภาพหน้าจอของปัญหาด้านล่างหากคุณมี",
    TicketName: "ชื่อตั๋ว",
    Priority: "ลำดับความสำคัญ",
    TicketType: "ประเภทตั๋ว",
    Medium: "กลาง",
    Low: "ต่ำ",
    High: "สูง",
    DateOfBirth: "วันเกิด",
    DateOfBirthRequired: "จำเป็นต้องระบุวันเกิด",
    Create: "สร้าง",
    AddNewLead: "เพิ่ม Lead ใหม่",
    UpdateLead: "อัปเดต Lead",
    PhoneNumberRequired: "จำเป็นต้องระบุหมายเลขโทรศัพท์",
    FailedToCreateOrUpdateLead:
      "ไม่สามารถสร้าง/อัปเดต Lead ได้ โปรดลองอีกครั้ง",
    LeadUpdatedSuccessfully: "อัปเดต Lead สำเร็จแล้ว!",
    LeadCreatedSuccessfully: "สร้าง Lead สำเร็จแล้ว!",
    Dashboard: "หน้าแรก",
    Clients: "ลูกค้า",
    Client: "ลูกค้า",
    ClientsC: "ลูกค้า",
    ClientC: "ลูกค้า",
    Notes: "บันทึก",
    MyProfile: "โปรไฟล์ของฉัน",
    RoomAvailability: "ห้องว่าง",
    RoomBooking: "การจองห้องพัก",
    Metrics: "ตัวชี้วัด",
    ExportData: "ส่งออกข้อมูล",
    Documents: "เอกสาร",
    SearchClient: "ค้นหาลูกค้า...",
    Settings: "การตั้งค่า",
    SignOut: "ออกจากระบบ",
    NextDeparture: "การออกเดินทางครั้งต่อไป",
    NextIncoming: "การเข้ามาถัดไป",
    Incoming: "ขาเข้า",
    NoDischarges: "ไม่มีการปลดปล่อย",
    NoUpcomingClient: "ไม่มีลูกค้ารายใหม่",
    LastClientNotes: "บันทึกลูกค้าล่าสุด",
    Date: "วันที่",
    DateC: "วันที่",
    Content: "เนื้อหา",
    ContentC: "เนื้อหา",
    Type: "ประเภท",
    Edit: "แก้ไข",
    Delete: "ลบ",
    ShowMoreNotes: "แสดงบันทึกเพิ่มเติม",
    ShowLessNotes: "แสดงบันทึกน้อยลง",
    ClickHereForHelp: "คลิกที่นี่เพื่อรับความช่วยเหลือบนหน้าจอ",
    AddClient: "เพิ่มลูกค้า",
    AddTheClient: "เพิ่มลูกค้า",
    AddNewClient: "เพิ่มลูกค้าใหม่",
    FirstName: "ชื่อจริง",
    LastName: "นามสกุล",
    LastName2: "นามสกุล",
    Nickname: "ชื่อเล่น",
    Gender: "เพศ",
    Email: "อีเมล",
    PhoneNumber: "เบอร์โทรศัพท์",
    Country: "ประเทศ",
    PrimaryAddiction: "การเสพติดหลัก",
    PrimaryAddictionT: "การเสพติดหลัก",
    DurationOfStay: "ระยะเวลาที่เข้าพัก",
    ETA: "เวลามาถึง",
    ClientSource: "แหล่งลูกค้า",
    ProjectedResidentialDischargeDate: "วันที่คาดว่าจะออกจากที่พักอาศัย",
    ProjectedProgramCompletionDate: "วันที่คาดว่าจะจบโปรแกรม",
    ProjectedResidentialDischargeDateRequired:
      "วันที่คาดว่าจะออกจากที่พักอาศัยจำเป็นต้องระบุ",
    ProjectedProgramCompletionDateRequired:
      "วันที่คาดว่าจะจบโปรแกรมจำเป็นต้องระบุ",
    PersonalInfos: "ข้อมูลส่วนตัว",
    AddictionInfos: "ข้อมูลการเสพติด",
    DischargeInfos: "ข้อมูลการจำหน่าย",
    FirstNameRequired: "ต้องระบุชื่อจริง",
    LastNameRequired: "ต้องระบุนามสกุล",
    NicknameRequired: "ต้องระบุชื่อเล่น",
    EmailRequired: "ต้องระบุอีเมล",
    ETARequired: "ต้องระบุ ETA",
    Back: "ย้อนกลับ",
    Next: "ถัดไป",
    Finish: "เสร็จสิ้น",
    DemoTestNoClient: "ทดสอบสาธิต: ไม่มีลูกค้าเพิ่ม :)",
    ClientName: "ชื่อลูกค้า",
    TypeQuickSessionNote: "พิมพ์โน๊ตเซสชันด่วนที่นี่",
    NoteContent: "เนื้อหาของโน๊ต",
    EffectiveDateTime: "วันที่และเวลาที่มีผลของโน๊ต",
    EffectiveDate: "วันที่และเวลาที่มีผล",
    NoteContent: "เนื้อหาของโน๊ต",
    TypeNoteHere: "พิมพ์โน๊ตของคุณที่นี่",
    StartShift: "เริ่มกะทำงาน",
    TypeNoteHere: "พิมพ์โน๊ตของคุณที่นี่",
    EndShift: "จบกะทำงาน",
    SelectStaff: "เลือกพนักงาน",
    EffectiveDateOnly: "วันที่มีผล",
    FamilyNoteRelationship: "ความสัมพันธ์ในโน๊ตครอบครัว",
    GroupTopic: "หัวข้อกลุ่ม",
    GroupDateStartTime: "วันที่และเวลาเริ่มต้นของกลุ่ม",
    TypeOfGroup: "ประเภทของกลุ่ม",
    GroupNote: "โน๊ตกลุ่ม",
    TypeGroupNoteHere: "พิมพ์โน๊ตกลุ่มที่นี่",
    ClientsNotInAttendance: "ลูกค้าที่ไม่ได้เข้าร่วม",
    TypeNamesHere:
      "พิมพ์ชื่อที่นี่ (ชื่อจริง / หรือชื่อที่ต้องการและอักษรย่อของนามสกุล) และเหตุผลว่าทำไมลูกค้าไม่อยู่ในกลุ่ม, ถ้าทราบ...",
    ImmigrationAppointment: "การนัดหมายตรวจคนเข้าเมือง / วีซ่า",
    DischargesFlights: "การปล่อยตัว / การจัดการเที่ยวบิน",
    NewClients: "ลูกค้าใหม่",
    PsychiatristAppointments: "การนัดหมายกับจิตแพทย์",
    HospitalAppointments: "การนัดหมายโรงพยาบาล",
    Outings: "กิจกรรมภายนอก",
    ZoomSessionAssistance: "การช่วยเหลือเซสชัน Zoom",
    StaffToday: "พนักงานวันนี้",
    Overnight: "กลางคืน",
    NumberOfClients: "จำนวนลูกค้า",
    Swot: "Swot",
    GeneralAnnouncement: "ประกาศทั่วไป / การสนทนา",
    EditFamilyNote: "แก้ไขโน๊ตครอบครัว",
    EditQuickNote: "แก้ไขโน๊ตด่วน",
    EditAftercareNote: "แก้ไขโน๊ตการดูแลต่อเนื่อง",
    EditGroupNote: "แก้ไขโน๊ตกลุ่ม",
    EditMedicalNote: "แก้ไขโน๊ตทางการแพทย์",
    EditSupportStaffNote: "แก้ไขโน๊ตพนักงานสนับสนุน",
    EditTherapyNote: "แก้ไขโน๊ตบำบัด",
    EditHandoverNote: "แก้ไขโน๊ตการส่งต่อ",
    Cancel: "ยกเลิก",
    Save: "บันทึก",
    TypeGroupNoteHere: "พิมพ์บันทึกกลุ่มของคุณที่นี่",
    TypeMedicalNoteHere: "พิมพ์บันทึกทางการแพทย์ของคุณที่นี่",
    ImportFile: "นำเข้าไฟล์",
    MaximumFileSize: "ขนาดไฟล์สูงสุด : 50 MB",
    SelectedFile: "ไฟล์ที่เลือก",
    FileAlreadyUploaded: "ไฟล์ถูกอัปโหลดแล้ว",
    AreYouSureToDeleteFile: "คุณแน่ใจหรือว่าต้องการลบไฟล์นี้?",
    Confirm: "ยืนยัน",
    ConfirmDeletion: "ยืนยันการลบ",
    TotalRecord: "บันทึกรวม",
    Filters: "ตัวกรอง",
    SelectClientType: "เลือกประเภทลูกค้า",
    SelectTherapist: "เลือกนักบำบัด",
    SelectAddiction: "เลือกการเสพติด",
    SelectILS: "เลือก ILS",
    ResetFilters: "รีเซ็ตตัวกรอง",
    ADDICTION: "การเสพติด",
    AKA: "ชื่อเล่น",
    AGE: "อายุ",
    ADMISSION: "การรับเข้า",
    THERAPIST: "นักบำบัด",
    ROOM: "ห้อง #",
    SUPPORT_STAFF: "เจ้าหน้าที่สนับสนุน",
    PleaseSelectBothDates: "โปรดเลือกทั้งวันเริ่มต้นและวันสิ้นสุด",
    SelectRangeDate: "เลือกช่วงวันที่:",
    SeeMore: "ดูเพิ่มเติม",
    TO: "ถึง",
    RoomsAvailablePerDay: "ห้องว่าง / วัน",
    DistributionPerDay: "การแจกจ่าย / วัน",
    RoomAvailability: "ความพร้อมของห้องพัก",
    RoomBooking: "การจองห้องพัก",
    FullscreenMode: "โหมดเต็มจอ",
    NoCredentials:
      "--คุณไม่มีข้อมูลประจำตัว Super-admin หรือ Admin เพื่อเข้าถึงหน้านี้--",
    ExitFullscreen: "ออกจากโหมดเต็มจอ",
    TodaysDate: "วันที่วันนี้",
    IncomingDischarges: "การปลดปล่อยที่จะเข้ามา",
    NightsMo: "คืน Mo.",
    LOSToDate: "L.O.S ถึงปัจจุบัน",
    LOSIntended: "L.O.S.(ที่ตั้งใจไว้)",
    TotalNightsBooked: "คืนที่จองทั้งหมด",
    TotalIncomingNights: "คืนที่กำลังจะมาถึงทั้งหมด",
    TotalPotentialNights: "คืนที่มีศักยภาพทั้งหมด",
    TotalProjectedNights: "คืนที่คาดการณ์ทั้งหมด",
    January: "มกราคม",
    February: "กุมภาพันธ์",
    March: "มีนาคม",
    April: "เมษายน",
    May: "พฤษภาคม",
    June: "มิถุนายน",
    July: "กรกฎาคม",
    August: "สิงหาคม",
    September: "กันยายน",
    October: "ตุลาคม",
    November: "พฤศจิกายน",
    December: "ธันวาคม",
    BedOccRate: "อัตราการครองเตียง",
    InHouseClients: "# ผู้ป่วยใน",
    AvTreatmentDur: "ระยะเวลาการรักษาเฉลี่ย",
    ClientsByAddictionTypeInhouse: "ผู้ป่วยในตามประเภทการเสพติด",
    ClientsByAddictionTypeAllClients: "ผู้ป่วยทั้งหมดตามประเภทการเสพติด",
    ClientsByTherapistInhouse: "ผู้ป่วยในตามนักบำบัด",
    Day: "วัน",
    Status: "สถานะ",
    Nationality: "สัญชาติ",
    SecondaryAddiction: "การเสพติดทุติยภูมิ",
    LengthOfStay: "ระยะเวลาการเข้าพัก",
    LengthOfStay: "ระยะเวลาพัก",
    InActiveRecovery: "อยู่ในการฟื้นฟูหรือไม่?",
    GoogleReviewDone: "รีวิว Google เสร็จหรือยัง?",
    ExportData: "ส่งออกข้อมูล",
    ExportData2: "ส่งออกข้อมูล",
    Documents: "เอกสาร",
    UPLOADING: "กำลังอัปโหลด...",
    UPLOAD: "อัปโหลด",
    NoFileSelected: "ไม่ได้เลือกไฟล์ กรุณาเลือกไฟล์และตั้งชื่อไฟล์",
    ChooseFile: "เลือกไฟล์",
    FileRequirements:
      "(ครั้งละหนึ่งไฟล์เท่านั้น รองรับเฉพาะ PDF, JPG หรือ PNG ขนาดไม่เกิน 5MB)",
    UploadDocument: "อัปโหลดไฟล์เอกสาร",
    NoFilesFound: "ไม่พบไฟล์",
    NoDeletePermissions: "คุณไม่มีสิทธิ์ลบไฟล์นี้",
    OneFileLimit: "คุณสามารถอัปโหลดไฟล์ได้เพียงไฟล์เดียวเท่านั้น",
    FileDeleted: "ลบไฟล์สำเร็จ!",
    ErrorDeletingFile: "เกิดข้อผิดพลาดในการลบไฟล์",
    FileSizeExceedsLimit: "ขนาดไฟล์เกินขีดจำกัด:",
    FilesUploaded: "อัปโหลดไฟล์สำเร็จ!",
    ErrorUpdatingClient: "เกิดข้อผิดพลาดในการอัปเดตลูกค้า:",
    NoFilesSelected: "ไม่มีไฟล์ที่เลือกสำหรับการอัปโหลด",
    FileName: "ชื่อไฟล์",
    AreYouSureYouWantToDeleteTheFile: "คุณแน่ใจหรือไม่ว่าต้องการลบไฟล์นี้",
    permanently: "อย่างถาวร?",
    TryOurAI: "ลอง A.I. ของเรา",
    ArtificialIntelligence: "ปัญญาประดิษฐ์",
    Photo: "รูปถ่าย",
    Email: "อีเมล",
    PhoneNumber: "เบอร์โทร",
    Role: "บทบาท",
    Edit: "แก้ไข",
    ChooseFile: "เลือกไฟล์",
    Profile: "โปรไฟล์",
    ProfileUpdatedSuccessfully: "อัปเดตโปรไฟล์สำเร็จ",
    ErrorUpdatingProfile: "ข้อผิดพลาดในการอัปเดตโปรไฟล์:",
    User: "ผู้ใช้",
    SuccessfullyLoggedOut: "ออกจากระบบสำเร็จ",
    LogOut: "ออกจากระบบ",
    Name: "ชื่อ",
    AskMeAnything: "ถามฉันอะไรก็ได้",
    NoPrimaryAddictionRecorded: "ไม่มีการบันทึกการเสพติดหลัก",
    PromptInstruction:
      "ในช่องคำสั่งด้านล่าง ให้ถามคำถามใดๆ เกี่ยวกับ EHR นี้และคำถามเกี่ยวกับลูกค้า คุณสามารถถามในภาษาใดก็ได้",
    AnonymizedRequests:
      "ทุกคำขอจะถูกทำให้ไม่ระบุตัวตน (ชื่อจริงของลูกค้าจะไม่ถูกส่งไปยังแบบจำลอง AI)",
    AskAbout: "ถามเกี่ยวกับ :",
    HowToAddClient: "ฉันจะเพิ่มลูกค้าได้อย่างไร?",
    GenerateReport: "ฉันสามารถสร้างรายงานความก้าวหน้าของลูกค้าได้ไหม?",
    JanesProgress: "ความก้าวหน้าของเจนเป็นอย่างไร?",
    JohnsRecentNotes: "บันทึกล่าสุดของจอห์นคืออะไร?",
    SummaryOfNotes: "ให้สรุปบันทึกของสัปดาห์ที่แล้วหน่อย",
    NoteAddedSuccess: "เพิ่มบันทึกสำเร็จ",
    SelectedClient: "ลูกค้าที่เลือก",
    NoClientSelected: "--ยังไม่ได้เลือกลูกค้า--",
    SelectAClient: "เลือกลูกค้า",
    TypeNamesAndReason:
      "พิมพ์ชื่อที่นี่ (ชื่อจริง / หรือชื่อที่ต้องการและชื่อย่อของนามสกุล) และเหตุผลว่าทำไมลูกค้าไม่อยู่ในกลุ่ม หากทราบ...",
    IfOtherDescribe: "ถ้าอื่น ๆ กรุณาอธิบาย",
    StartOfShift: "เริ่มกะ",
    EndOfShift: "สิ้นสุดกะ",
    SelectStaff: "เลือกพนักงาน",
    Add: "เพิ่ม",
    EditEndOfShiftNote: "แก้ไขบันทึกสิ้นสุดกะ",
    WelcomeToRehabAppDemo: "ยินดีต้อนรับสู่ Rehab-App (สาธิต)",
    ContinueWithGoogle: "ดำเนินการต่อด้วย Google",
    LogInWithGoogle: "เข้าสู่ระบบด้วย Google",
    InternetConnectionWorking: "การเชื่อมต่ออินเทอร์เน็ตของคุณทำงานได้",
    InternetConnectionNotWorking:
      "การเชื่อมต่ออินเทอร์เน็ตของคุณไม่ทำงาน กรุณาตรวจสอบการเชื่อมต่ออินเทอร์เน็ตของคุณ",
    SuccessfullyLoggedInWithGoogle:
      "เข้าสู่ระบบเรียบร้อยแล้วโดยใช้บัญชี Google ของคุณ",
    MustLogInWithMiraclesAsiaEmail:
      "คุณต้องเข้าสู่ระบบด้วยที่อยู่อีเมลที่ลงท้ายด้วย @miraclesasia.com เพื่อเข้าถึงแอปพลิเคชันนี้",
    FailedToLogInWithGoogle: "ไม่สามารถเข้าสู่ระบบด้วยบัญชี Google ของคุณ",
    NoRoleFoundForUser: "ไม่พบบทบาทสำหรับผู้ใช้รายนี้ใน Firestore",
    MustLogInWithGmailOrGsuite:
      "คุณต้องเข้าสู่ระบบด้วยที่อยู่อีเมล Gmail หรือ G Suite เพื่อเข้าถึงแอปพลิเคชันนี้",
    ClientNameRequired: "จำเป็นต้องมีชื่อคลายแอน",
    ContentRequired: "จำเป็นต้องมีเนื้อหา",
    EffectiveDateRequired: "จำเป็นต้องมีวันที่ที่มีผล",
    ImmigrationAppointmentRequired: "จำเป็นต้องมีการนัดหมายตรวจคนเข้าเมือง",
    DischargesFlightsRequired: "จำเป็นต้องมีการปล่อยตัว / เที่ยวบิน",
    NewClientsRequired: "จำเป็นต้องมีลูกค้าใหม่",
    PsychiatristAppointmentsRequired: "จำเป็นต้องมีการนัดหมายกับจิตแพทย์",
    HospitalAppointmentsRequired: "จำเป็นต้องมีการนัดหมายที่โรงพยาบาล",
    OutingsRequired: "จำเป็นต้องมีการออกนอกสถานที่",
    ZoomSessionAssistanceRequired: "จำเป็นต้องมีการสนับสนุนเซสชัน Zoom",
    StaffTodayRequired: "จำเป็นต้องมีพนักงานในวันนี้",
    OvernightRequired: "จำเป็นต้องค้างคืน",
    NumberOfClientsRequired: "จำเป็นต้องมีจำนวนลูกค้า",
    SwotRequired: "จำเป็นต้องมี SWOT",
    GeneralAnnouncementRequired: "จำเป็นต้องมีการประกาศทั่วไป",
    GroupTopicRequired: "จำเป็นต้องมีหัวข้อกลุ่ม",
    StartTimeRequired: "จำเป็นต้องมีเวลาที่เริ่มต้น",
    GroupTypeRequired: "จำเป็นต้องมีประเภทกลุ่ม",
    NotInAttendanceRequired: "จำเป็นต้องมีผู้ที่ไม่เข้าร่วม",
    StartOfShiftRequired: "จำเป็นต้องมีการเริ่มต้นกะ",
    EndOfShiftRequired: "จำเป็นต้องมีการสิ้นสุดกะ",
    StaffRequired: "จำเป็นต้องมีพนักงาน",
    PleaseFillAllRequiredFields: "กรุณากรอกข้อมูลที่จำเป็นทั้งหมด",
    PersonalInformation: "ข้อมูลส่วนบุคคล",
    FirstName: "ชื่อ",
    LastName: "นามสกุล",
    Nickname: "ชื่อเล่น",
    Email: "อีเมล",
    PhoneNumber: "หมายเลขโทรศัพท์",
    Birthday: "วันเกิด",
    AgeNotEditable: "อายุ (แก้ไขไม่ได้)",
    MaritalStatus: "สถานภาพสมรส",
    LivingWith: "อาศัยอยู่กับ",
    IfOtherDescribe: "หากอื่น ๆ โปรดระบุ",
    Gender: "เพศ",
    IfOtherGenderDescribe: "หากเพศอื่น ๆ โปรดระบุ",
    SexualOrientation: "รสนิยมทางเพศ",
    PhysicalAddressOfResidence: "ที่อยู่ที่พักอาศัย",
    City: "เมือง",
    ProvinceState: "จังหวัด / รัฐ",
    Country: "ประเทศ",
    Nationality: "สัญชาติ",
    ClientSource: "แหล่งที่มาของลูกค้า",
    PassportNumber: "หมายเลขหนังสือเดินทาง",
    VisaExpirationDate: "วันหมดอายุของวีซ่า",
    TreatmentInformation: "ข้อมูลการรักษา",
    ClientStatus: "สถานะลูกค้า",
    RoomNumber: "หมายเลขห้อง",
    AssignedTherapist: "นักบำบัดที่ได้รับมอบหมาย",
    AssignedSupportStaff: "พนักงานสนับสนุนที่ได้รับมอบหมาย",
    AdmissionAndDischarge: "การรับเข้า & การปล่อยตัว",
    ActualAdmissionDate: "วันที่เข้ารับการรักษาจริง",
    ActualDischargeDate: "วันที่ปล่อยตัวจริง",
    ProjectedDischargeDate: "วันที่ปล่อยตัวที่คาดการณ์ไว้",
    ProjAftercareCompletionDate:
      "วันที่เสร็จสิ้นการดูแลหลังการรักษาที่คาดการณ์ไว้",
    PassportPictures: "รูปถ่ายหนังสือเดินทาง",
    ImportFile: "นำเข้าไฟล์",
    MaximumFileSize: "ขนาดไฟล์สูงสุด: 100MB",
    UploadingFile: "กำลังอัปโหลดไฟล์...",
    UploadedFiles: "ไฟล์ที่อัปโหลดแล้ว",
    EmergencyContact: "ผู้ติดต่อฉุกเฉิน",
    EmergencyContactFirstName: "ชื่อจริงของผู้ติดต่อฉุกเฉิน",
    EmergencyContactLastName: "นามสกุลของผู้ติดต่อฉุกเฉิน",
    Relationship: "ความสัมพันธ์",
    IfOtherDescribe: "ถ้าอื่น ๆ โปรดอธิบาย",
    ContactPhoneNumber: "หมายเลขโทรศัพท์ของผู้ติดต่อ",
    ContactEmail: "อีเมลของผู้ติดต่อ",
    PhysicalAddressOfResidence: "ที่อยู่ที่พักอาศัย",
    City: "เมือง",
    Nationality: "สัญชาติ",
    StateProvince: "รัฐ / จังหวัด",
    Country: "ประเทศ",
    PersonPayingForTreatment: "บุคคลที่ชำระเงินสำหรับการรักษา",
    PhoneNumberOfPersonPaying: "หมายเลขโทรศัพท์ของบุคคลที่ชำระเงิน",
    SavingModifications: "กำลังบันทึกการแก้ไข...",
    ClickToSave: "คลิกเพื่อบันทึก",
    SaveModifications: "บันทึกการแก้ไข",
    UploadLimitExceeded: "คุณสามารถอัปโหลดได้สูงสุด 3 ไฟล์เท่านั้น",
    FileSizeExceedsLimit: "ขนาดไฟล์เกินขีดจำกัด:",
    FilesSuccessfullyUploaded: "ไฟล์อัปโหลดสำเร็จ!",
    FileSuccessfullyDeleted: "ไฟล์ถูกลบสำเร็จ!",
    ErrorDeletingFile: "เกิดข้อผิดพลาดในการลบไฟล์",
    GeneralInformation: "ข้อมูลทั่วไป",
    ActivityLogs: "บันทึกกิจกรรม",
    Help: "ช่วยเหลือ",
    Close: "ปิด",
    NoRecentActivity: "ไม่มีความเคลื่อนไหวล่าสุด",
    Language: "ภาษา",
    Admin: "ผู้ดูแลระบบ",
    NoClientIdentifier: "ไม่มีตัวระบุลูกค้า",
    NoTherapistAssignedYet: "ยังไม่มีการกำหนดนักบำบัด",
    AssignedTherapist: "นักบำบัดที่ได้รับมอบหมาย:",
    IntendedDurationOfStay: "ระยะเวลาที่ตั้งใจจะพัก",
    AdmissionDate: "วันที่เข้ารับการรักษา",
    MandatoryForms: "แบบฟอร์มบังคับ",
    AreYouSureToDeleteNote: "คุณแน่ใจหรือไม่ว่าต้องการลบโน้ตนี้?",
    AreYouSureToDeleteFile: "คุณแน่ใจหรือไม่ว่าต้องการลบไฟล์นี้?",
    FileSuccessfullyDeleted: "ลบไฟล์สำเร็จแล้ว!",
    ErrorDeletingFile: "เกิดข้อผิดพลาดในการลบไฟล์",
    NoteSuccessfullyDeleted: "ลบโน้ตสำเร็จแล้ว!",
    ErrorDeletingNote: "เกิดข้อผิดพลาดในการลบโน้ต:",
    Deleting: "กำลังลบ...",
    Updating: "กำลังอัปเดต...",
    NoRecentActivity: "ไม่มีการทำกิจกรรมล่าสุด",
    LastModification: "การแก้ไขล่าสุด",
    ClientAddedBy: "ลูกค้าเพิ่มโดย",
    EstimateDateOfArrival: "วันที่คาดว่าจะมาถึง",
    IntendedDurationOfStay: "ระยะเวลาที่ตั้งใจจะพัก",
    PassportStatus: "สถานะพาสปอร์ต",
    PassportCopy: "สำเนาพาสปอร์ต",
    PrevClientIdentifier: "รหัสประจำตัวลูกค้าก่อนหน้านี้",
    FlightDetails: "รายละเอียดเที่ยวบิน",
    Itinerary: "แผนการเดินทาง",
    VIPTransfer: "การโอน VIP",
    PaymentDetails: "รายละเอียดการชำระเงิน",
    MaritalStatus: "สถานภาพการสมรส",
    CurrentPartnerIfNotMarried: "คู่สมรสในปัจจุบันหากไม่ได้แต่งงาน?",
    ChildrensOrDependents: "บุตรหรือผู้อยู่ในอุปการะ",
    CurrentLivingArrangements: "การจัดการที่อยู่อาศัยปัจจุบัน",
    DietaryRequirements: "ข้อกำหนดทางโภชนาการ",
    FundingSource: "แหล่งเงินทุน",
    PrimAddSubstOrProcAbove:
      "การเสพติดหลัก (ทั้งการใช้สารเสพติดหรือกระบวนการที่กล่าวข้างต้น)",
    IfOtherSelectedDescribe: "หากเลือกอื่นๆ ให้ระบุ",
    SecondaryAddiction: "การเสพติดรอง",
    IfOtherSelectedDescribeSecondary: "หากเลือกอื่นๆ ให้ระบุ",
    SubstanceAddictions: "การเสพติดสารเสพติด",
    DoCCurrentUsageLevels:
      "ระดับการใช้งานในปัจจุบัน (ความถี่, ปริมาณ) และวิธี (การสูบบุหรี่, การฉีด, การดม, การรับประทานทางปาก)",
    AnyCurrentWithdrawalSymptoms: "อาการถอนสารเสพติดในปัจจุบัน",
    ProcessAddictions: "การเสพติดกระบวนการ",
    TypeProcessAddiction:
      "ประเภท, เช่น การพนัน, เซ็กส์, สื่อลามก, อาหาร, การเล่นเกม; และความถี่ในการมีส่วนร่วมในพฤติกรรมการเสพติดกระบวนการ",
    AddictionTreatmentHistory: "ประวัติการรักษาการเสพติด",
    MainUnwantedConsequences: "ผลกระทบที่ไม่พึงประสงค์หลักของการเสพติด",
    HowLongHasAddictionBeenAProblem:
      "การใช้ยาเสพติด/ดื่มสุรา/กระบวนการเสพติดเป็นปัญหานานเท่าไหร่?",
    WhatLedYouToSeekTreatmentNow:
      "อะไรเป็นแรงจูงใจให้คุณต้องการเข้ารับการรักษาในขณะนี้?",
    TreatmentGoals: "เป้าหมายการรักษา",
    BarriersForRehab: "อุปสรรคในการบำบัด",
    MotivationForTreatment: "แรงจูงใจในการรักษา",
    SavingModifications: "กำลังบันทึกการเปลี่ยนแปลง...",
    ClickToSave: "คลิกเพื่อบันทึก",
    SaveModifications: "บันทึกการเปลี่ยนแปลง",
    Addictions: "การเสพติด",
    PreAdmissionAssessment: "การประเมินก่อนเข้ารับการรักษา",
    AdmissionForms: "แบบฟอร์มการรับเข้า",
    AuthorizationReleaseConfidentialInfo: "การอนุญาตให้เปิดเผยข้อมูลลับ",
    AddRCIForm: "เพิ่มแบบฟอร์ม R.C.I.",
    UnableToAddMandatoryForms:
      "ไม่สามารถเพิ่มแบบฟอร์มบังคับได้หากยังไม่ได้รับการรับรองลูกค้า",
    UnableToAddROIForm:
      "ไม่สามารถเพิ่มแบบฟอร์ม R.O.I. ได้หากยังไม่ได้รับการรับรองลูกค้า",
    CompanyIndividualName: "ชื่อบริษัท / บุคคล",
    Filled: "กรอกแล้ว",
    Revoked: "เพิกถอน",
    Actions: "การดำเนินการ",
    Relationship: "ความสัมพันธ์",
    SelectedClient: "ลูกค้าที่เลือก:",
    ConfidentialityPolicyForm: "แบบฟอร์มนโยบายความลับ",
    PleaseSignBelow: "กรุณาลงชื่อด้านล่างและคลิกยืนยันเมื่อเสร็จสิ้น",
    SignatureNotValidated: "ลายเซ็นไม่ได้รับการยืนยัน",
    SignatureValidated: "ลายเซ็นได้รับการยืนยัน!",
    Clear: "ล้าง",
    Validate: "ยืนยัน",
    Saving: "กำลังบันทึก...",
    GeneralLiabilityReleaseForm: "แบบฟอร์มการสละความรับผิดชอบทั่วไป",
    GeneralLiabilityFormSubmitted:
      "แบบฟอร์มการสละความรับผิดชอบทั่วไปส่งเรียบร้อยแล้ว!",
    ErrorAddingDocument: "เกิดข้อผิดพลาดในการเพิ่มเอกสาร",
    InformedConsentForm: "แบบฟอร์มยินยอมที่ได้รับการแจ้ง",
    InformedConsentFormSubmitted:
      "แบบฟอร์มยินยอมที่ได้รับการแจ้งส่งเรียบร้อยแล้ว!",
    ConfidentialityPolicyFormSubmitted:
      "แบบฟอร์มนโยบายความลับส่งเรียบร้อยแล้ว!",
    PleaseAgreeToTerms: "โปรดตกลงตามเงื่อนไขทั้งหมดก่อนที่จะส่ง",
    Print: "พิมพ์",
    Intervention: "การแทรกแซง",
    AddTreatment: "เพิ่มการรักษา",
    Adding: "กำลังเพิ่ม...",
    Objective: "วัตถุประสงค์",
    AddObjective: "เพิ่มวัตถุประสงค์",
    ObjectivesAssociatedInterventions:
      "วัตถุประสงค์และการแทรกแซงที่เกี่ยวข้องขณะรับการรักษาที่ศูนย์บำบัด:",
    PresentingIssues: "ปัญหาที่นำเสนอ:",
    LongTermGoals: "เป้าหมายระยะยาว (หลังการรักษาที่ศูนย์บำบัด):",
    FailedToSaveTreatmentPlan:
      "ไม่สามารถบันทึกแผนการรักษาได้ กรุณาลองอีกครั้ง.",
    TreatmentPlanSuccessfullyAdded: "แผนการรักษาเพิ่มเรียบร้อยแล้ว!",
    PleaseFillRequiredFields: "กรุณากรอกข้อมูลในช่องที่จำเป็นทั้งหมด.",
    ObjectiveIntervention: "วัตถุประสงค์ / การแทรกแซง",
    TreatmentPlan: "แผนการรักษา",
    DischargeSummary: "สรุปการออกจากการรักษา",
    DischargeDate: "วันที่ออกจากการรักษา",
    DischargeType: "ประเภทการออกจากการรักษา",
    DischargeTo: "ออกจากการรักษาไปยัง",
    DateOfAdmission: "วันที่เข้ารับการรักษา",
    TotalLengthOfStay: "ระยะเวลาการรักษาทั้งหมด",
    MedicationPrescribedUponDischarge: "ยาที่สั่งจ่ายเมื่อออกจากการรักษา",
    IdentifiedIssuesUponAdmission: "ปัญหาที่ระบุได้เมื่อเข้ารับการรักษา",
    EmergingIssuesDuringTreatment: "ปัญหาที่เกิดขึ้นระหว่างการรักษา",
    OverallLevelOfEngagement: "ระดับการมีส่วนร่วมในโปรแกรมโดยรวม",
    Resistance: "ความต้านทาน",
    HighlyEngaged: "มีส่วนร่วมสูง",
    Progress: "ความก้าวหน้า",
    FamilyInvolvement: "การมีส่วนร่วมของครอบครัว / คนสำคัญ",
    RoadblocksConcerns: "อุปสรรค/ข้อกังวล",
    ClientPrognosis: "พยากรณ์โรคของผู้ป่วย",
    RecommendedDischargePlan: "แผนการออกจากการรักษาที่แนะนำ",
    StaffMemberName: "ชื่อสมาชิกทีมงาน",
    Submit: "ส่ง",
    LeaveAgainstClinicalAdvice: "ออกจากโรงพยาบาลโดยขัดคำแนะนำทางการแพทย์ (ACA)",
    EmergencyDischarge: "การออกฉุกเฉิน",
    TreatmentPlanFor: "แผนการรักษาสำหรับ:",
    EditTreatmentPlan: "แก้ไขแผนการรักษา",
    Review: "รีวิว",
    TypeReviewHere: "พิมพ์รีวิวที่นี่",
    AddReviews: "เพิ่มรีวิว",
    UpdateReviews: "อัปเดตรีวิว",
    Updating: "กำลังอัปเดต...",
    ObjectiveReviewsUpdated: "อัปเดตการตรวจสอบวัตถุประสงค์สำเร็จแล้ว!",
    FailedToUpdateObjectiveReviews:
      "การอัปเดตการตรวจสอบวัตถุประสงค์ล้มเหลว กรุณาลองใหม่อีกครั้ง.",
    TreatmentPlanUpdated: "อัปเดตแผนการรักษาสำเร็จแล้ว!",
    FailedToSaveTreatmentPlan:
      "การบันทึกแผนการรักษาล้มเหลว กรุณาลองใหม่อีกครั้ง.",
    SelectNoteType: "เลือกประเภทบันทึก",
    StartDate: "วันที่เริ่มต้น",
    EndDate: "วันที่สิ้นสุด",
    AddNote: "เพิ่มบันทึก",
    GeneralDocuments: "เอกสารทั่วไป",
    TherapistsDocuments: "เอกสารของนักบำบัด",
    CRM: "CRM",
    Medication: "ยา",
    Invoicing: "การออกใบแจ้งหนี้",
    Support: "สนับสนุน",
    AddAlert: "เพิ่มการแจ้งเตือน",
    EditAlert: "แก้ไขการแจ้งเตือน",
    PleaseSelectTypeAndContent:
      "โปรดเลือกประเภทและป้อนเนื้อหาสำหรับการแจ้งเตือน",
    ConfirmDeleteAlert: "คุณแน่ใจหรือไม่ว่าต้องการลบการแจ้งเตือนนี้?",
    ShowMore: "แสดงเพิ่มเติม",
    ShowLess: "แสดงน้อยลง",
    Alerts: "การแจ้งเตือน",
    ClinicalInformations: "ข้อมูลทางคลินิก",
    AddTreatmentPlan: "เพิ่มแผนการรักษา",
    NoTreatmentPlanAdded: "ไม่ได้เพิ่มแผนการรักษา โปรดเพิ่มแผนการรักษา",
    Added: "เพิ่ม",
    Reviewed: "ตรวจสอบแล้ว",
    DischargeForms: "แบบฟอร์มการจำหน่าย",
    PrimaryAddictionColon: "การเสพติดหลัก:",
    NoAdmissionDateYet: "ยังไม่มีการบันทึกวันที่รับเข้า",
    NoDurationOfStayYet: "ยังไม่มีการบันทึกระยะเวลาการเข้าพัก",
    YearsOld: "ปี",
    ClientProfile: "โปรไฟล์ลูกค้า",
  },
};
