import React, { useState } from "react";
import { Button, Checkbox } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import TextFieldComponentLarge from "../TextFieldComponentLarge";
import InputComponent from "../InputComponent";
import SignaturePadComponent from "../Signature/SignaturePadComponent";
import PhoneNumberInput from "../PhoneNumberInput";
import DropDownComponent from "../DropDownComponent";
import { db } from "../../firebase";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadString,
} from "firebase/storage";
import { useAuth } from "../../contexts/AuthContext";
import { showCustomToast } from "../Misc/CustomToast";
import TopTitleFormComponentMobile from "./TopTitleFormComponentMobile";
import SignaturePadComponentV2 from "../Signature/SignaturePadComponentV2";
import TextFieldComponentLargeMobile from "../TextFieldComponentLargeMobile";
import TopTitleFormComponentMobileV2 from "./TopTitleFormComponentMobileV2";
import SignaturePadComponentV2Mobile from "../Signature/SignaturePadComponentV2Mobile";

function AddReleaseOfInformationFormMobileV2(props) {
  const { currentUser } = useAuth();

  const { clientId } = props;
  const [companyIndividualName, setCompanyIndividualName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [relationship, setRelationship] = useState("");
  const [purpose, setPurpose] = useState("");
  const [signatureDataURL, setSignatureDataURL] = useState(null);
  const [isSignatureValidated, setIsSignatureValidated] = useState(false);
  const [acknowledgmentOfPresence, setAcknowledgmentOfPresence] =
    useState(false);

  const [neededInfos, setNeededInfos] = useState(false);
  const [clinicalInformation, setClinicalInformation] = useState(false);
  const [psychEvaluation, setPsychEvaluation] = useState(false);
  const [referralLetter, setReferralLetter] = useState(false);
  const [labData, setLabData] = useState(false);
  const [progressNotes, setProgressNotes] = useState(false);
  const [medicalRecords, setMedicalRecords] = useState(false);
  const [progressInTreatment, setProgressInTreatment] = useState(false);
  const [psychosocialAssessment, setPsychosocialAssessment] = useState(false);
  const [medicationInfo, setMedicationInfo] = useState(false);
  const [treatmentPlan, setTreatmentPlan] = useState(false);
  const [physicalAssessment, setPhysicalAssessment] = useState(false);
  const [dischargeSummary, setDischargeSummary] = useState(false);
  const [familyHistory, setFamilyHistory] = useState(false);
  const [dischargePlan, setDischargePlan] = useState(false);
  const [admissionDischargeDates, setAdmissionDischargeDates] = useState(false);
  const [otherInfo, setOtherInfo] = useState("");
  const [otherInfoInput, setOtherInfoInput] = useState("");
  const [otherRelationShip, setOtherRelationship] = useState("");
  const [, setIsSignatureStarted] = useState(false);
  const handleCompanyIndividualNameChange = (e) => setCompanyIndividualName(e);
  const handlePhoneNumberChange = (value) => setPhoneNumber(value);
  const handleEmailChange = (value) => setEmail(value);
  const handleRelationshipChange = (value) => setRelationship(value);
  const handlePurposeChange = (e) => setPurpose(e);
  const handleAcknowledgmentOfPresenceChange = (e) =>
    setAcknowledgmentOfPresence(e.target.checked);
  const handleNeededInfosChange = (e) => setNeededInfos(e.target.checked);
  const handleClinicalInformationChange = (e) =>
    setClinicalInformation(e.target.checked);
  const handlePsychEvaluationChange = (e) =>
    setPsychEvaluation(e.target.checked);
  const handleReferralLetterChange = (e) => setReferralLetter(e.target.checked);
  const handleLabDataChange = (e) => setLabData(e.target.checked);
  const handleProgressNotesChange = (e) => setProgressNotes(e.target.checked);
  const handleMedicalRecordsChange = (e) => setMedicalRecords(e.target.checked);
  const handleProgressInTreatmentChange = (e) =>
    setProgressInTreatment(e.target.checked);
  const handlePsychosocialAssessmentChange = (e) =>
    setPsychosocialAssessment(e.target.checked);
  const handleMedicationInfoChange = (e) => setMedicationInfo(e.target.checked);
  const handleTreatmentPlanChange = (e) => setTreatmentPlan(e.target.checked);
  const handlePhysicalAssessmentChange = (e) =>
    setPhysicalAssessment(e.target.checked);
  const handleDischargeSummaryChange = (e) =>
    setDischargeSummary(e.target.checked);
  const handleFamilyHistoryChange = (e) => setFamilyHistory(e.target.checked);
  const handleDischargePlanChange = (e) => setDischargePlan(e.target.checked);
  const handleAdmissionDischargeDatesChange = (e) =>
    setAdmissionDischargeDates(e.target.checked);
  const handleOtherInfoChange = (e) => setOtherInfo(e.target.checked);
  const handleOtherInfoInputChange = (e) => setOtherInfoInput(e);
  const handleOtherRelationship = (e) => setOtherRelationship(e);

  //Tous les states des données récupérées par client

  const [isLoading, setIsLoading] = useState(false);

  const handleSignatureSave = (dataURL) => {
    setSignatureDataURL(dataURL);
  };

  const handleFormSubmit = async () => {
    if (
      !companyIndividualName ||
      !relationship ||
      !purpose ||
      !signatureDataURL ||
      !isSignatureValidated
    ) {
      showCustomToast("Please check mandatory fields.", "error");
    }

    try {
      setIsLoading(true);

      const roiDocRef = doc(db, "roi", props.clientId);
      const roiDocSnap = await getDoc(roiDocRef);

      let roiCollectionRef;
      if (roiDocSnap.exists()) {
        roiCollectionRef = collection(roiDocRef, "subcollection");
      } else {
        await setDoc(roiDocRef, {});
        roiCollectionRef = collection(roiDocRef, "subcollection");
      }

      const newDoc = await addDoc(roiCollectionRef, {
        companyIndividualName,
        phoneNumber,
        email,
        relationship,
        purpose,
        acknowledgmentOfPresence,
        clinicalInformation,
        psychEvaluation,
        referralLetter,
        labData,
        progressNotes,
        medicalRecords,
        progressInTreatment,
        psychosocialAssessment,
        medicationInfo,
        treatmentPlan,
        physicalAssessment,
        dischargeSummary,
        familyHistory,
        dischargePlan,
        admissionDischargeDates,
        otherInfo,
        otherInfoInput,
        otherRelationShip,
        revoked: false,
        signedOn: serverTimestamp(),
        staffName: currentUser.displayName,
        neededInfos,
      });

      const newDocId = newDoc.id;

      const fileName1 = `${clientId}-${newDocId}-client.png`;
      const storage1 = getStorage();
      const signatureRef1 = ref(storage1, `ReleaseOfInformations/${fileName1}`);
      await uploadString(signatureRef1, signatureDataURL, "data_url");
      const signatureURL1 = await getDownloadURL(signatureRef1);

      await updateDoc(newDoc, {
        signatureClientUrl: signatureURL1,
      });

      showCustomToast(
        "Release of information form correctly submitted!",
        "success"
      );

      setIsLoading(false);
      props.closemodal();
    } catch (e) {
      showCustomToast("Error adding document", "error");

      console.error("Error adding document: ", e);
    }
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        className="line1-modal-percent"
        style={{ borderBottom: "1px solid lightgray" }}
      >
        <TopTitleFormComponentMobileV2
          title="Auth. for R.O.I."
          closemodal={props.closemodal}
        />
      </div>

      <div
        style={{
          width: "100%",
          marginTop: "10px",
          height: "80%",
          overflowY: "scroll",
          // overflowX: "hidden",
        }}
      >
        <div
          className="SubTitleBold"
          style={{
            paddingLeft: "30px",
            fontSize: "14px",
            paddingRight: "20px",
          }}
        >
          This is to confirm that I, {props.firstName} {props.lastName} , hereby
          authorise our rehab to consult with the following individuals and
          organisations about my treatment. I give my consent to:
        </div>

        <div
          className="block-text-check-leftm"
          style={{ fontSize: "14px", paddingRight: "20px" }}
        >
          Records may be released to and obtained from the following individual
          or organization:
        </div>
        <div
          className="tab-form-container"
          style={{ paddingLeft: "15px", fontSize: "14px" }}
        >
          <InputComponent
            title="Company / Individual Name"
            isRequired={true}
            width="auto"
            value={companyIndividualName}
            onChange={handleCompanyIndividualNameChange}
          />

          <PhoneNumberInput
            title="Phone number"
            isRequired={true}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            placeholder="Important data if client has!"
          />
          <InputComponent
            title="Email"
            isRequired={true}
            width="200px"
            value={email}
            onChange={handleEmailChange}
            placeholder="Important data if client has!"
          />
          <DropDownComponent
            api="relationship"
            title="Relationship"
            value={relationship}
            onChange={handleRelationshipChange}
            isRequired={true}
          />

          {relationship === "Other" ? (
            <InputComponent
              title="Other relationship"
              width="200px"
              value={otherRelationShip}
              onChange={handleOtherRelationship}
            />
          ) : (
            ""
          )}

          <TextFieldComponentLargeMobile
            title="The purpose of releasing / obtaining these records is: "
            isRequired={true}
            value={purpose}
            onChange={handlePurposeChange}
            placeholder="Type, for example, Continuity of care"
          />
        </div>

        <div className="SubTitleBold4" style={{ fontSize: "14px" }}>
          The specific and relevant information that may be released / obtained
          is:
        </div>

        <div
          className="yes-no-container-column-mobile-v2"
          style={{ fontSize: "14px" }}
        >
          <div className="checkbox-container" style={{ fontSize: "14px" }}>
            <Checkbox
              checked={acknowledgmentOfPresence}
              onChange={handleAcknowledgmentOfPresenceChange}
            />
            Acknowledgment of Presence in Treatment
          </div>

          <div className="checkbox-container">
            <Checkbox
              checked={neededInfos}
              onChange={handleNeededInfosChange}
            />
            Needed information to ensure payment for treatment
          </div>

          <div className="checkbox-container">
            <Checkbox
              checked={clinicalInformation}
              onChange={handleClinicalInformationChange}
            />
            Clinical information for insurance
          </div>
          <div className="checkbox-container">
            <Checkbox
              checked={psychEvaluation}
              onChange={handlePsychEvaluationChange}
            />
            Psychiatric or psychological evaluation
          </div>
          <div className="checkbox-container">
            <Checkbox
              checked={referralLetter}
              onChange={handleReferralLetterChange}
            />
            Referral letter
          </div>
          <div className="checkbox-container">
            <Checkbox checked={labData} onChange={handleLabDataChange} />
            Lab data / drug screens
          </div>
          <div className="checkbox-container">
            <Checkbox
              checked={progressNotes}
              onChange={handleProgressNotesChange}
            />
            Progress notes
          </div>

          <div className="checkbox-container">
            <Checkbox
              checked={medicalRecords}
              onChange={handleMedicalRecordsChange}
            />
            Medical / hospital records
          </div>
          <div className="checkbox-container">
            <Checkbox
              checked={progressInTreatment}
              onChange={handleProgressInTreatmentChange}
            />
            Progress in treatment
          </div>
          <div className="checkbox-container">
            <Checkbox
              checked={psychosocialAssessment}
              onChange={handlePsychosocialAssessmentChange}
            />
            Psychosocial assessment
          </div>
          <div className="checkbox-container">
            <Checkbox
              checked={medicationInfo}
              onChange={handleMedicationInfoChange}
            />
            Medication information
          </div>
          <div className="checkbox-container">
            <Checkbox
              checked={treatmentPlan}
              onChange={handleTreatmentPlanChange}
            />
            Treatment plan
          </div>
          <div className="checkbox-container">
            <Checkbox
              checked={physicalAssessment}
              onChange={handlePhysicalAssessmentChange}
            />
            Physical Assessment
          </div>
          <div className="checkbox-container">
            <Checkbox
              checked={dischargeSummary}
              onChange={handleDischargeSummaryChange}
            />
            Discharge summary
          </div>
          <div className="checkbox-container">
            <Checkbox
              checked={familyHistory}
              onChange={handleFamilyHistoryChange}
            />
            Family/ Significant Other history
          </div>
          <div className="checkbox-container">
            <Checkbox
              checked={dischargePlan}
              onChange={handleDischargePlanChange}
            />
            Discharge plan
          </div>
          <div className="checkbox-container">
            <Checkbox
              checked={admissionDischargeDates}
              onChange={handleAdmissionDischargeDatesChange}
            />
            Admission and discharge dates
          </div>
          <div className="checkbox-container">
            <Checkbox checked={otherInfo} onChange={handleOtherInfoChange} />
            Other
            {otherInfo ? (
              <InputComponent
                value={otherInfoInput}
                onChange={handleOtherInfoInputChange}
              />
            ) : (
              ""
            )}
          </div>
        </div>

        <div
          className="SubTitleBold4"
          style={{ fontSize: "14px", marginTop: "10px" }}
        >
          This Consent of Release of Information is valid up to 90 days
          post-discharge.
        </div>

        <div className="SubTitleBold4" style={{ fontSize: "14px" }}>
          I understand that I can revoke this Consent of Release of Information
          at any time by informing our rehab’s staff.
        </div>

        <div style={{ width: "100%", marginBottom: "25px" }}>
          <SignaturePadComponentV2Mobile
            onSignatureSave={handleSignatureSave}
            signatureDataURL={signatureDataURL}
            onSignatureStart={() => setIsSignatureStarted(true)}
            toWho="Client signature"
            onSignatureValidate={setIsSignatureValidated}
          />
        </div>
      </div>
      <div className="line4-modal-percent-bis">
        <>
          <Button
            variant="contained"
            style={{ backgroundColor: "white", color: "black" }}
            // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
            onClick={props.closemodal}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleFormSubmit}
            style={
              isLoading
                ? {
                    backgroundColor: "red",
                    color: "white",
                    marginLeft: "15px",
                    fontSize: "14px",
                  }
                : !companyIndividualName ||
                  !phoneNumber ||
                  !email ||
                  !relationship ||
                  !purpose ||
                  !signatureDataURL ||
                  !isSignatureValidated
                ? // !isSignature1Validated || !isSignature2Validated
                  {
                    backgroundColor: "grey",
                    color: "white",
                    marginLeft: "15px",
                    fontSize: "14px",
                  }
                : {
                    backgroundColor: "#1565C0",
                    color: "white",
                    marginLeft: "15px",
                    fontSize: "14px",
                  }
            }
            disabled={
              !companyIndividualName ||
              !phoneNumber ||
              !email ||
              !relationship ||
              !purpose ||
              !signatureDataURL ||
              isLoading ||
              !isSignatureValidated
            }
          >
            {isLoading ? "SAVING..." : "SAVE"}
          </Button>
        </>
      </div>
    </div>
  );
}

export default AddReleaseOfInformationFormMobileV2;
