import React from "react";

function TitleMedium5Mobile() {
  return (
    <div className="title-line-medium-mobile">
      <div className="title-medium5-block1-mobile font-mobile">
        Google email
      </div>
      {/* <div className="title-medium5-block2 font-mobile">User Name</div> */}
      <div className="title-medium5-block1-mobile font-mobile2">User Role</div>
      {/* <div className="title-medium5-block3 font-mobile">Phone Number</div> */}
      <div className="title-medium5-block1-mobile font-mobile">Actions</div>
    </div>
  );
}

export default TitleMedium5Mobile;
