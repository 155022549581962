import React, { useState, useEffect } from "react";

export default function DateToday(props) {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const options = { weekday: "short" };
  const yearOfDate = time.getFullYear(time);
  const day = time.getDate();
  const monthInWord = time.toLocaleString("en-US", { month: "short" });
  const dayinString = time.toLocaleString("en-US", options).split(" ")[0];
  const hours = time.getHours();
  const minutes = time.getMinutes().toString().padStart(2, "0");
  const seconds = time.getSeconds().toString().padStart(2, "0");

  const fullDateFormated =
    dayinString +
    " " +
    day +
    " " +
    monthInWord +
    " " +
    yearOfDate +
    " " +
    hours +
    ":" +
    minutes +
    ":" +
    seconds;

  const fontSize = props.textSize === "small" ? "12px" : "14px";

  return (
    <div
      style={{
        fontSize,
        width: "150px",
        textAlign: "left",
        whiteSpace: "nowrap",
      }}
    >
      {fullDateFormated}
    </div>
  );
}
