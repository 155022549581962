import React, { useContext, useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { LanguageContext } from "../../context/LanguageContext";

function AlertLineMobileV2({
  typeOfAlert,
  notedBy,
  date,
  content,
  onEdit,
  onDelete,
}) {
  const { translate } = useContext(LanguageContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    handleMenuClose();
    onEdit();
  };

  const handleDeleteClick = () => {
    handleMenuClose();
    onDelete();
  };

  const getAlertClassName = () => {
    switch (typeOfAlert) {
      case 1:
        return "blue-alert";
      case 2:
        return "orange-alert";
      case 3:
        return "red-alert";
      default:
        return "";
    }
  };

  return (
    <div
      className="formLineVersion2"
      style={{ marginLeft: "0px", cursor: "pointer" }}
    >
      <div
        className={`formLineBlock1Version2 alertTextColor ${getAlertClassName()}`}
        style={{ width: "40%" }}
      >
        {typeOfAlert === 1 ? (
          <span
            style={{
              backgroundColor: "#007bff",
              padding: "2px 10px",
              color: "white",
              borderRadius: "20px",
              width: "100px",
              textAlign: "center",
              fontSize: "12px",
            }}
          >
            Reminder
          </span>
        ) : typeOfAlert === 2 ? (
          <span
            style={{
              backgroundColor: "#ffa500",
              padding: "2px 10px",
              color: "white",
              borderRadius: "20px",
              width: "100px",
              textAlign: "center",
              fontSize: "12px",
            }}
          >
            Allergy
          </span>
        ) : typeOfAlert === 3 ? (
          <span
            style={{
              backgroundColor: "#fd0b0b",
              padding: "2px 10px",
              color: "white",
              borderRadius: "20px",
              width: "100px",
              textAlign: "center",
              fontSize: "12px",
            }}
          >
            Alert
          </span>
        ) : null}
      </div>
      {/* <div className="formLineBlock2Version2 alertTextColor">{content}</div> */}
      <div
        className="formLineBlock3 alertTextColor"
        style={{ width: "30%", fontSize: "12px" }}
      >
        {date}
      </div>
      <div
        className="formLineBlock3Version2 alertTextColor"
        style={{ width: "20%", fontSize: "12px" }}
      >
        {notedBy}
      </div>
      <div
        className="formLineBlock3Version2v2 alertTextColor"
        style={{ width: "10px" }}
      >
        <IconButton aria-label="settings" onClick={handleMenuClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleEditClick}>{translate("Edit")}</MenuItem>
          <MenuItem onClick={handleDeleteClick}>
            <span style={{ color: "red" }}>{translate("Delete")}</span>{" "}
          </MenuItem>
        </Menu>
      </div>
      {/* <div className="formLineBlock3Version2v2 alertTextColor">
        <IconButton aria-label="settings" onClick={handleMenuClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleEditClick}>{translate("Edit")}</MenuItem>
          <MenuItem onClick={handleDeleteClick}>
            <span style={{ color: "red" }}>{translate("Delete")}</span>{" "}
          </MenuItem>
        </Menu>
      </div> */}
    </div>
  );
}

export default AlertLineMobileV2;
