import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DatePickerWithTimeComponent from "../DatePickerWithTimeComponent";
import { useAuth } from "../../contexts/AuthContext";
import { Timestamp, doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { showCustomToast } from "../Misc/CustomToast";
import DropDownSpecificStaff from "../Dropdowns/DropDownSpecificStaff";

function EditEndOfShiftNote(props) {
  const convertToDate = (timestamp) =>
    timestamp ? timestamp.toDate() : new Date();
  const { currentUser } = useAuth();

  const [note, setNote] = useState(props.noteContent);
  const [startTime, setStartTime] = useState(
    convertToDate(props.shiftStartRaw)
  );
  const [endTime, setEndTime] = useState(convertToDate(props.shiftEndRaw));
  const [selectedStaff, setSelectedStaff] = useState(props.staff);
  const [isLoading, setIsLoading] = useState(false);

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const handleStartTimeChange = (event) => {
    setStartTime(event);
  };

  const handleEndTimeChange = (event) => {
    setEndTime(event);
  };

  const handleStaffChange = (selectedStaff) => {
    setSelectedStaff(selectedStaff);
  };

  const handleUpdateEndOfShiftNote = async () => {
    const noteData = {
      noteContent: note,
      shiftStart: Timestamp.fromDate(new Date(startTime)),
      shiftEnd: Timestamp.fromDate(new Date(endTime)),
      staff: selectedStaff,
      updatedBy: currentUser.displayName,
      updateAt: Timestamp.now(),
    };

    setIsLoading(true);
    try {
      const docRef = doc(db, "notes", props.noteId); // Cibler la collection 'notes'
      await updateDoc(docRef, noteData);
      showCustomToast("End of Shift Note successfully updated!", "success");
      setIsLoading(false);
      props.closemodal();
    } catch (error) {
      console.error("Error updating End of Shift Note:", error);
      showCustomToast("Problem when updating End of Shift Note!", "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ width: "auto", height: "100%" }}>
      <div className="line1-modal-percent">
        <div className="modal-title">Edit End Of Shift Note</div>
        <CloseIcon
          style={{
            marginRight: "16px",
            color: "rgb(214, 213, 213)",
            cursor: "pointer",
          }}
          onClick={props.closemodal}
        />
      </div>

      <div
        style={{
          width: "100%",
          marginTop: "0px",
          height: "75%",
          overflowY: "scroll",
        }}
      >
        <div className="line2-column-modal">
          <div className="label-textfield">Note content</div>
          <TextField
            value={note}
            onChange={handleNoteChange}
            style={{
              width: "95%",
              borderRadius: "5px",
              backgroundColor: "#f2eeef",
            }}
            placeholder="Type your note here"
            multiline
            rows={12}
          />
        </div>
        <div className="line3-modal-mobile-bis">
          <DatePickerWithTimeComponent
            value={startTime}
            onChange={handleStartTimeChange}
            title="Start of Shift"
          />
          <DatePickerWithTimeComponent
            value={endTime}
            onChange={handleEndTimeChange}
            title="End of Shift"
          />
        </div>
        <div className="line3-modal-mobile-bis">
          <DropDownSpecificStaff
            value={selectedStaff}
            onChange={handleStaffChange}
            title="Select Staff"
          />
        </div>
      </div>

      <div className="line4-modal-percent">
        <Button
          onClick={props.closemodal}
          variant="contained"
          style={{
            backgroundColor: "white",
            color: "black",
            marginRight: "10px",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleUpdateEndOfShiftNote}
          disabled={isLoading}
          variant="contained"
          style={
            isLoading
              ? { backgroundColor: "grey" }
              : { backgroundColor: "#1565C0" }
          }
        >
          {isLoading ? "Updating..." : "Update Note"}
        </Button>
      </div>
    </div>
  );
}

export default EditEndOfShiftNote;
