export default [
  {
    id: 1,
    textDropDownEn: "--Choose--",
  },

  {
    id: 2,
    textDropDownEn: "Milk",
  },

  {
    id: 3,
    textDropDownEn: "Eggs",
  },

  {
    id: 4,
    textDropDownEn: "Peanuts",
  },

  {
    id: 5,
    textDropDownEn: "Tree Nuts",
  },

  {
    id: 6,
    textDropDownEn: "Fish",
  },

  {
    id: 7,
    textDropDownEn: "Crustaceans (Shellfish)",
  },

  {
    id: 8,
    textDropDownEn: "Wheat",
  },

  {
    id: 9,
    textDropDownEn: "Soy",
  },

  {
    id: 10,
    textDropDownEn: "Other",
  },
];
