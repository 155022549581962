import React from "react";
import "../css/dashboard.css";
import TopTitleBannerMobile from "../component/TopTitleBannerMobile";
import CasadaSwitchMobile from "../component/Misc/CasadaSwitchMobile";

export default function RoomLayoutContentMobile() {
  return (
    <div className="right-container-mobile">
      {/* <TopTitleBannerMobile title={"Rooms layout"} /> */}
      <CasadaSwitchMobile />
    </div>
  );
}
