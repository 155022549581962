import React, { useState } from "react";
import ViewButton from "../Buttons/ViewButton";
import CheckWithDate from "../CheckWithDate";
import { Box, Modal } from "@mui/material";
import ViewContinuatedTherapySupport from "../forms/ViewContinuatedTherapySupport";

function FormLineCTS(props) {
  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: "80%",
    borderRadius: 5,
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const [showViewModal, setShowViewModal] = useState(false);

  const openViewModal = () => {
    setShowViewModal(true);
  };

  const closeViewModal = () => {
    setShowViewModal(false);
  };

  return (
    <div
      className={
        props.color === "1"
          ? "formLine60 light-grey-form"
          : "formLine60 grey-form"
      }
    >
      <Modal
        open={showViewModal}
        onClose={closeViewModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <ViewContinuatedTherapySupport
            {...props}
            closemodal={closeViewModal}
          />
        </Box>
      </Modal>
      <div className="formLineBlock1">{props.title}</div>
      <div className="formLineBlock2">
        {props.signed === false || props.signed === undefined ? (
          <CheckWithDate checked={false} />
        ) : (
          <CheckWithDate checked={true} content={props.date} />
        )}
      </div>
      <div className="formLineBlock3">
        {props.viewButton ? (
          <ViewButton handleOpen={openViewModal} {...props} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default FormLineCTS;
