import React from "react";
import PeopleIcon from "@mui/icons-material/People";
import FlightIcon from "@mui/icons-material/Flight";
import EventIcon from "@mui/icons-material/Event";
import { Skeleton } from "@mui/material";

export default function MetricsWidgetV2Mobile(props) {
  const renderIcon = (iconName) => {
    switch (iconName) {
      case "People":
        return (
          <PeopleIcon
            style={{
              fontSize: "30px",
              marginRight: "0px",
              backgroundColor: "#f3f4f6",
              paddingLeft: "10px",
              paddingRight: "10px",
              borderRadius: "50px",
              height: "40px",
              width: "40px",
            }}
          />
        );
      case "Flight":
        return (
          <FlightIcon
            style={{
              fontSize: "30px",
              marginRight: "0px",
              backgroundColor: "#f3f4f6",
              paddingLeft: "10px",
              paddingRight: "10px",
              borderRadius: "50px",
              height: "40px",
              width: "40px",
            }}
          />
        );
      case "Event":
        return (
          <EventIcon
            style={{
              fontSize: "30px",
              marginRight: "0px",
              backgroundColor: "#f3f4f6",
              paddingLeft: "10px",
              paddingRight: "10px",
              borderRadius: "50px",
              height: "40px",
              width: "40px",
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      // className="metrics-widget-container-v2"
      style={{
        backgroundColor: props.backgroundColor,
        color: props.textColor,
        display: "flex",
        flexDirection: "row",

        // width: "100%",
        width: "100%",
        border: "1px solid lightgrey",
        borderRadius: "10px",
        paddingLeft: "5px",
        paddingRight: "5px",
        paddingTop: "2px",
        paddingBottom: "1px",
      }}
    >
      <div
        style={{
          fontSize: "16px",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          alignSelf: "center",
          marginRight: "10px",
        }}
      >
        {renderIcon(props.iconName)}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "auto",
            textAlign: "start",
            fontSize: "14px",
            fontWeight: "500",
            color: props.textColor2,
          }}
        >
          {props.title}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            alignSelf: "flex-start",
          }}
        >
          <div
            style={{
              width: "auto",
              display: "flex",
              alignItems: "center",
            }}
          >
            {props.isLoading ? (
              <Skeleton width={150} height={35} />
            ) : (
              <>
                <div
                  style={{
                    width: "20%",
                    textAlign: "start",
                    fontSize: "18px",
                    fontWeight: "500",
                    marginRight: "0px",
                  }}
                >
                  {props.value}
                </div>
                <div
                  style={{
                    width: "80%",
                    textAlign: "start",
                    fontSize: "18px",
                    // lineHeight: "24px",
                    fontWeight: "500",
                  }}
                >
                  {props.title2}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
