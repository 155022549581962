import React, { useState } from "react";
import { Box, Modal, Slide } from "@mui/material";
import ViewInformedConsent from "../forms/ViewInformedConsent";
import CheckWithDateMobileV2 from "../CheckWithDateMobileV2";
import ViewButtonMobileV2 from "../Buttons/ViewButtonMobileV2";
import AddButtonRoundMobileV4 from "../Buttons/AddButtonRoundMobileV4";
import ViewInformedConsentMobileV2 from "../forms/ViewInformedConsentMobileV2";

function FormLineICMobileV2(props) {
  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: "80%",
    borderRadius: 5,
    backgroundColor: "white",
    boxShadow: 24,
  };

  const [showViewModal2, setShowViewModal2] = useState(false);

  const openViewModal2 = () => {
    setShowViewModal2(true);
  };

  const closeViewModal2 = () => {
    setShowViewModal2(false);
  };

  console.log("showViewModal2 : ", showViewModal2);

  return (
    <div
      style={{
        marginLeft: "0px",
        display: "flex",
        flexDirection: "row",
        height: "30px",
        borderRadius: "30px",
        border: "1px solid lightgrey",
        width: "100%",
        marginTop: "5px",
        marginBottom: "5px",
        paddingLeft: "15px",
        paddingRight: "5px",
      }}
    >
      <Modal open={showViewModal2} onClose={closeViewModal2}>
        <Slide direction="left" in={showViewModal2}>
          <Box
            sx={{
              position: "fixed",
              top: "5%",
              right: 0,
              width: "90%",
              height: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              marginRight: "15px",
              pl: 0,
              pr: 0,
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ViewInformedConsentMobileV2
              {...props}
              closemodal={closeViewModal2}
            />
          </Box>
        </Slide>
      </Modal>

      <div
        style={{
          width: "50%",
          fontSize: "13px",
          fontWeight: "600",
          display: "flex",
          justifyContent: "flex-start",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {props.title}
      </div>
      <div
        style={{
          width: "35%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          // alignItems: "flex-end",
        }}
      >
        {props.signed ? (
          <CheckWithDateMobileV2 checked={true} content={props.date} />
        ) : (
          <CheckWithDateMobileV2 checked={false} content="Not signed" />
        )}
      </div>

      <div
        style={{
          width: "15%",
          display: "flex",
          justifyContent: "flex-end",
          alignContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        {props.signed ? (
          <ViewButtonMobileV2 handleOpen2={openViewModal2} {...props} />
        ) : (
          <AddButtonRoundMobileV4 handleOpen={props.handleOpen} />
        )}
      </div>
    </div>
  );
}

export default FormLineICMobileV2;
