import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Slide,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@mui/material";
import { LanguageContext } from "../../context/LanguageContext";
import DropDownComponentV2 from "../DropDownComponentV2"; // Assuming you're using this component
import { metricsDb } from "../../firebaseMetrics";
import { collection, onSnapshot, query } from "firebase/firestore";

const EditTicketModal = ({ open, onClose, ticket, handleUpdate, loading }) => {
  const { translate } = useContext(LanguageContext);
  const [ticketName, setTicketName] = useState(ticket?.ticketName);
  const [ticketType, setTicketType] = useState(ticket?.ticketType);
  const [originalQuestion, setOriginalQuestion] = useState(
    ticket?.originalQuestion
  );
  const [pageList, setPageList] = useState(ticket?.pageConcerned);
  const [selectedPriority, setSelectedPriority] = useState(ticket?.priority);
  const [activities, setActivities] = useState([]);

  const status = ticket?.status; // Define the ticket status

  console.log("ticketName: ", ticket?.ticketName);

  useEffect(() => {
    if (ticket) {
      setTicketName(ticket.ticketName);
      setTicketType(ticket.ticketType);
      setOriginalQuestion(ticket.originalQuestion);
      setPageList(ticket.pageConcerned);
      setSelectedPriority(ticket.priority);
    }
  }, [ticket]);

  useEffect(() => {
    if (ticket && ticket.id) {
      const activitiesRef = collection(
        metricsDb,
        "ticketsSupport-rehab-app",
        ticket.id,
        "activity"
      );
      const q = query(activitiesRef);

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const activityData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setActivities(activityData);
      });

      // Cleanup function to unsubscribe when the component unmounts or ticket.id changes
      return () => unsubscribe();
    } else {
      // If there's no valid ticket, reset the activities
      setActivities([]);
    }
  }, [ticket]);

  // Move this part outside the return block
  if (!ticket) {
    return null; // Fallback UI if the ticket doesn't exist
  }

  const TicketActivityHistory = () => {
    return (
      <div style={{ padding: "20px" }}>
        <div
          style={{ fontSize: "18px", fontWeight: "600", marginBottom: "10px" }}
        >
          Ticket Activity
        </div>
        <div style={{ position: "relative", paddingLeft: "20px" }}>
          {activities.length > 0 ? (
            activities.map((activity, index) => (
              <div
                key={activity.id}
                style={{
                  display: "flex",
                  marginBottom: "20px",
                  position: "relative",
                }}
              >
                {/* The vertical line */}
                {index !== activities.length - 1 && (
                  <div
                    style={{
                      position: "absolute",
                      left: "15px",
                      top: "30px",
                      height: "calc(100% + 10px)",
                      borderLeft: "1px dotted gray",
                      zIndex: 1,
                    }}
                  ></div>
                )}

                {/* Icon container */}
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    backgroundColor: "#f0f0f0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 2,
                    marginRight: "10px",
                    position: "relative",
                  }}
                >
                  {/* Placeholder for dynamic icons based on action type */}
                  {activity.action === "Ticket Created" ? "📝" : "🔄"}
                </div>

                {/* Activity details */}
                <div style={{ flex: 1, paddingLeft: "10px" }}>
                  <div style={{ fontWeight: "500" }}>{activity.action}</div>
                  <div style={{ fontSize: "12px", color: "gray" }}>
                    Performed by: {activity.performedBy} -{" "}
                    {new Date(
                      activity.timestamp.seconds * 1000
                    ).toLocaleString()}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>No activity yet.</div>
          )}
          <div
            style={{
              padding: "10px",
              textAlign: "center",
              cursor: "pointer",
              color: "#1a73e8",
              fontWeight: "500",
            }}
          >
            View more activity
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal open={open} onClose={onClose} closeAfterTransition>
      <Slide direction="left" in={open}>
        <Box
          sx={{
            position: "fixed",
            top: "5%",
            right: 0,
            width: "60%",
            height: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            marginRight: "15px",
            pl: "2",
            pr: "2",
            borderRadius: "15px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 0,
              borderBottom: "1px solid lightgray",
              height: "8%",
            }}
          >
            <div
              id="edit-ticket-modal-title"
              style={{
                paddingLeft: "30px",
                paddingTop: "10px",
                paddingBottom: "10px",
                fontSize: "22px",
                fontWeight: "700",
              }}
            >
              {translate("UpdateTicket")}
            </div>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              height: "84%",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "50%",
                flexDirection: "row",
                // height: "100%",
                paddingLeft: "20px",
              }}
            >
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    fontWeight: "600",
                    paddingBottom: "10px",
                    fontSize: "16px",
                    paddingTop: "30px",
                  }}
                >
                  {translate("TicketNumber")}
                </div>
                <div style={{ width: "20%" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={ticket.ticketNumber}
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{ marginBottom: "20px" }}
                  />
                </div>

                <div
                  style={{
                    fontWeight: "600",
                    paddingBottom: "10px",
                    fontSize: "16px",
                    paddingTop: "0px",
                  }}
                >
                  {translate("TicketName")}
                </div>
                <div style={{ width: "60%" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={ticketName}
                    onChange={(e) =>
                      status === "Not Seen Yet" && setTicketName(e.target.value)
                    }
                    style={{ marginBottom: "20px" }}
                    InputProps={{
                      readOnly: status !== "Not Seen Yet",
                    }}
                  />
                </div>

                <div
                  style={{
                    fontWeight: "600",
                    paddingBottom: "10px",
                    fontSize: "16px",
                    paddingTop: "10px",
                  }}
                >
                  {translate("WriteDescription")}
                </div>
                <div style={{ width: "80%" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={originalQuestion}
                    onChange={(e) =>
                      status === "Not Seen Yet" &&
                      setOriginalQuestion(e.target.value)
                    }
                    style={{ marginBottom: "20px" }}
                    InputProps={{
                      readOnly: status !== "Not Seen Yet",
                    }}
                    multiline
                    rows={5}
                  />
                </div>

                <div style={{ paddingTop: "0px" }}>
                  <div
                    style={{
                      fontWeight: "600",
                      paddingBottom: "10px",
                      fontSize: "16px",
                    }}
                  >
                    {translate("Priority")}
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div
                      className={`priority-option ${
                        selectedPriority === "Low" ? "selected" : ""
                      }`}
                      onClick={() =>
                        status === "Not Seen Yet" && setSelectedPriority("Low")
                      }
                      style={{
                        cursor:
                          status === "Not Seen Yet" ? "pointer" : "not-allowed",
                        opacity: status === "Not Seen Yet" ? 1 : 0.5,
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "green",
                          width: "15px",
                          height: "15px",
                          borderRadius: "10px",
                        }}
                      ></div>
                      <span style={{ paddingLeft: "10px" }}>
                        {translate("Low")}
                      </span>
                    </div>
                    <div
                      className={`priority-option ${
                        selectedPriority === "Medium" ? "selected" : ""
                      }`}
                      onClick={() =>
                        status === "Not Seen Yet" &&
                        setSelectedPriority("Medium")
                      }
                      style={{
                        cursor:
                          status === "Not Seen Yet" ? "pointer" : "not-allowed",
                        opacity: status === "Not Seen Yet" ? 1 : 0.5,
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "orange",
                          width: "15px",
                          height: "15px",
                          borderRadius: "10px",
                        }}
                      ></div>
                      <span style={{ paddingLeft: "10px" }}>
                        {translate("Medium")}
                      </span>
                    </div>
                    <div
                      className={`priority-option ${
                        selectedPriority === "High" ? "selected" : ""
                      }`}
                      onClick={() =>
                        status === "Not Seen Yet" && setSelectedPriority("High")
                      }
                      style={{
                        cursor:
                          status === "Not Seen Yet" ? "pointer" : "not-allowed",
                        opacity: status === "Not Seen Yet" ? 1 : 0.5,
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "red",
                          width: "15px",
                          height: "15px",
                          borderRadius: "10px",
                        }}
                      ></div>
                      <span style={{ paddingLeft: "10px" }}>
                        {translate("High")}
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    fontWeight: "600",
                    paddingBottom: "10px",
                    fontSize: "16px",
                    paddingTop: "10px",
                  }}
                >
                  {translate("TicketType")}
                </div>
                <DropDownComponentV2
                  api="ticketType"
                  value={ticketType}
                  onChange={(e) =>
                    status === "Not Seen Yet" && setTicketType(e)
                  }
                  width="250px"
                />

                <div
                  style={{
                    fontWeight: "600",
                    paddingBottom: "10px",
                    fontSize: "16px",
                    paddingTop: "10px",
                  }}
                >
                  {translate("PageConcerned")}
                </div>
                <DropDownComponentV2
                  api="pageList"
                  value={pageList}
                  onChange={(e) => status === "Not Seen Yet" && setPageList(e)}
                  width="250px"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                paddingLeft: "20px",
              }}
            >
              <div style={{ width: "100%" }}>
                <TicketActivityHistory />
              </div>
            </div>
          </div>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 0,
              paddingLeft: "30px",
              paddingRight: "30px",
              paddingBottom: "10px",
              paddingTop: "10px",
              borderTop: "1px solid lightgray",
              height: "8%",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              style={{ marginRight: "10px" }}
              onClick={onClose}
            >
              {translate("Cancel")}
            </Button>

            <Button
              onClick={() =>
                handleUpdate({
                  ticketName,
                  ticketType,
                  originalQuestion,
                  pageList,
                  selectedPriority,
                })
              }
              variant="contained"
              color="primary"
              disabled={loading || status !== "Not Seen Yet"}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                translate("UpdateTicket")
              )}
            </Button>
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

export default EditTicketModal;
