import React, { useContext } from "react";
import "../css/dashboard.css";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import DocumentsContentMobile from "./DocumentsContentMobile";
import NavbarV2 from "../component/NavbarV2";
import CrmContent from "./CrmContent";
import SupportContent from "./SupportContent";
import ProfileWidgetMobileNoRole from "../component/ProfileWidgetMobileNoRole";
import { LanguageContext } from "../context/LanguageContext";
import SupportContentMobile from "./SupportContentMobile";

export default function Support() {
  const { translate } = useContext(LanguageContext);
  const navigate = useNavigate();

  const handleMenuOpen = () => {
    navigate("/menumobile");
  };

  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  return (
    <div className="main-container">
      {isMobile ? (
        <>
          <div
            className="menu-button-container-mobile"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingRight: "0%",
            }}
          >
            <div
              style={{
                width: "30%",
                paddingLeft: "5%",
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuOpen}
                className="menu-button"
              >
                <MenuIcon />
              </IconButton>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "40%",
                alignItems: "center",
                alignContent: "center",
                alignSelf: "center",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              {translate("Support")}
            </div>
            <div
              style={{
                width: "30%",
                paddingTop: "0px",
                paddingBottom: "0px",
                display: "flex",
                justifyContent: "end",
                alignContent: "center",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* <Tooltip
                  title={translate("ClickHereForHelp")}
                  placement="bottom"
                >
                  <HelpOutlineIcon
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                      color: "#a9a9a9",
                    }}
                  />
                </Tooltip> */}
                {/* <Tooltip title={translate("Settings")} placement="bottom">
                  <SettingsIcon
                    onClick={handleSettings}
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                      color: "#a9a9a9",
                    }}
                  />
                </Tooltip> */}
              </div>
              <ProfileWidgetMobileNoRole />
            </div>
          </div>

          <SupportContentMobile />
        </>
      ) : (
        <>
          <NavbarV2 linkActive={7} />
          <SupportContent />
        </>
      )}
    </div>
  );
}
