export default [
  {
    id: 1,
    textDropDownEn: "--Choose--",
  },

  {
    id: 2,
    textDropDownEn: "30 days",
  },

  {
    id: 3,
    textDropDownEn: "60 days",
  },

  {
    id: 4,
    textDropDownEn: "90 days",
  },

  {
    id: 5,
    textDropDownEn: "120 days",
  },
];
