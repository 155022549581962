import React, { useEffect, useState } from "react";
import {
  collection,
  onSnapshot,
  doc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { db } from "../../firebase";

function DropDownRoomsNumber(props) {
  const [roomNumbers, setRoomNumbers] = useState([]);
  const [selectedRoomNumber, setSelectedRoomNumber] = useState(
    props.value || ""
  );
  const [isUpdating, setIsUpdating] = useState(false);

  // Synchroniser props.value avec l'état local
  useEffect(() => {
    setSelectedRoomNumber(props.value);
  }, [props.value]);

  const updateRoomOccupation = async (
    newRoomNumber,
    previousRoomNumber,
    clientStatus
  ) => {
    if (previousRoomNumber) {
      console.log(`Suppression de l'ancienne chambre : ${previousRoomNumber}`);
      const previousRoomDocRef = doc(db, "RoomNumbers", previousRoomNumber);
      await deleteDoc(previousRoomDocRef);
      console.log(`Chambre supprimée : ${previousRoomNumber}`);
    }

    if (newRoomNumber && clientStatus === "In House") {
      console.log(`Ajout de la nouvelle chambre : ${newRoomNumber}`);
      const newRoomDocRef = doc(db, "RoomNumbers", newRoomNumber);
      await setDoc(newRoomDocRef, {
        occupied: true,
        clientId: props.clientId,
        clientFirstName: props.clientFirstName,
        clientLastName: props.clientLastName,
        profilePictureUrl: props.profilePictureUrl, // Ajoutez ces informations si nécessaires
      });
      console.log(`Chambre ajoutée : ${newRoomNumber}`);
    }
  };

  // Fonction de gestion du changement de numéro de chambre
  const handleChange = async (event) => {
    const newRoomNumber = event.target.value;
    console.log(`Chambre sélectionnée : ${newRoomNumber}`);
    setIsUpdating(true); // Démarrer le chargement
    await updateRoomOccupation(
      newRoomNumber,
      selectedRoomNumber,
      props.clientStatus
    );
    setSelectedRoomNumber(newRoomNumber); // Mettre à jour l'état local
    props.onChange(newRoomNumber);
    setIsUpdating(false); // Terminer le chargement
  };

  useEffect(() => {
    const predefinedRooms = [
      "A1",
      "A2",
      "A3",
      "A4",
      "A5",
      "B1",
      "B2",
      "B3",
      "B4",
      "B5",

      // "Room 1",
      // "Room 2",
      // "Room 3",
    ];

    const roomCollection = collection(db, "RoomNumbers");

    const unsubscribe = onSnapshot(roomCollection, (snapshot) => {
      const occupiedRooms = snapshot.docs.map((doc) => doc.id);
      console.log("Chambres occupées :", occupiedRooms);
      const availableRooms = predefinedRooms.filter(
        (room) => !occupiedRooms.includes(room)
      );
      console.log("Chambres disponibles :", availableRooms);
      setRoomNumbers(availableRooms);
    });

    return () => unsubscribe();
  }, []);

  const renderOptions = () => {
    return (
      <>
        <option value="">--Choose a room number--</option>
        {roomNumbers.map((room) => (
          <option key={room} value={room}>
            {room}
          </option>
        ))}
        {/* Affiche l'option de la chambre sélectionnée si elle est occupée */}
        {!roomNumbers.includes(selectedRoomNumber) && selectedRoomNumber && (
          <option value={selectedRoomNumber}>
            {selectedRoomNumber} (Occupied)
          </option>
        )}
      </>
    );
  };

  return (
    <div
      className="input-label-container"
      style={{ display: "flex", alignItems: "center" }}
    >
      <div className="input-label-dropdown">
        {props.title}{" "}
        {props.isRequired && <span style={{ color: "red" }}>*</span>}
      </div>
      <TextField
        className="dropdown-select"
        SelectProps={{
          native: true,
          style: { height: 40 },
        }}
        select
        value={selectedRoomNumber} // Utilisez l'état local ici
        onChange={handleChange}
        style={{ width: props.width || "auto", marginRight: 8 }}
      >
        {renderOptions()}
      </TextField>
      {isUpdating && <CircularProgress size={24} />}{" "}
      {/* Affiche le spinner pendant la mise à jour */}
    </div>
  );
}

export default DropDownRoomsNumber;
