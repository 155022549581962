import React, { useContext, useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Tooltip from "@mui/material/Tooltip";
import { LanguageContext } from "../../context/LanguageContext";

function TitleNoteCompactMobile(props) {
  const { translate, language, setLanguage } = useContext(LanguageContext);
  const [dateSortOrder, setDateSortOrder] = useState("asc");
  const [clientSortOrder, setClientSortOrder] = useState("asc");
  const [typeSortOrder, setTypeSortOrder] = useState("asc");
  const handleDateSort = () => {
    const newOrder = dateSortOrder === "asc" ? "desc" : "asc";
    setDateSortOrder(newOrder);
    props.handleSort(
      `date${newOrder.charAt(0).toUpperCase() + newOrder.slice(1)}`
    );
  };

  const handleClientSort = () => {
    const newOrder = clientSortOrder === "asc" ? "desc" : "asc";
    setClientSortOrder(newOrder);
    props.handleSort(
      `client${newOrder.charAt(0).toUpperCase() + newOrder.slice(1)}`
    );
  };

  const handleTypeSort = () => {
    const newOrder = typeSortOrder === "asc" ? "desc" : "asc";
    setTypeSortOrder(newOrder);
    props.handleSort(
      `type${newOrder.charAt(0).toUpperCase() + newOrder.slice(1)}`
    );
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "25px",
          display: "flex",
          flexDirection: "row",
          borderBottom: "1px solid lightgrey",
          cursor: "pointer",
          transition: "background-color 0.3s ease",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <div
            style={{
              width: "30%",
              height: "25px",
              display: "flex",
              flexDirection: "row",
              color: "#a3a3a4",
              fontWeight: "500",
              // textAlign: "left",
              justifyContent: "center",
              alignContent: "center",
              alignSelf: "center",
              alignItems: "center",
            }}
          >
            {translate("DateC")}
          </div>

          <div
            style={{
              width: "40%",
              height: "25px",
              display: "flex",
              color: "#a3a3a4",
              fontWeight: "500",
              textAlign: "center",
              justifyContent: "center",
              paddingLeft: "0px",
            }}
          >
            <div
              style={{
                paddingRight: "0px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {translate("ClientC")}
            </div>
          </div>

          <div
            style={{
              width: "30%",
              height: "25px",
              display: "flex",
              color: "#a3a3a4",
              fontWeight: "500",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            {translate("Type")}
          </div>
        </div>
      </div>
    </>
  );
}

export default TitleNoteCompactMobile;
