import React, { useEffect } from "react";
import "../css/SignCss.css";
import logo from "../images/logo-miraclesehr.png";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-tippy/dist/tippy.css";

export default function Signup() {
  const navigate = useNavigate();
  const { signInWithGoogle } = useAuth();

  const { currentUser } = useAuth();

  function handleSignInWithGoogle() {
    signInWithGoogle()
      .then((result) => {})
      .then(() => navigate("/"))
      .catch((error) => {});
  }

  useEffect(() => {
    if (currentUser) {
    }
  }, [currentUser]);

  return (
    <div className="sign-main-container">
      <div className="logo">
        <img src={logo} alt="" />
      </div>
      <div className="sign-left-container">
        <div className="alert-container-top"></div>

        <div>
          <button onClick={handleSignInWithGoogle}>
            Se connecter avec Google
          </button>
        </div>
      </div>

      <div className="sign-right-container"></div>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}
