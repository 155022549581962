import React, { useEffect, useState } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import TextField from "@mui/material/TextField";

function DropDownSpecificStaff(props) {
  const [selectedStaff, setSelectedStaff] = useState(props.value);
  const [allStaff, setAllStaff] = useState([]);

  useEffect(() => {
    const roleCollection = collection(db, "roles");
    const unsubscribe = onSnapshot(roleCollection, (querySnapshot) => {
      setAllStaff(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleChange = (event) => {
    props.onChange(event.target.value);
  };

  const renderOptions = () => {
    // Filtrer les rôles selon les rôles autorisés passés en props
    const filteredStaff = props.allowedRoles
      ? allStaff.filter((staff) => props.allowedRoles.includes(staff.role))
      : allStaff;

    filteredStaff.sort((a, b) => a.displayName.localeCompare(b.displayName));

    return (
      <>
        <option value="">--Choose--</option>
        {filteredStaff.map((staff) => (
          <option key={staff.id} value={staff.displayName}>
            {`${staff.displayName} - ${staff.role}`}
            {/* {staff.displayName} */}
          </option>
        ))}
      </>
    );
  };

  return (
    <div className="input-label-container">
      <div className="input-label-dropdown">
        {props.title}{" "}
        {props.isRequired && <span style={{ color: "red" }}>*</span>}
      </div>
      <TextField
        className="dropdown-select"
        SelectProps={{
          native: true,
          style: { height: 40 },
        }}
        select
        value={props.value}
        onChange={handleChange}
      >
        {renderOptions()}
      </TextField>
    </div>
  );
}

export default DropDownSpecificStaff;
