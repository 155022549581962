import React, { useEffect, useState } from "react";
import EndOfShiftNoteContainer from "../Notes/EndOfShiftNoteContainer";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { CircularProgress } from "@mui/material";

function EndOfShiftNotesContainer(props) {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);

  const formatDate = (date, options) => {
    return date
      ? new Intl.DateTimeFormat("en-EN", options).format(date.toDate())
      : "";
  };

  useEffect(() => {
    const notesRef = collection(db, "notes");
    const q = query(
      notesRef,
      where("noteType", "==", "endOfShift"),
      orderBy("createdAt", "desc"),
      limit(50)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const notesData = [];
      querySnapshot.forEach((doc) => {
        notesData.push({ id: doc.id, ...doc.data() });
      });
      setLoading(false);
      setNotes(notesData);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="notes-container-global">
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
          <p>Loading, please wait...</p>
        </div>
      ) : (
        notes.map((note) => {
          const dateOptions = {
            day: "numeric",
            month: "short",
            year: "numeric",
          };
          const timeOptions = { hour: "2-digit", minute: "2-digit" };

          const noteDate = formatDate(note.shiftStart, dateOptions);
          const noteTime = formatDate(note.shiftStart, timeOptions); // Assurez-vous que c'est bien le champ que vous voulez utiliser
          const noteDateEnd = formatDate(note.shiftEnd, dateOptions);
          const noteDateEndTime = formatDate(note.shiftEnd, timeOptions);

          return (
            <EndOfShiftNoteContainer
              key={note.id}
              noteContent={note.noteContent}
              shiftStartRaw={note.shiftStart}
              shiftEndRaw={note.shiftEnd}
              shiftStart={noteDate}
              shiftEnd={noteDateEnd}
              staff={note.staff}
              doneBy={note.doneBy}
              noteId={note.id}
              noteType={note.noteType}
              noteDate={noteDate} // Date formatée
              noteTime={noteTime} // Heure formatée
              noteDateEndTime={noteDateEndTime}
            />
          );
        })
      )}
    </div>
  );
}

export default EndOfShiftNotesContainer;
