import React, { useContext } from "react";
import "../css/dashboard.css";
import TopTitleBannerV2 from "../component/TopTitleBannerV2";
import { LanguageContext } from "../context/LanguageContext";
import UnderConstruction from "../component/Misc/UnderConstruction";
import Kanban from "../component/Misc/Kaban";
import KabanMobileV2 from "../component/Misc/KabanMobileV2";

export default function CrmContentMobileV2() {
  const { translate } = useContext(LanguageContext);
  return (
    <div className="right-container-mobile">
      {/* <TopTitleBannerV2 title={translate("CRM")} /> */}
      <KabanMobileV2 />
    </div>
  );
}
