import React, { useContext, useEffect, useState } from "react";
import {
  collection,
  query,
  orderBy,
  limit,
  onSnapshot,
  addDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";

import {
  Autocomplete,
  TextField,
  IconButton,
  CircularProgress,
  Button,
  Menu,
  MenuItem,
  Skeleton,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { styled, alpha } from "@mui/material/styles";
import NotesContainerCompact from "../Notes/NotesContainerCompact";
import TitleNoteCompact from "../Notes/TitleNoteCompact";
import FlipNumbers from "react-flip-numbers";
import NoteIcon from "@mui/icons-material/Note";
import { Add } from "@mui/icons-material";
import NotesAddModal from "./NotesAddModal";
import { LanguageContext } from "../../context/LanguageContext";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function AllNotesPerClientContainer({ userId, ...props }) {
  const { translate } = useContext(LanguageContext);
  const [notes, setNotes] = useState([]);
  const [readCount, setReadCount] = useState(0);
  const [noteTypes, setNoteTypes] = useState([]);
  const [selectedNoteType, setSelectedNoteType] = useState([]);
  const [selectedClientName, setSelectedClientName] = useState("");
  const [clientNames, setClientNames] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [visibleNotesCount, setVisibleNotesCount] = useState(7);
  const handleShowMoreNotes = () => {
    setVisibleNotesCount((prevCount) => prevCount + 10);
  };
  const handleShowLessNotes = () => {
    setVisibleNotesCount((prevCount) => Math.max(prevCount - 10, 7));
  };
  const [openAddNoteModal, setOpenAddNoteModal] = useState(false);
  const [selectedNoteTypeToAdd, setSelectedNoteTypeToAdd] = useState("");
  const handleOpenAddNoteModal = () => setOpenAddNoteModal(true);
  const handleCloseAddNoteModal = () => setOpenAddNoteModal(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [currentNoteType, setCurrentNoteType] = useState("");
  const handleOpenAddModal = (noteType) => {
    setCurrentNoteType(noteType);
    setOpenAddModal(true);
  };
  const [anchorElNoteType, setAnchorElNoteType] = useState(null);
  const openNoteTypeMenu = Boolean(anchorElNoteType);

  const handleOpenNoteTypeMenu = (event) => {
    setAnchorElNoteType(event.currentTarget);
  };

  const handleCloseNoteTypeMenu = () => {
    setAnchorElNoteType(null);
  };

  const handleSelectNoteType = (noteType) => {
    setCurrentNoteType(noteType);
    setOpenAddModal(true);
    handleCloseNoteTypeMenu();
  };

  const handleCloseAddModal = () => {
    console.log("close button clicked");
    setOpenAddModal(false);
  };

  const handleSort = (order) => {
    let sortedNotes = [...notes];
    switch (order) {
      case "dateAsc":
        sortedNotes.sort(
          (a, b) => a.effectiveDateTime.toDate() - b.effectiveDateTime.toDate()
        );
        break;
      case "dateDesc":
        sortedNotes.sort(
          (a, b) => b.effectiveDateTime.toDate() - a.effectiveDateTime.toDate()
        );
        break;
      case "clientAsc":
        sortedNotes.sort((a, b) =>
          (a.clientFirstName + " " + a.clientLastName).localeCompare(
            b.clientFirstName + " " + b.clientLastName
          )
        );
        break;
      case "clientDesc":
        sortedNotes.sort((a, b) =>
          (b.clientFirstName + " " + b.clientLastName).localeCompare(
            a.clientFirstName + " " + a.clientLastName
          )
        );
        break;
      case "typeAsc":
        sortedNotes.sort((a, b) => a.noteType.localeCompare(b.noteType));
        break;
      case "typeDesc":
        sortedNotes.sort((a, b) => b.noteType.localeCompare(a.noteType));
        break;
      default:
        break;
    }
    setNotes(sortedNotes);
  };

  useEffect(() => {
    const notesRef = collection(db, "notes");
    // const q = query(notesRef, orderBy("effectiveDateTime", "desc"), limit(30));
    // const q = query(
    //   notesRef,
    //   where("clientId", "==", userId), // Filtrer par l'ID du client
    //   orderBy("effectiveDateTime", "desc"),
    //   limit(30)
    // );

    const q = query(
      notesRef,
      orderBy("effectiveDateTime", "desc"),
      where("noteType", "in", [
        "quickNote",
        "therapyNote",
        "supportStaffNote",
        "medicalNote",
        "aftercareNote",
        "familyNote",
      ]),
      where("clientId", "==", userId), // Filtrer par clientId
      limit(30)
    );

    console.log("userId:", userId);

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const notesData = [];
      const types = new Set();
      querySnapshot.forEach((doc) => {
        const noteData = { id: doc.id, ...doc.data() };
        notesData.push(noteData);
        types.add(noteData.noteType);
      });

      setNotes(notesData);
      setNoteTypes(Array.from(types));
      setReadCount((prevCount) => prevCount + querySnapshot.size);
      setIsLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, [userId]);

  const handleStartDateChange = (newValue) => {
    if (endDate && newValue > endDate) {
      setEndDate(newValue);
    }
    setStartDate(newValue);
  };

  const handleEndDateChange = (newValue) => {
    if (startDate && newValue < startDate) {
      setStartDate(newValue);
    }
    setEndDate(newValue);
  };

  const resetDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const resetFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedNoteType([]);
    setSelectedClientName("");
  };

  useEffect(() => {
    if (selectedNoteType.length > 0) {
      const filteredNotesByType = notes.filter((note) =>
        selectedNoteType.includes(note.noteType)
      );
      const uniqueClientNames = Array.from(
        new Set(
          filteredNotesByType.map(
            (note) => note.clientFirstName + " " + note.clientLastName
          )
        )
      );
      setClientNames(uniqueClientNames);
    } else {
      const allClientNames = Array.from(
        new Set(
          notes.map((note) => note.clientFirstName + " " + note.clientLastName)
        )
      );
      setClientNames(allClientNames);
    }
  }, [selectedNoteType, notes]);

  const filteredNotes = notes.filter((note) => {
    const matchesType = selectedNoteType.length
      ? selectedNoteType.includes(note.noteType)
      : true;
    const matchesClient = selectedClientName
      ? note.clientFirstName + " " + note.clientLastName === selectedClientName
      : true;
    const matchesDate =
      startDate && endDate
        ? note.effectiveDateTime &&
          note.effectiveDateTime.toDate() >= startDate &&
          note.effectiveDateTime.toDate() <= endDate
        : true;
    return matchesType && matchesClient && matchesDate;
  });

  const formatDate = (date, options) => {
    return date
      ? new Intl.DateTimeFormat("en-EN", options).format(date.toDate())
      : "";
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // const handleSaveNote = async (
  //   noteType,
  //   noteContent,
  //   effectiveDateTime,
  //   files,
  //   fileUrls,
  //   selectedClient,
  //   shiftStart, // Ajoutez ce paramètre
  //   shiftEnd, // Ajoutez ce paramètre
  //   staff,
  //   doneBy, // Add this parameter
  //   updateBy
  // ) => {
  //   try {
  //     const newNote = {
  //       noteType,
  //       noteContent,
  //       effectiveDateTime,
  //       files,
  //       fileUrls,
  //       createdAt: new Date(),
  //       updatedAt: new Date(),
  //       doneBy, // Save the creator's name
  //       updateBy,
  //     };

  //     // Ajouter les informations du client si le type de note le nécessite
  //     if (noteType === "endOfShift") {
  //       newNote.shiftStart = shiftStart;
  //       newNote.shiftEnd = shiftEnd;
  //       newNote.staff = staff;
  //     }

  //     if (
  //       [
  //         "aftercareNote",
  //         "medicalNote",
  //         "familyNote",
  //         "quickNote",
  //         "supportStaffNote",
  //         "therapyNote",
  //       ].includes(noteType)
  //     ) {
  //       newNote.clientId = selectedClient?.id || null;
  //       newNote.clientFirstName = selectedClient?.firstName || "";
  //       newNote.clientLastName = selectedClient?.lastName || "";
  //       newNote.clientProfilePicture = selectedClient?.profilePicture || "";
  //     }

  //     await addDoc(collection(db, "notes"), newNote);

  //     console.log("Note saved:", newNote);
  //     handleCloseAddModal();
  //   } catch (error) {
  //     console.error("Error saving note:", error);
  //   }
  // };
  const handleSaveNote = async (
    noteType,
    noteContent,
    effectiveDateTime,
    files,
    fileUrls,
    selectedClient,
    shiftStart,
    shiftEnd,
    staff,
    doneBy,
    updateBy,
    generalAnnouncement,
    groupTopic,
    groupType, // Type de groupe
    notInAttendance,
    immigrationAppointment,
    dischargeSwot,
    newClients,
    medicalInfos,
    hospitalAppointment,
    residentialClientMovements,
    zoomSessionAssistance,
    staffToday,
    overnight,
    numberOfClient,
    swot
  ) => {
    try {
      const newNote = {
        noteType,
        noteContent,
        effectiveDateTime,
        files,
        fileUrls,
        createdAt: new Date(),
        updatedAt: new Date(),
        doneBy,
        updateBy,
      };

      // Ajouter les informations spécifiques pour le type de note handoverNote
      if (noteType === "handoverNote") {
        newNote.generalAnnouncement = generalAnnouncement;
        newNote.immigrationAppointment = immigrationAppointment;
        newNote.dischargeSwot = dischargeSwot;
        newNote.newClients = newClients;
        newNote.medicalInfos = medicalInfos;
        newNote.hospitalAppointment = hospitalAppointment;
        newNote.residentialClientMovements = residentialClientMovements;
        newNote.zoomSessionAssistance = zoomSessionAssistance;
        newNote.staffToday = staffToday;
        newNote.overnight = overnight;
        newNote.numberOfClient = numberOfClient;
        newNote.swot = swot;
      }

      // Ajouter les informations spécifiques pour le type de note groupNote
      if (noteType === "groupNote") {
        newNote.groupTopic = groupTopic;
        newNote.groupType = groupType;
        newNote.notInAttendance = notInAttendance;
        // Ajoutez d'autres champs spécifiques ici si nécessaire
      }

      // Ajouter les informations du client si le type de note le nécessite
      if (
        [
          "aftercareNote",
          "medicalNote",
          "familyNote",
          "quickNote",
          "supportStaffNote",
          "therapyNote",
        ].includes(noteType)
      ) {
        newNote.clientId = selectedClient?.id || null;
        newNote.clientFirstName = selectedClient?.firstName || "";
        newNote.clientLastName = selectedClient?.lastName || "";
        newNote.clientProfilePicture = selectedClient?.profilePicture || "";
      }

      // Ajouter les informations spécifiques pour le type de note endOfShift
      if (noteType === "endOfShift") {
        newNote.shiftStart = shiftStart;
        newNote.shiftEnd = shiftEnd;
        newNote.staff = staff;
      }

      await addDoc(collection(db, "notes"), newNote);

      console.log("Note saved:", newNote);
      handleCloseAddModal();
    } catch (error) {
      console.error("Error saving note:", error);
    }
  };

  const fixedNoteTypes = [
    "aftercareNote",
    "medicalNote",
    "quickNote",
    "familyNote",
    "supportStaffNote",
    "therapyNote",
    "handoverNote",
    "groupNote",
    "endOfShift",
  ];

  const formatNoteType = (noteType) => {
    return noteType
      .replace(/([A-Z])/g, " $1") // Ajouter un espace avant chaque lettre majuscule
      .replace(/^./, (str) => str.toUpperCase()); // Mettre la première lettre en majuscule
  };

  return (
    <div className="notes-container-global">
      <NotesAddModal
        open={openAddModal}
        onClose={() => setOpenAddModal(false)}
        // onClose={handleCloseAddNoteModal}
        noteType={currentNoteType}
        handleSave={handleSaveNote}
      />

      {isLoading ? (
        <div style={{ width: "100%" }}>
          <div style={{ marginBottom: "20px", width: "100%" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ paddingTop: "5px" }}>
                  <NoteIcon
                    style={{
                      marginRight: "8px",
                      color: "#a3a3a4",
                      fontSize: "10px",
                    }}
                  />
                </div>
                <div
                  style={{
                    fontWeight: "650",
                    fontSize: "22px",
                    paddingRight: "5px",
                  }}
                >
                  <FlipNumbers
                    height={30}
                    width={15}
                    color="#000000"
                    background="white"
                    play
                    perspective={1000}
                    numbers={filteredNotes.length.toString()}
                  />
                </div>
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#a3a3a4",
                    lineHeight: "14px",
                    paddingTop: "2px",
                    paddingLeft: "5px",
                  }}
                >
                  {`note${filteredNotes.length > 1 ? "s" : ""}`}
                </div>
              </div>
              <Button
                id="filters-button"
                aria-controls={openMenu ? "filters-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? "true" : undefined}
                variant="outlined"
                color="primary"
                disableElevation
                onClick={handleMenuClick}
                endIcon={<FilterListIcon />}
              >
                {translate("Filters")}
              </Button>
            </div>

            <StyledMenu
              id="filters-menu"
              MenuListProps={{
                "aria-labelledby": "filters-button",
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
            >
              <MenuItem>
                <Autocomplete
                  multiple
                  options={noteTypes}
                  getOptionLabel={(option) => option}
                  style={{ width: 300, marginBottom: 20 }}
                  value={selectedNoteType}
                  clearOnEscape
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translate("SelectNoteType")}
                      variant="outlined"
                    />
                  )}
                  onChange={(event, value) => setSelectedNoteType(value || "")}
                />
              </MenuItem>
              {/* <MenuItem>
                <Autocomplete
                  options={clientNames}
                  getOptionLabel={(option) => option}
                  style={{ width: 300, marginBottom: 20 }}
                  value={selectedClientName}
                  clearOnEscape
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Client Name"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, value) =>
                    setSelectedClientName(value || "")
                  }
                />
              </MenuItem> */}
              <MenuItem>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={translate("StartDate")}
                    value={startDate}
                    onChange={handleStartDateChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        style={{
                          width: 300,
                          marginBottom: 20,
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </MenuItem>
              <MenuItem>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={translate("EndDate")}
                    value={endDate}
                    onChange={handleEndDateChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        style={{
                          width: 300,
                          marginBottom: 20,
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </MenuItem>
              <MenuItem>
                <IconButton onClick={resetFilters}>
                  <DeleteIcon /> Reset filters
                </IconButton>
              </MenuItem>
            </StyledMenu>
          </div>

          <div style={{ width: "100%" }}>
            <TitleNoteCompact handleSort={handleSort} />
          </div>

          {Array.from({ length: 10 }).map((_, index) => (
            <div key={index} style={{ paddingLeft: "25px", width: "100%" }}>
              <div
                style={{
                  // width: "90%",
                  width: "1300px",
                  cursor: "pointer",
                  marginBottom: "0px",
                  borderBottom: "1px solid lightgrey",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "5px",
                  paddingLeft: "0px",
                  color: "grey",
                  fontSize: "14px",
                  fontWeight: "450",
                  height: "80px",
                  backgroundColor: "white",
                }}
              >
                <Skeleton
                  variant="rectangular"
                  width={50}
                  height={50}
                  style={{ marginRight: "10px", borderRadius: "8px" }}
                />
                <Skeleton
                  variant="text"
                  width="10%"
                  height={30}
                  style={{ marginLeft: "20px" }}
                />
                <Skeleton
                  variant="text"
                  width="20%"
                  height={30}
                  style={{ marginLeft: "30px" }}
                />
                <Skeleton
                  variant="text"
                  width="50%"
                  height={30}
                  style={{ marginLeft: "10px" }}
                />
                <Skeleton
                  variant="text"
                  width="15%"
                  height={30}
                  style={{ marginLeft: "10px" }}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div style={{ width: "1300px" }}>
          <div style={{ marginBottom: "20px" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ paddingTop: "5px", paddingLeft: "20px" }}>
                  <NoteIcon
                    style={{
                      marginRight: "8px",
                      color: "#a3a3a4",
                      fontSize: "20px",
                    }}
                  />
                </div>
                <div
                  style={{
                    fontWeight: "650",
                    fontSize: "22px",
                    paddingRight: "5px",
                  }}
                >
                  <FlipNumbers
                    height={30}
                    width={15}
                    color="#000000"
                    background="white"
                    play
                    perspective={1000}
                    numbers={filteredNotes.length.toString()}
                  />
                </div>
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#a3a3a4",
                    lineHeight: "14px",
                    paddingTop: "2px",
                    paddingLeft: "5px",
                  }}
                >
                  {`note${filteredNotes.length > 1 ? "s" : ""}`}
                </div>
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleOpenAddModal("quickNote")} // Vous pouvez changer "quickNote" par le type de note souhaité
                  startIcon={<Add />}
                  style={{ marginRight: "10px" }}
                >
                  Add Note
                </Button> */}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleOpenNoteTypeMenu}
                  startIcon={<Add />}
                  style={{ marginRight: "10px" }}
                >
                  {translate("AddNote")}
                </Button>

                {/* <Menu
                  anchorEl={anchorElNoteType}
                  open={openNoteTypeMenu}
                  onClose={handleCloseNoteTypeMenu}
                >
                  {noteTypes.map((noteType) => (
                    <MenuItem
                      key={noteType}
                      onClick={() => handleSelectNoteType(noteType)}
                    >
                      {noteType}
                    </MenuItem>
                  ))}
                </Menu> */}

                {/* <Menu
                  anchorEl={anchorElNoteType}
                  open={openNoteTypeMenu}
                  onClose={handleCloseNoteTypeMenu}
                >
                  
                  {fixedNoteTypes.map((noteType) => (
                    <MenuItem
                      key={noteType}
                      onClick={() => handleSelectNoteType(noteType)}
                    >
                      {formatNoteType(noteType)}
                    </MenuItem>
                  ))}
                </Menu> */}

                <Menu
                  anchorEl={anchorElNoteType}
                  open={openNoteTypeMenu}
                  onClose={handleCloseNoteTypeMenu}
                >
                  {[
                    "quickNote",
                    "therapyNote",
                    "supportStaffNote",
                    "medicalNote",
                    "aftercareNote",
                    "familyNote",
                  ].map((noteType) => (
                    <MenuItem
                      key={noteType}
                      onClick={() => handleSelectNoteType(noteType)}
                    >
                      {formatNoteType(noteType)}
                    </MenuItem>
                  ))}
                </Menu>

                <Button
                  id="filters-button"
                  aria-controls={openMenu ? "filters-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? "true" : undefined}
                  variant="outlined"
                  color="primary"
                  disableElevation
                  onClick={handleMenuClick}
                  endIcon={<FilterListIcon />}
                >
                  {translate("Filters")}
                </Button>
              </div>
            </div>

            <StyledMenu
              id="filters-menu"
              MenuListProps={{
                "aria-labelledby": "filters-button",
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
            >
              <MenuItem>
                <Autocomplete
                  multiple
                  options={noteTypes}
                  getOptionLabel={(option) => option}
                  style={{ width: 300, marginBottom: 20 }}
                  value={selectedNoteType}
                  clearOnEscape
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translate("SelectNoteType")}
                      variant="outlined"
                    />
                  )}
                  onChange={(event, value) => setSelectedNoteType(value || "")}
                />
              </MenuItem>
              {/* <MenuItem>
                <Autocomplete
                  options={clientNames}
                  getOptionLabel={(option) => option}
                  style={{ width: 300, marginBottom: 20 }}
                  value={selectedClientName}
                  clearOnEscape
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Client Name"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, value) =>
                    setSelectedClientName(value || "")
                  }
                />
              </MenuItem> */}
              <MenuItem>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={translate("StartDate")}
                    value={startDate}
                    onChange={handleStartDateChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        style={{
                          width: 300,
                          marginBottom: 20,
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </MenuItem>
              <MenuItem>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={translate("EndDate")}
                    value={endDate}
                    onChange={handleEndDateChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        style={{
                          width: 300,
                          marginBottom: 20,
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </MenuItem>
              <MenuItem>
                <IconButton onClick={resetFilters}>
                  <DeleteIcon />{" "}
                  <span style={{ fontSize: "14px" }}>
                    {translate("ResetFilters")}
                  </span>
                </IconButton>
              </MenuItem>
            </StyledMenu>
          </div>

          <div style={{ width: "100%" }}>
            <TitleNoteCompact handleSort={handleSort} />
          </div>

          {filteredNotes.slice(0, visibleNotesCount).map((note, index) => {
            const dateOptions = {
              day: "numeric",
              month: "short",
              year: "numeric",
            };

            const dateOptions2 = {
              day: "numeric",
              month: "short",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            };

            const timeOptions = {
              hour: "2-digit",
              minute: "2-digit",
            };

            const formattedTimestamp = note.createdAt
              ? new Intl.DateTimeFormat("en-EN", dateOptions2).format(
                  note.createdAt.toDate()
                )
              : "";

            const formattedLastModification = note.updateAt
              ? new Intl.DateTimeFormat("en-EN", dateOptions2).format(
                  note.updateAt.toDate()
                )
              : "";

            const formattedDate = note.effectiveDateTime
              ? new Intl.DateTimeFormat("en-EN", dateOptions).format(
                  note.effectiveDateTime.toDate()
                )
              : "";

            const formattedTime = note.effectiveDateTime
              ? new Intl.DateTimeFormat("en-EN", timeOptions).format(
                  note.effectiveDateTime.toDate()
                )
              : "";

            const noteDate = formatDate(note.shiftStart, dateOptions);
            const noteTime = formatDate(note.shiftStart, timeOptions);
            const noteDateEnd = formatDate(note.shiftEnd, dateOptions);
            const noteDateEndTime = formatDate(note.shiftEnd, timeOptions);

            return (
              <NotesContainerCompact
                key={note.id}
                noteType={note.noteType}
                noteContent={note.noteContent}
                effectiveDateTime={note.effectiveDateTime}
                noteDate={formattedDate}
                noteTime={formattedTime}
                doneBy={note.doneBy}
                timeStamp={formattedTimestamp}
                lastModification={formattedLastModification}
                clientPic={note.clientProfilePicture}
                clientFirstName={note.clientFirstName}
                clientLastName={note.clientLastName}
                noteId={note.id}
                shiftStartRaw={note.shiftStart}
                shiftEndRaw={note.shiftEnd}
                shiftStart={noteDate}
                shiftEnd={noteDateEnd}
                staff={note.staff}
                relationship={note.relationShipFamilyNote}
                fileUrls={note.fileUrls}
                immmigrationAppointment={note.immmigrationAppointment}
                dischargesSwot={note.dischargesSwot}
                newClients={note.newClients}
                medicalInfos={note.medicalInfos}
                hospitalAppointment={note.hospitalAppointment}
                residentialClientMovements={note.residentialClientMovements}
                zoomSessionAssistance={note.zoomSessionAssistance}
                updateAt={note.updateAt}
                createdAt={note.createdAt}
                staffToday={note.staffToday}
                generalAnnouncement={note.generalAnnouncement}
                groupTopic={note.groupTopic}
                groupType={note.groupType}
                notInAttendance={note.notInAttendance}
                groupStartDateTime={note.groupStartDateTime}
                noteDateEndTime={noteDateEndTime}
                {...note}
              />
            );
          })}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "40px",
            }}
          >
            {!isLoading && filteredNotes.length > visibleNotesCount && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: "30px",
                  paddingLeft: "0px",
                }}
              >
                <Button
                  onClick={handleShowMoreNotes}
                  variant="outlined"
                  style={{
                    padding: "5px 10px",
                    fontSize: "12px",
                    cursor: "pointer",
                  }}
                >
                  SHOW MORE NOTES
                </Button>
              </div>
            )}
            {!isLoading && visibleNotesCount > 7 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: "30px",
                  paddingLeft: "30px",
                }}
              >
                <Button
                  onClick={handleShowLessNotes}
                  variant="outlined"
                  style={{
                    padding: "5px 10px",
                    fontSize: "12px",
                    cursor: "pointer",
                  }}
                >
                  SHOW LESS NOTES
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default AllNotesPerClientContainer;
