import React, { useState } from "react";
import ViewButton from "../Buttons/ViewButton";
import CheckWithDate from "../CheckWithDate";
import { Box, Modal } from "@mui/material";
import ViewAcaReport from "../forms/ViewAcaReport";
import AddButtonRound from "../Buttons/AddButtonRound";

function FormLineAca(props) {
  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: "80%",
    borderRadius: 5,
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const [showViewModal, setShowViewModal] = useState(false);

  const openViewModal = () => {
    setShowViewModal(true);
  };

  const closeViewModal = () => {
    setShowViewModal(false);
  };

  return (
    <div
      style={{
        marginLeft: "0px",
        display: "flex",
        flexDirection: "row",
        height: "50px",
        borderRadius: "30px",
        border: "1px solid lightgrey",
        width: "50%",
        marginTop: "5px",
        marginBottom: "5px",
        paddingLeft: "15px",
        paddingRight: "5px",
      }}
    >
      <Modal
        open={showViewModal}
        onClose={closeViewModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <ViewAcaReport {...props} closemodal={closeViewModal} />
        </Box>
      </Modal>
      <div
        style={{
          width: "60%",
          fontSize: "18px",
          fontWeight: "600",
          display: "flex",
          justifyContent: "flex-start",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {props.title}
      </div>
      <div
        style={{
          width: "30%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.signed === false || props.signed === undefined ? (
          <CheckWithDate checked={false} content="Not signed" />
        ) : (
          <CheckWithDate checked={true} content={props.date} />
        )}
      </div>
      <div
        style={{
          width: "10%",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {props.signed ? (
          <ViewButton handleOpen2={openViewModal} {...props} />
        ) : (
          <AddButtonRound handleOpen={props.handleOpen} />
        )}

        {/* <CheckButton />
        <PrintButton />
        <MailButton /> */}
      </div>
    </div>
  );
}

export default FormLineAca;
