export default [
  {
    id: 1,
    textDropDownEn: "--Choose--",
  },

  {
    id: 2,
    textDropDownEn: "Pending",
  },

  {
    id: 3,
    textDropDownEn: "Received",
  },
];
