import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import ClientWidget from "../ClientWidget";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { format } from "date-fns";
import ClientWidgetMobile from "../ClientWidgetMobile";
import { Button } from "@mui/material";
import TitleComponentV2 from "../Misc/TitleComponentV2";
import ClientRowDetails from "../Misc/ClientRowDetails";

function ContinuedTherapy({ viewMode, ...props }) {
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [clientDisplayLimit, setClientDisplayLimit] = useState(12);

  function formatDate(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    return format(date, "d MMMM yyyy"); // "d" pour le jour, "MMMM" pour le mois en texte, "yyyy" pour l'année
  }

  useEffect(() => {
    const q = query(
      collection(db, "clients"),
      where("clientStatus", "==", "Continued Therapy")
    );
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const clientDataWithAlerts = await Promise.all(
        querySnapshot.docs.map(async (clientDoc) => {
          const clientData = clientDoc.data();
          const alertsSnapshot = await getDocs(
            collection(clientDoc.ref, "alerts")
          );
          const alerts = alertsSnapshot.docs.map((alertDoc) => alertDoc.data());
          return { ...clientData, alerts, id: clientDoc.id };
        })
      );

      setClients(clientDataWithAlerts);
      setIsLoading(false);
    });
    return () => unsubscribe();
  }, []);

  return (
    <div>
      <div className="container-counter-tris">
        Total records: {clients.length}
      </div>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "500px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div
          className="client-widgets-global-container"
          style={{ paddingLeft: "15px" }}
        >
          {viewMode ? <TitleComponentV2 /> : ""}
          {clients.map((client) => {
            // props.view === "mobile" ? (
            if (props.view === "mobile") {
              return (
                <ClientWidgetMobile
                  key={client.id}
                  clientStatus={client.clientStatus}
                  firstnameClient={client.firstName}
                  lastnameClient={client.lastName}
                  clientIdentifier={client.clientIdentifier}
                  arrivalDate={formatDate(client.dateOfAdmission)}
                  idd={formatDate(client.idd)}
                  ils={client.durationOfStay}
                  assignedTherapist={client.selectedTherapist}
                  roomNumber={client.roomNumber}
                  aka={client.nickname}
                  age={client.age}
                  profilePic={client.profilePicture}
                  docId={client.id}
                  dob={client.dob}
                  alerts={client.alerts}
                  alertType3={client.AlertType3}
                  alertType1={client.AlertType1}
                  alertType2={client.AlertType2}
                />
              );
            } else {
              // ) : (
              return viewMode ? (
                <ClientRowDetails
                  key={client.id}
                  clientStatus={client.clientStatus}
                  firstName={client.firstName}
                  lastName={client.lastName}
                  gender={client.gender} // Assurez-vous d'ajouter les props manquantes
                  nationality={client.nationality}
                  country={client.country}
                  primaryAddiction={client.primaryAddiction}
                  secondaryAddiction={client.secondaryAddiction}
                  inActiveRecovery={client.inActiveRecovery}
                  eta={formatDate(client.eta)}
                  googleReviewDone={client.googleReviewDone}
                  personalEmail={client.personalEmail}
                  admissionDate={formatDate(client.dateOfAdmission)}
                  durationOfStay={client.durationOfStay}
                  flight={client.flight}
                  paymentDetail={client.paymentDetail}
                  vipTransfer={client.vipTransfer}
                  passportStatus={client.passportStatus}
                  profilPicture={client.profilePicture}
                  aka={client.nickname}
                  idClient={client.id}
                  clientData={client}
                  idd={formatDate(client.idd)}
                  ils={client.durationOfStay}
                  roomNumber={client.roomNumber}
                  assignedTherapist={client.selectedTherapist}
                  arrivalDate={formatDate(client.dateOfAdmission)}
                  dob={client.dob}
                  projectedResidentialDischargeDate={formatDate(
                    client.projectedResidentialDischargeDate
                  )}
                  assignedSupportStaff={client.assignedSupportWorker}
                />
              ) : (
                <ClientWidget
                  key={client.id}
                  clientStatus={client.clientStatus}
                  firstnameClient={client.firstName}
                  lastnameClient={client.lastName}
                  clientIdentifier={client.clientIdentifier}
                  arrivalDate={formatDate(client.dateOfAdmission)}
                  idd={formatDate(client.idd)}
                  ils={client.durationOfStay}
                  assignedTherapist={client.selectedTherapist}
                  roomNumber={client.roomNumber}
                  aka={client.nickname}
                  age={client.age}
                  profilePic={client.profilePicture}
                  docId={client.id}
                  dob={client.dob}
                  alerts={client.alerts}
                  alertType3={client.AlertType3}
                  alertType1={client.AlertType1}
                  alertType2={client.AlertType2}
                />
              );
            }
          })}
        </div>
      )}
      {!isLoading && clients.length > clientDisplayLimit && (
        <div className="seemore-container">
          <Button
            onClick={() => setClientDisplayLimit(clientDisplayLimit + 12)}
          >
            See more
          </Button>
        </div>
      )}
    </div>
  );
}

export default ContinuedTherapy;
