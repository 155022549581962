import React from "react";
import TopTitleBanner from "../component/TopTitleBanner";
import "../css/dashboard.css";
import UnderConstruction from "../component/Misc/UnderConstruction";

export default function PrescriptionsContent() {
  return (
    <div className="right-container">
      <TopTitleBanner title={"Prescriptions dashboard"} />
      <UnderConstruction />
    </div>
  );
}
