export default [
  {
    id: 1,
    textDropDownEn: "--Choose--",
  },

  {
    id: 2,
    textDropDownEn: "Relapse prevention",
  },

  {
    id: 3,
    textDropDownEn: "Recovery coaching",
  },

  {
    id: 4,
    textDropDownEn: "Psychoed",
  },

  {
    id: 5,
    textDropDownEn: "Other",
  },
];
