import React from "react";
import AddNoteButton from "../Buttons/AddNoteButton";
import ButtonSortByDate from "../Buttons/ButtonSortByDate";
import DropDownComponentNotes from "../DropDownComponentNotes";

function TopTitleNoteContainerMain(props) {
  return (
    <div className="notes-button-container-center">
      {props.showButton ? (
        <AddNoteButton
          title={props.title}
          handleOpen={props.handleOpen}
          leftMargin="none"
        />
      ) : (
        ""
      )}

      {/* <DropDownComponentNotes api="dummyClients" /> */}

      {/* <ButtonSortByDate /> */}
    </div>
  );
}

export default TopTitleNoteContainerMain;
