import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import {
  collection,
  addDoc,
  Timestamp,
  query,
  where,
  onSnapshot,
  doc,
  updateDoc,
} from "firebase/firestore";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Slide,
} from "@mui/material";
import { showCustomToast } from "./CustomToast";
import CircularProgress from "@mui/material/CircularProgress";
import { LanguageContext } from "../../context/LanguageContext";
import { useAuth } from "../../contexts/AuthContext";
import TextFieldComponentV3 from "../TextFieldComponentV3";
import InputComponentV2 from "../InputComponentV2";
import DropDownComponentV2 from "../DropDownComponentV2";
import { metricsDb } from "../../firebaseMetrics";
import { Autocomplete, TextField } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteTicketModal from "./DeleteTicketModal";
import DeleteIcon from "@mui/icons-material/Delete";
import EditTicketModal from "../forms/EditTicketModal";

export default function Tickets() {
  const { translate } = useContext(LanguageContext);
  const [openAddClientModal, setOpenAddClientModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const { currentUser } = useAuth();
  const [ticketName, setTicketName] = useState("");
  const [, setScreenshots] = useState(null);
  const [ticketType, setTicketType] = useState("");
  const [pageList, setPageList] = useState("");
  const [originalQuestion, setOriginalQuestion] = useState("");
  const [selectedPriority, setSelectedPriority] = useState("Low");
  const [, setClientIdState] = useState(currentUser?.uid || "defaultClientId");
  const [tickets, setTickets] = useState([]);
  const [checkedTickets, setCheckedTickets] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [ticketToDelete, setTicketToDelete] = useState(null);
  const [filteredTickets, setFilteredTickets] = useState([]);
  // Assure-toi que `filters` est bien initialisé avant son utilisation
  const [filters, setFilters] = useState({
    type: "",
    priority: "",
    dateAdded: "",
    ticketFilters: "",
    pageConcerned: "",
  });

  const [openEditModal, setOpenEditModal] = useState(false);
  const [ticketToEdit, setTicketToEdit] = useState(null);
  const [editLoading, setEditLoading] = useState(false);

  const handleOpenEditModal = (ticket) => {
    setTicketToEdit(ticket);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setTicketToEdit(null);
  };

  const handleUpdateTicket = async (updatedTicket) => {
    setEditLoading(true);
    try {
      const ticketDocRef = doc(
        metricsDb, // Ton instance Firestore
        "ticketsSupport-rehab-app", // Nom de ta collection
        ticketToEdit.id // ID du ticket que tu veux mettre à jour
      );

      // Mettre à jour les champs du ticket
      await updateDoc(ticketDocRef, {
        ticketName: updatedTicket.ticketName,
        ticketType: updatedTicket.ticketType,
        originalQuestion: updatedTicket.originalQuestion,
        pageConcerned: updatedTicket.pageList,
        priority: updatedTicket.selectedPriority,
        // Ajoute d'autres champs si nécessaire
      });

      showCustomToast(translate("TicketUpdatedSuccessfully"), "success");
      handleCloseEditModal(); // Ferme le modal après la mise à jour
    } catch (error) {
      showCustomToast(translate("FailedToUpdateTicket"), "error");
      console.error("Error updating document: ", error);
    } finally {
      setEditLoading(false);
    }
  };

  const handleOpenDeleteModal = (ticketId) => {
    setTicketToDelete(ticketId);
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setTicketToDelete(null);
  };

  useEffect(() => {
    if (currentUser) {
      setClientIdState(currentUser.uid);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      const q = query(
        collection(metricsDb, "ticketsSupport-rehab-app"),
        where("createdBy", "==", currentUser?.displayName || "Anonymous")
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedTickets = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTickets(fetchedTickets);
      });

      // Nettoyage du snapshot listener lorsqu'on quitte le composant
      return () => unsubscribe();
    }
  }, [currentUser]);

  useEffect(() => {
    // Filtre les tickets selon les filtres sélectionnés
    const applyFilters = () => {
      let filtered = tickets;

      if (filters.type) {
        filtered = filtered.filter(
          (ticket) => ticket.ticketType === filters.type
        );
      }

      if (filters.priority) {
        filtered = filtered.filter(
          (ticket) => ticket.priority === filters.priority
        );
      }

      if (filters.ticketFilters) {
        filtered = filtered.filter(
          (ticket) => ticket.status === filters.ticketFilters
        );
      }

      if (filters.dateAdded) {
        const today = new Date();
        filtered = filtered.filter((ticket) => {
          const ticketDate = new Date(ticket.createdAt.seconds * 1000);
          if (filters.dateAdded === "Today") {
            return ticketDate.toDateString() === today.toDateString();
          } else if (filters.dateAdded === "Last Week") {
            const oneWeekAgo = new Date(today.setDate(today.getDate() - 7));
            return ticketDate >= oneWeekAgo;
          } else if (filters.dateAdded === "Last Month") {
            const oneMonthAgo = new Date(today.setMonth(today.getMonth() - 1));
            return ticketDate >= oneMonthAgo;
          }
          return true;
        });
      }

      setFilteredTickets(filtered);
    };

    applyFilters();
  }, [tickets, filters]);

  const resetForm = () => {
    setTicketName("");
    setTicketType("");
    setOriginalQuestion("");
    setPageList("");
    setScreenshots(null);
    setSelectedPriority("Low");
  };

  const handleOpenAddTicketModal = () => {
    setSelectedCard(null);
    resetForm();
    setOpenAddClientModal(true);
  };

  const handleCloseAddTicketModal = () => {
    if (!selectedCard) {
      resetForm();
    }
    setOpenAddClientModal(false);
  };

  // const handleSubmit = async () => {
  //   if (!ticketName || !ticketType || !pageList || !selectedPriority) {
  //     showCustomToast(translate("Please fill in all required fields"), "error");
  //     return;
  //   }

  //   // const ticketNumber = `TS-${new Date().getTime()}`;
  //   const ticketNumber = `TS-${Math.floor(100000 + Math.random() * 900000)}`;

  //   const newTicket = {
  //     ticketNumber,
  //     ticketName,
  //     ticketType,
  //     originalQuestion,
  //     pageConcerned: pageList,
  //     priority: selectedPriority,
  //     status: "Not Seen Yet",
  //     createdBy: currentUser?.displayName || "Anonymous",
  //     saasName: "Rehab-app",
  //     createdAt: Timestamp.now(),
  //   };

  //   try {
  //     setLoading(true);
  //     await addDoc(
  //       collection(metricsDb, "ticketsSupport-rehab-app"),
  //       newTicket
  //     );
  //     showCustomToast(translate("TicketCreatedSuccessfully"), "success");
  //     resetForm();
  //     setOpenAddClientModal(false);
  //   } catch (e) {
  //     showCustomToast(translate("FailedToCreateTicket"), "error");
  //     console.error("Error adding document: ", e);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const handleSubmit = async () => {
  //   if (!ticketName || !ticketType || !pageList || !selectedPriority) {
  //     showCustomToast(translate("Please fill in all required fields"), "error");
  //     return;
  //   }

  //   const ticketNumber = `TS-${Math.floor(100000 + Math.random() * 900000)}`;

  //   const newTicket = {
  //     ticketNumber,
  //     ticketName,
  //     ticketType,
  //     originalQuestion,
  //     pageConcerned: pageList,
  //     priority: selectedPriority,
  //     status: "Not Seen Yet",
  //     createdBy: currentUser?.displayName || "Anonymous",
  //     saasName: "Rehab-app",
  //     createdAt: Timestamp.now(),
  //   };

  //   try {
  //     setLoading(true);
  //     const ticketRef = await addDoc(
  //       collection(metricsDb, "ticketsSupport-rehab-app"),
  //       newTicket
  //     );

  //     // Adding the first entry to the `activity` subcollection
  //     await addDoc(collection(ticketRef, "activity"), {
  //       action: "Ticket Created",
  //       performedBy: currentUser?.displayName || "Anonymous",
  //       timestamp: Timestamp.now(),
  //     });

  //     showCustomToast(translate("TicketCreatedSuccessfully"), "success");
  //     resetForm();
  //     setOpenAddClientModal(false);
  //   } catch (e) {
  //     showCustomToast(translate("FailedToCreateTicket"), "error");
  //     console.error("Error adding document: ", e);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleSubmit = async () => {
    showCustomToast("Demo version - You can not use this feature :)", "info");
    return; // Arrête l'exécution de la fonction en mode démo
  };

  const handleDeleteTicket = (ticketId) => {
    handleOpenDeleteModal(ticketId); // Ouvre le modal et stocke l'ID du ticket à supprimer
  };

  const handleFilterChange = (filterName, newValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: newValue,
    }));
  };

  const handleCheckboxChange = (ticketId) => {
    setCheckedTickets((prevState) => ({
      ...prevState,
      [ticketId]: !prevState[ticketId], // Inverser l'état de la case cochée
    }));
  };

  const TicketLine = ({
    ticket,
    onCheckboxChange,
    checked,
    onEdit,
    onDelete,
  }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingLeft: "10px",
          borderBottom: "1px solid lightgrey",
          marginBottom: "0px",
          // borderRadius: "30px",
          marginLeft: "0px",
          marginRight: "0px",
        }}
      >
        <div
          style={{
            fontWeight: "500",
            width: "5%",
            paddingLeft: "0px",
            paddingTop: "15px",
            paddingBottom: "15px",
            borderRight: "1px solid lightgrey",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          <Checkbox
            checked={checked}
            onChange={onCheckboxChange} // Appelle cette fonction lorsqu'on modifie l'état
          />
        </div>
        <div
          style={{
            fontWeight: "500",
            width: "10%",
            paddingLeft: "5px",
            paddingTop: "15px",
            paddingBottom: "15px",
            borderRight: "1px solid lightgrey",
          }}
        >
          {ticket.ticketNumber}
        </div>
        <div
          style={{
            fontWeight: "500",
            width: "15%",
            paddingLeft: "5px",
            paddingTop: "15px",
            paddingBottom: "15px",
            borderRight: "1px solid lightgrey",
            whiteSpace: "nowrap", // Empêche le texte de s'étendre sur plusieurs lignes
            overflow: "hidden", // Cache le débordement du texte
            textOverflow: "ellipsis",
          }}
        >
          {ticket.ticketName}
        </div>
        <div
          style={{
            width: "14%",
            paddingLeft: "5px",
            paddingTop: "15px",
            paddingBottom: "15px",
            borderRight: "1px solid lightgrey",
          }}
        >
          {ticket.ticketType}
        </div>

        <div
          style={{
            width: "14%",
            paddingLeft: "10px",
            paddingTop: "15px",
            paddingBottom: "15px",
            borderRight: "1px solid lightgrey",
            paddingRight: "10px",
            display: "flex",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            alignItems: "flex-start",
            alignSelf: "center",
          }}
        >
          <div
            style={{
              // width: "auto",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignSelf: "center",
              paddingLeft: "5px",
              fontSize: "16px",
              lineHeight: "16px",
              paddingRight: "10px",
              paddingTop: "5px",
              paddingBottom: "5px",
              borderRight: "1px solid lightgrey",
              borderRadius: "20px",
              fontWeight: "500",
              backgroundColor:
                ticket.priority === "High"
                  ? "#f8d7da" // Rouge pastel pour "High"
                  : ticket.priority === "Medium"
                  ? "#fff3cd" // Jaune pastel pour "Medium"
                  : "#d4edda", // Vert pastel pour "Low"
              color:
                ticket.priority === "High"
                  ? "red" // Texte rouge pour "High"
                  : ticket.priority === "Medium"
                  ? "#856404" // Texte jaune foncé pour "Medium"
                  : "#155724", // Texte vert pour "Low"
              alignItems: "center", // Pour centrer verticalement l'icône et le texte
            }}
          >
            <span
              style={{
                display: "inline-block",
                width: "10px",
                height: "10px",
                backgroundColor:
                  ticket.priority === "High"
                    ? "red" // Point rouge pour "High"
                    : ticket.priority === "Medium"
                    ? "#ffc107" // Point jaune pour "Medium"
                    : "#28a745", // Point vert pour "Low"
                borderRadius: "50%",
                marginRight: "10px",
              }}
            ></span>
            <div style={{}}>{ticket.priority}</div>
          </div>
        </div>

        <div
          style={{
            width: "14%",
            paddingLeft: "5px",
            paddingTop: "15px",
            paddingBottom: "15px",
            borderRight: "1px solid lightgrey",
            display: "flex",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            alignItems: "flex-start",
            alignSelf: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "lightgrey",
              width: "auto",
              fontWeight: "500",
              paddingLeft: "10px",
              paddingRight: "10px",
              borderRadius: "20px",
              paddingTop: "3px",
              paddingBottom: "3px",
            }}
          >
            {ticket.pageConcerned}
          </div>
        </div>
        <div
          style={{
            width: "10%",
            paddingLeft: "5px",
            paddingTop: "15px",
            paddingBottom: "15px",
            borderRight: "1px solid lightgrey",
            display: "flex",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            alignItems: "flex-start",
            alignSelf: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              alignSelf: "center",
              paddingLeft: "5px",
              fontSize: "16px",
              lineHeight: "16px",
              paddingRight: "10px",
              paddingTop: "5px",
              paddingBottom: "5px",
              borderRight: "1px solid lightgrey",
              borderRadius: "20px",
              fontWeight: "500",
              backgroundColor:
                ticket.status === "Open"
                  ? "#f8d7da" // Rouge pastel pour "Open"
                  : ticket.status === "Not Seen Yet"
                  ? "#fff3cd" // Jaune pastel pour "Not Seen Yet"
                  : "#d4edda", // Vert pastel pour "Closed"
              color:
                ticket.status === "Open"
                  ? "red" // Texte rouge pour "Open"
                  : ticket.status === "Not Seen Yet"
                  ? "#856404" // Texte orange foncé pour "Not Seen Yet"
                  : "#155724", // Texte vert pour "Closed"
            }}
          >
            {ticket.status}
          </div>
        </div>
        <div
          style={{
            width: "14%",
            paddingLeft: "5px",
            paddingTop: "15px",
            paddingBottom: "15px",
            borderRight: "1px solid lightgrey",
          }}
        >
          {new Date(ticket.createdAt.seconds * 1000).toLocaleString()}
        </div>

        <div
          style={{
            width: "4%",
            paddingLeft: "5px",
            paddingTop: "15px",
            paddingBottom: "15px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={() => {
                // handleOpenEditModal(ticket);
                showCustomToast(
                  "Demo version - You can not use this feature :)",
                  "info"
                );
                handleMenuClose();
              }}
            >
              Edit
            </MenuItem>

            <MenuItem
              onClick={() => {
                // onDelete(ticket.id); // Ouvre le modal avec l'ID du ticket
                showCustomToast(
                  "Demo version - You can not use this feature :)",
                  "info"
                );
                handleMenuClose();
              }}
            >
              <span style={{ color: "red" }}>Delete</span>
            </MenuItem>
          </Menu>
        </div>
      </div>
    );
  };

  const resetFilters = () => {
    setFilters({
      type: "",
      priority: "",
      ticketFilters: "",
      dateAdded: "",
      pageConcerned: "",
    });
  };

  const headerStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: "10px",
    paddingTop: "15px",
    paddingBottom: "5px",
    borderBottom: "2px solid lightgrey",
    fontWeight: "bold",
    fontSize: "12px",
  };

  const commonCellStyle = {
    textAlign: "left",
    paddingLeft: "5px",
  };

  const headerCells = [
    { label: "Select", width: "5%", textAlign: "center" },
    { label: "Ticket No.", width: "10%" },
    { label: "Ticket Name", width: "15%" },
    { label: "Type", width: "14%" },
    { label: "Priority", width: "14%", paddingLeft: "10px" },
    { label: "Page Concerned", width: "14%" },
    { label: "Status", width: "10%" },
    { label: "Created At", width: "14%" },
    { label: "Actions", width: "4%" },
  ];

  useEffect(() => {
    // Filtre les tickets selon les filtres sélectionnés
    const applyFilters = () => {
      let filtered = tickets;

      if (filters.type) {
        filtered = filtered.filter(
          (ticket) => ticket.ticketType === filters.type
        );
      }

      if (filters.priority) {
        filtered = filtered.filter(
          (ticket) => ticket.priority === filters.priority
        );
      }

      if (filters.pageConcerned) {
        filtered = filtered.filter(
          (ticket) => ticket.pageConcerned === filters.pageConcerned
        );
      }

      if (filters.ticketFilters) {
        filtered = filtered.filter(
          (ticket) => ticket.status === filters.ticketFilters
        );
      }

      if (filters.dateAdded) {
        const today = new Date();
        filtered = filtered.filter((ticket) => {
          const ticketDate = new Date(ticket.createdAt.seconds * 1000);
          if (filters.dateAdded === "Today") {
            return ticketDate.toDateString() === today.toDateString();
          } else if (filters.dateAdded === "Last Week") {
            const oneWeekAgo = new Date(today.setDate(today.getDate() - 7));
            return ticketDate >= oneWeekAgo;
          } else if (filters.dateAdded === "Last Month") {
            const oneMonthAgo = new Date(today.setMonth(today.getMonth() - 1));
            return ticketDate >= oneMonthAgo;
          }
          return true;
        });
      }

      setFilteredTickets(filtered);
    };

    applyFilters();
  }, [tickets, filters]);

  return (
    <div>
      <EditTicketModal
        open={openEditModal}
        onClose={handleCloseEditModal}
        ticket={ticketToEdit}
        handleUpdate={handleUpdateTicket}
        loading={editLoading}
      />

      <DeleteTicketModal
        open={deleteModalOpen}
        onClose={handleCloseDeleteModal}
        ticketId={ticketToDelete}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: "60px",
          paddingBottom: "20px",
          marginTop: "15px",
          paddingRight: "30px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
          }}
        >
          <div style={{ fontSize: "22px", fontWeight: "600" }}>
            {translate("SupportTickets")}
          </div>

          <div
            style={{ fontSize: "18px", fontWeight: "500", color: "#aaaaaa" }}
          >
            {translate("PleaseSendTicket")}
          </div>
        </div>

        <Button
          variant="outlined"
          color="primary"
          onClick={handleOpenAddTicketModal}
          startIcon={<Add />}
        >
          {translate("AddNewTicket")}
        </Button>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: "60px",
          paddingBottom: "20px",
          marginTop: "0px",
          paddingRight: "30px",
          borderTop: "1px solid #aaaaaa",
          paddingTop: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "80%",
            height: "5vh",
          }}
        >
          <Autocomplete
            options={["Incident", "Problem", "Question", "Suggestion"]} // Les types de tickets
            getOptionLabel={(option) => option}
            value={filters.type}
            onChange={(event, newValue) => handleFilterChange("type", newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Type" variant="outlined" />
            )}
            style={{ width: 200, marginRight: "10px" }}
          />

          <Autocomplete
            options={["Low", "Medium", "High"]}
            getOptionLabel={(option) => option}
            value={filters.priority}
            onChange={(event, newValue) =>
              handleFilterChange("priority", newValue)
            }
            renderInput={(params) => (
              <TextField {...params} label="Priority" variant="outlined" />
            )}
            style={{ width: 200, marginRight: "10px" }}
          />

          <Autocomplete
            options={[
              "Dashboard",
              "Clients",
              "Notes",
              "Room availability",
              "Room booking",
              "CRM",
              "Metrics",
              "Datas export",
              "Documents",
              "Profil",
              "Artificial intelligence",
            ]}
            getOptionLabel={(option) => option}
            value={filters.pageConcerned}
            onChange={(event, newValue) =>
              handleFilterChange("pageConcerned", newValue)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Page Concerned"
                variant="outlined"
              />
            )}
            style={{ width: 200, marginRight: "10px" }}
          />

          <Autocomplete
            options={["Not Seen Yet", "Open", "Closed"]}
            getOptionLabel={(option) => option}
            value={filters.ticketFilters}
            onChange={(event, newValue) =>
              handleFilterChange("ticketFilters", newValue)
            }
            renderInput={(params) => (
              <TextField {...params} label="Status" variant="outlined" />
            )}
            style={{ width: 200, marginRight: "10px" }}
          />

          <Autocomplete
            options={["Today", "Last Week", "Last Month"]}
            getOptionLabel={(option) => option}
            value={filters.dateAdded}
            onChange={(event, newValue) =>
              handleFilterChange("dateAdded", newValue)
            }
            renderInput={(params) => (
              <TextField {...params} label="Date Added" variant="outlined" />
            )}
            style={{ width: 200, marginRight: "10px" }}
          />
          <Button
            variant="contained"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={resetFilters}
            style={{ marginLeft: "10px", height: "40px" }} // Ajuste la taille et le margin
          >
            Reset
          </Button>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "20%",
            height: "5vh",
          }}
        ></div>
      </div>

      <div
        style={{
          width: "100%",
          paddingLeft: "60px",
          paddingRight: "30px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ paddingRight: "20px", width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "70vh",
              border: "1px solid lightgrey",
              borderRadius: "10px",
              paddingTop: "20px",
              paddingLeft: "0px",
            }}
          >
            <div
              style={{
                fontSize: "18px",
                fontWeight: "500",
                borderRadius: "20px",
                paddingLeft: "20px",
              }}
            >
              {translate("LatestSupportTickets")}
            </div>
            <div style={{ marginTop: "10px" }}>
              <div style={headerStyle}>
                {headerCells.map((cell, index) => (
                  <div
                    key={index}
                    style={{
                      width: cell.width,
                      ...commonCellStyle,
                      textAlign: cell.textAlign || "left", // Default to "left" if not provided
                      paddingLeft: cell.paddingLeft || "5px", // Default to "5px" if not provided
                    }}
                  >
                    {cell.label}
                  </div>
                ))}
              </div>
              <div style={{ marginTop: "0px" }}>
                {filteredTickets.length > 0 ? (
                  filteredTickets.map((ticket) => (
                    <TicketLine
                      key={ticket.id}
                      ticket={ticket}
                      checked={checkedTickets[ticket.id] || false} // Gérer la case à cocher par ticket
                      onCheckboxChange={() => handleCheckboxChange(ticket.id)} // Gestion du changement
                      onDelete={handleDeleteTicket}
                    />
                    // <TicketLine key={ticket.id} ticket={ticket} />
                  ))
                ) : (
                  <div style={{ paddingLeft: "20px", paddingTop: "10px" }}>
                    {translate("NoTicketsFound")}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={openAddClientModal}
        onClose={handleCloseAddTicketModal}
        aria-labelledby="add-client-modal-title"
        aria-describedby="add-client-modal-description"
        closeAfterTransition
      >
        <Slide direction="left" in={openAddClientModal}>
          <Box
            sx={{
              position: "fixed",
              top: "5%",
              right: 0,
              width: "40%",
              height: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              marginRight: "15px",
              pl: "2",
              pr: "2",
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 0,
                borderBottom: "1px solid lightgray",
              }}
            >
              <div
                id="add-client-modal-title"
                style={{
                  paddingLeft: "30px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  fontSize: "22px",
                  fontWeight: "700",
                }}
              >
                {selectedCard
                  ? translate("UpdateTicket")
                  : translate("AddNewTicket")}
              </div>
              <div style={{ paddingRight: "15px" }}>
                <IconButton onClick={handleCloseAddTicketModal}>
                  <CloseIcon />
                </IconButton>
              </div>
            </Box>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
              }}
            >
              <div style={{ width: "100%", paddingLeft: "20px" }}>
                <div
                  style={{
                    fontWeight: "600",
                    paddingBottom: "10px",
                    fontSize: "16px",
                    paddingTop: "30px",
                  }}
                >
                  {translate("TicketName")}
                </div>
                <InputComponentV2
                  width="80%"
                  value={ticketName}
                  onChange={(e) => setTicketName(e)}
                />

                <div
                  style={{
                    fontWeight: "600",
                    paddingBottom: "10px",
                    fontSize: "16px",
                    paddingTop: "30px",
                  }}
                >
                  {translate("WriteDescription")}
                </div>
                <TextFieldComponentV3
                  width="90%"
                  value={originalQuestion}
                  onChange={(e) => setOriginalQuestion(e)}
                />

                <div style={{ paddingTop: "20px" }}>
                  <div
                    style={{
                      fontWeight: "600",
                      paddingBottom: "10px",
                      fontSize: "16px",
                    }}
                  >
                    {translate("Priority")}
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div
                      className={`priority-option ${
                        selectedPriority === "Low" ? "selected" : ""
                      }`}
                      onClick={() => setSelectedPriority("Low")}
                    >
                      <div
                        style={{
                          backgroundColor: "green",
                          width: "15px",
                          height: "15px",
                          borderRadius: "10px",
                        }}
                      ></div>
                      <span style={{ paddingLeft: "10px" }}>
                        {translate("Low")}
                      </span>
                    </div>
                    <div
                      className={`priority-option ${
                        selectedPriority === "Medium" ? "selected" : ""
                      }`}
                      onClick={() => setSelectedPriority("Medium")}
                    >
                      <div
                        style={{
                          backgroundColor: "orange",
                          width: "15px",
                          height: "15px",
                          borderRadius: "10px",
                        }}
                      ></div>
                      <span style={{ paddingLeft: "10px" }}>
                        {translate("Medium")}
                      </span>
                    </div>
                    <div
                      className={`priority-option ${
                        selectedPriority === "High" ? "selected" : ""
                      }`}
                      onClick={() => setSelectedPriority("High")}
                    >
                      <div
                        style={{
                          backgroundColor: "red",
                          width: "15px",
                          height: "15px",
                          borderRadius: "10px",
                        }}
                      ></div>
                      <span style={{ paddingLeft: "10px" }}>
                        {translate("High")}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    fontWeight: "600",
                    paddingBottom: "10px",
                    fontSize: "16px",
                    paddingTop: "30px",
                  }}
                >
                  {translate("TicketType")}
                </div>
                <DropDownComponentV2
                  api="ticketType"
                  value={ticketType}
                  onChange={(e) => setTicketType(e)}
                  width="250px"
                />

                <div
                  style={{
                    fontWeight: "600",
                    paddingBottom: "10px",
                    fontSize: "16px",
                    paddingTop: "30px",
                  }}
                >
                  {translate("PageConcerned")}
                </div>
                <DropDownComponentV2
                  api="pageList"
                  value={pageList}
                  onChange={(e) => setPageList(e)}
                  width="250px"
                />
              </div>
            </div>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 0,
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingBottom: "20px",
                paddingTop: "20px",
                borderTop: "1px solid lightgray",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: "10px" }}
                onClick={handleCloseAddTicketModal}
              >
                {translate("Cancel")}
              </Button>

              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : selectedCard ? (
                  translate("UpdateTicket")
                ) : (
                  translate("AddNewTicket")
                )}
              </Button>
            </Box>
          </Box>
        </Slide>
      </Modal>
    </div>
  );
}
