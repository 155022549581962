import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";

function EditButtonMobileV2(props) {
  return (
    <IconButton
      aria-label="view"
      title="View"
      onClick={props.handleOpen2}
      style={{ backgroundColor: "#168f02", color: "white" }} // Define circle background and icon color
    >
      <EditIcon sx={{ fontSize: 11, color: "white" }} />
      {/* <span className="tooltip-text">{props.title}</span> */}
    </IconButton>
  );
}

export default EditButtonMobileV2;
