import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import DatePickerWithTimeComponent from "../DatePickerWithTimeComponent";
import InputComponent from "../InputComponent";
import { useAuth } from "../../contexts/AuthContext";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { showCustomToast } from "../Misc/CustomToast";
import { format } from "date-fns";
import DropDownComponentSorted from "../DropDownComponentSorted";
import TopTitleFormComponent from "../forms/TopTitleFormComponent";
import { useRef } from "react";
import ReactToPrint from "react-to-print";

function ViewGroupNotes(props) {
  const { currentUser } = useAuth();
  const printRef = useRef();

  function formatDate(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    return format(date, "d MMMM yyyy"); // "d" pour le jour, "MMMM" pour le mois en texte, "yyyy" pour l'année
  }

  // Initialize states with props
  const [typeGroupNote, setTypeGroupNote] = useState(props.groupType);
  const [otherTypeGroupNote, setOtherTypeGroupNote] = useState(
    props.otherTypeGroupNote
  );
  const [groupTopic, setGroupTopic] = useState(props.groupTopic);
  const [noteContent, setNoteContent] = useState(props.noteContent);
  const [notInAttendance, setNotInAttendance] = useState(props.notInAttendance);
  const [hasChanged, setHasChanged] = useState(false);
  const [groupNoteDateTime, setGroupNoteDateTime] = useState(
    props.effectiveDateTime || ""
  );

  const [groupNoteStartDateTime, setGroupNoteStartDateTime] = useState(
    props.groupStartDateTime
  );

  // Other state definitions
  const [isLoading, setIsLoading] = useState(false);

  // Other handler definitions
  const handleClose2 = () => setOpen2(false);
  const [open2, setOpen2] = useState(false);

  const handleTypeGroupNote = (event) => {
    setTypeGroupNote(event);
    setHasChanged(true);
  };

  const handleGroupTopic = (event) => {
    setGroupTopic(event);
    setHasChanged(true);
  };

  const handleOtherTypeGroupNote = (event) => {
    setOtherTypeGroupNote(event);
    setHasChanged(true);
  };

  const handleNoteContent = (event) => {
    setNoteContent(event.target.value);
    setHasChanged(true);
  };

  const handleNotInAttendance = (event) => {
    setNotInAttendance(event.target.value);
    setHasChanged(true);
  };

  const handleDateTimeChange = (event) => {
    setGroupNoteDateTime(event);
    setHasChanged(true);
  };

  const handleGroupStartDateTimeChange = (event) => {
    setGroupNoteStartDateTime(event);
    setHasChanged(true);
  };

  const handleUpdateGroupNote = async (note, effectiveDateTime) => {
    const noteData = {
      noteType: "groupNote",
      noteContent: note,
      updateAt: serverTimestamp(),
      effectiveDateTime: effectiveDateTime,
      doneBy: currentUser.displayName,
      notInAttendance: notInAttendance,
      groupTopic: groupTopic,
      typeGroupNote: typeGroupNote,
      otherTypeGroupNote: otherTypeGroupNote,
    };

    if (typeGroupNote && groupTopic && noteContent && notInAttendance) {
      setIsLoading(true);
      try {
        const docRef = doc(db, "notes", props.noteId); // Get a reference to the document to update
        await updateDoc(docRef, noteData); // Update the document
        showCustomToast("Group note successfully updated!", "success");

        setIsLoading(false);
        props.closemodal();
      } catch (error) {
        console.log("error updating note :", error);
        showCustomToast("Problem when updating note!", "error");
      }
    } else {
      showCustomToast(
        "Some mandatory fields are empty. Please check.!",
        "error"
      );
    }
  };

  const handleSubmit = () => {
    handleUpdateGroupNote(noteContent, groupNoteDateTime);
  };

  function formatDate(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    return format(date, "d MMMM yyyy"); // "d" pour le jour, "MMMM" pour le mois en texte, "yyyy" pour l'année
  }

  function formatDateWithTime(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    const formattedDate = format(date, "d MMMM yyyy"); // Date
    const formattedTime = format(date, "H 'h' mm 'min'"); // Heure en format européen

    return `${formattedDate} à ${formattedTime}`;
  }

  return (
    <>
      <style>
        {`
          @media print {
            #printableContent {
              height: auto !important;
              overflow-y: visible !important;
            }
          }
        `}
      </style>
      <div
        // className="inputAndLabelModalContainerTitle2"
        style={{ width: "auto", height: "100%" }}
      >
        <div className="line1-modal-percent">
          <TopTitleFormComponent
            title="VIEW GROUP NOTE"
            closemodal={props.closemodal}
          />
        </div>

        <div
          id="printableContent"
          style={{
            width: "100%",
            marginTop: "10px",
            height: "75%",
            overflowY: "scroll",
            // overflowX: "hidden",
          }}
          ref={printRef}
        >
          {" "}
          <div className="top-title-view-modal">
            Group note - {formatDate(groupNoteStartDateTime)}{" "}
            <span style={{ fontSize: "14px" }}> done by :</span>
            <span style={{ fontSize: "16px", fontWeight: "bold" }}>
              {" "}
              {props.doneBy}
            </span>
          </div>
          <div className="viewBlock">
            <div className="titleInput">Group topic</div>
            <div className="contentInput">{groupTopic}</div>
          </div>
          <div className="viewBlock">
            <div className="titleInput">Group Date and start time</div>
            <div className="contentInput">
              {formatDateWithTime(groupNoteStartDateTime)}
            </div>
          </div>
          <div className="viewBlock">
            <div className="titleInput">Type of group</div>
            {typeGroupNote === "Other" ? (
              <div className="contentInput">{otherTypeGroupNote}</div>
            ) : (
              <div className="contentInput">{typeGroupNote}</div>
            )}
          </div>
          <div className="viewBlock">
            <div className="titleInput"> Group note</div>
            <div className="contentInput">{noteContent}</div>
          </div>
          <div className="viewBlock">
            <div className="titleInput"> Clients NOT in attendance</div>
            <div className="contentInput">{notInAttendance}</div>
          </div>
          <div className="viewBlock">
            <div className="titleInput"> Effective date and time</div>
            <div className="contentInput">
              {formatDateWithTime(groupNoteDateTime)}
            </div>
          </div>
          <div className="viewFooter"></div>
        </div>

        <div className="line4-modal-percent">
          <Button
            variant="contained"
            style={{ backgroundColor: "white", color: "black" }}
            onClick={props.closemodal}
          >
            Cancel
          </Button>
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" style={{ marginLeft: "20px" }}>
                Print
              </Button>
            )}
            content={() => printRef.current}
            onAfterPrint={props.closemodal}
          />
        </div>
      </div>
    </>
  );
}

export default ViewGroupNotes;
