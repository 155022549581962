export default [
  {
    id: 1,
    textDropDownEn: "-- Choose --",
  },
  {
    id: 2,
    textDropDownEn: "Dashboard",
  },
  {
    id: 3,
    textDropDownEn: "Clients",
  },
  {
    id: 4,
    textDropDownEn: "Notes",
  },

  {
    id: 5,
    textDropDownEn: "Room availability",
  },

  {
    id: 6,
    textDropDownEn: "Room booking",
  },

  {
    id: 7,
    textDropDownEn: "CRM",
  },

  {
    id: 8,
    textDropDownEn: "Metrics",
  },

  {
    id: 9,
    textDropDownEn: "Datas export",
  },
  {
    id: 10,
    textDropDownEn: "Documents",
  },

  {
    id: 11,
    textDropDownEn: "Profil",
  },

  {
    id: 12,
    textDropDownEn: "Artificial intelligence",
  },
];
