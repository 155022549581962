import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function getStatusStyle(status) {
  const statusColors = {
    "In House": "green",
    Aftercare: "#e7900d",
    Incoming: "#6366f1",
    "Miracles@home": "#f02ddd",
    Inactive: "#f02d2d",
    "Hot Lead": "#2dc9f0",
    "Clinical Assessment": "#f0742d",
    "Continued Therapy": "#bc3efc",
    "Denied Admission": "#8a2be2", // Supposant que "Denied" est pour "chip-denied-admission"
    Potential: "#0d47a1", // Bleu foncé
  };

  return {
    width: "95px",
    color: "white",
    paddingLeft: "5px",
    marginRight: "12px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingTop: "5px",
    paddingBottom: "5px",

    paddingRight: "3px",
    backgroundColor: statusColors[status] || "#e0e0e0",
    borderRadius: "4px",
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
  };
}

function ClientRow({
  clientStatus,
  firstName,
  lastName,
  gender,
  nationality,
  country,
  primaryAddiction,
  secondaryAddiction,
  inActiveRecovery,
  googleReviewDone,
  personalEmail,
  durationOfStay,
  idClient,
  clientData,
}) {
  const navigate = useNavigate();
  const [shouldNavigate, setShouldNavigate] = React.useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleNavigate = () => {
    setShouldNavigate(true);
  };

  React.useEffect(() => {
    if (shouldNavigate) {
      navigate(`/client/${idClient}`, {
        state: clientData, // Passez l'objet client complet
      });
    }
  }, [shouldNavigate]);
  const rowStyle = {
    paddingLeft: "20px",
    cursor: "pointer",
    // Changez les couleurs selon vos préférences
  };
  const textStyle = {
    width: "95px",
    marginRight: "10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // backgroundColor: "grey",
  };

  const textStyleName = {
    width: "95px",
    marginRight: "10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "600",
    fontSize: "14px",
    color: "black",
    // backgroundColor: "grey",
  };

  const textStyleName2 = {
    width: "95px",
    marginRight: "10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "600",
    fontSize: "14px",
    // backgroundColor: "grey",
    paddingLeft: "3px",
    color: "black",
  };

  const textStyle2 = {
    width: "95px",
    marginRight: "3px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const textStyle3 = {
    width: "95px",
    marginRight: "10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // backgroundColor: "grey",
    marginLeft: "5px",
  };

  return (
    <div
      style={rowStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleNavigate}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: "5px",
          borderRadius: "5px",
          // borderBottom: "1px solid #ccc",
          marginBottom: "4px",
          color: "grey",
          fontSize: "14px",
          fontWeight: "450",
          height: "60px",
          // backgroundColor: "#e9e9e9",
          backgroundColor: isHovered ? "#d9e0ea" : "#efebeb",
        }}
      >
        <div style={textStyleName2}>{firstName}</div>
        <div style={textStyleName}>{lastName}</div>
        <div style={textStyle}>{gender}</div>
        <div
          style={{
            ...getStatusStyle(clientStatus),
            textAlign: "center",
            borderRadius: "30px",
            fontWeight: "700",
          }}
        >
          {clientStatus}
        </div>
        {/* <div style={textStyle}>{personalEmail}</div> */}
        <div style={textStyle}>{nationality}</div>
        <div style={textStyle}>{country}</div>
        <div style={textStyle}>{primaryAddiction}</div>
        <div style={textStyle}>{secondaryAddiction}</div>
        <div style={textStyle3}>{durationOfStay}</div>
        <div style={textStyle2}>{inActiveRecovery}</div>

        <div style={textStyle2}>{googleReviewDone}</div>
      </div>
    </div>
  );
}

export default ClientRow;
