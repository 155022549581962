export default [
  {
    id: 1,
    textDropDownEn: "--Choose--",
  },

  {
    id: 2,
    textDropDownEn: "Yes",
  },

  {
    id: 3,
    textDropDownEn: "No",
  },
];
