import React from "react";
import Navbar from "../component/Navbar";
import "../css/dashboard.css";
import PrescriptionsContent from "./PrescriptionsContent";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PrescriptionsContentMobile from "./PrescriptionsContentMobile";

export default function Prescriptions() {
  const navigate = useNavigate();

  const handleMenuOpen = () => {
    navigate("/menumobile");
  };

  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  return (
    <div className="main-container">
      {isMobile ? (
        <>
          <div className="menu-button-container">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuOpen}
              className="menu-button"
            >
              <MenuIcon />
            </IconButton>
          </div>

          <PrescriptionsContentMobile />
        </>
      ) : (
        <>
          <Navbar linkActive={4} />
          <PrescriptionsContent />
        </>
      )}
    </div>
  );
}
