import React from "react";
import "../css/dashboard.css";
import TopTitleBannerMobile from "../component/TopTitleBannerMobile";
import TutorialVideosContentMobile from "../component/Misc/TutorialVideosContentMobile";

export default function TutorialsContentMobile() {
  return (
    <div className="right-container-mobile">
      <TopTitleBannerMobile title={"Tutorials"} />
      <TutorialVideosContentMobile />
    </div>
  );
}
