import React from "react";
import CheckGreen from "../images/check-icon.png";
import CancelIcon from "../images/cancel-icon.png";

export default function CheckRibonMobileV2(props) {
  return (
    <div className="ribon-information-mobile-v2">
      {props.check ? (
        <img src={CheckGreen} style={{ width: "15px", height: "15px" }} />
      ) : (
        <img src={CancelIcon} style={{ width: "15px", height: "15px" }} />
      )}
      <div className="title-ribon"> {props.labelContent}</div>
    </div>
  );
}
