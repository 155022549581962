import { TextField } from "@mui/material";
import React from "react";

export default function TextFieldComponent(props) {
  const handleChange = (event) => {
    props.onChange(event.target.value);
  };
  return (
    <div className="input-label-container">
      <div className="input-label">{props.title}</div>
      <TextField
        //   value={takeProfitState}
        //   onChange={(e) => setTakeProfitState(e.target.value)}
        style={{
          width: props.width ? props.width : "auto",
          backgroundColor: "#FFFFFF",
          marginTop: 5,
        }}
        multiline
        rows={4}
        value={props.value}
        onChange={handleChange}
      />
    </div>
  );
}
