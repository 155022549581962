import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Modal,
} from "@mui/material";
import ClientProfilTab from "./ClientProfilTab";
import { db, storage } from "../firebase";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import AddClientInClient from "./forms/AddClientInClient";
import { format } from "date-fns";
import { readAndCompressImage } from "browser-image-resizer";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { IconButton } from "@mui/joy";
import { Edit as EditIcon } from "@mui/icons-material";
import DropDownComponent from "./DropDownComponent";
import DropDownComponentSorted from "./DropDownComponentSorted";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DatePickerComponent from "./DatePickerComponent";
import Joyride, { ACTIONS, STATUS } from "react-joyride";
import { LanguageContext } from "../context/LanguageContext";
import MainAddictionRibonMobile from "./MainAddictionRibonMobile";
import InformationRibonMobile from "./InformationRibonMobile";
import StatusRibonMobile from "./StatusRibonMobile";
import CheckRibonMobile from "./CheckRibonMobile";
import SearchAndAddClientModal from "./Misc/SearchAndAddClientModal";
import ClientProfilTabMobile from "./ClientProfilTabMobile";
import StatusRibonMobileV2 from "./StatusRibonMobileV2";
import MainAddictionRibonMobileV2 from "./MainAddictionRibonMobileV2";
import CheckRibonMobileV2 from "./CheckRibonMobileV2";
import InformationRibonMobileV2 from "./InformationRibonMobileV2";
import ClientProfilTabMobileV2 from "./ClientProfilTabMobileV2";

export default function ClientProfileMobileV2(props) {
  const { translate } = useContext(LanguageContext);
  const navigate = useNavigate();
  const imageConfig = {
    quality: 0.9,
    maxWidth: 200,
    autoRotate: true,
    debug: true,
  };

  const styleModalGeneral = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 750,
    height: 600,
    borderRadius: 5,
    backgroundColor: "white",
    boxShadow: 24,
  };

  const customStyles = {
    options: {
      zIndex: 10000,
    },

    tooltip: {
      backgroundColor: "#fff",
      color: "#000",
      animation: "fadeIn 0.3s ease-in-out",
    },

    tooltipContent: {
      textAlign: "justify",
      marginTop: "10px",
    },
    beacon: {
      offsetX: -50,
      offsetY: 0,

      inner: {
        content: "",
        width: 30,
        height: 30,
        background: 'url("/path-to-your-icon.svg") no-repeat center center',
        backgroundSize: "contain",
        backgroundColor: "blue",
      },
    },
    buttonNext: {
      backgroundColor: "#1565c0",
      color: "#fff",
    },
    buttonBack: {
      color: "blue",
    },
    beaconInner: {
      backgroundColor: "#1565c0", // Couleur du cercle intérieur
      borderColor: "#1565c0",
    },
    beaconOuter: {
      backgroundColor: "rgba(21, 101, 192, 0.5)", // Couleur de l'animation concentrique
      borderColor: "rgba(21, 101, 192, 0.5)",
    },
  };

  const handleBackClick = () => {
    navigate("/", {
      state: { selectedTab: clientData.clientStatus },
    });
  };

  const [, setUploadingImage] = useState(false);
  const [openAddClient, setOpenAddClient] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [isLoadingUpdateStatus, setIsLoadingUpdateStatus] = useState(false);
  const [openPrimaryAddictionModal, setOpenPrimaryAddictionModal] =
    useState(false);
  const [isLoadingUpdateAddiction, setIsLoadingUpdateAddiction] =
    useState(false);
  const [clientData, setClientData] = useState(null);
  const [newClientStatus, setNewClientStatus] = useState("");
  const [newClientAddiction, setNewClientAddiction] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalPhoto, setOpenModalPhoto] = React.useState(false);
  const [openAdmissionDateModal, setOpenAdmissionDateModal] = useState(false);
  const [openDurationModal, setOpenDurationModal] = useState(false);
  const handleOpenAdmissionDateModal = () => setOpenAdmissionDateModal(true);
  const handleCloseAdmissionDateModal = () => setOpenAdmissionDateModal(false);
  const handleOpenDurationModal = () => setOpenDurationModal(true);
  const handleCloseDurationModal = () => setOpenDurationModal(false);
  const [isLoadingUpdateAdmissionDate, setIsLoadingUpdateAdmissionDate] =
    useState(false);
  const [isLoadingUpdateDurationOfStay, setIsLoadingUpdateDurationOfStay] =
    useState(false);

  // const [newAdmissionDate, setNewAdmissionDate] = useState(
  //   clientData?.dateOfAdmission?.toDate().toISOString().split("T")[0] || ""
  // );

  const [newAdmissionDate, setNewAdmissionDate] = useState(
    clientData?.dateOfAdmission?.seconds
      ? clientData.dateOfAdmission.toDate().toISOString().split("T")[0]
      : clientData?.dateOfAdmission || ""
  );

  const [newDurationOfStay, setNewDurationOfStay] = useState(
    clientData?.durationOfStay || ""
  );

  const handleUpdateAdmissionDate = async () => {
    setIsLoadingUpdateAdmissionDate(true);
    const clientRef = doc(db, "clients", props.clientId);
    await updateDoc(clientRef, { dateOfAdmission: newAdmissionDate });
    setIsLoadingUpdateAdmissionDate(false);
    handleCloseAdmissionDateModal();
  };

  const handleUpdateDurationOfStay = async () => {
    setIsLoadingUpdateDurationOfStay(true);
    const clientRef = doc(db, "clients", props.clientId);
    await updateDoc(clientRef, { durationOfStay: newDurationOfStay });
    setIsLoadingUpdateDurationOfStay(false);
    handleCloseDurationModal();
  };

  const [run, setRun] = useState(false);

  const [showHelpButton, setShowHelpButton] = useState(true);

  const handleCloseAddClient = () => setOpenAddClient(false);

  const handleOpenModalPhoto = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseModalPhoto = () => {
    setOpenModal(false);
  };

  const handleOpenAddictionModal = () => {
    setOpenPrimaryAddictionModal(true);
  };

  const handleCloseAddictionModal = () => {
    setOpenPrimaryAddictionModal(false);
  };

  const handleOpenStatusModal = () => {
    setOpenStatusModal(true);
  };

  const handleCloseStatusModal = () => {
    setOpenStatusModal(false);
  };

  const handleClientStatusChange = (newValue) => {
    setNewClientStatus(newValue);
  };

  const handleClientAddictionChange = (newValue) => {
    setNewClientAddiction(newValue);
  };

  const handleUpdateClientStatus = async () => {
    setIsLoadingUpdateStatus(true);
    const clientRef = doc(db, "clients", props.clientId);
    await updateDoc(clientRef, { clientStatus: newClientStatus });
    setIsLoadingUpdateStatus(false);
    handleCloseStatusModal();
  };

  const handleUpdatePrimaryAddiction = async () => {
    setIsLoadingUpdateAddiction(true);
    const clientRef = doc(db, "clients", props.clientId);
    await updateDoc(clientRef, { primaryAddiction: newClientAddiction });
    setIsLoadingUpdateAddiction(false);
    handleCloseAddictionModal();
  };

  function formatDate(timestamp) {
    if (!timestamp || !timestamp.seconds) return timestamp || "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    return format(date, "d MMMM yyyy");
  }

  const handleProfilePictureChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const resizedImage = await readAndCompressImage(file, imageConfig);
        setUploadingImage(true);
        const storageRef = ref(storage, `profilePics/${clientData.id}`);
        await uploadBytes(storageRef, resizedImage);
        const profilePictureURL = await getDownloadURL(storageRef);
        await updateDoc(doc(db, "clients", clientData.id), {
          profilePicture: profilePictureURL,
        });
        setUploadingImage(false);
      } catch (error) {
        console.error("Error resizing and uploading image:", error);
        setUploadingImage(false);
      }
    }
  };

  useEffect(() => {
    if (props.clientId) {
      const clientRef = doc(db, "clients", props.clientId);
      const unsubscribe = onSnapshot(clientRef, (snapshot) => {
        const clientData = { id: snapshot.id, ...snapshot.data() };
        setClientData(clientData);
      });

      return () => {
        unsubscribe();
      };
    }
  }, [props.clientId]);

  useEffect(() => {
    if (clientData) {
      setNewClientStatus(clientData.clientStatus);
      setNewClientAddiction(clientData.primaryAddiction);
    }
  }, [clientData]);

  const handleHelpClick = () => {
    setRun(true);
    setShowHelpButton(false); // Cache le bouton "HELP" après le clic
  };

  const [steps, setSteps] = useState([
    {
      target: "#search-bar",
      content:
        "This is the search bar. Use this to search for clients. By typing just a few letters of the client's first or last name, client suggestions will appear.",
    },
    {
      target: "#status-ribon",
      content:
        "This is the client status. Click here to change the status by selecting an option from the dropdown menu. The client status indicates whether the client is in-house, incoming, in aftercare, etc.",
    },
    {
      target: "#primary-addiction-ribon",
      content:
        "This is the primary addiction. Click here to change the primary addiction by selecting an option from the dropdown menu. The primary addiction refers to the main substance or behavior the client is struggling with.",
    },
    {
      target: "#admission-date-ribon",
      content:
        "This is the admission date. Click here to change the admission date by selecting a date from the calendar. The admission date is the date when the client was admitted to the facility.",
    },
    {
      target: "#duration-of-stay-ribon",
      content:
        "This is the intended duration of stay. Click here to change the intended duration by selecting an option from the dropdown menu. The intended duration of stay refers to the planned length of the client's stay at the facility.",
    },
  ]);

  const handleJoyrideCallback = (data) => {
    const { status, action } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      setShowHelpButton(true);
    } else if ([ACTIONS.CLOSE].includes(action)) {
      setRun(false);
      setShowHelpButton(true);
    }
  };

  if (!clientData) {
    return (
      <div className="client-profil-sub-container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div
      className="client-profil-sub-container-mobile-v2"
      style={{
        height: "100vh",
        position: "relative",
      }}
    >
      <Joyride
        steps={steps}
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
        run={run}
        callback={handleJoyrideCallback}
        disableScrolling={true}
        disableBeacon={true}
        styles={customStyles}
      />

      <Modal
        open={openAdmissionDateModal}
        onClose={handleCloseAdmissionDateModal}
        aria-labelledby="admission-date-modal-title"
        aria-describedby="admission-date-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            height: 220,
            borderRadius: 5,
            backgroundColor: "white",
            boxShadow: 24,
          }}
        >
          <div className="status-modal-title">Change Admission Date</div>
          <div className="container-small-modal">
            <DatePickerComponent
              title="Admission Date"
              value={clientData.dateOfAdmission}
              onChange={(date) => setNewAdmissionDate(date)}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "10px",
              }}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  marginRight: 10,
                }}
                onClick={handleCloseAdmissionDateModal}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateAdmissionDate}
                disabled={isLoadingUpdateAdmissionDate}
              >
                {isLoadingUpdateAdmissionDate ? "Updating..." : "Update"}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openDurationModal}
        onClose={handleCloseDurationModal}
        aria-labelledby="duration-modal-title"
        aria-describedby="duration-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            height: 220,
            borderRadius: 5,
            backgroundColor: "white",
            boxShadow: 24,
          }}
        >
          <div className="status-modal-title">
            Change Intended Duration of Stay
          </div>
          <div className="container-small-modal">
            <DropDownComponentSorted
              api="durationOfStay"
              value={newDurationOfStay}
              onChange={(value) => setNewDurationOfStay(value)}
              title="Intended Duration of Stay"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "10px",
              }}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  marginRight: 10,
                }}
                onClick={handleCloseDurationModal}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateDurationOfStay}
                disabled={isLoadingUpdateDurationOfStay}
              >
                {isLoadingUpdateDurationOfStay ? "Updating..." : "Update"}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openAddClient}
        onClose={handleCloseAddClient}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalGeneral}>
          <AddClientInClient closemodal={handleCloseAddClient} />
        </Box>
      </Modal>

      <Modal
        open={openStatusModal}
        onClose={handleCloseStatusModal}
        aria-labelledby="status-modal-title"
        aria-describedby="status-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            height: 220,
            borderRadius: 5,
            backgroundColor: "white",
            boxShadow: 24,
          }}
        >
          <div className="status-modal-title">Change Client Status</div>
          <div className="container-small-modal">
            <DropDownComponentSorted
              api="clientStatus"
              value={newClientStatus}
              onChange={handleClientStatusChange}
              title="Client Status"
            />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  marginRight: 10,
                }}
                onClick={handleCloseStatusModal}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateClientStatus}
                disabled={isLoadingUpdateStatus}
              >
                {isLoadingUpdateStatus ? "Updating..." : "Update"}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openPrimaryAddictionModal}
        onClose={handleCloseAddictionModal}
        aria-labelledby="status-modal-title"
        aria-describedby="status-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            height: 220,
            borderRadius: 5,
            backgroundColor: "white",
            boxShadow: 24,
          }}
        >
          <div className="status-modal-title">Change Primary Addiction</div>
          <div className="container-small-modal">
            <DropDownComponent
              api="addictions"
              value={newClientAddiction}
              onChange={handleClientAddictionChange}
              title="Primary Addiction"
            />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  marginRight: 10,
                }}
                onClick={handleCloseAddictionModal}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdatePrimaryAddiction}
                disabled={isLoadingUpdateAddiction}
              >
                {isLoadingUpdateAddiction ? "Updating..." : "Update"}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openModalPhoto}
        onClose={handleCloseModalPhoto}
        aria-labelledby="modal-image"
        aria-describedby="image-full-size"
      >
        <div
          onClick={handleCloseModalPhoto}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          }}
        >
          <img
            src={props.profilePic}
            alt="Full-size profile picture"
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              objectFit: "contain",
            }}
          />
        </div>
      </Modal>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-image"
        aria-describedby="image-full-size"
      >
        <div
          onClick={handleCloseModal}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          }}
        >
          <img
            src={clientData.profilePicture}
            alt=""
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              objectFit: "contain",
            }}
          />
        </div>
      </Modal>

      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <SearchAndAddClientModal translate={translate} />

        <div
          className="top-component-list-client-bis-mobile-v2"
          style={{
            marginTop: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <div className="line1-title-client-profile">
            <div
              className="line1-sub-client-profile"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                className="block1"
                style={{
                  width: "100%",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                <div
                  // className="profile-client-pic-container"
                  style={{ width: "25%" }}
                >
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent={null}
                  >
                    <IconButton
                      onClick={handleBackClick}
                      style={{ marginRight: "8px", width: "10px" }}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={handleOpenModalPhoto}
                    >
                      <Avatar
                        src={clientData.profilePicture}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "8px",
                        }}
                        variant="square"
                      />
                    </div>
                  </Badge>
                  <input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleProfilePictureChange}
                  />
                </div>

                <div
                  // className="profile-client-name-container"

                  style={{
                    width: "75%",
                    marginBottom: "10px",
                    paddingLeft: "10px",
                  }}
                >
                  <div
                    className="profile-client-name-mobile-v2"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <div style={{ fontSize: "14px", fontWeight: "700" }}>
                      {clientData.firstName} {clientData.lastName}{" "}
                    </div>
                    <div style={{ fontSize: "14px", fontWeight: "400" }}>
                      {clientData.age && clientData.age !== "NA"
                        ? ` - ${clientData.age} ${translate("YearsOld")}`
                        : ""}{" "}
                    </div>
                    <div style={{ fontSize: "14px", fontWeight: "500" }}>
                      {clientData.gender && clientData.gender !== "NA"
                        ? ` - ${clientData.gender}`
                        : ""}
                    </div>
                    {/* <span style={{ fontSize: "14px", fontWeight: "500" }}>
                      {clientData.clientIdentifier ? (
                        <span>
                          {" - "}
                          <span style={{ fontWeight: 400 }}>
                            {clientData.clientIdentifier}
                          </span>
                        </span>
                      ) : (
                        translate("NoClientIdentifier")
                      )}
                    </span> */}
                  </div>
                  <div
                    // className="profile-client-subtext"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    {clientData.selectedTherapist ? (
                      <>
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            paddingLeft: "0px",
                          }}
                        >
                          {" "}
                          {translate("AssignedTherapist")}{" "}
                        </span>

                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            paddingLeft: "10px",
                          }}
                        >
                          {" "}
                          {clientData.selectedTherapist}
                        </span>
                      </>
                    ) : (
                      "No therapist assigned yet"
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                <div
                  id="status-ribon"
                  // className="status-ribon"
                  style={{ marginBottom: "0px", marginTop: "0px" }}
                >
                  <StatusRibonMobileV2
                    status={clientData.clientStatus}
                    onClick={handleOpenStatusModal}
                  />
                </div>
                <div
                  id="primary-addiction-ribon"
                  // className="main-addiction-ribon"
                  style={{
                    marginTop: "0px",
                    marginBottom: "0px",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                >
                  <MainAddictionRibonMobileV2
                    className="main-addiction-ribon"
                    mainAddiction={clientData.primaryAddiction}
                    addictionOther={clientData.addictionOther}
                    onClick={handleOpenAddictionModal}
                  />
                </div>

                <div style={{ paddingLeft: "0px" }}>
                  {clientData.AggressionControlPolicyOk === true &&
                  clientData.ClientRightsOk === true &&
                  clientData.ConfidentialityPolicysOk === true &&
                  clientData.GeneralLiabilityOk === true &&
                  clientData.InformedConsentOk === true &&
                  clientData.PsychiatristServicesOk === true ? (
                    <CheckRibonMobileV2
                      check={true}
                      labelContent={translate("MandatoryForms")}
                    />
                  ) : (
                    <CheckRibonMobileV2
                      labelContent={translate("MandatoryForms")}
                    />
                  )}
                </div>

                <div id="admission-date-ribon" style={{ marginLeft: "0px" }}>
                  <InformationRibonMobileV2
                    infoLabel={translate("AdmissionDate")}
                    labelContent={
                      clientData.dateOfAdmission
                        ? formatDate(clientData.dateOfAdmission)
                        : translate("NoAdmissionDateYet")
                    }
                    onClick={handleOpenAdmissionDateModal}
                  />
                </div>
                <div
                  id="duration-of-stay-ribon"
                  // className="duration-of-stay-ribon"
                  style={{ paddingLeft: "0px" }}
                >
                  <InformationRibonMobileV2
                    infoLabel={translate("IntendedDurationOfStay")}
                    labelContent={
                      clientData.durationOfStay
                        ? clientData.durationOfStay
                        : translate("NoDurationOfStayYet")
                    }
                    onClick={handleOpenDurationModal}
                  />
                </div>
              </div>
              <div className="block2">
                <div
                  style={{
                    position: "absolute",
                    top: "20px",
                    left: "20px",
                    zIndex: 10001,
                  }}
                ></div>

                <div className="client-profil-add-client-button"></div>
              </div>
            </div>
          </div>

          <div className="tab-content-bis-mobile-v2">
            <ClientProfilTabMobileV2
              clientId={clientData.id}
              selectedTab={props.selectedTab}
              {...clientData}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
