import React, { useState } from "react";
import { Button, Checkbox } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import SignaturePadComponent from "../Signature/SignaturePadComponent";
import TopTitleFormComponent from "./TopTitleFormComponent";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { updateDoc, doc as firestoreDoc } from "firebase/firestore";
import { db } from "../../firebase";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadString,
} from "firebase/storage";
import { showCustomToast } from "../Misc/CustomToast";
import SignaturePadComponentV2 from "../Signature/SignaturePadComponentV2";

function AddClientRights(props) {
  //Tous les states des données récupérées par client
  const { clientId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [signatureDataURL, setSignatureDataURL] = useState(null);
  const [isSignatureStarted, setIsSignatureStarted] = useState(false);
  const [isSignatureValidated, setIsSignatureValidated] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checked8, setChecked8] = useState(false);
  const [checked9, setChecked9] = useState(false);
  const [checked10, setChecked10] = useState(false);
  const [checked11, setChecked11] = useState(false);
  const [text0, setText0] = useState(
    "All individuals who apply for services, regardless of sex, race, age, color, creed, financial status, or national origin are assured that their rights as clients. In treatment you have the right to:"
  );
  const [text1, setText1] = useState("Be treated with respect and dignity");
  const [text2, setText2] = useState(
    "Receive timely, quality, and appropriate treatment at our rehab"
  );
  const [text3, setText3] = useState(
    "Receive communication and correspondence from individuals"
  );
  const [text4, setText4] = useState("Practice your religious beliefs");
  const [text5, setText5] = useState(
    "Consult with legal counsel and private practitioners of your choice and at your expense"
  );
  const [text6, setText6] = useState(
    "Exercise civil rights, including the right to personal clothing and effects"
  );
  const [text7, setText7] = useState(
    "Be provided information regarding your treatment and the treatment process and to receive services in accordance with standards of practice, appropriate to needs and designed to afford a reasonable opportunity to improve your condition"
  );
  const [text8, setText8] = useState(
    "Confidentiality to have your client records kept confidential, except in extreme cases which include: Crimes on program premises or against program personnel; medical emergencies; mandated reporting of child abuse or neglect or elder abuse or neglect; audit and evaluations; court orders; if you are assessed to be a danger to self or others."
  );
  const [text9, setText9] = useState(
    "You are encouraged to discuss any problems with our rehab clinical or medical staff"
  );
  const [text10, setText10] = useState(
    "If you are not happy with the outcome, you may bring your grievance to the Managing Director for further attention and resolution."
  );
  const [text11, setText11] = useState(
    "I hereby acknowledge receipt of and understand the Client Rights statement as indicated by my signature."
  );

  const handleCheckboxChange1 = (event) => {
    setChecked1(event.target.checked);
  };

  const handleCheckboxChange2 = (event) => {
    setChecked2(event.target.checked);
  };

  const handleCheckboxChange3 = (event) => {
    setChecked3(event.target.checked);
  };

  const handleCheckboxChange4 = (event) => {
    setChecked4(event.target.checked);
  };

  const handleCheckboxChange5 = (event) => {
    setChecked5(event.target.checked);
  };

  const handleCheckboxChange6 = (event) => {
    setChecked6(event.target.checked);
  };

  const handleCheckboxChange7 = (event) => {
    setChecked7(event.target.checked);
  };

  const handleCheckboxChange8 = (event) => {
    setChecked8(event.target.checked);
  };

  const handleCheckboxChange9 = (event) => {
    setChecked9(event.target.checked);
  };

  const handleCheckboxChange10 = (event) => {
    setChecked10(event.target.checked);
  };

  const handleCheckboxChange11 = (event) => {
    setChecked11(event.target.checked);
  };

  const handleSignatureSave = (dataURL) => {
    setSignatureDataURL(dataURL);
  };

  const uploadSignature = async () => {
    const storage = getStorage();
    const fileName = `${clientId}.png`;
    const signatureRef = ref(
      storage,
      `MandatoryForms/ClientRights/${fileName}`
    );

    await uploadString(signatureRef, signatureDataURL, "data_url");
    const downloadURL = await getDownloadURL(signatureRef);

    return downloadURL;
  };

  const handleFormSubmit = async () => {
    if (
      !checked1 ||
      !checked2 ||
      !checked3 ||
      !checked4 ||
      !checked5 ||
      !checked6 ||
      !checked7 ||
      !checked8 ||
      !checked9 ||
      !checked10 ||
      !checked11 ||
      !signatureDataURL ||
      !isSignatureValidated
    ) {
      showCustomToast(
        "Please agree to all the terms before submitting.",
        "error"
      );
    }

    try {
      setIsLoading(true);
      const signatureURL = await uploadSignature();
      const docRef = doc(db, "ClientRights", props.clientId);
      await setDoc(docRef, {
        checked1,
        checked2,
        checked3,
        checked4,
        checked5,
        checked6,
        checked7,
        checked8,
        checked9,
        checked10,
        checked11,
        text0,
        text1,
        text2,
        text3,
        text4,
        text5,
        text6,
        text7,
        text8,
        text9,
        text10,
        text11,
        signatureURL,
        signedOn: serverTimestamp(),
      });

      const clientDocRef = firestoreDoc(db, "clients", props.clientId);
      await updateDoc(clientDocRef, {
        ClientRightsOk: true,
        ClientRightsDateSigned: serverTimestamp(),
      });
      showCustomToast("Client rights form correctly submitted!", "success");

      setIsLoading(false);
      props.closemodal();
    } catch (e) {
      showCustomToast("Error adding document", "error");

      console.error("Error adding document: ", e);
    }
  };

  const allChecked =
    checked1 &&
    checked2 &&
    checked3 &&
    checked4 &&
    checked5 &&
    checked6 &&
    checked7 &&
    checked8 &&
    checked9 &&
    checked10 &&
    checked11;
  return (
    <>
      <div style={{ width: "auto", height: "100%" }}>
        <div className="line1-modal-percent">
          <TopTitleFormComponent
            title="CLIENT RIGHTS FORM"
            closemodal={props.closemodal}
          />
        </div>

        {/* <br /> */}

        <div
          style={{
            width: "100%",
            marginTop: "10px",
            height: "80%",
            overflowY: "scroll",
            paddingLeft: "15px",
            paddingRight: "15px",
            // overflowX: "hidden",
          }}
        >
          <div className="SubTitleBold">{text0}</div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked1} onChange={handleCheckboxChange1} />
            <div className="block-text-check">{text1}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked2} onChange={handleCheckboxChange2} />
            <div className="block-text-check">{text2}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked3} onChange={handleCheckboxChange3} />
            <div className="block-text-check">{text3}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked4} onChange={handleCheckboxChange4} />
            <div className="block-text-check">{text4}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked5} onChange={handleCheckboxChange5} />
            <div className="block-text-check">{text5}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked6} onChange={handleCheckboxChange6} />
            <div className="block-text-check">{text6}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked7} onChange={handleCheckboxChange7} />
            <div className="block-text-check">{text7}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked8} onChange={handleCheckboxChange8} />
            <div className="block-text-check">{text8}</div>
          </div>

          <div className="SubTitleBold">Grievance Procedure:</div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked9} onChange={handleCheckboxChange9} />
            <div className="block-text-check">{text9}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked10} onChange={handleCheckboxChange10} />
            <div className="block-text-check">{text10}</div>
          </div>

          <div className="SubTitleBold">Acknowledgement</div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked11} onChange={handleCheckboxChange11} />
            <div className="block-text-check">{text11}</div>
          </div>
          <div style={{ width: "100%", marginBottom: "25px" }}>
            <SignaturePadComponentV2
              onSignatureSave={handleSignatureSave}
              signatureDataURL={signatureDataURL}
              onSignatureStart={() => setIsSignatureStarted(true)}
              onSignatureValidate={setIsSignatureValidated}
            />
          </div>
        </div>
        <div className="line4-modal-percent-bis">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
              onClick={props.closemodal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleFormSubmit}
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : !signatureDataURL || !isSignatureValidated || !allChecked
                  ? {
                      backgroundColor: "grey",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                    }
              }
              disabled={
                isLoading ||
                !signatureDataURL ||
                !allChecked ||
                !isSignatureValidated
              }
            >
              {isLoading ? "SAVING..." : "SAVE"}
            </Button>
          </>
        </div>
      </div>
    </>
  );
}

export default AddClientRights;
