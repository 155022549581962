import React, { useContext, useEffect, useState } from "react";
import TopTitleBanner from "../component/TopTitleBanner";
import "../css/dashboard.css";
import "../css/client.css";
import { Button } from "@mui/material";
import { documentId, onSnapshot, query, where } from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import { roles } from "../firebase.collection";
import CheatsheetChart from "../component/clientsList/CheatsheetChart";
import TopTitleBannerV2 from "../component/TopTitleBannerV2";
import { LanguageContext } from "../context/LanguageContext";

export default function CheatsheetContent() {
  const { translate } = useContext(LanguageContext);
  const { currentUser } = useAuth();
  const userIdState = currentUser ? currentUser.uid : "";
  const [userRole, setUserRole] = useState("");
  const [isFullscreen, setIsFullscreen] = useState(false);

  function queryRole() {
    onSnapshot(
      query(roles, where(documentId(), "==", userIdState)),
      (querySnapshot) => {
        const doc = querySnapshot.docs[0];
        if (doc && doc.exists) {
          const role = doc.data().role;
          setUserRole(role);
        } else {
          console.log("No role for this user");
        }
      }
    );
  }

  useEffect(() => {
    if (currentUser) {
      queryRole();
    } else {
      console.log("No user id");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const isSuperAdmin = userRole === "Super Admin" || userRole === "Admin";

  const handleEnterFullscreen = () => {
    const element = document.getElementById("cheatsheet-chart-fullscreen");
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      /* Firefox */
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      /* IE/Edge */
      element.msRequestFullscreen();
    }
    setIsFullscreen(true);
  };

  const handleExitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari & Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen();
    }
    setIsFullscreen(false);
  };

  return (
    <div className="right-container-cheatsheet" style={{ zIndex: 1 }}>
      {isSuperAdmin ? (
        <>
          <TopTitleBannerV2 title={translate("RoomBooking")} />
          <div className="top-component-list-clients"> </div>

          <div style={{ paddingLeft: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleEnterFullscreen}
              style={{ width: "200px", marginLeft: "50px" }}
            >
              {translate("FullscreenMode")}
            </Button>
          </div>

          <div
            id="cheatsheet-chart-fullscreen"
            style={{
              position: isFullscreen ? "fixed" : "relative",
              top: isFullscreen ? 0 : "auto",
              left: isFullscreen ? 0 : "auto",
              width: isFullscreen ? "100%" : "auto",
              height: isFullscreen ? "100%" : "auto",
              backgroundColor: isFullscreen ? "white" : "transparent",
              zIndex: isFullscreen ? 9999 : "auto",
              padding: isFullscreen ? "20px" : "0",
            }}
          >
            <CheatsheetChart />
            {isFullscreen && (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleExitFullscreen}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                }}
              >
                {translate("ExitFullscreen")}
              </Button>
            )}
          </div>
        </>
      ) : (
        <div>
          <TopTitleBannerV2 title={"Advanced search"} />
          <p style={{ marginLeft: "60px", color: "red", fontWeight: "bold " }}>
            {translate("NoCredentials")}
          </p>
        </div>
      )}
    </div>
  );
}
