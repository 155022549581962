import React, { useEffect, useState } from "react";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../../firebase";
import TextField from "@mui/material/TextField";

function DropDownClientIdentifier(props) {
  const [selectedClient, setSelectedClient] = useState(props.value);
  const [allClients, setAllClients] = useState([]);

  useEffect(() => {
    const clientCollection = collection(db, "clients");
    const q = query(clientCollection);

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      setAllClients(
        querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            firstName: data.firstName,
            lastName: data.lastName,
            clientIdentifier: data.clientIdentifier,
          };
        })
      );
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleChange = (event) => {
    props.onChange(event.target.value);
  };

  // const renderOptions = () => {
  //   allClients.sort((a, b) => a.firstName.localeCompare(b.firstName));
  //   return (
  //     <>
  //       <option value="">--Choose a client--</option>
  //       {allClients.map((client) => (
  //         <option key={client.id} value={client.clientIdentifier}>
  //           {`${client.clientIdentifier} (${client.firstName} ${client.lastName})`}
  //         </option>
  //       ))}
  //     </>
  //   );
  // };

  const renderOptions = () => {
    allClients.sort((a, b) => {
      // Assure-toi que `firstName` est défini avant d'utiliser `localeCompare`
      const firstNameA = a.firstName || "";
      const firstNameB = b.firstName || "";
      return firstNameA.localeCompare(firstNameB);
    });
    return (
      <>
        <option value="">--Choose a client--</option>
        {allClients.map((client) => (
          <option key={client.id} value={client.clientIdentifier}>
            {`${client.clientIdentifier} (${client.firstName || "Unknown"} ${
              client.lastName || "Unknown"
            })`}
          </option>
        ))}
      </>
    );
  };

  return (
    <div
    // className="input-label-container"
    >
      <div
        style={{
          color: "#a0a0a0",
          fontWeight: "500",
          fontSize: "12px",
          textTransform: "uppercase",
          paddingBottom: "3px",
        }}
      >
        {props.title}
        {props.isRequired && <span style={{ color: "red" }}>*</span>}
      </div>
      <TextField
        className="dropdown-select"
        // style={{ width: "200px" }}
        style={{
          width: props.width,
          backgroundColor: "#f3f3f3",
          fontWeight: "500",
          fontSize: "16px",
        }}
        SelectProps={{
          native: true,
          style: { height: 40 },
        }}
        select
        value={props.value}
        onChange={handleChange}
      >
        {renderOptions()}
      </TextField>
    </div>
  );
}

export default DropDownClientIdentifier;
