import React, { useContext } from "react";
import "../css/dashboard.css";
import { LanguageContext } from "../context/LanguageContext";
import CollapseComponentMobileV2 from "../component/Misc/CollapseComponentMobileV2";

export default function DocumentsContentMobileV2() {
  const { translate } = useContext(LanguageContext);
  return (
    <div className="right-container-mobile">
      {/* <TopTitleBannerV2 title={translate("Documents")} /> */}
      <CollapseComponentMobileV2 />
    </div>
  );
}
