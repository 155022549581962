import React from "react";

// function LineLogsDetails({ fieldName, value }) {
//   return (
//     <div className="line-detail-logs">
//       <div>
//         <span
//           style={{
//             fontSize: "16px",
//             fontWeight: "500",
//             color: "black",
//             paddingRight: "5px",
//           }}
//         >
//           {/* New value on the field : */}
//         </span>
//         <span
//           style={{
//             fontSize: "16px",
//             fontWeight: "500",
//             color: "#007bff",
//             paddingLeft: "5px",
//           }}
//         >
//           {fieldName}:
//           <span style={{ color: "red", paddingLeft: "10px" }}>
//             {JSON.stringify(value)}
//           </span>
//         </span>
//       </div>
//     </div>
//   );
// }

function LineLogsDetails({ fieldName, value }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 15px",
        borderBottom: "1px solid #e0e0e0",
        backgroundColor: "#f9f9f9",
        fontFamily: '"Arial", sans-serif',
        fontSize: "0.95em",
        color: "#333333",
      }}
    >
      <div style={{ fontWeight: "bold", flex: 1 }}>{fieldName}</div>
      <div style={{ flex: 2 }}>{JSON.stringify(value)}</div>
    </div>
  );
}

export default LineLogsDetails;
