import React, { useContext } from "react";
import "../css/dashboard.css";
import "../css/client.css";
import ClientProfile from "../component/ClientProfile";
import { useLocation, useParams } from "react-router-dom";
import TopTitleBannerV2 from "../component/TopTitleBannerV2";
import { LanguageContext } from "../context/LanguageContext";
import ClientProfileMobileV2 from "../component/ClientProfileMobileV2";

export default function ClientContentMobileV2(props) {
  const { translate } = useContext(LanguageContext);
  const { id } = useParams();
  const clientId = props.clientId || id;
  const { state } = useLocation();
  const selectedTab = state?.selectedTab || "2";

  return (
    <div className="right-container-mobile">
      {/* <TopTitleBannerV2
        title={translate("ClientProfile")}
        clientId={clientId}
      /> */}
      <ClientProfileMobileV2
        clientId={clientId}
        selectedTab={selectedTab}
        // clientId={props.clientId}
      />
    </div>
  );
}
