import { useState } from "react";
import TopTitleFormComponent from "./TopTitleFormComponent";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import DatePickerComponent from "../DatePickerComponent";
import DropDownComponent from "../DropDownComponent";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { showCustomToast } from "../Misc/CustomToast";
import InputComponent from "../InputComponent";
export default function EditSwotLine(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [dos, setDos] = useState(props.dos);
  const [nameStaff, setNameStaff] = useState(props.nameStaff);
  const [nameCTS, setNameCTS] = useState(props.nameCTS);
  const [nameRecoveryCoach, setNameRecoveryCoach] = useState(
    props.nameRecoveryCoach || ""
  );
  const [mAtHome, setMAtHome] = useState(
    props.mah === "true" ? true : props.mah === "false" ? false : undefined
  );
  const [intakeDate, setIntakeDate] = useState(props.intakeDate);
  const [dischargeDate, setDischargeDate] = useState(props.dischargeDate);
  const [swot, setSwot] = useState(props.swot);

  const handleDosChange = (event) => {
    setDos(event);
  };

  const handleNameStaffChange = (event) => {
    setNameStaff(event);
  };

  const handleNameCTSChange = (event) => {
    setNameCTS(event);
  };

  const handleNameRecoveryCoachChange = (event) => {
    setNameRecoveryCoach(event);
  };

  const handleMAtHomeChange = (event) => {
    setMAtHome(event.target.checked);
  };

  const handleIntakeDateChange = (event) => {
    setIntakeDate(event);
  };

  const handleDischargeDateChange = (event) => {
    setDischargeDate(event);
  };

  const handleSwotChange = (event) => {
    setSwot(event);
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    try {
      await updateDoc(doc(db, "clients", props.clientId), {
        durationOfStay: dos,
        // flight: flightDate,
        aftercareStaff1: nameStaff,
        aftercareStaff2: nameCTS,
        nameRecoveryCoach: nameRecoveryCoach,
        miraclesAtHome: mAtHome === undefined ? null : mAtHome,
        dateOfAdmission: intakeDate,
        swot: swot,
        // swotDone: swotDone === undefined ? null : swotDone,
        idd: dischargeDate,
      });
      setIsLoading(false);
      showCustomToast(`Swot successfully updated!`, "success");
      props.closemodal();
    } catch (error) {
      console.error(error);
      showCustomToast(`Error while updating Swot`, "error");
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        className="inputAndLabelModalContainerTitle3"
        style={{
          position: "relative",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 1,
        }}
      >
        <TopTitleFormComponent
          title={`Edit ${props.name} swot`}
          closemodal={props.closemodal}
        />
      </div>

      <div style={{ marginTop: "30px", height: "250px" }}>
        <div className="tab-form-container">
          <DatePickerComponent
            title="Intake date"
            width="150px"
            value={intakeDate}
            onChange={handleIntakeDateChange}
          />
          <DropDownComponent
            api="durationOfStay"
            title="Intended duration of stay"
            value={dos}
            onChange={handleDosChange}
          />

          <DatePickerComponent
            title="Discharge date"
            width="150px"
            value={dischargeDate}
            onChange={handleDischargeDateChange}
          />

          {/* <FormControlLabel
            control={
              <Checkbox
                checked={swotDone}
                onChange={handleSwotDoneChange}
                style={{ marginLeft: "35px" }}
              />
            }
            label="Swot done? "
          /> */}

          <DatePickerComponent
            title="Swot date"
            width="150px"
            value={swot}
            onChange={handleSwotChange}
          />

          {/* <DatePickerComponent
            title="Flight Date"
            width="150px"
            value={flightDate}
            onChange={handleFlightDateChange}
          /> */}

          <InputComponent
            title="Aftercare staff name"
            width="250px"
            value={nameStaff}
            onChange={handleNameStaffChange}
          />

          {/* <SupportStaff
            title="Aftercare staff name"
            value={nameStaff}
            onChange={handleNameStaffChange}
          /> */}

          <InputComponent
            title="Continued counselling with our rehab therapists"
            width="250px"
            value={nameCTS}
            onChange={handleNameCTSChange}
            placeholder="Provide therapist name"
          />

          {/* <SupportStaff
            title="CTS counselling"
            value={nameCTS}
            onChange={handleNameCTSChange}
          /> */}
          <br />
        </div>

        <div className="tab-form-container">
          <InputComponent
            title="Recovery coach"
            width="250px"
            value={nameRecoveryCoach}
            onChange={handleNameRecoveryCoachChange}
            placeholder="Provide recovery coach name"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={mAtHome}
                onChange={handleMAtHomeChange}
                style={{
                  marginLeft: "35px",
                  paddingTop: "10px",
                }}
              />
            }
            label="Miracles at Home ? "
          />
        </div>
        <div className="line4-modal">
          <Button
            variant="contained"
            style={{ backgroundColor: "white", color: "black" }}
            // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
            onClick={props.closemodal}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handleUpdate()}
            style={
              isLoading
                ? {
                    backgroundColor: "red",
                    color: "white",
                    marginLeft: "15px",
                  }
                : {
                    backgroundColor: "#1565C0",
                    color: "white",
                    marginLeft: "15px",
                  }
            }
            disabled={isLoading}
            //   onClick={handlePostRecord}
          >
            {isLoading ? "UPDATING SWOT..." : "UPDATE SWOT"}
          </Button>
        </div>
      </div>
    </>
  );
}
