import React from "react";
import { Box } from "@mui/material";
import { BounceLoader } from "react-spinners";
import "./LoadingComponent.css";

// Styles pour l'animation du texte

function LoadingComponent() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "80%",
      }}
    >
      <BounceLoader color={"#2696ff"} />
      <p className="bouncing-text">Fetching data</p>
    </Box>
  );
}

export default LoadingComponent;
