export default [
  {
    id: 1,
    textDropDownEn: "--Choose--",
  },

  {
    id: 2,
    textDropDownEn: "Father",
  },

  {
    id: 3,
    textDropDownEn: "Mother",
  },

  {
    id: 4,
    textDropDownEn: "Son",
  },

  {
    id: 5,
    textDropDownEn: "Daughter",
  },

  {
    id: 6,
    textDropDownEn: "Husband",
  },

  {
    id: 7,
    textDropDownEn: "Wife",
  },

  {
    id: 8,
    textDropDownEn: "Brother",
  },

  {
    id: 9,
    textDropDownEn: "Sister",
  },

  {
    id: 10,
    textDropDownEn: "Friend",
  },

  {
    id: 11,
    textDropDownEn: "Partner",
  },

  {
    id: 12,
    textDropDownEn: "Other",
  },
];
