import React, { useEffect } from "react";
import GroupNoteContainer from "../Notes/GroupNoteContainer";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useState } from "react";
import { CircularProgress } from "@mui/material";

function GroupNotesAll(props) {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const notesRef = collection(db, "notes");
    const q = query(
      notesRef,
      where("noteType", "==", "groupNote"),
      orderBy("effectiveDateTime", "desc"),
      limit(50)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const notesData = [];
      querySnapshot.forEach((doc) => {
        notesData.push({ id: doc.id, ...doc.data() });
      });
      setLoading(false);
      setNotes(notesData);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="notes-container-global">
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
          <p>Loading, please wait...</p>
        </div>
      ) : (
        notes.map((note) => {
          const dateOptions = {
            day: "numeric",
            month: "short",
            year: "numeric",
          };

          const dateOptions2 = {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          };

          const timeOptions = {
            hour: "2-digit",
            minute: "2-digit",
          };

          const formattedTimestamp = note.createdAt
            ? new Intl.DateTimeFormat("en-EN", dateOptions2).format(
                note.createdAt.toDate()
              )
            : "";

          const formattedLastModification = note.updateAt
            ? new Intl.DateTimeFormat("en-EN", dateOptions2).format(
                note.updateAt.toDate()
              )
            : "";

          const formattedDate = note.effectiveDateTime
            ? new Intl.DateTimeFormat("en-EN", dateOptions).format(
                note.effectiveDateTime.toDate()
              )
            : "";

          const formattedTime = note.effectiveDateTime
            ? new Intl.DateTimeFormat("en-EN", timeOptions).format(
                note.effectiveDateTime.toDate()
              )
            : "";

          return (
            <GroupNoteContainer
              key={note.id}
              noteContent={note.noteContent}
              effectiveDateTime={note.effectiveDateTime}
              noteDate={formattedDate}
              noteTime={formattedTime}
              doneBy={note.doneBy}
              timeStamp={formattedTimestamp}
              lastModification={formattedLastModification}
              clientPic={note.clientProfilePicture}
              clientFirstName={note.clientFirstName}
              clientLastName={note.clientLastName}
              noteId={note.id}
              groupTopic={note.groupTopic}
              groupType={note.typeGroupNote}
              notInAttendance={note.notInAttendance}
              updateAt={note.updateAt}
              createdAt={note.createdAt}
              groupStartDateTime={note.groupStartDateTime}
              {...note}
            />
          );
        })
      )}
    </div>
  );
}

export default GroupNotesAll;
