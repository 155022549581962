export default [
  {
    id: 1,
    textDropDownEn: "--Choose--",
  },

  {
    id: 2,
    textDropDownEn: "Completed Residential Treatment",
  },

  // {
  //   id: 3,
  //   textDropDownEn: "Transition to Miracles@Home Aftercare",
  // },

  {
    id: 4,
    textDropDownEn: "Against Clinical Advice",
  },
  {
    id: 5,
    textDropDownEn: "Emergency Discharge",
  },

  {
    id: 6,
    textDropDownEn: "Other",
  },
];
