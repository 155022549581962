import React from "react";
import DeleteButtonFile from "../Buttons/DeleteButtonFile";

function UploadedFilesComponent({ fileLinks, deleteFile, noteId }) {
  return (
    <div className="form2">
      <label
        htmlFor="uploadFiles"
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "8px",
          marginTop: "20px",
          paddingLeft: "5px",
          paddingRight: "5px",
        }}
      >
        Files already uploaded :
      </label>
      <div className="list-files-container">
        {fileLinks.map((file, index) => (
          <div key={index} className="file-item">
            File name :
            <a
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                width: 100,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              href={file.url}
              target="_blank"
              rel="noreferrer"
            >
              {file.name}
            </a>
            <DeleteButtonFile
              fileName={file.name}
              onClick={() => deleteFile(file.url, file.name, noteId)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default UploadedFilesComponent;
