import React from "react";
import CheckGreen from "../images/check-icon.png";
import CancelIcon from "../images/cancel-icon.png";

export default function CheckRibonMobile(props) {
  return (
    <div className="ribon-information-mobile">
      {props.check ? <img src={CheckGreen} /> : <img src={CancelIcon} />}
      <div className="title-ribon"> {props.labelContent}</div>
    </div>
  );
}
