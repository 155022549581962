export default [
  {
    id: 1,
    textDropDownEn: "-- Choose --",
  },

  {
    id: 2,
    textDropDownEn: "Approved",
  },

  {
    id: 3,
    textDropDownEn: "Denied",
  },
];
