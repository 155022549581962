import React from "react";

function BlockMiddleLayout() {
  const sections = [
    // { label: "GATE", height: "10%" },
    // { label: "ENTRY AREA", height: "30%" },
    { label: "SOFA AREA", height: "50%" },
    { label: "DINING AREA", height: "50%" },
  ];

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        border: "1px solid black",
      }}
    >
      {sections.map((section, index) => (
        <div
          key={index}
          style={{
            backgroundColor: "#fdebdd",
            height: section.height,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderBottom:
              index < sections.length - 1 ? "1px solid black" : "none",
          }}
        >
          <span style={{ fontWeight: "bold" }}>{section.label}</span>
        </div>
      ))}
    </div>
  );
}

export default BlockMiddleLayout;
