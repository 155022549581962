import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Modal,
} from "@mui/material";
import ClientProfilTab from "./ClientProfilTab";
import { db, storage } from "../firebase";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import AddClientInClient from "./forms/AddClientInClient";
import { format } from "date-fns";
import { readAndCompressImage } from "browser-image-resizer";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { IconButton } from "@mui/joy";
import { Edit as EditIcon } from "@mui/icons-material";
import DropDownComponent from "./DropDownComponent";
import DropDownComponentSorted from "./DropDownComponentSorted";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DatePickerComponent from "./DatePickerComponent";
import Joyride, { ACTIONS, STATUS } from "react-joyride";
import { LanguageContext } from "../context/LanguageContext";
import MainAddictionRibonMobile from "./MainAddictionRibonMobile";
import InformationRibonMobile from "./InformationRibonMobile";
import StatusRibonMobile from "./StatusRibonMobile";
import CheckRibonMobile from "./CheckRibonMobile";
import * as XLSX from "xlsx";

export default function ClientProfile(props) {
  const { translate } = useContext(LanguageContext);
  const navigate = useNavigate();
  const imageConfig = {
    quality: 0.9,
    maxWidth: 200,
    autoRotate: true,
    debug: true,
  };

  const styleModalGeneral = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 750,
    height: 600,
    borderRadius: 5,
    backgroundColor: "white",
    boxShadow: 24,
  };

  const customStyles = {
    options: {
      zIndex: 10000,
    },

    tooltip: {
      backgroundColor: "#fff",
      color: "#000",
      animation: "fadeIn 0.3s ease-in-out",
    },

    tooltipContent: {
      textAlign: "justify",
      marginTop: "10px",
    },
    beacon: {
      offsetX: -50,
      offsetY: 0,

      inner: {
        content: "",
        width: 30,
        height: 30,
        background: 'url("/path-to-your-icon.svg") no-repeat center center',
        backgroundSize: "contain",
        backgroundColor: "blue",
      },
    },
    buttonNext: {
      backgroundColor: "#1565c0",
      color: "#fff",
    },
    buttonBack: {
      color: "blue",
    },
    beaconInner: {
      backgroundColor: "#1565c0", // Couleur du cercle intérieur
      borderColor: "#1565c0",
    },
    beaconOuter: {
      backgroundColor: "rgba(21, 101, 192, 0.5)", // Couleur de l'animation concentrique
      borderColor: "rgba(21, 101, 192, 0.5)",
    },
  };

  const handleBackClick = () => {
    navigate("/", {
      state: { selectedTab: clientData.clientStatus },
    });
  };

  const [, setUploadingImage] = useState(false);
  const [openAddClient, setOpenAddClient] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [isLoadingUpdateStatus, setIsLoadingUpdateStatus] = useState(false);
  const [openPrimaryAddictionModal, setOpenPrimaryAddictionModal] =
    useState(false);
  const [isLoadingUpdateAddiction, setIsLoadingUpdateAddiction] =
    useState(false);
  const [clientData, setClientData] = useState(null);
  const [newClientStatus, setNewClientStatus] = useState("");
  const [newClientAddiction, setNewClientAddiction] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalPhoto, setOpenModalPhoto] = React.useState(false);
  const [openAdmissionDateModal, setOpenAdmissionDateModal] = useState(false);
  const [openDurationModal, setOpenDurationModal] = useState(false);
  const handleOpenAdmissionDateModal = () => setOpenAdmissionDateModal(true);
  const handleCloseAdmissionDateModal = () => setOpenAdmissionDateModal(false);
  const handleOpenDurationModal = () => setOpenDurationModal(true);
  const handleCloseDurationModal = () => setOpenDurationModal(false);
  const [isLoadingUpdateAdmissionDate, setIsLoadingUpdateAdmissionDate] =
    useState(false);
  const [isLoadingUpdateDurationOfStay, setIsLoadingUpdateDurationOfStay] =
    useState(false);

  // const [newAdmissionDate, setNewAdmissionDate] = useState(
  //   clientData?.dateOfAdmission?.toDate().toISOString().split("T")[0] || ""
  // );

  const [newAdmissionDate, setNewAdmissionDate] = useState(
    clientData?.dateOfAdmission?.seconds
      ? clientData.dateOfAdmission.toDate().toISOString().split("T")[0]
      : clientData?.dateOfAdmission || ""
  );

  const [newDurationOfStay, setNewDurationOfStay] = useState(
    clientData?.durationOfStay || ""
  );

  const handleUpdateAdmissionDate = async () => {
    setIsLoadingUpdateAdmissionDate(true);
    const clientRef = doc(db, "clients", props.clientId);
    await updateDoc(clientRef, { dateOfAdmission: newAdmissionDate });
    setIsLoadingUpdateAdmissionDate(false);
    handleCloseAdmissionDateModal();
  };

  const handleUpdateDurationOfStay = async () => {
    setIsLoadingUpdateDurationOfStay(true);
    const clientRef = doc(db, "clients", props.clientId);
    await updateDoc(clientRef, { durationOfStay: newDurationOfStay });
    setIsLoadingUpdateDurationOfStay(false);
    handleCloseDurationModal();
  };

  const [run, setRun] = useState(false);

  const [showHelpButton, setShowHelpButton] = useState(true);

  const handleCloseAddClient = () => setOpenAddClient(false);

  const handleOpenModalPhoto = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseModalPhoto = () => {
    setOpenModal(false);
  };

  const handleOpenAddictionModal = () => {
    setOpenPrimaryAddictionModal(true);
  };

  const handleCloseAddictionModal = () => {
    setOpenPrimaryAddictionModal(false);
  };

  const handleOpenStatusModal = () => {
    setOpenStatusModal(true);
  };

  const handleCloseStatusModal = () => {
    setOpenStatusModal(false);
  };

  const handleClientStatusChange = (newValue) => {
    setNewClientStatus(newValue);
  };

  const handleClientAddictionChange = (newValue) => {
    setNewClientAddiction(newValue);
  };

  const handleUpdateClientStatus = async () => {
    setIsLoadingUpdateStatus(true);
    const clientRef = doc(db, "clients", props.clientId);
    await updateDoc(clientRef, { clientStatus: newClientStatus });
    setIsLoadingUpdateStatus(false);
    handleCloseStatusModal();
  };

  const handleUpdatePrimaryAddiction = async () => {
    setIsLoadingUpdateAddiction(true);
    const clientRef = doc(db, "clients", props.clientId);
    await updateDoc(clientRef, { primaryAddiction: newClientAddiction });
    setIsLoadingUpdateAddiction(false);
    handleCloseAddictionModal();
  };

  function formatDate(timestamp) {
    if (!timestamp || !timestamp.seconds) return timestamp || "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    return format(date, "d MMMM yyyy");
  }

  function formatDate3(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleDateString("en-EN");
  }

  const handleProfilePictureChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const resizedImage = await readAndCompressImage(file, imageConfig);
        setUploadingImage(true);
        const storageRef = ref(storage, `profilePics/${clientData.id}`);
        await uploadBytes(storageRef, resizedImage);
        const profilePictureURL = await getDownloadURL(storageRef);
        await updateDoc(doc(db, "clients", clientData.id), {
          profilePicture: profilePictureURL,
        });
        setUploadingImage(false);
      } catch (error) {
        console.error("Error resizing and uploading image:", error);
        setUploadingImage(false);
      }
    }
  };

  const EXCEL_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const handleExportAllData = async () => {
    try {
      const clientRef = doc(db, "clients", props.clientId);
      const clientSnapshot = await getDoc(clientRef);
      const clientData = { id: clientSnapshot.id, ...clientSnapshot.data() };
      const preAdmissionRef = doc(
        db,
        "preAdmissionAssessments",
        props.clientId
      );
      const preAdmissionSnapshot = await getDoc(preAdmissionRef);
      const preAdmissionData = preAdmissionSnapshot.exists()
        ? preAdmissionSnapshot.data()
        : {};
      const formattedPreAdmissionData = Object.entries(preAdmissionData);
      formattedPreAdmissionData.unshift(["Field", "Value"]);

      const formattedClientData = {
        ...clientData,
        dateOfAdmission: clientData.dateOfAdmission
          ? formatDate3(clientData.dateOfAdmission)
          : "",
        // Ajoutez ici la conversion pour d'autres champs de date si nécessaire
      };

      const notesQuery = query(
        collection(db, "notes"),
        where("clientId", "==", props.clientId)
      );
      const notesSnapshot = await getDocs(notesQuery);
      const notesData = notesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const formattedNotesData = notesData.map((note) => ({
        ...note,
        createdAt: note.createdAt ? formatDate3(note.createdAt) : "",
        updatedAt: note.updatedAt ? formatDate3(note.updatedAt) : "",
        effectiveDateTime: note.effectiveDateTime
          ? formatDate3(note.effectiveDateTime)
          : "",
      }));

      const workbook = XLSX.utils.book_new();

      const clientSheetData = Object.entries(formattedClientData);
      clientSheetData.unshift(["Field", "Value"]);

      const maxLengths = [0, 0];
      clientSheetData.forEach((row) => {
        row.forEach((cell, idx) => {
          const cellLength = cell ? cell.toString().length : 0;
          if (cellLength > maxLengths[idx]) {
            maxLengths[idx] = cellLength;
          }
        });
      });

      const clientSheet = XLSX.utils.aoa_to_sheet(clientSheetData);
      clientSheet["!cols"] = [
        { wch: maxLengths[0] + 5 },
        { wch: maxLengths[1] + 5 },
      ];

      XLSX.utils.book_append_sheet(workbook, clientSheet, "Client");

      const notesSheet = XLSX.utils.json_to_sheet(formattedNotesData);

      const colors = ["#d0e7f9", "#e8e8e8"]; // Bleu clair et gris clair

      Object.keys(formattedNotesData[0]).forEach((_, idx) => {
        const fillColor = colors[idx % 2];
        formattedNotesData.forEach((row, rowIndex) => {
          const cellAddress = XLSX.utils.encode_cell({
            c: idx,
            r: rowIndex + 1,
          }); // Décale de 1 pour ignorer les en-têtes
          if (!notesSheet[cellAddress]) notesSheet[cellAddress] = {};
          notesSheet[cellAddress].s = {
            fill: {
              fgColor: { rgb: fillColor.replace("#", "") },
            },
          };
        });
      });

      notesSheet["!cols"] = Array(
        Object.keys(formattedNotesData[0]).length
      ).fill({ wch: 10 });

      XLSX.utils.book_append_sheet(workbook, notesSheet, "Notes");

      // Ajouter l'onglet "PreAdmission"
      const preAdmissionSheet = XLSX.utils.aoa_to_sheet(
        formattedPreAdmissionData
      );
      preAdmissionSheet["!cols"] = [{ wch: 20 }, { wch: 30 }];
      XLSX.utils.book_append_sheet(workbook, preAdmissionSheet, "PreAdmission");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const data = new Blob([excelBuffer], { type: EXCEL_TYPE });
      const filename = `${clientData.firstName}_${clientData.lastName}_data.xlsx`;

      const url = window.URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Erreur lors de l'exportation des données :", error);
    }
  };

  const handleExportClientData = async () => {
    try {
      // Récupérer les données du client
      const clientRef = doc(db, "clients", props.clientId);
      const clientSnapshot = await getDoc(clientRef);
      const clientData = { id: clientSnapshot.id, ...clientSnapshot.data() };

      // Convertir les timestamps en dates lisibles (si nécessaire)
      const formattedClientData = {
        ...clientData,
        dateOfAdmission: clientData.dateOfAdmission
          ? formatDate3(clientData.dateOfAdmission)
          : "",
        // Ajoutez ici la conversion pour d'autres champs de date si nécessaire
      };

      // Créer un nouveau classeur Excel
      const workbook = XLSX.utils.book_new();

      // Ajouter les données du client dans une feuille
      const clientSheetData = [formattedClientData];
      const clientSheet = XLSX.utils.json_to_sheet(clientSheetData);
      XLSX.utils.book_append_sheet(workbook, clientSheet, "Client");

      // Générer le fichier Excel et le déclencher en téléchargement
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const data = new Blob([excelBuffer], { type: EXCEL_TYPE });
      const filename = `${clientData.firstName}_${clientData.lastName}_client_data.xlsx`;

      // Créer un lien de téléchargement
      const url = window.URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error(
        "Erreur lors de l'exportation des données du client :",
        error
      );
    }
  };

  const handleExportNotesData = async () => {
    try {
      // Récupérer les notes du client
      const notesQuery = query(
        collection(db, "notes"),
        where("clientId", "==", props.clientId)
      );
      const notesSnapshot = await getDocs(notesQuery);
      const notesData = notesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Convertir les timestamps en dates lisibles (si nécessaire)
      const formattedNotesData = notesData.map((note) => ({
        ...note,
        date: note.date ? formatDate3(note.date) : "",
        // Ajoutez ici la conversion pour d'autres champs de date si nécessaire
      }));

      // Créer un nouveau classeur Excel
      const workbook = XLSX.utils.book_new();

      // Ajouter les notes dans une feuille
      const notesSheet = XLSX.utils.json_to_sheet(formattedNotesData);
      XLSX.utils.book_append_sheet(workbook, notesSheet, "Notes");

      // Générer le fichier Excel et le déclencher en téléchargement
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const data = new Blob([excelBuffer], { type: EXCEL_TYPE });
      const filename = `${clientData.firstName}_${clientData.lastName}_notes.xlsx`;

      // Créer un lien de téléchargement
      const url = window.URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Erreur lors de l'exportation des notes :", error);
    }
  };

  useEffect(() => {
    if (props.clientId) {
      const clientRef = doc(db, "clients", props.clientId);
      const unsubscribe = onSnapshot(clientRef, (snapshot) => {
        const clientData = { id: snapshot.id, ...snapshot.data() };
        setClientData(clientData);
      });

      return () => {
        unsubscribe();
      };
    }
  }, [props.clientId]);

  useEffect(() => {
    if (clientData) {
      setNewClientStatus(clientData.clientStatus);
      setNewClientAddiction(clientData.primaryAddiction);
    }
  }, [clientData]);

  const handleHelpClick = () => {
    setRun(true);
    setShowHelpButton(false); // Cache le bouton "HELP" après le clic
  };

  const [steps, setSteps] = useState([
    {
      target: "#search-bar",
      content:
        "This is the search bar. Use this to search for clients. By typing just a few letters of the client's first or last name, client suggestions will appear.",
    },
    {
      target: "#status-ribon",
      content:
        "This is the client status. Click here to change the status by selecting an option from the dropdown menu. The client status indicates whether the client is in-house, incoming, in aftercare, etc.",
    },
    {
      target: "#primary-addiction-ribon",
      content:
        "This is the primary addiction. Click here to change the primary addiction by selecting an option from the dropdown menu. The primary addiction refers to the main substance or behavior the client is struggling with.",
    },
    {
      target: "#admission-date-ribon",
      content:
        "This is the admission date. Click here to change the admission date by selecting a date from the calendar. The admission date is the date when the client was admitted to the facility.",
    },
    {
      target: "#duration-of-stay-ribon",
      content:
        "This is the intended duration of stay. Click here to change the intended duration by selecting an option from the dropdown menu. The intended duration of stay refers to the planned length of the client's stay at the facility.",
    },
  ]);

  const handleJoyrideCallback = (data) => {
    const { status, action } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      setShowHelpButton(true);
    } else if ([ACTIONS.CLOSE].includes(action)) {
      setRun(false);
      setShowHelpButton(true);
    }
  };

  if (!clientData) {
    return (
      <div className="client-profil-sub-container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div
      className="client-profil-sub-container"
      style={{
        height: "100vh",
        position: "relative",
      }}
    >
      <Joyride
        steps={steps}
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
        run={run}
        callback={handleJoyrideCallback}
        disableScrolling={true}
        disableBeacon={true}
        styles={customStyles}
      />

      <Modal
        open={openAdmissionDateModal}
        onClose={handleCloseAdmissionDateModal}
        aria-labelledby="admission-date-modal-title"
        aria-describedby="admission-date-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            height: 220,
            borderRadius: 5,
            backgroundColor: "white",
            boxShadow: 24,
          }}
        >
          <div className="status-modal-title">Change Admission Date</div>
          <div className="container-small-modal">
            <DatePickerComponent
              title="Admission Date"
              value={clientData.dateOfAdmission}
              onChange={(date) => setNewAdmissionDate(date)}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "10px",
              }}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  marginRight: 10,
                }}
                onClick={handleCloseAdmissionDateModal}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateAdmissionDate}
                disabled={isLoadingUpdateAdmissionDate}
              >
                {isLoadingUpdateAdmissionDate ? "Updating..." : "Update"}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openDurationModal}
        onClose={handleCloseDurationModal}
        aria-labelledby="duration-modal-title"
        aria-describedby="duration-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            height: 220,
            borderRadius: 5,
            backgroundColor: "white",
            boxShadow: 24,
          }}
        >
          <div className="status-modal-title">
            Change Intended Duration of Stay
          </div>
          <div className="container-small-modal">
            <DropDownComponentSorted
              api="durationOfStay"
              value={newDurationOfStay}
              onChange={(value) => setNewDurationOfStay(value)}
              title="Intended Duration of Stay"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "10px",
              }}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  marginRight: 10,
                }}
                onClick={handleCloseDurationModal}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateDurationOfStay}
                disabled={isLoadingUpdateDurationOfStay}
              >
                {isLoadingUpdateDurationOfStay ? "Updating..." : "Update"}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openAddClient}
        onClose={handleCloseAddClient}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalGeneral}>
          <AddClientInClient closemodal={handleCloseAddClient} />
        </Box>
      </Modal>

      <Modal
        open={openStatusModal}
        onClose={handleCloseStatusModal}
        aria-labelledby="status-modal-title"
        aria-describedby="status-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            height: 220,
            borderRadius: 5,
            backgroundColor: "white",
            boxShadow: 24,
          }}
        >
          <div className="status-modal-title">Change Client Status</div>
          <div className="container-small-modal">
            <DropDownComponentSorted
              api="clientStatus"
              value={newClientStatus}
              onChange={handleClientStatusChange}
              title="Client Status"
            />

            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  marginRight: 10,
                }}
                onClick={handleCloseStatusModal}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateClientStatus}
                disabled={isLoadingUpdateStatus}
              >
                {isLoadingUpdateStatus ? "Updating..." : "Update"}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openPrimaryAddictionModal}
        onClose={handleCloseAddictionModal}
        aria-labelledby="status-modal-title"
        aria-describedby="status-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            height: 220,
            borderRadius: 5,
            backgroundColor: "white",
            boxShadow: 24,
          }}
        >
          <div className="status-modal-title">Change Primary Addiction</div>
          <div className="container-small-modal">
            <DropDownComponent
              api="addictions"
              value={newClientAddiction}
              onChange={handleClientAddictionChange}
              title="Primary Addiction"
            />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  marginRight: 10,
                }}
                onClick={handleCloseAddictionModal}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdatePrimaryAddiction}
                disabled={isLoadingUpdateAddiction}
              >
                {isLoadingUpdateAddiction ? "Updating..." : "Update"}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openModalPhoto}
        onClose={handleCloseModalPhoto}
        aria-labelledby="modal-image"
        aria-describedby="image-full-size"
      >
        <div
          onClick={handleCloseModalPhoto}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          }}
        >
          <img
            src={props.profilePic}
            alt="Full-size profile picture"
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              objectFit: "contain",
            }}
          />
        </div>
      </Modal>

      <div className="top-component-list-client-bis">
        <div className="line1-title-client-profile">
          <div className="line1-sub-client-profile">
            <div className="block1" style={{ width: "100%" }}>
              <div
                className="profile-client-pic-container"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent={
                      <IconButton
                        aria-label="upload picture"
                        component="span"
                        style={{
                          backgroundColor: "#1565c0",
                          color: "white",
                          width: "20px",
                          height: "20px",
                          borderRadius: "50%",
                          padding: "0px",
                        }} // Changez la couleur d'arrière-plan au besoin
                        onClick={() =>
                          document
                            .getElementById("contained-button-file")
                            .click()
                        }
                      >
                        <EditIcon style={{ fontSize: "20px" }} />
                      </IconButton>
                    }
                  >
                    <IconButton
                      onClick={handleBackClick}
                      style={{ marginRight: "8px" }}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={handleOpenModalPhoto}
                    >
                      <Avatar
                        src={clientData.profilePicture}
                        style={{
                          width: "72px",
                          height: "72px",
                          borderRadius: "8px",
                        }}
                        variant="square"
                      />
                    </div>
                  </Badge>
                  <input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleProfilePictureChange}
                  />
                </div>
                <div style={{ marginTop: "10px", marginLeft: "0px" }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleExportAllData}
                    sx={{ fontSize: "10px" }}
                  >
                    Export Client Datas
                  </Button>
                </div>
              </div>

              <div
                // className="profile-client-name-container"

                style={{ width: "50%" }}
              >
                <div className="profile-client-name">
                  <span style={{ fontSize: "22px", fontWeight: "700" }}>
                    {clientData.firstName} {clientData.lastName}{" "}
                  </span>
                  <span style={{ fontSize: "22px", fontWeight: "400" }}>
                    {clientData.age && clientData.age !== "NA"
                      ? ` - ${clientData.age} ${translate("YearsOld")}`
                      : ""}{" "}
                  </span>
                  <span style={{ fontSize: "22px", fontWeight: "500" }}>
                    {clientData.gender && clientData.gender !== "NA"
                      ? ` - ${clientData.gender}`
                      : ""}
                  </span>
                  <span style={{ fontSize: "22px", fontWeight: "500" }}>
                    {clientData.clientIdentifier ? (
                      <span>
                        {/* {" - Client identifier: "} */}
                        {" - "}
                        <span style={{ fontWeight: 400 }}>
                          {clientData.clientIdentifier}
                        </span>
                      </span>
                    ) : (
                      translate("NoClientIdentifier")
                    )}
                  </span>
                  {/* {clientData.firstName} {clientData.lastName} -{" "}
                  {clientData.age} years old - {clientData.gender}{" "} */}
                </div>
                <div
                  // className="profile-client-subtext"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  {clientData.selectedTherapist ? (
                    <>
                      <span
                        style={{
                          fontSize: "20px",
                          fontWeight: "400",
                          paddingLeft: "20px",
                        }}
                      >
                        {" "}
                        {translate("AssignedTherapist")}{" "}
                      </span>

                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "20px",
                          paddingLeft: "10px",
                        }}
                      >
                        {" "}
                        {clientData.selectedTherapist}
                      </span>
                    </>
                  ) : (
                    "No therapist assigned yet"
                  )}
                </div>
              </div>

              <div
                style={{
                  width: "40%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                <div
                  id="status-ribon"
                  // className="status-ribon"
                  style={{ marginBottom: "0px", marginTop: "0px" }}
                >
                  <StatusRibonMobile
                    status={clientData.clientStatus}
                    onClick={handleOpenStatusModal}
                  />
                </div>
                {/* <Button
                  variant="contained"
                  color="primary"
                  onClick={handleExportClientData}
                >
                  Exporter les données du client en Excel
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleExportNotesData}
                >
                  Exporter les notes en Excel
                </Button> */}

                <div
                  id="primary-addiction-ribon"
                  // className="main-addiction-ribon"
                  style={{ marginTop: "0px" }}
                >
                  <MainAddictionRibonMobile
                    className="main-addiction-ribon"
                    mainAddiction={clientData.primaryAddiction}
                    addictionOther={clientData.addictionOther}
                    onClick={handleOpenAddictionModal}
                  />
                </div>

                <div style={{ paddingLeft: "0px" }}>
                  {clientData.AggressionControlPolicyOk === true &&
                  clientData.ClientRightsOk === true &&
                  clientData.ConfidentialityPolicysOk === true &&
                  clientData.GeneralLiabilityOk === true &&
                  clientData.InformedConsentOk === true &&
                  clientData.PsychiatristServicesOk === true ? (
                    <CheckRibonMobile
                      check={true}
                      labelContent={translate("MandatoryForms")}
                    />
                  ) : (
                    <CheckRibonMobile
                      labelContent={translate("MandatoryForms")}
                    />
                  )}
                </div>

                <div id="admission-date-ribon" style={{ marginLeft: "0px" }}>
                  <InformationRibonMobile
                    infoLabel={translate("AdmissionDate")}
                    labelContent={
                      clientData.dateOfAdmission
                        ? formatDate(clientData.dateOfAdmission)
                        : translate("NoAdmissionDateYet")
                    }
                    onClick={handleOpenAdmissionDateModal}
                  />
                </div>
                <div
                  id="duration-of-stay-ribon"
                  // className="duration-of-stay-ribon"
                  style={{ paddingLeft: "0px" }}
                >
                  <InformationRibonMobile
                    infoLabel={translate("IntendedDurationOfStay")}
                    labelContent={
                      clientData.durationOfStay
                        ? clientData.durationOfStay
                        : translate("NoDurationOfStayYet")
                    }
                    onClick={handleOpenDurationModal}
                  />
                </div>
              </div>
            </div>
            <div className="block2">
              <div
                style={{
                  position: "absolute",
                  top: "20px",
                  left: "20px",
                  zIndex: 10001,
                }}
              ></div>

              <div className="client-profil-add-client-button"></div>
            </div>
          </div>
        </div>

        <div className="tab-content-bis">
          <ClientProfilTab
            clientId={clientData.id}
            selectedTab={props.selectedTab}
            {...clientData}
          />
        </div>
      </div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-image"
        aria-describedby="image-full-size"
      >
        <div
          onClick={handleCloseModal}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          }}
        >
          <img
            src={clientData.profilePicture}
            alt=""
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              objectFit: "contain",
            }}
          />
        </div>
      </Modal>
    </div>
  );
}
