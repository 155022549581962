import React, { useState } from "react";
import { Button, Checkbox, Switch } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import SignaturePadComponent from "../Signature/SignaturePadComponent";
import TopTitleFormComponent from "./TopTitleFormComponent";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { updateDoc, doc as firestoreDoc } from "firebase/firestore";
import { db } from "../../firebase";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadString,
} from "firebase/storage";
import { showCustomToast } from "../Misc/CustomToast";

function AddContinuatedTherapySupport(props) {
  //Tous les states des données récupérées par client
  const { clientId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [signatureDataURL, setSignatureDataURL] = useState(null);
  const [isSignatureStarted, setIsSignatureStarted] = useState(false);
  const [isSignatureValidated, setIsSignatureValidated] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checkedExtra8, setCheckedExtra8] = useState(false);
  const [checkedExtra9, setCheckedExtra9] = useState(false);
  const [checkedExtra10, setCheckedExtra10] = useState(false);
  const [checked8, setChecked8] = useState(false);
  const [checked9, setChecked9] = useState(false);
  const [checked10, setChecked10] = useState(false);
  const [checked11, setChecked11] = useState(false);
  const [checked12, setChecked12] = useState(false);
  const [checked13, setChecked13] = useState(false);
  const [checked14, setChecked14] = useState(false);
  const [checked15, setChecked15] = useState(false);
  const [checked16, setChecked16] = useState(false);
  const [sessionCount, setSessionCount] = useState("6 sessions"); // Valeur par défaut

  const [text0, setText0] = useState(
    "Number of block Sessions in Package (please circle one):"
  );
  const [text1, setText1] = useState(
    "All professionals delivering Continuing Therapeutic Support will be a member of an appropriate professional body."
  );
  const [text2, setText2] = useState(
    "All professionals delivering CTS work will have appropriate insurance cover."
  );
  const [text3, setText3] = useState(
    "All professionals delivering CTS work will operate within their registered bodies ethical framework."
  );
  const [text4, setText4] = useState(
    "All sessions and professionals are bound by confidentiality. However, should your CTS professional have concern that you or someone else is at risk, confidentiality may in this instance be breached. Should your professional consider this necessary, they will inform you first of the action they are taking in response to the identified risk."
  );
  const [text5, setText5] = useState(
    "At times your CTS professional may consider that additional group processing of identified levels of burden you are experiencing would be beneficial for you and recommend that you source peer-led support from your our rehab Aftercare Group. If this is discussed with you and deemed an appropriate support, you will be asked for consent to share certain information with the facilitator of the Aftercare Group pertaining to the nature of the identified level of burden. This is to enable the facilitator to encourage and guide you in being able to effectively source support from your peers in Group."
  );
  const [text6, setText6] = useState(
    "Continuing Therapeutic Support will take place at an agreed venue (inclusive of online platforms), will consist of a specified period of therapy / counselling, and will be contracted for (6) OR (10) sessions (depending on what you have chosen) to be carried out within an 8 week period (6 sessions) or within a 12 - week period (10 sessions) -- from the first session date."
  );
  const [text7, setText7] = useState(
    "Should the client have issues with the therapeutic relationship and request a different professional, our rehab will endeavour to assist you with your concern and if appropriate provide another counsellor. You should contact the Director directly with any difficult you want to raise to our manager"
  );

  const [textExtra8, setTextExtra8] = useState(
    "You must not be under the influence of alcohol or drugs when attending any therapy / counselling sessions.Your professional may discontinue with your session should it emerge that you are under the influence whilst engaging. Under such circumstances, you are not deemed to be able to effectively engage in and benefit from your therapy /counselling."
  );

  const [textExtra9, setTextExtra9] = useState(
    "Professionals delivering CTS must be treated with respect. Our rehab have a zero-tolerance policy towards verbal and physical abuse of therapists / counsellors / professionals undertaking such work."
  );

  const [textExtra10, setTextExtra10] = useState(
    "Should you wish to extend your CTS package once it ends, you can do so by contacting the Director of the site you were engaged with in primary treatment rehab@rehab.com"
  );

  const [text8, setText8] = useState(
    "Our rehab will strive to always work in your best interests and will do its utmost to be flexible around the rearrangement of appointments if this becomes necessary due to your changing circumstances. Equally, we hope that clients will understand that there are many necessary costs that a business such as ours must incur.Unreasonable requests for refunds when therapy has commenced, if agreed to without due consideration, would doubtless lead to our inability to continue operating. Because of this we have formulated the refund policy below and wish you to read carefully before progressing."
  );
  const [text9, setText9] = useState(
    "Continuing Therapeutic Support therapy or counselling will take place at an agreed venue and will consist of 6 contracted sessions of therapy / counselling."
  );
  const [text10, setText10] = useState(
    "If therapy / counselling has been paid but not yet commenced, and you wish to cancel the therapy / counselling, then a full refund will be payable if cancellation occurs within 7 working days of making the initial payment."
  );
  const [text11, setText11] = useState(
    "If a client cancels after 7 working days, and you have not yet commenced therapy / counselling clients receive a full refund minus our costs such as administration expenses and any card payment processing charge."
  );

  const [text12, setText12] = useState(
    "The arrangements for payment are that clients have paid by credit/debit card or bacs transfer when booking the aftercare therapy package. Clients are required to attend the therapy / counselling session on the agreed date and time."
  );
  const [text13, setText13] = useState(
    "Our rehab’s Continuing Therapeutic Support package costs are inclusive of administration and marketing costs."
  );
  const [text14, setText14] = useState(
    "48 hours’ notice must be provided to the therapist / counsellor / professional regarding the cancellation of an appointment."
  );
  const [text15, setText15] = useState(
    "Failure to provide notice will result in the session being deducted from your package. Cancellations may be rebooked at a reduced rate."
  );
  const [text16, setText16] = useState(
    "Clients will not receive a refund should they disengage from therapy / counselling after the therapy / counselling process has begun."
  );

  const handleCheckboxChange1 = (event) => {
    setChecked1(event.target.checked);
  };

  const handleCheckboxChange2 = (event) => {
    setChecked2(event.target.checked);
  };

  const handleCheckboxChange3 = (event) => {
    setChecked3(event.target.checked);
  };

  const handleCheckboxChange4 = (event) => {
    setChecked4(event.target.checked);
  };

  const handleCheckboxChange5 = (event) => {
    setChecked5(event.target.checked);
  };

  const handleCheckboxChange6 = (event) => {
    setChecked6(event.target.checked);
  };

  const handleCheckboxChange7 = (event) => {
    setChecked7(event.target.checked);
  };

  const handleCheckboxChangeExtra8 = (event) => {
    setCheckedExtra8(event.target.checked);
  };

  const handleCheckboxChangeExtra9 = (event) => {
    setCheckedExtra9(event.target.checked);
  };

  const handleCheckboxChangeExtra10 = (event) => {
    setCheckedExtra10(event.target.checked);
  };

  const handleCheckboxChange8 = (event) => {
    setChecked8(event.target.checked);
  };

  const handleCheckboxChange9 = (event) => {
    setChecked9(event.target.checked);
  };

  const handleCheckboxChange10 = (event) => {
    setChecked10(event.target.checked);
  };

  const handleCheckboxChange11 = (event) => {
    setChecked11(event.target.checked);
  };

  const handleCheckboxChange12 = (event) => {
    setChecked12(event.target.checked);
  };

  const handleCheckboxChange13 = (event) => {
    setChecked13(event.target.checked);
  };

  const handleCheckboxChange14 = (event) => {
    setChecked14(event.target.checked);
  };

  const handleCheckboxChange15 = (event) => {
    setChecked15(event.target.checked);
  };

  const handleCheckboxChange16 = (event) => {
    setChecked16(event.target.checked);
  };

  const handleSignatureSave = (dataURL) => {
    setSignatureDataURL(dataURL);
  };

  const uploadSignature = async () => {
    const storage = getStorage();
    const fileName = `${clientId}.png`;
    const signatureRef = ref(
      storage,
      `MandatoryForms/ContinuatedTherapySupport/${fileName}`
    );

    await uploadString(signatureRef, signatureDataURL, "data_url");
    const downloadURL = await getDownloadURL(signatureRef);

    return downloadURL;
  };

  const handleFormSubmit = async () => {
    if (
      !checked1 ||
      !checked2 ||
      !checked3 ||
      !checked4 ||
      !checked5 ||
      !checked6 ||
      !checked7 ||
      !checkedExtra8 ||
      !checkedExtra9 ||
      !checkedExtra10 ||
      !checked8 ||
      !checked9 ||
      !checked10 ||
      !checked11 ||
      !checked12 ||
      !checked13 ||
      !checked14 ||
      !checked15 ||
      !checked16 ||
      !signatureDataURL ||
      !isSignatureValidated
    ) {
      showCustomToast(
        "Please agree to all the terms before submitting.",
        "error"
      );
    }

    try {
      setIsLoading(true);
      const signatureURL = await uploadSignature();
      const docRef = doc(db, "ContinuatedTherapySupport", props.clientId);
      await setDoc(docRef, {
        checked1,
        checked2,
        checked3,
        checked4,
        checked5,
        checked6,
        checked7,
        checkedExtra8,
        checkedExtra9,
        checkedExtra10,
        checked8,
        checked9,
        checked10,
        checked11,
        checked12,
        checked13,
        checked14,
        checked15,
        checked16,
        text0,
        text1,
        text2,
        text3,
        text4,
        text5,
        text6,
        text7,
        textExtra8,
        textExtra9,
        textExtra10,
        text8,
        text9,
        text10,
        text11,
        text12,
        text13,
        text14,
        text15,
        text16,
        signatureURL,
        signedOn: serverTimestamp(),
        sessionCount,
      });

      const clientDocRef = firestoreDoc(db, "clients", props.clientId);
      await updateDoc(clientDocRef, {
        ContinuatedTherapySupportOk: true,
        ContinuatedTherapySupportDateSigned: serverTimestamp(),
      });
      showCustomToast("Client rights form correctly submitted!", "success");

      setIsLoading(false);
      props.closemodal();
    } catch (e) {
      showCustomToast("Error adding document", "error");

      console.error("Error adding document: ", e);
    }
  };

  const allChecked =
    checked1 &&
    checked2 &&
    checked3 &&
    checked4 &&
    checked5 &&
    checked6 &&
    checked7 &&
    checkedExtra8 &&
    checkedExtra9 &&
    checkedExtra10 &&
    checked8 &&
    checked9 &&
    checked10 &&
    checked11 &&
    checked12 &&
    checked13 &&
    checked14 &&
    checked15 &&
    checked16;
  return (
    <>
      <div style={{ width: "auto", height: "100%" }}>
        <div className="line1-modal-percent">
          <TopTitleFormComponent
            title="CONTINUING THERAPEUTIC SUPPORT (CTS) FORM"
            closemodal={props.closemodal}
          />
        </div>

        {/* <br /> */}

        <div
          style={{
            width: "100%",
            marginTop: "10px",
            height: "75%",
            overflowY: "scroll",
            // overflowX: "hidden",
          }}
        >
          <div className="SubTitleBold">{text0}</div>

          {/* <div className="checkbox-container-margin">
            <Switch
              checked={sessionCount === "10 sessions"}
              onChange={(event) =>
                setSessionCount(
                  event.target.checked ? "10 sessions" : "6 sessions"
                )
              }
              name="sessionCountSwitch"
            />
            <div className="block-text-check">
              Number of Sessions in Package: {sessionCount}
            </div>
          </div> */}

          <div className="checkbox-container-margin">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "45px",
              }}
            >
              <div className="block-text-check">
                Number of Sessions in Package:{" "}
              </div>
              <div
                style={{
                  flexGrow: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginLeft: 10,
                }}
              >
                <div
                  style={{
                    fontWeight:
                      sessionCount === "6 sessions" ? "bold" : "normal",
                    color: sessionCount === "6 sessions" ? "#1565C0" : "black",
                  }}
                >
                  6 Sessions
                </div>
                <Switch
                  checked={sessionCount === "10 sessions"}
                  onChange={(event) =>
                    setSessionCount(
                      event.target.checked ? "10 sessions" : "6 sessions"
                    )
                  }
                  name="sessionCountSwitch"
                />
                <div
                  style={{
                    fontWeight:
                      sessionCount === "10 sessions" ? "bold" : "normal",
                    color: sessionCount === "10 sessions" ? "#1565C0" : "black",
                  }}
                >
                  10 Sessions
                </div>
              </div>
            </div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked1} onChange={handleCheckboxChange1} />
            <div className="block-text-check">{text1}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked2} onChange={handleCheckboxChange2} />
            <div className="block-text-check">{text2}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked3} onChange={handleCheckboxChange3} />
            <div className="block-text-check">{text3}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked4} onChange={handleCheckboxChange4} />
            <div className="block-text-check">{text4}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked5} onChange={handleCheckboxChange5} />
            <div className="block-text-check">{text5}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked6} onChange={handleCheckboxChange6} />
            <div className="block-text-check">{text6}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked7} onChange={handleCheckboxChange7} />
            <div className="block-text-check">{text7}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox
              checked={checkedExtra8}
              onChange={handleCheckboxChangeExtra8}
            />
            <div className="block-text-check">{textExtra8}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox
              checked={checkedExtra9}
              onChange={handleCheckboxChangeExtra9}
            />
            <div className="block-text-check">{textExtra9}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox
              checked={checkedExtra10}
              onChange={handleCheckboxChangeExtra10}
            />
            <div className="block-text-check">{textExtra10}</div>
          </div>

          <div className="SubTitleBold">Refund Policy:</div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked8} onChange={handleCheckboxChange8} />
            <div className="block-text-check">{text8}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked9} onChange={handleCheckboxChange9} />
            <div className="block-text-check">{text9}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked10} onChange={handleCheckboxChange10} />
            <div className="block-text-check">{text10}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked11} onChange={handleCheckboxChange11} />
            <div className="block-text-check">{text11}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked12} onChange={handleCheckboxChange12} />
            <div className="block-text-check">{text12}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked13} onChange={handleCheckboxChange13} />
            <div className="block-text-check">{text13}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked14} onChange={handleCheckboxChange14} />
            <div className="block-text-check">{text14}</div>
          </div>
          <div className="checkbox-container-margin">
            <Checkbox checked={checked15} onChange={handleCheckboxChange15} />
            <div className="block-text-check">{text15}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked16} onChange={handleCheckboxChange16} />
            <div className="block-text-check">{text16}</div>
          </div>

          <SignaturePadComponent
            onSignatureSave={handleSignatureSave}
            signatureDataURL={signatureDataURL}
            onSignatureStart={() => setIsSignatureStarted(true)}
            onSignatureValidate={setIsSignatureValidated}
          />
        </div>
        <div className="line4-modal-percent">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
              onClick={props.closemodal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleFormSubmit}
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : !signatureDataURL || !isSignatureValidated || !allChecked
                  ? {
                      backgroundColor: "grey",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                    }
              }
              disabled={
                isLoading ||
                !signatureDataURL ||
                !allChecked ||
                !isSignatureValidated
              }
            >
              {isLoading ? "SAVING..." : "SAVE"}
            </Button>
          </>
        </div>
      </div>
    </>
  );
}

export default AddContinuatedTherapySupport;
