import React, { useState } from "react";
import { Avatar, Button } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { readAndCompressImage } from "browser-image-resizer";
import DropDownComponent from "../DropDownComponent";
import InputComponent from "../InputComponent";
import PhoneNumberInput from "../PhoneNumberInput";
import TextFieldComponentLarge from "../TextFieldComponentLarge";
import DatePickerComponent from "../DatePickerComponent";
import TopTitleFormComponent from "./TopTitleFormComponent";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { toast } from "react-toastify";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";
import DropDownComponentSorted from "../DropDownComponentSorted";

function AddClientInClient(props) {
  const imageConfig = {
    quality: 0.9,
    maxWidth: 200,
    autoRotate: true,
    debug: true,
  };

  const { currentUser, logOut } = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nickname, setNickname] = useState("");
  const [clientStatus, setClientStatus] = useState("");
  const [gender, setGender] = useState("");
  const [sexualOrientation, setSexualOrientation] = useState("");
  const [personalEmail, setPersonalEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [dob, setDob] = useState(null);
  const [currentAddress, setCurrentAddress] = useState("");
  const [city, setCity] = useState("");
  const [nationality, setNationality] = useState("");
  const [country, setCountry] = useState("");
  const [age, setAge] = useState("");

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const [profilePicture, setProfilePicture] = useState(null);
  const [previewImageUrl, setPreviewImageUrl] = useState(null);

  const handleProfilePictureChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const resizedImage = await readAndCompressImage(file, imageConfig);
        setProfilePicture(resizedImage);
        const previewUrl = URL.createObjectURL(resizedImage);
        setPreviewImageUrl(previewUrl);
      } catch (error) {
        console.error("Error resizing image:", error);
      }
    }
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event);
  };

  const handleLastNameChange = (event) => {
    setLastName(event);
  };

  const handleNicknameChange = (event) => {
    setNickname(event);
  };

  const handleGenderChange = (value) => {
    setGender(value);
  };

  const handleSexualOrientationChange = (value) => {
    setSexualOrientation(value);
  };

  const handlePersonalEmailChange = (event) => {
    setPersonalEmail(event);
  };

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
  };

  const handleDateOfBirthChange = (date) => {
    setDob(date);
  };

  const handleCurrentAddressChange = (event) => {
    setCurrentAddress(event);
  };

  const handleCityChange = (event) => {
    setCity(event);
  };

  const handleNationalityChange = (value) => {
    setNationality(value);
  };

  const handleCountryChange = (value) => {
    setCountry(value);
  };

  const handleClientStatus = (value) => {
    setClientStatus(value);
  };

  const handleClientAge = (value) => {
    setAge(value);
  };

  // const handleClientPictureChange = (file) => {
  //   setClientPicture(file);
  // };

  const handleSave = async () => {
    if (
      firstName &&
      lastName &&
      nickname &&
      clientStatus &&
      gender &&
      sexualOrientation &&
      personalEmail &&
      phoneNumber &&
      dob &&
      profilePicture
    ) {
      setIsLoading(true);
      try {
        const newClientRef = await addDoc(collection(db, "clients"), {
          firstName,
          lastName,
          nickname,
          gender,
          sexualOrientation,
          personalEmail,
          phoneNumber,
          dob,
          currentAddress,
          city,
          nationality,
          country,
          staffId: currentUser.uid,
          staffEmail: currentUser.email,
          clientStatus: clientStatus,
          age: age,
        });

        const storageRef = ref(storage, `profilePics/${newClientRef.id}`);
        await uploadBytes(storageRef, profilePicture);
        const profilePictureURL = await getDownloadURL(storageRef);

        await updateDoc(doc(db, "clients", newClientRef.id), {
          profilePicture: profilePictureURL,
        });

        console.log(`Client added with ID: ${newClientRef.id}`);
        toast.success(`Client successfully added!`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setIsLoading(false);
        props.closemodal();
      } catch (error) {
        console.error("Error adding client: ", error);
        setIsLoading(false);
      }
    } else {
      toast.error(`Some mandatory fields are empty. Please check.`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <>
      <div
        className="inputAndLabelModalContainerTitle2"
        style={{ position: "fixed", top: 0, left: 0, width: "100%", zIndex: 1 }}
      >
        <TopTitleFormComponent
          title="ADD NEW CLIENT"
          closemodal={props.closemodal}
        />
      </div>

      <div style={{ marginTop: "60px", height: "500px", overflowY: "scroll" }}>
        <div className="tab-form-container">
          <InputComponent
            title="Client firstname"
            width="300"
            isRequired={true}
            value={firstName}
            onChange={handleFirstNameChange}
          />

          <InputComponent
            title="Client lastname"
            width="300"
            isRequired={true}
            value={lastName}
            onChange={handleLastNameChange}
          />
          <InputComponent
            title="Client nickname"
            width="300"
            isRequired={true}
            value={nickname}
            onChange={handleNicknameChange}
          />

          <DropDownComponent
            api="clientStatus"
            title="Client status"
            isRequired={true}
            value={clientStatus}
            onChange={handleClientStatus}
          />

          <DropDownComponent
            api="gender"
            title="Client gender"
            isRequired={true}
            value={gender}
            onChange={handleGenderChange}
          />
          <DropDownComponent
            api="sexualOrientation"
            title="Sexual orientation"
            isRequired={true}
            value={sexualOrientation}
            onChange={handleSexualOrientationChange}
          />
          <InputComponent
            title="Client personal email"
            width="300"
            isRequired={true}
            value={personalEmail}
            onChange={handlePersonalEmailChange}
          />

          <PhoneNumberInput
            title="Client phone number"
            isRequired={true}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
          />
          <InputComponent
            title="Client age"
            width="200"
            isRequired={true}
            value={age}
            onChange={handleClientAge}
          />
          <DatePickerComponent
            title="Date of birth"
            width="150px"
            isRequired={true}
            selected={dob}
            onChange={handleDateOfBirthChange}
            value={dob}
          />

          <div className="uploader-component-intake">
            <label htmlFor="profilePictureInput">
              <Button variant="contained" component="span">
                Upload Profile Picture
              </Button>
              <input
                id="profilePictureInput"
                type="file"
                onChange={handleProfilePictureChange}
                accept="image/*"
                style={{ display: "none" }}
              />
            </label>
            <Avatar
              src={previewImageUrl}
              style={{
                width: "42px",
                height: "42px",
                marginLeft: "20px",
                marginTop: "3px",
              }}
            />
          </div>

          <TextFieldComponentLarge
            title="Client current address"
            value={currentAddress}
            onChange={handleCurrentAddressChange}
          />
          <InputComponent
            title="City"
            width="300px"
            value={city}
            onChange={handleCityChange}
          />
          <DropDownComponent
            api="nationalities"
            title="Nationality"
            value={nationality}
            onChange={handleNationalityChange}
          />
          <DropDownComponentSorted
            api="countries"
            title="Country"
            value={country}
            onChange={handleCountryChange}
          />
        </div>

        <div className="line4-modal">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
              onClick={props.closemodal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSave}
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                    }
              }
              disabled={isLoading}
              //   onClick={handlePostRecord}
            >
              {isLoading ? "ADDING CLIENT..." : "ADD CLIENT"}
            </Button>
          </>
        </div>
      </div>
    </>
  );
}

export default AddClientInClient;
