function UploaderDocForNote({ handleFileChange, files }) {
  return (
    <div className="main-upload-container ">
      <div className="form2">
        <div className="line5-modal">
          <label
            htmlFor="uploadFiles"
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "8px",
              marginTop: "20px",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
          >
            Upload Files (max 10)
          </label>
          <input
            type="file"
            id="uploadFiles"
            multiple
            onChange={handleFileChange}
            accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv,.zip,.rar,.7z"
            style={{
              width: "0.1px",
              height: "0.1px",
              opacity: 0,
              overflow: "hidden",
              position: "absolute",
              zIndex: -1,
            }}
          />
          <label htmlFor="uploadFiles" className="custom-file-upload">
            Select files
          </label>

          {files?.length > 0 && (
            <div className="select-title">
              Selected files:
              <ul className="ul-container">
                {Array.from(files).map((file) => (
                  <a className="a-container" key={file.name}>
                    {file.name}
                  </a>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UploaderDocForNote;
