import React, { useContext } from "react";
import "../css/dashboard.css";
import TopTitleBannerV2 from "../component/TopTitleBannerV2";
import { LanguageContext } from "../context/LanguageContext";
import Tickets from "../component/Misc/Tickets";
import TicketsMobile from "../component/Misc/TicketsMobile";

export default function SupportContentMobile() {
  const { translate } = useContext(LanguageContext);
  return (
    <div className="right-container-mobile">
      {/* <TopTitleBannerV2 title={translate("Support")} /> */}
      <TicketsMobile />
    </div>
  );
}
