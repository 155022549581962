import { Modal, Box, Button } from "@mui/material";
import { doc, deleteDoc } from "firebase/firestore";
import { useState, useContext } from "react";
import BinStylized from "../../images/binstyliszed.png"; // Assurez-vous d'importer l'image correcte
import { showCustomToast } from "./CustomToast";
import { LanguageContext } from "../../context/LanguageContext";
import { metricsDb } from "../../firebaseMetrics";

function DeleteTicketModal({ open, onClose, ticketId }) {
  const [isLoading, setIsLoading] = useState(false);
  const { translate } = useContext(LanguageContext);

  async function handleDeleteTicket() {
    setIsLoading(true);
    try {
      await deleteDoc(doc(metricsDb, "ticketsSupport-rehab-app", ticketId)); // Suppression du ticket
      console.log("Ticket Deleted : ", ticketId);
      showCustomToast(`${translate("TicketSuccessfullyDeleted")}`, "success");
      onClose(); // Fermer le modal après suppression
    } catch (error) {
      showCustomToast(`${translate("ErrorDeletingTicket")}`, "error");
      console.error("Error deleting ticket:", error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          textAlign: "center",
        }}
      >
        <img src={BinStylized} style={{ width: "60px" }} alt="Delete Icon" />
        <div style={{ marginTop: "20px", fontSize: "16px" }}>
          {translate("AreYouSureToDeleteTicket")}
        </div>
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <Button
            variant="outlined"
            onClick={onClose}
            style={{ fontSize: "12px" }}
          >
            {translate("Cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={handleDeleteTicket}
            disabled={isLoading}
            style={{ backgroundColor: "red", color: "white", fontSize: "12px" }}
          >
            {isLoading ? translate("Deleting") : translate("Delete")}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default DeleteTicketModal;
