export default [
  {
    id: 1,
    textDropDownEn: "-- Choose --",
  },
  {
    id: 2,
    textDropDownEn: "Incident",
  },
  {
    id: 3,
    textDropDownEn: "Problem",
  },
  {
    id: 4,
    textDropDownEn: "Question",
  },

  {
    id: 5,
    textDropDownEn: "Suggestion",
  },
];
