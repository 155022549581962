import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Slide,
} from "@mui/material";
import React, { useContext } from "react";
import DatePickerComponent from "../DatePickerComponent";
import DropDownComponent from "../DropDownComponent";
import InputComponent from "../InputComponent";
import PhoneNumberInput from "../PhoneNumberInput";
import TextFieldComponent from "../TextFieldComponent";
import { useState } from "react";
import { db } from "../../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useEffect } from "react";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";
import { showCustomToast } from "../Misc/CustomToast";
import InputComponentReadOnly from "../InputComponentReadOnly";
import DropDownComponentSorted from "../DropDownComponentSorted";
import { useAuth } from "../../contexts/AuthContext";
import DropDownAllStaffs from "../Dropdowns/DropDownAllStaffs";
import DropDownSpecificStaff from "../Dropdowns/DropDownSpecificStaff";
import LineLogsDetails from "../Lines/LineLogsDetails";
import UploaderGeneralInfosEncapsulatedBis from "../Updloader/UploaderGeneralInfosEncapsulatedBis";
import Joyride, { ACTIONS, STATUS } from "react-joyride";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListIcon from "@mui/icons-material/List";
import InputComponentV2 from "../InputComponentV2";
import DatePickerComponentV2 from "../DatePickerComponentV2";
import DropDownComponentV2 from "../DropDownComponentV2";
import DropDownComponentSortedV2 from "../DropDownComponentSortedV2";
import TextFieldComponentV2 from "../TextFieldComponentV2";
import DropDownAllStaffsV2 from "../Dropdowns/DropDownAllStaffsV2";
import DropDownSpecificStaffV2 from "../Dropdowns/DropDownSpecificStaffV2";
import { LanguageContext } from "../../context/LanguageContext";
import CloseIcon from "@mui/icons-material/Close";
import DatePickerComponentV2Mobile from "../DatePickerComponentV2Mobile";
import TextFieldComponentV2Mobile from "../TextFieldComponentV2Mobile";
import InputComponentV2Mobile from "../InputComponentV2Mobile";
import DropDownAllStaffsV2Mobile from "../Dropdowns/DropDownAllStaffsV2Mobile";
import DropDownSpecificStaffV2Mobile from "../Dropdowns/DropDownSpecificStaffV2Mobile";
import UploaderGeneralInfosEncapsulatedBisMobileV2 from "../Updloader/UploaderGeneralInfosEncapsulatedBisMobileV2";

function GeneralInformationV2(props) {
  const { translate } = useContext(LanguageContext);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalLogsOpen, setIsModalLogsOpen] = useState(false);
  const [, setIsLoadingFetchLogs] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);
  // const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  // const closeDetailLogsModal = () => setIsDetailsModalOpen(false);
  const closeLogsModal = () => setIsModalLogsOpen(false);
  const [run, setRun] = useState(false);
  const [showHelpButton, setShowHelpButton] = useState(true);

  const handleHelpClick = () => {
    setRun(true);
    setShowHelpButton(false); // Cache le bouton "HELP" après le clic
  };

  const styleModalLogs2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    height: "50%",
    borderRadius: 2,
    //overflowY: "auto",
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const customStyles = {
    options: {
      zIndex: 10000,
    },
    tooltip: {
      backgroundColor: "#fff",
      color: "#000",
      animation: "fadeIn 0.3s ease-in-out",
    },
    tooltipContent: {
      textAlign: "justify",
      marginTop: "10px",
    },
    beacon: {
      offsetY: -50, // Ajustez cette valeur pour positionner le beacon au-dessus
    },
    buttonNext: {
      backgroundColor: "#1565c0",
      color: "#fff",
    },
    buttonBack: {
      color: "blue",
    },
    overlay: {
      position: "fixed", // Utilisez 'fixed' pour que l'overlay couvre toute la page
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Ajustez l'opacité de l'overlay
      zIndex: 1000, // Assurez-vous que l'overlay est au-dessus de tout le reste
    },
    spotlight: {
      // backgroundColor: "rgba(0, 0, 0, 0.7)",
      position: "absolute",
      borderRadius: 0,
    },
    // beacon: {
    //   placement: "top",
    // },
    beaconInner: {
      backgroundColor: "#1565c0", // Couleur du cercle intérieur
      borderColor: "#1565c0",
    },
    beaconOuter: {
      backgroundColor: "rgba(21, 101, 192, 0.5)", // Couleur de l'animation concentrique
      borderColor: "rgba(21, 101, 192, 0.5)",
    },
  };

  const steps = [
    {
      target: "#personal-information",
      content:
        "In the 'Personal Information' section, you can update various details about the client, including their name, date of birth, and contact information. Ensure that all the information is accurate and up to date.",
    },
    {
      target: "#treatment-information",
      content:
        "The 'Treatment Information' section allows you to manage the client's treatment details, including their status, assigned therapist, and room number. Keeping this information current is essential for proper care management.",
    },
    {
      target: "#admission-and-discharge",
      content:
        "In the 'Admission & Discharge' section, you can set and update the client's admission and discharge dates. This section helps in tracking the client's stay and planning their treatment timeline.",
    },
    {
      target: "#passport-picture",
      content:
        "The 'Passport Pictures' section is where you can upload and view the client's passport photos. Ensure the images are clear and meet the required standards.",
    },
    {
      target: "#emergency-contact",
      content:
        "The 'Emergency Contact' section is vital for providing contact details in case of emergencies. Fill in the necessary information for timely communication when needed.",
    },
  ];

  const handleJoyrideCallback = (data) => {
    const { status, action } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      setShowHelpButton(true);
    } else if ([ACTIONS.CLOSE].includes(action)) {
      setRun(false);
      setShowHelpButton(true);
    }
  };

  function formatDate3(value) {
    // Assurez-vous que la valeur est une instance de Timestamp
    // et convertissez-la en objet Date, puis formatez-la.
    if (value && value.toDate) {
      return value.toDate().toLocaleString("en-EN", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    }
    return value;
  }

  const fetchLogs = async (clientId) => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(
        collection(db, "logs-general-infos", clientId, "modifications")
      );
      const fetchedLogs = [];
      querySnapshot.forEach((doc) => {
        fetchedLogs.push({ id: doc.id, ...doc.data() });
      });
      setLogs(fetchedLogs);
    } catch (error) {
      console.error("Error fetching logs: ", error);
    } finally {
      setLoading(false);
    }
  };

  const openModalLogs = (clientId) => {
    fetchLogs(clientId);
    setIsModalLogsOpen(true);
  };

  const formatDateLogs = (timestamp) => {
    const date = timestamp.toDate();
    return date.toLocaleString(); // Formate la date selon la locale de l'environnement
  };

  function formatDateLogs2(timestamp) {
    // Assurez-vous que timestamp est un objet Firestore Timestamp
    // et convertissez-le en objet Date JavaScript
    const date = timestamp.toDate();

    // Retourne la date formatée comme une chaîne de caractères
    // Modifiez les options selon vos besoins pour le format de la date et de l'heure
    return date.toLocaleString("en-EN", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }

  useEffect(() => {}, [props.clientId]);

  //VARIABLES
  const maxSizePicFile = 2 * 1024 * 1024;

  //STATE
  const [isModified, setIsModified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTherapist, setSelectedTherapist] = useState(
    props.selectedTherapist || ""
  );

  const [assignedSupportWorker, setAssignedSupportWorker] = useState(
    props.assignedSupportWorker || ""
  );
  const [genderOther, setGenderOther] = useState(props.genderOther || "");
  const [sexualOrientationOther, setSexualOrientationOther] = useState(
    props.sexualOrientationOther || ""
  );
  const [livingWithOther, setLivingWithOther] = useState(
    props.livingWithOther || ""
  );
  const [province, setProvince] = useState(props.province || "");
  const [provinceEmergencyContact, setProvinceEmergencyContact] = useState(
    props.provinceEmergencyContact || ""
  );
  const [emergencyRelationshipOther, setEmergencyRelationshipOther] = useState(
    props.emergencyRelationshipOther || ""
  );
  const [clientIdState, setClientIdState] = useState(props.clientId || "");
  const [firstName, setFirstName] = useState(props.firstName || "");
  const [lastName, setLastName] = useState(props.lastName || "");
  const [nickname, setNickname] = useState(props.nickname || "");
  const [clientSource, setClientSource] = useState(props.clientSource || "");
  const [gender, setGender] = useState(props.gender || "");
  const [sexualOrientation, setSexualOrientation] = useState(
    props.sexualOrientation || ""
  );
  const [personalEmail, setPersonalEmail] = useState(props.personalEmail || "");
  const [phoneNumber, setPhoneNumber] = useState(props.phoneNumber || "");
  const [dob, setDob] = useState(props.dob || "");
  const [currentAddress, setCurrentAddress] = useState(
    props.currentAddress || ""
  );
  const [city, setCity] = useState(props.city || "");
  const [nationality, setNationality] = useState(props.nationality || "");
  const [country, setCountry] = useState(props.country || "");
  const [durationOfStay, setDurationOfStay] = useState(
    props.durationOfStay || ""
  );
  const [dateOfAdmission, setDateOfAdmission] = useState(
    props.dateOfAdmission || ""
  );
  const [
    projectedResidentialDischargeDate,
    setProjectedResidentialDischargeDate,
  ] = useState(props.projectedResidentialDischargeDate || "");
  const [maritalStatus, setMaritalStatus] = useState(props.maritalStatus || "");
  const [livingWith, setLivingWith] = useState(props.livingWith || "");
  const [projectedProgramCompletionDate, setProjectedProgramCompletionDate] =
    useState(props.projectedProgramCompletionDate || "");
  const [passportNumber, setPassportNumber] = useState(
    props.passportNumber || ""
  );
  const [roomNumber, setRoomNumber] = useState(props.roomNumber || "");
  const [visaExpirationDate, setVisaExpirationDate] = useState(
    props.visaExpirationDate || ""
  );

  const [dischargeDate, setDischargeDate] = useState(
    props.actualDischargeDate || null
  );
  const [emergencyContactFirstName, setEmergencyContactFirstName] = useState(
    props.emergencyContactFirstName || ""
  );
  const [emergencyContactLastName, setEmergencyContactLastName] = useState(
    props.emergencyContactLastName || ""
  );
  const [emergencyContactRelationship, setEmergencyContactRelationship] =
    useState(props.emergencyContactRelationship || "");
  const [emergencyContactPhoneNumber, setEmergencyContactPhoneNumber] =
    useState(props.emergencyContactPhoneNumber || "");
  const [emergencyContactEmail, setEmergencyContactEmail] = useState(
    props.emergencyContactEmail || ""
  );
  const [emergencyContactAddress, setEmergencyContactAddress] = useState(
    props.emergencyContactAddress || ""
  );
  const [emergencyContactCity, setEmergencyContactCity] = useState(
    props.emergencyContactCity || ""
  );
  const [emergencyContactNationality, setEmergencyContactNationality] =
    useState(props.emergencyContactNationality || "");
  const [emergencyContactCountry, setEmergencyContactCountry] = useState(
    props.emergencyContactCountry || ""
  );
  const [payerName, setPayerName] = useState(props.payerName || "");
  const [payerPhoneNumber, setPayerPhoneNumber] = useState(
    props.payerPhoneNumber || ""
  );
  const [inTakestaffName, setInTakestaffName] = useState(
    props.inTakestaffName || ""
  );
  const [intakeStaffId, setIntakeStaffId] = useState(props.intakeStaffId || "");
  const [intakeStaffEmail, setIntakeStaffEmail] = useState(
    props.intakeStaffEmail || ""
  );
  const [clientStatus, setClientStatus] = useState(props.clientStatus || "");
  const [age, setAge] = useState("NA");
  const [lastModification, setLastModification] = useState(
    props.lastModification || ""
  );
  const [files, setFiles] = useState([]);
  const [fileLinks, setFileLinks] = useState(props.fileUrls || []);
  const [acknowledgmentOfPresence, setAcknowledgmentOfPresence] = useState(
    props.emergencyCheckbox0 || false
  );
  const [clinicalInformation, setClinicalInformation] = useState(
    props.emergencyCheckbox1 || false
  );
  const [psychEvaluation, setPsychEvaluation] = useState(
    props.emergencyCheckbox2 || false
  );
  const [referralLetter, setReferralLetter] = useState(
    props.emergencyCheckbox3 || false
  );
  const [labData, setLabData] = useState(props.emergencyCheckbox4 || false);
  const [progressNotes, setProgressNotes] = useState(
    props.emergencyCheckbox5 || false
  );
  const [medicalRecords, setMedicalRecords] = useState(
    props.emergencyCheckbox6 || false
  );
  const [progressInTreatment, setProgressInTreatment] = useState(
    props.emergencyCheckbox7 || false
  );
  const [psychosocialAssessment, setPsychosocialAssessment] = useState(
    props.emergencyCheckbox8 || false
  );
  const [medicationInfo, setMedicationInfo] = useState(
    props.emergencyCheckbox9 || false
  );
  const [treatmentPlan, setTreatmentPlan] = useState(
    props.emergencyCheckbox10 || false
  );
  const [physicalAssessment, setPhysicalAssessment] = useState(
    props.emergencyCheckbox11 || false
  );
  const [dischargeSummary, setDischargeSummary] = useState(
    props.emergencyCheckbox12 || false
  );
  const [familyHistory, setFamilyHistory] = useState(
    props.emergencyCheckbox13 || false
  );
  const [dischargePlan, setDischargePlan] = useState(
    props.emergencyCheckbox14 || false
  );
  const [admissionDischargeDates, setAdmissionDischargeDates] = useState(
    props.emergencyCheckbox15 || false
  );
  const [otherInfo, setOtherInfo] = useState(
    props.emergencyCheckboxContent15 || ""
  );
  const [otherInfoInput, setOtherInfoInput] = useState(
    props.emergencyCheckboxContent15Input || ""
  );

  // FUNCTIONS

  const handleProvinceChange = (event) => {
    setProvince(event);
    setIsModified(true);
  };

  const handleProvinceEmergencyContactChange = (event) => {
    setIsModified(true);
    setProvinceEmergencyContact(event);
  };

  const handleGenderOther = (event) => {
    setIsModified(true);
    setGenderOther(event);
  };

  const handleSexualOrientationOther = (event) => {
    setIsModified(true);
    setSexualOrientationOther(event);
  };

  const handleEmergencyRelationshipOther = (event) => {
    setIsModified(true);
    setEmergencyRelationshipOther(event);
  };

  const handleLivingWithOther = (event) => {
    setIsModified(true);
    setLivingWithOther(event);
  };

  const handleMaritalStatusChange = (event) => {
    setIsModified(true);
    setMaritalStatus(event);
  };

  const handleLivingWithChange = (event) => {
    setIsModified(true);
    setLivingWith(event);
  };

  const handleSelectedTherapistChange = (value) => {
    setIsModified(true);
    setSelectedTherapist(value);
  };

  const handleAssignedSupportWorkerChange = (value) => {
    setIsModified(true);
    setAssignedSupportWorker(value);
  };

  const handleFirstNameChange = (event) => {
    setIsModified(true);
    setFirstName(event);
  };

  const handleLastNameChange = (event) => {
    setIsModified(true);
    setLastName(event);
  };

  const handleNicknameChange = (event) => {
    setIsModified(true);
    setNickname(event);
  };

  const handleGenderChange = (value) => {
    setIsModified(true);
    setGender(value);
  };

  const handleSexualOrientationChange = (value) => {
    setIsModified(true);
    setSexualOrientation(value);
  };

  const handlePersonalEmailChange = (event) => {
    setIsModified(true);
    setPersonalEmail(event);
  };

  const handlePhoneNumberChange = (value) => {
    setIsModified(true);
    setPhoneNumber(value);
  };

  const handleDobChange = (value) => {
    setIsModified(true);
    setDob(value);
    setAge(getAge(value));
  };

  const handleCurrentAddressChange = (event) => {
    setIsModified(true);
    setCurrentAddress(event);
  };

  const handleCityChange = (event) => {
    setIsModified(true);
    setCity(event);
  };

  const handleNationalityChange = (value) => {
    setIsModified(true);
    setNationality(value);
  };

  const handleCountryChange = (value) => {
    setIsModified(true);
    setCountry(value);
  };

  const handleDurationOfStayChange = (value) => {
    setIsModified(true);
    setDurationOfStay(value);
  };

  const handleDateOfAdmissionChange = (value) => {
    setIsModified(true);
    setDateOfAdmission(value);
  };

  const handleProjectedResidentialDischargeDateChange = (value) => {
    setIsModified(true);
    setProjectedResidentialDischargeDate(value);
  };

  const handleProjectedProgramCompletionDateChange = (value) => {
    setIsModified(true);
    setProjectedProgramCompletionDate(value);
  };

  const handleDischargeDateChange = (value) => {
    setIsModified(true);
    setDischargeDate(value);
  };

  const handlePassportNumberChange = (event) => {
    setIsModified(true);
    setPassportNumber(event);
  };

  const handleRoomNumberChange = (event) => {
    setIsModified(true);
    setRoomNumber(event);
  };

  const handleVisaExpirationDateChange = (value) => {
    setIsModified(true);
    setVisaExpirationDate(value);
  };

  const handleEmergencyContactFirstNameChange = (event) => {
    setIsModified(true);
    setEmergencyContactFirstName(event);
  };

  const handleEmergencyContactLastNameChange = (event) => {
    setIsModified(true);
    setEmergencyContactLastName(event);
  };

  const handleEmergencyContactRelationshipChange = (value) => {
    setIsModified(true);
    setEmergencyContactRelationship(value);
  };

  const handleEmergencyContactPhoneNumberChange = (value) => {
    setIsModified(true);
    setEmergencyContactPhoneNumber(value);
  };

  const handleEmergencyContactEmailChange = (event) => {
    setIsModified(true);
    setEmergencyContactEmail(event);
  };

  const handleEmergencyContactAddressChange = (event) => {
    setIsModified(true);
    setEmergencyContactAddress(event);
  };

  const handleEmergencyContactCityChange = (event) => {
    setIsModified(true);
    setEmergencyContactCity(event);
  };

  const handleEmergencyContactNationalityChange = (value) => {
    setIsModified(true);
    setEmergencyContactNationality(value);
  };

  const handleEmergencyContactCountryChange = (value) => {
    setIsModified(true);
    setEmergencyContactCountry(value);
  };

  const handlePayerNameChange = (event) => {
    setIsModified(true);
    setPayerName(event);
  };

  const handlePayerPhoneNumberChange = (value) => {
    setIsModified(true);
    setPayerPhoneNumber(value);
  };

  const handleClientStatusChange = (value) => {
    setIsModified(true);
    setClientStatus(value);
  };

  const handleClientSourceChange = (value) => {
    setIsModified(true);
    setClientSource(value);
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length <= 3) {
      setFiles(event.target.files);
    } else {
      showCustomToast("You can only upload up to 3 files.", "error");
    }
  };

  function getAge(dobTimestamp) {
    if (!dobTimestamp) {
      return "NA";
    }

    const dob = dobTimestamp.toDate(); // Convertit le timestamp Firestore en une date JavaScript
    const diff = Date.now() - dob.getTime();
    const ageDate = new Date(diff);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  useEffect(() => {
    setAge(getAge(dob));
  }, [dob]);

  const uploadFilesAndGetUrls = async (docId) => {
    const storage = getStorage();
    const fileUrls = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > maxSizePicFile) {
        showCustomToast(`File size exceeds the limit: ${file.name}`, "error");

        console.error(`File size exceeds the limit: ${file.name}`);
        continue; // Passez au fichier suivant dans la boucle
      }
      const filePath = `Documents/generalInfos/${docId}/${file.name}`;
      const storageRef = ref(storage, filePath);

      await uploadBytes(storageRef, file);
      const fileUrl = await getDownloadURL(storageRef);
      fileUrls.push({ url: fileUrl, name: file.name });
    }

    return fileUrls;
  };

  const deleteFile = async (fileUrl, fileName, noteId) => {
    const storage = getStorage();
    const filePath = `Documents/generalInfos/${noteId}/${fileName}`;
    const storageRef = ref(storage, filePath);

    try {
      await deleteObject(storageRef);
      const newFileLinks = fileLinks.filter((file) => file.url !== fileUrl);
      setFileLinks(newFileLinks);
      const noteRef = doc(db, "clients", noteId);

      // Modifier cette ligne pour inclure les noms des fichiers
      await updateDoc(noteRef, {
        fileUrls: newFileLinks.map((file) => ({
          url: file.url,
          name: file.name,
        })),
      });

      showCustomToast("File successfully deleted!", "success");
    } catch (error) {
      console.log("Error deleting file:", error);
      showCustomToast("Error deleting file", "error");
    }
  };

  useEffect(() => {}, [files]);

  const updateClientData = async (clientId) => {
    if (firstName && lastName) {
      setIsLoading(true);
      try {
        const clientRef = doc(db, "clients", clientId);

        // Fetch the existing client data from Firestore
        const clientDoc = await getDoc(clientRef);
        const previousData = clientDoc.data();

        const fileUrls = await uploadFilesAndGetUrls(clientRef.id);
        const existingFileUrls = previousData.fileUrls || [];
        const mergedFileUrls = [...existingFileUrls, ...fileUrls];

        // Create an object for the new data
        const newData = {
          selectedTherapist,
          firstName,
          lastName,
          nickname,
          gender,
          sexualOrientation,
          personalEmail,
          phoneNumber,
          dob,
          currentAddress,
          city,
          nationality,
          country,
          durationOfStay,
          dateOfAdmission,
          projectedResidentialDischargeDate,
          maritalStatus,
          livingWith,
          projectedProgramCompletionDate,
          passportNumber,
          roomNumber,
          visaExpirationDate,
          emergencyContactFirstName,
          emergencyContactLastName,
          emergencyContactRelationship,
          emergencyContactPhoneNumber,
          emergencyContactEmail,
          emergencyContactAddress,
          emergencyContactCity,
          emergencyContactNationality,
          emergencyContactCountry,
          emergencyRelationshipOther,
          payerName,
          payerPhoneNumber,
          inTakestaffName,
          intakeStaffId,
          intakeStaffEmail,
          clientStatus,
          age,
          fileUrls: mergedFileUrls,
          genderOther,
          sexualOrientationOther,
          livingWithOther,
          province,
          provinceEmergencyContact,
          emergencyCheckbox0: acknowledgmentOfPresence,
          emergencyCheckbox1: clinicalInformation,
          emergencyCheckbox2: psychEvaluation,
          emergencyCheckbox3: referralLetter,
          emergencyCheckbox4: labData,
          emergencyCheckbox5: progressNotes,
          emergencyCheckbox6: medicalRecords,
          emergencyCheckbox7: progressInTreatment,
          emergencyCheckbox8: psychosocialAssessment,
          emergencyCheckbox9: medicationInfo,
          emergencyCheckbox10: treatmentPlan,
          emergencyCheckbox11: physicalAssessment,
          emergencyCheckbox12: dischargeSummary,
          emergencyCheckbox13: familyHistory,
          emergencyCheckbox14: dischargePlan,
          emergencyCheckbox15: admissionDischargeDates,
          emergencyCheckboxContent15: otherInfo,
          emergencyCheckboxContent15Input: otherInfoInput,
          actualDischargeDate: dischargeDate,
          assignedSupportWorker: assignedSupportWorker,
          clientSource: clientSource,
        };

        // Determine changes by comparing newData with previousData
        const changes = {};
        Object.keys(newData).forEach((key) => {
          const newValue = newData[key];
          const oldValue = previousData[key];

          if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
            changes[key] = newValue;
          }
        });

        // Update only if there are changes
        if (Object.keys(changes).length > 0) {
          await updateDoc(clientRef, {
            ...changes,
            lastModification: serverTimestamp(),
            lastModificationDoneBy: currentUser.displayName,
          });

          // Log only the changes
          const clientLogsRef = doc(
            collection(db, "logs-general-infos", clientId, "modifications")
          );

          const logEntry = {
            clientId,
            changes, // Only log the changed fields
            recordedBy: currentUser?.displayName,
            recordedAt: serverTimestamp(),
          };

          await setDoc(clientLogsRef, logEntry);

          showCustomToast("Client successfully updated!", "success");
        } else {
          showCustomToast("No changes detected.", "info");
        }

        setIsModified(false);
        setIsLoading(false);
      } catch (error) {
        console.error("Error updating client: ", error);
        setIsLoading(false);
        showCustomToast(`Error updating client: ${error}`, "error");
      }
    } else {
      showCustomToast(
        "Some mandatory fields are empty. Please check.",
        "error"
      );
    }
  };

  useEffect(() => {
    setSelectedTherapist(props.selectedTherapist || "");
    setFirstName(props.firstName || "");
    setLastName(props.lastName || "");
    setNickname(props.nickname || "");
    setGender(props.gender || "");
    setSexualOrientation(props.sexualOrientation || "");
    setPersonalEmail(props.personalEmail || "");
    setPhoneNumber(props.phoneNumber || "");
    setDob(props.dob || "");
    setCurrentAddress(props.currentAddress || "");
    setCity(props.city || "");
    setNationality(props.nationality || "");
    setCountry(props.country || "");
    setDurationOfStay(props.durationOfStay || "");
    setDateOfAdmission(props.dateOfAdmission || "");
    setProjectedResidentialDischargeDate(
      props.projectedResidentialDischargeDate || ""
    );
    setMaritalStatus(props.maritalStatus || "");
    setLivingWith(props.livingWith || "");
    setProjectedProgramCompletionDate(
      props.projectedProgramCompletionDate || ""
    );
    setPassportNumber(props.passportNumber || "");
    setRoomNumber(props.roomNumber || "");
    setVisaExpirationDate(props.visaExpirationDate || "");
    setEmergencyContactFirstName(props.emergencyContactFirstName || "");
    setEmergencyContactLastName(props.emergencyContactLastName || "");
    setEmergencyContactRelationship(props.emergencyContactRelationship || "");
    setEmergencyContactPhoneNumber(props.emergencyContactPhoneNumber || "");
    setEmergencyContactEmail(props.emergencyContactEmail || "");
    setEmergencyContactAddress(props.emergencyContactAddress || "");
    setEmergencyContactCity(props.emergencyContactCity || "");
    setEmergencyContactNationality(props.emergencyContactNationality || "");
    setEmergencyContactCountry(props.emergencyContactCountry || "");
    setPayerName(props.payerName || "");
    setPayerPhoneNumber(props.payerPhoneNumber || "");
    setInTakestaffName(props.inTakestaffName || "");
    setIntakeStaffId(props.intakeStaffId || "");
    setIntakeStaffEmail(props.intakeStaffEmail || "");
    setClientStatus(props.clientStatus || "");
    // setAge(props.age || "");
    setLastModification(props.lastModification || "");
    setFileLinks(props.fileUrls || "");
    setGenderOther(props.genderOther || "");
    setSexualOrientationOther(props.sexualOrientationOther || "");
    setLivingWithOther(props.livingWithOther || "");
    setEmergencyRelationshipOther(props.emergencyRelationshipOther || "");
    setProvince(props.province || "");
    setProvinceEmergencyContact(props.provinceEmergencyContact || "");
    setAcknowledgmentOfPresence(props.emergencyCheckbox0 || false);
    setClinicalInformation(props.emergencyCheckbox1 || false);
    setPsychEvaluation(props.emergencyCheckbox2 || false);
    setReferralLetter(props.emergencyCheckbox3 || false);
    setLabData(props.emergencyCheckbox4 || false);
    setProgressNotes(props.emergencyCheckbox5 || false);
    setMedicalRecords(props.emergencyCheckbox6 || false);
    setProgressInTreatment(props.emergencyCheckbox7 || false);
    setPsychosocialAssessment(props.emergencyCheckbox8 || false);
    setMedicationInfo(props.emergencyCheckbox9 || false);
    setTreatmentPlan(props.emergencyCheckbox10 || false);
    setPhysicalAssessment(props.emergencyCheckbox11 || false);
    setDischargeSummary(props.emergencyCheckbox12 || false);
    setFamilyHistory(props.emergencyCheckbox13 || false);
    setDischargePlan(props.emergencyCheckbox14 || false);
    setAdmissionDischargeDates(props.emergencyCheckbox15 || false);
    setOtherInfo(props.emergencyCheckboxContent15 || "");
    setOtherInfoInput(props.emergencyCheckboxContent15Input || "");
    setDischargeDate(props.actualDischargeDate || null);
    setAssignedSupportWorker(props.assignedSupportWorker || "");
    setClientSource(props.clientSource || "");
  }, [
    props.actualDischargeDate,
    props.selectedTherapist,
    props.firstName,
    props.lastName,
    props.nickname,
    props.gender,
    props.sexualOrientation,
    props.personalEmail,
    props.phoneNumber,
    props.dob,
    props.currentAddress,
    props.city,
    props.nationality,
    props.country,
    props.durationOfStay,
    props.dateOfAdmission,
    props.projectedResidentialDischargeDate,
    props.maritalStatus,
    props.livingWith,
    props.projectedProgramCompletionDate,
    props.passportNumber,
    props.roomNumber,
    props.visaExpirationDate,
    props.emergencyContactFirstName,
    props.emergencyContactLastName,
    props.emergencyContactRelationship,
    props.emergencyContactPhoneNumber,
    props.emergencyContactEmail,
    props.emergencyContactAddress,
    props.emergencyContactCity,
    props.emergencyContactNationality,
    props.emergencyContactCountry,
    props.emergencyRelationshipOther,
    props.payerName,
    props.payerPhoneNumber,
    props.inTakestaffName,
    props.intakeStaffId,
    props.intakeStaffEmail,
    props.clientStatus,
    props.age,
    props.lastModification,
    props.fileUrls,
    props.genderOther,
    props.sexualOrientationOther,
    props.livingWithOther,
    props.province,
    props.provinceEmergencyContact,
    props.emergencyCheckbox0,
    props.emergencyCheckbox1,
    props.emergencyCheckbox2,
    props.emergencyCheckbox3,
    props.emergencyCheckbox4,
    props.emergencyCheckbox5,
    props.emergencyCheckbox6,
    props.emergencyCheckbox7,
    props.emergencyCheckbox8,
    props.emergencyCheckbox9,
    props.emergencyCheckbox10,
    props.emergencyCheckbox11,
    props.emergencyCheckbox12,
    props.emergencyCheckbox13,
    props.emergencyCheckbox14,
    props.emergencyCheckbox15,
    props.emergencyCheckboxContent15,
    props.emergencyCheckboxContent15Input,
    props.assignedSupportWorker,
    props.clientSource,
  ]);

  const { currentUser } = useAuth();

  useEffect(() => {
    props.onModificationChange(isModified);
  }, [isModified]);

  const formatDate2 = (timestamp) => {
    const date = timestamp.toDate();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const circularProgressStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "100%",
    height: "400px",
  };

  return (
    // <div className="tab-container-white-desktop">
    <div>
      <Joyride
        steps={steps}
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
        run={run}
        callback={handleJoyrideCallback}
        disableScrolling={true}
        disableBeacon={true}
        styles={customStyles}
      />
      <div className="container-main-plan-mobile-v3">
        <div
        // className="tab-container-title-mobile-3"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "10px",
              paddingLeft: "0px",
              justifyContent: "space-between",
              paddingRight: "0px",
              paddingLeft: "10px",
            }}
          >
            <div style={{ fontSize: "16px", fontWeight: "600", width: "50%" }}>
              {translate("GeneralInformation")}
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
                alignContent: "flex-end",
                alignItems: "flex-end",
                alignSelf: "flex-end",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => openModalLogs(props.clientId)}
                sx={{ fontSize: "10px", marginRight: "0px" }}
              >
                {translate("ActivityLogs")}
              </Button>

              {/* {showHelpButton && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleHelpClick}
                  sx={{ fontSize: "10px" }}
                >
                  {translate("Help")}
                </Button>
              )} */}
            </div>
          </div>

          {isModalLogsOpen && (
            <Modal
              open={isModalLogsOpen}
              onClose={() => setIsModalLogsOpen(false)}
              aria-labelledby="activity-log-title"
              aria-describedby="activity-log-description"
              closeAfterTransition
            >
              <Slide direction="left" in={isModalLogsOpen}>
                <Box
                  sx={{
                    position: "fixed",
                    top: "5%",
                    right: 0,
                    width: "90%", // Adjust width as needed
                    height: "90%",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    marginRight: "15px",
                    pl: 0,
                    pr: 0,
                    borderRadius: "15px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 1,
                      borderBottom: "1px solid lightgray",
                    }}
                  >
                    <div
                      id="activity-log-title"
                      style={{
                        margin: 0,
                        paddingLeft: "35px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      {translate("ActivityLogs")}
                    </div>
                    <div style={{ paddingRight: "25px" }}>
                      <IconButton onClick={closeLogsModal}>
                        {/* <Button onClick={closeLogsModal} variant="outlined"> */}
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </Box>
                  <Box
                    id="activity-log-description"
                    sx={{
                      flexGrow: 1,
                      overflowY: "auto",
                      mt: 1,
                      pl: 4,
                      pr: 3.3,
                    }}
                  >
                    {loading ? (
                      <div style={circularProgressStyle}>
                        <CircularProgress />
                      </div>
                    ) : logs.length === 0 ? (
                      <div style={{ padding: "20px", textAlign: "center" }}>
                        No recent activity
                      </div>
                    ) : (
                      logs
                        .sort(
                          (a, b) =>
                            b.recordedAt.toDate() - a.recordedAt.toDate()
                        )
                        .map((log) => (
                          <Accordion key={log.id}>
                            <AccordionSummary
                              // style={{ marginBottom: 0 }}
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`panel-${log.id}-content`}
                              id={`panel-${log.id}-header`}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                padding: "5px 10px",
                                backgroundColor: "#f2f2f2",
                                borderBottom: "1px solid #e0e0e0",
                                fontFamily:
                                  '"Courier New", Consolas, monospace',
                                fontSize: "0.9em",
                                color: "#1d1d1d",
                                textTransform: "uppercase",
                              }}
                            >
                              {/* <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <div>
                                  {log.recordedBy} -{" "}
                                  {formatDateLogs2(log.recordedAt)}
                                </div>
                              </div> */}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <ListIcon
                                  style={{
                                    marginRight: "8px",
                                    color: "#888888",
                                  }}
                                />
                                <div>
                                  {log.recordedBy} -{" "}
                                  {formatDateLogs2(log.recordedAt)}
                                </div>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails
                              style={{
                                marginTop: 0,
                                paddingTop: 0,
                                marginLeft: 0,
                                marginRight: 0,
                              }}
                            >
                              {Object.entries(log.changes)
                                .filter(
                                  ([key, _]) => key !== "lastModification"
                                )
                                .map(([key, value]) => (
                                  <LineLogsDetails
                                    key={key}
                                    fieldName={key}
                                    value={value}
                                  />
                                ))}
                            </AccordionDetails>
                          </Accordion>
                        ))
                    )}
                    {/* Add the initial log entry at the bottom of the list */}
                    {props.creatorName && props.dateOfCreation && (
                      <div
                        style={{
                          fontSize: "12px",
                          fontStyle: "italic",
                          padding: "20px",
                          borderTop: "1px solid #e0e0e0",
                        }}
                      >
                        {`Client added by ${
                          props.creatorName
                        } on ${props.dateOfCreation
                          .toDate()
                          .toLocaleString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          })}`}
                      </div>
                    )}
                  </Box>
                </Box>
              </Slide>
            </Modal>
          )}
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ padding: "0px", width: "100%" }}>
            <div
              style={{
                width: "100%",
                paddingLeft: "0px",
                paddingRight: "10px",
                paddingTop: "0px",
                paddingBottom: "30px",
                display: "flex",
                flexDirection: "column",
                // backgroundColor: "#f5f5f5",
                flexWrap: "wrap", // Permet le retour à la ligne
                gap: "20px",
              }}
            >
              <div
                // className="tab-container-title-mobile-3"
                style={{
                  borderTop: "1px solid #cccccc",
                  paddingTop: "20px",
                  marginTop: "20px",
                  paddingLeft: "10px",
                }}
              >
                <span
                  id="personal-information"
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  {" "}
                  {translate("PersonalInformation")}
                </span>
              </div>
              <div
                style={{
                  width: "100%",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "0px",
                  paddingBottom: "10px",
                  display: "flex",
                  flexDirection: "row",
                  flexDirection: "space-between",
                  // backgroundColor: "#f5f5f5",
                  // flexWrap: "wrap", // Permet le retour à la ligne
                  gap: "20px",
                }}
              >
                {/* <div className="tab-form-container"> */}
                <InputComponentV2
                  title={translate("FirstName")}
                  width="100%"
                  value={firstName}
                  onChange={handleFirstNameChange}
                />
                <InputComponentV2
                  title={translate("LastName")}
                  width="100%"
                  value={lastName}
                  onChange={handleLastNameChange}
                />
              </div>

              <div
                style={{
                  width: "100%",
                  paddingLeft: "10px",
                  paddingRight: "0px",
                  paddingTop: "0px",
                  paddingBottom: "10px",
                  display: "flex",
                  flexDirection: "row",
                  flexDirection: "space-between",

                  // backgroundColor: "#f5f5f5",
                  // flexWrap: "wrap", // Permet le retour à la ligne
                  // gap: "20px",
                }}
              >
                <InputComponentV2
                  title={translate("Nickname")}
                  width="95%"
                  value={nickname}
                  onChange={handleNicknameChange}
                />

                <InputComponentV2
                  title={translate("Email")}
                  width="95%"
                  value={personalEmail}
                  onChange={handlePersonalEmailChange}
                />
              </div>

              <div
                style={{
                  width: "100%",
                  paddingLeft: "10px",
                  paddingRight: "0px",
                  paddingTop: "0px",
                  paddingBottom: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",

                  // backgroundColor: "#f5f5f5",
                  // flexWrap: "wrap", // Permet le retour à la ligne
                  // gap: "20px",
                }}
              >
                {/* <div className="tab-form-container"> */}

                <PhoneNumberInput
                  title={translate("PhoneNumber")}
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  width="95%"
                />

                <DatePickerComponentV2Mobile
                  title={translate("Birthday")}
                  width="95%"
                  height="30px"
                  value={dob}
                  onChange={handleDobChange}
                />
              </div>

              <div
                style={{
                  width: "100%",
                  paddingLeft: "10px",
                  paddingRight: "0px",
                  paddingTop: "0px",
                  paddingBottom: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",

                  // backgroundColor: "#f5f5f5",
                  // flexWrap: "wrap", // Permet le retour à la ligne
                  // gap: "20px",
                }}
              >
                <InputComponentReadOnly
                  title={translate("AgeNonEditable")}
                  width="95%"
                  value={age}
                  height="40px"
                  // onChange={handleAgeChange}
                />
              </div>

              <div
                style={{
                  width: "100%",
                  paddingLeft: "10px",
                  paddingRight: "0px",
                  paddingTop: "0px",
                  paddingBottom: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",

                  // backgroundColor: "#f5f5f5",
                  // flexWrap: "wrap", // Permet le retour à la ligne
                  // gap: "20px",
                }}
              >
                <DropDownComponentV2
                  api="maritalStatus"
                  title={translate("MaritalStatus")}
                  value={maritalStatus}
                  onChange={handleMaritalStatusChange}
                  width="100%"
                />
                <DropDownComponentSortedV2
                  api="relationship"
                  title={translate("LivingWith")}
                  value={livingWith}
                  onChange={handleLivingWithChange}
                  width="100%"
                />

                {/* {livingWith === "Other" ? (
                  <InputComponentV2
                    title={translate("IfOtherDescribe")}
                    width="250px"
                    value={livingWithOther}
                    onChange={handleLivingWithOther}
                  />
                ) : (
                  ""
                )} */}
              </div>

              <div
                style={{
                  width: "100%",
                  paddingLeft: "10px",
                  paddingRight: "0px",
                  paddingTop: "0px",
                  paddingBottom: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",

                  // backgroundColor: "#f5f5f5",
                  // flexWrap: "wrap", // Permet le retour à la ligne
                  // gap: "20px",
                }}
              >
                <DropDownComponentV2
                  api="gender"
                  title={translate("Gender")}
                  value={gender}
                  onChange={handleGenderChange}
                  width="100%"
                />

                {gender === "Other" ? (
                  <InputComponentV2
                    title={translate("IfOtherGenderDescribe")}
                    width="100%"
                    value={genderOther}
                    onChange={handleGenderOther}
                  />
                ) : (
                  ""
                )}

                <DropDownComponentV2
                  api="sexualOrientation"
                  title={translate("SexualOrientation")}
                  value={sexualOrientation}
                  onChange={handleSexualOrientationChange}
                  width="100%"
                />

                {sexualOrientation === "other" ? (
                  <InputComponent
                    title={translate("IfOtherDescribe")}
                    width="100%"
                    value={sexualOrientationOther}
                    onChange={handleSexualOrientationOther}
                  />
                ) : (
                  ""
                )}
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: "20px",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                  paddingRight: "0px",
                }}
              >
                <TextFieldComponentV2Mobile
                  title={translate("PhysicalAddressOfResidence")}
                  value={currentAddress}
                  onChange={handleCurrentAddressChange}
                  width="100%"
                />
              </div>
              {/* <div style={{ gap: "10px" }}> */}
              <div
                style={{
                  width: "100%",
                  paddingLeft: "10px",
                  paddingRight: "0px",
                  paddingTop: "0px",
                  paddingBottom: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",

                  // backgroundColor: "#f5f5f5",
                  // flexWrap: "wrap", // Permet le retour à la ligne
                  // gap: "20px",
                }}
              >
                <InputComponentV2Mobile
                  title={translate("City")}
                  width="90%"
                  value={city}
                  onChange={handleCityChange}
                />
                <InputComponentV2Mobile
                  title={translate("ProvinceState")}
                  width="90%"
                  value={province}
                  onChange={handleProvinceChange}
                />
                {/* </div> */}
              </div>

              <div
                style={{
                  width: "100%",
                  paddingLeft: "10px",
                  paddingRight: "0px",
                  paddingTop: "0px",
                  paddingBottom: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",

                  // backgroundColor: "#f5f5f5",
                  // flexWrap: "wrap", // Permet le retour à la ligne
                  // gap: "20px",
                }}
              >
                <DropDownComponentV2
                  api="countries"
                  title={translate("Country")}
                  value={country}
                  onChange={handleCountryChange}
                  width="95%"
                />
                <DropDownComponentV2
                  api="nationalities"
                  title={translate("Nationality")}
                  value={nationality}
                  onChange={handleNationalityChange}
                  width="95%"
                />
              </div>
              {/* </div> */}
              <div
                style={{
                  width: "100%",
                  paddingLeft: "10px",
                  paddingRight: "0px",
                  paddingTop: "0px",
                  paddingBottom: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flexWrap: "wrap",

                  // backgroundColor: "#f5f5f5",
                  // flexWrap: "wrap", // Permet le retour à la ligne
                  // gap: "20px",
                }}
              >
                {/* </div> */}
                {/* <div className="tab-form-container"> */}
                <DropDownComponentSortedV2
                  api="clientSource"
                  title={translate("ClientSource")}
                  value={clientSource}
                  onChange={handleClientSourceChange}
                  width="95%"
                />
                <div style={{ marginTop: "10px" }}>
                  <InputComponentV2
                    title={translate("PassportNumber")}
                    value={passportNumber}
                    onChange={handlePassportNumberChange}
                    width="95%"
                  />
                </div>

                <DatePickerComponentV2
                  title={translate("VisaExpirationDate")}
                  width="250px"
                  value={visaExpirationDate}
                  onChange={handleVisaExpirationDateChange}
                />
              </div>
            </div>
          </div>

          <div style={{ padding: "0px", width: "100%", paddingBottom: "20px" }}>
            <div
              // className="tab-container-title-mobile-3"
              style={{
                borderTop: "1px solid #cccccc",
                paddingTop: "20px",
                marginTop: "20px",
              }}
            >
              <span
                id="treatment-information"
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  paddingLeft: "10px",
                }}
              >
                {" "}
                {translate("TreatmentInformation")}
              </span>
            </div>
            <div
              style={{
                width: "100%",
                paddingLeft: "10px",
                paddingRight: "0px",
                paddingTop: "15px",
                paddingBottom: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "wrap",

                // backgroundColor: "#f5f5f5",
                // flexWrap: "wrap", // Permet le retour à la ligne
                // gap: "20px",
              }}
            >
              <DropDownComponentSortedV2
                api="clientStatus"
                title={translate("ClientStatus")}
                value={clientStatus}
                onChange={handleClientStatusChange}
                width="100%"
              />
              <InputComponentV2
                title={translate("RoomNumber")}
                width="100%"
                value={roomNumber}
                onChange={handleRoomNumberChange}
              />
              <DropDownAllStaffsV2Mobile
                title={translate("AssignedTherapist")}
                value={selectedTherapist}
                width="auto"
                onChange={handleSelectedTherapistChange}
              />

              <DropDownSpecificStaffV2Mobile
                title={translate("AssignedSupportStaff")}
                value={assignedSupportWorker}
                onChange={handleAssignedSupportWorkerChange}
                allowedRoles={["Support Staff"]}
                width="250px"
              />
            </div>
          </div>

          <div
            style={{ paddingLeft: "0px", width: "100%", paddingBottom: "20px" }}
          >
            <div
              // className="tab-container-title-mobile-3"
              style={{
                borderTop: "1px solid #cccccc",
                paddingTop: "20px",
                marginTop: "20px",
                gap: "10px",
              }}
            >
              <span
                id="admission-and-discharge"
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  paddingLeft: "10px",
                }}
              >
                {" "}
                {translate("AdmissionAndDischarge")}
              </span>
            </div>
            <div
              style={{
                width: "100%",
                paddingLeft: "10px",
                paddingRight: "0px",
                paddingTop: "15px",
                paddingBottom: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "wrap",

                // backgroundColor: "#f5f5f5",
                // flexWrap: "wrap", // Permet le retour à la ligne
                // gap: "20px",
              }}
            >
              <DatePickerComponentV2
                title={translate("ActualAdmissionDate")}
                width="250px"
                value={dateOfAdmission}
                onChange={handleDateOfAdmissionChange}
              />
              <div style={{ paddingLeft: "0px" }}>
                <DatePickerComponentV2
                  title={translate("ActualDischargeDate")}
                  width={250}
                  value={dischargeDate}
                  onChange={handleDischargeDateChange}
                />
              </div>
            </div>

            <div
              style={{
                width: "100%",
                paddingLeft: "10px",
                paddingRight: "0px",
                paddingTop: "15px",
                paddingBottom: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "wrap",

                // backgroundColor: "#f5f5f5",
                // flexWrap: "wrap", // Permet le retour à la ligne
                // gap: "20px",
              }}
            >
              <DatePickerComponentV2
                title={translate("ProjectedDischargeDate")}
                width={250}
                value={projectedResidentialDischargeDate}
                onChange={handleProjectedResidentialDischargeDateChange}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 0,
                }}
              >
                <DatePickerComponentV2
                  title={translate("ProjAftercareCompletionDate")}
                  width={250}
                  value={projectedProgramCompletionDate}
                  onChange={handleProjectedProgramCompletionDateChange}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              // backgroundColor: "#f6f6f6",
              marginLeft: "0px ",
              marginTop: "20px",
              paddingBottom: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                width: "100%",
                fontSize: "22px",
                fontWeight: "600",
                textAlign: "left",
                paddingTop: "10px",
                borderTop: "1px solid #cccccc",
              }}
            >
              <span
                id="passport-picture"
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  paddingLeft: "10px",
                }}
              >
                {" "}
                {translate("PassportPictures")}
              </span>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignContent: "center",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              <UploaderGeneralInfosEncapsulatedBisMobileV2 {...props} />
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", marginLeft: "0px" }}>
        {/* <div className="container-main-plan-red-bis"> */}
        {/* <div className="tab-form-container-red"> */}
        <div
          style={{
            paddingBottom: "25px",
            borderTop: "1px solid red",
            paddingTop: "10px",
          }}
        >
          <span
            id="emergency-contact"
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: "red",
              paddingLeft: "10px",
            }}
          >
            {translate("EmergencyContact")}
          </span>
        </div>
        <div
          // className="tab-form-container-fied"
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            paddingLeft: "10px",
            gap: "10px",
          }}
        >
          <InputComponentV2
            title={translate("EmergencyContactFirstName")}
            width="95%"
            value={emergencyContactFirstName}
            onChange={handleEmergencyContactFirstNameChange}
          />
          <InputComponentV2
            title={translate("EmergencyContactLastName")}
            width="95%"
            value={emergencyContactLastName}
            onChange={handleEmergencyContactLastNameChange}
          />
          <DropDownComponentSortedV2
            api="relationship"
            title={translate("Relationship")}
            width="95%"
            value={emergencyContactRelationship}
            onChange={handleEmergencyContactRelationshipChange}
          />

          {emergencyContactRelationship === "Other" ? (
            <InputComponentV2
              title={translate("IfOtherDescribe")}
              width="250px"
              value={emergencyRelationshipOther}
              onChange={handleEmergencyRelationshipOther}
            />
          ) : (
            ""
          )}

          <div style={{ width: "50%" }}>
            <PhoneNumberInput
              title={translate("ContactPhoneNumber")}
              value={emergencyContactPhoneNumber}
              onChange={handleEmergencyContactPhoneNumberChange}
            />
          </div>

          <div style={{ width: "50%" }}>
            <InputComponentV2
              title={translate("ContactEmail")}
              width="250px"
              value={emergencyContactEmail}
              onChange={handleEmergencyContactEmailChange}
            />
          </div>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            marginTop: "20px",
            paddingLeft: "10px",
          }}
        >
          <TextFieldComponentV2Mobile
            title={translate("PhysicalAddressOfResidence")}
            value={emergencyContactAddress}
            onChange={handleEmergencyContactAddressChange}
            width="100%"
          />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            paddingLeft: "10px",
            marginTop: "10px",
          }}
        >
          <InputComponentV2
            title={translate("City")}
            width="250px"
            value={emergencyContactCity}
            onChange={handleEmergencyContactCityChange}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            marginLeft: "10px",
            marginRight: "20px",
            marginTop: "10px",
          }}
        >
          <DropDownComponentV2
            api="nationalities"
            title={translate("Nationality")}
            value={emergencyContactNationality}
            onChange={handleEmergencyContactNationalityChange}
            width="250px"
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            marginLeft: "10px",
            marginRight: "20px",
            marginTop: "10px",
          }}
        >
          <InputComponentV2
            title={translate("StateProvince")}
            width="250px"
            value={provinceEmergencyContact}
            onChange={handleProvinceEmergencyContactChange}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            marginLeft: "10px",
            marginRight: "20px",
            marginTop: "10px",
          }}
        >
          <DropDownComponentSortedV2
            api="countries"
            title={translate("Country")}
            value={emergencyContactCountry}
            onChange={handleEmergencyContactCountryChange}
            width="250px"
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            marginLeft: "10px",
            marginRight: "20px",
            marginTop: "10px",
          }}
        >
          <InputComponentV2
            title={translate("PersonPayingForTreatment")}
            width="250px"
            value={payerName}
            onChange={handlePayerNameChange}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            marginLeft: "10px",
            marginRight: "20px",
            marginTop: "10px",
            marginBottom: "100px",
          }}
        >
          <PhoneNumberInput
            title={translate("PhoneNumberOfPersonPaying")}
            value={payerPhoneNumber}
            onChange={handlePayerPhoneNumberChange}
          />
        </div>
        {/* </div> */}
      </div>

      <div className="button-container-form-gi" style={{ fontSize: "12px" }}>
        <Button
          variant="contained"
          onClick={() => updateClientData(props.clientId)}
          style={
            isModified && !isLoading
              ? {
                  backgroundColor: "red",
                  color: "white",
                  marginLeft: "15px",
                }
              : {
                  backgroundColor: "#1565C0",
                  color: "white",
                  marginLeft: "15px",
                }
          }
          disabled={isLoading}
          sx={{
            height: "40px",
            width: "auto",
            marginLeft: "25px",
            marginRight: "30px",
            fontSize: "12px",
          }}
        >
          {isLoading
            ? translate("SavingModifications")
            : isModified
            ? translate("ClickToSave")
            : translate("SaveModifications")}
        </Button>
      </div>
    </div>
  );
}

export default GeneralInformationV2;
