import React from "react";

function TitleSearchComponentNoFilterCenter(props) {
  return (
    <div
      style={{
        marginRight: "0px",
        // backgroundColor: "yellow",
      }}
    >
      <div style={{ width: props.width }}>
        <div
          style={{
            fontSize: "14px",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            paddingBottom: "0px",
            fontWeight: "700",
            textAlign: "left",
            textAlign: "left",
            paddingLeft: "8px",
            textAlign: "center",
            backgroundColor: "#e4e4e4",
            height: "46px",
          }}
        >
          {props.title}
        </div>
      </div>
    </div>
  );
}

export default TitleSearchComponentNoFilterCenter;
