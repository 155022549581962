export default [
  {
    id: 1,
    textDropDownEn: "--Choose--",
  },

  {
    id: 2,
    textDropDownEn: "Heterosexual",
  },

  {
    id: 3,
    textDropDownEn: "Homosexual",
  },

  {
    id: 4,
    textDropDownEn: "Bisexual",
  },

  {
    id: 5,
    textDropDownEn: "other",
  },
];
