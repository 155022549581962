import React, { useEffect, useState } from "react";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { db } from "../firebase";
import TopTitleBannerV2 from "../component/TopTitleBannerV2";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
  Checkbox,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

function PromptCard({ prompt, onSelect, selected }) {
  return (
    <Card sx={{ marginBottom: "15px", width: "100%", maxWidth: "600px", boxShadow: 3 }}>
      <CardContent>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Checkbox checked={selected} onChange={() => onSelect(prompt.id)} />
          <Box sx={{ marginLeft: "10px" }}>
            <Typography variant="subtitle1" color="textSecondary">
              <strong>User:</strong> {prompt.username}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Timestamp:</strong> {new Date(prompt.timestamp.seconds * 1000).toLocaleString()}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Target:</strong> {prompt.clientTarget || "All Clients"}
            </Typography>
            <Typography variant="body1" color="textPrimary" sx={{ paddingTop: "8px" }}>
              <strong>Content:</strong> {prompt.content}
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ paddingTop: "8px" }}>
              <strong>Response:</strong> {prompt.response || "No response available"}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default function RehappPromptsContent() {
  const [prompts, setPrompts] = useState([]);
  const [filteredPrompts, setFilteredPrompts] = useState([]);
  const [usernames, setUsernames] = useState([]);
  const [selectedUsername, setSelectedUsername] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedPrompts, setSelectedPrompts] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  useEffect(() => {
    const fetchPrompts = async () => {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, "prompts"));
      const fetchedPrompts = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Sort prompts by timestamp (newest first)
      const sortedPrompts = fetchedPrompts.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);

      setPrompts(sortedPrompts);
      setFilteredPrompts(sortedPrompts);
      setLoading(false);

      // Get unique usernames for the dropdown
      const uniqueUsernames = [...new Set(sortedPrompts.map((prompt) => prompt.username))];
      setUsernames(uniqueUsernames);
    };

    fetchPrompts();
  }, []);

  const handleUsernameChange = (event) => {
    const selected = event.target.value;
    setSelectedUsername(selected);

    // Filter prompts based on selected username or show all if no username selected
    const filtered = selected ? prompts.filter((prompt) => prompt.username === selected) : prompts;
    setFilteredPrompts(filtered);
    setSelectedPrompts([]); // Reset selected prompts
  };

  const handleSelectPrompt = (promptId) => {
    setSelectedPrompts((prev) =>
      prev.includes(promptId) ? prev.filter((id) => id !== promptId) : [...prev, promptId]
    );
  };

  const handleDeleteSelected = async () => {
    setDeleting(true);
    try {
      // Only delete selected prompts from the current filtered prompts
      const promptsToDelete = selectedPrompts.filter((id) =>
        filteredPrompts.some((prompt) => prompt.id === id)
      );
      await Promise.all(promptsToDelete.map((id) => deleteDoc(doc(db, "prompts", id))));
      setPrompts((prev) => prev.filter((prompt) => !promptsToDelete.includes(prompt.id)));
      setFilteredPrompts((prev) => prev.filter((prompt) => !promptsToDelete.includes(prompt.id)));
      setSelectedPrompts([]);
    } catch (error) {
      console.error("Error deleting prompts:", error);
    } finally {
      setDeleting(false);
      setConfirmDeleteOpen(false);
    }
  };

  const handleSelectAll = () => {
    if (selectedPrompts.length === filteredPrompts.length) {
      setSelectedPrompts([]);
    } else {
      setSelectedPrompts(filteredPrompts.map((prompt) => prompt.id));
    }
  };

  return (
    <div className="right-container">
      <TopTitleBannerV2 title="Bodhi Prompt" />

      <div style={{ paddingLeft: "60px", paddingTop: "20px", display: "flex", alignItems: "center", gap: "20px" }}>
        <Select
          value={selectedUsername}
          onChange={handleUsernameChange}
          displayEmpty
          style={{ width: "200px" }}
        >
          <MenuItem value="">All Users</MenuItem>
          {usernames.map((username) => (
            <MenuItem key={username} value={username}>
              {username}
            </MenuItem>
          ))}
        </Select>
        <Typography variant="h6" color="textSecondary">
          Total Prompts: {filteredPrompts.length}
        </Typography>
        <Button
          variant="outlined"
          onClick={handleSelectAll}
          sx={{ marginLeft: "auto" }}
        >
          {selectedPrompts.length === filteredPrompts.length ? "Deselect All" : "Select All"}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setConfirmDeleteOpen(true)}
          disabled={selectedPrompts.length === 0 || deleting}
        >
          {deleting ? <CircularProgress size={24} /> : "Delete Selected"}
        </Button>
      </div>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "60vh" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ paddingLeft: "60px", paddingTop: "20px", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
          {filteredPrompts.map((prompt) => (
            <PromptCard
              key={prompt.id}
              prompt={prompt}
              onSelect={handleSelectPrompt}
              selected={selectedPrompts.includes(prompt.id)}
            />
          ))}
        </Box>
      )}

      <Dialog open={confirmDeleteOpen} onClose={() => setConfirmDeleteOpen(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the selected prompts? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteSelected} color="secondary" disabled={deleting}>
            {deleting ? <CircularProgress size={24} /> : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
