import React, { useState } from "react";
import ViewButton from "../Buttons/ViewButton";
import AddButtonRound from "../Buttons/AddButtonRound";
import CheckWithDate from "../CheckWithDate";
import { Box, Modal, Slide } from "@mui/material";
import ViewConfidentialityPolicy from "../forms/ViewConfidentialityPolicy";

function FormLineCP(props) {
  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: "80%",
    borderRadius: 5,
    backgroundColor: "white",
    boxShadow: 24,
  };

  const [showViewModal, setShowViewModal] = useState(false);

  const openViewModal = () => {
    setShowViewModal(true);
  };

  const closeViewModal = () => {
    setShowViewModal(false);
  };
  console.log("showViewModal:", showViewModal);

  return (
    <div
      style={{
        marginLeft: "0px",
        display: "flex",
        flexDirection: "row",
        height: "50px",
        borderRadius: "30px",
        border: "1px solid lightgrey",
        width: "40%",
        marginTop: "5px",
        marginBottom: "5px",
        paddingLeft: "15px",
        paddingRight: "5px",
      }}
    >
      <Modal open={showViewModal} onClose={closeViewModal}>
        <Slide direction="left" in={showViewModal}>
          <Box
            sx={{
              position: "fixed",
              top: "5%",
              right: 0,
              width: "30%",
              height: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              marginRight: "15px",
              pl: 0,
              pr: 0,
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ViewConfidentialityPolicy {...props} closemodal={closeViewModal} />
          </Box>
        </Slide>
      </Modal>
      <div
        style={{
          width: "60%",
          fontSize: "18px",
          fontWeight: "600",
          display: "flex",
          justifyContent: "flex-start",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {props.title}
      </div>
      <div
        style={{
          width: "30%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.signed ? (
          <CheckWithDate checked={true} content={props.date} />
        ) : (
          <CheckWithDate checked={false} content="Not signed" />
        )}
      </div>

      <div
        style={{
          width: "10%",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {props.signed ? (
          <ViewButton handleOpen2={openViewModal} {...props} />
        ) : (
          <AddButtonRound handleOpen={props.handleOpen} />
        )}
      </div>
    </div>
  );
}

export default FormLineCP;
