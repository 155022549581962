import { Box, Tab } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import GeneralInformation from "./ClientsCategory/GeneralInformation";
import ClinicalInformations from "./ClientsCategory/ClinicalInformations";
import Forms from "./ClientsCategory/Forms";
import PreAdmission from "./PreAdmission";
import Swots from "./ClientsCategory/Swots";
import Upload from "./ClientsCategory/Upload";
import Alerts from "./ClientsCategory/Alerts";
import { useAuth } from "../contexts/AuthContext";
import { documentId, onSnapshot, query, where } from "firebase/firestore";
import { roles } from "../firebase.collection";
import { showCustomToast } from "./Misc/CustomToast";
import Admin from "./ClientsCategory/Admin";
import AllNotesPerClientContainer from "./containers/AllNotesPerClientContainer";
import { LanguageContext } from "../context/LanguageContext";

function ClientProfilTab(props) {
  const { translate } = useContext(LanguageContext);
  const { currentUser } = useAuth();
  const userIdState = currentUser ? currentUser.uid : "";
  // const [value, setValue] = useState("2");
  const [value, setValue] = useState(props.selectedTab || "2");
  const [userRole, setUserRole] = useState("");
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const handleModificationChange = (isModified) => {
    setHasUnsavedChanges(isModified);
  };

  const handleChange = (event, newValue) => {
    if (["1", "2", "4"].includes(value) && hasUnsavedChanges) {
      showCustomToast("Don't forget to save your modification!", "error");
    } else {
      setValue(newValue);
    }
  };

  function queryRole() {
    onSnapshot(
      query(roles, where(documentId(), "==", userIdState)),
      (querySnapshot) => {
        const doc = querySnapshot.docs[0];
        if (doc && doc.exists) {
          const role = doc.data().role;

          setUserRole(role);
        } else {
          console.log("No role for this user");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }
    );
  }

  useEffect(() => {
    if (currentUser) {
      queryRole();
    } else {
      console.log("No user id");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <div className="tab-client-container">
      <Box>
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              width: "auto",
            }}
          >
            <TabList
              aria-label="Tabs example"
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label={translate("GeneralInformation")} value="2" />
              <Tab label={translate("PreAdmissionAssessment")} value="1" />
              {userRole !== "Admission staff" &&
              userRole !== "Hospitality Staff" ? (
                <Tab label={translate("AdmissionForms")} value="3" />
              ) : (
                ""
              )}
              {userRole !== "Admission staff" &&
              userRole !== "Hospitality Staff" ? (
                <Tab label={translate("ClinicalInformations")} value="4" />
              ) : (
                ""
              )}
              {userRole !== "Admission staff" ? (
                <Tab label={translate("Notes")} value="5" />
              ) : (
                ""
              )}
              {/* {userRole !== "Admission staff" ? (
                <Tab label="Discharge & Ongoing care" value="6" />
              ) : (
                ""
              )} */}
              <Tab label={translate("UPLOAD")} value="7" />
              <Tab label={translate("Alerts")} value="8" />
              {/* {(userRole === "Super Admin" ||
                userRole === "Admin" ||
                userRole === "Clinical Director") && (
                <Tab label="Admin" value="9" />
              )} */}
            </TabList>
          </Box>
          {(() => {
            switch (value) {
              case "1":
                return (
                  <TabPanel value="1" style={{ paddingLeft: 0 }}>
                    <PreAdmission
                      onModificationChange={handleModificationChange}
                      clientId={props.clientId}
                      {...props}
                    />
                  </TabPanel>
                );
              case "2":
                return (
                  <TabPanel value="2" style={{ paddingLeft: 0 }}>
                    <GeneralInformation
                      onModificationChange={handleModificationChange}
                      clientId={props.clientId}
                      {...props}
                    />
                  </TabPanel>
                );
              case "3":
                return (
                  <TabPanel value="3" style={{ paddingLeft: 0 }}>
                    <Forms clientId={props.clientId} {...props} />
                  </TabPanel>
                );
              case "4":
                return (
                  <TabPanel value="4" style={{ paddingLeft: 0 }}>
                    <ClinicalInformations
                      onModificationChange={handleModificationChange}
                      clientId={props.clientId}
                      {...props}
                    />
                  </TabPanel>
                );
              case "5":
                return (
                  <TabPanel value="5" style={{ paddingLeft: 0 }}>
                    <AllNotesPerClientContainer
                      userId={props.clientId}
                      {...props}
                    />
                    {/* <ClientNotes clientId={props.clientId} {...props} /> */}
                  </TabPanel>
                );
              case "6":
                return (
                  <TabPanel value="6" style={{ paddingLeft: 0 }}>
                    <Swots clientId={props.clientId} {...props} />
                  </TabPanel>
                );
              case "7":
                return (
                  <TabPanel value="7" style={{ paddingLeft: 0 }}>
                    <Upload clientId={props.clientId} {...props} />
                  </TabPanel>
                );

              case "8":
                return (
                  <TabPanel value="8" style={{ paddingLeft: 0 }}>
                    <Alerts
                      clientId={props.clientId}
                      clientIdentifier={props.clientIdentifier}
                      {...props}
                    />
                  </TabPanel>
                );
              case "9":
                return (
                  <TabPanel value="9" style={{ paddingLeft: 0 }}>
                    <Admin
                      clientId={props.clientId}
                      clientIdentifier={props.clientIdentifier}
                      {...props}
                    />
                  </TabPanel>
                );
              default:
                return null;
            }
          })()}
        </TabContext>
      </Box>
    </div>
  );
}

export default ClientProfilTab;
