export default [
  {
    id: 1,
    textDropDownEn: "--Choose--",
  },

  {
    id: 2,
    textDropDownEn: "O",
  },

  {
    id: 3,
    textDropDownEn: "IV",
  },

  {
    id: 4,
    textDropDownEn: "SM",
  },
  {
    id: 5,
    textDropDownEn: "N",
  },
];
