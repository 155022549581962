import { Avatar } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import "../css/navbar.css";
import { useAuth } from "../contexts/AuthContext";
import { documentId, onSnapshot, query, where } from "firebase/firestore";
import { roles } from "../firebase.collection";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/system";
import ListItemIcon from "@mui/material/ListItemIcon";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { showCustomToast } from "./Misc/CustomToast";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../context/LanguageContext";

export default function ProfileWidgetV2(props) {
  const { translate, language, setLanguage } = useContext(LanguageContext);
  const { currentUser, logOut } = useAuth();
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const [userId, setUserId] = useState(currentUser ? currentUser.uid : "");

  const [displayedName, setDisplayedName] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");

  const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  }));
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  async function handleLogout() {
    setError("");
    try {
      await logOut();
      showCustomToast(
        `User ${currentUser.email} successfuly logged out`,
        "success"
      );

      navigate("/login");
    } catch {
      setError("Failed to logout");
      console.log("Error", error);
    }
  }

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true); // Set to true when menu opens
  };

  const handleMenuClose = (callback) => {
    setAnchorEl(null);
    setIsMenuOpen(false);
    if (callback) {
      callback();
    }
  };

  const handleSettings = () => {
    navigate("/account");
  };

  const open = Boolean(anchorEl);

  function queryRole() {
    onSnapshot(
      query(roles, where(documentId(), "==", userId)),
      (querySnapshot) => {
        const doc = querySnapshot.docs[0];
        if (doc && doc.exists) {
          const role = doc.data().role;
          const phone = doc.data().phoneNumber;
          const firstNameFromDb = doc.data().firstName;
          const lastNameFromDb = doc.data().lastName;
          const photoUrlFromDb = doc.data().photoUrl;

          setUserRole(role);
          setLoading(false);

          // Mettre à jour le nom et la photo avec ceux de Firestore s'ils existent
          if (firstNameFromDb && lastNameFromDb) {
            setDisplayedName(`${firstNameFromDb} ${lastNameFromDb}`);
          } else if (currentUser) {
            setDisplayedName(currentUser.displayName);
          }

          if (photoUrlFromDb) {
            setPhotoUrl(photoUrlFromDb);
          } else if (currentUser) {
            setPhotoUrl(currentUser.photoURL);
          }
        } else {
          console.log("Problem loading roles");
        }
      }
    );
  }

  useEffect(() => {
    if (currentUser) {
      queryRole();
    } else {
      console.log("No user id");
    }
  }, [currentUser]);

  let roleColor = "";
  switch (userRole) {
    case "Super Admin":
      roleColor = "red"; // Jaune doré
      break;
    case "Admin":
      roleColor = "#1E90FF"; // Bleu ciel
      break;
    case "Therapist":
      roleColor = "#32CD32"; // Vert lime
      break;
    case "Support Staff":
      roleColor = "#FFA500"; // Orange vif
      break;
    case "Admission staff":
      roleColor = "#c729e9"; // Violet
      break;
    case "Medical Staff":
      roleColor = "#4B0082"; // Indigo
      break;
    case "Hospitality Staff": // Ajout de cette ligne
      roleColor = "#F08080"; // Vous pouvez choisir la couleur que vous préférez
      break;
    default:
      roleColor = "#808080"; // Gris foncé
      break;
  }

  return (
    <div
      className="widget-profile-container-bis"
      style={{
        width: "250px",
        display: "flex",
        justifyContent: "flex-end",
        alignContent: "flex-end",
        alignItems: "flex-end",
        alignSelf: "flex-end",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignContent: "flex-end",
          width: "220px",
        }}
      >
        <div className="avatar-container">
          <Avatar
            src={photoUrl}
            style={{
              width: "40px",
              height: "40px",
              border: "1px solid lightgrey",
              // marginLeft: "20px",
            }}
          />
        </div>
        <div className="profil-widget-container">
          <div className="container-chip-role-profile">
            <div
              className="profil-name"
              title={displayedName}
              style={{ cursor: "pointer", fontSize: "16px", fontWeight: "600" }}
            >
              <span
                className="displayed-name-span"
                style={{
                  display: "inline-block",
                  maxWidth: "190px", // Vous pouvez ajuster cette valeur en fonction de vos besoins
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {displayedName}
              </span>
            </div>
          </div>
          <div
            className="container-chip-role-profile"
            // style={{ backgroundColor: "lightsalmon" }}
          >
            <div className="profil-role-bis" style={{ background: roleColor }}>
              {userRole}
            </div>
          </div>
        </div>
      </div>

      <div>
        <ExpandMoreIcon
          onClick={handleMenuClick}
          style={{
            cursor: "pointer",
            marginLeft: "10px",
            color: "#a9a9a9",
            transform: isMenuOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        />
      </div>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPaper-root": {
            transform: "translateY(40px)",
            width: "200px",
          },
        }}
      >
        <MenuItem onClick={() => handleMenuClose(handleSettings)}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          {translate("Settings")}
        </MenuItem>
        <MenuItem onClick={() => handleMenuClose(handleLogout)}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          {translate("SignOut")}
        </MenuItem>
      </Menu>
    </div>
  );
}
