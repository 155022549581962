import React from "react";

function TitleAlerts() {
  return (
    <div className="title-line-alert" style={{ fontWeight: "500" }}>
      <div className="title-block1" style={{ width: "10%" }}>
        TYPE OF ALERT
      </div>
      <div className="title-block2" style={{ width: "50%" }}>
        CONTENT OF ALERT
      </div>
      <div className="title-block3" style={{ width: "20%" }}>
        DATE
      </div>
      <div className="title-block4" style={{ width: "15%" }}>
        NOTED BY
      </div>
      <div className="title-block4" style={{ width: "5%" }}></div>
    </div>
  );
}

export default TitleAlerts;
