import { Avatar } from "antd";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";

const Container = styled.div`
  border-radius: 10px;
  padding: 8px;
  color: #000;
  margin-bottom: 8px;
  min-height: 120px;
  margin-left: 0px;
  margin-right: 0px;
  background-color: transparent; /* Suppression du background */
  cursor: pointer;
  background-color: white;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border: ${(props) =>
    props.isDragging
      ? "3px solid #1976d2"
      : "2px solid #DCDCDC"}; /* Changement de la couleur de la bordure */
  &:hover {
    border-color: #1976d2; /* La bordure devient bleue lors du survol */
  }
`;

const TextContent = styled.div``;

const Icons = styled.div`
  display: flex;
  justify-content: end;
  padding: 2px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  flex-basis: 60%; /* Occupy 60% of the height */
`;

const Name = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #1c1e21;
`;

const Subtitle = styled.div`
  font-size: 14px;
  color: #6a6d73;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #6a6d73;
  border-top: 1px solid #e0e0e0;
  padding-top: 8px;
  margin-top: 8px;
  flex-basis: 40%; /* Occupy 40% of the height */
`;

function stringToColor(string) {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }
  return color;
}

export default function Card({
  task,
  index,
  firstName,
  lastName,
  sex,
  country,
  addiction,
  onSelectCard,
}) {
  const avatarColor = stringToColor(firstName);
  return (
    <Draggable draggableId={`${task.id}`} key={task.id} index={index}>
      {(provided, snapshot) => (
        // <Container
        //   {...provided.draggableProps}
        //   {...provided.dragHandleProps}
        //   ref={provided.innerRef}
        //   isDragging={snapshot.isDragging}
        // >
        <Container
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
          onClick={(e) => {
            e.stopPropagation(); // Empêche l'interaction avec le drag-and-drop
            onSelectCard(task); // Fonction pour sélectionner la carte pour édition
          }}
        >
          <Header>
            <Avatar
              size={40}
              style={{ backgroundColor: avatarColor, marginRight: "12px" }}
            >
              {firstName.charAt(0)}
            </Avatar>
            <div>
              <Name>{`${firstName} ${lastName}`}</Name>
              <Subtitle>
                Sex:{" "}
                <span style={{ fontWeight: "500", paddingLeft: "5px" }}>
                  {sex ? sex : "no sex filled"}
                </span>
              </Subtitle>
            </div>
          </Header>
          <Footer>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                Country:
                <span style={{ fontWeight: "500", paddingLeft: "5px" }}>
                  {country ? country : "no country filled"}
                </span>
              </div>
              <div>
                Addiction:
                <span style={{ fontWeight: "500", paddingLeft: "5px" }}>
                  {" "}
                  {addiction ? addiction : "no addiction filled"}
                </span>{" "}
              </div>
            </div>
          </Footer>

          {provided.placeholder}
        </Container>
      )}
    </Draggable>
  );
}
