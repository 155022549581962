import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import DatePickerWithTimeComponent from "../DatePickerWithTimeComponent";
import { useAuth } from "../../contexts/AuthContext";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { showCustomToast } from "../Misc/CustomToast";

function AddHandoverNote(props) {
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [immmigrationAppointment, setImmmigrationAppointment] = useState("");
  const [dischargesSwot, setDischargeSwot] = useState("");
  const [newClients, setNewClients] = useState("");
  const [medicalInfos, setMedicalInfos] = useState("");
  const [hospitalAppointment, setHospitalAppointment] = useState("");
  const [residentialClientMovements, setResidentialClientMovements] =
    useState("");
  const [medicalNote, setMedicalNote] = useState("");
  const [zoomSessionAssistance, setZoomSessionAsssistance] = useState("");
  const [staffToday, setStaffToday] = useState("");
  const [overnight, setOvernight] = useState("");
  const [numberOfClient, setNumberOfClient] = useState("");
  const [swot, setSwot] = useState("");
  const [generalAnnouncement, setGeneralAnnouncement] = useState("");
  const [handoverNoteDateTime, setHandoverNoteDateTime] = useState(new Date());

  const handleImmagrationAppointment = (event) => {
    setImmmigrationAppointment(event.target.value);
  };

  const handleStaffToday = (event) => {
    setStaffToday(event.target.value);
  };

  const handleSwot = (event) => {
    setSwot(event.target.value);
  };

  const handleNumberOfclient = (event) => {
    setNumberOfClient(event.target.value);
  };

  const handleOvernight = (event) => {
    setOvernight(event.target.value);
  };

  const handleGeneralAnnoucement = (event) => {
    setGeneralAnnouncement(event.target.value);
  };

  const handleDischargeSwot = (event) => {
    setDischargeSwot(event.target.value);
  };

  const handleZoomSessionAssistance = (event) => {
    setZoomSessionAsssistance(event.target.value);
  };

  const handleNewClients = (event) => {
    setNewClients(event.target.value);
  };

  const handleMedicalInfos = (event) => {
    setMedicalInfos(event.target.value);
  };

  const handleHospitalAppointment = (event) => {
    setHospitalAppointment(event.target.value);
  };

  const handleResidentialClientMovements = (event) => {
    setResidentialClientMovements(event.target.value);
  };

  const handleMedicalNotes = (event) => {
    setMedicalNote(event.target.value);
  };

  const handleHandoverNoteDateTimes = (event) => {
    setHandoverNoteDateTime(event);
  };

  const handleAddHandoverNote = async (effectiveDateTime) => {
    const noteData = {
      noteType: "handoverNote",
      createdAt: serverTimestamp(),
      effectiveDateTime: effectiveDateTime,
      doneBy: currentUser.displayName,
      immmigrationAppointment: immmigrationAppointment,
      dischargesSwot: dischargesSwot,
      newClients: newClients,
      medicalInfos: medicalInfos,
      hospitalAppointment: hospitalAppointment,
      residentialClientMovements: residentialClientMovements,
      // noteContent: note,
      zoomSessionAssistance: zoomSessionAssistance,
      generalAnnouncement: generalAnnouncement,
      staffToday: staffToday,
      overnight: overnight,
      numberOfClient: numberOfClient,
      swot: swot,
    };

    if (
      immmigrationAppointment &&
      dischargesSwot &&
      newClients &&
      medicalInfos &&
      hospitalAppointment &&
      residentialClientMovements &&
      zoomSessionAssistance &&
      staffToday &&
      generalAnnouncement &&
      overnight &&
      numberOfClient &&
      swot
    ) {
      setIsLoading(true);
      try {
        const docRef = await addDoc(collection(db, "notes"), noteData);
        showCustomToast("Handover note successfully added!", "success");

        setIsLoading(false);
        props.closemodal();
      } catch (error) {
        console.log("error adding note :", error);
        showCustomToast("Problem when adding note!", "error");
      }
    } else {
      showCustomToast(
        "Some mandatory fields are empty. Please check.!",
        "error"
      );
    }
  };

  const handleSubmit = () => {
    handleAddHandoverNote(handoverNoteDateTime);
  };

  return (
    <>
      <div
        className="inputAndLabelModalContainerTitle2"
        style={{ width: "auto", height: "100%" }}
      >
        <div className="line1-modal-percent">
          <div className="modal-title">Add handover note</div>
          <CloseIcon
            style={{
              marginRight: "16px",
              color: "rgb(214, 213, 213)",
              cursor: "pointer",
            }}
            onClick={props.closemodal}
          />
        </div>
        <div
          style={{
            width: "100%",
            marginTop: "10px",
            height: "75%",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <div className="line2-column-modal-handover">
            <div className="tab-form-container-handover">
              <div className="textfield-and-label-container100">
                <div className="label-textfield-handover">
                  Immigration appointment / visa
                </div>
                <TextField
                  //   value={picture2SubText}
                  //   onChange={(e) => setPicture2SubText(e.target.value)}

                  style={{
                    width: "95%",
                    borderRadius: "5px",
                    backgroundColor: "#f2eeef",
                  }}
                  placeholder="..."
                  multiline
                  rows={5}
                  onChange={handleImmagrationAppointment}
                />
              </div>
            </div>
            <div className="tab-form-container-handover">
              <div className="textfield-and-label-container100">
                {" "}
                <div className="label-textfield-handover">
                  Discharges / Flights arrangements
                </div>
                <TextField
                  //   value={picture2SubText}
                  //   onChange={(e) => setPicture2SubText(e.target.value)}

                  style={{
                    width: "95%",
                    borderRadius: "5px",
                    backgroundColor: "#f2eeef",
                  }}
                  placeholder="..."
                  multiline
                  rows={5}
                  onChange={handleDischargeSwot}
                />
              </div>
            </div>
            <div className="tab-form-container-handover">
              <div className="textfield-and-label-container100">
                <div className="label-textfield-handover">New clients</div>
                <TextField
                  //   value={picture2SubText}
                  //   onChange={(e) => setPicture2SubText(e.target.value)}

                  style={{
                    width: "95%",
                    borderRadius: "5px",
                    backgroundColor: "#f2eeef",
                  }}
                  placeholder="..."
                  multiline
                  rows={5}
                  onChange={handleNewClients}
                />
              </div>
            </div>
            <div className="tab-form-container-handover">
              <div className="textfield-and-label-container100">
                {" "}
                <div className="label-textfield-handover">
                  Specialist doctor Appointments
                </div>
                <TextField
                  //   value={picture2SubText}
                  //   onChange={(e) => setPicture2SubText(e.target.value)}

                  style={{
                    width: "95%",
                    borderRadius: "5px",
                    backgroundColor: "#f2eeef",
                  }}
                  placeholder="..."
                  multiline
                  rows={5}
                  onChange={handleMedicalInfos}
                />
              </div>
            </div>
            <div className="tab-form-container-handover">
              <div className="textfield-and-label-container100">
                <div className="label-textfield-handover">
                  (Hospital) appointments
                </div>
                <TextField
                  //   value={picture2SubText}
                  //   onChange={(e) => setPicture2SubText(e.target.value)}

                  style={{
                    width: "95%",
                    borderRadius: "5px",
                    backgroundColor: "#f2eeef",
                  }}
                  placeholder="..."
                  multiline
                  rows={5}
                  onChange={handleHospitalAppointment}
                />
              </div>
            </div>
            <div className="tab-form-container-handover">
              <div className="textfield-and-label-container100">
                {" "}
                <div className="label-textfield-handover">Outings</div>
                <TextField
                  //   value={picture2SubText}
                  //   onChange={(e) => setPicture2SubText(e.target.value)}

                  style={{
                    width: "95%",
                    borderRadius: "5px",
                    backgroundColor: "#f2eeef",
                  }}
                  placeholder="..."
                  multiline
                  rows={5}
                  onChange={handleResidentialClientMovements}
                />
              </div>
            </div>
            <div className="tab-form-container-handover">
              <div
                className="textfield-and-label-container100"
                style={{ marginTop: "20px" }}
              >
                {" "}
                <div className="label-textfield-handover">
                  Zoom session assistance
                </div>
                <TextField
                  //   value={picture2SubText}
                  //   onChange={(e) => setPicture2SubText(e.target.value)}

                  style={{
                    width: "95%",
                    borderRadius: "5px",
                    backgroundColor: "#f2eeef",
                  }}
                  placeholder="..."
                  multiline
                  rows={5}
                  onChange={handleZoomSessionAssistance}
                />
              </div>
            </div>
            <div className="tab-form-container-handover">
              <div
                className="textfield-and-label-container100"
                style={{ marginTop: "20px" }}
              >
                {" "}
                <div className="label-textfield-handover">Staff today</div>
                <TextField
                  //   value={picture2SubText}
                  //   onChange={(e) => setPicture2SubText(e.target.value)}

                  style={{
                    width: "95%",
                    borderRadius: "5px",
                    backgroundColor: "#f2eeef",
                  }}
                  placeholder="..."
                  multiline
                  rows={5}
                  onChange={handleStaffToday}
                />
              </div>
            </div>
            <div className="tab-form-container-handover">
              <div
                className="textfield-and-label-container100"
                style={{ marginTop: "20px" }}
              >
                {" "}
                <div className="label-textfield-handover">Overnight</div>
                <TextField
                  //   value={picture2SubText}
                  //   onChange={(e) => setPicture2SubText(e.target.value)}

                  style={{
                    width: "95%",
                    borderRadius: "5px",
                    backgroundColor: "#f2eeef",
                  }}
                  placeholder="..."
                  multiline
                  rows={5}
                  onChange={handleOvernight}
                />
              </div>
            </div>
            <div className="tab-form-container-handover">
              <div
                className="textfield-and-label-container100"
                style={{ marginTop: "20px" }}
              >
                {" "}
                <div className="label-textfield-handover">
                  Number of clients
                </div>
                <TextField
                  style={{
                    width: "95%",
                    borderRadius: "5px",
                    backgroundColor: "#f2eeef",
                  }}
                  placeholder="..."
                  multiline
                  rows={5}
                  onChange={handleNumberOfclient}
                />
              </div>
            </div>
            <div className="tab-form-container-handover">
              <div
                className="textfield-and-label-container100"
                style={{ marginTop: "20px" }}
              >
                {" "}
                <div className="label-textfield-handover">Swot</div>
                <TextField
                  style={{
                    width: "95%",
                    borderRadius: "5px",
                    backgroundColor: "#f2eeef",
                  }}
                  placeholder="..."
                  multiline
                  rows={5}
                  onChange={handleSwot}
                />
              </div>

              <div className="tab-form-container-handover">
                <div className="label-textfield-handover">
                  General Announcement / Discussion
                </div>
                <TextField
                  //   value={picture2SubText}
                  //   onChange={(e) => setPicture2SubText(e.target.value)}

                  style={{
                    width: "100%",
                    borderRadius: "5px",
                    backgroundColor: "#f2eeef",
                  }}
                  placeholder="Type medical note here"
                  multiline
                  rows={5}
                  onChange={handleGeneralAnnoucement}
                />

                {/* <TextField
                  //   value={picture2SubText}
                  //   onChange={(e) => setPicture2SubText(e.target.value)}

                  style={{
                    width: "100%",
                    borderRadius: "5px",
                    backgroundColor: "#f2eeef",
                  }}
                  placeholder="Type medical note here"
                  multiline
                  rows={3}
                  onChange={handleMedicalNotes}
                /> */}
              </div>
              <div
                className="textfield-and-label-container"
                style={{
                  // marginLeft: "20px",
                  paddingLeft: "0px",
                  marginBottom: "20px",
                }}
              >
                <DatePickerWithTimeComponent
                  value={handoverNoteDateTime}
                  onChange={handleHandoverNoteDateTimes}
                  title="Effective date and time"
                  leftMargin="none"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="line4-modal-percent">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
              onClick={props.closemodal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                    }
              }
              disabled={isLoading}
              onClick={handleSubmit}
            >
              {isLoading ? "ADDING NOTE..." : "ADD NOTE"}
            </Button>
          </>
        </div>
      </div>
    </>
  );
}

export default AddHandoverNote;
