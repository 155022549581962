import React from "react";

function TitleAlertsMobileV2() {
  return (
    <div className="title-line-alert" style={{ fontWeight: "500" }}>
      <div
        className="title-block1"
        style={{ width: "40%", paddingLeft: "10px" }}
      >
        TYPE OF ALERT
      </div>
      {/* <div className="title-block2" style={{ width: "50%" }}>
        CONTENT OF ALERT
      </div> */}
      <div className="title-block3" style={{ width: "30%" }}>
        DATE
      </div>
      <div className="title-block4" style={{ width: "30%" }}>
        NOTED BY
      </div>
      {/* <div className="title-block4" style={{ width: "5%" }}></div> */}
    </div>
  );
}

export default TitleAlertsMobileV2;
