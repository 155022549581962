export default [
  {
    id: 1,
    textDropDownEn: "--Choose--",
  },

  {
    id: 2,
    textDropDownEn: "Wife",
  },

  {
    id: 3,
    textDropDownEn: "Husband",
  },

  {
    id: 4,
    textDropDownEn: "Parent",
  },

  {
    id: 5,
    textDropDownEn: "Relative",
  },
];
