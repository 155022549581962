import React, { useContext } from "react";
import Navbar from "../component/Navbar";
import "../css/dashboard.css";
import NoteContent from "./NoteContent";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, Tooltip } from "@mui/material";
import NoteContentMobile from "./NoteContentMobile";
import NavbarV2 from "../component/NavbarV2";
import NoteContentV2Mobile from "./NoteContentV2Mobile";
import { LanguageContext } from "../context/LanguageContext";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ProfileWidgetMobileNoRole from "../component/ProfileWidgetMobileNoRole";

export default function Notes() {
  const { translate } = useContext(LanguageContext);
  const navigate = useNavigate();
  const location = useLocation();

  const handleMenuOpen = () => {
    navigate("/menumobile");
  };
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

  return (
    <div className="main-container">
      {isMobile ? (
        <>
          {/* <div className="menu-button-container">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuOpen}
              className="menu-button"
            >
              <MenuIcon />
            </IconButton>
          </div> */}
          <div
            className="menu-button-container-mobile"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingRight: "0%",
            }}
          >
            <div
              style={{
                width: "30%",
                paddingLeft: "5%",
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuOpen}
                className="menu-button"
              >
                <MenuIcon />
              </IconButton>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "40%",
                alignItems: "center",
                alignContent: "center",
                alignSelf: "center",
                fontWeight: "700",
              }}
            >
              {translate("Notes")}
            </div>
            <div
              style={{
                width: "30%",
                paddingTop: "0px",
                paddingBottom: "0px",
                display: "flex",
                justifyContent: "end",
                alignContent: "center",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* <Tooltip
                  title={translate("ClickHereForHelp")}
                  placement="bottom"
                >
                  <HelpOutlineIcon
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                      color: "#a9a9a9",
                    }}
                  />
                </Tooltip> */}
                {/* <Tooltip title={translate("Settings")} placement="bottom">
                  <SettingsIcon
                    onClick={handleSettings}
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                      color: "#a9a9a9",
                    }}
                  />
                </Tooltip> */}
              </div>
              <ProfileWidgetMobileNoRole />
            </div>
          </div>

          <NoteContentV2Mobile />
        </>
      ) : (
        <>
          <NavbarV2 linkActive={2} />
          <NoteContent />
        </>
      )}
    </div>
  );
}
