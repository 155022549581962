import { Button } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import SignaturePad from "react-signature-canvas";
import { LanguageContext } from "../../context/LanguageContext";

function SignaturePadComponentV2Mobile({
  onSignatureSave,
  signatureDataURL,
  toWho,
  onSignatureValidate,
}) {
  const { translate } = useContext(LanguageContext);
  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  const [isSignatureStarted, setIsSignatureStarted] = useState(false);
  const [signatureValidated, setSignatureValidated] = useState(false);

  const sigPad = useRef();

  const getDataURLFromCanvas = () => {
    return sigPad.current.getTrimmedCanvas().toDataURL("image/png");
  };

  const handleClear = () => {
    sigPad.current.clear();
    setTrimmedDataURL(null);
    onSignatureSave(null);
    setSignatureValidated(false);
    onSignatureValidate(false);
  };

  const handleSave = () => {
    const dataURL = getDataURLFromCanvas();
    setTrimmedDataURL(dataURL);
    onSignatureSave(dataURL);
    setSignatureValidated(true);
    onSignatureValidate(true);
  };

  useEffect(() => {
    if (sigPad.current) {
      sigPad.current.onBegin = () => {
        setIsSignatureStarted(true);
        setTrimmedDataURL(null);
        setSignatureValidated(false);
        onSignatureValidate(false);
      };
    }
  }, [sigPad, onSignatureValidate]);

  return (
    <div className="signature-container-column">
      <div
        style={{
          marginBottom: "0px",
          paddingBottom: "0px",
          textAlign: "center",
          paddingBottom: "5px",
          fontSize: "12px",
        }}
      >
        {translate("PleaseSignBelow")}
      </div>
      <SignaturePad
        penColor="black"
        velocityFilterWeight={0.7}
        minWidth={0.5}
        maxWidth={2.5}
        canvasProps={{
          // width: 300,
          // height: 200,
          className: "sigCanvas",
          style: { margin: 0, padding: 0, display: "block" },
        }}
        ref={sigPad}
      />
      <div className="right-signature-container-2">
        <div className="signature-validation-message-bis">
          {signatureValidated ? (
            <span
              style={{
                color: "green",
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {translate("SignatureValidated")}
            </span>
          ) : (
            <span style={{ color: "red", paddingBottom: "10px" }}>
              {translate("SignatureNotValidated")}
            </span>
          )}
        </div>

        <div className="signature-buttons-container-bis">
          <div style={{ width: "50%" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "red",
                  color: "white",
                  width: "80%",
                  fontSize: "12px",
                }}
                // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
                onClick={handleClear}
              >
                {translate("Clear")}
              </Button>
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "green",
                  color: "white",
                  width: "80%",
                  fontSize: "12px",
                }}
                onClick={handleSave}
                // disabled={!trimmedDataURL || !isSignatureStarted}
              >
                {translate("Validate")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignaturePadComponentV2Mobile;
