import React, { useContext, useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Tooltip from "@mui/material/Tooltip";
import { LanguageContext } from "../../context/LanguageContext";

function TitleNoteCompactClientMobile(props) {
  const { translate } = useContext(LanguageContext);
  const [clientSortOrder, setClientSortOrder] = useState("asc");
  const [addictionSortOrder, setAddictionSortOrder] = useState("asc");
  const [akaSortOrder, setAkaSortOrder] = useState("asc");
  const [admissionSortOrder, setAdmissionSortOrder] = useState("asc");
  const [pddSortOrder, setPddSortOrder] = useState("asc");
  const [ilsSortOrder, setIlsSortOrder] = useState("asc");

  const handleClientSort = () => {
    const newOrder = clientSortOrder === "asc" ? "desc" : "asc";
    setClientSortOrder(newOrder);
    props.handleSort(
      `client${newOrder.charAt(0).toUpperCase() + newOrder.slice(1)}`
    );
  };

  const handleAddictionSort = () => {
    const newOrder = addictionSortOrder === "asc" ? "desc" : "asc";
    setAddictionSortOrder(newOrder);
    props.handleSort(
      `addiction${newOrder.charAt(0).toUpperCase() + newOrder.slice(1)}`
    );
  };

  const handleAkaSort = () => {
    const newOrder = akaSortOrder === "asc" ? "desc" : "asc";
    setAkaSortOrder(newOrder);
    props.handleSort(
      `aka${newOrder.charAt(0).toUpperCase() + newOrder.slice(1)}`
    );
  };

  const handleAdmissionSort = () => {
    const newOrder = admissionSortOrder === "asc" ? "desc" : "asc";
    setAdmissionSortOrder(newOrder);
    props.handleSort(
      `admission${newOrder.charAt(0).toUpperCase() + newOrder.slice(1)}`
    );
  };

  const handlePddSort = () => {
    const newOrder = pddSortOrder === "asc" ? "desc" : "asc";
    setPddSortOrder(newOrder);
    props.handleSort(
      `pdd${newOrder.charAt(0).toUpperCase() + newOrder.slice(1)}`
    );
  };

  const handleIlsSort = () => {
    const newOrder = ilsSortOrder === "asc" ? "desc" : "asc";
    setIlsSortOrder(newOrder);
    props.handleSort(
      `ils${newOrder.charAt(0).toUpperCase() + newOrder.slice(1)}`
    );
  };

  return (
    <div style={{ paddingLeft: "0px" }}>
      <div
        style={{
          width: "100%",
          height: "30px",
          display: "flex",
          flexDirection: "row",
          borderBottom: "1px solid lightgrey",
          cursor: "pointer",
          transition: "background-color 0.3s ease",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "40%",
              height: "15px",
              display: "flex",
              color: "#a3a3a4",
              textAlign: "center",
              fontWeight: "500",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              alignSelf: "center",
              fontSize: "10px",
            }}
          >
            {translate("ClientC")}
          </div>

          <div
            style={{
              width: "20%",
              height: "15px",
              display: "flex",
              color: "#a3a3a4",
              textAlign: "center",
              fontWeight: "500",
              justifyContent: "center",
              fontSize: "10px",

              alignContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            {translate("ADDICTION")}
          </div>

          <div
            style={{
              width: "20%",
              height: "15px",
              display: "flex",
              color: "#a3a3a4",
              alignItems: "center",
              fontWeight: "500",
              fontSize: "10px",
              justifyContent: "center",

              alignContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            {translate("ROOM")}
          </div>
          <div
            style={{
              // backgroundColor: "lightcoral",
              width: "20%",
              height: "15px",
              display: "flex",
              color: "#a3a3a4",
              alignItems: "center",
              fontWeight: "500",

              fontSize: "10px",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            {translate("Alerts")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TitleNoteCompactClientMobile;
