import React, { useContext } from "react";
import UploaderGeneralEncapsulatedV2 from "../Updloader/UploaderGeneralEncapsulatedV2";
import UploaderTherapistencapsulatedV2 from "../Updloader/UploaderTherapistencapsulatedV2";
import { LanguageContext } from "../../context/LanguageContext";

function Upload(props) {
  const { translate } = useContext(LanguageContext);
  return (
    <div className="tab-container-white-v2">
      <div className="upload-content-container">
        <div className="tab-form-container5Top">
          {" "}
          <div className="tab-container-title3">
            {translate("GeneralDocuments")}
          </div>
          <UploaderGeneralEncapsulatedV2 {...props} />
        </div>
        <div className="tab-form-container5Top">
          <div className="tab-container-title3">
            {translate("TherapistsDocuments")}
          </div>

          <UploaderTherapistencapsulatedV2 {...props} />
        </div>
      </div>
    </div>
  );
}

export default Upload;
