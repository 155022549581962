import React, { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";

function TitleMediumRoi() {
  const { translate } = useContext(LanguageContext);
  return (
    <div className="title-line-medium-roi-v2">
      <div className="title-roi-block1">
        {translate("CompanyIndividualName")}
      </div>
      <div className="title-roi-block1bis">{translate("Relationship")}</div>
      <div
        // className="title-roi-block2"
        style={{
          textAlign: "center",
          // display: "flex",
          // alignItems: "center",
          width: "15%",
          height: "100%",
          height: "auto",
        }}
      >
        {translate("Filled")}
      </div>
      <div className="title-roi-block2bis">{translate("Revoked")} </div>
      <div
        // className="title-roi-block3"
        style={{
          textAlign: "center",
          // display: "flex",
          // alignItems: "center",
          width: "19%",
          height: "100%",
          height: "auto",
        }}
      >
        {translate("Actions")}
      </div>
    </div>
  );
}

export default TitleMediumRoi;
