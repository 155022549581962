import { TextField } from "@mui/material";
import React from "react";

export default function TextFieldComponentLarge(props) {
  const handleChange = (event) => {
    props.onChange(event.target.value);
  };
  return (
    <div className="input-label-container">
      <div className="input-label">
        {" "}
        {props.title}{" "}
        {props.isRequired && <span style={{ color: "red" }}>*</span>}
      </div>
      <TextField
        //   value={takeProfitState}
        //   onChange={(e) => setTakeProfitState(e.target.value)}
        style={{ width: 550, backgroundColor: "#FFFFFF", marginTop: 7 }}
        placeholder={props.placeholder}
        multiline
        rows={4}
        onChange={handleChange}
        value={props.value}
      />
    </div>
  );
}
