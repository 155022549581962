import React from "react";
import DateToday from "./DateToday";

export default function TopTitleBannerMobile(props) {
  return (
    <div className="main-top-title-banner-container">
      <div className="date-container-mobile">
        <DateToday />{" "}
        <div
          style={{
            backgroundColor: "green",
            marginLeft: "10px",
            borderRadius: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
            color: "white",
            fontSize: "10px",
          }}
        >
          DEMO VERSION
        </div>
      </div>
      <div className="title-text-banner-mobile">{props.title}</div>
    </div>
  );
}
