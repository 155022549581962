// firebaseMetrics.js

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const metricsApp = initializeApp(
  {
    apiKey: process.env.REACT_APP_METRICS_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_METRICS_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_METRICS_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_METRICS_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_METRICS_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_METRICS_FIREBASE_APPID,
  },
  "metricsApp"
);

export const metricsDb = getFirestore(metricsApp);
