import { Input, TextField } from "@mui/material";
import React from "react";

export default function InputComponentNoTitle(props) {
  const handleChange = (event) => {
    props.onChange(event.target.value);
  };
  return (
    <div className="input-label-container-newdesign">
      {/* <div className="input-label">
        {" "}
        {props.title}{" "}
        {props.isRequired && <span style={{ color: "red" }}>*</span>}
      </div> */}

      <Input
        variant="outlined"
        // placeholder="Search client…"
        onChange={handleChange}
        value={props.value}
        style={{
          width: props.width,
          height: "40px",
          borderRadius: "5px",
          background: "#FFFFFF",
          border: "1px solid #BEBCBC",
          marginTop: "2px",
          paddingLeft: "8px",
        }}
        placeholder={props.placeholder}
      />
    </div>
  );
}
