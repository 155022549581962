import { Avatar } from "@mui/material";
import React from "react";

import Avatar1 from "../images/Avatar1.png";
import Avatar2 from "../images/Avatar2.png";
import AugustinAvatar from "../images/augustine-pic.png";
import PaulaAvatar from "../images/paula-pic.png";

export default function SmallClientChipV2LargeMobile(props) {
  const avatarSrc =
    props.avatar === "1"
      ? Avatar1
      : props.avatar === "2"
      ? Avatar2
      : props.avatar === "Paula"
      ? PaulaAvatar
      : AugustinAvatar;

  return (
    <div className="small-client-chip-container-mobile-v2" style={{}}>
      <Avatar
        style={{
          width: "40px",
          height: "40px",
          border: "1px solid lightgrey",
        }}
        src={props.clientPic}
      />
      <div
        className="small-client-chip-name-large"
        style={{ fontSize: "16px", fontWeight: "600" }}
      >
        {props.firstName} {props.lastName}
      </div>
    </div>
  );
}
