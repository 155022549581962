import { Button, CircularProgress } from "@mui/material";
import { updateDoc, doc } from "firebase/firestore";
import { useState } from "react";
import { toast } from "react-toastify";
import TopTitleFormComponent from "./TopTitleFormComponent";
import DropDownComponent from "../DropDownComponent";
import { db } from "../../firebase";

export default function EditRoleMobile(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState(props.roleUser);

  const handleRoleChange = (event) => {
    setSelectedRole(event);
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await updateDoc(doc(db, "roles", props.userId), { role: selectedRole });
      toast.success("Role updated successfully!");
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while updating the role.");
    }
    setIsLoading(false);
    props.closemodal();
  };

  return (
    <>
      <div
        className="inputAndLabelModalContainerTitle2"
        style={{ position: "fixed", top: 0, left: 0, width: "100%", zIndex: 1 }}
      >
        <TopTitleFormComponent
          title={`Edit ${props.userName} role`}
          closemodal={props.closemodal}
        />
      </div>

      <div style={{ marginTop: "60px", height: "250px" }}>
        <div className="tab-form-container-centered">
          <DropDownComponent
            api="roles"
            title="User role"
            value={selectedRole}
            onChange={handleRoleChange}
          />
        </div>

        <div className="line4-modal-centered">
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress size={24} color="secondary" />
              ) : (
                "Save"
              )}
            </Button>
          </>
        </div>
      </div>
    </>
  );
}
