export default [
  {
    id: 1,
    textDropDownEn: "--Choose--",
  },
  {
    id: 2,
    textDropDownEn: "1 - Invoice Requested",
  },

  {
    id: 3,
    textDropDownEn: "2 - Invoice Sent",
  },

  // {
  //   id: 4,
  //   textDropDownEn: "3 - Pending Release My Super",
  // },

  {
    id: 5,
    textDropDownEn: "4 - Deposit Paid",
  },
  {
    id: 6,
    textDropDownEn: "5 - Paid in full",
  },
  {
    id: 7,
    textDropDownEn: "6 - Pay on arrival",
  },
];
