import React, { useContext, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import TopTitleFormComponent from "./TopTitleFormComponent";
import DatePickerComponent from "../DatePickerComponent";
import { Button, Checkbox } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebase";
import {
  collection,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadString,
} from "firebase/storage";

import { doc as firestoreDoc } from "firebase/firestore";
import { showCustomToast } from "../Misc/CustomToast";
import SignaturePadComponentV2 from "../Signature/SignaturePadComponentV2";
import { LanguageContext } from "../../context/LanguageContext";

function AddEmergencyDischargeForm(props) {
  const { translate } = useContext(LanguageContext);
  const { currentUser } = useAuth();
  const { clientId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const clientIdState = props.clientId;
  const [, setDischargeDate] = useState(null);
  const [voluntaryDischarge, setVoluntaryDischarge] = useState(null);

  const [voluntaryDischargeAcknoledgment, setVoluntaryDischargeAcknoledgment] =
    useState(false);
  const [releaseAgreement, setReleaseAgreement] = useState(false);
  const [treatmentAcknowledge, setTreatmentAcknowledge] = useState(false);
  const [finalUnderstanding, setFinalUnderstanding] = useState(false);
  const [, setIsSignatureStarted] = useState(false);

  const text1 =
    "This agreement releases our rehab (Thailand) Ltd from all duty of care responsibilities and liability for myself once I discharge from the treatment program.";
  const text2 =
    "I also acknowledge that my Treatment Program with our rehab commenced on the above stated admission date, and ceased due to an unforeseeable emergency event that has been reported to the our rehab Clinical Director with my rehabilitation treatment emergency discharge (on compassionate grounds) effective on:";
  const text3 = " I agree that my discharge is voluntary.";

  const [signatureDataURL, setSignatureDataURL] = useState(null);
  const [isSignatureValidated, setIsSignatureValidated] = useState(false);

  const handleDischargeDateChange = (date) => {
    setDischargeDate(date);
  };

  const handleVolontaryDischargeDateChange = (date) => {
    setVoluntaryDischarge(date);
  };

  const handleVoluntaryDischargeAcknoledgementChange = (checked) => {
    setVoluntaryDischargeAcknoledgment(checked.target.checked);
  };

  const handleReleaseAgreementChange = (checked) => {
    setReleaseAgreement(checked.target.checked);
  };

  const handleTreatmentAcknowledgeChange = (checked) => {
    setTreatmentAcknowledge(checked.target.checked);
  };

  const handleFinalUnderstandingChange = (checked) => {
    setFinalUnderstanding(checked.target.checked);
  };

  const handleClientSignatureChange = (signature) =>
    setSignatureDataURL(signature);

  //FUNCTIONS

  const handleFormSubmit = async () => {
    // if (
    //   !voluntaryDischarge ||
    //   !dischargeDate ||
    //   !finalUnderstanding ||
    //   !signatureDataURL ||
    //   !isSignatureValidated
    // ) {
    //   showCustomToast("Please check mandatory fields", "error");
    // }

    setIsLoading(true);

    try {
      const emergencyDischargeData = {
        releaseAgreement,
        treatmentAcknowledge,
        voluntaryDischarge,
        voluntaryDischargeAcknoledgment,
        finalUnderstanding,
        signedOn: serverTimestamp(),
        staffName: currentUser.displayName,
        text1,
        text2,
        text3,
        // text4,
        // witnessName,
        // directorName,
      };

      const emergencyDischargeFormsRef = collection(db, "EmergencyDischarge");
      const emergencyDischargeFormDocRef = doc(
        emergencyDischargeFormsRef,
        clientIdState
      );

      const newDoc = await setDoc(
        emergencyDischargeFormDocRef,
        emergencyDischargeData
      );

      const newDocId = emergencyDischargeFormDocRef.id;

      const fileName1 = `${clientId}-${newDocId}-client.png`;

      const storage1 = getStorage();
      const signatureRef1 = ref(storage1, `EmergencyDischarges/${fileName1}`);
      await uploadString(signatureRef1, signatureDataURL, "data_url");
      const signatureURL1 = await getDownloadURL(signatureRef1);

      await updateDoc(emergencyDischargeFormDocRef, {
        signatureClientUrl: signatureURL1,
        // signatureStaffUrl: signatureURL2,
        // signatureManagerUrl: signatureURL3,
      });

      const clientDocRef = firestoreDoc(db, "clients", clientIdState);
      await updateDoc(clientDocRef, {
        EmergencyDischargeOk: true,
        EmergencyDischargeDateSigned: serverTimestamp(),
      });

      showCustomToast(
        "Emergency discharge form correctly submitted!",
        "success"
      );

      setIsLoading(false);
      props.closemodal();
    } catch (e) {
      showCustomToast("Error adding document", "error");

      console.error("Error adding document: ", e);
    }
  };

  return (
    <>
      <div style={{ width: "auto", height: "100%" }}>
        <div className="line1-modal-percent">
          <TopTitleFormComponent
            title={translate("EmergencyDischarge")}
            closemodal={props.closemodal}
          />
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "3px",
            height: "80%",
            overflowY: "scroll",
            paddingLeft: "25px",
            paddingRight: "25px",
            // overflowX: "hidden",
          }}
        >
          <div className="tab-form-container"></div>

          <div className="tab-form-container">
            <div className="checkbox-container-margin">
              <Checkbox
                checked={releaseAgreement}
                onChange={handleReleaseAgreementChange}
              />{" "}
              {text1}
            </div>
          </div>

          <div className="tab-form-container">
            <div className="checkbox-container-margin">
              <Checkbox
                checked={treatmentAcknowledge}
                onChange={handleTreatmentAcknowledgeChange}
              />{" "}
              {text2}
            </div>
          </div>

          <div className="tab-form-container">
            <div style={{ marginLeft: 20 }}>
              <DatePickerComponent
                title="Date of voluntary discharge/leaving the treatment facility"
                width="400"
                isRequired={true}
                value={voluntaryDischarge}
                onChange={handleVolontaryDischargeDateChange}
              />
            </div>
          </div>

          <div className="tab-form-container">
            <div className="checkbox-container-margin">
              <Checkbox
                checked={voluntaryDischargeAcknoledgment}
                onChange={handleVoluntaryDischargeAcknoledgementChange}
              />{" "}
              {text3}
            </div>
          </div>

          <div className="tab-form-container" style={{ paddingBottom: "10px" }}>
            <div className="checkbox-container-margin">
              <Checkbox
                checked={finalUnderstanding}
                onChange={handleFinalUnderstandingChange}
              />{" "}
              I, <span style={{ fontWeight: "bold" }}>{props.firstName}</span>
              &ensp;
              <span style={{ fontWeight: "bold" }}>{props.lastName}</span>,
              fully understand and agree to the above terms.
            </div>
          </div>
          <div style={{ width: "100%", marginBottom: "25px" }}>
            <SignaturePadComponentV2
              onSignatureSave={handleClientSignatureChange}
              signatureDataURL={signatureDataURL}
              onSignatureStart={() => setIsSignatureStarted(true)}
              onSignatureValidate={setIsSignatureValidated}
              toWho="Client signature"
            />
          </div>
        </div>
        <div className="line4-modal-percent-bis">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
              onClick={props.closemodal}
            >
              {translate("Cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={handleFormSubmit}
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : !voluntaryDischarge ||
                    !voluntaryDischargeAcknoledgment ||
                    !releaseAgreement ||
                    !treatmentAcknowledge ||
                    !finalUnderstanding ||
                    !signatureDataURL ||
                    !isSignatureValidated
                  ? {
                      backgroundColor: "grey",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                    }
              }
              disabled={
                isLoading ||
                !voluntaryDischargeAcknoledgment ||
                !releaseAgreement ||
                !treatmentAcknowledge ||
                !finalUnderstanding ||
                !signatureDataURL ||
                !isSignatureValidated
              }
            >
              {isLoading ? translate("Submitting") : translate("Submit")}
            </Button>
          </>
        </div>
      </div>
    </>
  );
}

export default AddEmergencyDischargeForm;
