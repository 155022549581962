import React, { useEffect, useState } from "react";
import Clientstab from "../component/ClientsTab";
import "../css/dashboard.css";
import "../css/client.css";
import { Box, Modal } from "@mui/material";
import AddClient from "../component/forms/AddClient";
import { documentId, onSnapshot, query, where } from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import { roles } from "../firebase.collection";
import Joyride, { ACTIONS, STATUS } from "react-joyride";
import { recordMetrics } from "../component/logsComponents/recordMetrics";
import TopTitleBannerV2 from "../component/TopTitleBannerV2";
import AllClient from "../component/clientsList/AllClient";

export default function ClientsContent({ selectedTab }) {
  const { currentUser } = useAuth();
  const userIdState = currentUser ? currentUser.uid : "";
  const [userRole, setUserRole] = useState("");
  const [viewMode, setViewMode] = useState(false); // true pour les widgets, false pour la vue en ligne
  const [dark, setDark] = React.useState(false);
  const [run, setRun] = useState(false);
  const [showHelpButton, setShowHelpButton] = useState(true);

  const handleHelpClick = () => {
    setRun(true);
    setShowHelpButton(false); // Cache le bouton "HELP" après le clic
  };

  const steps = [
    {
      target: "#search-client",
      content:
        "Use this search bar to quickly find a client by their name or details. Start typing the first few letters, and it will suggest matching clients.",
    },
    {
      target: "#add-client",
      content:
        "Click this button to add a new client. Fill in the necessary information in the form that appears.",
    },
    {
      target: "#display-client",
      content:
        "Use this switch to toggle between grid view and list view for the clients.",
    },
    {
      target: "#access-client",
      content:
        "This section allows you to list clients by their status (inhouse, aftercare, incoming, etc.). The tabs display the corresponding clients. For inhouse clients, you can list them by therapists. You can click on the card representing the client to view more details.",
    },
  ];

  const customStyles = {
    options: {
      zIndex: 10000,
    },
    tooltip: {
      backgroundColor: "#fff",
      color: "#000",
      animation: "fadeIn 0.3s ease-in-out",
    },
    tooltipContent: {
      textAlign: "justify",
      marginTop: "10px",
    },
    beacon: {
      offsetY: -50,
    },
    buttonNext: {
      backgroundColor: "#1565c0",
      color: "#fff",
    },
    buttonBack: {
      color: "blue",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 1000,
    },
    spotlight: {
      position: "absolute",
      borderRadius: 0,
    },
    beaconInner: {
      backgroundColor: "#1565c0", // Couleur du cercle intérieur
      borderColor: "#1565c0",
    },
    beaconOuter: {
      backgroundColor: "rgba(21, 101, 192, 0.5)", // Couleur de l'animation concentrique
      borderColor: "rgba(21, 101, 192, 0.5)",
    },
  };

  const handleJoyrideCallback = (data) => {
    const { status, action } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      setShowHelpButton(true);
    } else if ([ACTIONS.CLOSE].includes(action)) {
      setRun(false);
      setShowHelpButton(true);
    }
  };

  const styleModalGeneral = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 600,
    backgroundColor: "white",
    borderRadius: 5,
    boxShadow: 24,
  };

  const [openAddClient, setOpenAddClient] = useState(false);

  // const handleOpenAddClient = () => setOpenAddClient(true);
  const handleOpenAddClient = () => {
    setOpenAddClient(true);
    recordMetrics(
      "Clients",
      "addClient",
      currentUser.email,
      "write",
      "rehab-app-demo"
    );
  };

  const handleCloseAddClient = () => setOpenAddClient(false);

  function queryRole() {
    onSnapshot(
      query(roles, where(documentId(), "==", userIdState)),
      (querySnapshot) => {
        const doc = querySnapshot.docs[0];
        if (doc && doc.exists) {
          const role = doc.data().role;
          setUserRole(role);
        } else {
          console.log("No role for this user");
        }
      }
    );
  }

  useEffect(() => {
    if (currentUser) {
      queryRole();
      recordMetrics(
        "Clients",
        "pageLoad",
        currentUser.email,
        "read",
        "rehab-app-demo"
      );
    } else {
      console.log("No user id");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <div className="right-container">
      <Joyride
        steps={steps}
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
        run={run}
        callback={handleJoyrideCallback}
        disableScrolling={true}
        disableBeacon={true}
        styles={customStyles}
      />
      <Modal
        open={openAddClient}
        onClose={handleCloseAddClient}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalGeneral}>
          <AddClient closemodal={handleCloseAddClient} />
        </Box>
      </Modal>
      <TopTitleBannerV2 title={"Clients"} />

      <div
        className="top-component-list-clients"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "50px",
        }}
      >
        {" "}
      </div>
      <div id="access-client">
        <AllClient />
        {/* <Clientstab viewMode={viewMode} selectedTab={selectedTab} /> */}
      </div>
    </div>
  );
}
