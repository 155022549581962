export default [
  {
    id: 1,
    textDropDownEn: "--Choose--",
  },

  {
    id: 2,
    textDropDownEn: "Aftercare staff 1",
  },

  {
    id: 3,
    textDropDownEn: "Aftercare staff 2",
  },

  {
    id: 4,
    textDropDownEn: "Aftercare staff 3",
  },
  {
    id: 5,
    textDropDownEn: "Aftercare staff 3",
  },

  {
    id: 6,
    textDropDownEn: "Aftercare staff 3",
  },
];
