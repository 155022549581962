import { Button } from "@mui/material";
import React from "react";

function AddNoteButton(props) {
  return (
    <Button
      style={{
        width: "auto",
        height: "45px",
        marginRight: props.leftMargin === "none" ? 0 : "40px",
        marginLeft: props.leftMargin === "none" ? 0 : "40px",
      }}
      variant="contained"
      onClick={props.handleOpen}
    >
      {props.title}
    </Button>
  );
}

export default AddNoteButton;
