import React from "react";

function AddButtonRoundSmall(props) {
  return (
    <div
      className="add-round-button-small"
      onClick={props.handleOpen}
      title={props.title}
    >
      +
    </div>
  );
}

export default AddButtonRoundSmall;
