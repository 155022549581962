import React from "react";

export default function ChipStatus(props) {
  return (
    <>
      {props.status === "In House" ? (
        <div className="chip-status chip-green">Inhouse</div>
      ) : props.status === "Aftercare" ? (
        <div className="chip-status chip-aftercare">Aftercare</div>
      ) : props.status === "Incoming" ? (
        <div className="chip-status chip-incoming">Incoming</div>
      ) : props.status === "Miracles@home" ? (
        <div className="chip-status chip-mathome">M@home</div>
      ) : props.status === "Hot Lead" ? (
        <div className="chip-status chip-lead">Hot Lead</div>
      ) : props.status === "Clinical Assessment" ? (
        <div className="chip-status chip-clinical-assessment">
          Clin. Assess.
        </div>
      ) : props.status === "Continued Therapy" ? (
        <div className="chip-status chip-continued-therapy">Cont. Ther.</div>
      ) : props.status === "Denied" ? (
        <div className="chip-status chip-denied-admission">Den. Adm.</div>
      ) : props.status === "Potential" ? (
        <div className="chip-status chip-potential">Potential</div>
      ) : (
        <div className="chip-status chip-inactive">Inactive</div>
      )}
    </>
  );
}
