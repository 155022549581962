import React, { useRef, useState } from "react";
import { Button, FormControlLabel, Switch } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import TopTitleFormComponent from "./TopTitleFormComponent";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { showCustomToast } from "../Misc/CustomToast";

function ViewRevoke(props) {
  const [isLoading, setIsLoading] = useState(false);
  const printRef = useRef();
  const { currentUser } = useAuth();

  const [revoked, setRevoked] = useState(props.revoked);
  const documentId = props.clientId;
  const subcollectionDocumentId = props.subcollectionDocumentId;

  const handleSave = async () => {
    await updateRevokedField(documentId, subcollectionDocumentId, revoked);
  };

  const handleChangeRevoked = async (event) => {
    const newRevoked = event.target.checked;
    setRevoked(newRevoked);
  };

  const updateRevokedField = async (
    documentId,
    subcollectionDocumentId,
    newRevoked
  ) => {
    setIsLoading(true);

    try {
      const roiDoc = doc(
        db,
        "roi",
        documentId,
        "subcollection",
        subcollectionDocumentId
      );
      await updateDoc(roiDoc, {
        revoked: newRevoked,
        dateRevoked: serverTimestamp(),
        revokedBy: currentUser?.displayName,
      });

      showCustomToast("Release of information document revoked!", "success");
    } catch (error) {
      showCustomToast(`Error revoking the document: ${error.message}`, "error");
    } finally {
      setIsLoading(false);
      props.closemodal();
    }
  };

  return (
    <div>
      <div
        className="inputAndLabelModalContainerTitle2"
        style={{ position: "fixed", top: 0, left: 0, width: "100%", zIndex: 1 }}
      >
        <TopTitleFormComponent
          title="REVOKE R.O.I."
          closemodal={props.closemodal}
        />
      </div>

      <br />
      <div
        id="printableContent"
        style={{
          marginTop: "60px",
          height: "150px",
          // overflowY: "scroll",
          pageBreakAfter: "always",
        }}
        ref={printRef}
      >
        <div className="client-name-container-centered">Revoke this ROI ?</div>
        <br />

        <div className="tab-form-container-centered">
          <FormControlLabel
            control={
              <Switch checked={revoked} onChange={handleChangeRevoked} />
            }
            label={revoked ? "Revoked" : "Not Revoked"}
          />
        </div>
      </div>
      <div className="text-block-2"></div>
      <div className="line4-modal">
        <>
          <Button
            variant="contained"
            style={{ backgroundColor: "white", color: "black" }}
            onClick={props.closemodal}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            onClick={handleSave}
            style={
              isLoading
                ? {
                    backgroundColor: "red",
                    color: "white",
                    marginLeft: "15px",
                  }
                : {
                    backgroundColor: "#1565C0",
                    color: "white",
                    marginLeft: "15px",
                  }
            }
            disabled={isLoading}
          >
            {isLoading ? "SAVING..." : "SAVE"}
          </Button>
        </>
      </div>
    </div>
  );
}

export default ViewRevoke;
