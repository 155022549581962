import React, { useState } from "react";
import { Button, Input, TextField } from "@mui/material";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import DatePickerWithTimeComponent from "../DatePickerWithTimeComponent";
import InputComponent from "../InputComponent";
import { useAuth } from "../../contexts/AuthContext";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { showCustomToast } from "../Misc/CustomToast";
import DropDownComponentSorted from "../DropDownComponentSorted";

function AddGroupNote(props) {
  const location = useLocation();
  const { currentUser } = useAuth();

  //Tous les states des données récupérées par client

  const [isLoading, setIsLoading] = useState(false);
  const [typeGroupNote, setTypeGroupNote] = useState("");
  const [groupTopic, setGroupTopic] = useState("");
  const [noteContent, setNoteContent] = useState("");
  const [notInAttendance, setNotInAttendance] = useState("");
  const [otherTypeGroupNote, setOtherTypeGroupNote] = useState("");
  const [groupNoteDateTime, setGroupNoteDateTime] = useState(new Date());
  const [groupNoteStartDateTime, setGroupNoteStartDateTime] = useState(
    new Date()
  );

  const handleClose2 = () => setOpen2(false);
  const [open2, setOpen2] = React.useState(false);

  const handleTypeGroupNote = (event) => {
    setTypeGroupNote(event);
  };

  const handleGroupTopic = (event) => {
    setGroupTopic(event);
  };
  const handleNoteContent = (event) => {
    setNoteContent(event.target.value);
  };

  const handleOtherTypeGroupNote = (event) => {
    setOtherTypeGroupNote(event);
  };

  const handleNotInAttendance = (event) => {
    setNotInAttendance(event.target.value);
  };

  const handleDateTimeChange = (event) => {
    setGroupNoteDateTime(event);
  };

  const handleGroupStartDateTimeChange = (event) => {
    setGroupNoteStartDateTime(event);
  };

  const handleAddGroupNote = async (note, effectiveDateTime) => {
    const noteData = {
      noteType: "groupNote",
      noteContent: note,
      createdAt: serverTimestamp(),
      effectiveDateTime: effectiveDateTime,
      doneBy: currentUser.displayName,
      notInAttendance: notInAttendance,
      groupTopic: groupTopic,
      typeGroupNote: typeGroupNote,
      otherTypeGroupNote: otherTypeGroupNote,
      groupStartDateTime: groupNoteStartDateTime,
    };

    if (
      typeGroupNote &&
      groupTopic &&
      noteContent &&
      notInAttendance &&
      groupNoteStartDateTime
    ) {
      setIsLoading(true);
      try {
        const docRef = await addDoc(collection(db, "notes"), noteData);
        showCustomToast("Group note successfully added!", "success");

        setIsLoading(false);
        props.closemodal();
      } catch (error) {
        console.log("error adding note :", error);
        showCustomToast("Problem when adding note!", "error");
      }
    } else {
      showCustomToast(
        "Some mandatory fields are empty. Please check.!",
        "error"
      );
    }
  };

  const handleSubmit = () => {
    handleAddGroupNote(noteContent, groupNoteDateTime);
  };

  return (
    <>
      <div
        style={{ width: "auto", height: "100%" }}
        // className="inputAndLabelModalContainerTitle2"
      >
        <div className="line1-modal-percent">
          <div className="modal-title">Add group note</div>
          <CloseIcon
            style={{
              marginRight: "16px",
              color: "rgb(214, 213, 213)",
              cursor: "pointer",
            }}
            onClick={props.closemodal}
          />
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "10px",
            height: "75%",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <div
            className="line3-modal-nopad"
            style={{
              paddingLeft: "10px",
              // backgroundColor: "pink",
            }}
          >
            <div style={{ width: "100%", maxWidth: "300px" }}>
              <InputComponent
                isRequired={true}
                value={groupTopic}
                title="Group topic"
                width="auto"
                onChange={handleGroupTopic}
              />
            </div>
          </div>

          <div
            className="line3-modal-nopad"
            style={{
              paddingLeft: "10px",
              // backgroundColor: "pink",
            }}
          >
            <DatePickerWithTimeComponent
              isRequired={true}
              value={groupNoteStartDateTime}
              title="Group Date and start time"
              width="300px"
              onChange={handleGroupStartDateTimeChange}
            />
          </div>

          <div
            className="line3-modal-nopad"
            style={{
              paddingLeft: "10px",
              // backgroundColor: "pink",
            }}
          >
            <DropDownComponentSorted
              isRequired={true}
              value={typeGroupNote}
              api="groupType"
              title="Type of group"
              onChange={handleTypeGroupNote}
            />

            {typeGroupNote === "Other" ? (
              <InputComponent
                value={otherTypeGroupNote}
                title="if other, describe"
                width="300px"
                onChange={handleOtherTypeGroupNote}
              />
            ) : (
              ""
            )}
          </div>

          <div
            className="line2-column-modal"
            // style={{ backgroundColor: "pink" }}
          >
            <div className="label-textfield">
              Group note
              <span style={{ color: "red", marginLeft: "5px" }}>*</span>
            </div>

            <TextField
              style={{
                width: "95%",
                borderRadius: "5px",
                backgroundColor: "#f2eeef",
              }}
              placeholder="Type group note here"
              multiline
              rows={10}
              onChange={handleNoteContent}
            />
          </div>
          <div
            className="line2-column-modal"
            // style={{ backgroundColor: "pink" }}
          >
            <div className="label-textfield">
              Clients NOT in attendance
              <span style={{ color: "red", marginLeft: "5px" }}>*</span>
            </div>
            <TextField
              style={{
                width: "95%",
                borderRadius: "5px",
                backgroundColor: "#f2eeef",
              }}
              placeholder="Type names here (first name / or preferred name and first initial of last name) and reason why client is not in group, if known..."
              // placeholder="Type therapy session note here."
              multiline
              rows={4}
              onChange={handleNotInAttendance}
            />
          </div>
          <div
            className="line3-modal-nopad"
            style={{
              // marginLeft: "20px",
              paddingLeft: "10px",
              marginBottom: "20px",
            }}
          >
            <DatePickerWithTimeComponent
              isRequired={true}
              // therapyNoteDateTime={nonTherapyNoteDateTime}
              value={groupNoteDateTime}
              // setTherapyNoteDateTime={setNonTherapyNoteDateTime}
              onChange={handleDateTimeChange}
              title="Effective date and time"
            />
          </div>
        </div>

        <div className="line4-modal-percent">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
              onClick={props.closemodal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                    }
              }
              disabled={isLoading}
              onClick={handleSubmit}
            >
              {isLoading ? "ADDING NOTE..." : "ADD NOTE"}
            </Button>
          </>
        </div>
      </div>
    </>
  );
}

export default AddGroupNote;
