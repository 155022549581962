import React, { useContext, useState } from "react";
import Select from "react-select";
import Joyride, { ACTIONS, STATUS } from "react-joyride";
import { Button } from "@mui/material";
import { LanguageContext } from "../../context/LanguageContext";

const MonthPicker = ({
  selectedYear,
  selectedMonth,
  onYearChange,
  onMonthChange,
}) => {
  const years = Array.from({ length: 10 }, (_, i) => ({
    value: new Date().getFullYear() - i,
    label: new Date().getFullYear() - i,
  })); // Dernières 10 années

  const { translate } = useContext(LanguageContext);

  const [run, setRun] = useState(false);
  const [showHelpButton, setShowHelpButton] = useState(true);

  const handleHelpClick = () => {
    setRun(true);
    setShowHelpButton(false); // Cache le bouton "HELP" après le clic
  };

  const customStyles2 = {
    options: {
      zIndex: 10000,
    },
    tooltip: {
      backgroundColor: "#fff",
      color: "#000",
      animation: "fadeIn 0.3s ease-in-out",
    },
    tooltipContent: {
      textAlign: "justify",
      marginTop: "10px",
    },
    beacon: {
      offsetY: -50, // Ajustez cette valeur pour positionner le beacon au-dessus
    },
    buttonNext: {
      backgroundColor: "#1565c0",
      color: "#fff",
    },
    buttonBack: {
      color: "blue",
    },
    overlay: {
      position: "fixed", // Utilisez 'fixed' pour que l'overlay couvre toute la page
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Ajustez l'opacité de l'overlay
      zIndex: 1000, // Assurez-vous que l'overlay est au-dessus de tout le reste
    },
    spotlight: {
      // backgroundColor: "rgba(0, 0, 0, 0.7)",
      // position: "absolute",
      borderRadius: 0,
    },
    // beacon: {
    //   placement: "top",
    // },
  };

  const handleJoyrideCallback = (data) => {
    const { status, action } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      setShowHelpButton(true);
    } else if ([ACTIONS.CLOSE].includes(action)) {
      setRun(false);
      setShowHelpButton(true);
    }
  };

  const steps = [
    {
      target: "#year-selector",
      content:
        "Use this dropdown to select the desired year for filtering data.",
    },
    {
      target: "#month-selector",
      content:
        "Use this dropdown to select the desired month for filtering data.",
    },
    {
      target: "#today-date",
      content: "This color indicates today's date on the chart.",
    },
    {
      target: "#incoming-discharge",
      content:
        "This color indicates the discharge dates of clients for the selected month on the chart.",
    },
  ];

  const months = [
    { value: 0, label: translate("January") },
    { value: 1, label: translate("February") },
    { value: 2, label: translate("March") },
    { value: 3, label: translate("April") },
    { value: 4, label: translate("May") },
    { value: 5, label: translate("June") },
    { value: 6, label: translate("July") },
    { value: 7, label: translate("August") },
    { value: 8, label: translate("September") },
    { value: 9, label: translate("October") },
    { value: 10, label: translate("November") },
    { value: 11, label: translate("December") },
  ];

  // const customStyles = {
  //   menu: (provided) => ({
  //     ...provided,
  //     maxHeight: 50, // Hauteur maximale du dropdown
  //     backgroundColor: "#f1f1f1",
  //   }),
  //   control: (provided) => ({
  //     ...provided,
  //     minHeight: "40px",
  //   }),
  //   option: (provided, state) => ({
  //     ...provided,
  //     backgroundColor: state.isFocused ? "lightgray" : "#f1f1f1",
  //     color: "black",
  //   }),
  // };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      maxHeight: 50, // Hauteur maximale du dropdown
      backgroundColor: "#f1f1f1",
    }),
    control: (provided) => ({
      ...provided,
      minHeight: "40px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "lightgray" : "#f1f1f1",
      color: "black",
    }),
    buttonNext: {
      backgroundColor: "#1565c0",
      color: "#fff",
    },
    buttonBack: {
      color: "#1565c0",
    },
    beacon: {},
    beaconInner: {
      backgroundColor: "#1565c0", // Couleur du cercle intérieur
      borderColor: "#1565c0",
    },
    beaconOuter: {
      backgroundColor: "rgba(21, 101, 192, 0.5)", // Couleur de l'animation concentrique
      borderColor: "rgba(21, 101, 192, 0.5)",
    },
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "1200px",
        paddingLeft: "40px",
        marginTop: "20px",
      }}
    >
      <Joyride
        steps={steps}
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
        run={run}
        callback={handleJoyrideCallback}
        disableScrolling={true}
        disableBeacon={true}
        styles={customStyles}
      />
      <div id="year-selector" style={{ width: "150px", marginRight: "20px" }}>
        <Select
          value={years.find((year) => year.value === selectedYear)}
          onChange={(option) => onYearChange(option.value)}
          options={years}
          styles={customStyles}
        />
      </div>
      <div id="month-selector" style={{ width: "150px", marginRight: "20px" }}>
        <Select
          value={months.find((month) => month.value === selectedMonth)}
          onChange={(option) => onMonthChange(option.value)}
          options={months}
          styles={customStyles}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "500px",
        }}
      >
        <div id="today-date" style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              width: "20px",
              height: "20px",
              backgroundColor: "green",
              // marginRight: "5px",
            }}
          />
          <div>
            <span style={{ marginRight: "20px", paddingLeft: "5px" }}>
              {translate("TodaysDate")}
            </span>
          </div>
        </div>

        <div
          id="incoming-discharge"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <div
            style={{
              width: "20px",
              height: "20px",
              backgroundColor: "red",
              marginRight: "5px",
            }}
          />
          <div>
            <span>{translate("IncomingDischarges")} </span>
          </div>
        </div>

        {/* {showHelpButton && (
          <div
            style={{ height: "30px", paddingTop: "0px", paddingLeft: "40px" }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={handleHelpClick}
              sx={{ fontSize: "10px" }}
            >
              HELP
            </Button>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default MonthPicker;
