import React, { useContext } from "react";
import "../css/dashboard.css";
import TopTitleBannerMobile from "../component/TopTitleBannerMobile";
import CollapseComponentMobile from "../component/Misc/CollapseComponentMobile";
import TopTitleBannerV2 from "../component/TopTitleBannerV2";
import { LanguageContext } from "../context/LanguageContext";
import UnderConstruction from "../component/Misc/UnderConstruction";

export default function MedicationContent() {
  const { translate } = useContext(LanguageContext);
  return (
    <div className="right-container">
      <TopTitleBannerV2 title={translate("Medication")} />
      <UnderConstruction />
    </div>
  );
}
