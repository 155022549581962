import React, { useContext, useEffect, useState } from "react";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { format } from "date-fns";
import { Button } from "@mui/material";
import Papa from "papaparse";
import DatePickerV2ComponentNoTitle from "../DatePickerV2ComponentNoTitle";
import dayjs from "dayjs";
import { showCustomToast } from "../Misc/CustomToast";
import ButtonDeleteRed from "../Misc/ButtonDeleteRed";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Joyride, { ACTIONS, STATUS } from "react-joyride";
import LoadingComponent from "../loaders/LoadingComponent";
import { LanguageContext } from "../../context/LanguageContext";

function AvailabilityChart(props) {
  const { translate } = useContext(LanguageContext);
  const [run, setRun] = useState(false);
  const [showHelpButton, setShowHelpButton] = useState(true);

  const handleHelpClick = () => {
    setRun(true);
    setShowHelpButton(false); // Cache le bouton "HELP" après le clic
  };

  const customStyles = {
    options: {
      zIndex: 10000,
    },
    tooltip: {
      backgroundColor: "#fff",
      color: "#000",
      animation: "fadeIn 0.3s ease-in-out",
    },
    tooltipContent: {
      textAlign: "justify",
      marginTop: "10px",
    },
    beacon: {
      offsetY: -50, // Ajustez cette valeur pour positionner le beacon au-dessus
    },
    buttonNext: {
      backgroundColor: "#1565c0",
      color: "#fff",
    },
    buttonBack: {
      color: "blue",
    },
    overlay: {
      position: "fixed", // Utilisez 'fixed' pour que l'overlay couvre toute la page
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Ajustez l'opacité de l'overlay
      zIndex: 1000, // Assurez-vous que l'overlay est au-dessus de tout le reste
    },
    spotlight: {
      // backgroundColor: "rgba(0, 0, 0, 0.7)",
      position: "absolute",
      borderRadius: 0,
    },
    // beacon: {
    //   placement: "top",
    // },
    beaconInner: {
      backgroundColor: "#1565c0", // Couleur du cercle intérieur
      borderColor: "#1565c0",
    },
    beaconOuter: {
      backgroundColor: "rgba(21, 101, 192, 0.5)", // Couleur de l'animation concentrique
      borderColor: "rgba(21, 101, 192, 0.5)",
    },

    beaconInner: {
      backgroundColor: "#1565c0", // Couleur du cercle intérieur
      borderColor: "#1565c0",
    },
    beaconOuter: {
      backgroundColor: "rgba(21, 101, 192, 0.5)", // Couleur de l'animation concentrique
      borderColor: "rgba(21, 101, 192, 0.5)",
    },
  };

  const steps = [
    {
      target: "#date-selector",
      content:
        "Select the date range here to display the room availability charts for this period.",
    },
  ];

  const handleJoyrideCallback = (data) => {
    const { status, action } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      setShowHelpButton(true);
    } else if ([ACTIONS.CLOSE].includes(action)) {
      setRun(false);
      setShowHelpButton(true);
    }
  };

  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [clientDisplayLimit, setClientDisplayLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filteredClients, setFilteredClients] = useState([]);
  // const [dateStart, setDateStart] = useState("");
  // const [dateEnd, setDateEnd] = useState("");
  const [dateStart, setDateStart] = useState(Timestamp.fromDate(new Date())); // Aujourd'hui
  const [dateEnd, setDateEnd] = useState(
    Timestamp.fromDate(new Date(Date.now() + 21 * 24 * 60 * 60 * 1000))
  );
  const [presenceCounts, setPresenceCounts] = useState({});
  const [showAvailableRooms, setShowAvailableRooms] = useState(true);
  const [clientDetails, setClientDetails] = useState([]);
  const [showStatusSplit, setShowStatusSplit] = useState(false);

  const handleDateStartChange = (value) => {
    setDateStart(value);
  };
  const handleDateEndChange = (value) => {
    setDateEnd(value);
  };

  const resetDates = () => {
    setDateStart(""); // Réinitialiser à la valeur initiale appropriée
    setDateEnd(""); // Réinitialiser à la valeur initiale appropriée
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  ChartJS.register({
    id: "warningIndicator",
    afterDraw: (chart) => {
      const ctx = chart.ctx;
      chart.data.datasets.forEach((dataset, i) => {
        chart.getDatasetMeta(i).data.forEach((bar, index) => {
          const total = dataset.data[index]; // Obtenez la valeur totale pour cette barre
          if (total > 25) {
            // Dessinez un indicateur de warning, par exemple, une bordure rouge en haut de la barre
            ctx.save();
            ctx.fillStyle = "red";
            ctx.fillRect(bar.x - bar.width / 2, bar.y, bar.width, 5); // Ajustez selon le besoin
            ctx.restore();
          }
        });
      });
    },
  });
  ChartJS.register(ChartDataLabels);

  const Histogram = ({ data }) => {
    const options = {
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
          max: 25, // Valeur fixe pour l'axe des ordonnées
        },
      },
      plugins: {
        datalabels: {
          display: (context) => {
            // Afficher uniquement le label pour le dernier dataset pour éviter les doublons
            return (
              context.datasetIndex === context.chart.data.datasets.length - 1
            );
          },
          align: "end",
          anchor: "end",
          // color: "#444",
          color: (context) => {
            // Appliquer la couleur rouge uniquement en mode "Available Rooms" et si la valeur est 0
            if (
              showAvailableRooms &&
              context.dataset.data[context.dataIndex] === 0
            ) {
              return "red";
            } else {
              return "#444"; // Couleur par défaut
            }
          },
          font: {
            weight: (context) => {
              // Appliquer le texte en gras uniquement en mode "Available Rooms" et si la valeur est 0
              if (
                showAvailableRooms &&
                context.dataset.data[context.dataIndex] === 0
              ) {
                return "bold";
              } else {
                return "normal"; // Style de police par défaut
              }
            },
            size: 16,
          },
          formatter: (value, context) => {
            // Calculer le total pour chaque barre ici
            const total = context.chart.data.datasets.reduce((acc, dataset) => {
              return acc + (dataset.data[context.dataIndex] || 0);
            }, 0);
            return total; // Afficher le total
          },
        },
        legend: {
          position: "top",
          labels: {
            paddingBottom: 20, // Augmentez l'espacement sous la légende
          },
        },
        title: {
          display: true,
          text: showAvailableRooms
            ? translate("RoomsAvailablePerDay")
            : translate("DistributionPerDay"),
          font: {
            size: 18, // Augmenter la taille du titre
          },
        },
      },
    };

    return <Bar options={options} data={data} />;
  };

  const [filters, setFilters] = useState({
    fullName: "",
    primaryAddiction: "",
    estimatedArrivalDate: "",
    admissionDate: "",
    durationOfStay: "",
    flights: "",
    payment: "",
    passport: "",
    vipTransfer: "",
    // ... (autres filtres existants)
  });

  function convertToCSV(data) {
    const csv = Papa.unparse(data, {
      header: true,
    });
    return csv;
  }

  const [clientsPotentialIncoming, setClientsPotentialIncoming] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    const fetchSpecificStatusClients = async () => {
      // Définir les statuts à requêter
      const statuses = [
        "In House",
        "Incoming",
        "Aftercare",
        "Potential",
        "Miracles@home",
      ];

      // Utiliser query et where avec `in` pour filtrer par plusieurs statuts
      const queryRef = query(
        collection(db, "clients"),
        where("clientStatus", "in", statuses)
      );
      const snapshot = await getDocs(queryRef);
      let specificStatusClients = [];

      for (let docList of snapshot.docs) {
        let clientData = docList.data();
        clientData.id = docList.id;

        // Récupérer les données de preAdmissionAssessments
        const assessmentDocRef = doc(
          db,
          "preAdmissionAssessments",
          clientData.id
        );
        const assessmentSnapshot = await getDoc(assessmentDocRef);
        if (assessmentSnapshot.exists()) {
          const assessmentData = assessmentSnapshot.data();
          clientData = { ...clientData, ...assessmentData }; // Fusionner les données
        }

        specificStatusClients.push(clientData);
      }

      // Puisque la requête initiale filtre déjà par statuts, tous les clients récupérés correspondent aux statuts désirés
      setClients(specificStatusClients); // Mettre à jour avec les clients filtrés
      setTotalRecords(specificStatusClients.length);
      setIsLoading(false);
      // handleGeneratePrevision();
    };

    fetchSpecificStatusClients().catch((error) => {
      console.error("Failed to fetch clients with specific statuses:", error);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (clients.length > 0 && dateStart && dateEnd) {
      handleGeneratePrevision();
    }
  }, [clients, dateStart, dateEnd]);

  const handleGeneratePrevision = () => {
    if (dateStart && dateEnd) {
      const newPresenceCounts = countPresencePerDay(
        clients,
        dateStart,
        dateEnd
      );
      console.log("PresenceCounts:", newPresenceCounts);
      setPresenceCounts(newPresenceCounts); // Mise à jour de l'état avec les nouveaux counts
    } else {
      showCustomToast(translate("PleaseSelectBothDates"), "error");
    }
  };

  useEffect(() => {
    // Trier les clients par clientStatus en ordre alphabétique avant de les stocker
    const sortedClients = clients.sort((a, b) =>
      a.clientStatus.localeCompare(b.clientStatus)
    );

    const updatedClientDetails = sortedClients.map((client) => {
      const eta = client.dateOfAdmission
        ? dayjs(client.dateOfAdmission.toDate())
        : client.eta
        ? dayjs(client.eta.toDate())
        : null;
      const dischargeDate = client.actualDischargeDate
        ? dayjs(client.actualDischargeDate.toDate())
        : client.projectedResidentialDischargeDate
        ? dayjs(client.projectedResidentialDischargeDate.toDate())
        : eta
        ? eta.add(client.durationOfStay, "days")
        : null;
      let durationDays;

      if (eta && dischargeDate) {
        durationDays = dischargeDate.diff(eta, "day");
      }

      return {
        ...client,
        eta: eta ? eta.format("YYYY-MM-DD") : "N/A",
        endDate: dischargeDate ? dischargeDate.format("YYYY-MM-DD") : "N/A",
        durationDays: durationDays || "N/A",
      };
    });

    setClientDetails(updatedClientDetails);
  }, [clients]);

  const renderClientDetailsTable = () => {
    return (
      <table
        style={{
          width: "60%",
          marginTop: "20px",
          borderCollapse: "collapse",
          marginBottom: "40px",
        }}
      >
        <thead>
          <tr>
            <th style={{ textAlign: "start", border: "1px solid black" }}>
              Status
            </th>
            <th style={{ textAlign: "start", border: "1px solid black" }}>
              Last Name
            </th>
            <th style={{ textAlign: "start", border: "1px solid black" }}>
              ETA
            </th>
            <th style={{ textAlign: "start", border: "1px solid black" }}>
              End Date
            </th>
            <th style={{ textAlign: "start", border: "1px solid black" }}>
              Duration (days)
            </th>
          </tr>
        </thead>
        <tbody>
          {clientDetails.map((client, index) => (
            <tr key={index}>
              <td style={{ border: "1px solid black" }}>
                {client.clientStatus}
              </td>
              <td style={{ border: "1px solid black" }}>{client.lastName}</td>
              <td style={{ border: "1px solid black" }}>{client.eta}</td>
              <td style={{ border: "1px solid black" }}>{client.endDate}</td>
              <td style={{ border: "1px solid black" }}>
                {client.durationDays}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const countPresencePerDay = (clients, dateStart, dateEnd) => {
    let presenceCounts = {};

    const startDateFilter = dayjs(dateStart.toDate());
    const endDateFilter = dayjs(dateEnd.toDate());

    let currentDate = startDateFilter;
    while (
      currentDate.isBefore(endDateFilter) ||
      currentDate.isSame(endDateFilter, "day")
    ) {
      const formattedDay = currentDate.format("YYYY-MM-DD");
      presenceCounts[formattedDay] = {
        Incoming: 0,
        Potential: 0,
        InHouse: 0,
        Aftercare: 0,
        "Miracles@home": 0,
      };
      currentDate = currentDate.add(1, "day");
    }

    clients.forEach((client) => {
      const eta = client.dateOfAdmission
        ? dayjs(client.dateOfAdmission.toDate())
        : client.eta
        ? dayjs(client.eta.toDate())
        : null;
      const dischargeDate = client.actualDischargeDate
        ? dayjs(client.actualDischargeDate.toDate())
        : client.projectedResidentialDischargeDate
        ? dayjs(client.projectedResidentialDischargeDate.toDate())
        : eta
        ? eta.add(client.durationOfStay, "days")
        : null;

      if (eta && dischargeDate) {
        for (
          let day = eta;
          day.isBefore(dischargeDate) || day.isSame(dischargeDate, "day");
          day = day.add(1, "day")
        ) {
          const formattedDay = day.format("YYYY-MM-DD");
          if (
            presenceCounts[formattedDay] &&
            (day.isAfter(startDateFilter) ||
              day.isSame(startDateFilter, "day")) &&
            (day.isBefore(endDateFilter) || day.isSame(endDateFilter, "day"))
          ) {
            const statusKey = client.clientStatus.replace(/\s/g, "");
            presenceCounts[formattedDay][statusKey] =
              (presenceCounts[formattedDay][statusKey] || 0) + 1;
          }
        }
      }
    });

    return presenceCounts;
  };

  const toggleViewMode = () => {
    setShowStatusSplit(!showStatusSplit); // Bascule entre true et false
    if (!showStatusSplit) {
      // Si on passe à true, on veut montrer le split de statut
      const statusCounts = countStatusPerDay(clients, dateStart, dateEnd);
      setPresenceCounts(statusCounts); // Utiliser setPresenceCounts pour rester générique
    } else {
      // Sinon, on revient à la vue par défaut
      handleGeneratePrevision(); // Cette fonction devrait recalculer la présence par jour comme avant
    }
  };

  function countStatusPerDay(clients, dateStart, dateEnd) {
    let statusCounts = {};
    const startDateFilter = dayjs(dateStart.toDate());
    const endDateFilter = dayjs(dateEnd.toDate());

    let currentDate = startDateFilter;
    while (
      currentDate.isBefore(endDateFilter) ||
      currentDate.isSame(endDateFilter, "day")
    ) {
      const formattedDay = currentDate.format("YYYY-MM-DD");
      statusCounts[formattedDay] = { InHouse: 0, Aftercare: 0 };
      currentDate = currentDate.add(1, "day");
    }

    clients.forEach((client) => {
      const eta = client.dateOfAdmission
        ? dayjs(client.dateOfAdmission.toDate())
        : client.eta
        ? dayjs(client.eta.toDate())
        : null;
      const dischargeDate = client.actualDischargeDate
        ? dayjs(client.actualDischargeDate.toDate())
        : client.projectedResidentialDischargeDate
        ? dayjs(client.projectedResidentialDischargeDate.toDate())
        : eta
        ? eta.add(client.durationOfStay, "days")
        : null;

      // InHouse calculation
      if (eta && dischargeDate) {
        for (
          let day = eta;
          day.isBefore(dischargeDate) || day.isSame(dischargeDate, "day");
          day = day.add(1, "day")
        ) {
          const formattedDay = day.format("YYYY-MM-DD");
          if (
            (day.isAfter(startDateFilter) ||
              day.isSame(startDateFilter, "day")) &&
            (day.isBefore(endDateFilter) || day.isSame(endDateFilter, "day"))
          ) {
            statusCounts[formattedDay].InHouse += 1;
          }
        }
      }

      // Aftercare calculation
      if (dischargeDate) {
        const aftercareEnd = dischargeDate.add(90, "day");
        for (
          let day = dischargeDate.add(1, "day");
          day.isBefore(aftercareEnd) || day.isSame(aftercareEnd, "day");
          day = day.add(1, "day")
        ) {
          const formattedDay = day.format("YYYY-MM-DD");
          if (
            (day.isAfter(startDateFilter) ||
              day.isSame(startDateFilter, "day")) &&
            (day.isBefore(endDateFilter) || day.isSame(endDateFilter, "day"))
          ) {
            statusCounts[formattedDay].Aftercare += 1;
          }
        }
      }
    });

    return statusCounts;
  }

  function formatDate(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    return format(date, "d MMMM yyyy");
  }

  const chartData = React.useMemo(() => {
    const labels = Object.keys(presenceCounts);

    if (showStatusSplit) {
      const inHouseData = labels.map((day) => presenceCounts[day].InHouse || 0);
      const aftercareData = labels.map(
        (day) => presenceCounts[day].Aftercare || 0
      );

      return {
        labels,
        datasets: [
          {
            label: "InHouse",
            data: inHouseData,
            backgroundColor: "rgba(76, 175, 80, 0.7)",
          },
          {
            label: "Aftercare",
            data: aftercareData,
            backgroundColor: "rgba(255, 206, 86, 0.5)",
          },
        ],
      };
    } else {
      const availableRoomsData = labels.map((day) => {
        const totalPresence = Object.values(presenceCounts[day])
          .filter((value) => typeof value === "number")
          .reduce((sum, num) => sum + num, 0);
        return 25 - totalPresence;
      });

      return {
        labels,
        datasets: [
          {
            label: "Available Rooms",
            data: availableRoomsData,
            backgroundColor: "rgba(76, 175, 80, 0.7)",
          },
        ],
      };
    }
  }, [presenceCounts, showStatusSplit]);

  return (
    <div>
      <Joyride
        steps={steps}
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
        run={run}
        callback={handleJoyrideCallback}
        disableScrolling={true}
        disableBeacon={true}
        styles={customStyles}
      />
      <div
        id="date-selector"
        style={{
          paddingLeft: "25px",
          width: "500px",
          display: "flex",
          flexDirection: "column",
          height: "20%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "430px",
          }}
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "40px",
            }}
          >
            {translate("SelectRangeDate")}{" "}
          </span>
          {/* {showHelpButton && (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleHelpClick}
              sx={{ fontSize: "10px" }}
            >
              HELP
            </Button>
          )} */}
        </div>
        <div
          style={{
            width: "960px",
            paddingBottom: "20px",
            display: "flex",
            flexDirection: "row",
            alignItem: "center",
            justifyContent: "flex-start",
            paddingLeft: "30px",
          }}
        >
          <div style={{ width: "200px" }}>
            <DatePickerV2ComponentNoTitle
              value={dateStart}
              onChange={handleDateStartChange}
            />
          </div>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "10px",
              fontWeight: "500",
            }}
          >
            {translate("TO")}{" "}
          </span>
          <div style={{ width: "200px" }}>
            <DatePickerV2ComponentNoTitle
              value={dateEnd}
              onChange={handleDateEndChange}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              marginLeft: "10px",
              paddingTop: "10px",
            }}
          >
            <ButtonDeleteRed variant="contained" onClick={resetDates}>
              {translate("SeeMore")}
            </ButtonDeleteRed>
          </div>
        </div>
      </div>

      <div
        style={{ height: "70vh", position: "relative", paddingLeft: "30px" }}
      >
        {isLoading ? (
          // <Box
          //   sx={{
          //     display: "flex",
          //     flexDirection: "column",
          //     alignItems: "center",
          //     justifyContent: "center",
          //     height: "80%",
          //   }}
          // >
          //   <CircularProgress />
          //   <p>Fetching data...</p>
          // </Box>
          <LoadingComponent />
        ) : (
          <div style={{ width: "80%" }}>
            {Object.keys(presenceCounts).length > 0 && (
              <div style={{ height: "80%", width: "100%" }}>
                <Histogram data={chartData} />
              </div>
            )}
          </div>
        )}
      </div>

      {!isLoading && filteredClients.length > clientDisplayLimit && (
        <div className="seemore-container">
          <Button
            onClick={() => setClientDisplayLimit(clientDisplayLimit + 10)}
          >
            {translate("SeeMore")}
          </Button>
        </div>
      )}
    </div>
  );
}

export default AvailabilityChart;
