import React from "react";
import PeopleIcon from "@mui/icons-material/People";
import FlightIcon from "@mui/icons-material/Flight";
import EventIcon from "@mui/icons-material/Event";
import { Skeleton } from "@mui/material";

export default function MetricsWidgetV2MobileV2(props) {
  const renderIcon = (iconName) => {
    switch (iconName) {
      case "People":
        return (
          <PeopleIcon
            style={{
              fontSize: "20px",
              marginRight: "10px",
              backgroundColor: "#f3f4f6",
              paddingLeft: "5px",
              paddingRight: "5px",
              borderRadius: "50px",
              height: "30px",
              width: "30px",
            }}
          />
        );
      case "Flight":
        return (
          <FlightIcon
            style={{
              fontSize: "30px",
              marginRight: "10px",
              backgroundColor: "#f3f4f6",
              paddingLeft: "10px",
              paddingRight: "10px",
              borderRadius: "50px",
              height: "40px",
              width: "40px",
            }}
          />
        );
      case "Event":
        return (
          <EventIcon
            style={{
              fontSize: "30px",
              marginRight: "10px",
              backgroundColor: "#f3f4f6",
              paddingLeft: "10px",
              paddingRight: "10px",
              borderRadius: "50px",
              height: "40px",
              width: "40px",
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      className="metrics-widget-container-v2-mobile"
      style={{
        backgroundColor: props.backgroundColor,
        color: props.textColor,
        display: "flex",
        flexDirection: "row",
        width: "auto",
        marginBottom: "10px",
      }}
    >
      <div
        style={{
          fontSize: "12px",
          height: "auto",
        }}
      >
        {renderIcon(props.iconName)}
      </div>

      <div style={{ height: "auto" }}>
        <div
          style={{
            width: "100%",
            height: "auto",
            textAlign: "start",
            fontSize: "12px",
            fontWeight: "600",
            color: props.textColor2,
          }}
        >
          {props.title}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingBottom: "5px",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            alignSelf: "flex-start",
          }}
        >
          <div
            style={{
              width: "auto",
              display: "flex",
              alignItems: "center",
            }}
          >
            {props.isLoading ? (
              <Skeleton width={150} height={35} />
            ) : (
              <>
                <div
                  style={{
                    width: "auto",
                    textAlign: "start",
                    fontSize: "14px",
                    fontWeight: "700",
                    marginRight: "0px",
                    lineHeight: "14px",
                  }}
                >
                  {props.value}
                </div>
                <div
                  style={{
                    width: "auto",
                    textAlign: "start",
                    fontSize: "14px",
                    lineHeight: "14px",
                    fontWeight: "700",
                    paddingLeft: "5px",
                  }}
                >
                  {props.title2}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
