import React, { useRef, useState } from "react";
import { Button, TextField } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import DatePickerWithTimeComponent from "../DatePickerWithTimeComponent";
import { useAuth } from "../../contexts/AuthContext";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { showCustomToast } from "../Misc/CustomToast";
import { format } from "date-fns";
import ReactToPrint from "react-to-print";
import ViewBlock from "../Misc/ViewBlock";

function ViewHandoverNotes(props) {
  const { currentUser } = useAuth();
  const printRef = useRef();

  function formatDate(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    return format(date, "d MMMM yyyy"); // "d" pour le jour, "MMMM" pour le mois en texte, "yyyy" pour l'année
  }

  // Initialize states with props

  const [immigrationAppointment, setImmigrationAppointment] = useState(
    props.immmigrationAppointment || ""
  );
  const [dischargeSwot, setDischargeSwot] = useState(
    props.dischargesSwot || ""
  );
  const [newClients, setNewClients] = useState(props.newClients || "");
  const [medicalInfos, setMedicalInfos] = useState(props.medicalInfos || "");
  const [hospitalAppointment, setHospitalAppointment] = useState(
    props.hospitalAppointment || ""
  );
  const [residentialClientMovements, setResidentialClientMovements] = useState(
    props.residentialClientMovements || ""
  );
  const [medicalNotes, setMedicalNotes] = useState(props.noteContent || "");
  const [handoverNoteDateTime, setHandoverNoteDateTime] = useState(
    props.effectiveDateTime || ""
  );

  const [zoomSessionAssistance, setZoomSessionAssistance] = useState(
    props.zoomSessionAssistance || ""
  );

  const [staffToday, setStaffToday] = useState(props.staffToday || "");
  const [generalAnnouncement, setGeneralAnnouncement] = useState(
    props.generalAnnouncement || ""
  );

  const [overnight, setOvernight] = useState(props.overnight || "");
  const [numberOfClient, setNumberOfClient] = useState(
    props.numberOfClient || ""
  );
  const [swot, setSwot] = useState(props.swot || "");

  const [hasChanged, setHasChanged] = useState(false);

  const handleHandoverNoteDateTimes = (event) => {
    setHandoverNoteDateTime(event);
    setHasChanged(true);
  };

  const handleStaffToday = (event) => {
    setStaffToday(event.target.value);
    setHasChanged(true);
  };

  const handleOvernight = (event) => {
    setOvernight(event.target.value);
    setHasChanged(true);
  };

  const handleNumberOfClient = (event) => {
    setNumberOfClient(event.target.value);
    setHasChanged(true);
  };

  const handleSwot = (event) => {
    setSwot(event.target.value);
    setHasChanged(true);
  };

  const handleGeneralAnnoucement = (event) => {
    setGeneralAnnouncement(event.target.value);
    setHasChanged(true);
  };

  const handleImmagrationAppointment = (event) => {
    setImmigrationAppointment(event.target.value);
    setHasChanged(true);
  };

  const handleZoomSessionAssistance = (event) => {
    setZoomSessionAssistance(event.target.value);
    setHasChanged(true);
  };

  const handleDischargeSwot = (event) => {
    setDischargeSwot(event.target.value);
    setHasChanged(true);
  };

  const handleNewClients = (event) => {
    setNewClients(event.target.value);
    setHasChanged(true);
  };

  const handleMedicalInfos = (event) => {
    setMedicalInfos(event.target.value);
    setHasChanged(true);
  };

  const handleHospitalAppointment = (event) => {
    setHospitalAppointment(event.target.value);
    setHasChanged(true);
  };

  const handleResidentialClientMovements = (event) => {
    setResidentialClientMovements(event.target.value);
    setHasChanged(true);
  };

  const handleMedicalNotes = (event) => {
    setMedicalNotes(event.target.value);
    setHasChanged(true);
  };

  // Other state definitions
  const [isLoading, setIsLoading] = useState(false);

  // Other handler definitions
  const handleClose2 = () => setOpen2(false);
  const [open2, setOpen2] = useState(false);

  const handleUpdateGroupNote = async (note, effectiveDateTime) => {
    const noteData = {
      noteType: "handoverNote",
      noteContent: note,
      updateAt: serverTimestamp(),
      effectiveDateTime: effectiveDateTime,
      doneBy: currentUser.displayName,
      immmigrationAppointment: immigrationAppointment,
      dischargesSwot: dischargeSwot,
      newClients: newClients,
      medicalInfos: medicalInfos,
      hospitalAppointment: hospitalAppointment,
      residentialClientMovements: residentialClientMovements,
      zoomSessionAssistance: zoomSessionAssistance,
      staffToday: staffToday,
      generalAnnouncement: generalAnnouncement,
      overnight: overnight,
      numberOfClient: numberOfClient,
      swot: swot,
    };

    if (
      immigrationAppointment &&
      dischargeSwot &&
      newClients &&
      medicalInfos &&
      hospitalAppointment &&
      residentialClientMovements &&
      zoomSessionAssistance &&
      staffToday &&
      generalAnnouncement &&
      overnight &&
      numberOfClient &&
      swot
    ) {
      setIsLoading(true);
      try {
        const docRef = doc(db, "notes", props.noteId); // Get a reference to the document to update
        await updateDoc(docRef, noteData); // Update the document
        showCustomToast("Handover note successfully updated!", "success");

        setIsLoading(false);
        props.closemodal();
      } catch (error) {
        console.log("error updating note :", error);
        showCustomToast("Problem when updating note!", "error");
      }
    } else {
      showCustomToast(
        "Some mandatory fields are empty. Please check.!",
        "error"
      );
    }
  };

  const handleSubmit = () => {
    handleUpdateGroupNote(medicalNotes, handoverNoteDateTime);
  };

  function formatDate(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    return format(date, "d MMMM yyyy"); // "d" pour le jour, "MMMM" pour le mois en texte, "yyyy" pour l'année
  }

  function formatDateWithTime(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    const formattedDate = format(date, "d MMMM yyyy"); // Date
    const formattedTime = format(date, "H 'h' mm 'min'"); // Heure en format européen

    return `${formattedDate} à ${formattedTime}`;
  }

  return (
    <>
      <style>
        {`
          @media print {
            #printableContent {
              height: auto !important;
              overflow-y: visible !important;
            }
          }
        `}
      </style>
      <div
        className="inputAndLabelModalContainerTitle2"
        style={{ width: "auto", height: "100%" }}
      >
        <div className="line1-modal-percent">
          <div className="modal-title2">
            <>View handover note </>
            <span style={{ fontSize: "12px" }}>
              (last modification on{" "}
              {props.updateAt
                ? formatDate(props.updateAt)
                : formatDate(props.createdAt)}{" "}
              by {props.doneBy})
            </span>
          </div>
          <CloseIcon
            style={{
              marginRight: "16px",
              color: "rgb(214, 213, 213)",
              cursor: "pointer",
            }}
            onClick={props.closemodal}
          />
        </div>

        <div
          id="printableContent"
          style={{
            width: "100%",
            marginTop: "10px",
            height: "75%",
            overflowY: "scroll",
            // overflowX: "hidden",
          }}
          ref={printRef}

          // style={{ marginTop: "60px", height: "500px", overflowY: "scroll" }}
          // style={{ marginTop: "60px", height: "auto", overflowY: "scroll" }}
        >
          {/* <div
            style={{ marginTop: "10px", height: "520px", overflowY: "scroll" }}
          > */}

          <div className="top-title-view-modal">
            Handover note - {formatDate(props.createdAt)}{" "}
            <span style={{ fontSize: "14px" }}> done by :</span>
            <span style={{ fontSize: "16px", fontWeight: "bold" }}>
              {" "}
              {props.doneBy}
            </span>
          </div>

          <ViewBlock
            title="Immigration appointment / visa"
            content={immigrationAppointment}
          />
          <ViewBlock
            title="Discharges / Flights arrangements"
            content={dischargeSwot}
          />
          <ViewBlock title="New clients" content={newClients} />

          <ViewBlock title="Dr. Harshal Appointments" content={medicalInfos} />
          <ViewBlock
            title="(Hospital) appointments"
            content={hospitalAppointment}
          />
          <ViewBlock title="Outings" content={residentialClientMovements} />
          <ViewBlock
            title="Zoom Session Assistance"
            content={zoomSessionAssistance}
          />
          <ViewBlock title="Staff today" content={staffToday} />

          <ViewBlock title="Overnight" content={overnight} />
          <ViewBlock title="Number of clients" content={numberOfClient} />

          <ViewBlock title="Swot" content={swot} />

          <ViewBlock
            title="General announcement / Discussion"
            content={generalAnnouncement}
          />

          <ViewBlock
            title="Effective date and time"
            content={formatDateWithTime(handoverNoteDateTime)}
          />
        </div>

        <div className="line4-modal-percent">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              onClick={props.closemodal}
            >
              Cancel
            </Button>

            <ReactToPrint
              trigger={() => (
                <Button variant="contained" style={{ marginLeft: "20px" }}>
                  Print
                </Button>
              )}
              content={() => printRef.current}
              onAfterPrint={props.closemodal}
            />
          </>
        </div>
      </div>
    </>
  );
}

export default ViewHandoverNotes;
