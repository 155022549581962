import React from "react";

function TitleSearchComponentNoFilter(props) {
  return (
    <div
      style={{
        marginRight: "0px",
      }}
    >
      <div style={{ width: props.width }}>
        <div
          style={{
            fontSize: "12px",
            paddingBottom: "3px",
            fontWeight: "700",
            textAlign: "left",
            // backgroundColor: "lightgray",
            // borderRight: "1px solid black",
            textAlign: "left",
            paddingLeft: "8px",
          }}
        >
          {props.title}
        </div>
      </div>
    </div>
  );
}

export default TitleSearchComponentNoFilter;
