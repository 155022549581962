import React, { useContext } from "react";
import "../css/dashboard.css";
import "../css/client.css";
import ClientProfile from "../component/ClientProfile";
import { useLocation, useParams } from "react-router-dom";
import TopTitleBannerV2 from "../component/TopTitleBannerV2";
import { LanguageContext } from "../context/LanguageContext";

export default function ClientContent(props) {
  const { translate } = useContext(LanguageContext);
  const { id } = useParams();
  const clientId = props.clientId || id;
  const { state } = useLocation();
  const selectedTab = state?.selectedTab || "2";

  return (
    <div className="right-container">
      <TopTitleBannerV2
        title={translate("ClientProfile")}
        clientId={clientId}
      />
      <ClientProfile
        clientId={clientId}
        selectedTab={selectedTab}
        // clientId={props.clientId}
      />
    </div>
  );
}
