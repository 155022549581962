export default [
  {
    id: 1,
    textDropDownEn: "--Choose--",
  },

  {
    id: 2,
    textDropDownEn: "Client 1",
  },

  {
    id: 3,
    textDropDownEn: "Client 2",
  },

  {
    id: 4,
    textDropDownEn: "Client 3",
  },

  {
    id: 5,
    textDropDownEn: "Client 4",
  },
  {
    id: 6,
    textDropDownEn: "Client 5",
  },
  {
    id: 7,
    textDropDownEn: "Client 6",
  },
];
