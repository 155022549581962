export default [
  {
    id: 1,
    textDropDownEn: "-- Choose --",
  },

  {
    id: 2,
    textDropDownEn: "Signed Up",
  },

  {
    id: 3,
    textDropDownEn: "Not Signed Up",
  },
];
