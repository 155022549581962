export default [
  {
    id: 1,
    textDropDownEn: "--Choose--",
  },

  {
    id: 2,
    textDropDownEn: "Drug 1",
  },

  {
    id: 3,
    textDropDownEn: "Drug 2",
  },

  {
    id: 4,
    textDropDownEn: "Drug 3",
  },

  {
    id: 5,
    textDropDownEn: "Drug 4",
  },

  {
    id: 6,
    textDropDownEn: "Drug 5",
  },

  {
    id: 7,
    textDropDownEn: "Drug 6",
  },

  {
    id: 8,
    textDropDownEn: "Other",
  },
];
