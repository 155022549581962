import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import DatePickerWithTimeComponent from "../DatePickerWithTimeComponent";
import DropDownComponent from "../DropDownComponent";
import { useAuth } from "../../contexts/AuthContext";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { showCustomToast } from "../Misc/CustomToast";
import UploaderDocForNote from "../Updloader/UploaderDocForNote";
import InputComponent from "../InputComponent";
import TopTitleFormComponent from "../forms/TopTitleFormComponent";

function AddFamilyNote(props) {
  //VARIABLES
  const location = useLocation();
  const state = location.state;
  const { currentUser } = useAuth();

  //STATES
  const [isLoading, setIsLoading] = useState(false);
  const [familyNote, setFamilyNote] = useState("");
  const [familyNoteDateTime, setFamilyNoteDateTime] = useState(new Date());
  const [relationshipFamilyNote, setRelationshipFamilyNote] = useState("");
  const [relationshipFamilyNoteOther, setRelationshipFamilyNoteOther] =
    useState("");
  const [files, setFiles] = useState([]);

  //FUNCTIONS
  const handleFamilyNoteChange = (event) => {
    setFamilyNote(event.target.value);
  };

  const handleDateTimeChange = (event) => {
    setFamilyNoteDateTime(event);
  };

  const handleRelationshipFamilyNote = (event) => {
    setRelationshipFamilyNote(event);
  };

  const handleRelationshipFamilyNoteOther = (event) => {
    setRelationshipFamilyNoteOther(event);
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length <= 10) {
      setFiles(event.target.files);
    } else {
      showCustomToast("You can only upload up to 10 files.", "error");
    }
  };

  useEffect(() => {}, [files]);

  const uploadFilesAndGetUrls = async (docId) => {
    const storage = getStorage();
    const fileUrls = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const filePath = `Documents/familyNotes/${docId}/${file.name}`;
      const storageRef = ref(storage, filePath);

      await uploadBytes(storageRef, file);
      const fileUrl = await getDownloadURL(storageRef);
      fileUrls.push({ url: fileUrl, name: file.name });
    }

    return fileUrls;
  };

  const handleAddFamilyNote = async (
    note,
    clientID,
    effectiveDateTime,
    relationship,
    relationshipOther
  ) => {
    const noteData = {
      noteType: "familyNote",
      noteContent: note,
      clientId: clientID,
      createdAt: serverTimestamp(),
      effectiveDateTime: effectiveDateTime,
      doneBy: currentUser.displayName,
      relationShipFamilyNote: relationship,
      clientFirstName: props.firstName,
      clientLastName: props.lastName,
      clientProfilePicture: props.profilePicture || "",
      relationShipFamilyNoteOther: relationshipOther,
    };

    if (familyNote && familyNoteDateTime && relationshipFamilyNote) {
      setIsLoading(true);
      try {
        const docRef = await addDoc(collection(db, "notes"), noteData);
        const fileUrls = await uploadFilesAndGetUrls(docRef.id);
        await updateDoc(doc(db, "notes", docRef.id), {
          fileUrls: fileUrls,
        });
        showCustomToast(`Non therapy note successfully added!`, "success");

        setIsLoading(false);
        props.closemodal();
      } catch (error) {
        console.log("error adding note :", error);
        showCustomToast(`Problem when adding note`, "error");
      }
    } else {
      showCustomToast(
        `Some mandatory fields are empty. Please check.`,
        "error"
      );
    }
  };

  const handleSubmit = () => {
    handleAddFamilyNote(
      familyNote,
      props.clientId,
      familyNoteDateTime,
      relationshipFamilyNote,
      relationshipFamilyNoteOther
    );
  };

  return (
    <>
      <div
        // className="inputAndLabelModalContainerTitle2"
        style={{ width: "auto", height: "100%" }}
      >
        <div className="line1-modal-percent">
          <TopTitleFormComponent
            title="ADD FAMILY/SIGNIFICANT OTHER NOTE"
            closemodal={props.closemodal}
          />
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "0px",
            height: "80%",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <div className="line2-column-modal-bis">
            <div className="label-textfield" style={{ paddingLeft: "10px" }}>
              Note content
            </div>
            <TextField
              value={familyNote}
              onChange={handleFamilyNoteChange}
              style={{
                width: "95%",
                borderRadius: "5px",
                backgroundColor: "#f2eeef",
              }}
              placeholder="Type family/significant other note here"
              multiline
              rows={12}
            />
          </div>
          <div
            className="line3-modal-nopad"
            style={{
              // marginLeft: "20px",
              paddingLeft: "20px",
              marginBottom: "20px",
              paddingTop: "10px",
            }}
          >
            <DatePickerWithTimeComponent
              value={familyNoteDateTime}
              onChange={handleDateTimeChange}
              title="Effective date and time"
            />

            <DropDownComponent
              api="relationship"
              title="Relationship to client"
              value={relationshipFamilyNote}
              onChange={handleRelationshipFamilyNote}
            />
            {relationshipFamilyNote === "Other" ? (
              <InputComponent
                title="If Other, describe"
                value={relationshipFamilyNoteOther}
                onChange={handleRelationshipFamilyNoteOther}
                style={{
                  width: "95%",
                  borderRadius: "5px",
                  backgroundColor: "#f2eeef",
                }}
                // placeholder="Type family/significant other note here"
              />
            ) : (
              ""
            )}
          </div>

          <UploaderDocForNote
            handleFileChange={handleFileChange}
            files={files}
          />
        </div>
        <div className="line4-modal-percent-bis">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
              onClick={props.closemodal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                    }
              }
              disabled={isLoading}
              onClick={handleSubmit}
            >
              {isLoading ? "ADDING NOTE..." : "ADD NOTE"}
            </Button>
          </>
        </div>
      </div>
    </>
  );
}

export default AddFamilyNote;
