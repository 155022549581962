// import React, { useState } from "react";
// import ViewButton from "../Buttons/ViewButton";
// import CheckWithDate from "../CheckWithDate";
// import { Box, Modal } from "@mui/material";
// import ViewInformedConsent from "../forms/ViewInformedConsent";

// function FormLineIC(props) {
//   const styleModal = {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     width: 700,
//     height: "80%",
//     borderRadius: 5,
//     backgroundColor: "white",
//     /*border: "1px solid #000",*/
//     boxShadow: 24,
//   };

//   const [showViewModal, setShowViewModal] = useState(false);

//   const openViewModal = () => {
//     setShowViewModal(true);
//   };

//   const closeViewModal = () => {
//     setShowViewModal(false);
//   };

//   return (
//     <div
//       className={
//         props.color === "1" ? "formLine light-grey-form" : "formLine grey-form"
//       }
//       style={{ marginLeft: "0px" }}
//     >
//       <Modal
//         open={showViewModal}
//         onClose={closeViewModal}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={styleModal}>
//           <ViewInformedConsent {...props} closemodal={closeViewModal} />
//         </Box>
//       </Modal>
//       <div className="formLineBlock1">{props.title}</div>
//       <div className="formLineBlock2">
//         {props.signed !== true || props.signed === undefined ? (
//           <CheckWithDate checked={false} content="Not signed" />
//         ) : (
//           <CheckWithDate checked={true} content={props.date} />
//         )}
//       </div>
//       <div className="formLineBlock3">
//         {props.signed ? (
//           <ViewButton handleOpen={openViewModal} {...props} />
//         ) : (
//           ""
//         )}
//       </div>
//     </div>
//   );
// }

// export default FormLineIC;

import React, { useState } from "react";
import ViewButton from "../Buttons/ViewButton";
import AddButtonRound from "../Buttons/AddButtonRound";
import CheckWithDate from "../CheckWithDate";
import { Box, Modal, Slide } from "@mui/material";
import ViewInformedConsent from "../forms/ViewInformedConsent";

function FormLineIC(props) {
  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: "80%",
    borderRadius: 5,
    backgroundColor: "white",
    boxShadow: 24,
  };

  const [showViewModal2, setShowViewModal2] = useState(false);

  const openViewModal2 = () => {
    setShowViewModal2(true);
  };

  const closeViewModal2 = () => {
    setShowViewModal2(false);
  };

  console.log("showViewModal2 : ", showViewModal2);

  return (
    <div
      style={{
        marginLeft: "0px",
        display: "flex",
        flexDirection: "row",
        height: "50px",
        borderRadius: "30px",
        border: "1px solid lightgrey",
        width: "40%",
        marginTop: "5px",
        marginBottom: "5px",
        paddingLeft: "15px",
        paddingRight: "5px",
      }}
    >
      <Modal open={showViewModal2} onClose={closeViewModal2}>
        <Slide direction="left" in={showViewModal2}>
          <Box
            sx={{
              position: "fixed",
              top: "5%",
              right: 0,
              width: "30%",
              height: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              marginRight: "15px",
              pl: 0,
              pr: 0,
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ViewInformedConsent {...props} closemodal={closeViewModal2} />
          </Box>
        </Slide>
      </Modal>

      <div
        style={{
          width: "60%",
          fontSize: "18px",
          fontWeight: "600",
          display: "flex",
          justifyContent: "flex-start",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {props.title}
      </div>
      <div
        style={{
          width: "30%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.signed ? (
          <CheckWithDate checked={true} content={props.date} />
        ) : (
          <CheckWithDate checked={false} content="Not signed" />
        )}
      </div>

      <div
        style={{
          width: "10%",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {props.signed ? (
          <ViewButton handleOpen2={openViewModal2} {...props} />
        ) : (
          <AddButtonRound handleOpen={props.handleOpen} />
        )}
      </div>
    </div>
  );
}

export default FormLineIC;
