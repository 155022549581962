import React, { useState } from "react";
import RoomLayoutMapMobile from "./RoomLayoutMapMobile";
import RoomLayoutMap2Mobile from "./RoomLayoutMap2Mobile";

export default function CasadaSwitchMobile(props) {
  const [selectedCasada, setSelectedCasada] = useState("Casada 1"); // État pour le switch

  const handleChange = (event) => {
    setSelectedCasada(event.target.value); // Met à jour l'état selon la sélection
  };

  return (
    <div
      className="main-top-title-banner-container"
      style={{ paddingTop: "10px" }}
    >
      {/* <div className="date-container">
        <DateToday />
      </div>
      <div className="title-text-banner">{props.title}</div> */}
      {/* <div
        style={{
          width: "100%",
          paddingTop: "10px",
          paddingBottom: "10px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <select
          value={selectedCasada}
          onChange={handleChange}
          style={{ fontSize: "16px" }}
        >
          <option value="Casada 1">Casada 1</option>
          <option value="Casada 2">Casada 2</option>
        </select>
      </div> */}
      {/* Affiche le composant correspondant selon le choix */}
      {selectedCasada === "Casada 1" ? (
        <RoomLayoutMapMobile />
      ) : (
        <RoomLayoutMap2Mobile />
      )}
    </div>
  );
}
