import React from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";

function ViewButtonSmall(props) {
  return (
    <div
      className="square-button-small view-button-color centered-content button-pointer"
      title="View"
      onClick={props.handleOpen}
    >
      <VisibilityIcon style={{ color: "white", fontSize: 15 }} />
    </div>
  );
}

export default ViewButtonSmall;
