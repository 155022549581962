// import { TextField } from "@mui/material";
// import React from "react";
// import marital_status from "../api/maritalStatus";
// import sexual_orientation from "../api/sexualOrientation";
// import gender from "../api/gender";
// import living_with from "../api/livingwith";
// import nationalities from "../api/nationalities";
// import countries from "../api/countries";
// import relationship from "../api/relationship";
// import addictions from "../api/addictions";
// import allergies from "../api/allergies";
// import drugallergies from "../api/drugallergies";
// import aftercareStaff from "../api/aftercareStaff";
// import ids from "../api/intendedDurationOfStay";
// import groupType from "../api/groupType";
// import dummyClients from "../api/dummyClients";
// import dischargeType from "../api/dischargesType";
// import dischargeTo from "../api/dischargeTo";
// import treatmentType from "../api/treatmentType";
// import treatmentOutcome from "../api/treatmentOutcome";
// import substances from "../api/substances";
// import route from "../api/route";
// import roles from "../api/roles";
// import clientStatus from "../api/clientStatus";
// import durationOfStay from "../api/durationOfStay";
// import "../css/inputs/Dropdown.css";
// import pendingReceived from "../api/pendingReceived";
// import pendingBooked from "../api/pendingBooked";
// import paymentDetails from "../api/paymentDetails";
// import status from "../api/status";
// import yesno from "../api/yesno";
// import doneNotDone from "../api/doneNotDone";
// import clientSource from "../api/clientSource";

// export default function DropDownComponentSortedNoTitle(props) {
//   const api = props.api;
//   const apiData = {
//     maritalStatus: marital_status,
//     sexualOrientation: sexual_orientation,
//     gender: gender,
//     livingWith: living_with,
//     nationalities: nationalities,
//     countries: countries,
//     relationship: relationship,
//     addictions: addictions,
//     allergies: allergies,
//     drugallergies: drugallergies,
//     ids: ids,
//     aftercareStaff: aftercareStaff,
//     groupType: groupType,
//     dummyClients: dummyClients,
//     dischargeType: dischargeType,
//     dischargeTo: dischargeTo,
//     treatmentType: treatmentType,
//     treatmentOutcome: treatmentOutcome,
//     substances: substances,
//     route: route,
//     roles: roles,
//     clientStatus: clientStatus,
//     durationOfStay: durationOfStay,
//     pendingReceived: pendingReceived,
//     pendingBooked: pendingBooked,
//     paymentDetail: paymentDetails,
//     status: status,
//     yesno: yesno,
//     donenotdone: doneNotDone,
//     clientSource: clientSource,
//   };

//   const options = apiData[api];

//   const handleChange = (event) => {
//     props.onChange(event.target.value);
//   };

//   const renderOptions = () => {
//     if (props.level === "2") {
//       const category = options.find(
//         (option) => option.textDropDownEn === props.mainSubstance
//       );
//       if (category && category.subcategories) {
//         return category.subcategories
//           .sort((a, b) => a.id - b.id)
//           .map((sub) => (
//             <option key={sub.id} value={sub.textDropDownEn}>
//               {sub.textDropDownEn}
//             </option>
//           ));
//       }
//     } else {
//       return options
//         .sort((a, b) => a.id - b.id)
//         .map((option) => (
//           <option key={option.id} value={option.textDropDownEn}>
//             {option.textDropDownEn}
//           </option>
//         ));
//     }
//   };

//   return (
//     <div className="input-label-container-newdesign">
//       {/* <div className="input-label-dropdown">
//         {props.title}{" "}
//         {props.isRequired && <span style={{ color: "red" }}>*</span>}
//       </div> */}
//       <TextField
//         className="dropdown-select"
//         SelectProps={{
//           native: true,
//           style: { height: 40 },
//         }}
//         select
//         value={props.value}
//         onChange={handleChange}
//       >
//         {renderOptions()}
//       </TextField>
//     </div>
//   );
// }

import React from "react";
import { TextField } from "@mui/material";
import groupType from "../api/groupType"; // Import the necessary API data
import "../css/inputs/Dropdown.css";

export default function DropDownComponentSortedNoTitle(props) {
  const api = props.api;
  const apiData = {
    groupType: groupType,
    // Add other APIs if needed
  };

  const options = apiData[api];

  const handleChange = (event) => {
    props.onChange(event.target.value);
  };

  const renderOptions = () => {
    if (props.level === "2") {
      const category = options.find(
        (option) => option.textDropDownEn === props.mainSubstance
      );
      if (category && category.subcategories) {
        return category.subcategories
          .sort((a, b) => a.id - b.id)
          .map((sub) => (
            <option key={sub.id} value={sub.textDropDownEn}>
              {sub.textDropDownEn}
            </option>
          ));
      }
    } else {
      return options
        .sort((a, b) => a.id - b.id)
        .map((option) => (
          <option key={option.id} value={option.textDropDownEn}>
            {option.textDropDownEn}
          </option>
        ));
    }
  };

  return (
    <div className="input-label-container-newdesign">
      <TextField
        className="dropdown-select"
        SelectProps={{
          native: true,
          style: { height: 40 },
        }}
        select
        value={props.value}
        onChange={handleChange}
        error={props.error}
        helperText={props.helperText}
      >
        {renderOptions()}
      </TextField>
    </div>
  );
}
