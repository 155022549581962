// // import React, { forwardRef } from "react";

// // const PDFContent = forwardRef((props, ref) => {
// //   const {
// //     firstName,
// //     lastName,
// //     text1,
// //     text2,
// //     text3,
// //     text4,
// //     GeneralLiabilityDateSigned,
// //     signatureURL,
// //   } = props.data;

// //   const formatDate = (timestamp) => {
// //     const date = new Date(timestamp.seconds * 1000);
// //     const day = date.getDate().toString().padStart(2, "0");
// //     const monthNames = [
// //       "January",
// //       "February",
// //       "March",
// //       "April",
// //       "May",
// //       "June",
// //       "July",
// //       "August",
// //       "September",
// //       "October",
// //       "November",
// //       "December",
// //     ];
// //     const month = monthNames[date.getMonth()];
// //     const year = date.getFullYear();
// //     const hours = date.getHours().toString().padStart(2, "0");
// //     const minutes = date.getMinutes().toString().padStart(2, "0");
// //     const timeZone = date
// //       .toLocaleString("en", { timeZoneName: "short" })
// //       .split(" ")
// //       .pop();
// //     return `${day} ${month} ${year} ${hours}:${minutes} ${timeZone}`;
// //   };

// //   return (
// //     <div ref={ref} style={{ padding: "20px", backgroundColor: "white" }}>
// //       <h3>
// //         General liability form for: {firstName || ""} {lastName || ""}
// //       </h3>
// //       <div>{text1 || ""}</div>
// //       <div>
// //         <input type="checkbox" checked />I fully understand and agree to the
// //         above terms.
// //       </div>
// //       <div>{text2 || ""}</div>
// //       <div>
// //         <input type="checkbox" checked />I fully understand and agree to the
// //         above terms.
// //       </div>
// //       <div>{text3 || ""}</div>
// //       <div>
// //         <input type="checkbox" checked />I fully understand and agree to the
// //         above terms.
// //       </div>
// //       <div>{text4 || ""}</div>
// //       <div>
// //         <input type="checkbox" checked />I fully understand and agree to the
// //         above terms.
// //       </div>
// //       <div>
// //         Timestamp signature:{" "}
// //         {GeneralLiabilityDateSigned
// //           ? formatDate(GeneralLiabilityDateSigned)
// //           : "N/A"}
// //       </div>
// //       <div>
// //         Client signature ({firstName || ""} {lastName || ""}):
// //       </div>
// //       <img
// //         id="signature"
// //         src={signatureURL || ""}
// //         alt="Signature"
// //         style={{
// //           marginLeft: "25px",
// //           marginTop: "20px",
// //           border: "1px solid black",
// //           borderRadius: "20px",
// //           padding: "30px",
// //           width: "300px",
// //           height: "200px",
// //         }}
// //       />
// //     </div>
// //   );
// // });

// // export default PDFContent;

// import React from "react";

// const PDFContent = React.forwardRef(({ data }, ref) => {
//   const {
//     firstName,
//     lastName,
//     text1,
//     text2,
//     text3,
//     text4,
//     GeneralLiabilityDateSigned,
//     signatureURL,
//   } = data;

//   return (
//     <div ref={ref}>
//       <div style={{ padding: "20px" }}>
//         <h3>
//           General liability form for: {firstName || ""} {lastName || ""}
//         </h3>
//         <div>{text1 || ""}</div>
//         <div>
//           <input type="checkbox" checked readOnly />I fully understand and agree
//           to the above terms.
//         </div>
//         <div>{text2 || ""}</div>
//         <div>
//           <input type="checkbox" checked readOnly />I fully understand and agree
//           to the above terms.
//         </div>
//         <div>{text3 || ""}</div>
//         <div>
//           <input type="checkbox" checked readOnly />I fully understand and agree
//           to the above terms.
//         </div>
//         <div>{text4 || ""}</div>
//         <div>
//           <input type="checkbox" checked readOnly />I fully understand and agree
//           to the above terms.
//         </div>
//         <div>
//           Timestamp signature:{" "}
//           {GeneralLiabilityDateSigned
//             ? new Date(
//                 GeneralLiabilityDateSigned.seconds * 1000
//               ).toLocaleString()
//             : "N/A"}
//         </div>
//         <div>
//           Client signature ({firstName || ""} {lastName || ""}):
//         </div>
//         <img
//           id="signature"
//           src={signatureURL || ""}
//           alt="Signature"
//           style={{
//             marginLeft: "25px",
//             marginTop: "20px",
//             border: "1px solid black",
//             borderRadius: "20px",
//             padding: "30px",
//             width: "300px",
//             height: "200px",
//           }}
//         />
//       </div>
//     </div>
//   );
// });

// export default PDFContent;

import React from "react";

const PDFContent = React.forwardRef(({ data }, ref) => {
  const {
    firstName,
    lastName,
    text1,
    text2,
    text3,
    text4,
    GeneralLiabilityDateSigned,
    signatureURL,
  } = data;

  return (
    <div ref={ref}>
      <div style={{ padding: "20px" }}>
        <h3>
          General liability form for: {firstName || ""} {lastName || ""}
        </h3>
        <div>{text1 || ""}</div>
        <div>
          <input type="checkbox" checked readOnly />I fully understand and agree
          to the above terms.
        </div>
        <div>{text2 || ""}</div>
        <div>
          <input type="checkbox" checked readOnly />I fully understand and agree
          to the above terms.
        </div>
        <div>{text3 || ""}</div>
        <div>
          <input type="checkbox" checked readOnly />I fully understand and agree
          to the above terms.
        </div>
        <div>{text4 || ""}</div>
        <div>
          <input type="checkbox" checked readOnly />I fully understand and agree
          to the above terms.
        </div>
        <div>
          Timestamp signature:{" "}
          {GeneralLiabilityDateSigned
            ? new Date(
                GeneralLiabilityDateSigned.seconds * 1000
              ).toLocaleString()
            : "N/A"}
        </div>
        <div>
          Client signature ({firstName || ""} {lastName || ""}):
        </div>
        <img
          id="signature"
          src={signatureURL || ""}
          alt="Signature"
          style={{
            marginLeft: "25px",
            marginTop: "20px",
            border: "1px solid black",
            borderRadius: "20px",
            padding: "30px",
            width: "300px",
            height: "200px",
          }}
        />
      </div>
    </div>
  );
});

export default PDFContent;
