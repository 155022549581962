// import { getDoc, doc } from "firebase/firestore";
// import { getDownloadURL, ref } from "firebase/storage";
// import * as XLSX from "xlsx";
// import JSZip from "jszip";
// import { saveAs } from "file-saver";
// import { db, storage } from "../../firebase";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
// import ReactToPrint from "react-to-print";
// import { render } from "react-dom";
// import PDFContent from "./PDFContent";
// import React from "react";
// import { createRoot } from "react-dom/client";

// const getClientData = async (clientId) => {
//   const clientData = {};
//   const clientRef = doc(db, "clients", clientId);

//   const clientSnapshot = await getDoc(clientRef);
//   if (clientSnapshot.exists()) {
//     clientData.profile = clientSnapshot.data();
//   }

//   console.log("Client profile data:", clientData.profile); // Ajout du console.log

//   // Ajoutez ici les autres collections à récupérer
//   const collections = [
//     "preAdmissionAssessments",
//     "mandatoryForms",
//     "notes",
//     "GeneralLiability",
//   ];
//   for (const collectionName of collections) {
//     const collectionRef = doc(db, collectionName, clientId);
//     const collectionSnapshot = await getDoc(collectionRef);
//     if (collectionSnapshot.exists()) {
//       clientData[collectionName] = collectionSnapshot.data();
//     }
//     console.log(
//       `Data for collection ${collectionName}:`,
//       clientData[collectionName]
//     ); // Ajout du console.log
//   }

//   return clientData;
// };

// const generatePDF = async (clientId, data) => {
//   return new Promise((resolve, reject) => {
//     const printRef = React.createRef();

//     const handlePrint = () => {
//       const pdf = new jsPDF();
//       html2canvas(printRef.current, { scale: 2 })
//         .then((canvas) => {
//           const imgData = canvas.toDataURL("image/png");
//           pdf.addImage(imgData, "PNG", 10, 10, 190, 277);
//           resolve(pdf.output("blob"));
//         })
//         .catch(reject);
//     };

//     // Créez un conteneur pour rendre l'élément
//     const container = document.createElement("div");
//     document.body.appendChild(container);

//     const root = createRoot(container);
//     root.render(
//       <div style={{ display: "none" }}>
//         <PDFContent ref={printRef} data={data} />
//         <ReactToPrint
//           trigger={() => <button style={{ display: "none" }}>Print</button>}
//           content={() => printRef.current}
//           onAfterPrint={handlePrint}
//         />
//       </div>
//     );
//   });
// };

// const getFiles = async (clientId) => {
//   const files = {};

//   const fileRefs = [{ key: "profilePicture", path: `profilePics/${clientId}` }];

//   for (const fileRef of fileRefs) {
//     try {
//       const fileURL = await getDownloadURL(ref(storage, fileRef.path));
//       const response = await fetch(fileURL);
//       const blob = await response.blob();
//       files[fileRef.key] = blob;
//     } catch (error) {
//       console.error(`Error fetching file ${fileRef.path}:`, error);
//     }
//   }

//   return files;
// };

// const createExcelFiles = (clientData) => {
//   const files = {};

//   for (const [key, data] of Object.entries(clientData)) {
//     const worksheet = XLSX.utils.json_to_sheet([data]);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, key);
//     const excelBuffer = XLSX.write(workbook, {
//       bookType: "xlsx",
//       type: "array",
//     });
//     files[`${key}.xlsx`] = new Blob([excelBuffer], {
//       type: "application/octet-stream",
//     });
//   }

//   return files;
// };

// export const createZip = async (clientId) => {
//   const clientData = await getClientData(clientId);
//   const files = await getFiles(clientId);
//   const excelFiles = createExcelFiles(clientData);

//   const zip = new JSZip();

//   for (const [fileName, fileContent] of Object.entries(excelFiles)) {
//     zip.file(fileName, fileContent);
//   }

//   for (const [fileName, fileContent] of Object.entries(files)) {
//     zip.file(`${fileName}.${fileContent.type.split("/")[1]}`, fileContent);
//   }

//   // Attendez que le PDF soit généré avant de l'ajouter au ZIP
//   const pdfBlob = await generatePDF(clientId, clientData.GeneralLiability);
//   zip.file(`${clientId}_GeneralLiability.pdf`, pdfBlob);

//   const zipContent = await zip.generateAsync({ type: "blob" });
//   saveAs(zipContent, `${clientId}_data.zip`);
// };

import { getDoc, doc } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import * as XLSX from "xlsx";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { db, storage } from "../../firebase";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactToPrint from "react-to-print";
import React from "react";
import { createRoot } from "react-dom/client";
import PDFContent from "./PDFContent";

const getClientData = async (clientId) => {
  const clientData = {};
  const clientRef = doc(db, "clients", clientId);

  const clientSnapshot = await getDoc(clientRef);
  if (clientSnapshot.exists()) {
    clientData.profile = clientSnapshot.data();
  }

  console.log("Client profile data:", clientData.profile);

  const collections = [
    "preAdmissionAssessments",
    "mandatoryForms",
    "notes",
    "GeneralLiability",
  ];
  for (const collectionName of collections) {
    const collectionRef = doc(db, collectionName, clientId);
    const collectionSnapshot = await getDoc(collectionRef);
    if (collectionSnapshot.exists()) {
      clientData[collectionName] = collectionSnapshot.data();
    } else {
      console.log(
        `Collection ${collectionName} does not exist for client ${clientId}`
      );
    }
    console.log(
      `Data for collection ${collectionName}:`,
      clientData[collectionName]
    );
  }

  return clientData;
};

const generatePDF = async (clientId, data) => {
  return new Promise((resolve, reject) => {
    const printRef = React.createRef();

    const handlePrint = () => {
      const pdf = new jsPDF();
      html2canvas(printRef.current, { scale: 2 })
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          pdf.addImage(imgData, "PNG", 10, 10, 190, 277);
          resolve(pdf.output("blob"));
        })
        .catch(reject);
    };

    const container = document.createElement("div");
    document.body.appendChild(container);

    const root = createRoot(container);
    root.render(
      <div style={{ display: "none" }}>
        <PDFContent ref={printRef} data={data} />
        <ReactToPrint
          trigger={() => <button style={{ display: "none" }}>Print</button>}
          content={() => printRef.current}
          onAfterPrint={handlePrint}
        />
      </div>
    );
  });
};

const getFiles = async (clientId) => {
  const files = {};

  const fileRefs = [{ key: "profilePicture", path: `profilePics/${clientId}` }];

  for (const fileRef of fileRefs) {
    try {
      const fileURL = await getDownloadURL(ref(storage, fileRef.path));
      const response = await fetch(fileURL);
      const blob = await response.blob();
      files[fileRef.key] = blob;
    } catch (error) {
      console.error(`Error fetching file ${fileRef.path}:`, error);
    }
  }

  return files;
};

const createExcelFiles = (clientData) => {
  const files = {};

  for (const [key, data] of Object.entries(clientData)) {
    const worksheet = XLSX.utils.json_to_sheet([data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, key);
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    files[`${key}.xlsx`] = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
  }

  return files;
};

export const createZip = async (clientId) => {
  const clientData = await getClientData(clientId);
  const files = await getFiles(clientId);
  const excelFiles = createExcelFiles(clientData);

  const zip = new JSZip();

  for (const [fileName, fileContent] of Object.entries(excelFiles)) {
    zip.file(fileName, fileContent);
  }

  for (const [fileName, fileContent] of Object.entries(files)) {
    zip.file(`${fileName}.${fileContent.type.split("/")[1]}`, fileContent);
  }

  const pdfBlob = await generatePDF(clientId, clientData.GeneralLiability);
  zip.file(`${clientId}_GeneralLiability.pdf`, pdfBlob);

  const zipContent = await zip.generateAsync({ type: "blob" });
  saveAs(zipContent, `${clientId}_data.zip`);
};
