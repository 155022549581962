import { Box, Tab, Modal } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import React, { useState } from "react";
import AddQuickNote from "../Notes/AddQuickNote";
import AddTherapyNote from "../Notes/AddTherapyNote";
import AddNonTherapyNote from "../Notes/AddNonTherapyNote";
import AddFamilyNote from "../Notes/AddFamilyNote";
import AddGroupNote from "../Notes/AddGroupNote";
import AddMedicalNote from "../Notes/AddMedicalNote";
import AddHandoverNote from "../Notes/AddHandoverNote";
import TopTitleNoteContainerMain from "../Notes/TopTitleNoteContainerMain";
import { useTheme } from "@mui/material/styles";
import { Container } from "@mui/material";
import { Grid } from "@mui/material";
import QuickNotesContainerAll from "../containers/QuickNotesContainerAll";
import FamilyNotesContainerAll from "../containers/FamilyNotesContainerAll";
import MedicalNotesAll from "../containers/MedicalNotesAll";
import GroupNotesAll from "../containers/GroupNotesAll";
import HandoverNotesAll from "../containers/HandoverNotesAll";
import AddAftercareNote from "../Notes/AddAftercareNote";
import NonTherapyNoteContainerAll from "../containers/NonTherapyNoteContainerAll";
import EndOfShiftNotesContainer from "../containers/EndOfShiftNotesContainer";
import AddEndOfShiftNote from "../Notes/AddEndOfShiftNote";

function ClientNotesMainMedicalStaff(props) {
  const theme = useTheme();
  const styleLive = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: 600,
    borderRadius: 5,
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const styleFamilyNote = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: 800,
    borderRadius: 5,
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const styleGroupNote = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: 750,
    borderRadius: 5,
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const styleHandoverNote = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "80%",
    borderRadius: 5,
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const dateToday = new Date();

  const [openQuickNote, setOpenQuickNote] = useState(false);
  const [openTherapyNote, setOpenTherapyNote] = useState(false);
  const [openNonTherapyNote, setOpenNonTherapyNote] = useState(false);
  const [openFamilyNote, setOpenFamilyNote] = useState(false);
  const [openGroupNote, setOpenGroupNote] = useState(false);
  const [openMedicalNote, setOpenMedicalNote] = useState(false);
  const [openHandoverNote, setOpenHandoverNote] = useState(false);
  const [openAftercareNote, setOpenAftercareNote] = useState(false);
  const [openEndOfShiftNote, setOpenEndOfShiftNote] = useState(false);

  const handleOpenQuickNote = () => setOpenQuickNote(true);
  const handleOpenTherapyNote = () => setOpenTherapyNote(true);
  const handleOpenNonTherapyNote = () => setOpenNonTherapyNote(true);
  const handleOpenFamilyNote = () => setOpenFamilyNote(true);
  const handleOpenGroupNote = () => setOpenGroupNote(true);
  const handleOpenMedicalNote = () => setOpenMedicalNote(true);
  const handleOpenHandoverNote = () => setOpenHandoverNote(true);
  const handleOpenAftercareNote = () => setOpenAftercareNote(true);
  const handleOpenEndOfShiftNote = () => setOpenEndOfShiftNote(true);

  const handleCloseTherapyNote = () => setOpenTherapyNote(false);
  const handleCloseNonTherapyNote = () => setOpenNonTherapyNote(false);
  const handleCloseQuickNote = () => setOpenQuickNote(false);
  const handleCloseFamilyNote = () => setOpenFamilyNote(false);
  const handleCloseGroupNote = () => setOpenGroupNote(false);
  const handleCloseMedicalNote = () => setOpenMedicalNote(false);
  const handleCloseHandoverNote = () => setOpenHandoverNote(false);
  const handleCloseAftercareNote = () => setOpenAftercareNote(false);

  const handleCloseEndOfShiftNote = () => setOpenEndOfShiftNote(false);

  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="tab-container-white">
      <div className="tab-form-container">
        <Modal
          open={openQuickNote}
          onClose={handleCloseQuickNote}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleLive}>
            <AddQuickNote closemodal={handleCloseQuickNote} />
          </Box>
        </Modal>
        <Modal
          open={openEndOfShiftNote}
          onClose={handleCloseEndOfShiftNote}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleLive}>
            <AddEndOfShiftNote
              closemodal={handleCloseEndOfShiftNote}
              // Autres props si nécessaire
            />
          </Box>
        </Modal>
        <Modal
          open={openTherapyNote}
          onClose={handleCloseTherapyNote}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleLive}>
            <AddTherapyNote
              dateTherapyNote={dateToday}
              closemodal={handleCloseTherapyNote}
            />
          </Box>
        </Modal>
        <Modal
          open={openNonTherapyNote}
          onClose={handleCloseNonTherapyNote}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleLive}>
            <AddNonTherapyNote
              dateNonTherapyNote={dateToday}
              closemodal={handleCloseNonTherapyNote}
            />
          </Box>
        </Modal>

        <Modal
          open={openFamilyNote}
          onClose={handleCloseFamilyNote}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleFamilyNote}>
            <AddFamilyNote
              dateNonTherapyNote={dateToday}
              closemodal={handleCloseFamilyNote}
            />
          </Box>
        </Modal>
        <Modal
          open={openGroupNote}
          onClose={handleCloseGroupNote}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleGroupNote}>
            <AddGroupNote
              dateNonTherapyNote={dateToday}
              closemodal={handleCloseGroupNote}
              {...props}
            />
          </Box>
        </Modal>
        <Modal
          open={openMedicalNote}
          onClose={handleCloseMedicalNote}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleLive}>
            <AddMedicalNote
              dateNonTherapyNote={dateToday}
              closemodal={handleCloseMedicalNote}
            />
          </Box>
        </Modal>

        <Modal
          open={openHandoverNote}
          onClose={handleCloseHandoverNote}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleHandoverNote}>
            <AddHandoverNote
              dateNonTherapyNote={dateToday}
              closemodal={handleCloseHandoverNote}
            />
          </Box>
        </Modal>

        <Modal
          open={openAftercareNote}
          onClose={handleCloseAftercareNote}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleLive}>
            <AddAftercareNote
              dateNonTherapyNote={dateToday}
              closemodal={handleCloseAftercareNote}
              clientId={props.clientId}
              {...props}
            />
          </Box>
        </Modal>

        <Container maxWidth="lg">
          <Box sx={{ padding: "16px" }}>
            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divier",
                  width: "auto",
                }}
              >
                <Grid container alignItems="flex-start">
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        overflowX: "auto",
                        "&::-webkit-scrollbar": {
                          width: "0.5em",
                          height: "0.5em",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                        },
                      }}
                    >
                      <TabList
                        aria-label="Tabs example"
                        onChange={handleChange}
                        sx={{
                          flexGrow: 1,
                          whiteSpace: "nowrap",
                          "& .MuiTab-root": {
                            minWidth: "auto",
                            [theme.breakpoints.down("sm")]: {
                              flexBasis: "50%",
                            },
                          },
                        }}
                        variant="scrollable"
                        scrollButtons="auto"
                      >
                        <Tab label="Quick notes" value="1" />
                        <Tab label="Handover notes" value="2" />
                        <Tab label="1 to 1 support staff notes" value="3" />
                        <Tab label="Medical notes" value="4" />
                        <Tab label="Group notes" value="5" />
                        <Tab
                          label="Family / significant other notes"
                          value="6"
                        />
                        <Tab label="End of notes" value="7" />
                      </TabList>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <TabPanel value="1">
                <TopTitleNoteContainerMain
                  title="Add quick note"
                  handleOpen={handleOpenQuickNote}
                />
                <QuickNotesContainerAll clientId={props.clientId} {...props} />
              </TabPanel>
              <TabPanel value="2">
                <TopTitleNoteContainerMain
                  showButton={true}
                  title="Add handover note"
                  handleOpen={handleOpenHandoverNote}
                />
                <HandoverNotesAll />
              </TabPanel>

              <TabPanel value="3">
                <TopTitleNoteContainerMain
                  title="Add 1 to 1 support staff note"
                  handleOpen={handleOpenNonTherapyNote}
                />
                <NonTherapyNoteContainerAll />
              </TabPanel>

              <TabPanel value="4">
                <TopTitleNoteContainerMain
                  title="Add medical note"
                  handleOpen={handleOpenMedicalNote}
                />
                <MedicalNotesAll clientId={props.clientId} {...props} />
              </TabPanel>
              <TabPanel value="5">
                <TopTitleNoteContainerMain
                  showButton={true}
                  title="Add group note"
                  handleOpen={handleOpenGroupNote}
                />
                <GroupNotesAll />
              </TabPanel>
              <TabPanel value="6">
                <TopTitleNoteContainerMain
                  title="Add family/significant other note"
                  handleOpen={handleOpenFamilyNote}
                />
                <FamilyNotesContainerAll clientId={props.clientId} {...props} />
              </TabPanel>
              <TabPanel value="7">
                {/* Contenu pour End of Shift Notes */}
                <TopTitleNoteContainerMain
                  showButton={true}
                  title="Add End of Shift Note"
                  handleOpen={handleOpenEndOfShiftNote}
                />
                <EndOfShiftNotesContainer
                  clientId={props.clientId}
                  {...props}
                />
                {/* Composant pour afficher les End of Shift Notes */}
              </TabPanel>
            </TabContext>
          </Box>
        </Container>
      </div>
    </div>
  );
}

export default ClientNotesMainMedicalStaff;
