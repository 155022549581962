import React from "react";
import { Box, Modal } from "@mui/material";
import { useState } from "react";
import EditRole from "./forms/EditRole";
import DeleteUserModal from "./Notes/DeleteUserModal";
import EditButtonMobile from "./Buttons/EditButtonMobile";
import DeleteButtonUserDataMobile from "./Buttons/DeleteButtonUserDataMobile";
import EditRoleMobile from "./forms/EditRoleMobile";

function FormLineMedium5mobile(props) {
  const styleDeleteModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    height: 260,
    borderRadius: 10,
    bgcolor: "background.paper",
    /*border: "1px solid #000",*/
    boxShadow: 24,
    // p: 4,
  };

  const styleModalGeneral = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: "40%",
    borderRadius: 5,
    backgroundColor: "white",
    boxShadow: 24,
  };

  const [openEditRole, setOpenEditRole] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleOpenEditRole = () => setOpenEditRole(true);
  const handleCloseEditRole = () => setOpenEditRole(false);

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  return (
    <div
      className={
        props.color === "1"
          ? "formLineMedium5Mobile light-grey-form"
          : "formLineMedium5Mobile grey-form"
      }
    >
      <Modal
        open={openEditRole}
        onClose={handleCloseEditRole}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalGeneral}>
          <EditRoleMobile
            closemodal={handleCloseEditRole}
            userName={props.userName}
            roleUser={props.userRole}
            userId={props.userId}
          />
        </Box>
      </Modal>
      <Modal
        open={showDeleteModal}
        onClose={closeDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleDeleteModal}>
          <DeleteUserModal onClose={closeDeleteModal} uid={props.userId} />
        </Box>
      </Modal>
      <div className="parent">
        <div className="role-line-block1-mobile ellipsis-mobile">
          {props.userEmail}
        </div>
      </div>
      {/* <div className="role-line-block2">{props.userName}</div> */}
      <div className="parent">
        <div className="role-line-block1-mobile">{props.userRole}</div>
        {/* <div className="role-line-block3">{props.userPhone}</div> */}
      </div>

      <div className="parent2">
        <div className="role-line-block3-mobile">
          <EditButtonMobile title="Edit" handleOpen={handleOpenEditRole} />
          <DeleteButtonUserDataMobile
            handleOpen={openDeleteModal}
            closeModal={closeDeleteModal}
          />
        </div>
      </div>
    </div>
  );
}

export default FormLineMedium5mobile;
