import React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { Timestamp } from "firebase/firestore";
import dayjs from "dayjs";
import "../css/customDatePickerStyles.css";

export default function DatePickerComponentV4(props) {
  const handleChange = (date) => {
    const timestamp = Timestamp.fromMillis(date.valueOf());
    props.onChange(timestamp);
  };

  const dateObject =
    props.value && typeof props.value.toMillis === "function"
      ? dayjs(new Date(props.value.toMillis()))
      : props.value;

  return (
    <div
      // className="input-label-container-v2"
      style={{ width: props.width, height: props.height, marginTop: "0px" }}
    >
      <div
        // className="input-label"
        style={{
          color: "#a0a0a0",
          fontWeight: "500",
          fontSize: "12px",
          textTransform: "uppercase",
          marginBottom: "5px",
        }}
      >
        {props.title}{" "}
        {props.isRequired && <span style={{ color: "red" }}>*</span>}
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          textField={
            <TextField
              variant="outlined"
              error={false}
              InputProps={{
                style: {
                  padding: "10px",
                  fontSize: "18px",
                  height: props.height || 30,
                  backgroundColor: "#f3f3f3",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "grey", // ou la couleur que vous préférez
                },
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#f3f3f3", // Optionnel : Changer la couleur du background ici aussi
                },
                // width: props.width || "auto",
              }}
            />
          }
          sx={{}}
          value={dateObject}
          onChange={handleChange}
          format="DD/MM/YYYY"
        />
      </LocalizationProvider>
    </div>
  );
}
