import React, { useContext, useState } from "react";
import { documentId, onSnapshot, query, where } from "firebase/firestore";
import {
  deleteObject,
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  getMetadata,
} from "firebase/storage";
import DeleteButtonFile from "../Buttons/DeleteButtonFile";
import { Button, TextField } from "@mui/material";
import { showCustomToast } from "../Misc/CustomToast";
import { useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { roles } from "../../firebase.collection";
import PdfIcon from "../../images/pdf-icon-small.png";
import DocIcon from "../../images/word-icon-small.png";
import ExcelIcon from "../../images/excel-icon-small.png";
import ImageIcon from "@mui/icons-material/Image";
import ViewButtonSmall from "../Buttons/ViewButtonSmall";
import Joyride, { ACTIONS, STATUS } from "react-joyride";
import { LanguageContext } from "../../context/LanguageContext";

const maxSizePicFile = 50 * 1024 * 1024; // 5 Mo

function UpdloaderDocumentMobile({ inputId, ...props }) {
  const { translate } = useContext(LanguageContext);
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileLinks, setFileLinks] = useState(props.fileUrlsGeneralDocs || []);
  const [userRole, setUserRole] = useState(null); // Ici, vous pouvez définir le rôle de l'utilisateur
  const [fileName, setFileName] = useState(""); // Ajouter un état pour le nom du fichier modifié
  const [userIdState, setUserIdState] = useState(
    currentUser ? currentUser.uid : ""
  );
  const [fileNames, setFileNames] = useState([]);
  const [run, setRun] = useState(false);
  const [showHelpButton, setShowHelpButton] = useState(true);

  const handleHelpClick = () => {
    setRun(true);
    setShowHelpButton(false); // Cache le bouton "HELP" après le clic
  };

  const steps = [
    {
      target: "#file-links",
      content:
        "This area displays the list of uploaded files. You can view or delete the files if you have the necessary permissions.",
    },
    {
      target: "#upload-file-section",
      content:
        "Use this section to upload a new document file. You can select a file and provide a custom file name before uploading.",
    },
    {
      target: "#upload-button",
      content:
        "Click the 'UPLOAD' button to upload the selected file. The button will show a progress indicator during the upload process.",
    },
  ];

  const customStyles = {
    options: {
      zIndex: 10000,
    },
    tooltip: {
      backgroundColor: "#fff",
      color: "#000",
      animation: "fadeIn 0.3s ease-in-out",
    },
    tooltipContent: {
      textAlign: "justify",
      marginTop: "10px",
    },
    beacon: {
      offsetY: -50,
    },
    buttonNext: {
      backgroundColor: "#1565c0",
      color: "#fff",
    },
    buttonBack: {
      color: "blue",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 1000,
    },
    spotlight: {
      position: "absolute",
      borderRadius: 0,
    },
    beaconInner: {
      backgroundColor: "#1565c0", // Couleur du cercle intérieur
      borderColor: "#1565c0",
    },
    beaconOuter: {
      backgroundColor: "rgba(21, 101, 192, 0.5)", // Couleur de l'animation concentrique
      borderColor: "rgba(21, 101, 192, 0.5)",
    },
  };

  const handleJoyrideCallback = (data) => {
    const { status, action } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      setShowHelpButton(true);
    } else if ([ACTIONS.CLOSE].includes(action)) {
      setRun(false);
      setShowHelpButton(true);
    }
  };

  useEffect(() => {
    if (currentUser) {
      queryRole();
    } else {
      console.log("No user id");
    }
  }, [currentUser]);

  const getFileIcon = (mimeType) => {
    switch (mimeType) {
      case "application/pdf":
        return <img src={PdfIcon} />;
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return <img src={DocIcon} />;
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return <img src={ExcelIcon} />;
      case "image/jpeg":
      case "image/png":
      case "image/gif":
        return <ImageIcon />;
      default:
        return null; // Retourne null ou une icône par défaut pour les autres types de fichiers
    }
  };

  const getFileExtension = (mimeType) => {
    switch (mimeType) {
      case "application/pdf":
        return "pdf";
      case "application/msword":
        return "doc";
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "docx";
      case "application/vnd.ms-excel":
        return "xls";
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "xlsx";
      case "image/jpeg":
        return "jpeg";
      case "image/png":
        return "png";
      case "image/gif":
        return "gif";
      default:
        return ""; // Retourne une chaîne vide pour les autres types de fichiers
    }
  };

  const fetchFiles = async () => {
    const storage = getStorage();
    const storageRef = ref(
      storage,
      `Documents/SharedDocuments/${props.repository}`
    ); // Le chemin de votre répertoire

    try {
      const res = await listAll(storageRef);
      const fileUrls = await Promise.all(
        res.items.map(async (itemRef) => {
          const url = await getDownloadURL(itemRef);
          const metadata = await getMetadata(itemRef);
          return { url, mimeType: metadata.contentType, name: itemRef.name };
        })
      );
      setFileLinks(fileUrls);
    } catch (error) {
      console.error("Error fetching files: ", error);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  function queryRole() {
    onSnapshot(
      query(roles, where(documentId(), "==", userIdState)),
      (querySnapshot) => {
        const doc = querySnapshot.docs[0];
        if (doc && doc.exists) {
          const role = doc.data().role;
          const phone = doc.data().phoneNumber;
          setUserRole(role);
        } else {
          console.log("Problem loading roles");
        }
      }
    );
  }

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length === 1) {
      setFiles(event.target.files[0]); // Set files to the first file in the list
    } else {
      showCustomToast(translate("OneFileLimit"));
    }
  };

  const deleteFile = async (fileUrl, fileName) => {
    if (userRole !== "Super Admin") {
      showCustomToast(translate("NoDeletePermissions"), "error");
      return;
    }

    const storage = getStorage();
    const filePath = `Documents/SharedDocuments/${props.repository}/${fileName}`; // Modifié pour SharedDocuments
    const storageRef = ref(storage, filePath);

    try {
      await deleteObject(storageRef);
      const newFileLinks = fileLinks.filter((file) => file.url !== fileUrl);
      setFileLinks(newFileLinks);

      showCustomToast(translate("FileDeleted"), "success");
    } catch (error) {
      showCustomToast(translate("ErrorDeletingFile"), "error");
    }
  };

  const handleFileNameChange = (event, index) => {
    const newFileNames = [...fileNames];
    newFileNames[index] = event.target.value;
    setFileNames(newFileNames);
  };

  const uploadFilesAndGetUrls = async () => {
    const storage = getStorage();
    const fileUrls = [];

    const file = files;
    if (file.size > maxSizePicFile) {
      showCustomToast(`$ ${file.name}`, "error");
      console.error(`${translate("FileSizeExceedsLimit")} ${file.name}`);
      return fileUrls;
    }
    const filePath = `Documents/SharedDocuments/${props.repository}/${
      fileName || file.name
    }`;
    const storageRef = ref(storage, filePath);

    await uploadBytes(storageRef, file);
    const fileUrl = await getDownloadURL(storageRef);
    fileUrls.push({
      url: fileUrl,
      name: fileName || file.name,
      mimeType: file.type,
    }); // Add the mimeType

    return fileUrls;
  };

  const updateClientData = async () => {
    if (userRole !== "Super Admin") {
      showCustomToast(translate("NoDeletePermissions"), "error");
      return;
    }

    setIsLoading(true);
    if (files) {
      // Check if files is truthy
      try {
        const fileUrls = await uploadFilesAndGetUrls();
        const existingFileUrls = fileLinks;
        const mergedFileUrls = [...existingFileUrls, ...fileUrls];

        setFileLinks(mergedFileUrls);

        showCustomToast(translate("FilesUploaded"), "success");
      } catch (error) {
        console.error("Error updating client: ", error);

        showCustomToast(
          `${translate("ErrorUpdatingClient")} ${error}`,
          "error"
        );
      }
    } else {
      showCustomToast(translate("NoFilesSelected"), "error");
    }
    setIsLoading(false);
  };

  return (
    <div className="uploader-roi">
      <Joyride
        steps={steps}
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
        run={run}
        callback={handleJoyrideCallback}
        disableScrolling={true}
        disableBeacon={true}
        styles={customStyles}
      />

      <div className="uploader-component-general-infos-mobile">
        <div id="file-links" className="form4-mobile">
          <label
            htmlFor="uploadFiles"
            style={{
              display: "flex",
              alignContent: "flex-start",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              marginBottom: "8px",
              // marginTop: "20px",
              width: "100%",
            }}
          >
            {/* Available documents : */}
          </label>
          {fileLinks && fileLinks.length > 0 ? (
            fileLinks
              .sort((a, b) => a.name.localeCompare(b.name)) // trier les fichiers par nom de manière alphabétique
              .map((fileLink, index) => (
                <div key={index} className="file-item2">
                  {getFileIcon(fileLink.mimeType)}

                  <a
                    className="ellipsis2"
                    style={{
                      marginLeft: "0px",
                      marginRight: "0px",
                      width: "80%",
                      textTransform: "uppercase", // Transformer le texte en majuscules
                      fontWeight: "bold", // Rendre le texte en gras
                      color: "black",
                      paddingLeft: "10px",
                    }}
                    href={fileLink.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {fileLink.name}
                  </a>
                  <>
                    <a href={fileLink.url} target="_blank" rel="noreferrer">
                      <ViewButtonSmall
                        style={{ height: "15px" }} // Modifier en fonction de la hauteur de l'icône de suppression
                      />
                    </a>

                    {(userRole === "Super Admin" ||
                      userRole === "Clinical Director") && (
                      <DeleteButtonFile
                        onClick={() =>
                          deleteFile(
                            fileLink.url,
                            fileLink.name,
                            props.clientId
                          )
                        }
                        fileName={fileLink.name}
                      />
                    )}
                  </>
                </div>
              ))
          ) : (
            <div>{translate("NoFilesFound")}</div>
          )}
        </div>
        {(userRole === "Super Admin" || userRole === "Clinical Director") && (
          <div id="upload-file-section" className="form3-mobile">
            <div className="line5-modal">
              <div className="form-container">
                <div className="input-file-container">
                  <label
                    htmlFor={inputId}
                    style={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "8px",
                      marginTop: "20px",
                    }}
                  >
                    <span style={{ textAlign: "center" }}>
                      {" "}
                      <span style={{ fontSize: "20px" }}>
                        {translate("UploadDocument")}{" "}
                      </span>
                      <br />
                      <span style={{ fontSize: "12px" }}>
                        {" "}
                        {translate("FileRequirements")}
                      </span>
                    </span>
                  </label>
                  <input
                    type="file"
                    id={inputId}
                    multiple
                    onChange={handleFileChange}
                    accept=".pdf,.png,.jpg"
                    style={{
                      width: "0.1px",
                      height: "0.1px",
                      opacity: 0,
                      overflow: "hidden",
                      position: "absolute",
                      zIndex: -1,
                    }}
                  />
                  <div className="input-name-file">
                    <TextField
                      value={fileName}
                      onChange={(e) => setFileName(e.target.value)}
                      label={translate("FileName")}
                      style={{ marginBottom: "10px" }}
                    />
                  </div>
                </div>
                <div className="btn-upload-container">
                  <label htmlFor={inputId} className="custom-file-upload">
                    {translate("ChooseFile")}
                  </label>
                </div>
              </div>
              <div className="line5-modal">
                <div className="form-container">
                  {files && files.name ? (
                    <div className="select-title">
                      {translate("SelectedFile")}
                      <ul className="ul-container">
                        <div key={files.name}>
                          <a className="a-container">
                            <span
                              className="ellipsis"
                              style={{
                                maxWidth: "calc(30ch)",
                                color: "black",
                                fontWeight: "500",
                              }}
                            >
                              {files.name}
                            </span>
                          </a>
                        </div>
                      </ul>
                    </div>
                  ) : (
                    <div className="select-title">
                      {translate("NoFileSelected")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="bottom-button-uploader">
        {showHelpButton && (
          <div style={{ paddingRight: "40px" }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleHelpClick}
              sx={{ fontSize: "10px" }}
            >
              HELP
            </Button>
          </div>
        )}

        {(userRole === "Super Admin" || userRole === "Clinical Director") && (
          <Button
            variant="contained"
            style={{
              backgroundColor: "white",
              color: "black",
              // marginBottom: "10px",
            }}
            onClick={props.closemodal}
          >
            {translate("Cancel")}
          </Button>
        )}
        {(userRole === "Super Admin" || userRole === "Clinical Director") && (
          <div id="upload-button">
            <Button
              variant="contained"
              onClick={() => updateClientData()}
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                      // marginBottom: "10px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                      // marginBottom: "10px",
                    }
              }
              disabled={isLoading}
            >
              {isLoading ? translate("UPLOADING") : translate("UPLOAD")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default UpdloaderDocumentMobile;
