import React from "react";
import GradingIcon from "@mui/icons-material/Grading";

function ReviewButton(props) {
  return (
    <div
      className="square-button-2 review-button-color"
      title="Revoke"
      onClick={props.handleOpen}
    >
      <GradingIcon style={{ color: "white" }} />
    </div>
  );
}

export default ReviewButton;
