export default [
  {
    id: 1,
    textDropDownEn: "-- Choose --",
  },

  {
    id: 2,
    textDropDownEn: "Clinical Assessment",
  },

  {
    id: 3,
    textDropDownEn: "Potential",
  },

  // {
  //   id: 3,
  //   textDropDownEn: "Hot Lead",
  // },

  {
    id: 4,
    textDropDownEn: "Incoming",
  },

  {
    id: 5,
    textDropDownEn: "In House",
  },

  {
    id: 6,
    textDropDownEn: "Aftercare",
  },
  // {
  //   id: 7,
  //   textDropDownEn: "Miracles@home",
  // },

  // {
  //   id: 8,
  //   textDropDownEn: "Continued Therapy",
  // },

  {
    id: 9,
    textDropDownEn: "Denied Admission",
  },

  {
    id: 10,
    textDropDownEn: "Inactive",
  },
];
