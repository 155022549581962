import React from "react";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

function DeleteButtonUserDataMobile(props) {
  return (
    <div
      className="square-button-mobile delete-button-color"
      title="Delete"
      onClick={props.handleOpen}
    >
      <DeleteForeverIcon style={{ font: 18, color: "white" }} />
    </div>
  );
}

export default DeleteButtonUserDataMobile;
