import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Modal,
  Slide,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { showCustomToast } from "./Misc/CustomToast";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { useAuth } from "../contexts/AuthContext";
import { useEffect } from "react";
import UploaderPreAdmission from "./Updloader/UploaderPreAdmission";
import DropDownClientIdentifier from "./Dropdowns/DropDownClientIdentifier";
import LineLogsDetails from "./Lines/LineLogsDetails";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { LanguageContext } from "../context/LanguageContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListIcon from "@mui/icons-material/List";
import InputComponentV2 from "./InputComponentV2";
import DropDownComponentV2 from "./DropDownComponentV2";
import DropDownComponentSortedV2 from "./DropDownComponentSortedV2";
import TextFieldComponentV2 from "./TextFieldComponentV2";
import DatePickerComponentV3 from "./DatePickerComponentV3";
import DatePickerComponentV2 from "./DatePickerComponentV2";
import DatePickerComponentV4 from "./DatePickerComponentV4";

function PreAdmissionMobileV2(props) {
  const [loading, setLoading] = useState(true);
  const { translate } = useContext(LanguageContext);
  const [showHelpButton, setShowHelpButton] = useState(true);
  const [run, setRun] = useState(false);
  const handleHelpClick = () => {
    setRun(true);
    setShowHelpButton(false); // Cache le bouton "HELP" après le clic
  };

  function formatDateLogs2(timestamp) {
    // Assurez-vous que timestamp est un objet Firestore Timestamp
    // et convertissez-le en objet Date JavaScript
    const date = timestamp.toDate();

    // Retourne la date formatée comme une chaîne de caractères
    // Modifiez les options selon vos besoins pour le format de la date et de l'heure
    return date.toLocaleString("en-EN", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }

  const styleModalUploadPdf = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    height: "auto",
    borderRadius: 5,
    // overflowY: "scroll",
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const styleModalLogs2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    height: "50%",
    borderRadius: 2,
    //overflowY: "auto",
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const { currentUser } = useAuth();
  const [isModified, setIsModified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [docData, setDocData] = useState({});
  const [docDataClient, setDocDataClient] = useState({});
  const [openUploadPreAdmission, setOpenUploadPreAdmission] = useState(false);
  const handleClosePreAdmForm = () => setOpenUploadPreAdmission(false);
  const [durationOfStay, setDurationOfStay] = useState(
    props.durationOfStay || ""
  );
  const [logs, setLogs] = useState([]);
  const [isModalLogsOpen, setIsModalLogsOpen] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const closeDetailLogsModal = () => setIsDetailsModalOpen(false);
  const closeLogsModal = () => setIsModalLogsOpen(false);
  const [previousClientIdentifier, setPreviousClientIdentifier] = useState("");
  const [substanceAddiction, setSubstanceAddiction] = useState("");

  const showLogDetails = (selectedLog) => {
    const changes = selectedLog.changes;
    const who = selectedLog.recordedBy;
    const when = formatDateLogs(selectedLog.recordedAt);

    return (
      <div
        style={{
          width: "100%",
        }}
      >
        <div
          className="modal-header"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ paddingLeft: "15px" }}>
            Log details of {who} - {when}
          </div>
          <div
            style={{
              paddingRight: "20px",
              cursor: "pointer",
              paddingTop: "5px",
            }}
            onClick={closeDetailLogsModal}
          >
            <HighlightOffIcon />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: "90%",
            maxHeight: "430px",
            overflowY: "auto",
          }}
        >
          {Object.entries(changes)
            .filter(([key, _]) => key !== "recordedAt")
            .map(([key, value]) => (
              <LineLogsDetails key={key} fieldName={key} value={value} />
            ))}
        </div>
      </div>
    );
  };

  function formatDate2(value) {
    if (value && value.toDate) {
      return value.toDate().toLocaleString("en-EN", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    }
    return value;
  }

  const handleShowDetailsClick = (selectedLog) => {
    const sortedLogs = logs.sort(
      (a, b) => b.recordedAt.toDate() - a.recordedAt.toDate()
    );

    const selectedIndex = sortedLogs.findIndex(
      (log) => log.id === selectedLog.id
    );

    if (selectedIndex === -1) {
      setSelectedLog({ ...selectedLog, changes: selectedLog.changes });
    } else {
      const previousLog = sortedLogs[selectedIndex + 1];

      let differences = {};
      Object.keys(selectedLog.changes).forEach((key) => {
        let currentValue = selectedLog.changes[key];
        const previousValue = previousLog
          ? previousLog.changes[key]
          : undefined;

        currentValue = formatDate2(currentValue);
        if (
          (!previousLog ||
            JSON.stringify(currentValue) !== JSON.stringify(previousValue)) &&
          currentValue !== undefined &&
          currentValue !== null &&
          currentValue !== ""
        ) {
          differences[key] = currentValue;
        }
      });

      setSelectedLog({ ...selectedLog, changes: differences });
    }

    setIsDetailsModalOpen(true);
  };

  const fetchLogs = async (clientId) => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(
        collection(db, "logs", clientId, "modifications")
      );
      const fetchedLogs = [];
      querySnapshot.forEach((doc) => {
        fetchedLogs.push({ id: doc.id, ...doc.data() });
      });
      setLogs(fetchedLogs);
    } catch (error) {
      console.error("Error fetching logs: ", error);
    } finally {
      setLoading(false);
    }
  };

  const openModalLogs = (clientId) => {
    fetchLogs(clientId);
    setIsModalLogsOpen(true);
  };

  const formatDateLogs = (timestamp) => {
    const date = timestamp.toDate();
    return date.toLocaleString();
  };

  const handleDurationOfStayChange = (value) => {
    setIsModified(true);
    setDurationOfStay(value);
  };

  useEffect(() => {
    const docRef = doc(db, "preAdmissionAssessments", props.clientId);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        setDocData(doc.data());
      } else {
        console.log("No such document!");
      }
    });

    return () => unsubscribe();
  }, [props.clientId]);

  useEffect(() => {
    const docRef = doc(db, "clients", props.clientId);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        setDocDataClient(doc.data());
      } else {
        console.log("No such document!");
      }
    });

    return () => unsubscribe();
  }, [props.clientId]);

  useEffect(() => {
    setNationality(docData.nationality || docDataClient.nationality || "");
    setMaritalStatus(
      docData.maritalStatus || docDataClient.maritalStatus || ""
    );
    setPrimaryAddiction(
      docData.primaryAddiction || docDataClient.primaryAddiction || ""
    );
    setAddictionOther(docData.addictionOther || "");
    setPassportStatus(docData.passportStatus || "");
    setCurrentPartner(docData.currentPartner || "");
    setBodyHeight(docData.bodyHeight || "");
    setCurrentLivingArrangements(docData.currentLivingArrangements || "");
    setDietaryRequirements(docData.dietaryRequirements || "");
    setFundingSource(docData.fundingSource || "");
    setCurrentWithdrawalSymptoms(docData.currentWithdrawalSymptoms || "");
    setProcessAddictions(docData.processAddictions || "");
    setAddictionTreatmentHistory(docData.addictionTreatmentHistory || "");
    setTreatmentGoals(docData.treatmentGoals || "");
    setBarriersForRehab(docData.barriersForRehab || "");
    setMotivationForTreatment(docData.motivationForTreatment || "");
    setCurrentMentalHealthIssues(docData.currentMentalHealthIssues || "");
    setLastSelfHarm(docData.lastSelfHarm || "");
    setCurrentProvider(docData.currentProvider || "");
    setCurrentMedications(docData.currentMedications || "");
    setCurrentPastInjury(docData.currentPastInjury || "");
    setCurrentMedicalIllness(docData.currentMedicalIllness || "");
    setAllergies(docData.allergies || "");
    setLegalProblems(docData.legalProblems || "");
    setUnwantedConsequencesOfAddiction(
      docData.unwantedConsequencesOfAddiction || ""
    );
    setPreviousClientIdentifier(docData.previousClientIdentifier || "");
    setReasonForSeekingTreatment(docData.reasonForSeekingTreatment || "");
    setChildrenOrDependents(docData.childrenOrDependents || "");
    setSubstanceAddiction(docData.substanceAddiction || "");

    // Pour les Checkbox

    setPassportCopy(docData.passportCopy || "");
    setFlightDetails(docData.flightDetails || "");
    setVipTransfer(docData.vipTransfer || "");
    setPaymentDetails(docData.paymentDetails || "");
    setHowLongUsingDrink(docData.howLongUsingDrink || "");
    setMentalHealthOtherCheck(docData.mentalHealthOtherCheck || false);
    setMentalHealthOtherContent(docData.mentalHealthOtherContent || "");
    setFlightDetailsContent(docData.flightDetailsContent || "");
    setSecondaryAddiction(docData.secondaryAddiction || "");
    setSecondaryAddictionOther(docData.secondaryAddictionOther || "");
    setEta(docDataClient.eta || "");
    setHistoryPhysicalViolent(docData.historyPhysicalViolent || "");
    setAssessmentStatus(docData.assessmentStatus || "");
    setAssessmentReason(docData.assessmentReason || "");

    setSpecificDisorder(docData.specificDisorder || "");

    setDurationOfStay(docDataClient.durationOfStay || "");
  }, [docData, docDataClient]);

  // Pour les DropDownComponent

  const [specificDisorder, setSpecificDisorder] = useState("");
  const [flightDetailsContent, setFlightDetailsContent] = useState(
    docData.flightDetailsContent || ""
  );
  const [mentalHealthOtherCheck, setMentalHealthOtherCheck] = useState(
    docData.mentalHealthOtherCheck || ""
  );
  const [mentalHealthOtherContent, setMentalHealthOtherContent] = useState(
    docData.mentalHealthOtherContent || ""
  );
  const [howLongUsingDrink, setHowLongUsingDrink] = useState(
    docData.howLongUsingDrink || ""
  );
  const [nationality, setNationality] = useState(
    docData.nationality || docDataClient.nationality || ""
  );
  const [adhd, setAdhd] = useState(docData.adhd || "");
  const [noneApply, setNoneApply] = useState(docData.noneApply || "");
  const [addictionOther, setAddictionOther] = useState(
    docData.addictionOther || ""
  );
  const [secondaryAddictionOther, setSecondaryAddictionOther] = useState(
    docData.secondartAddictionOther || ""
  );
  const [maritalStatus, setMaritalStatus] = useState(
    docData.maritalStatus || ""
  );
  const [passportCopy, setPassportCopy] = useState(docData.passportCopy || "");
  const [primaryAddiction, setPrimaryAddiction] = useState(
    docData.primaryAddiction || ""
  );
  const [secondaryAddiction, setSecondaryAddiction] = useState(
    docData.secondaryAddiction || ""
  );

  // Pour les InputComponent
  const [passportStatus, setPassportStatus] = useState(
    docData.passportStatus || ""
  );
  const [currentPartner, setCurrentPartner] = useState(
    docData.currentPartner || ""
  );
  const [suicidalThoughtsWhen, setSuicidalThoughtsWhen] = useState(
    docData.suicidalThoughtsWhen || ""
  );
  const [suicidalThoughtsIntent, setSuicidalThoughtsIntent] = useState(
    docData.suicidalThoughtsIntent || ""
  );

  const [suicideAttemptsWhen, setSuicideAttemptsWhen] = useState(
    docData.suicideAttemptsWhen || ""
  );
  const [bodyHeight, setBodyHeight] = useState(docData.bodyHeight || "");

  // Pour les TextFieldComponentLarge
  const [currentLivingArrangements, setCurrentLivingArrangements] = useState(
    docData.currentLivingArrangements || ""
  );
  const [dietaryRequirements, setDietaryRequirements] = useState(
    docData.dietaryRequirements || ""
  );
  const [fundingSource, setFundingSource] = useState(
    docData.fundingSource || ""
  );
  const [currentWithdrawalSymptoms, setCurrentWithdrawalSymptoms] = useState(
    docData.currentWithdrawalSymptoms || ""
  );
  const [processAddictions, setProcessAddictions] = useState(
    docData.processAddictions || ""
  );
  const [addictionTreatmentHistory, setAddictionTreatmentHistory] = useState(
    docData.addictionTreatmentHistory || ""
  );
  const [treatmentGoals, setTreatmentGoals] = useState(
    docData.treatmentGoals || ""
  );
  const [barriersForRehab, setBarriersForRehab] = useState(
    docData.barriersForRehab || ""
  );
  const [motivationForTreatment, setMotivationForTreatment] = useState(
    docData.motivationForTreatment || ""
  );
  const [currentMentalHealthIssues, setCurrentMentalHealthIssues] = useState(
    docData.currentMentalHealthIssues || ""
  );
  const [lastSelfHarm, setLastSelfHarm] = useState(docData.lastSelfHarm || "");

  const [currentProvider, setCurrentProvider] = useState(
    docData.currentProvider || ""
  );
  const [historyOfSeizures, setHistoryOfSeizures] = useState(
    docData.historyOfSeizures || ""
  );
  const [currentMedications, setCurrentMedications] = useState(
    docData.currentMedications || ""
  );
  const [currentPastInjury, setCurrentPastInjury] = useState(
    docData.currentPastInjury || ""
  );
  const [currentMedicalIllness, setCurrentMedicalIllness] = useState(
    docData.currentMedicalIllness || ""
  );
  const [allergies, setAllergies] = useState(docData.allergies || "");
  const [legalProblems, setLegalProblems] = useState(
    docData.legalProblems || ""
  );

  const [historyPhysicalViolent, setHistoryPhysicalViolent] = useState(
    docData.historyPhysicalViolent || ""
  );
  const [mentalHealthTreatment, setMentalHealthTreatment] = useState(
    docData.mentalHealthTreatment || ""
  );

  const [unwantedConsequencesOfAddiction, setUnwantedConsequencesOfAddiction] =
    useState(docData.unwantedConsequencesOfAddiction || "");
  const [reasonForSeekingTreatment, setReasonForSeekingTreatment] = useState(
    docData.reasonForSeekingTreatment || ""
  );

  const [childrenOrDependents, setChildrenOrDependents] = useState(
    docData.childrenOrDependents || ""
  );

  const [flightDetails, setFlightDetails] = useState(
    docData.flightDetails || ""
  );
  const [vipTransfer, setVipTransfer] = useState(docData.vipTransfer || "");
  const [paymentDetails, setPaymentDetails] = useState(
    docData.paymentDetails || ""
  );

  const [assessmentStatus, setAssessmentStatus] = useState(
    docData.assessmentStatus || ""
  );

  const [assessmentReason, setAssessmentReason] = useState(
    docData.assessmentReason || ""
  );

  // Handle functions

  const handleFlightDetailsContent = (event) => {
    setIsModified(true);
    setFlightDetailsContent(event);
  };

  const handleHowLongUsingDring = (event) => {
    setIsModified(true);
    setHowLongUsingDrink(event);
  };

  const handlePreviousClientIdentifier = (value) => {
    setIsModified(true);
    setPreviousClientIdentifier(value);
  };

  const handleFlightDetailsChange = (value) => {
    setIsModified(true);
    setFlightDetails(value);
  };

  const handleVipTransferChange = (value) => {
    setIsModified(true);
    setVipTransfer(value);
  };
  const handlePaymentDetailsChange = (value) => {
    setIsModified(true);
    setPaymentDetails(value);
  };

  // Pour les DropDownComponent

  const handleNationalityChange = (event) => {
    setIsModified(true);
    setNationality(event);
  };

  const handleMaritalStatusChange = (event) => {
    setIsModified(true);
    setMaritalStatus(event);
  };

  const handlePassportCopy = (event) => {
    setIsModified(true);
    setPassportCopy(event);
  };

  const handlePrimaryAddictionChange = (event) => {
    setIsModified(true);
    setPrimaryAddiction(event);
  };

  const handleSecondaryAddictionChange = (event) => {
    setIsModified(true);
    setSecondaryAddiction(event);
  };

  // Pour les InputComponent
  const handleAddictionOther = (event) => {
    setIsModified(true);
    setAddictionOther(event);
  };

  const handleSecondaryAddictionOther = (event) => {
    setIsModified(true);
    setSecondaryAddictionOther(event);
  };

  const handlePassportStatusChange = (event) => {
    setIsModified(true);
    setPassportStatus(event);
  };

  const handlEtaChange = (event) => {
    setIsModified(true);
    setEta(event);
  };

  // const handlEtaChange = (event) => {
  //   setIsModified(true);
  //   if (event instanceof Date) {
  //     setEta(event);
  //   } else if (event.toDate) {
  //     // Si la valeur est un Firestore Timestamp, on la convertit
  //     setEta(event.toDate());
  //   }
  // };

  const handleCurrentPartnerChange = (event) => {
    setIsModified(true);
    setCurrentPartner(event);
  };

  // Pour les TextFieldComponentLarge

  const handleCurrentLivingArrangementsChange = (event) => {
    setIsModified(true);
    setCurrentLivingArrangements(event);
  };
  const handleDietaryRequirementsChange = (event) => {
    setIsModified(true);
    setDietaryRequirements(event);
  };
  const handleFundingSourceChange = (event) => {
    setIsModified(true);
    setFundingSource(event);
  };
  const handleCurrentWithdrawalSymptomsChange = (event) => {
    setIsModified(true);
    setCurrentWithdrawalSymptoms(event);
  };
  const handleProcessAddictionsChange = (event) => {
    setIsModified(true);
    setProcessAddictions(event);
  };
  const handleAddictionTreatmentHistoryChange = (event) => {
    setIsModified(true);
    setAddictionTreatmentHistory(event);
  };
  const handleTreatmentGoalsChange = (event) => {
    setIsModified(true);
    setTreatmentGoals(event);
  };
  const handleBarriersForRehabChange = (event) => {
    setIsModified(true);
    setBarriersForRehab(event);
  };
  const handleMotivationForTreatmentChange = (event) => {
    setIsModified(true);
    setMotivationForTreatment(event);
  };

  const handleUnwantedConsequencesOfAddictionChange = (e) => {
    setIsModified(true);
    setUnwantedConsequencesOfAddiction(e);
  };
  const handleReasonForSeekingTreatmentChange = (e) => {
    setIsModified(true);
    setReasonForSeekingTreatment(e);
  };
  const handleSubstanceAddictionChange = (e) => {
    setIsModified(true);
    setSubstanceAddiction(e);
  };
  const handleChildrenOrDependentsChange = (e) => {
    setIsModified(true);
    setChildrenOrDependents(e);
  };

  async function saveToFirestore(clientId) {
    setIsLoading(true);

    try {
      const clientRef = doc(db, "clients", clientId);
      const clientDoc = await getDoc(clientRef);
      const previousData = clientDoc.data();

      // Données à enregistrer dans preAdmissionAssessments
      const preAdmissionData = {
        nationality,
        maritalStatus,
        primaryAddiction,
        secondaryAddiction,
        passportStatus,
        currentPartner,
        bodyHeight,
        currentLivingArrangements,
        dietaryRequirements,
        fundingSource,
        currentWithdrawalSymptoms,
        processAddictions,
        addictionTreatmentHistory,
        treatmentGoals,
        barriersForRehab,
        motivationForTreatment,
        currentMentalHealthIssues,
        lastSelfHarm,
        currentProvider,
        currentMedications,
        currentPastInjury,
        currentMedicalIllness,
        allergies,
        legalProblems,
        unwantedConsequencesOfAddiction,
        reasonForSeekingTreatment,
        childrenOrDependents,
        noneApply,
        adhd,
        passportCopy, // Inclure passportCopy ici
        flightDetails,
        vipTransfer,
        paymentDetails,
        howLongUsingDrink,
        mentalHealthOtherCheck,
        mentalHealthOtherContent,
        flightDetailsContent,
        secondaryAddictionOther,
        eta,
        historyPhysicalViolent,
        assessmentStatus,
        assessmentReason,
        durationOfStay,
        previousClientIdentifier,
      };

      // Données à enregistrer dans clients
      const clientData = {
        nationality,
        maritalStatus,
        primaryAddiction,
        secondaryAddiction,
        addictionOther,
        secondaryAddictionOther,
        eta,
        durationOfStay,
      };

      // Détermine les changements pour les collections respectives
      const preAdmissionChanges = {};
      Object.keys(preAdmissionData).forEach((key) => {
        const newValue = preAdmissionData[key];
        const oldValue = previousData[key];

        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          preAdmissionChanges[key] = newValue;
        }
      });

      const clientChanges = {};
      Object.keys(clientData).forEach((key) => {
        const newValue = clientData[key];
        const oldValue = previousData[key];

        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          clientChanges[key] = newValue;
        }
      });

      // Met à jour les collections et log les changements uniquement si nécessaire
      if (Object.keys(preAdmissionChanges).length > 0) {
        await setDoc(doc(db, "preAdmissionAssessments", clientId), {
          ...preAdmissionData,
          recordedAt: serverTimestamp(),
          recordedBy: currentUser?.displayName,
        });

        // Enregistrer les changements dans les logs si applicable
        const logEntry = {
          clientId,
          changes: preAdmissionChanges,
          recordedBy: currentUser?.displayName,
          recordedAt: serverTimestamp(),
          lastModification: serverTimestamp(),
        };
        const clientLogsRef = doc(
          collection(db, "logs", clientId, "modifications")
        );
        await setDoc(clientLogsRef, logEntry);
      }

      if (Object.keys(clientChanges).length > 0) {
        await updateDoc(clientRef, {
          ...clientData,
          lastModification: serverTimestamp(),
          lastModificationDoneBy: currentUser.displayName,
        });

        const logEntry = {
          clientId,
          changes: clientChanges, // Log uniquement les changements
          recordedBy: currentUser?.displayName,
          recordedAt: serverTimestamp(),
          lastModification: serverTimestamp(),
        };
        const clientLogsRef = doc(
          collection(db, "logs", clientId, "modifications")
        );
        await setDoc(clientLogsRef, logEntry);
      }

      showCustomToast(
        "Pre admission document successfully recorded",
        "success"
      );
    } catch (error) {
      showCustomToast("Error recording Pre admission assessment", "error");
      console.error("Error adding document: ", error);
    } finally {
      setIsModified(false);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    props.onModificationChange(isModified);
  }, [isModified]);

  const [eta, setEta] = useState(docData.eta || null);
  // const [eta, setEta] = useState(
  //   docDataClient.eta ? docDataClient.eta.toDate() : null
  // );

  // console.log("docDataClient:", docDataClient);

  const circularProgressStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "300px", // ou une autre hauteur fixe selon la hauteur de la fenêtre modale
  };

  return (
    <div
    // className="tab-container-white"
    >
      <Modal
        open={openUploadPreAdmission}
        onClose={handleClosePreAdmForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalUploadPdf}>
          <UploaderPreAdmission {...props} closemodal={handleClosePreAdmForm} />
        </Box>
      </Modal>

      <div className="container-main-plan-mobile-v3">
        <div
        // className="tab-container-title"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "10px",
              paddingLeft: "10px",
              justifyContent: "space-between",
              paddingRight: "0px",
            }}
          >
            <div style={{ fontSize: "16px", fontWeight: "600", width: "60%" }}>
              {translate("PreAdmissionAssessment")}
            </div>
            <div
              style={{
                width: "40%",
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
                alignContent: "flex-end",
                alignItems: "flex-end",
                alignSelf: "flex-end",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => openModalLogs(props.clientId)}
                sx={{ fontSize: "10px", marginRight: "10px" }}
              >
                {translate("ActivityLogs")}
              </Button>

              {/* {showHelpButton && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleHelpClick}
                  sx={{ fontSize: "10px" }}
                >
                  {translate("Help")}
                </Button>
              )} */}
            </div>
          </div>

          {isDetailsModalOpen && (
            <Modal
              open={isDetailsModalOpen}
              onClose={() => setIsDetailsModalOpen(false)}
            >
              <Box sx={styleModalLogs2}>
                {selectedLog &&
                  showLogDetails(selectedLog, closeDetailLogsModal)}
              </Box>
            </Modal>
          )}

          {isModalLogsOpen && (
            <Modal
              open={isModalLogsOpen}
              onClose={() => setIsModalLogsOpen(false)}
              aria-labelledby="activity-log-title"
              aria-describedby="activity-log-description"
              closeAfterTransition
            >
              <Slide direction="left" in={isModalLogsOpen}>
                <Box
                  sx={{
                    position: "fixed",
                    top: "5%",
                    right: 0,
                    width: "30%", // Adjust width as needed
                    height: "90%",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    marginRight: "15px",
                    pl: 0,
                    pr: 0,
                    borderRadius: "15px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 1,
                      borderBottom: "1px solid lightgray",
                    }}
                  >
                    <div
                      id="activity-log-title"
                      style={{
                        margin: 0,
                        paddingLeft: "35px",
                        paddingTop: "20px",
                        paddingBottom: "10px",
                        fontSize: "22px",
                        fontWeight: "700",
                      }}
                    >
                      {translate("ActivityLogs")}
                    </div>
                    <div style={{ paddingRight: "25px" }}>
                      <Button onClick={closeLogsModal} variant="outlined">
                        {translate("Close")}
                      </Button>
                    </div>
                  </Box>
                  <Box
                    id="activity-log-description"
                    sx={{
                      flexGrow: 1,
                      overflowY: "auto",
                      mt: 1,
                      pl: 4,
                      pr: 3.3,
                    }}
                  >
                    {loading ? (
                      <div style={circularProgressStyle}>
                        <CircularProgress />
                      </div>
                    ) : logs.length === 0 ? (
                      <div style={{ padding: "20px", textAlign: "center" }}>
                        {translate("NoRecentActivity")}
                      </div>
                    ) : (
                      logs
                        .sort(
                          (a, b) =>
                            b.recordedAt.toDate() - a.recordedAt.toDate()
                        )
                        .map((log) => (
                          <Accordion key={log.id}>
                            <AccordionSummary
                              // style={{ marginBottom: 0 }}
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`panel-${log.id}-content`}
                              id={`panel-${log.id}-header`}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                padding: "5px 10px",
                                backgroundColor: "#f2f2f2",
                                borderBottom: "1px solid #e0e0e0",
                                fontFamily:
                                  '"Courier New", Consolas, monospace',
                                fontSize: "0.9em",
                                color: "#1d1d1d",
                                textTransform: "uppercase",
                              }}
                            >
                              {/* <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <div>
                                  {log.recordedBy} -{" "}
                                  {formatDateLogs2(log.recordedAt)}
                                </div>
                              </div> */}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <ListIcon
                                  style={{
                                    marginRight: "8px",
                                    color: "#888888",
                                  }}
                                />
                                <div>
                                  {log.recordedBy} -{" "}
                                  {formatDateLogs2(log.recordedAt)}
                                </div>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails
                              style={{
                                marginTop: 0,
                                paddingTop: 0,
                                marginLeft: 0,
                                marginRight: 0,
                              }}
                            >
                              {Object.entries(log.changes)
                                .filter(
                                  ([key, _]) =>
                                    key !== translate("LastModification")
                                )
                                .map(([key, value]) => (
                                  <LineLogsDetails
                                    key={key}
                                    fieldName={key}
                                    value={value}
                                  />
                                ))}
                            </AccordionDetails>
                          </Accordion>
                        ))
                    )}
                    {/* Add the initial log entry at the bottom of the list */}
                    {props.creatorName && props.dateOfCreation && (
                      <div
                        style={{
                          fontSize: "12px",
                          fontStyle: "italic",
                          padding: "20px",
                          borderTop: "1px solid #e0e0e0",
                        }}
                      >
                        {`${translate("ClientAddedBy")}  ${
                          props.creatorName
                        } on ${props.dateOfCreation
                          .toDate()
                          .toLocaleString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          })}`}
                      </div>
                    )}
                  </Box>
                </Box>
              </Slide>
            </Modal>
          )}
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{ paddingLeft: "0px", width: "100%", paddingRight: "10px" }}
          >
            <div
              // style={{
              //   width: "100%",
              //   backgroundColor: "#f5f5f5",
              //   paddingBottom: "20px",
              //   paddingTop: "10px",
              //   paddingLeft: "10px",
              // }}
              style={{
                width: "100%",
                paddingLeft: "0px",
                paddingRight: "10px",
                paddingTop: "0px",
                paddingBottom: "30px",
                display: "flex",
                flexDirection: "column",
                // backgroundColor: "#f5f5f5",
                flexWrap: "wrap", // Permet le retour à la ligne
                gap: "20px",
              }}
            >
              <div
                // className="tab-container-title-mobile-3"
                style={{
                  borderTop: "1px solid #cccccc",
                  paddingTop: "20px",
                  marginTop: "20px",
                }}
              >
                <span
                  style={{
                    paddingLeft: "0px",
                    fontSize: "14px",
                    fontWeight: "600",
                    paddingLeft: "10px",
                  }}
                >
                  {translate("admissionInformations")}
                </span>
              </div>
              {/* <div className="tab-form-container-v2"> */}
              <div
                style={{
                  width: "100%",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "0px",
                  paddingBottom: "10px",
                  display: "flex",
                  flexDirection: "row",
                  // backgroundColor: "#f5f5f5",
                  flexWrap: "wrap", // Permet le retour à la ligne
                  gap: "20px",
                  alignContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <div style={{ marginBottom: "30px" }}>
                  <DatePickerComponentV4
                    title={translate("EstimateDateOfArrival")}
                    width="260px"
                    height="40px"
                    value={eta}
                    onChange={handlEtaChange}
                  />
                </div>

                <div
                  style={{
                    width: "55%",

                    paddingTop: "0px",
                  }}
                >
                  <DropDownComponentSortedV2
                    api="durationOfStay"
                    title={translate("IntendedDurationOfStay")}
                    width="100%"
                    value={durationOfStay}
                    onChange={handleDurationOfStayChange}
                  />
                </div>

                <div style={{ width: "35%" }}>
                  <InputComponentV2
                    title={translate("PassportStatus")}
                    width="100%"
                    value={passportStatus}
                    onChange={handlePassportStatusChange}
                  />
                </div>

                <div style={{ width: "45%" }}>
                  <DropDownComponentV2
                    api="pendingReceived"
                    title={translate("PassportCopy")}
                    value={passportCopy}
                    onChange={handlePassportCopy}
                    width="100%"
                  />
                </div>

                <div style={{ width: "45%" }}>
                  <DropDownClientIdentifier
                    title={translate("PrevClientIdentifier")}
                    value={previousClientIdentifier}
                    onChange={handlePreviousClientIdentifier}
                    width="100%"
                  />
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "0px",
                  paddingBottom: "10px",
                  display: "flex",
                  flexDirection: "row",
                  // backgroundColor: "#f5f5f5",
                  flexWrap: "wrap", // Permet le retour à la ligne
                  gap: "20px",
                }}
              >
                <div style={{ width: "45%" }}>
                  <DropDownComponentV2
                    api="pendingReceived"
                    title={translate("FlightDetails")}
                    value={flightDetails}
                    onChange={handleFlightDetailsChange}
                    width="100%"
                  />
                </div>

                <div style={{ width: "45%" }}>
                  <InputComponentV2
                    title={translate("Itinerary")}
                    width="100%"
                    value={flightDetailsContent}
                    onChange={handleFlightDetailsContent}
                  />
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "0px",
                  paddingBottom: "10px",
                  display: "flex",
                  flexDirection: "row",
                  // backgroundColor: "#f5f5f5",
                  flexWrap: "wrap", // Permet le retour à la ligne
                  gap: "20px",
                }}
              >
                <div style={{ width: "45%" }}>
                  <DropDownComponentV2
                    api="pendingBooked"
                    title={translate("VIPTransfer")}
                    value={vipTransfer}
                    onChange={handleVipTransferChange}
                    width="100%"
                  />
                </div>
                <div style={{ width: "45%" }}>
                  <DropDownComponentV2
                    api="paymentDetail"
                    title={translate("PaymentDetails")}
                    value={paymentDetails}
                    onChange={handlePaymentDetailsChange}
                    width="100%"
                  />
                </div>

                <div style={{ width: "45%" }}>
                  <DropDownComponentV2
                    api="maritalStatus"
                    title={translate("MaritalStatus")}
                    value={maritalStatus}
                    onChange={handleMaritalStatusChange}
                    width="100%"
                  />
                </div>

                <div style={{ width: "45%" }}>
                  <InputComponentV2
                    title={translate("CurrentPartnerIfNotMarried")}
                    value={currentPartner}
                    onChange={handleCurrentPartnerChange}
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingTop: "0px",
            paddingBottom: "10px",
            display: "flex",
            flexDirection: "row",
            // backgroundColor: "#f5f5f5",
            flexWrap: "wrap", // Permet le retour à la ligne
            gap: "20px",
          }}
        >
          <div style={{ width: "100%" }}>
            <TextFieldComponentV2
              title={translate("ChildrensOrDependents")}
              value={childrenOrDependents}
              onChange={handleChildrenOrDependentsChange}
              width="100%"
            />
          </div>
          <div style={{ width: "100%" }}>
            <TextFieldComponentV2
              title={translate("CurrentLivingArrangements")}
              value={currentLivingArrangements}
              onChange={handleCurrentLivingArrangementsChange}
              width="100%"
            />
          </div>
          <div style={{ width: "100%" }}>
            <TextFieldComponentV2
              title={translate("DietaryRequirements")}
              value={dietaryRequirements}
              onChange={handleDietaryRequirementsChange}
              width="100%"
            />
          </div>
          <div style={{ width: "100%" }}>
            <TextFieldComponentV2
              title={translate("FundingSource")}
              value={fundingSource}
              onChange={handleFundingSourceChange}
              width="100%"
            />
          </div>
        </div>
      </div>

      <div>
        <div
          // className="tab-container-title-mobile-3"
          style={{
            borderTop: "1px solid #cccccc",
            paddingTop: "20px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <span
            style={{
              paddingLeft: "10px",
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            {translate("Addictions")}
          </span>
        </div>
        {/* <div className="tab-container-title">Addictions(s)</div> */}

        <div
          style={{
            width: "100%",
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingTop: "0px",
            paddingBottom: "10px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "20px",
            marginBottom: "20px",
          }}
        >
          {/* <div className="column-div"> */}
          <div style={{ width: "45%" }}>
            <DropDownComponentV2
              api="addictions"
              title={translate("PrimAddSubstOrProcAbove")}
              value={primaryAddiction}
              onChange={handlePrimaryAddictionChange}
              width="100%"
            />
            {/* </div> */}

            {primaryAddiction === "Other" ? (
              <InputComponentV2
                title={translate("IfOtherSelectedDescribe")}
                width="250px"
                value={addictionOther}
                onChange={handleAddictionOther}
              />
            ) : (
              ""
            )}
          </div>

          <div style={{ width: "45%" }}>
            <DropDownComponentV2
              api="addictions"
              title={translate("SecondaryAddiction")}
              placeholder="Either substance or process above"
              value={secondaryAddiction}
              onChange={handleSecondaryAddictionChange}
              width="100%"
            />

            {secondaryAddiction === "Other" ? (
              <InputComponentV2
                title={translate("IfOtherSelectedDescribeSecondary")}
                width="250px"
                value={secondaryAddictionOther}
                onChange={handleSecondaryAddictionOther}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          style={{
            width: "100%",
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingTop: "0px",
            paddingBottom: "10px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "20px",
            marginBottom: "80px",
          }}
        >
          <div style={{ width: "100%" }}>
            <TextFieldComponentV2
              title={translate("SubstanceAddictions")}
              placeholder={translate("DoCCurrentUsageLevels")}
              value={substanceAddiction}
              onChange={handleSubstanceAddictionChange}
              width="100%"
            />
          </div>
          <div style={{ width: "100%" }}>
            <TextFieldComponentV2
              title={translate("AnyCurrentWithdrawalSymptoms")}
              value={currentWithdrawalSymptoms}
              onChange={handleCurrentWithdrawalSymptomsChange}
              width="100%"
            />
          </div>

          <div style={{ width: "100%" }}>
            <TextFieldComponentV2
              title={translate("ProcessAddictions")}
              placeholder={translate("TypeProcessAddiction")}
              value={processAddictions}
              onChange={handleProcessAddictionsChange}
              width="100%"
            />
          </div>

          <div style={{ width: "100%" }}>
            <TextFieldComponentV2
              title={translate("AddictionTreatmentHistory")}
              value={addictionTreatmentHistory}
              onChange={handleAddictionTreatmentHistoryChange}
              width="100%"
            />
          </div>

          <div style={{ width: "100%" }}>
            <TextFieldComponentV2
              title={translate("MainUnwantedConsequences")}
              value={unwantedConsequencesOfAddiction}
              onChange={handleUnwantedConsequencesOfAddictionChange}
              width="100%"
            />
          </div>

          <div style={{ width: "100%" }}>
            <TextFieldComponentV2
              title={translate("HowLongHasAddictionBeenAProblem")}
              value={howLongUsingDrink}
              onChange={handleHowLongUsingDring}
              width="100%"
            />
          </div>

          <div style={{ width: "100%" }}>
            <TextFieldComponentV2
              title={translate("WhatLedYouToSeekTreatmentNow")}
              value={reasonForSeekingTreatment}
              onChange={handleReasonForSeekingTreatmentChange}
              width="100%"
            />
          </div>

          <div style={{ width: "100%" }}>
            <TextFieldComponentV2
              title={translate("TreatmentGoals")}
              value={treatmentGoals}
              onChange={handleTreatmentGoalsChange}
              width="100%"
            />
          </div>

          <div style={{ width: "100%" }}>
            <TextFieldComponentV2
              title={translate("BarriersForRehab")}
              value={barriersForRehab}
              onChange={handleBarriersForRehabChange}
              width="100%"
            />
          </div>
          <div style={{ width: "100%" }}>
            <TextFieldComponentV2
              title={translate("MotivationForTreatment")}
              value={motivationForTreatment}
              onChange={handleMotivationForTreatmentChange}
              width="100%"
            />
          </div>
        </div>
      </div>

      <div className="button-container-form-gi" style={{ fontSize: "12px" }}>
        <Button
          variant="contained"
          onClick={() => saveToFirestore(props.clientId)}
          style={
            isModified && !isLoading
              ? {
                  backgroundColor: "red",
                  color: "white",
                  marginLeft: "15px",
                }
              : {
                  backgroundColor: "#1565C0",
                  color: "white",
                  marginLeft: "15px",
                }
          }
          disabled={isLoading}
          sx={{
            height: "40px",
            width: "auto",
            marginLeft: "25px",
            marginRight: "30px",
          }}
        >
          {isLoading
            ? translate("SavingModifications")
            : isModified
            ? translate("ClickToSave")
            : translate("SaveModifications")}
        </Button>
      </div>
    </div>
  );
}

export default PreAdmissionMobileV2;
