import DeleteIcon from "@mui/icons-material/Delete";
import uploadicon from "../../images/uploadfile.png";
import { useContext, useState } from "react";
import { LanguageContext } from "../../context/LanguageContext";

function UploaderDocForNoteV2({ handleFileChange, files, handleFileRemove }) {
  const { translate, language, setLanguage } = useContext(LanguageContext);
  const [uploading, setUploading] = useState(false);
  const handleFileUpload = (event) => {
    setUploading(true); // Commence le chargement
    handleFileChange(event);
    // Simuler un chargement de fichier pour démonstration
    setTimeout(() => {
      setUploading(false); // Fini le chargement
    }, 3000); // Remplacez cela par la logique réelle de chargement de fichier
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="main-upload-container-v2">
        <div className="form2-v2">
          <div className="line5-modal">
            <img
              src={uploadicon}
              width="50px"
              alt=""
              onClick={() => document.getElementById("uploadFiles").click()}
              style={{ cursor: "pointer" }} // Pour indiquer que l'image est cliquable
            />
            <input
              type="file"
              id="uploadFiles"
              multiple
              onChange={handleFileChange}
              accept="image/png, image/jpeg, image/jpg"
              style={{
                width: "0.1px",
                height: "0.1px",
                opacity: 0,
                overflow: "hidden",
                position: "absolute",
                zIndex: -1,
              }}
            />
            <label
              htmlFor="uploadFiles"
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "8px",
                marginTop: "20px",
                paddingLeft: "5px",
                paddingRight: "5px",
                fontWeight: "600",
              }}
            >
              {translate("ImportFile")}
            </label>
            <div style={{ color: "#838383", fontSize: "12px" }}>
              {translate("MaximumFileSize")}
            </div>
          </div>
        </div>
      </div>
      {files?.length > 0 && (
        <div
          style={{ width: "100%", paddingLeft: "25px", paddingRight: "25px" }}
        >
          <div
            style={{
              border: "1px solid lightgrey",
              width: "100%",
              borderRadius: "10px",
            }}
          >
            <div
              className="select-title-v2"
              style={{ paddingTop: "10px", fontWeight: "500" }}
            >
              {translate("SelectedFile")}
              <div className="ul-container-v2">
                {Array.from(files).map((file) => (
                  <div className="file-item-v2" key={file.name}>
                    <img
                      src={uploadicon}
                      className="file-icon"
                      alt="file icon"
                    />
                    <span className="file-name">
                      {file.name} ({file.type})
                    </span>
                    <DeleteIcon
                      onClick={() => handleFileRemove(file.name)}
                      style={{ cursor: "pointer", marginLeft: "10px" }}
                    />
                  </div>
                ))}
              </div>
              {/* <div className="progress-bar"></div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UploaderDocForNoteV2;
