import React from "react";
import LineSwot from "../component/Lines/LineSwot";
import TobSubtitle from "../component/TobSubtitle";
import TopTitleBanner from "../component/TopTitleBanner";
import "../css/dashboard.css";
import { useState } from "react";
import { useEffect } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { CircularProgress } from "@mui/material";
export default function DashboardContent() {
  const [clientData, setClientData] = useState(null);

  useEffect(() => {
    const clientsCollectionRef = collection(db, "clients");
    const unsubscribe = onSnapshot(clientsCollectionRef, (querySnapshot) => {
      const clients = [];
      querySnapshot.forEach((doc) => {
        clients.push({ id: doc.id, ...doc.data() });
      });
      setClientData(clients);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="right-container">
      <TopTitleBanner title={"Dashboard"} />{" "}
      <TobSubtitle text={"SWOT SCHEDULE & DISCHARGE"} />
      <div className="swot-container">
        <div className="titles-swot-table">
          <div className="text-title-swot-table title7-swot">
            <div className="title-2items">Client name</div>
          </div>
          <div className="text-title-swot-table2 title8-swot">
            <div className="title-2items">Intake date</div>
          </div>
          <div className="text-title-swot-table2 title8-swot">
            <div className="title-2items">Intended D.O.S.</div>
          </div>
          <div className="text-title-swot-table2 title6-swot">
            <div className="title-2items"> Discharge date</div>
          </div>
          <div className="text-title-swot-table title6-swot">
            {" "}
            <div className="title-2items"> Swot</div>
          </div>
          {/* <div className="text-title-swot-table title3-swot">
            {" "}
            <div className="title-2items">Flight</div>
          </div> */}
          <div className="text-title-swot-table title6-swot">
            <div className="title-2items">
              <p>Aftercare staff</p>{" "}
              {/* <img className="sorting-icon" src={filterIcon} /> */}
            </div>
          </div>
          <div className="text-title-swot-table title6-swot">
            <div className="title-2items"> CTS counseling</div>
          </div>
          <div className="text-title-swot-table title6-swot">
            <div className="title-2items"> Recovery coach</div>
          </div>
          <div className="text-title-swot-table title6-swot">
            <div className="title-2items">Miracles@home</div>
          </div>
        </div>

        {clientData ? (
          clientData
            .filter((client) => client.dateOfAdmission)
            .map((client, index) => (
              <LineSwot
                key={client.id}
                clientId={client.id}
                background={index % 2 === 0 ? "white" : "grey"}
                avatar={client.profilePicture || "1"}
                dos={client.durationOfStay || ""}
                swotChecked={client.swotDone || ""}
                clientName={client.clientName || "------"}
                firstName={client.firstName || "------"}
                lastName={client.lastName || "------"}
                avatarStaff={client.avatarStaff || "Augustine"}
                nameStaff={client.aftercareStaff1 || "------"}
                nameCTS={client.aftercareStaff2 || "------"}
                avatarCTS={client.avatarCTS || "Paula"}
                mAtHome={client.miraclesAtHome || ""}
                admissionDate={client.dateOfAdmission}
                dischargeDate={client.idd}
                nameRecoveryCoach={client.nameRecoveryCoach || ""}
                dateSwot={client.swot || ""}
                flightDate={client.flight}
                swotDone={
                  client.swotDone === "undefined" ? false : client.swotDone
                }
              />
            ))
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </div>
        )}

        {/* {clientData ? (
          clientData.map((client, index) => (
            <LineSwot
              key={client.id}
              clientId={client.id}
              background={index % 2 === 0 ? "white" : "grey"}
              avatar={client.profilePicture || "1"}
              dos={client.durationOfStay || ""}
              swotChecked={client.swotDone || ""}
              clientName={client.clientName || "------"}
              firstName={client.firstName || "------"}
              lastName={client.lastName || "------"}
              avatarStaff={client.avatarStaff || "Augustine"}
              nameStaff={client.aftercareStaff1 || "------"}
              nameCTS={client.aftercareStaff2 || "------"}
              avatarCTS={client.avatarCTS || "Paula"}
              mAtHome={client.miraclesAtHome || ""}
              admissionDate={client.dateOfAdmission}
              dischargeDate={client.idd}
              nameRecoveryCoach={client.nameRecoveryCoach || ""}
              dateSwot={client.swot || ""}
              flightDate={client.flight}
              swotDone={
                client.swotDone === "undefined" ? false : client.swotDone
              }
            />
          ))
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </div>
        )} */}
      </div>
    </div>
  );
}
