import React from "react";
import CloseIcon from "@mui/icons-material/Close";

function TopTitleFormComponentMobile(props) {
  return (
    <div className="line1-modal">
      <div className="modal-title-small">{props.title}</div>
      <CloseIcon
        style={{
          marginRight: "16px",
          color: "rgb(214, 213, 213)",
          cursor: "pointer",
          pointerEvents: "auto",
        }}
        onClick={props.closemodal}
      />
    </div>
  );
}

export default TopTitleFormComponentMobile;
