import React from "react";

function TitleMedium5() {
  return (
    <div className="title-line-medium">
      <div className="title-medium5-block1">Google email</div>
      <div className="title-medium5-block2">User Name</div>
      <div className="title-medium5-block3">User Role</div>
      <div className="title-medium5-block3">Phone Number</div>
      <div className="title-medium5-block3">Actions</div>
    </div>
  );
}

export default TitleMedium5;
