import { useState, useEffect, useContext } from "react";
import {
  collection,
  query,
  where,
  limit,
  onSnapshot,
} from "firebase/firestore";
import { Autocomplete, Avatar } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { recordMetrics } from "./logsComponents/recordMetrics";
import { useAuth } from "../contexts/AuthContext";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { LanguageContext } from "../context/LanguageContext";

export default function SearchBarInModalMobile({ setSelectedClient }) {
  const { translate } = useContext(LanguageContext);
  const { currentUser } = useAuth();
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [unsubscribe, setUnsubscribe] = useState(null);
  const [localSelectedClient, setLocalSelectedClient] = useState(null); // Renommé pour éviter les conflits
  const [readCount, setReadCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  useEffect(() => {
    let newUnsubscribe;

    if (debouncedSearchQuery.length > 0) {
      setLoading(true);

      const search = query(
        collection(db, "clients"),
        where(
          "searchTerms",
          "array-contains-any",
          debouncedSearchQuery.trim().toLowerCase().split(" ")
        ),
        limit(10)
      );

      newUnsubscribe = onSnapshot(search, (snapshot) => {
        const results = [];
        snapshot.forEach((doc) => {
          results.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setClients(results);
        setLoading(false);
        setReadCount((count) => count + 1);
        console.log(`Nombre de lectures Firestore : ${readCount}`);
      });
    } else {
      setClients([]);
    }

    setUnsubscribe(() => newUnsubscribe);
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [debouncedSearchQuery]);

  const handleChange = async (e) => {
    setSearchQuery(e.target.value);
    setLocalSelectedClient(null);
  };

  const handleSelect = async (event, value) => {
    if (value) {
      const client = clients.find((client) => client.id === value.id);
      if (client) {
        setSelectedClient(client); // Met à jour le client sélectionné avec la prop passée
        await recordMetrics(
          "Clients",
          "searchClient",
          currentUser.email,
          "read",
          "rehab-app-demo"
        );
      } else {
        setSelectedClient(null);
      }
    } else {
      setSelectedClient(null);
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "In House":
        return "ribon-color-inhouse";
      case "Aftercare":
        return "ribon-color-aftercare";
      case "Incoming":
        return "ribon-color-incoming";
      case "Miracles@home":
        return "ribon-color-mathome";
      case "Clinical Assessment":
        return "ribon-color-clinical-assessment";
      case "Continued Therapy":
        return "ribon-color-continued-therapy";
      case "Hot Lead":
        return "ribon-color-hot-lead";
      case "Denied Admission":
        return "ribon-color-denied-admission";
      case "Potential":
        return "ribon-color-potential";
      default:
        return "ribon-color-inactive";
    }
  };

  return (
    <div>
      <Autocomplete
        freeSolo
        disableClearable
        options={clients}
        getOptionLabel={(option) =>
          `${option.firstName} ${option.lastName} (${option.clientIdentifier})`
        }
        renderOption={(props, option) => {
          return (
            <li
              {...props}
              key={option.id}
              style={{
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid #BEBCBC",
              }}
            >
              {option.profilePicture ? (
                <Avatar
                  src={option.profilePicture}
                  style={{ marginRight: "10px", width: "50px", height: "50px" }}
                />
              ) : (
                <Avatar
                  style={{
                    marginRight: "10px",
                    width: "50px",
                    height: "50px",
                    backgroundColor: "#e0e0e0",
                  }}
                >
                  <AccountCircleIcon />
                </Avatar>
              )}
              <div style={{ width: "180px" }}>
                {`${option.firstName} ${option.lastName}`}
              </div>

              <div
                style={{
                  width: "120px",
                  fontSize: "12px",
                }}
                className={`${
                  option.clientStatus === "Clinical Assessment"
                    ? "ribon-container-medium"
                    : "ribon-container-small"
                } ${getStatusClass(option.clientStatus)}`}
              >
                {option.clientStatus}
              </div>
            </li>
          );
        }}
        renderInput={(params) => (
          <div
            ref={params.InputProps.ref}
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <SearchIcon
              style={{
                position: "absolute",
                left: "10px",
                color: "#9e9e9e",
              }}
            />
            <input
              type="text"
              {...params.inputProps}
              onChange={handleChange}
              value={searchQuery}
              placeholder={translate("SelectAClient")}
              style={{
                width: "250px",
                height: "45px",
                borderRadius: "10px",
                paddingLeft: "40px",
                background: "#f2f3f8",
                border: "1px solid #e9e9ea",
              }}
            />
          </div>
        )}
        onChange={handleSelect}
      />
    </div>
  );
}
