import React, { useContext } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { LanguageContext } from "../../context/LanguageContext";

function TopTitleFormComponentMobileV2(props) {
  const { translate } = useContext(LanguageContext);
  return (
    <div className="line1-modal-newdesign-bis">
      <div
        className="modal-title"
        style={{
          margin: 0,
          paddingLeft: "35px",
          paddingTop: "10px",
          paddingBottom: "10px",
          fontSize: "18px",
          fontWeight: "700",
        }}
      >
        {props.title}
      </div>

      <div style={{ paddingRight: "20px" }}>
        <IconButton onClick={props.closemodal}>
          <CloseIcon
            // onClick={props.closemodal}
            title="Close"
            sx={{ fontSize: 30, cursor: "pointer" }} // Couleur bleue et taille de 60px
          />
        </IconButton>
      </div>
    </div>
  );
}

export default TopTitleFormComponentMobileV2;
