import { metricsDb } from "../../firebaseMetrics";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

export const recordMetrics = async (
  namePage,
  nameModule,
  email,
  type,
  projectName
) => {
  try {
    // Utiliser `projectName` pour spécifier la collection
    await addDoc(collection(metricsDb, projectName), {
      namePage,
      nameModule,
      email,
      type,
      time: serverTimestamp(),
      projectName,
    });
    console.log("Metrics recorded successfully");
  } catch (error) {
    console.error("Error recording metrics: ", error);
  }
};
