import React, { useContext, useEffect } from "react";
import "../css/SignCss.css";
import logo from "../images/logo-miraclesehr.png";
import googleIcon from "../images/google-icon.png";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-tippy/dist/tippy.css";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Alert } from "@mui/material";
import { showCustomToast } from "./Misc/CustomToast";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { LanguageContext } from "../context/LanguageContext";
import ThailandFlag from "../images/flags/thai-flag.png";
import USFlag from "../images/flags/uk-flag-icon.png";
import FranceFlag from "../images/flags/french-flag-icon.png";
import RehappLogo from "../images/rehap-logo.png";

export default function Login() {
  const navigate = useNavigate();
  const { translate, language, setLanguage } = useContext(LanguageContext);
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };
  const isOnline = navigator.onLine;
  const { signInWithGoogle } = useAuth();

  const { currentUser } = useAuth();

  // useEffect(() => {
  //   if (currentUser) {
  //     navigate("/account");
  //   }
  // }, [currentUser, navigate]);

  useEffect(() => {
    async function fetchUserRoleAndNavigate() {
      // Si currentUser n'est pas null, alors l'utilisateur est connecté
      if (currentUser) {
        // Récupérez le document de rôle pour l'utilisateur actuel
        const docRef = doc(db, "roles", currentUser.uid);
        const docSnap = await getDoc(docRef);

        // Vérifiez si le document existe et si le rôle est "Normal User"
        if (docSnap.exists()) {
          if (docSnap.data().role === "Normal User" || !docSnap.data().role) {
            navigate("/ai");
          } else {
            navigate("/ai");
          }
        } else {
          // S'il n'y a pas de rôle défini, redirigez vers "/account"
          navigate("/ai");
          console.log(translate("NoRoleFoundForUser"));
        }
      }
    }

    fetchUserRoleAndNavigate();
  }, [currentUser, navigate]);

  function handleSignInWithGoogle() {
    signInWithGoogle()
      .then((result) => {
        showCustomToast(
          `${translate("SuccessfullyLoggedInWithGoogle")}`,
          "success"
        );
      })
      .catch((error) => {
        if (error.code === "permission-denied") {
          showCustomToast(
            `${translate("MustLogInWithGmailOrGsuite")}`,
            "error"
          );
        } else {
          showCustomToast(
            `${translate("Failed to log in with your Google account")}`,
            "error"
          );
        }
      });
  }

  return (
    <div className="sign-main-container">
      {window.innerWidth < 400 ? (
        <div />
      ) : (
        <div className="logo">{/* <img src={logo} alt="" /> */}</div>
      )}
      {/* <div className="logo">
        <img src={logo} alt="" />
      </div> */}
      <div className="sign-left-container">
        <div className="alert-container-top"></div>
        <div className="signup-container-title">
          <div className="signup-title">{translate("WelcomeToRehappApp")}</div>
          <div className="signup-subtitle">
            {translate("ContinueWithGoogle")}{" "}
          </div>
        </div>

        <div className="container-google-sso" style={{fontSize:'10px'}}>
          <Button
            sx={{
              borderRadius: "30px",
              paddingTop: "10px",
              paddingBottom: "10px",
              width: "45%",
              height: "30px",
              fontSize:'10px',
              "@media (max-width: 1100px)": {
                width: "90%", // Lorsque la largeur de l'écran est inférieure à 500 pixels, la largeur sera de 70%
                fontSize:'10px'
              },
            }}
            // sx={{
            //   borderRadius: "5px",
            //   width: "45%",
            //   height: "auto",
            // }}
            variant="outlined"
            onClick={handleSignInWithGoogle}

          >
            {" "}
            <img alt="Google icon" src={googleIcon} className="google-icon" />
            {translate("LogInWithGoogle")}
          </Button>
        </div>

        <div
          style={{
            paddingLeft: "0px",
            paddingTop: "40px",
            height: "10%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormControl
            variant="outlined"
            size="small"
            style={{ minWidth: 180 }}
          >
            <InputLabel id="language-select-label">Language</InputLabel>
            <Select
              labelId="language-select-label"
              id="language-select"
              value={language}
              onChange={handleLanguageChange}
              label="Language"
              renderValue={(selected) => (
                <Box display="flex" alignItems="center">
                  {selected === "en" && (
                    <img
                      src={USFlag}
                      alt="US Flag"
                      style={{ width: 20, marginRight: 10 }}
                    />
                  )}
                  {selected === "fr" && (
                    <img
                      src={FranceFlag}
                      alt="France Flag"
                      style={{ width: 20, marginRight: 10 }}
                    />
                  )}
                  {selected === "th" && (
                    <img
                      src={ThailandFlag}
                      alt="Thailand Flag"
                      style={{ width: 20, marginRight: 10 }}
                    />
                  )}
                  {selected === "en"
                    ? "English"
                    : selected === "fr"
                    ? "Français"
                    : "ภาษาไทย"}
                </Box>
              )}
            >
              <MenuItem value="en">
                <Box display="flex" alignItems="center">
                  <img
                    src={USFlag}
                    alt="US Flag"
                    style={{ width: 20, marginRight: 10 }}
                  />
                  English
                </Box>
              </MenuItem>
              <MenuItem value="fr">
                <Box display="flex" alignItems="center">
                  <img
                    src={FranceFlag}
                    alt="France Flag"
                    style={{ width: 20, marginRight: 10 }}
                  />
                  Français
                </Box>
              </MenuItem>
              <MenuItem value="th">
                <Box display="flex" alignItems="center">
                  <img
                    src={ThailandFlag}
                    alt="Thailand Flag"
                    style={{ width: 20, marginRight: 10 }}
                  />
                  ภาษาไทย
                </Box>
              </MenuItem>
            </Select>
          </FormControl>
        </div>

        {/* {isOnline ? (
          <Alert style={{ marginTop: "25px" }} severity="success">
            {translate("InternetConnectionWorking")}
          </Alert>
        ) : (
          <Alert style={{ marginTop: "25px" }} severity="error">
            {" "}
            {translate("InternetConnectionNotWorking")}
          </Alert>
        )} */}
      </div>

      {/* <div
        // className="sign-right-container"
        style={{
          backgroundColor: "lightpink",
          display: "flex",
        }}
      >
        <img
          src={RehappLogo}
          alt=""
          style={{ width: "200px", height: "auto" }}
        />
      </div> */}
      <div
        className="sign-right-container-2"
        style={{
          display: "flex",
          alignItems: "center", // Centre verticalement l'image dans le conteneur
          justifyContent: "center", // Centre horizontalement l'image dans le conteneur
          borderLeft: "1.5px solid lightgrey",
          paddingTop: "20px", // Ajoute un espace en haut
          paddingBottom: "20px", // Ajoute un espace en bas
          height: "100%", // Assure que le conteneur prend toute la hauteur disponible
          boxSizing: "border-box", // Pour inclure le padding dans la hauteur totale
        }}
      >
        <img
          src={RehappLogo}
          alt="Rehapp Logo"
          style={{ height: "100px", width: "auto" }}
        />
      </div>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}
