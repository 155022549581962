import React, { useContext, useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Tooltip from "@mui/material/Tooltip";
import { LanguageContext } from "../../context/LanguageContext";

function TitleNoteCompact(props) {
  const { translate, language, setLanguage } = useContext(LanguageContext);
  const [dateSortOrder, setDateSortOrder] = useState("asc");
  const [clientSortOrder, setClientSortOrder] = useState("asc");
  const [typeSortOrder, setTypeSortOrder] = useState("asc");
  const handleDateSort = () => {
    const newOrder = dateSortOrder === "asc" ? "desc" : "asc";
    setDateSortOrder(newOrder);
    props.handleSort(
      `date${newOrder.charAt(0).toUpperCase() + newOrder.slice(1)}`
    );
  };

  const handleClientSort = () => {
    const newOrder = clientSortOrder === "asc" ? "desc" : "asc";
    setClientSortOrder(newOrder);
    props.handleSort(
      `client${newOrder.charAt(0).toUpperCase() + newOrder.slice(1)}`
    );
  };

  const handleTypeSort = () => {
    const newOrder = typeSortOrder === "asc" ? "desc" : "asc";
    setTypeSortOrder(newOrder);
    props.handleSort(
      `type${newOrder.charAt(0).toUpperCase() + newOrder.slice(1)}`
    );
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "25px",
          display: "flex",
          flexDirection: "row",
          borderBottom: "1px solid lightgrey",
          cursor: "pointer",
          transition: "background-color 0.3s ease",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <div
            style={{
              width: "10%",
              height: "25px",
              display: "flex",
              flexDirection: "row",
              color: "#a3a3a4",
              fontWeight: "500",
              textAlign: "left",
            }}
          >
            <Tooltip title={translate("DateC")} arrow>
              <div
                style={{
                  paddingRight: "0px",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "20px",
                }}
              >
                {translate("DateC")}
                <div style={{ paddingTop: "7px" }}>
                  {dateSortOrder === "asc" ? (
                    <ArrowDropUpIcon
                      style={{
                        cursor: "pointer",
                        margin: "0",
                        fontSize: "2rem",
                      }}
                      onClick={handleDateSort}
                    />
                  ) : (
                    <ArrowDropDownIcon
                      style={{
                        cursor: "pointer",
                        margin: "0",
                        fontSize: "2rem",
                      }}
                      onClick={handleDateSort}
                    />
                  )}
                </div>
              </div>
            </Tooltip>
          </div>

          <div
            style={{
              width: "20%",
              height: "25px",
              display: "flex",
              color: "#a3a3a4",
              fontWeight: "500",
              textAlign: "left",
              paddingLeft: "30px",
            }}
          >
            <Tooltip title={translate("ClientC")} arrow>
              <div
                style={{
                  paddingRight: "0px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {translate("ClientC")}
                <div style={{ paddingTop: "7px" }}>
                  {clientSortOrder === "asc" ? (
                    <ArrowDropUpIcon
                      style={{
                        cursor: "pointer",
                        margin: "0",
                        fontSize: "2rem",
                      }}
                      onClick={handleClientSort}
                    />
                  ) : (
                    <ArrowDropDownIcon
                      style={{
                        cursor: "pointer",
                        margin: "0",
                        fontSize: "2rem",
                      }}
                      onClick={handleClientSort}
                    />
                  )}
                </div>
              </div>
            </Tooltip>
          </div>

          <div
            style={{
              width: "50%",
              height: "25px",
              display: "flex",
              color: "#a3a3a4",
              fontWeight: "500",
              textAlign: "left",
              paddingLeft: "10px",
            }}
          >
            {translate("ContentC")}
          </div>
          <div
            style={{
              width: "15%",
              height: "25px",
              display: "flex",
              color: "#a3a3a4",
              fontWeight: "500",
              textAlign: "left",
            }}
          >
            <Tooltip title={translate("Type")} arrow>
              <div
                style={{
                  paddingRight: "0px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {translate("Type")}
                <div style={{ paddingTop: "7px" }}>
                  {typeSortOrder === "asc" ? (
                    <ArrowDropUpIcon
                      style={{
                        cursor: "pointer",
                        margin: "0",
                        fontSize: "2rem",
                      }}
                      onClick={handleTypeSort}
                    />
                  ) : (
                    <ArrowDropDownIcon
                      style={{
                        cursor: "pointer",
                        margin: "0",
                        fontSize: "2rem",
                      }}
                      onClick={handleTypeSort}
                    />
                  )}
                </div>
              </div>
            </Tooltip>
          </div>
        </div>

        <div
          style={{
            // backgroundColor: "lightcoral",
            width: "5%",
            // backgroundColor: "lightpink",
            height: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>
      </div>
    </>
  );
}

export default TitleNoteCompact;
