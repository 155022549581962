import React from "react";
import TopTitleBanner from "../component/TopTitleBanner";
import "../css/dashboard.css";
import TutorialVideosContent from "../component/Misc/TutorialVideosContent";

export default function TutorialsContent() {
  return (
    <div className="right-container">
      <TopTitleBanner title={"Tutorials"} />
      <TutorialVideosContent />
    </div>
  );
}
