import { doc, getDoc, updateDoc } from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";
import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import DeleteButtonFile from "../Buttons/DeleteButtonFile";
import { showCustomToast } from "../Misc/CustomToast";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function UploaderPreAdmission(props) {
  const maxSizePicFile = 5 * 1024 * 1024; // 5 Mo

  const [files, setFiles] = useState([]);
  const [fileLinks, setFileLinks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length <= 3) {
      setFiles(event.target.files);
    } else {
      showCustomToast("You can only upload up to 3 files.", "error");
    }
  };

  const fetchFileUrls = async () => {
    const docRef = doc(db, "clients", props.clientId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setFileLinks(docSnap.data().filesUrlPreAdm || []);
    } else {
      console.log("No such document!");
    }
  };

  useEffect(() => {
    fetchFileUrls();
  }, [props.clientId]);

  const uploadFilesAndGetUrls = async (docId) => {
    setIsLoading(true);
    const storage = getStorage();
    const promises = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > maxSizePicFile) {
        showCustomToast(`File size exceeds the limit: ${file.name}`, "error");
        console.error(`File size exceeds the limit: ${file.name}`);
        continue;
      }
      const filePath = `PreAdmission/${props.clientId}/${file.name}`;
      const storageRef = ref(storage, filePath);

      const uploadTask = uploadBytes(storageRef, file).then(() =>
        getDownloadURL(storageRef).then((url) => ({ name: file.name, url }))
      );
      promises.push(uploadTask);
    }

    const fileUrls = await Promise.all(promises);

    const clientRef = doc(db, "clients", docId);
    const docSnap = await getDoc(clientRef);
    const existingUrls =
      docSnap.exists() && docSnap.data().filesUrlPreAdm
        ? docSnap.data().filesUrlPreAdm
        : [];

    await updateDoc(clientRef, {
      filesUrlPreAdm: [...existingUrls, ...fileUrls],
    });
    setIsLoading(false);

    fetchFileUrls(); // Call the function here

    return fileUrls;
  };

  const deleteFile = async (fileUrl, fileName, noteId) => {
    const storage = getStorage();
    const filePath = `PreAdmission/${props.clientId}/${fileName}`;
    const storageRef = ref(storage, filePath);

    try {
      await deleteObject(storageRef);
      const newFileLinks = fileLinks.filter((file) => file.url !== fileUrl);
      setFileLinks(newFileLinks);
      const noteRef = doc(db, "clients", noteId);

      await updateDoc(noteRef, {
        filesUrlPreAdm: newFileLinks,
      });

      showCustomToast("File successfully deleted!", "success");
    } catch (error) {
      console.log("Error deleting file:", error);
      showCustomToast("Error deleting file", "error");
    }
  };

  return (
    <>
      <div
        className="inputAndLabelModalContainerTitle2"
        style={{ position: "fixed", top: 0, left: 0, width: "100%", zIndex: 1 }}
      >
        <div className="line1-modal">
          <div className="modal-title">Add document </div>

          <CloseIcon
            style={{
              marginRight: "16px",
              color: "rgb(214, 213, 213)",
              cursor: "pointer",
            }}
            onClick={props.closemodal}
          />
        </div>
      </div>
      <div
        style={{
          marginTop: "80px",
          height: "350px",
          paddingRight: "20px",
          // overflowY: "scroll"
        }}
      >
        <div className="uploader-component-general-infos">
          <div className="form2">
            <div className="line5-modal">
              <label
                htmlFor="uploadFiles"
                style={{
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "8px",
                  marginTop: "20px",
                }}
              >
                Upload file(s)
              </label>
              <input
                type="file"
                id="uploadFiles"
                multiple
                onChange={handleFileChange}
                accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv,.zip,.rar,.7z"
                style={{
                  width: "0.1px",
                  height: "0.1px",
                  opacity: 0,
                  overflow: "hidden",
                  position: "absolute",
                  zIndex: -1,
                }}
              />
              <label htmlFor="uploadFiles" className="custom-file-upload">
                Select files
              </label>

              {files.length > 0 && (
                <div className="select-title">
                  Selected files:
                  <ul className="ul-container">
                    {Array.from(files).map((file) => (
                      <a className="a-container" key={file.name}>
                        {file.name}
                      </a>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>

          <div className="form2">
            <label
              htmlFor="uploadFiles"
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "8px",
                marginTop: "20px",
              }}
            >
              Document(s) already uploaded :
            </label>
            <div className="list-files-container">
              {fileLinks && fileLinks !== null && fileLinks !== undefined ? (
                fileLinks.map((file, index) => (
                  <div key={index} className="file-item">
                    File name :
                    <a
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: 100,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      href={file.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {file.name}
                    </a>
                    <DeleteButtonFile
                      fileName={file.name}
                      onClick={() =>
                        deleteFile(file.url, file.name, props.clientId)
                      }
                    />
                  </div>
                ))
              ) : (
                <div>No files found</div>
              )}
            </div>
          </div>
          <div className="line4-modal">
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
              onClick={props.closemodal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                    }
              }
              disabled={isLoading}
              onClick={() => uploadFilesAndGetUrls(props.clientId)} // passing the clientId here
            >
              {isLoading ? "UPLOADING..." : "UPLOAD"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default UploaderPreAdmission;
